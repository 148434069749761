import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import TextField from '@mui/material/TextField';

export default function BaseInput(props) {
  const { style, onChange, InputLabelProps={}, ...otherProps } = props;

  const useStyles = makeStyles((theme) => ({
    input: {
      "& .MuiOutlinedInput-input": {
        cursor:  `${props.inputtype}`
      }
    }
  }));
  const classes = useStyles();

  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      style={{ maxWidth: 600, ...style }}
      onChange={(e) => onChange(e.target.value, e)}
      InputLabelProps={otherProps.value ? { shrink: true, ...InputLabelProps } : {...InputLabelProps}}
      className={classes.input}
      {...otherProps}
    />
  );
};
