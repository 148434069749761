import React from 'react';

import { withDesign } from './Design';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import Select, { BooleanSelect } from 'components/atoms/Input/Select';
import Layout from './Layout';
import PaperGroup from 'components/molecules/Form/PaperGroup';

function Inline(props) {
  const { simulator, designs } = props;
  const design = simulator.design;

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [ inlineVideo, setInlineVideo ] = React.useState(design.inlineVideo);
  const [ inlineVideoAccess, setInlineVideoAccess ] = React.useState(design.inlineVideoAccess);

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [inlineVideo, inlineVideoAccess]);

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])
  
  const cancel = () => {
    setInlineVideo(design.inlineVideo);
    setInlineVideoAccess(design.inlineVideoAccess)

    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const stateDesign = {
    inlineVideo,
    inlineVideoAccess,
    // inlineVideoDisplayTitle: 'none',
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm: stateDesign,
    simulator: { ...simulator, noDevice: true },
    tab: "Video Settings",
    headerTitle: "Inline Content",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  return (
    <Layout {...layoutProps}>
      <PaperGroup>
        <BooleanSelect
          value={inlineVideo}
          onChange={setInlineVideo}
          label="Inline Video Thumbnails"
          helperText="Note: Inline Video Thumbnails can cause poor performance or lagginess when there are many videos or large video files."
          disabled={disableForm}
          required
        />

        <Select
          options={[
            { label: "Full Access", value: "full" },
            { label: "Limited Access", value: "limited" },
            { label: "None", value: "none" },
          ]}
          value={inlineVideoAccess}
          onChange={setInlineVideoAccess}
          label="Inline Menu"
          disabled={disableForm}
          required
        />
      </PaperGroup>
    </Layout>
  );
}

export default withDesign(withSimulator(Inline));
