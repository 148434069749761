import React from "react";
import makeStyles from '@mui/styles/makeStyles';

import {
  Grid,
  Typography,
  Fab,
  Link,
  Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Input from "components/atoms/Input/BaseInput";
import Select from "components/atoms/Input/Select";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: '22px'
  },
}));

export default function Kvps(props) {
  const { currentFormKeys, currentStateValues, updateState, kvpCount, setKvpCount } = props;
  const classes = useStyles();

  return (
    <Grid container >
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Target ads with key value Pairs(KVPs)
              </Typography>
        <Typography variant="body2" gutterBottom>
          If there are key values in your MRSS feed you want to target with ads, enter them below.
          </Typography>
        <Link gutterBottom href="https://help.mazsystems.com/en/setting-up-kvps-for-video-ads" variant="body2" target="_blank">
          Learn more about how KVPs work in your MRSS.
          </Link>
      </Grid>
      <Grid xs={12}>
        {
          [...Array(kvpCount)].map((x, index) => (
            <Input
              label="Key Value Pair Tag"
              // error={errorHandler("name")}
              name="keyValuePairTag"
              value={currentStateValues?.adKvps[index]?.kvp || ""}
              onChange={(val) => updateState("adKvps", val, null, index)}
            />
          ))
        }

        <Fab onClick={() => setKvpCount(kvpCount + 1)} size="small" color="secondary" aria-label="add" className={classes.margin}>
          <AddIcon />
        </Fab>
      </Grid>

      {
        currentFormKeys.kvp_note &&
        <Grid xs={12}>
          <Box bgcolor="#f2f2ed" p={2}>
            <Typography variant="h8" paragraph>
              NOTE: To complete the Key-Value Pairs Setup, you will need to share your Keys
              and Values with your {currentFormKeys.display_name} account manager to add to the {currentFormKeys.display_name} dashboard.
          </Typography>
            <Typography variant="h8" paragraph>
              {currentFormKeys.display_name} cannot parse colons ':' in key-value pair tags. Hence, any colon ':' appearing in any key-value pair tag will be converted to an underscore '_' and therefore, key-value pair tags must be set up on the {currentFormKeys.display_name} dashboard accordingly.
          </Typography>
            <Typography variant="h8" paragraph>

              Please contact your {currentFormKeys.display_name} rep for more information!
          </Typography>
          </Box>
        </Grid>
      }

      {["advanced"].includes(currentFormKeys.name) && currentStateValues?.adKvps[0]?.kvp &&
        <Select
          label="Method to send KVPs"
          value={currentStateValues?.kvpMethod}
          onChange={(val) => updateState('kvpMethod', val)}
          options={[
            { label: "Google Ad Manager Method", value: "google_ad_manager_method" },
            { label: "SpringServe Method", value: "spring_serve_method" },
          ]}
        />
      }

    </Grid>
  )
}
