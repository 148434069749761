import React from "react";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_APP_PROPS } from "api";
import _ from "lodash";

import FormLayout from "components/molecules/Form/FormLayout";
import { withDesignSubmit } from "./Design";
import { withSavedSnack } from "components/molecules/Alerts/Saved";
import ContentLayout from "screens/Layout/ContentLayout";

function DesignLayout(props) {
  const { design, stateDesign, tab, headerTitle } = props;
  const currentDesign = { ...design, ...stateDesign };
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;

  const simulator = props.simulator
    ? { ...props.simulator, design: currentDesign, tab }
    : null;
  const formProps = {
    ...props,
    simulator: simulator,
    title: tab,
  };

  return (
    <ContentLayout title={headerTitle || tab} right={props.right}>
      <FormLayout {...formProps} />
    </ContentLayout>
  );
}

export default withSavedSnack(withDesignSubmit(DesignLayout));
