import React, { useEffect }  from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Link, NavLink, useLocation, useRouteMatch } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AppsIcon from '@mui/icons-material/Apps';
import HelpIcon from '@mui/icons-material/Help';
import SettingsIcon from "@mui/icons-material/Settings";
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

import clsx from 'clsx';
import Badge from '@mui/material/Badge';

import { ME, GET_CURRENT_WORKSPACE, GET_CURRENT_APP_PROPS } from 'api';
import Logo from 'components/atoms/Logo';
import NewWorkspaceModal from "components/molecules/Modal/NewWorkspace";
import DeveloperModeModal from "components/molecules/Modal/DeveloperModeModal";
import { isSuperUser } from 'helpers/platformUtils';

const PREFIX = 'Header';

const classes = {
  appBarContainer: `${PREFIX}-appBarContainer`,
  appbarContainerLeft: `${PREFIX}-appbarContainerLeft`,
  appbarContainerLeftR: `${PREFIX}-appbarContainerLeftR`,
  appbarContainerRight: `${PREFIX}-appbarContainerRight`,
  appBarContainerRightMobile: `${PREFIX}-appBarContainerRightMobile`,
  appbarContainerRightMobileLogo: `${PREFIX}-appbarContainerRightMobileLogo`,
  appbarContainerRightMobileBottomT: `${PREFIX}-appbarContainerRightMobileBottomT`,
  appbarContainerRightMobileBottom: `${PREFIX}-appbarContainerRightMobileBottom`,
  appbarContainerRightDesktop: `${PREFIX}-appbarContainerRightDesktop`,
  toolbar: `${PREFIX}-toolbar`,
  logoLink: `${PREFIX}-logoLink`,
  sidebarToggle: `${PREFIX}-sidebarToggle`,
  appBar: `${PREFIX}-appBar`,
  appMenu: `${PREFIX}-appMenu`,
  appList: `${PREFIX}-appList`,
  createApp: `${PREFIX}-createApp`,
  hub: `${PREFIX}-hub`,
  selectedHub: `${PREFIX}-selectedHub`,
  liveNav: `${PREFIX}-liveNav`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.appBarContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems:"center",
    padding: "7px",
  },

  [`& .${classes.appbarContainerLeft}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.appbarContainerLeftR}`]: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('mobile')]: {
      display: "none"
    },
  },

  [`& .${classes.appbarContainerRight}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.appBarContainerRightMobile}`]: {
    display: "flex",
    cursor: "pointer",
    [theme.breakpoints.up('tablet')]: {
      display: "none",
    },
  },

  [`& .${classes.appbarContainerRightMobileLogo}`]: {
      display:"none",
    [theme.breakpoints.down('sm')]: {
      display:"inline-block"
    },
  },

  [`& .${classes.appbarContainerRightMobileBottomT}`]: {
    display: "none",
    [theme.breakpoints.down('mobile')]: {
      display: "flex",
      justifyContent: "center"
    },
  },

  [`& .${classes.appbarContainerRightMobileBottom}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up('tablet')]: {
      display: "none",
    },
  },

  [`& .${classes.appbarContainerRightDesktop}`]: {
    [theme.breakpoints.down('tablet')]: {
      display: "none",
    },
  },

  [`& .${classes.toolbar}`]: {
    paddingLeft:9,
    paddingRight:9
  },

  [`& .${classes.logoLink}`]: {
    lineHeight: 1,
    display: 'flex',
    marginRight: theme.spacing(2),
    '& > span > span': {
      padding: '1px 4px 2px 3px',
      borderRadius: '5px',
      right: '-10px',
      top: '8px',
    }
  },

  [`& .${classes.sidebarToggle}`]: {
    marginLeft: -theme.spacing(2),
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 100,
    '& a':{
      textDecoration:"none",
      color:"#ffffff"
    }
  },

  [`& .${classes.appMenu}`]: {
    minWidth: 184,
  },

  [`& .${classes.appList}`]: {
    fontSize: 14,
  },

  [`& .${classes.createApp}`]: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },

  [`& .${classes.hub}`]: {
    margin: theme.spacing(0, 2),
    lineHeight: 1.6,
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: 'transparent',
    fontSize:14,
    '&:hover': {
      borderColor: 'inherit',
    }
  },

  [`& .${classes.selectedHub}`]: {
    borderColor: 'inherit',
  },

  [`& .${classes.liveNav}`]: {
    width: '60px',
    '& > span > span': {
      padding: '1px 4px 2px 3px',
      borderRadius: '5px',
      right: '-18px',
      top: '6px',
    }
  }
}));

export default function Header(props) {
  const { toggleDrawer } = props;


  const { data } = useQuery(ME);
  const user = data?.me;
  const isSuperAdmin = isSuperUser(user)

  const [ appAnchor, setAppAnchor ] = React.useState(null);
  const [ helpAnchor, setHelpAnchor ] = React.useState(null);
  const [ isSigningOut, setIsSigningOut ] = React.useState(null);
  const [ appenv,setAppenv] = React.useState(null);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV?.toLowerCase() === "development") setAppenv("DEV");
    if (process.env.REACT_APP_ENV?.toLowerCase() === "staging") setAppenv("STG");
  }, []);

  const handleHelpClick = (event) => {
    setHelpAnchor(event.currentTarget);
  };

  const handleAppClick = (event) => {
    setAppAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAppAnchor(null);
    setHelpAnchor(null);
  };

  return (
    <Root>
      <AppBar className={classes.appBar} position="fixed" elevation={0}>
        <Box className={classes.appBarContainer}>
          <Box className={classes.appbarContainerLeft}>
            <IconButton
              color="inherit"
              className={classes.sidebarToggle}
              onClick={toggleDrawer}
              size="large">
              <MenuIcon />
            </IconButton>
            <Box className={classes.appbarContainerLeftR}>
              {appenv !== null ?
                <Link to="/" className={classes.logoLink}>
                  <Badge color="secondary" badgeContent={appenv}>
                    <Logo />
                  </Badge>
                </Link> :
                <Link to="/" className={classes.logoLink}>
                  <Logo />
                </Link>
              }
              {user?.id && <AppHubs />}
            </Box>
          </Box>

          <Box className={classes.appBarContainerRight}>
            <Box className={classes.appBarContainerRightMobile}>
              <Box className={classes.appbarContainerRightMobileLogo}>
                {appenv !== null ?
                  <Link to="/" className={classes.logoLink}>
                    <Badge color="secondary" badgeContent={appenv}>
                      <Logo />
                    </Badge>
                  </Link> :
                  <Link to="/" className={classes.logoLink}>
                    <Logo />
                  </Link>
                }
              </Box>
              {!dropdownOpen && <ArrowDropDownRoundedIcon fontSize="large" onClick={() => setDropdownOpen(true)} />}
              {dropdownOpen && <ArrowDropUpRoundedIcon fontSize="large" onClick={() => setDropdownOpen(false)} />}
            </Box>
            <Box className={classes.appbarContainerRightDesktop}>
              {isSuperAdmin ? <DeveloperMode /> : null}
              <AccountMenu />
              <HelpMenu {...{ helpAnchor, handleHelpClick, handleClose }} />
              {user?.id && (
                <WorkspaceMenu {...{ user, appAnchor, handleAppClick, handleClose }} />
              )}
            </Box>
          </Box>
        </Box>
        {dropdownOpen && <>
          <Box className={classes.appbarContainerRightMobileBottomT}>
            {user?.id && <AppHubs />}
          </Box>
          <Box className={classes.appbarContainerRightMobileBottom}>
            {isSuperAdmin ? <DeveloperMode /> : null}
            <AccountMenu />
            <HelpMenu {...{ helpAnchor, handleHelpClick, handleClose }} />
            {user?.id && (
              <WorkspaceMenu {...{ user, appAnchor, handleAppClick, handleClose }} />
            )}
          </Box>
        </>
        }
      </AppBar>
    </Root>
  );
}

const Hubs = (props) => {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data?.getCurrentAppProps;
  const baseLink = `${appProps.workspaceId}/${appProps.appId}`;


  return (
    <>
      {appProps.appId
        ? <>
            <HubLink href={`/${baseLink}/monetization`} selector="monetization" >
              Monetization
            </HubLink>
            <HubLink href={`/${baseLink}/content`} selector="content" >
              Content
            </HubLink>
            <HubLink href={`/${baseLink}/live`} selector="live" className={classes.liveNav} >
              <Badge color="secondary" badgeContent="Beta"> Live </Badge>
            </HubLink>
            <HubLink href={`/${baseLink}/design`} selector="design" >
              Design
            </HubLink>
            {/* <HubLink href={`/${baseLink}/analytics`} selector="reports">Analytics</HubLink> */}
          </>
        : null}
    </>
  );
};

const AppHubs = Hubs;

const HubLink = ({ href, selector, children, className={}, onClick }) => {

  const location = useLocation();
  const selected = location.pathname.split("/")[3] === selector;

  const historyObj = useSelector(state => state.routeHistory.route) || {};
  const newHref = (historyObj[selector] || `${href}/${selector}`);
  
  return (
    <NavLink to={newHref} className={clsx(classes.hub, selected && classes.selectedHub, className)}>
      {children}
    </NavLink>
  );
};

const WorkspaceMenu = ({ user, appAnchor, handleAppClick, handleClose }) => {

  const { path } = useRouteMatch();
  const workspace = useQuery(GET_CURRENT_WORKSPACE).data.getCurrentWorkspace;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data?.getCurrentAppProps;

  const [openModal, setOpenModal] = React.useState(null);
  const closeModal = (data) => {
    setOpenModal(null);
    if (data.id !== undefined && data.apps!== undefined) { 
      window.location.href = `/${data.id}/${data.apps[0].id}`;
    }
  };

  return (
    <>
      <Button
        aria-controls="app-menu"
        aria-haspopup="true"
        startIcon={<AppsIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        color="inherit"
        onClick={handleAppClick}
      >
        {workspace.name}
      </Button>
      <Menu
        id="app-menu"
        anchorEl={appAnchor}
        open={Boolean(appAnchor)}
        onClose={handleClose}
        keepMounted
      >
        {user.workspaces.map((a) => (
          <MenuItem
            key={`MenuItemWorkspace-${a.id}`}
            className={classes.appList}
            onClick={() => {
              handleClose();
              if (a.id !== appProps.workspaceId) {
                window.location.href = `/${a.id}/${a.apps[0].id}`;
              }
            }}
          >
            {a.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          className={classes.createApp}
          onClick={() => {
            setOpenModal({});
            handleClose();
          }}
        >
          Create Workspace
        </MenuItem>
      </Menu>

      <NewWorkspaceModal
        open={Boolean(openModal)}
        onClose={closeModal}
        workspace={openModal}
      />
    </>
  );
};

const HelpMenu = ({ helpAnchor, handleHelpClick, handleClose }) => {
  return <>
    <IconButton
      aria-controls="help-menu"
      aria-haspopup="true"
      color="inherit"
      onClick={handleHelpClick}
      size="large">
      <HelpIcon />
    </IconButton>

    <Menu
      id="help-menu"
      anchorEl={helpAnchor}
      keepMounted
      open={Boolean(helpAnchor)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleClose}>
        <MuiLink
          color="inherit"
          underline="none"
          href="https://help.mazsystems.com"
          target="_blank"
        >
          Knowledge Base
        </MuiLink>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <MuiLink
          color="inherit"
          underline="none"
          href="https://help.mazsystems.com/en/how-to-contact-maz-support"
          target="_blank"
        >
          Contact Us
        </MuiLink>
      </MenuItem>
    </Menu>
  </>;
};

const AccountMenu = () => {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data?.getCurrentAppProps;

  return (
    <IconButton
      aria-controls="account-menu"
      aria-haspopup="true"
      color="inherit"
      component={Link}
      to={`/${appProps.workspaceId}/${appProps.appId}/settings/profile`}
      size="large">
      <SettingsIcon />
    </IconButton>
  );
};

const DeveloperMode = () => {
  const [ visible, setVisible ] = React.useState(false);

  return <>
    <IconButton
      aria-controls="developer-menu"
      aria-haspopup="true"
      color="inherit"
      onClick={() => setVisible(!visible)}
      size="large">
      <DeveloperModeIcon />
    </IconButton>

    <DeveloperModeModal
      open={visible}
      onClose={() => setVisible(false)}
    />
  </>;
}
