import { useApolloClient } from "@apollo/client";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import _ from 'lodash';

import BaseInput from './BaseInput';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Input from '@mui/material/Input';
import { upload } from "api";
import { getHeightAndWidthFromDataUrl } from 'helpers/fileUtils';

const useStyles = makeStyles((theme) => ({
  iconPaper: {
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px',
    maxHeight: 166,
    overflowY: 'auto',
    position: 'relative',
    backgroundColor: 'white',
    display: "flex",
    flexWrap: "wrap",
  },
  iconBox: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
}));

export default function IconPicker(props) {
  const { getter, setter, label, textFieldProps, onUpload , disabled} = props;
  const [ iconAnchor, setIconAnchor ] = React.useState(null);
  const handleClose = () => setIconAnchor(null);
  const [uploadedUrl, setUploadedUrl] = React.useState(null);

  function fullUrl(name) {
    if (name.includes("http")) {
      return name;
    } else {
      return (`https://resources.mazsystems.com/assets/core/production/${name}@3x.png`);
    }
  }

  function handleUpload(response) {
    onUpload(response);
    setUploadedUrl(response.url);
    handleClose();
  }

  return (
    <div>
      <BaseInput
        readOnly
        required
        label={label}
        value="Choose Icon"
        inputtype="pointer"
        name={_.camelCase(label)}
        onClick={(event) => setIconAnchor(event.currentTarget)}
        style={{ maxWidth: 260 }}
        disabled={disabled}
        {...textFieldProps}
      />
      <div style={{ position: 'relative', maxWidth: 260 }}>
        <Box bgcolor="primary.main" style={{ position: 'absolute', top: -52, right: 8, padding: 4, height: 28 }}>
          <img
            src={uploadedUrl || fullUrl(getter)}
            alt={getter}
            width="20"
            height="20"
          />
        </Box>
      </div>
      {!disabled && <Menu
        anchorEl={iconAnchor}
        open={Boolean(iconAnchor)}
        onClose={handleClose}
        PaperProps={{ style: { width: 260 } }}
        MenuListProps={{ style: { padding: 0 } }}
      >
        <IconPaper
          fullUrl={fullUrl}
          icon={getter}
          onUpload={handleUpload}
          onChange={(icon) => {
            setter(icon);
            handleClose();
          }}
        />
      </Menu>}
    </div>
  );
}

const icons = ["trophy", "book", "10", "3", "5", "check", "ribbon", "broadcast", "film", "photos", "livevideo", "email", "recipes", "tv", "store", "news", "video", "workout", "save", "save_fill", "saved-icons-01a", "saved-icons-01b", "saved-icons-02a", "saved-icons-02b", "saved-icons-03a", "saved-icons-03b", "saved-icons-04a", "saved-icons-04b", "saved-icons-05a", "saved-icons-05b", "saved-icons-06a", "saved-icons-06b", "saved-icons-07a", "saved-icons-07b", "saved-icons-08a", "saved-icons-08b", "search", "search-icons-01", "search-icons-02", "search-icons-03", "search-icons-04", "search-icons-05", "search-icons-06", "search-icons-07", "search-icons-08", "lock-icon", "subscribe", "subscribe-icons-01", "subscribe-icons-02", "subscribe-icons-03", "subscribe-icons-04", "subscribe-icons-05", "subscribe-icons-06", "settings", "settings-icons-01", "settings-icons-02", "settings-icons-03", "settings-icons-04", "settings-icons-05", "settings-icons-06", "settings-icons-07", "settings-icons-08", "settings-icons-09", "settings-icons-10", "misc-icons-01", "misc-icons-02", "misc-icons-03", "misc-icons-04", "misc-icons-05", "misc-icons-06", "misc-icons-07"];

const IconPaper = ({ fullUrl, onChange, setError, onUpload }) => {
  const classes = useStyles();
  const inputRef = React.useRef(null);
  const client = useApolloClient();

  const onProgress = () => {};

  async function validations(file) {
    setError && setError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (dimensions.height !== 174 || dimensions.width !== 174) {
      setError && setError("Invalid dimensions");
      return false;
    } else {
      return true;
    }
  }

  function handleUpload(response) {
    Boolean(onUpload) && onUpload(response);
  }

  const onInputChange = async (ev) => {
    let file = ev.target.files[0];

    const isValid = await validations(file);
    if (isValid) {
      upload(client, file, handleUpload, onProgress);
    }
  };

  return (
    <div className={classes.iconPaper}>
      {icons.map((name) => (
        <Box
          key={`IconPaper-${name}`}
          className={classes.iconBox}
          m={1}
          style={{ width: '3rem', height: '3rem', display: "flex", alignItems: 'center', justifyContent: 'center' }}
          onClick={() => onChange(name)}
        >
          <img height="32" width="32" src={fullUrl(name)} alt={name} style={{cursor:"pointer"}}/>
        </Box>
      ))}
      <IconButton
        style={{ margin: 8 }}
        onClick={() => inputRef.current.click()}
        size="large"><AddIcon /></IconButton>
      <Input
        inputRef={inputRef}
        type="file"
        style={{ position: 'absolute', height: 0, width: 0, opacity: 0 }}
        onChange={onInputChange}
      />
    </div>
  );
}
