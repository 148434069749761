import { useMutation, useQuery } from '@apollo/client';
import { GET_CURRENT_APP_PROPS, GET_TIER, GET_TIERS, GET_BUNDLE_TIERS, UPDATE_TIER } from 'api';
import React from 'react'
import { useParams } from 'react-router-dom';
import { getBundleType, getBundleData } from "helpers/bundleUtils";
import DataGrid from "components/atoms/DataGrid";
import clsx from "clsx";
import FormLayout from "components/molecules/Form/FormLayout";
import Confirm from 'components/molecules/Dialog/Confirm/Confirm';
import ContentLayout from 'screens/Layout/ContentLayout';
import BundleSelector from 'components/molecules/Modal/BundleSelector';
import AssetModal from "components/molecules/Modal/AssetModal";
import _ from 'lodash';

const columns = [
  {
    valueGetter : (params) => getBundleData(params.data)?.title,
    headerName: "Name",
    width: 650,
    sortable:true,
    sort:"asc"
  },
  {
    field: "category",
    headerName: "Kind",
    width: 100,
    valueGetter: (params) => getBundleType(params.data.type),
    flex: 1,
    filter: true,
    sortable:true
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 50,
    cellRenderer: "actionCellRenderer",
    flex: 1,
  },
];

const subscriptionFilters = [
  "Bundle::Collection::Manual", "Bundle::Catalog::Series",
  "Bundle::Catalog::LimitedSeries", "Bundle::Catalog::Movie",
  "Bundle::Catalog::Event", "Bundle::Playlist::Manual",
  "Bundle::Playlist::Search", "Bundle::Playlist::Feed"
];
const eventFilters = [ "Bundle::Catalog::Event" ];
const registrationFilters = subscriptionFilters;
const purchaseFilters = subscriptionFilters.filter((f) => !eventFilters.includes(f));
const rentalFilters = subscriptionFilters.filter((f) => !eventFilters.includes(f));

const filters = {
  "Tier::Subscription": subscriptionFilters,
  "Tier::Purchase": purchaseFilters,
  "Tier::Rental": rentalFilters,
  "Tier::Event": eventFilters,
  "Tier::Registration": registrationFilters,
};

function List(props) {
  const { tierId } = useParams();
  const {name, monetizationPath, ...otherProps} = props;

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data: tierDetails, loading, refetch } = useQuery(GET_BUNDLE_TIERS, { variables: { ...appProps, id: tierId } });

  const [showModal, setShowModal] = React.useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);

  const [showAssetIdModal, setShowAssetIdModal] = React.useState(null);
  const closeAssetIdModal = () => setShowAssetIdModal(null);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/${monetizationPath}`;

  const [updateTier] = useMutation(UPDATE_TIER, {
    update(cache, { data: { updateTier } }) {
      cache.modify({
        fields: {
          tiers(existingRefs, { readField }) {
            return existingRefs.map((ref) => (
              ref.id === updateTier.tier.id
              ? { ...ref, ...updateTier.tier }
              : ref
            ));
          },
        },
      });
    },
    onCompleted(data) {
      refetch()
      setShowModal(false);
      setDeleteConfirmation(null)
    },
    onError(data) {
      console.log(data);
    },
  });

  if (loading) {
    return null;
  }

  const onDelete = (row) => {
    setDeleteConfirmation(row);
  }

  const onDeleteConfirmation = () => {
    updateTier({
      variables: {
        ...appProps,
        tierId: parseInt(tierDetails.tier.id),
        tierFields: {
          bundleTiersAttributes: [{ id: deleteConfirmation.bundleTierId, _destroy: '1' }]
        }
      }
    })
  }

  const onBundleSelect = (bundles) => {
    setShowModal(false);
    if (!bundles.length) return
    const oldBundles = tierDetails?.tier?.bundleTiers.map(bt => bt.bundle.id) || [];
    const filteredBundles = bundles.filter(b => !oldBundles.includes(b.id))
    updateTier({
      variables: {
        ...appProps,
        tierId: parseInt(tierDetails.tier.id),
        tierFields: {
          bundleTiersAttributes: filteredBundles.map(b => ({ bundleId: b.id })),
        }
      }
    });
  }

  const onAssetId = (bt, ev) => {
    ev && ev.preventDefault();
    setShowAssetIdModal(bt);
  };

  const handleAssetId = (val) => {
    updateTier({
      variables: {
        ...appProps,
        tierId: parseInt(tierDetails.tier.id),
        tierFields: {
          bundleTiersAttributes: [{ id: showAssetIdModal.bundleTierId, thirdPartyId: val }],
        }
      }
    }).then(data => {
      closeAssetIdModal();
    });
  };

  const isSubscriptionType = tierDetails?.tier?.type === "Tier::Subscription";

  return (
    <ContentLayout
      title={name}
      fab={{ onClick: () => { setShowModal(true) } }}
    >
      <FormLayout
        breadcrumbs={[
          { name: name, link: listUrl },
          { name: tierDetails?.tier.title },
        ]}
      >
        <DataGrid
          rowData={tierDetails?.tier?.bundleTiers.map((ft) => {
            return {
              bundleTierId: ft.id,
              thirdPartyId: ft.thirdPartyId,
              ...ft.bundle,
              actions: {
                onDelete,
                onAssetId: isSubscriptionType ? null : onAssetId,
              }
            }
          }) || []}
          columnDefs={columns}
          accentedSort={true}
        />

        <BundleSelector
          open={showModal}
          onClose={onBundleSelect}
          filters={filters[tierDetails?.tier?.type]}
          multiple={true}
          selectedBundles={tierDetails?.tier?.bundleTiers.map(bt => bt.bundle)}
          freezeAlreadySelected
        />

        <Confirm
          open={!!deleteConfirmation}
          handleClose={() => { setDeleteConfirmation(null) }}
          accept={onDeleteConfirmation}
          acceptLabel={'Remove'}
          content={'This bundle will be removed from the tier. Are you sure you want to proceed?'}
        />

        {Boolean(showAssetIdModal) && (
          <AssetModal
            open={Boolean(showAssetIdModal)}
            onClose={closeAssetIdModal}
            submit={handleAssetId}
            assetId={showAssetIdModal.thirdPartyId}
          />
        )}
      </FormLayout>
    </ContentLayout>
  )
}

export default List
