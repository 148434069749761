import { useMutation } from '@apollo/client';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { formattedPhoneNumber } from 'helpers/phoneUtils';

import { SEND_VERIFICATION_CODE, VERIFY_CODE, CREATE_WORKSPACE, UPDATE_WORKSPACE, CREATE_APP, UPDATE_APP } from 'api';
import PhoneInput from 'components/atoms/Input/Phone';
import OtpInput from 'components/atoms/Input/Otp';
import Input from 'components/atoms/Input/BaseInput';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(4, 8),
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
  },
  form: {
    padding: 32,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Onboarding({ me, refetch }) {
  const classes = useStyles();

  const [ phone, setPhone ] = React.useState(me.phone);
  const [ codeSent, setCodeSent ] = React.useState(false);
  const [ otp, setOtp ] = React.useState(null);
  const [ cleared, setCleared ] = React.useState(false);
  const [ codeError, setCodeError ] = React.useState(null);
  const [ workspace, setWorkspace ] = React.useState(me.workspaces[0] || {});
  const [ app, setApp ] = React.useState(workspace.apps ? workspace.apps[0] || {} : {});
  const [ workspaceError, setWorkspaceError ] = React.useState(null);
  const [ appError, setAppError ] = React.useState(null);

  const [activeStep, setActiveStep] = React.useState(me.phoneVerified ? (workspace.id ? 2 : 1) : 0);
  const steps = ['Contact information', 'Create organization', 'Create brand'];

  const [ sendCode ] = useMutation(SEND_VERIFICATION_CODE, {
    variables: { phone: phone },
    onCompleted(data) {
      if (data.sendVerificationCode.success) {
        setCodeError(null);
        setCodeSent(true);
      } else {
        setCodeError("Error sending one time code. Please check the number you've entered or your account may have been locked.")
      }
    },
    onError(data) {
      console.log(data);
    }
  });

  const [ verifyCode ] = useMutation(VERIFY_CODE, {
    variables: { phone: phone, code: otp },
    onCompleted(data) {
      if (data.verifyCode.success) {
        setActiveStep(1);
      } else {
        setCleared(true);
        setCodeError("Incorrect code entered")
      }
    },
    onError(data) {
      console.log(data);
    }
  });

  const handleWorkspaceError = (data) => {
    if (data.message.includes("has already been taken")) {
      setWorkspaceError("This name is already taken. Please try a different name.")
    }
  }

  const handleAppError = (data) => {
    setAppError(data.message);
  }

  const [ createWorkspace ] = useMutation(CREATE_WORKSPACE, {
    variables: { name: workspace.name },
    onCompleted(data) {
      setWorkspace(data.createWorkspace.workspace);
      if (data.createWorkspace.workspace.apps[0]) {
        setApp(data.createWorkspace.workspace.apps[0]);
      }
      setActiveStep(2);
    },
    onError(data) {
      handleWorkspaceError(data);
    }
  });

  const [ updateWorkspace ] = useMutation(UPDATE_WORKSPACE, {
    variables: { id: workspace.id, workspaceFields: { name: workspace.name } },
    onCompleted(data) {
      setWorkspace(data.updateWorkspace.workspace);
      setActiveStep(2);
    },
    onError(data) {
      handleWorkspaceError(data);
    }
  });

  const [ createApp ] = useMutation(CREATE_APP, {
    variables: { workspaceId: workspace.id, name: app.name },
    onCompleted(data) {
      setApp(data.createApp.app);
      refetch();
    },
    onError(data) {
      handleAppError(data);
    }
  });

  const [ updateApp ] = useMutation(UPDATE_APP, {
    variables: { workspaceId: workspace.id, id: app.id, appFields: { name: app.name } },
    onCompleted(data) {
      setApp(data.updateApp.app);
      refetch();
    },
    onError(data) {
      handleAppError(data);
    }
  });

  React.useEffect(() => {
    if (activeStep === 0 && codeSent && otp) {
      verifyCode();
    }
  }, [activeStep, otp]);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleNext();
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (!codeSent && phone) {
        sendCode();
      }
    } else if (activeStep === 1) {
      if (Boolean(workspace.name)) {
        setWorkspaceError(null);
        workspace.id ? updateWorkspace() : createWorkspace();
      }
    } else if (activeStep === 2) {
      if (Boolean(app.name)) {
        setAppError(null);
        app.id ? updateApp() : createApp();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(step) {
    const errorMessage = (message) => <Typography style={{ marginTop: 8, fontSize: 12 }} color="secondary">{message}</Typography>;

    return (
      <div style={{ flexGrow: 1 }}>
        {step === 0 && (
          <>
            {codeSent
              ? <>
                  <OtpInput codeLength={4} cleared={cleared} setCleared={setCleared} setOtp={setOtp} />
                  {codeError
                    ? errorMessage(codeError)
                    : <Typography style={{ marginTop: 8 }} variant="body2">We sent a one time code to {formattedPhoneNumber(phone)}. <Link href="#" onClick={(ev) => { ev.preventDefault(); setCodeSent(false)}}>Change contact number</Link></Typography>
                  }
                </>
              : <>
                  <PhoneInput phone={phone} setPhone={setPhone} inputProps={{ autoFocus: true }} />
                  {errorMessage(codeError)}
                </>
              }
          </>
        )}
        {step === 1 && (
          <Input
            name="businessName"
            label="Business Name"
            placeholder="My Company Inc."
            defaultValue={workspace.name}
            onChange={(name) => setWorkspace({ ...workspace, name: name })}
            error={Boolean(workspaceError)}
            helperText={workspaceError}
            autoFocus
          />
        )}
        {step === 2 && (
          <Input
            name="appName"
            label="What's the name of your brand?"
            placeholder="Funny TV"
            defaultValue={app.name}
            onChange={(name) => setApp({ ...app, name: name })}
            error={Boolean(appError)}
            helperText={appError}
            autoFocus
          />
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="md" className={classes.container}>
        <Paper square className={classes.paper}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <form className={classes.form} onSubmit={handleSubmit}>
              {getStepContent(activeStep)}
              <div style={{ display: "flex", marginTop: 16 }}>
                {activeStep === 2 && (
                  <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                <div style={{ flexGrow: 1 }} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    activeStep === 0
                      ? (codeSent ? true : !Boolean(phone))
                      : activeStep === 1
                      ? !Boolean(workspace.name)
                      : !Boolean(app.name)}
                >
                  {activeStep === 0
                    ? (codeSent ? 'Verify' : 'Send verification code')
                    : activeStep === 1
                    ? 'Next'
                    : 'Finish'}
                </Button>
              </div>
            </form>
          )}
        </>
        </Paper>
      </Container>
    </div>
  );
}
