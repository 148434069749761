import React from "react";
import _ from "underscore";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import BundleIdInput from "components/atoms/Input/BundleId";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Tooltip from "components/atoms/Tooltip";
import { SwitchGroup } from "components/atoms/Input/Switch";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { AMAZON_ASSET_MAP } from "./constants";
import ContentLayout from "screens/Layout/ContentLayout";
import Link from "@mui/material/Link";
import LanguageText from "components/molecules/LanguageText";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import { PlatformApiKey } from "./List";

export default function Amazon(props) {
  const { stores, updateStoreApi, appProps, isMultiLangOrRegion } = props;

  const store = stores?.find((s) => s.name === "amazon");
  const platformProps = { store, appProps, updateStoreApi, isMultiLangOrRegion };

  return <ContentLayout title="Amazon">
    {store ? <Platform {...platformProps} /> : null}
  </ContentLayout>
}

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion } = props;
  const mobilePlatform = store.platforms?.find(
    (p) => p.type === "Platform::Amazon"
  );
  const tvPlatform = store.platforms?.find(
    (p) => p.type === "Platform::FireTv"
  );
  const { lang } = getDefaultLocaleAndLang(app);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store.metadata || {};
  const mobileMetadata = mobilePlatform.metadata || {};
  const tvMetadata = tvPlatform.metadata || {};

  const [accessGranted, setAccessGranted] = React.useState(
    storeMetadata.access_granted || false
  );

  const [appStoreName, setAppStoreName] = React.useState(
    storeMetadata.app_store_name || ""
  );
  const [mobileGaTrackingId, setMobileGaTrackingId] = React.useState(mobileMetadata.ga_tracking_id || "");
  const [tvGaTrackingId, setTvGaTrackingId] = React.useState(tvMetadata.ga_tracking_id || "");
  const [mobileBundleId, setMobileBundleId] = React.useState(mobileMetadata.bundle_id || "");
  const [tvBundleId, setTvBundleId] = React.useState(tvMetadata.bundle_id || "");
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))
  const [shortDescriptionLangObj, setShortDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.short_description))

  const saveTimer = React.useRef(null);

  const description = descriptionLangObj[lang] || "";
  const shortDescription = shortDescriptionLangObj[lang] || "";

  const [asset, setAsset] = React.useState({
    urls: {
      appIcon: mobilePlatform.assetUrls.app_icon,
      cover: mobilePlatform.assetUrls.covers || [],
      preview: mobilePlatform.assetUrls.previews || [],
      tvIcon: tvPlatform.assetUrls.app_icon,
      tvCover: tvPlatform.assetUrls.covers || [],
      storeBackground: tvPlatform.assetUrls.store_background,
    },
    blobs: {
      appIcon: null,
      cover: [],
      preview: [],
      tvIcon: null,
      tvCover: [],
      storeBackground: null,
    },
    errors: {
      appIcon: null,
      cover: null,
      preview: null,
      tvIcon: null,
      tvCover: null,
      storeBackground: null,
    },
  });

  const [mobileLastUploaded, setMobileLastUploaded] = React.useState(null);
  const [tvLastUploaded, setTvLastUploaded] = React.useState(null);
  const assetProps = { asset, setAsset, assetMap: AMAZON_ASSET_MAP };
  const apiKeys = [
    {name: "Amazon", apiKey: mobilePlatform.apiKey},
    {name: "FireTv", apiKey: tvPlatform.apiKey}
  ]

  React.useEffect(() => {
    if (
      asset.blobs.appIcon ||
      asset.blobs.cover.length > 0 ||
      asset.blobs.preview.length > 0
    ) {
      setMobileLastUploaded(new Date().getTime());
    }
  }, [asset.blobs.appIcon, asset.blobs.cover, asset.blobs.preview]);

  React.useEffect(() => {
    if (
      asset.blobs.tvIcon ||
      asset.blobs.storeBackground ||
      asset.blobs.tvCover.length > 0
    ) {
      setTvLastUploaded(new Date().getTime());
    }
  }, [asset.blobs.tvIcon, asset.blobs.tvCover, asset.blobs.storeBackground]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [tvLastUploaded, mobileLastUploaded, appStoreName, descriptionLangObj, shortDescriptionLangObj, accessGranted, mobileGaTrackingId, tvGaTrackingId, mobileBundleId, tvBundleId]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            metadata: {
              access_granted: accessGranted,
              app_store_name: appStoreName,
              description: descriptionLangObj,
              short_description: shortDescriptionLangObj,
            },
            platformsAttributes: [
              {
                id: mobilePlatform.id,
                metadata: {
                  bundle_id: mobileBundleId,
                  ga_tracking_id: mobileGaTrackingId,
                  last_uploaded: mobileLastUploaded,
                },
                assetBlobs: {
                  app_icon: asset.blobs.appIcon,
                  covers: asset.blobs.cover,
                  previews: asset.blobs.preview,
                },
              },
              {
                id: tvPlatform.id,
                metadata: {
                  bundle_id: tvBundleId,
                  ga_tracking_id: tvGaTrackingId,
                  last_uploaded: tvLastUploaded,
                },
                assetBlobs: {
                  app_icon: asset.blobs.tvIcon,
                  covers: asset.blobs.tvCover,
                  store_background: asset.blobs.storeBackground,
                },
              },
            ],
          },
        },
      });
    }
  };

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  const validateForm = () => {
    return true;
  };

  const langInput = (data, onSubmit, title, charLimit) => (<LanguageText
    data={data}
    onSubmit={onSubmit}
    languageOnly
    buttonProps={{style: {marginBottom: '32px'}}}
    title={title}
    charLimit={charLimit}
  />)

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl }, { name: "Amazon" }]}>
      <PaperGroup>
        <GroupHeader title="General" />
        <Tooltip title="This name is used only in the app store.">
          <Input
            label="App store name"
            defaultValue={appStoreName}
            onChange={setAppStoreName}
            inputProps={{ maxLength: 30 }}
            helperText={`${30 - appStoreName.length} - character limit`}
            required
            autoFocus
          />
        </Tooltip>

        <div style={{display: 'flex'}}>
          <Tooltip title="This is a description of your app that will be displayed in the app store. Keep your catchiest content in the first few lines to make sure it is visible to all visitors.">
            <Input
              label="Description"
              value={description}
              onChange={(val) => {onLangTextChange(val, setDescriptionLangObj)}}
              inputProps={{ maxLength: 4000 }}
              helperText={`${4000 - description.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description", 4000)}
        </div>

        <div style={{display: 'flex'}}>
          <Tooltip title="This is a shorter version of the app description, for use on mobile devices.">
            <Input
              label="Short Description"
              value={shortDescription}
              onChange={(val) => {onLangTextChange(val, setShortDescriptionLangObj)}}
              inputProps={{ maxLength: 1200 }}
              helperText={`${1200 - shortDescription.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(shortDescriptionLangObj, setShortDescriptionLangObj, "Short Description", 1200)}
        </div>
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Mobile / Tablet" />

        <ImageUploadGroup {...assetProps} name="appIcon" horizontal previewMaxHeight={128} />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="cover" horizontal previewMaxHeight={108} />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="preview" horizontal />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Fire TV" />

        <ImageUploadGroup {...assetProps} name="tvIcon" horizontal previewMaxHeight={128} />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="tvCover" horizontal previewMaxHeight={108} />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="storeBackground" horizontal previewMaxHeight={128} />
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaperGroup>
        <GroupHeader title="Developer Access" />

        <SwitchGroup
          label={(<>
            Admin access granted to devaccounts@mazsystems.com <Link href="https://help.mazsystems.com/en/developer-account-creation-access-and-maintenance" target="_blank">Learn more</Link>
          </>)}
          checked={accessGranted}
          onChange={setAccessGranted}
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <GroupHeader title="&nbsp;" caption="Mobile" />

        <GaTrackingIdInput
          label="Amazon Mobile Google Analytics Tracking ID"
          defaultValue={mobileGaTrackingId}
          onChange={setMobileGaTrackingId}
          required
        />

        <BundleIdInput
          label="Mobile App Bundle ID"
          defaultValue={mobileBundleId}
          onChange={setMobileBundleId}
          placeholder={`com.maz.tvod.${appProps.appId}.${mobilePlatform.id}`}
        />

        <GroupHeader title="&nbsp;" caption="Fire TV" />

        <GaTrackingIdInput
          label="Fire TV Google Analytics Tracking ID"
          defaultValue={tvGaTrackingId}
          onChange={setTvGaTrackingId}
          required
        />

        <BundleIdInput
          label="Fire TV App Bundle ID"
          defaultValue={tvBundleId}
          onChange={setTvBundleId}
          placeholder={`com.maz.tvod.${appProps.appId}.${tvPlatform.id}`}
        />
      </PaperGroup>
    </FormLayout>
  );
};
