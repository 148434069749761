import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";

 function CopyToClipboard(props) {
  const { style, children, copyTip, copyText, onCopy, savedSnack, ...otherProps } = props;

  const CopyToClip = (copyText) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(copyText)
      .then(() => {
        onCopy();
      });
    }
  }

  return (
    <Tooltip  title={copyTip || "Copy URL"} aria-label="copy">
      <IconButton onClick={ ()=> CopyToClip(copyText) } size="large">
          <FileCopyIcon />
        </IconButton>
    </Tooltip>
  );
}


export default CopyToClipboard;