import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import Attributes from "./Attributes";

export default function Content(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;
  const commonProps = { savedSnack };

  return (
    <Switch>
      <Route path={`${path}/attributes`}>
        <Attributes {...commonProps} />
      </Route>
      <Redirect to={`${path}/attributes`} />
    </Switch>
  );
}
