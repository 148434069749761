import { useQuery } from "@apollo/client";
import { GET_CURRENT_APP } from "api";
import Select from "components/atoms/Input/Select";
import { SwitchGroup } from "components/atoms/Input/Switch";
import GroupHeader from "components/atoms/Text/GroupHeader";
import { LANGUAGE_MAP } from "helpers/langUtils";
import React from "react";
import { LocaleSelector } from "./Modal/MenuFormModal";
import _ from 'lodash';
import { withLocaleLang } from "components/containers/withLocaleLang";
import DividerAtom from "components/atoms/Divider";
import Error from "components/atoms/Error";
import PaperGroup from "./Form/PaperGroup";

/**
 *
 * @param {Object} props
 * @param {[number]|null} props.locales - locales ids or null for all locales
 * @param {Function} props.setLocales - callback to set locale ids
 * @param {string} props.language - language
 * @param {Function} props.setLanguage - callback to set language
 * @returns
 */
function LocaleOptions(props) {
  const { locales, setLocales, withLanguage=false, language, setLanguage, divider, errorText, wrapPaper, isMultiLangOrRegion } = props;
  const [localeIds, setLocaleIds] = React.useState(null || locales);
  const [allLocales, setAllLocales] = React.useState(false);

  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;

  const availableLocales = React.useMemo(() => (allLocales || !Boolean(localeIds) ? app.locales : app.locales.filter(l => localeIds.includes(l.id))),[allLocales, localeIds])  ;
  const languages = _.uniq(_.flatten(availableLocales.map(l => l.languages)));
  const langOptions = [...languages.map(l => ({ label: LANGUAGE_MAP[l], value: l }))]


  React.useEffect(() => {
    if (!localeIds || app?.locales.every((l) => localeIds.includes(l.id))) {
      setAllLocales(true);
      setLocaleIds(null);
      setLocales(null);
    }
    if (localeIds && !localeIds.length) {
      setLanguage && setLanguage(null);
    }
  }, [localeIds]);

  React.useEffect(() => {
    if (allLocales) {
      setLocaleIds(null);
      setLocales(null);
    }
  }, [allLocales]);

  React.useEffect(() => {
    if (!language && langOptions.length) {
      setLanguage && setLanguage(langOptions[0].value)
    }
  }, [language, localeIds])

  const onLocalesChange = (ids) => {
    setLocaleIds(ids);
    setLocales(ids);
  };

  if (!isMultiLangOrRegion) return null;
  
  const localeForm = <>
      <GroupHeader title="Regions" />
      <SwitchGroup
        checked={allLocales}
        onChange={setAllLocales}
        label="All Locales"
        disabled={false}
      />

      {!allLocales && (
        <LocaleSelector
          locales={app?.locales}
          localeIds={localeIds}
          setLocaleIds={onLocalesChange}
        />
      )}
      {withLanguage &&
        <Select
          options={langOptions}
          value={language }
          onChange={setLanguage}
        />
      }
      {errorText ? <Error>{errorText}</Error> : null}
      {divider ? <DividerAtom />: null}
    </>

  return (
    wrapPaper
    ? <PaperGroup>{localeForm}</PaperGroup>
    : localeForm
  );
}

export default withLocaleLang(LocaleOptions);
