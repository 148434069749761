import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import New from "./New";
import Edit from "./Edit";
import List from "./List";
import { withDesign } from 'screens/Hubs/Design/Design/Design';

function Onboarding(props) {
  const { path } = useRouteMatch();
  const { savedSnack, design } = props;
  const [ defaultLocale, setDefaultLocale ] = React.useState({});

  const commonProps = { savedSnack, design, defaultLocale, setDefaultLocale };

  return (
    <Switch>
      <Route exact path={`${path}/:designId/new`}><New {...commonProps} /></Route>
      <Route exact path={`${path}/:designId/edit/:id`}><Edit {...commonProps} /></Route>
      <Route path={path}><List {...commonProps} /></Route>
    </Switch>
  );
}

export default withDesign(Onboarding)
