import { useQuery } from "@apollo/client";
import { GET_CURRENT_APP_PROPS, GET_INTEGRATIONS } from "api";
import GroupHeader from "components/atoms/Text/GroupHeader";
import { INTEGRATION_PATH } from "helpers/urlConstants";
import React from "react";
import { Link } from "react-router-dom";

function Cleeng(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_INTEGRATIONS, { variables: appProps });
  const integrations = data?.integrations;
  const activeCleeng = integrations?.some(
    (i) => i.sso && i.isActive && i.name === "Cleeng"
  );
  const link = `/${appProps.workspaceId}/${appProps.appId}/${INTEGRATION_PATH}`;

  return (
    <>
      <GroupHeader title="Cleeng" />
      {activeCleeng ? (
        <div>
          <b>Status: </b>Active <br />
        </div>
      ) : (
        <Link to={link}>Please create or activate a cleeng integration</Link>
      )}
    </>
  );
}

export default Cleeng;
