import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import urlJoin from "url-join";
import _ from "underscore";

import {
  GET_LIVE_STREAMS,
  GET_CURRENT_APP_PROPS,
  DELETE_LIVE_STREAM,
} from "api";
import DataGrid from "components/atoms/DataGrid";
import FormLayout from "components/molecules/Form/FormLayout";
import { withRedirect } from "components/containers/Redirect";
import ConfirmDialog from "components/molecules/Dialog/Confirm/LiveStream";
import ContentLayout from "screens/Layout/ContentLayout";

const columns = [
  {
    field: "title",
    headerName: "Name",
    width: 400,
    filter: true,
    cellRenderer: 'titleCellRenderer',
    sortable:true,
    sort: 'asc'
  },
  {
    field: "status",
    headerName: "Status",
    width: 150,
    filter: true,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    flex:1,
    minWidth: 300
  },
];

function LiveStreams(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_LIVE_STREAMS, { variables: appProps });
  const { redirect } = props;
  const { path } = useRouteMatch();
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);

  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  const onEdit = (liveStream) => {
    redirect.set(urlJoin(path.replace(":workspaceId", appProps.workspaceId).replace(":appId", appProps.appId), `edit/${liveStream.id}`));
  };

  const [ deleteLiveStream ] = useMutation(DELETE_LIVE_STREAM, {
    update(cache, { data: { deleteLiveStream } }) {
      cache.modify({
        fields: {
          liveStreams(existingRefs, { readField }) {
            return existingRefs.filter((ref) => deleteLiveStream.liveStream.id !== readField("id", ref));
          },
        }
      })
    },
    onCompleted(data) {
      closeDeleteConfirmation();
    },
  });

  const deleteLiveStreamApi = (id) => {
    deleteLiveStream({
      variables: {
        ...appProps,
        id: id,
      }
    });
  };

  return (
    <ContentLayout
      title="Live Streams"
      fab={{ onClick: () => redirect.set(urlJoin(path.replace(":workspaceId", appProps.workspaceId).replace(":appId", appProps.appId), "new")) }}
    >
      <FormLayout>
        <DataGrid
          rowData={
            data?.liveStreams
              ? data.liveStreams.map((ls) => ({
                  ...ls,
                  actions: {
                    ...(ls.metadata.stream_url
                      ? {
                          onCopy: () => props.savedSnack.launch("Copied to clipboard"),
                          copyText: ls.metadata.stream_url,
                          copyTip: "Copy streaming URL",
                        }
                      : {}),
                    onEdit,
                    onDelete: () => setDeleteConfirmation(ls),
                  }
                }))
              : []
          }
          columnDefs={columns}
          accentedSort={true}
          immutableData={true}
          getRowNodeId={(data) => data.id}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => deleteLiveStreamApi(deleteConfirmation.id)}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(LiveStreams);
