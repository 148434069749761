import { InMemoryCache } from '@apollo/client';
import { makeVar } from '@apollo/client';

export const cache = new InMemoryCache({
  typePolicies: {
    LiveProgram: {
      keyFields: ["className", "id"],
    },
    Query: {
      fields: {
        getCurrentApp: {
          read() {
            return app();
          },
        },
        getCurrentWorkspace: {
          read() {
            return workspace();
          },
        },
        getCurrentAppProps: {
          read() {
            return { workspaceId: workspace().id, appId: app().id };
          }
        },
      },
    },
  },
});

export const workspace = makeVar({});
export const app = makeVar({});
