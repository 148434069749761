import Layout from './Layout';

export default Layout;
export { drawerWidth } from './Layout';

export function withLayout(Component) {
  return (props) => {
    return (
      <Layout><Component {...props} /></Layout>
    );
  }
}
