import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Web from "./Web";
import Apple from "./Apple";
import Google from "./Google";
import Roku from "./Roku";
import Amazon from "./Amazon";
import Samsung from "./Samsung";
import Lg from "./Lg";
import Vizio from "./Vizio";
import Microsoft from "./Microsoft";
import ListPlatforms from "./List";
import { GET_CURRENT_APP_PROPS, GET_APP_STORES, UPDATE_APP_STORE, GET_CURRENT_APP } from "api";

import { withSavedSnack } from "../../../../components/molecules/Alerts/Saved";

function Platforms(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data } = useQuery(GET_APP_STORES, { variables: appProps });
  const { path } = useRouteMatch();

  const [updateStoreApi] = useMutation(UPDATE_APP_STORE, {
    update(cache, { data: { updateAppStore } }) {
      cache.modify({
        fields: {
          appStores(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateAppStore.appStore.id === readField("id", ref)
                ? { ...ref, ...updateAppStore.appStore }
                : ref
            );
          },
        },
      });
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const commonProps = {
    stores: data?.appStores,
    updateStoreApi,
    appProps,
    app,
    isMultiLangOrRegion: props.isMultiLangOrRegion,
    savedSnack: props.savedSnack
  };

  return (
    <Switch>
      <Route exact path={`${path}/web`}>
        <Web name="web" title="Web" {...commonProps} />
      </Route>
      <Route exact path={`${path}/apple`}>
        <Apple name="apple" title="Apple" {...commonProps} />
      </Route>
      <Route exact path={`${path}/google`}>
        <Google name="google" title="Google" {...commonProps} />
      </Route>
      <Route exact path={`${path}/roku`}>
        <Roku name="roku" title="Roku" {...commonProps} />
      </Route>
      <Route exact path={`${path}/amazon`}>
        <Amazon name="amazon" title="Amazon" {...commonProps} />
      </Route>
      <Route exact path={`${path}/samsung`}>
        <Samsung name="samsung" title="Samsung" {...commonProps} />
      </Route>
      <Route exact path={`${path}/lg`}>
        <Lg name="lg" title="LG" {...commonProps} />
      </Route>
      <Route exact path={`${path}/vizio`}>
        <Vizio name="vizio" title="Vizio" {...commonProps} />
      </Route>
      <Route exact path={`${path}/microsoft`}>
        <Microsoft name="microsoft" title="Microsoft" {...commonProps} />
      </Route>
      <Route exact path={`${path}`}>
        <ListPlatforms {...commonProps} />
      </Route>
    </Switch>
  );
}

export default withSavedSnack(Platforms);
