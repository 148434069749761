import React from "react";

import Input from "components/atoms/Input/BaseInput";
import HelpText from "components/atoms/Text/Help";
import { SwitchGroup } from "components/atoms/Input/Switch";
import { INTEGRATION_OAUTH, OAUTH_PASSWORD } from "../constants";

export default function Cleeng(props) {
  const { integration, setForm, setIsFormChanged } = props;
  const grant = integration?.oauthGrants?.find((g) => g.type === OAUTH_PASSWORD) || {};

  const [ clientId, setClientId ] = React.useState(integration.metadata?.client_id || "");
  const [ clientSecret, setClientSecret ] = React.useState(integration.metadata?.client_secret || "");
  const [ sandbox, setSandbox ] = React.useState(integration.metadata?.template === "CleengSandbox" || false);
  const [ offerid, setOfferid ] = React.useState(integration.metadata?.offer_id || "")

  React.useEffect(() => {
    setForm({
      type: INTEGRATION_OAUTH,
      clientId,
      clientSecret,
      template: sandbox ? "CleengSandbox" : "Cleeng",
      metadataExtension: {
        offer_id: offerid
      }
    });
    setIsFormChanged(true);
  }, [ clientId, clientSecret, sandbox, offerid ]);

  React.useEffect(() => {
    setIsFormChanged(false);
  },[]);

  return (
    <>
      <SwitchGroup
        label="Sandbox Env"
        checked={sandbox}
        onChange={setSandbox}
      />

      <Input
        label="Publisher ID"
        value={clientId}
        onChange={setClientId}
        required
      />

      <Input
        label="Publisher API Key"
        value={clientSecret}
        onChange={setClientSecret}
        required
      />

      <Input
        label="Offer Id"
        value={offerid}
        onChange={setOfferid}
        required
      />

      <HelpText>This integration requires an enterprise account with Cleeng.</HelpText>
    </>
  );
}
