import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import List from '../Transactions/List';
import New from '../Transactions/New';
import Edit from '../Transactions/Edit';
import BundleList from '../Transactions/bundle/List';
import { useQuery } from "@apollo/client";
import { GET_CURRENT_APP_PROPS } from "api";

function Subscriptions(props) {
  const { path } = useRouteMatch();

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const tabProps = { ...props, appProps };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <New {...tabProps} name="Subscriptions" filter="Subscription" monetizationPath="subscriptions"/>
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <Edit {...tabProps} name="Subscriptions" filter="Subscription" monetizationPath="subscriptions"/>
      </Route>
      <Route exact path={`${path}/:tierId/list`}>
        <BundleList {...tabProps} name="Subscriptions" filter="Subscription" monetizationPath="subscriptions"/>
      </Route>
      <Route path={`${path}`}>
        <List {...tabProps} name="Subscriptions" filter="Subscription" monetizationPath="subscriptions"/>
      </Route>
    </Switch>
  );
}

export default Subscriptions;
