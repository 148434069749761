import React from 'react';
import BaseInput from './BaseInput';

export default function Email(props) {
  return (
    <BaseInput
      id="email"
      label="Email Address"
      name="email"
      autoComplete="email"
      {...props}
    />
  );
};
