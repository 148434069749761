import React, {useEffect, useState} from "react";
import _ from 'underscore';

import Layout from "./Layout";
import ColorPicker from 'components/atoms/Input/ColorPicker';
import LanguageText from "components/molecules/LanguageText";
import GroupHeader from 'components/atoms/Text/GroupHeader';
import Input from 'components/atoms/Input/BaseInput';
import BackgroundGroup from "components/molecules/BackgroundGroup";
import Select, { BooleanSelect } from 'components/atoms/Input/Select';

import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import { withDesign } from './Design';
import { generateLocalesForDesigns } from "helpers/langUtils";

function Form(props) {
  const { simulator, designs, isMultiLangOrRegion } = props;
  const design = simulator.design;

  const title = "Welcome Again";
  const summary = "Watch TV shows & movies anytime, anywhere";
  const logoUrl = design.tvLogoUrl;

  function getTextLocales() { return generateLocalesForDesigns(designs, ["onboardingButtonTitle"]) };
  const locales = getTextLocales();
  const defaultLocale = { locale: design.locale.id,lang: design.language };

  const [ textLocales, setTextLocales ] = useState(locales);
  const onboardingButtonTitle = textLocales[defaultLocale.locale][defaultLocale.lang]["onboardingButtonTitle"];

  const [ onboardingWall, setOnboardingWall ] = useState(design.onboardingWall);
  const [ onboardingAllowSkip, setOnboardingAllowSkip ] = useState(design.onboardingAllowSkip);
  const [ onboardingAction, setOnboardingAction ] = useState(design.onboardingAction);
  const [ onboardingButtonColor, setOnboardingButtonColor ] = useState(design.onboardingButtonColor);
  const [ onboardingButtonTitleColor, setOnboardingButtonTitleColor ] = useState(design.onboardingButtonTitleColor);
  const [ onboardingTitleColor, setOnboardingTitleColor ] = useState(design.onboardingTitleColor);
  const [ onboardingDescriptionColor, setOnboardingDescriptionColor ] = useState(design.onboardingDescriptionColor);

  const [isFormChanged, setIsFormChanged] = useState(false);
  const [disableForm, setDisableForm] = useState(false);

  const [ onboardingPageControlSelectedColor, setOnboardingPageControlSelectedColor ] = useState(design.onboardingPageControlSelectedColor);
  const [ onboardingPageControlUnselectedColor, setOnboardingPageControlUnselectedColor ] = useState(design.onboardingPageControlUnselectedColor);
  const [ onboardingScreenBackground, setOnboardingScreenBackground ] = useState(design.onboardingScreenBackground);


  useEffect(() => {
    setIsFormChanged(true);
  }, [onboardingWall,
      onboardingAllowSkip,
      onboardingAction,
      onboardingButtonColor,
      onboardingButtonTitleColor, 
      onboardingTitleColor,
      onboardingDescriptionColor, 
      onboardingPageControlSelectedColor,
      onboardingScreenBackground, 
      textLocales]);

  useEffect(() => {
    setTimeout(()=>setIsFormChanged(false),0);
  }, [])
  
  const cancel = () => {
    setOnboardingWall(design.onboardingWall);
    setOnboardingAllowSkip(design.onboardingAllowSkip);
    setOnboardingAction(design.onboardingAction);
    setOnboardingButtonColor(design.onboardingButtonColor);
    setOnboardingButtonTitleColor(design.onboardingButtonTitleColor);
    setOnboardingTitleColor(design.onboardingTitleColor);
    setOnboardingDescriptionColor(design.onboardingDescriptionColor);
    setOnboardingPageControlSelectedColor(design.onboardingPageControlSelectedColor);
    setOnboardingScreenBackground(design.onboardingScreenBackground);
    setTextLocales(locales);
    setTimeout(()=>setIsFormChanged(false),100);
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);
    
  const commonFields = {
    onboardingWall,
    onboardingAllowSkip,
    onboardingButtonTitle,
    onboardingAction,
    onboardingTitleColor,
    onboardingDescriptionColor,
    onboardingPageControlSelectedColor,
    onboardingPageControlUnselectedColor,
    onboardingButtonColor,
    onboardingButtonTitleColor,
  };

  const stateDesign = {
    ...commonFields,
    onboardingScreenBackground: _.omit(onboardingScreenBackground, "assetBlobId"),
    screen: {
      title,
      summary,
      logoUrl
    }
  };

  const stateForm = {
    ...commonFields,
    textLocales,
    onboardingScreenBackgroundAttributes: _.omit(onboardingScreenBackground, "assetUrl", "__typename"),
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm,
    simulator,
    tab: "Onboarding",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  function onLangInputChange(val, key) {
    const textLocaleDuplicate = {...textLocales}
    textLocaleDuplicate[defaultLocale.locale][defaultLocale.lang][key] = val;
    setTextLocales(textLocaleDuplicate);
  }

  function multiText(title, key, charLimit = 0, buttonMargin) {
    const buttonProps = buttonMargin
      ? { style: { marginBottom: buttonMargin } }
      : {};
    return (
      <LanguageText
        onSubmit={setTextLocales}
        data={textLocales}
        property={{ key, charLimit }}
        buttonProps={buttonProps}
        title={title}
      />
    );
  }

  const buttonColorInput =
    <ColorPicker
      getter={onboardingButtonColor}
      setter={setOnboardingButtonColor}
      label="Button Color"
      disabled={disableForm}
    />;
  const buttonTitleColorInput =
    <ColorPicker
      getter={onboardingButtonTitleColor}
      setter={setOnboardingButtonTitleColor}
      label="Button Text Color"
      disabled={disableForm}
    />;
  const titleColorInput =
    <ColorPicker
      getter={onboardingTitleColor}
      setter={setOnboardingTitleColor}
      label="Title Color"
      disabled={disableForm}
    />;
  const subtitleColorInput =
    <ColorPicker
      getter={onboardingDescriptionColor}
      setter={setOnboardingDescriptionColor}
      label="Subtitle Color"
      disabled={disableForm}
    />;
  const screenBackground =
    <BackgroundGroup
      getter={onboardingScreenBackground}
      setter={setOnboardingScreenBackground}
      basicBlack
      supportedImageFormats={['jpg']}
      disabled={disableForm}
      noVideo
    />;
  const allowSkipInput =
    <BooleanSelect
      value={onboardingAllowSkip}
      onChange={setOnboardingAllowSkip}
      label="Allow Skip"
      disabled={disableForm}
      required
    />;
  const onboardingWallInput =
    <BooleanSelect
      value={onboardingWall}
      onChange={setOnboardingWall}
      label="Onboarding Wall"
      helperText={<>The last screen of the onboarding will persist when the user is not logged in. <br /><br/>This setting can be used to enforce the user to log in before entering the app.</>}
      disabled={disableForm}
      required
      richFormat
    />;  

  const header = <GroupHeader title="Last screen settings" caption="These settings are available on the final onboarding screen only."/>
  const buttonTitleInput =
    <div style={{display: 'flex'}}>
      <Input
        label="Button Label"
        value={onboardingButtonTitle}
        onChange={val => {onLangInputChange(val, "onboardingButtonTitle")}}
        required
        disabled={isMultiLangOrRegion || disableForm}
      />
      {multiText("Button Label", "onboardingButtonTitle", 0, "8px")}
    </div>
  const buttonAction =
    <Select
      value={onboardingAction}
      onChange={setOnboardingAction}
      options={[
        { label: "Start App", value: "default" },
        { label: "Register", value: "register" },
      ]}
      label="Button Action"
      disabled={disableForm}
      required
    />;

  React.useEffect(() => {
    if (onboardingButtonColor) {
      setOnboardingPageControlSelectedColor(onboardingButtonColor);
    }
  }, [onboardingButtonColor]);

  return (
    <Layout {...layoutProps}>
      {buttonColorInput}
      {buttonTitleColorInput}
      {titleColorInput}
      {subtitleColorInput}
      {screenBackground}
      {allowSkipInput}
      {!onboardingAllowSkip && onboardingWallInput}
      {header}
      {buttonTitleInput}
      {buttonAction}
    </Layout>
  );
}

export default withDesign(withSimulator(Form));
