import React from "react";
import _ from "underscore";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import BundleIdInput from "components/atoms/Input/BundleId";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Divider from "components/atoms/Divider";
import Tooltip from "components/atoms/Tooltip";
import { SwitchGroup } from "components/atoms/Input/Switch";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { GOOGLE_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import Password from "components/atoms/Input/Password";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import LanguageText from "components/molecules/LanguageText";
import { PlatformApiKey } from "./List";

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion } = props;

  const androidPlatform = store?.platforms?.find(
    (p) => p.type === "Platform::Android"
  );
  const androidTvPlatform = store?.platforms?.find(
    (p) => p.type === "Platform::AndroidTv"
  );
  const { lang } = getDefaultLocaleAndLang(app);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store.metadata || {};
  const androidMetadata = androidPlatform.metadata || {};
  const androidTvMetadata = androidTvPlatform.metadata || {};

  const [accessGranted, setAccessGranted] = React.useState(
    storeMetadata.access_granted || false
  );
  const [storePassword, setStorePassword] = React.useState(
    storeMetadata.store_password || ""
  );
  const [keyAlias, setKeyAlias] = React.useState(storeMetadata.key_alias || "");
  const [keyPassword, setKeyPassword] = React.useState(
    storeMetadata.key_password || ""
  );
  const [shaKey, setShaKey] = React.useState(storeMetadata.sha_key || "");
  const [licenseKey, setLicenseKey] = React.useState(
    storeMetadata.license_key || ""
  );
  const [firebaseApiKey, setFirebaseApiKey] = React.useState(
    storeMetadata.firebase_api_key || ""
  );
  const [firebaseServiceJson, setFirebaseServiceJson] = React.useState(
    storeMetadata.firebase_service_json || "{}"
  );

  const [appStoreName, setAppStoreName] = React.useState(
    storeMetadata.app_store_name || ""
  );
  // const [description, setDescription] = React.useState(
  //   storeMetadata.description || ""
  // );
  // const [shortDescription, setShortDescription] = React.useState(
  //   storeMetadata.short_description || ""
  // );
  const [promoVideoUrl, setPromoVideoUrl] = React.useState(
    storeMetadata.promo_video_url || ""
  );
  const [developerWebsite, setDeveloperWebsite] = React.useState(storeMetadata.developer_website || "");
  const [releaseNotes, setReleaseNotes] = React.useState(storeMetadata.release_notes || "");

  const [androidGaTrackingId, setAndroidGaTrackingId] = React.useState(
    androidMetadata.ga_tracking_id || ""
  );
  const [androidTvGaTrackingId, setAndroidTvGaTrackingId] = React.useState(
    androidTvMetadata.ga_tracking_id || ""
  );
  const [bundleId, setBundleId] = React.useState(androidMetadata.bundle_id || "");
  const saveTimer = React.useRef(null);
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))
  const [shortDescriptionLangObj, setShortDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.short_description))
  const description = descriptionLangObj[lang] || "";
  const shortDescription = shortDescriptionLangObj[lang] || "";
  const apiKeys = [
    {name: "Android", apiKey: androidPlatform.apiKey},
    {name: "Android TV", apiKey: androidTvPlatform.apiKey},
  ]

  const [asset, setAsset] = React.useState({
    urls: {
      appIcon: androidPlatform.assetUrls.app_icon,
      cover: androidPlatform.assetUrls.covers || [],
      promoGraphic: androidPlatform.assetUrls.promo_graphic,
      featureGraphic: androidPlatform.assetUrls.feature_graphic,
      notificationIcon: androidPlatform.assetUrls.notification_icon,
      tvIcon: androidTvPlatform.assetUrls.app_icon,
      tvFeatureGraphic: androidTvPlatform.assetUrls.feature_graphic,
      tvBanner: androidTvPlatform.assetUrls.banner,
      tvSmallBanner: androidTvPlatform.assetUrls.small_banner,
      tvCover: androidTvPlatform.assetUrls.covers || [],
      keyStore: store.assetUrls.key_store,
      serviceAccountKeyJson: store.assetUrls.service_account_key_json,
    },
    blobs: {
      appIcon: null,
      cover: [],
      promoGraphic: null,
      featureGraphic: null,
      notificationIcon: null,
      tvIcon: null,
      tvFeatureGraphic: null,
      tvBanner: null,
      tvIcon: null,
      tvSmallBanner: null,
      tvCover: [],
      keyStore: null,
      serviceAccountKeyJson: null,
    },
    errors: {
      appIcon: null,
      cover: null,
      promoGraphic: null,
      featureGraphic: null,
      notificationIcon: null,
      tvIcon: null,
      tvFeatureGraphic: null,
      tvBanner: null,
      tvIcon: null,
      tvSmallBanner: null,
      tvCover: null,
      keyStore: null,
      serviceAccountKeyJson: null,
    },
  });

  const [storeLastUploaded, setStoreLastUploaded] = React.useState(null);
  const [mobileLastUploaded, setMobileLastUploaded] = React.useState(null);
  const [tvLastUploaded, setTvLastUploaded] = React.useState(null);

  const assetProps = { asset, setAsset, assetMap: GOOGLE_ASSET_MAP };

  const [firebaseServiceJsonError, setFirebaseServiceJsonError] =
    React.useState(null);

  const handleFirebaseServiceJson = (data) => {
    try {
      JSON.parse(data);
    } catch (e) {
      setFirebaseServiceJsonError("Invalid JSON format");
      return;
    }

    if (Boolean(firebaseServiceJsonError)) {
      setFirebaseServiceJsonError(null);
    }
    setFirebaseServiceJson(data);
  };

  React.useEffect(() => {
    if (
      asset.blobs.appIcon ||
      asset.blobs.cover.length > 0 ||
      asset.blobs.promoGraphic ||
      asset.blobs.featureGraphic ||
      asset.blobs.notificationIcon
    ) {
      setMobileLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.appIcon,
    asset.blobs.cover,
    asset.blobs.promoGraphic,
    asset.blobs.featureGraphic,
    asset.blobs.notificationIcon,
  ]);

  React.useEffect(() => {
    if (
      asset.blobs.tvIcon ||
      asset.blobs.tvFeatureGraphic ||
      asset.blobs.tvBanner ||
      asset.blobs.tvSmallBanner ||
      asset.blobs.tvCover.length > 0
    ) {
      setTvLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.tvIcon,
    asset.blobs.tvCover,
    asset.blobs.tvFeatureGraphic,
    asset.blobs.tvBanner,
    asset.blobs.tvSmallBanner,
  ]);

  React.useEffect(() => {
    if (asset.blobs.serviceAccountKeyJson || asset.blobs.keyStore) {
      setStoreLastUploaded(new Date().getTime());
    }
  }, [asset.blobs.serviceAccountKeyJson, asset.blobs.keyStore]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [
    storeLastUploaded,
    tvLastUploaded,
    mobileLastUploaded,
    accessGranted,
    storePassword,
    keyAlias,
    keyPassword,
    shaKey,
    licenseKey,
    firebaseApiKey,
    firebaseServiceJson,
    appStoreName,
    descriptionLangObj,
    shortDescriptionLangObj,
    promoVideoUrl,
    androidGaTrackingId,
    androidTvGaTrackingId,
    bundleId,
    releaseNotes,
    developerWebsite
  ]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            metadata: {
              access_granted: accessGranted,
              store_password: storePassword,
              key_alias: keyAlias,
              key_password: keyPassword,
              sha_key: shaKey,
              license_key: licenseKey,
              firebase_api_key: firebaseApiKey,
              firebase_service_json: firebaseServiceJson,
              app_store_name: appStoreName,
              description: descriptionLangObj,
              short_description: shortDescriptionLangObj,
              promo_video_url: promoVideoUrl,
              last_uploaded: storeLastUploaded,
              release_notes: releaseNotes,
              developer_website: developerWebsite
            },
            assetBlobs: {
              key_store: asset.blobs.keyStore,
              service_account_key_json: asset.blobs.serviceAccountKeyJson,
            },
            platformsAttributes: [
              {
                id: androidPlatform.id,
                metadata: {
                  bundle_id: bundleId,
                  ga_tracking_id: androidGaTrackingId,
                  last_uploaded: mobileLastUploaded,
                },
                assetBlobs: {
                  app_icon: asset.blobs.appIcon,
                  covers: asset.blobs.cover,
                  promo_graphic: asset.blobs.promoGraphic,
                  feature_graphic: asset.blobs.featureGraphic,
                  notification_icon: asset.blobs.notificationIcon,
                },
              },
              {
                id: androidTvPlatform.id,
                metadata: {
                  ga_tracking_id: androidTvGaTrackingId,
                  last_uploaded: tvLastUploaded,
                },
                assetBlobs: {
                  app_icon: asset.blobs.tvIcon,
                  feature_graphic: asset.blobs.tvFeatureGraphic,
                  banner: asset.blobs.tvBanner,
                  small_banner: asset.blobs.tvSmallBanner,
                  covers: asset.blobs.tvCover,
                },
              },
            ],
          },
        },
      });
    }
  };

  const validateForm = () => {
    return true;
  };

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  const langInput = (data, onSubmit, title, charLimit) => (<LanguageText
    data={data}
    onSubmit={onSubmit}
    languageOnly
    buttonProps={{style: {marginBottom: '32px'}}}
    title={title}
    charLimit={charLimit}
  />)

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl  }, { name: "Google" }]}>
      <PaperGroup>
        <GroupHeader title="General" />

        <Tooltip title="This name is used only in the app store.">
          <Input
            label="App store name"
            defaultValue={appStoreName}
            onChange={setAppStoreName}
            inputProps={{ maxLength: 30 }}
            helperText={`${30 - appStoreName.length} - character limit`}
            required
            autoFocus
          />
        </Tooltip>

        <div style={{display: 'flex'}}>
          <Tooltip title="This is a description of your app that will be displayed in the app store. Keep your catchiest content in the first few lines to make sure it is visible to all visitors.">
            <Input
              label="Description"
              value={description}
              onChange={(val) => {onLangTextChange(val, setDescriptionLangObj)}}
              inputProps={{ maxLength: 4000 }}
              helperText={`${4000 - description.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description", 4000)}
        </div>

        <div style={{display: 'flex'}}>
          <Tooltip title="The short description displays at the top of your listing in the app store.">
            <Input
              label="Short description"
              value={shortDescription}
              onChange={(val) => {onLangTextChange(val, setShortDescriptionLangObj)}}
              inputProps={{ maxLength: 80 }}
              helperText={`${80 - shortDescription.length} - character limit`}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(shortDescriptionLangObj, setShortDescriptionLangObj, "Description", 80)}
        </div>

        <Tooltip title="With video, you can quickly highlight your app’s top features. It’s also a good opportunity to show people how to use your app so they feel like experts before they’ve even installed it.">
          <Input
            label="Promo Video URL"
            defaultValue={promoVideoUrl}
            onChange={setPromoVideoUrl}
          />
        </Tooltip>
        <Tooltip title="Your website to be passed on to the app stores.">
          <Input
            label="Developers Website"
            defaultValue={developerWebsite}
            onChange={setDeveloperWebsite}
          />
        </Tooltip>
        <Tooltip title="It's text should be according to new features in your upcoming release.">
          <Input
            label="Release Notes"
            defaultValue={releaseNotes}
            onChange={setReleaseNotes}
          />
        </Tooltip>
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Android Mobile" />

        <ImageUploadGroup
          {...assetProps}
          name="appIcon"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="cover"
          horizontal
          previewMaxHeight={108}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="promoGraphic"
          horizontal
          previewMaxHeight={108}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="featureGraphic"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="notificationIcon"
          horizontal
          previewMaxHeight={87}
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Android TV" />

        <ImageUploadGroup
          {...assetProps}
          name="tvIcon"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="tvFeatureGraphic"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="tvBanner"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="tvSmallBanner"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="tvCover"
          horizontal
          previewMaxHeight={108}
        />
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaperGroup>
        <GroupHeader title="Developer Access" />

        <SwitchGroup
          label="Admin access granted to devaccounts@mazsystems.com"
          checked={accessGranted}
          onChange={setAccessGranted}
        />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="keyStore" />
        <Password
          label="Store password"
          defaultValue={storePassword}
          onChange={setStorePassword}
          required
        />
        <Input
          label="Key alias"
          defaultValue={keyAlias}
          onChange={setKeyAlias}
          required
        />
        <Password
          label="Key password"
          defaultValue={keyPassword}
          onChange={setKeyPassword}
          required
        />
        <Input
          label="SHA1 key"
          defaultValue={shaKey}
          onChange={setShaKey}
          required
        />
        <Input
          label="Google license key"
          defaultValue={licenseKey}
          onChange={setLicenseKey}
          required
        />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="serviceAccountKeyJson" />
        <Input
          label="Firebase API Key"
          defaultValue={firebaseApiKey}
          onChange={setFirebaseApiKey}
          required
        />
        <Input
          label="Firebase service JSON"
          defaultValue={firebaseServiceJson}
          onChange={handleFirebaseServiceJson}
          error={Boolean(firebaseServiceJsonError)}
          helperText={firebaseServiceJsonError}
          multiline={true}
          rows={5}
          required
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <GaTrackingIdInput
          label="Android Mobile Google Analytics Tracking ID"
          defaultValue={androidGaTrackingId}
          onChange={setAndroidGaTrackingId}
          required
        />

        <GaTrackingIdInput
          label="Android TV Google Analytics Tracking ID"
          defaultValue={androidTvGaTrackingId}
          onChange={setAndroidTvGaTrackingId}
          required
        />

        <BundleIdInput
          label="App Bundle ID"
          defaultValue={bundleId}
          onChange={setBundleId}
          placeholder={`com.maz.tvod.${appProps.appId}.${androidPlatform.id}`}
        />
      </PaperGroup>
    </FormLayout>
  );
};

export default withStore(Platform);
