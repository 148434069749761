import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";
import { useRouteMatch, useParams } from "react-router-dom";
import urlJoin from "url-join";
import _ from "underscore";
import moment from "moment";

import { GET_LIVE_PROGRAMS, GET_CURRENT_APP_PROPS, DELETE_LIVE_PROGRAM } from "api";
import DataGrid from "components/atoms/DataGrid";
import FormLayout from "components/molecules/Form/FormLayout";
import { withRedirect } from "components/containers/Redirect";
import ConfirmDialog from "components/molecules/Dialog/Confirm/LiveProgram";
import ContentLayout from "screens/Layout/ContentLayout";

const programColumns = [
  {
    field: "title",
    headerName: "Name",
    width: 250,
    sortable: true,
    filter: true,
    sort:"asc"
  },
  {
    field: "start",
    headerName: "Starts at",
    width: 220,
    sortable: true,
    filter: true,
    valueGetter: (params) => moment(params.data.start).format("llll"),
  },
  {
    field: "stop",
    headerName: "Ends at",
    width: 220,
    sortable: true,
    filter: true,
    valueGetter: (params) => moment(params.data.stop).format("llll"),
    flex: 1,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    flex: 1,
  }
];

const xmltvColumns = [
  {
    field: "image",
    headerName: " ",
    width: 75,
    cellRenderer: "imageCellRenderer",
  },
  {
    field: "title",
    headerName: "Name",
    width: 225,
    sortable: true,
    filter: true,
    sort:"asc"
  },
  {
    field: "start",
    headerName: "Starts at",
    width: 220,
    sortable: true,
    filter: true,
    valueGetter: (params) => moment(params.data.start).format("llll"),
  },
  {
    field: "stop",
    headerName: "Ends at",
    width: 220,
    sortable: true,
    filter: true,
    valueGetter: (params) => moment(params.data.stop).format("llll"),
    flex: 1,
  },
];

function LiveChannels(props) {
  const client = useApolloClient();
  const { liveChannelId, liveScheduleId } = useParams();
  const { path } = useRouteMatch();
  const { redirect, liveChannel, channelsPath } = props;
  const [columns, setColumns] = React.useState([
    {
      field: "image",
      headerName: " ",
      width: 75,
      cellRenderer: "imageCellRenderer",
    },
    {
      field: "title",
      headerName: "Name",
      width: 225,
      sortable: true,
      filter: true,
    },
    {
      field: "start",
      headerName: "Starts at",
      width: 220,
      sortable: true,
      filter: true,
      valueGetter: (params) => moment(params.data.start).format("llll"),
    },
    {
      field: "stop",
      headerName: "Ends at",
      width: 220,
      sortable: true,
      filter: true,
      valueGetter: (params) => moment(params.data.stop).format("llll"),
      flex: 1,
    },
  ]);

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_LIVE_PROGRAMS, { variables: { ...appProps, liveChannelId, liveScheduleId } });

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  const onEdit = (liveProgram) => {
    redirect.set(urlJoin(path.replace(":workspaceId", appProps.workspaceId).replace(":appId", appProps.appId).replace(":liveChannelId", liveChannel?.id).replace(":liveScheduleId", liveChannel?.defaultSchedule?.id), `edit/${liveProgram.id}`))
  };

  const [ deleteLiveProgram ] = useMutation(DELETE_LIVE_PROGRAM, {
    update(cache, { data: { deleteLiveProgram } }) {
      cache.modify({
        fields: {
          livePrograms(existingRefs, { readField }) {
            return existingRefs.filter((ref) => deleteLiveProgram.liveProgram.id !== readField("id", ref));
          },
        }
      })
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
      closeDeleteConfirmation();
    },
  });

  const deleteLiveProgramApi = (id) => {
    deleteLiveProgram({
      variables: {
        ...appProps,
        liveChannelId: liveChannel?.id,
        liveScheduleId: liveChannel?.defaultSchedule?.id,
        id: id,
      }
    });
  };

  const getBreadcrumbs = () => {
    const sectionPath =   `/${appProps.workspaceId}/${appProps.appId}/sections`;
    let breadcrumbs = [{name:"Sections", link: sectionPath},{ name: "Guide", link: channelsPath }];

    if (liveChannel) {
      breadcrumbs.push({ name: liveChannel.title, link: `` });
    }
    return breadcrumbs;
  };

  const getHeaderProps = () => {
    let headerProps = { title: liveChannel.title };

    if (liveChannel && !Boolean(liveChannel.xmltvChannelId)) {
      headerProps = { ...headerProps, fab: { onClick: () => redirect.set(urlJoin(path.replace(":workspaceId", appProps.workspaceId).replace(":appId", appProps.appId).replace(":liveChannelId", liveChannelId).replace(":liveScheduleId", liveScheduleId), "new")) } };
    }
    return headerProps;
  }

  return (
    <ContentLayout { ...getHeaderProps() }>
      <FormLayout breadcrumbs={getBreadcrumbs()}>
        {liveChannel && !Boolean(liveChannel.xmltvChannelId)
          ? <DataGrid
              rowData={data?.livePrograms
                ? _.sortBy(data.livePrograms, (r) => r.start).map((af) => ({
                    ...af,
                    actions: {
                      onEdit,
                      onDelete: () => setDeleteConfirmation(af),
                    }
                  }))
                : []
              }
              columnDefs={programColumns}
            />
          : <DataGrid
              rowData={data?.livePrograms
                ? _.sortBy(data.livePrograms, (r) => r.start)
                : []
              }
              columnDefs={xmltvColumns}
            />
        }

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => deleteLiveProgramApi(deleteConfirmation.id)}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(LiveChannels);
