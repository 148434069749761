import { useQuery } from "@apollo/client";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import { GET_CURRENT_APP_PROPS } from "api";
import _ from 'lodash';

export function useLayoutData() {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const location = useLocation();
  const locSplit = location.pathname.split("/");

  const initializeToggle = (subUrls) => {
    return !_.difference(subUrls, locSplit).length;
  }

  const [collectionCollapse, setCollectionCollapse] = React.useState(initializeToggle(['content', 'collection']) || locSplit[4] === "collections");
  const toggleCollectionCollapse = () => setCollectionCollapse(!collectionCollapse);

  const [playlistCollapse, setPlaylistCollapse] = React.useState(initializeToggle(['content', 'playlists']));
  const togglePlaylistCollapse = () => setPlaylistCollapse(!playlistCollapse);

  const [contentCollapse, setContentCollapse] = React.useState(initializeToggle(['content', 'catalog']) || true);
  const toggleContentCollapse = () => setContentCollapse(!contentCollapse);

  const [metadataCollapse, setMetadataCollapse] = React.useState(initializeToggle(['content', 'metadata']));
  const toggleMetadataCollapse = () => setMetadataCollapse(!metadataCollapse);

  const [tierCollapse, setTierCollapse] = React.useState(initializeToggle(['monetization', 'tiers']) || true);
  const toggleTierCollapse = () => setTierCollapse(!tierCollapse);

  const [storeCollapse, setStoreCollapse] = React.useState(initializeToggle(['monetization', 'stores']));
  const toggleStoreCollapse = () => setStoreCollapse(!storeCollapse);

  const [adsCollapse, setAdsCollapse] = React.useState(initializeToggle(['monetization', 'ads']));
  const toggleAdsCollapse = () => setAdsCollapse(!adsCollapse);

  const [workspaceSettingsCollapse, setWorkspaceSettingsCollapse] = React.useState(initializeToggle(['settings', 'workspace']));
  const toggleWorkspaceSettingsCollapse = () => setWorkspaceSettingsCollapse(!workspaceSettingsCollapse);

  const selectedHub = locSplit[3];

  const drawerMap = {
    design: {
      data: [
        {
          name: "General",
          path: "/design/general",
          selected: locSplit[4] === "general",
        },
        {
          name: "Launch",
          path: "/design/launch",
          selected: locSplit[4] === "launch",
        },
        {
          name: "Onboarding",
          path: "/design/onboarding",
          selected: locSplit[4] === "onboarding",
        },
        {
          name: "Saved Items",
          path: "/design/saved",
          selected: locSplit[4] === "saved",
        },
        {
          name: "Program Guide",
          path: "/design/guide",
          selected: locSplit[4] === "guide",
        },
        {
          name: "Menus",
          path: "/design/menus",
          selected: locSplit[4] === "menus",
        },
        {
          name: "Registration & Login",
          path: "/design/login_configurations",
          selected: locSplit[4] === "login_configurations",
        },
        {
          name: "Fonts",
          path: "/design/fonts",
          selected: locSplit[4] === "fonts",
        },
        {
          name: "Inline Content",
          path: "/design/video_settings",
          selected: locSplit[4] === "video_settings",
        },
        {
          name: "Privacy",
          path: "/design/legal",
          selected: locSplit[4] === "legal",
        },
        {
          name: "Platforms",
          path: "/design/platforms",
          selected: locSplit[4] === "platforms"
        },
      ],
    },
    content: {
      data: [
        {
          name: "Sections",
          path: "/content/sections" || "/content/channels",
          selected: locSplit[4] === "sections" || locSplit[4] === "channels",
        },
        {
          name: "Collections",
          toggle: {
            click: toggleCollectionCollapse,
            flag: collectionCollapse,
            data: [
              {
                name: "Custom",
                path: "/content/collections/manual",
                selected: locSplit[4] === "collections" && locSplit[5] === "manual"
              },
              {
                name: "Categories",
                path: "/content/collections/categories",
                selected: locSplit[4] === "collections" && locSplit[5] === "categories",
              },
              {
                name: "Genres",
                path: "/content/collections/genres",
                selected: locSplit[4] === "collections" && locSplit[5] === "genres",
              },
              {
                name: "Keywords",
                path: "/content/collections/keywords",
                selected: locSplit[4] === "collections" && locSplit[5] === "keywords",
              },
              {
                name: "Actors",
                path: "/content/collections/actors",
                selected: locSplit[4] === "collections" && locSplit[5] === "actors",
              },
              {
                name: "Directors",
                path: "/content/collections/directors",
                selected: locSplit[4] === "collections" && locSplit[5] === "directors",
              },
            ],
          },
        },
        {
          name: "Playlists",
          toggle: {
            click: togglePlaylistCollapse,
            flag: playlistCollapse,
            data: [
              {
                name: "Manual",
                path: "/content/playlists/manual",
                selected: locSplit[4] === "playlists" && locSplit[5] === "manual"
              },
              {
                name: "Search",
                path: "/content/playlists/search",
                selected: locSplit[4] === "playlists" && locSplit[5] === "search",
              },
              {
                name: "Feeds",
                path: "/content/playlists/feeds",
                selected: locSplit[4] === "playlists" && locSplit[5] === "feeds",
              },
            ],
          },
        },
        {
          name: "Catalog",
          toggle: {
            click: toggleContentCollapse,
            flag: contentCollapse,
            data: [
              {
                name: "All",
                path: "/content/catalog/all",
                selected: locSplit[4] === "catalog" && locSplit[5] === "all",
              },
              {
                name: "Series",
                path: "/content/catalog/series",
                selected: locSplit[4] === "catalog" && ["series", "seasons", "episodes", "limited_series"].includes(locSplit[5]),
              },
              {
                name: "Movies",
                path: "/content/catalog/movies",
                selected: locSplit[4] === "catalog" && locSplit[5] === "movies",
              },
              {
                name: "Events",
                path: "/content/catalog/events",
                selected: locSplit[4] === "catalog" && locSplit[5] === "events",
              },
              {
                name: "Extras",
                path: "/content/catalog/extras",
                selected: locSplit[4] === "catalog" && locSplit[5] === "extras",
              },
              {
                name: "Links",
                path: "/content/catalog/links",
                selected: locSplit[4] === "catalog" && locSplit[5] === "links",
              },
            ],
          },
        },
        {
          name: "Metadata",
          toggle: {
            click: toggleMetadataCollapse,
            flag: metadataCollapse,
            data: [
              {
                name: "Attributes",
                path: "/content/metadata/attributes",
                selected: locSplit[4] === "metadata" && locSplit[5] === "attributes",
              }
            ],
          },
        },
        {
          name: "Sources",
          path: "/content/sources",
          selected: locSplit[4] === "sources",
        },
      ],
    },
    monetization: {
      data: [
        {
          name: "Consumers",
          path: "/monetization/customers",
          selected: locSplit[4] === "customers",
        },
        {
          name: "Payments",
          path: "/monetization/payment",
          selected: locSplit[4] === "payment",
        },
        {
          name: "Subscriptions",
          path: "/monetization/subscriptions",
          selected: locSplit[4] === "subscriptions",
        },
        {
          name: "Transactions",
          toggle: {
            click: toggleTierCollapse,
            flag: tierCollapse,
            data: [
              {
                name: "Purchases",
                path: "/monetization/transaction/purchases",
                selected: locSplit[5] === "purchases",
              },
              {
                name: "Rentals",
                path: "/monetization/transaction/rentals",
                selected: locSplit[5] === "rentals",
              },
              {
                name: "Events",
                path: "/monetization/transaction/events",
                selected: locSplit[5] === "events",
              },
            ],
          },
        },
        {
          name: "Advertising",
          toggle: {
            click: toggleAdsCollapse,
            flag: adsCollapse,
            data: [
              {
                name: "Ads",
                path: "/monetization/ads",
                selected: locSplit[4] === "ads" && !locSplit[5],
              },
              {
                name: "Banners",
                path: "/monetization/ads/banners",
                selected: locSplit[4] === "ads" && locSplit[5] === "banners",
              },
              {
                name: "Sponsors",
                path: "/monetization/ads/sponsors",
                selected: locSplit[4] === "ads" && locSplit[5] === "sponsors",
              },
            ],
          },
        },
        {
          name: "Notifications",
          path: "/monetization/notifications",
          selected: locSplit[4] === "notifications",
        },
        {
          name: "Stores",
          toggle: {
            click: toggleStoreCollapse,
            flag: storeCollapse,
            data: [
              {
                name: "Web",
                path: "/monetization/stores/web",
                selected: locSplit[4] === "stores" && locSplit[5] === "web",
              },
              {
                name: "Apple",
                path: "/monetization/stores/apple",
                selected:
                  locSplit[4] === "stores" && locSplit[5] === "apple",
              },
              {
                name: "Google",
                path: "/monetization/stores/google",
                selected:
                  locSplit[4] === "stores" && locSplit[5] === "google",
              },
              {
                name: "Roku",
                path: "/monetization/stores/roku",
                selected: locSplit[4] === "stores" && locSplit[5] === "roku",
              },
              {
                name: "Amazon",
                path: "/monetization/stores/amazon",
                selected:
                  locSplit[4] === "stores" && locSplit[5] === "amazon",
              },
              {
                name: "Samsung",
                path: "/monetization/stores/samsung",
                selected:
                  locSplit[4] === "stores" && locSplit[5] === "samsung",
              },
              {
                name: "LG",
                path: "/monetization/stores/lg",
                selected: locSplit[4] === "stores" && locSplit[5] === "lg",
              },
              {
                name: "Vizio",
                path: "/monetization/stores/vizio",
                selected:
                  locSplit[4] === "stores" && locSplit[5] === "vizio",
              },
              {
                name: "Microsoft",
                path: "/monetization/stores/microsoft",
                selected:
                  locSplit[4] === "stores" && locSplit[5] === "microsoft",
              },
            ],
          },
        },
        {
          name: "Integrations",
          path: "/monetization/integrations",
          selected: locSplit[4] === "integrations",
        },
        {
          name: "Onboarding",
          path: "/monetization/onboarding",
          selected: locSplit[4] === "onboarding",
        },
      ],
    },
    live: {
      data: [
        {
          name: "Streams",
          path: "/live/streams",
          selected: locSplit[4] === "streams",
        },
      ],
    },
    settings: {
      data: [
        {
          name: "Profile",
          path: "/settings/profile",
          selected: locSplit[3] === "settings" && locSplit[4] === "profile",
        },
        {
          name: "Workspace",
          toggle: {
            click: toggleWorkspaceSettingsCollapse,
            flag: workspaceSettingsCollapse,
            data: [
              {
                name: "Brands",
                path: "/settings/workspace/brands",
                selected: locSplit[4] === "workspace" && locSplit[5] === "brands"
              },
              {
                name: "Users",
                path: "/settings/workspace/users",
                selected: locSplit[4] === "workspace" && locSplit[5] === "users"
              },
            ]
          }
        },
        {
          name: "Logout",
          path: "/logout",
        },
      ],
    },
  };

  let data = { drawer: drawerMap[selectedHub] };

  return data;
}
