import React from "react";
import ContentLayout from "screens/Layout/ContentLayout";

export default function withStore(Component) {
  return (props) => {
    const { name, title, stores, updateStoreApi, appProps, isMultiLangOrRegion, app, isUpdating } = props;

    const store = stores?.find((s) => s.name === name);
    const platformProps = { store, appProps, updateStoreApi, isMultiLangOrRegion, app, isUpdating };

    return (
      <ContentLayout title={title}>
        {store ? <Component {...platformProps} /> : null}
      </ContentLayout>
    )
  };
}
