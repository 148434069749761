import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import _ from "underscore";

import { GET_CURRENT_APP_PROPS, UPDATE_BADGE, CREATE_BADGE } from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import Select from "components/atoms/Input/Select";
import { checkFileType, getHeightAndWidthFromDataUrl } from "helpers/fileUtils";
import UploadButton, { ImagePreview } from "components/molecules/UploadGroup";
import PaperGroup from "components/molecules/Form/PaperGroup";
import ContentLayout from "screens/Layout/ContentLayout";
import ColorPicker from 'components/atoms/Input/ColorPicker';
import { withDesign } from 'screens/Hubs/Design/Design/Design';
import GroupHeader from "components/atoms/Text/GroupHeader";
import { FONTS_DESIGN_PATH } from "helpers/urlConstants";

function Form(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { badge, designs=[] } = props;
  const fonts = designs[0]?.fonts;
  const [name, setName] = React.useState(badge.name || "");
  const [badgeType, setBadgeType] = React.useState(badge.badgeType || "none");
  const [positionType, setPositionType] = React.useState(badge.position || "details");
  const [imageUrl, setImageUrl] = React.useState(badge.imageUrl || "");
  const [imageBlobId, setImageBlobId] = React.useState(null);
  const [imageAltText, setImageAltText] = React.useState(
    badge.imageAltText || ""
  );
  const [fileMetadata, setFileMetadata] = React.useState("");
  const [imageError, setImageError] = React.useState(null);

  const [labelText, setLabelText] = React.useState(badge.label || "");
  const [textColor, setTextColor] = React.useState(badge.metadata?.textColor || "#FFF");
  const [backgroundColor, setBackgroundColor] = React.useState(badge.metadata?.backgroundColor || "#F00");
  const [font, setFont] = React.useState(badge.metadata?.font || "default");
  const [fontSize, setFontSize] = React.useState(badge.metadata?.fontSize || "12");

  const [nameError, setNameError] = React.useState(null);
  const [labelError, setLabelError] = React.useState(null);

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);

  const listUrl = `${pathname.split("attributes")[0]}/attributes`;

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [
    name,
    imageUrl,
    imageAltText,
    labelText,
    textColor,
    backgroundColor,
    font,
    fontSize,
    fileMetadata,
    positionType,
    badgeType
  ]);

  React.useEffect(() => {
    setTimeout(() => setIsFormChanged(false), 50);
  }, []);

  const onSave = () => {
    props.savedSnack.launch();
    history.push(listUrl);
  };

  const stateForm = {
    name,
    badgeType,
    imageBlobId,
    imageAltText,
    label: labelText,
    metadata: {
      textColour: textColor,
      backgroundColour: backgroundColor,
      font,
      fontSize,
    },
    position: positionType
  };

  const isValidate = () => {
    let check = true;
    if (!stateForm.name) {
      setNameError(true);
      check = false;
    }
    if (badgeType === "label" && !stateForm.label) {
      setLabelError(true);
      check = false;
    }
    if (badgeType === "image" && !stateForm.imageBlobId) {
      setImageError("Required");
      check = false;
    }
    return check;
  };

  const submit = () => {
    if (!isValidate()) {
      return;
    }
    if (badge.id) {
      updateBadge({
        variables: {
          ...appProps,
          badgeId: badge.id,
          badgeFields: stateForm,
        },
      });
    } else {
      createBadge({
        variables: {
          ...appProps,
          badgeFields: stateForm,
        },
      });
    }
  };

  function handleLogoUpload(response) {
    setImageUrl(response.url);
    setImageBlobId(response.signedBlobId);
  }

  async function handleLogoValidations(file) {
    setImageError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (!checkFileType(file, ["png"])) {
      setImageError("Invalid file type");
      return false;
    }
    if (file.size > 1000000) {
      setImageError("Image size exceeded");
      return false;
    }
    if (dimensions.width <= 600 && dimensions.height === 60) {
      return true;
    }
    setImageError("Invalid dimensions");
    return false;
  }

  const handleError = (data) => {
    if (data.message.includes("Name")) {
      setNameError(data.message);
    }
  };

  React.useEffect(() => {
    if (fileMetadata?.name) {
      setImageAltText(fileMetadata.name);
    }
  }, [fileMetadata]);

  const [updateBadge, { loading: isUpdating }] = useMutation(UPDATE_BADGE, {
    update(cache, { data: { updateBadge } }) {
      cache.modify({
        fields: {
          badges(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateBadge.badge.id === readField("id", ref)
                ? { ...ref, ...updateBadge.badge }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      handleError(data);
    },
  });

  const [createBadge, { loading: isCreating }] = useMutation(CREATE_BADGE, {
    update(cache, { data: { createBadge } }) {
      cache.modify({
        fields: {
          badges(existingRefs) {
            return [...existingRefs, createBadge.badge];
          },
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      handleError(data);
    },
  });

  const renderFile = (url, alt, urlSetter, blobSetter) =>
    url ? <ImagePreview {...{ url, alt, urlSetter, blobSetter }} /> : null;

  const nonNullTitle = name || "Untitled Attribute";

  const fontOptions = [
    { label: "Default", value: "default" },
    ...fonts.map((f) => ({ label: (f.postscriptName || f.name), value: f.id })),
  ];
  const fontSizes = ["8", "9", "10", "11", "12", "14"];
  const fontSizeOptions = fontSizes.map(fs => ({label: `${fs} PX`, value: fs}))

  const isLoading = isCreating || isUpdating;
  const badgeTextColor = <ColorPicker getter={textColor} setter={setTextColor} label="Text color" disabled={disableForm}/>;
  const badgeBackgroundColor = <ColorPicker getter={backgroundColor} setter={setBackgroundColor} label="Background color" disabled={disableForm}/>;

  return (
    <ContentLayout title={nonNullTitle}>
      <FormLayout
        submit={submit}
        breadcrumbs={[
          { name: "Attributes", link: listUrl },
          { name: nonNullTitle },
        ]}
        betterForm
        isChanged={isFormChanged}
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <PaperGroup>
          <Input
            label="Title"
            name="name"
            defaultValue={name}
            onChange={setName}
            autoFocus
            required
            error={!name && nameError}
            style={{width: 320}}
            disabled={badge?.isSystemAttribute}
          />
          <Select
            value={badgeType}
            onChange={setBadgeType}
            options={[
              { label: "None", value: "none" },
              { label: "Label", value: "label" },
              { label: "Image", value: "image" },
            ]}
            label="Badge Type"
            required
          />

          <Select
            value={positionType}
            onChange={setPositionType}
            options={[
              { label: "Details", value: "details" },
              { label: "Top Left", value: "top_left" },
              { label: "Top Center", value: "top_center" },
              { label: "Top Right", value: "top_right" },
              { label: "Center Left", value: "left_center" },
              { label: "Center", value: "center" },
              { label: "Center Right", value: "right_center" },
              { label: "Bottom Left", value: "bottom_left" },
              { label: "Bottom Center", value: "bottom_center" },
              { label: "Bottom Right", value: "bottom_right" },
            ]}
            label="Position"
          />

          {
            badgeType === "image" &&
            <>
              <UploadButton
                requirements={[
                  "Height 60px",
                  "Max width 600px",
                  "PNG",
                  "Maximum size: 1 MB",
                ]}
                label="Badge Logo"
                onUpload={handleLogoUpload}
                validations={handleLogoValidations}
                error={imageError}
                fileRender={() =>
                  renderFile(imageUrl, "Logo", setImageUrl, setImageBlobId)
                }
                inputProps={{ accept: ".png" }}
                setFileMetadata={setFileMetadata}
                required
              >
                Upload Image
              </UploadButton>

              <Input
                label="Logo Alt Text"
                value={imageAltText}
                onChange={setImageAltText}
              />
            </>
          }

          {
            badgeType === "label" && 
            <>
              <Input
                label="Label Text"
                value={labelText}
                onChange={setLabelText}
                style={{width: 320}}
                required
                error={!labelText && labelError}
              />
              <Select
                value={font}
                onChange={setFont}
                options={fontOptions}
                label="Font"
                required
              />
              <Select
                value={fontSize}
                onChange={setFontSize}
                options={fontSizeOptions}
                label="Font"
                required
              />
              <GroupHeader caption={<div>More fonts options can be added from <Link to={`/${appProps.workspaceId}/${appProps.appId}/${FONTS_DESIGN_PATH}`}>Design</Link></div>}/>
              {badgeTextColor}
              {badgeBackgroundColor}
            </>
          }
          
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default withDesign(Form);
