import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import validator from 'validator';

export default function Phone({ phone, setPhone, ...otherProps }) {
  const [ statePhone, setStatePhone ] = React.useState(phone);

  return (
    <PhoneInput
      country={'us'}
      value={statePhone}
      onChange={(phoneNumber) => {
        setStatePhone(phoneNumber)
        setPhone(validator.isMobilePhone(phoneNumber) ? phoneNumber : null);
      }}
      {...otherProps}
    />
  );
}
