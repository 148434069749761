import React from "react";
import makeStyles from '@mui/styles/makeStyles';

import publogo from "images/default-publisher-logo.svg";

const useStyles = makeStyles((theme) => ({
    header: props => ({
        height: "57px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        padding: "0 10px",
        backgroundColor: props.themePrimary ? props.themePrimary : props.themePrimary,
        justifyContent: props.logoPlacement === "center" ? "center" : "flex-start",
      }),
      logoPlacement: props => ({
        position: "absolute",
        right: "10px",
        top: "20px",
        WebkitFilter: props.lightMode ? "invert(100%)": null,
      })
}))

const getIcon = (icon) => {
  if (icon && icon.includes("http")) {
    return icon;
  } else {
    return `https://resources.mazsystems.com/assets/core/production/${icon}@3x.png`;
  }
};


function Header(props) {
    const { simulator } = props;
    const { design } = simulator;
    const classes = useStyles(design);

    return(
        <div className={classes.header}>
            <img
                height={"32"}
                src={design.mobileLogoUrl ? design.mobileLogoUrl : publogo}
                alt="Logo"
            />
            <div className={classes.logoPlacement}>
                <img
                    width={"20"}
                    style={{ marginRight: "7px" }}
                    src={getIcon(design.iconSearch ? design.iconSearch : design.iconSearch)}
                    alt="Search"
                />
                <img
                    width={"20"}
                    src={getIcon(design.iconSettings ? design.iconSettings : design.iconSettings)}
                    alt="Settings"
                />
            </div>
        </div>
    )
}

export default Header;
