import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import React, { useEffect } from 'react';
import { ChromePicker } from 'react-color'
import _ from 'lodash';

import BaseInput from './BaseInput';

export default function ColorPicker(props) {
  const { setter, label, textFieldProps, disabled } = props;
  const [ value, setValue ] = React.useState(props.getter);
  const [ colorAnchor, setColorAnchor ] = React.useState(null);
  const handleClose = () => setColorAnchor(null);
  const maxWidth = 320;

  useEffect(() => {
    setValue(props.getter);
  }, [props.getter])

  return (
    <div>
      <BaseInput
        required
        label={label}
        value={value}
        name={_.camelCase(label)}
        inputtype="pointer"
        onClick={(event) => setColorAnchor(event.currentTarget)}
        onChange={(val) => {
          setValue(val);
          setter(val);
        }}
        style={{ maxWidth: maxWidth }}
        {...textFieldProps}
        disabled={disabled}
      />
      <div style={{ position: 'relative', maxWidth: maxWidth }}>
        <Box
          borderRadius={1}
          bgcolor={value}
          borderColor="grey.500"
          m={1}
          border={1}
          style={{ width: '1rem', height: '1rem', position: 'absolute', zIndex: 1, right: 8, top: -52 }} />
      </div>
      {!disabled && <Menu
        anchorEl={colorAnchor}
        open={Boolean(colorAnchor)}
        onClose={handleClose}
        PaperProps={{ style: { width: 225 } }}
        MenuListProps={{ style: { padding: 0 } }}
      >
        <ChromePicker
          disableAlpha={true}
          color={value}
          onChange={(color) => {
            setValue(color.hex);
            setter(color.hex);
          }}
        />
      </Menu>
      }
    </div>
  );
}
