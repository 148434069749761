import { gql } from '@apollo/client';

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationSchema on Notification {
    id
    name
    linkType
    url
    notificationText
    platforms{
      id
      isActive
      platformType
    }
    scheduleOption
    scheduleFor
    segmentation
    totalDevices
    status
    localeIds
    language
  }
`

export const GET_AVAILABLE_PLATFORMS = gql`
  query availableNotificationPlatforms($workspaceId: ID! $appId: ID!) {
    availableNotificationPlatforms(workspaceId: $workspaceId appId: $appId) {
      id
      type
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query notifications($workspaceId: ID! $appId: ID!) {
    notifications(workspaceId: $workspaceId appId: $appId) {
      ...NotificationSchema
    }
  }
  ${NOTIFICATION_FRAGMENT}
`

export const GET_NOTIFICATION = gql`
  query notification($workspaceId: ID! $appId: ID! $id: ID!) {
    notification(workspaceId: $workspaceId appId: $appId id: $id) {
      ...NotificationSchema
    }
  }
  ${NOTIFICATION_FRAGMENT}
`

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $workspaceId: ID!
    $appId: ID!
    $notificationFields: NotificationInput!
  ) {
    createNotification(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        notificationFields: $notificationFields
      }
    ) {
      notification {
        ...NotificationSchema
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $workspaceId: ID!
    $appId: ID!
    $notificationId: ID!
    $notificationFields: NotificationInput!
  ) {
    updateNotification(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        notificationId: $notificationId
        notificationFields: $notificationFields
      }
    ) {
      notification {
        ...NotificationSchema
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification(
    $workspaceId: ID!
    $appId: ID!
    $notificationId: ID!
  ) {
    deleteNotification(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        notificationId: $notificationId
      }
    ) {
      notification {
        ...NotificationSchema
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`
