import React from 'react';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  }
}));

export default function DividerAtom() {
  const classes = useStyles();

  return (
    <Divider className={classes.divider} />
  );
}
