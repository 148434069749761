export default function isValid(platformDetails, appStores) {
    const { metadata, assetUrls, storeId } = platformDetails;
    const storeDetails = appStores.find(s => s.id === storeId);
    const {metadata: storeMetadata} = storeDetails;

    if (!metadata || !storeMetadata || !assetUrls ) return false;

    if (!storeMetadata.product_name) return false;
    if (!storeMetadata.description) return false;

    if (!storeMetadata.package_identity_name) return false;
    if (!storeMetadata.package_identity_publisher) return false;
    if (!storeMetadata.publisher_display_name) return false;

    if (!metadata.ga_tracking_id) return false;

    if (!assetUrls.covers || assetUrls.covers.length < 4) return false;

    return true;
}