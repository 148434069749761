import React from 'react';
import BaseInput from './BaseInput';

export default function Password(props) {
  return (
    <BaseInput
      name="password"
      label="Password"
      type="password"
      id="password"
      autoComplete="new-password"
      {...props}
    />
  );
};
