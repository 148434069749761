export const VALID_TEMPLATES = [
  "Cleeng",
  "Zype",
  "Toolbox"
];


export const OAUTH_PASSWORD = "OauthGrant::Password";
export const INTEGRATION_OAUTH = "Integration::Oauth";
export const INTEGRATION_TVE = "Integration::Tve";
export const OAUTH_AUTHORIZATION = "OauthGrant::AuthorizationCode";
