import React from 'react';
import _ from 'lodash';

import ColorPicker from '../atoms/Input/ColorPicker';
import UploadButton, { ImagePreview, VideoPreview } from './UploadGroup';
import { checkFileType, getHeightAndWidthFromDataUrl } from 'helpers/fileUtils';
import Select from '../atoms/Input/Select';
import Input from 'components/atoms/Input/BaseInput';
import GroupHeader from 'components/atoms/Text/GroupHeader';

/**
 *
 * @param {Object} props
 * @param {Boolean} props.hideGradientIfVideoOrImage - If true, Gradient option will hide, if video or image is selected as background.
 * @param {Array} props.supportedImageFormats - ['jpg', 'png', 'webp']. If not provided, all image types are accepted.
 * @param {Array} props.maxFileSize - Image max size in MB.
 */
export default function BackgroundGroup(props) {
  const { getter,
    setter,
    imageDimensions,
    renderAsset,
    hideGradientIfVideoOrImage,
    supportedImageFormats,
    maxFileSize,
    noTransparent,
    disabled
  } = props;

  const [format, setFormat] = React.useState(getter.format);
  const [primaryColor, setPrimaryColor] = React.useState(getter.primaryColor);
  const [secondaryColor, setSecondaryColor] = React.useState(getter.secondaryColor);
  const [assetBlobId, setAssetBlobId] = React.useState(null);
  const [assetUrl, setAssetUrl] = React.useState(getter.assetUrl);
  const [assetError, setAssetError] = React.useState(null);
  const [altText, setAltText] = React.useState(getter.altText || "");

  const allowedImageHeight = imageDimensions?.height || 1080;
  const allowedImageWidth = imageDimensions?.width || 1920;

  React.useEffect(() => {
    if (props.error) {
      setAssetError(props.error)
    }
  }, [props.error])

  React.useEffect(() => {
    setFormat(getter.format);
  }, [getter.format]);

  React.useEffect(() => {
    setAssetUrl(getter.assetUrl);
  }, [getter.assetUrl]);

  React.useEffect(() => {
    setPrimaryColor(getter.primaryColor);
    setSecondaryColor(getter.secondaryColor);
  }, [getter.primaryColor,getter.secondaryColor,]);

  function handleAssetUpload(response) {
    setAssetBlobId(response.signedBlobId);
    setAssetUrl(response.url);
  }

  async function handleAssetValidations(file) {
    try {
      setAssetError(null);
      if (format === "image") {
        const fileAsDataURL = window.URL.createObjectURL(file);
        const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

        if (maxFileSize && (file.size / 1000 > maxFileSize * 1000)) {
          setAssetError(`File size should be less than ${maxFileSize} MB`);
          return false
        }
        if (supportedImageFormats?.length && !checkFileType(file, supportedImageFormats)) {
          setAssetError("Invalid file type");
          return false;
        }
        if (dimensions.width === allowedImageWidth && dimensions.height === allowedImageHeight) {
          setAltText(file.name?.split('.')[0]);
          return true;
        } else {
          setAssetError("Invalid dimensions");
        }
      } else if (format === "video") {
        if (file.size < 200000000) {
          const video = await loadVideo(file)
          if(Math.floor(video.duration) <= 5){
            return true;
          }
          else{
            setAssetError("File duration should be less than 5 seconds");
          }
        } else {
          setAssetError("Invalid file");
        }
      }

      return false;
    } catch (e) {
      return false
    }
  }

  let options = [
    { label: props.basicBlack ? "Default" : "Single Color", value: props.basicBlack ? "basic_black" : "single" },
    { label: "Gradient", value: "gradient" },
    { label: "Image", value: "image" },
  ];

  if (!props.noVideo) {
    options.push({ label: "Video", value: "video" });
  }

  const renderFile = (url, alt, urlSetter, blobSetter, format) => (
    renderAsset && url
      ? format === 'video'
        ? <VideoPreview {...{ url, alt, urlSetter, blobSetter }} />
        : <ImagePreview {...{ url, alt, urlSetter, blobSetter }} />
      : null
  );

  React.useEffect(() => {
    setter({ ...getter, format, primaryColor, secondaryColor, assetBlobId, assetUrl, altText });
  }, [format, primaryColor, secondaryColor, assetBlobId, assetUrl, altText]);

  React.useEffect(() => {
    if (format === "basic_black") {
      setPrimaryColor("#000000");
    }
  }, [format]);

  const getInputPropsForUpload = () => {
    if (format === 'image' && supportedImageFormats?.length) {
      const formats = supportedImageFormats.map(ext => `.${ext}`).join(', ');
      //e.g. { inputProps: { accept: ".png, .jpg, .webp"} }
      return { inputProps: { accept: formats } }
    }
    if (format === 'video' ){
      return { inputProps: { accept: ".mp4, .mkv, .mpg, .mp2, .mpeg, .mpe, .mpv, .ogg, .m4p, .m4v, .wmv" }}
    }
    return {}
  }

    const loadVideo = file => new Promise((resolve, reject) => {
      try {
          let video = document.createElement('video')
          video.preload = 'metadata'

          video.onloadedmetadata = function () {
              resolve(this)
          }

          video.onerror = function () {
              reject("Invalid video. Please select a video file.")
          }

          video.src = window.URL.createObjectURL(file)
      } catch (e) {
          reject(e)
      }
  })

  const hidePickersIfVideoOrImage = hideGradientIfVideoOrImage && (["image", "video"].includes(format));
  const imageFormats = supportedImageFormats?.length ? supportedImageFormats.map(format => format.toUpperCase()).join(', ') : 'PNG';

  return (
    <>
      <Select
        value={format}
        onChange={setFormat}
        options={options}
        label="Background Type"
        required
        disabled={disabled}
      />
      {format === 'video' && <GroupHeader caption="*Not supported on Roku" />}
      {format !== "basic_black" && !hidePickersIfVideoOrImage && (
        <ColorPicker
          getter={primaryColor}
          setter={setPrimaryColor}
          label={`${format === "single" ? 'Background Color' : 'Gradient Top'}`}
          disabled={disabled}
        />
      )}

      {["gradient", "image", "video"].includes(format) && (
        <>
          {!hidePickersIfVideoOrImage &&
            <ColorPicker getter={secondaryColor} setter={setSecondaryColor} label="Gradient Bottom"   disabled={disabled}/>
          }

          {format !== "gradient" && (
            <>
              <UploadButton
                label={`Background ${_.capitalize(format)}`}
                required
                requirements={
                  format === "image"
                    ? [`${allowedImageWidth} x ${allowedImageHeight} px`, noTransparent ? undefined : 'transparent background', '72 dpi', imageFormats, maxFileSize ? `Maximum size: ${maxFileSize} MB` : undefined].filter(n => n)
                    : ['Duration not more than 5 seconds', 'File size less than 200 MB']
                }
                onUpload={handleAssetUpload}
                validations={handleAssetValidations}
                error={assetError}
                fileRender={() => renderFile(assetUrl, "Asset", setAssetUrl, setAssetBlobId, format)}
                disabled={disabled}
                {...getInputPropsForUpload()}
              >Upload {_.capitalize(format)}</UploadButton>

              {format === "image" && (
                <Input
                  label="Background Image Alt Text"
                  value={altText}
                  onChange={setAltText}
                  style={{ maxWidth: 320 }}
                  disabled={disabled}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
