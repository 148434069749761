import { useQuery, useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import React from "react";
import _ from "lodash";

import { UPDATE_DESIGNS, GET_CURRENT_APP, GET_CURRENT_APP_PROPS } from "../../../api";
import Base from "./Base";
import DataGrid from "../../atoms/DataGrid";
import SubmitButton from "../../atoms/Button/Submit";
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

const columns = [{
  field: "name",
  headerName: "Name",
  width: 400,
  filter: true,
  checkboxSelection: true
}];

export default function FontSelector(props) {
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const theme = useTheme();
  const [googleFonts, setGoogleFonts] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const { fonts, setFonts, designs, ...otherProps } = props;

  const [updateDesign] = useMutation(UPDATE_DESIGNS, {
    onCompleted(data) {
      props.onClose();
    },
    onError(data) {
      console.log(data);
    },
  });

  const handleSelect = (params) => {
    setSelected(params.api.getSelectedNodes().map((n) => n.data));
  };

  const saveFonts = () => {
    const fonts = selected.map((s) => ({
      name: s.name,
      url: s.url,
    }));

    updateDesign({
      variables: {
        appId: app.id,
        workspaceId: appProps.workspaceId,
        designFields: [{
          id: designs[0].id,
          fontsAttributes: fonts
        }],
      },
    });
  };

  const fetchGoogleFonts = () => {
    fetch(
      "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDU0f_uQzs_TXFVTnoArDroHH6I_xhCbKQ"
    )
      .then((res) => res.json())
      .then((res) => {
        let data = [];
        res.items.forEach((f) => {
          f.variants.forEach((v, i) => {
            const name = `${f.family} ${_.capitalize(v)}`;
            data.push({ id: name, name: name, url: f.files[v] });
          });
        });

        setGoogleFonts(data);
      });
  };

  React.useEffect(() => {
    fetchGoogleFonts();
  }, []);


  return (
    <Base
      dimensions={{ width: theme.spacing(100), height: theme.spacing(75) }}
      title="Add Google Fonts"
      titleRight={() => <SubmitButton onClick={saveFonts}>Save</SubmitButton>}
      {...otherProps}
    >
      {googleFonts.length &&
      <DataGrid
        modules={[ ServerSideRowModelModule, RowGroupingModule, AllCommunityModules ]}
        rowData={googleFonts}
        columnDefs={columns}
        onRowSelected={handleSelect}
        isRowSelectable={(params) =>
          !Boolean(fonts.find((f) => f.name === params.data.name))
        }
        autoHeight
        rowSelection={"multiple"}
        checkboxSelection
      />}
    </Base>
  );
}
