import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_NOTIFICATION } from "api";
import Form from "./Form";

export default function Edit(props) {
  const { id } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_NOTIFICATION, { variables: { ...appProps, id: id } });

  return data?.notification ? <Form {...props} notification={data?.notification} /> : null;
}
