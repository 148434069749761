import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  const { menu } = props;
  const { item } = menu;

  const image = item.images[0];
  const url = item.url;
  const title = item.title;
  const summary = item.summary;
  const cardActionAreaPointerStyle = props.noPointer?{pointerEvents:"none"}:{}

  return (
    <Card className={classes.root}>
      <CardActionArea style={cardActionAreaPointerStyle}>
        <CardMedia
          className={classes.media}
          image={image}
          title={title}

        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {summary}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
