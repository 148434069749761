import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import _ from "underscore";
import { useParams } from "react-router-dom";
import {
  GET_CURRENT_APP_PROPS,
  GET_BANNER_GROUP,
  GET_BANNERS,
  CREATE_BANNER,
  UPDATE_BANNER,
} from "api";
import { getHeightAndWidthFromDataUrl } from "helpers/fileUtils";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import Tooltip from "components/atoms/Tooltip";
import UploadButton, { ImagePreview } from "components/molecules/UploadGroup";
import PaperGroup from "components/molecules/Form/PaperGroup";
import New from "components/atoms/Button/New";
import Preview from "./Preview";
import ContentLayout from "screens/Layout/ContentLayout";
import DeeplinkModal from "components/molecules/Modal/BundleSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  backtype: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    },
  },
  listEditing: {
    height: 0,
    overflow: "hidden",
  },
}));

function Banner(props) {
  const classes = useStyles();
  const { parentId } = useParams();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const bannerGroup = useQuery(GET_BANNER_GROUP, {
    variables: { ...appProps, id: parentId },
  });
  const [assetError, setAssetError] = React.useState({
    tv: null,
    mobile: null,
  });
  const [ nameError, setNameError ] = React.useState(null);
  const { banner } = props;

  const [name, setName] = React.useState(banner.name || "");
  const [mobileImageBlobId, setMobileImageBlobId] = React.useState(null);
  const [mobileImageUrl, setMobileImageUrl] = React.useState(banner.mobileImageUrl);

  const [tvImageBlobId, setTvImageBlobId] = React.useState(null);
  const [tvImageUrl, setTvImageUrl] = React.useState(banner.tvImageUrl);

  const [mobileImageAltText, setMobileImageAltText] = React.useState(banner.altText);
  const [showPreview, setShowPreview] = React.useState(false);

  const [showDeepLinkModal, setShowDeepLinkModal] = React.useState(false);
  
  const [actionType, setActionType] = React.useState("item");
  const [actionUrl, setActionUrl] = React.useState(banner.mobileUrl);
  const [actionText, setActionText] = React.useState(banner.mobileUrl);
  
  const [fileMetadata, setFileMetadata] = React.useState("");
  const [isFormChanged, setIsFormChanged] = React.useState(false);


  React.useEffect(() => {
    setIsFormChanged(true);
  },[name, mobileImageUrl, tvImageUrl, mobileImageAltText, actionUrl, actionText])

  React.useEffect(() => {
    setIsFormChanged(false);
  },[])

  const renderImage = (url, alt, urlSetter, blobSetter) => {
    return url ? (
      <ImagePreview {...{ url, alt, urlSetter, blobSetter }} />
    ) : null;
  };

  function handleImageUpload(response, urlSetter, blobSetter) {
    urlSetter(response.url);
    blobSetter(response.signedBlobId);
  }

  async function handleBannerValidations(file, platform) {
    setAssetError({ tv: null, mobile: null });

    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (
      dimensions.width === 2048 &&
      dimensions.height === 450 &&
      platform === "mobile"
    ) {
      return true;
    } else if (
      dimensions.width === 1966 &&
      dimensions.height === 400 &&
      platform === "tv"
    ) {
      return true;
    } else {
      setAssetError({
        ...assetError,
        [platform]: "Invalid dimensions",
      });
      return false;
    }
  }

  const mobileSegment = (
    <Grid item xs={6}>
      <Grid container>
        <Grid item xs={10}>
          <h3>Mobile/Tablet</h3>
        </Grid>
        <Grid item xs={10}>
          <UploadButton
            requirements={["2048 x 450 px", "72 dpi", "JPG"]}
            label="Banner Image"
            onUpload={(res) => handleImageUpload(res, setMobileImageUrl, setMobileImageBlobId)}
            inputProps={{ accept: ".jpeg, .jpg" }}
            validations={(file) => handleBannerValidations(file, "mobile")}
            error={assetError?.mobile}
            helperText="displays in the center of the screen"
            fileRender={() =>
              renderImage(
                mobileImageUrl,
                "Mobile Banner",
                setMobileImageUrl,
                setMobileImageBlobId,
              )
            }
            setFileMetadata={setFileMetadata}
            required
          >
            Upload Image
          </UploadButton>
        </Grid>
        <Grid item xs={10}>
          <Input
            label="Banner Image Alt Text"
            value={mobileImageAltText}
            onChange={setMobileImageAltText}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const tvSegment = (
    <Grid item xs={6}>
      <Grid container>
        <Grid item xs={10}>
          <h3>CTV</h3>
        </Grid>
        <Grid item xs={10}>
          <UploadButton
            requirements={["1966 x 400 px", "72 dpi", "JPG"]}
            label="Banner Image"
            onUpload={(res) => handleImageUpload(res, setTvImageUrl, setTvImageBlobId)}
            inputProps={{ accept: ".jpg, .jpeg" }}
            validations={(f) => handleBannerValidations(f, "tv")}
            error={assetError?.tv}
            helperText="displays in the center of the screen"
            fileRender={() =>
              renderImage(
                tvImageUrl,
                "TV Banner",
                setTvImageUrl,
                setTvImageBlobId
              )
            }
            required
          >
            Upload Image
          </UploadButton>
        </Grid>
      </Grid>
    </Grid>
  );

  const body = (
    <Grid container>
      {tvSegment}
      {mobileSegment}
    </Grid>
  );

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/ads/banners/${parentId}`;


  React.useEffect(()=>{
    console.log(actionUrl);
  },[actionUrl])


  const onSave = () => {
    props.savedSnack.launch();
    history.push(listUrl);
  };

  const [updateBanner, { loading: isUpdating }] = useMutation(UPDATE_BANNER, {
    update(cache, { data: { updateBanner } }) {
      cache.modify({
        fields: {
          banners(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateBanner.banner.id === readField("id", ref)
                ? { ...ref, ...updateBanner.banner }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      console.log(data);
    },
  });

  const [createBanner, { loading: isCreating }] = useMutation(CREATE_BANNER, {
    update(cache, { data: { createBanner } }) {
      const existingBanners = cache.readQuery({
        query: GET_BANNERS,
        variables: { ...appProps, bannerGroupId: parentId },
      });

      cache.writeQuery({
        query: GET_BANNERS,
        variables: { ...appProps, bannerGroupId: parentId },
        data: {
          banners: existingBanners
            ? [...existingBanners.banners, createBanner.banner]
            : [createBanner.banner]
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      console.log(data);
    },
  });

  const stateForm = {
    name: name,
    mobileImageBlobId: mobileImageBlobId,
    tvImageBlobId: tvImageBlobId,
    altText: mobileImageAltText,
    mobileUrl: actionUrl,
    mobileUrlType: actionType
  };

  const isValidate = () => {
    let check = true;
    if(!stateForm.name){
      setNameError("Tracking name can't be blank")
      check = false
    }
    if((!stateForm.mobileImageBlobId && !banner.mobileImageUrl) || stateForm.mobileImageBlobId == "detach"){
      setAssetError(prevErr => ({
        ...prevErr,
        mobile: "Mandatory to upload"
      }))
      check = false
    }
    if((!stateForm.tvImageBlobId && !banner.tvImageUrl) || stateForm.tvImageBlobId == "detach"){
      setAssetError(prevErr => ({
        ...prevErr,
        tv: "Mandatory to upload"
      }))
      check = false
    }
    return check
  }

  const submit = () => {
    if(!isValidate()){
      return;
    }
    if (banner.id != null) {
      updateBanner({
        variables: {
          ...appProps,
          bannerFields: stateForm,
          bannerId: banner.id,
          bannerGroupId: parentId,
        },
      });
    } else {
      createBanner({
        variables: {
          ...appProps,
          bannerGroupId: parentId,
          bannerFields: stateForm,
        },
      });
    }
  };

  const checkIfImageExists = () => {
    if((!mobileImageBlobId && !banner.mobileImageUrl) || mobileImageBlobId == "detach"){
      return false;
    }
    if((!tvImageBlobId && !banner.tvImageUrl) || tvImageBlobId == "detach"){
      return false;
    }
    return true;
  }

  const fetchParentArr = (item) => {
    const treeBundle = [];
    
    if (item.parent && item.parent.key) {
        treeBundle.push(...fetchParentArr(item.parent)) 
    }
    treeBundle.push(item);
    return treeBundle
  }
  const closeDeepLinkModal = (e) => {
    setShowDeepLinkModal(false)
    if (!e || !e[0]) {
      setActionUrl(null)
      setActionText(null)
      return;
    }
    
    let tempItemURL = {text:"ROOT", url:""}
    const itemParent = fetchParentArr(e[0])
    itemParent[itemParent.length-1] = e[0].data;
    itemParent.forEach(element => {
      (element.data && element.data.title)?tempItemURL.text+="> "+element.data.title:tempItemURL.text+="> "+element.title;
      (element.data && element.data.title)?tempItemURL.url+="/"+element.data.id:tempItemURL.url+="/"+element.id;
    });

    setActionUrl(tempItemURL.url)
    setActionType("item")
    setActionText(tempItemURL.text)
  }

  const previewButton = () => (checkIfImageExists()) && (
    <New onClick={() => {setShowPreview(true)}}>Preview</New>
  )

  const onDeeplinkWebURLChange = (e) => {
    // setDeeplinkType("web_url")
    // setDeeplinkWebURL(e)
  }

  // const { data: deeplinkfolder } = useQuery(GET_FOLDER, {
  //   variables: {
  //     ...appProps,
  //     id: deeplinkType==="deeplink"?deeplinkItemURL.split("/").pop():""
  //   }
  // });
  const deeplinkfolder = null;

  // React.useEffect(() => {
  //   deeplinkfolder && deeplinkfolder?.folder && setSelectedDeepLink(deeplinkfolder.folder)
  // }, [deeplinkfolder])

  React.useEffect(() => {
    if (fileMetadata.name) {
      setMobileImageAltText(fileMetadata?.name)
    }
  }, [fileMetadata])

  const nonNullTitle = name || `Untitled Banner`;
  const isLoading = isCreating || isUpdating;

  return (
    <ContentLayout
      title={nonNullTitle}
      right={previewButton}
    >
      <FormLayout
        submit={submit}
        breadcrumbs={[
          {
            name: "Banner Groups",
            link: `/${appProps.workspaceId}/${appProps.appId}/monetization/ads/banners`,
          },
          {
            name: bannerGroup?.data?.bannerGroup?.name,
            link: listUrl,
          },
          { name: nonNullTitle },
        ]}
        isChanged={isFormChanged}
        betterForm={true} 
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <Preview
          open={showPreview}
          title="Banner Preview"
          onClose={() => {setShowPreview(false)}}
          banners={[{
            tvImageUrl, mobileImageUrl
          }]}
        />
        <div className={classes.root}>
          <PaperGroup>
            <Grid container>
              <Grid item xs={12}>
                <Tooltip
                  title="Used to track banner engagement in Google Analytics and to identify the banner in MAZ Control."
                >
                  <Input
                    label="Tracking Name"
                    name="name"
                    value={name}
                    onChange={setName}
                    helperText="Not consumer facing"
                    autoFocus
                    error={name.length == 0 && nameError}
                    required
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </PaperGroup>
          <PaperGroup>
            {body}
          </PaperGroup>
          <PaperGroup>
            <h3>Create Deeplink</h3>
            <Grid container>
              <Grid item xs={6}>
                <New
                color="secondary"
                onClick = {e => {setShowDeepLinkModal(true)}}
                disabled = {actionType!=="item"}
                >
                  Deeplink to...
                </New>
                {actionUrl && <Grid item xs={10} >{actionText}</Grid>}
                
              </Grid>

              {/* <Grid item xs={6}>
                <Input
                  label="Enter WebURL"
                  value={deeplinkWebURL}
                  onChange={onDeeplinkWebURLChange}
                  disabled = {selectedDeepLink?.ancestors?.length>0}
                />
              </Grid> */}
            </Grid>
          </PaperGroup>
        </div>
        {showDeepLinkModal && <DeeplinkModal
          filters={["Bundle::Layout::Section","Bundle::Layout::Symlink"]}
          multiple={true}
          selectSingle={true}
          open={showDeepLinkModal}
          onClose={e => closeDeepLinkModal(e)}
          disableInActive
        />}
      </FormLayout>
    </ContentLayout>
  );
}

export default Banner;
