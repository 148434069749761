import { useQuery, useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import React from "react";
import _ from "lodash";

import { GET_CURRENT_APP } from "../../../api";
import Base from "./Base";
// import DataGrid from "../../atoms/DataGrid";
import SubmitButton from "../../atoms/Button/Submit";
import Input from "components/atoms/Input/BaseInput";
import { getAllLanguagesFromLocale, LANGUAGE_MAP } from "helpers/langUtils";
import Label from "components/atoms/Text/Label";
import GroupHeader from "components/atoms/Text/GroupHeader";
import { Divider, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PaperGroup from "../Form/PaperGroup";
import TextFieldsIcon from "@mui/icons-material/TextFields";

const useStyles = makeStyles((theme) => ({
  parent: {
    flex: 1,
  },
  gap: {
    marginBottom: 30,
  },
}));

export default function LanguageInput(props) {
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const theme = useTheme();
  const classes = useStyles();
  const { onSubmit, property, data, languageOnly, charLimit, ...otherProps } = props;

  const [langObj, setLangObj] = React.useState(_.cloneDeep(data) || {});

  const locales = app.locales;
  const allLanguages = getAllLanguagesFromLocale(locales);

  const onTextChange = (locale, lang, value) => {
    if (languageOnly) {
      setLangObj(prev => ({ ...prev, [lang]: value }));
    } else {
      const newLang = { ...langObj };
      newLang[locale][lang][property.key] = value;
      setLangObj(newLang);
    }
  };

  const onFormSubmit = () => {
    onSubmit(langObj);
    otherProps.onClose();
  };

  const getValue = (locale, lang, key) => {
    if (langObj[locale] && langObj[locale][lang]) {
      return (langObj[locale][lang][key] || "")
    }
    return ""
  }

  let charLimitObj = (id, lang) => {
    if (!property.charLimit) return {};
    return {
      inputProps: { maxLength: property.charLimit },
      helperText: `${
        property.charLimit - getValue(id, lang, property.key).length
      } - character limit`,
    };
  };

  const langOnlyCharLimit = (value="") => {
    if (!charLimit) return {};
    return {
      inputProps: { maxLength: charLimit },
      helperText: `${
        charLimit - value.length
      } - character limit`,
    };
  }

  const showLocalesAndLanguages = !languageOnly && (
    <div className={classes.parent}>
      {locales.map((le) => (
        <PaperGroup>
          <GroupHeader title={le.name} />
          {le.languages.map((ln) => (
            <>
              <GroupHeader caption={LANGUAGE_MAP[ln]} />
              <Input
                onChange={(val) => onTextChange(le.id, ln, val)}
                value={getValue(le.id, ln, property.key)}
                fullWidth
                style={{ maxWidth: 800 }}
                {...charLimitObj(le.id, ln)}
              />
              <div className={classes.gap}> </div>
            </>
          ))}
        </PaperGroup>
      ))}
    </div>
  );

  const showLanguages = languageOnly && (
    <div className={classes.parent}>
      {
        allLanguages.map(lang => (
          <>
            <GroupHeader caption={LANGUAGE_MAP[lang]} />
            <Input
              onChange={(val) => onTextChange(null, lang, val)}
              value={langObj[lang]}
              fullWidth
              style={{ maxWidth: 800 }}
              {...langOnlyCharLimit(langObj[lang])}
            />
            <div className={classes.gap}> </div>
          </>
        ))
      }
    </div>
  )

  return (
    <Base
      dimensions={{ width: theme.spacing(100), height: theme.spacing(55) }}
      title={property?.title || otherProps.title || "Languages"}
      titleRight={() => (
        <SubmitButton onClick={onFormSubmit}>Save</SubmitButton>
      )}
      {...otherProps}
    >
      {languageOnly ? showLanguages : showLocalesAndLanguages}
    </Base>
  );
}
