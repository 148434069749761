import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import List from "../List";
import Programmes from './Programmes';

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Contents(props) {
  const { path } = useRouteMatch();
  const { savedSnack, category } = props;

  const commonProps = { savedSnack };

  return (
    <Switch>
      <Route path={`${path}/:liveChannelId/schedules/:liveScheduleId/programs`}>
        <Programmes {...commonProps} hub="content" />
      </Route>
      <Route path={path}>
        <List {...commonProps} category="channels" />
      </Route>
    </Switch>
  );
}

export default withSavedSnack(Contents);
