export default function isValid(platformDetails, appStores) {
    const { metadata, assetUrls, storeId } = platformDetails;
    const storeDetails = appStores.find(s => s.id === storeId);
    const {metadata: storeMetadata} = storeDetails;

    if (!metadata || !storeMetadata || !assetUrls ) return false;

    if (!storeMetadata.app_store_name) return false;
    if (!storeMetadata.description) return false;
    if (!storeMetadata.primary_category) return false;
    if (!storeMetadata.secondary_category) return false;
    if (!storeMetadata.rating) return false;
    if (!storeMetadata.app_store_name) return false;
    if (!storeMetadata.support_email) return false;
    if (!storeMetadata.keywords) return false;

    if (!storeMetadata.username) return false;
    if (!storeMetadata.password) return false;

    if (!metadata.ga_tracking_id) return false;

    if (!assetUrls.app_icon) return false;
    if (!assetUrls.primary_cover) return false;
    if (!assetUrls.secondary_covers || assetUrls.secondary_covers.length < 2) return false;
    if (!assetUrls.launcher_background) return false;
    if (!assetUrls.splash_background) return false;


    return true;
}