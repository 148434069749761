import React from "react";
import Base from "./Base";
import Button from 'components/atoms/Button/New';
import { useTheme } from "@mui/material/styles";

export default function BundleSelector(props) {
  const theme = useTheme();
  const { onSelect, ...otherProps } = props;

  return (
    <Base
      dimensions={{ width: theme.spacing(28), height: theme.spacing(28) }}
      title="Select"
      {...otherProps}
    >
      <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
        <SelectButton onClick={() => onSelect(["Bundle::Playlist::Manual", "Bundle::Playlist::Search", "Bundle::Playlist::Feed"])}>Playlist</SelectButton>
        <SelectButton onClick={() => onSelect("manual")}>Manual</SelectButton>
      </div>
    </Base>
  );
}

const SelectButton = (props) => {
  const { children, ...otherProps } = props;
  const theme = useTheme();

  return (
    <div style={{ padding: theme.spacing(1) }}>
      <Button {...otherProps} style={{ width: 120 }}>{children}</Button>
    </div>
  );
}
