import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  caption: {
    color: theme.palette.grey["600"],
    marginBottom: theme.spacing(1),
  }
}));

export default function GroupHeader({ title, caption }) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="p" gutterBottom className={classes.caption}>{caption}</Typography>
    </>
  );
};
