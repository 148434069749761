import React from 'react';
import Confirm from './Confirm';

export default function Logout(props) {
  return (
    <Confirm
      {...props}
      content="Are you sure you want to logout?"
      acceptLabel="Logout"
    />
  );
}
