import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import urlJoin from "url-join";

import {
  GET_BANNER_GROUPS,
  GET_CURRENT_APP_PROPS,
  DELETE_BANNER_GROUP,
} from "api";

import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import ContentLayout from "screens/Layout/ContentLayout";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  rowClass: {
    '& > div': {
      border: 'none !important',
    },
    cursor: 'pointer'
  }
}))

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 400,
    cellRenderer: "titleCellRenderer",
    sortable:true,
    sort: 'asc',
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    minWidth:300,
    flex: 1,
  },
];

function BannerGroups(props) {
  const classes = useStyles();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_BANNER_GROUPS, { variables: appProps });
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const { pathname } = useLocation();
  const history = useHistory();

  const onOpen = (bannerGroup) => {
    history.push(urlJoin(pathname, bannerGroup.id));
  };

  const onEdit = (bannerGroup, event) => {
    event && event.stopPropagation();
    history.push(urlJoin(pathname, `edit/${bannerGroup.id}`));
  };

  const onDelete = (bannerGroup, event) => {
    event && event.stopPropagation();
    setDeleteConfirmation(bannerGroup);
  };

  const [deleteBannerGroup] = useMutation(DELETE_BANNER_GROUP, {
    update(cache, { data: { deleteBannerGroup } }) {
      cache.modify({
        fields: {
          bannerGroups(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteBannerGroup.bannerGroup.id !== readField("id", ref)
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
    },
    onError(data) {
      console.log(data);
    },
  });

  const deleteBannerGroupApi = (id) => {
    closeDeleteConfirmation();
    deleteBannerGroup({
      variables: {
        ...appProps,
        bannerGroupId: id,
      },
    });
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(null);
  };

  const handleSelect = (params) => {
    onOpen(params.data);
  }

  return (
    <ContentLayout
      title="Banner Groups"
      fab={{ onClick: () => history.push(urlJoin(pathname, "new")) }}
    >
      <FormLayout breadcrumbs={[{ name: "Banner Groups" }]}>
        <DataGrid
          rowData={
            data?.bannerGroups
              ? data.bannerGroups.map((bg) => ({
                  ...bg,
                  title: bg.name,
                  actions: { onEdit, onDelete },
                }))
              : []
          }
          columnDefs={columns}
          accentedSort={true}
          rowSelection='single'
          onRowSelected={handleSelect}
          rowClass={classes.rowClass}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => {
            deleteBannerGroupApi(deleteConfirmation.id);
          }}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default BannerGroups;
