export const WEB_ASSET_MAP = {
  favicon: {
    label: "Favicon",
    requirements: ["16 x 16 px", "PNG/ICO/JPG"],
    formats: ["image/png", "image/jpg", "image/jpeg", "image/x-icon", "image/vnd.microsoft.icon"],
    dimensions: [[16, 16]],
    tooltip: "This will be visible on web browser tab"
  },
};

export const APPLE_ASSET_MAP = {
  appIcon: {
    label: "App Icon*",
    requirements: [
      "1024 x 1024 px",
      "square with corners",
      "no transparency",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [[1024, 1024]],
  },
  iphoneXCover: {
    label: "iPhone XS Max, XR Screenshots*",
    requirements: [
      "At least 1 required",
      "upload up to 5",
      "Allowed Sizes: 1242 x 2688 px, 2688 x 1242 px",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [
      [1242, 2688],
      [2688, 1242],
    ],
    max: 5,
  },
  iphoneCover: {
    label: "iPhone 8 Plus Screenshots*",
    requirements: [
      "At least 1 required",
      "upload up to 5",
      "Allowed Sizes: 1242 x 2208 px, 2208 x 1242 px",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [
      [1242, 2208],
      [2208, 1242],
    ],
    max: 5,
  },
  ipadCover: {
    label: "iPad Pro Screenshots*",
    requirements: [
      "At least 1 required",
      "upload up to 5",
      "Allowed Sizes: 2048 x 2732 px, 2732 x 2048 px",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [
      [2048, 2732],
      [2732, 2048],
    ],
    max: 5,
  },
  iphoneXPreview: {
    label: "iPhone XS Max, iPhone XR",
    requirements: [
      "Allowed Sizes: 886 x 1920 px, 1920 x 886 px",
      "15-30 seconds long",
      "Upload up to 3 videos",
      "Use footage captured on the device",
      "Mov, m4v (H.264 only), mp4 (H.264 only)",
    ],
    formats: ["video/quicktime", "video/mp4"],
    dimensions: [
      [886, 1920],
      [1920, 886],
    ],
    max: 3,
    type: "video",
  },
  iphonePreview: {
    label: "iPhone 6s Plus, iPhone 7 Plus, iPhone 8 Plus",
    requirements: [
      "Allowed Sizes: 1080 x 1920 px, 1920 x 1080 px",
      "15-30 seconds long",
      "Upload up to 3 videos",
      "Use footage captured on the device",
      "Mov, m4v (H.264 only), mp4 (H.264 only)",
    ],
    formats: ["video/quicktime", "video/mp4"],
    dimensions: [
      [1080, 1920],
      [1920, 1080],
    ],
    max: 3,
    type: "video",
  },
  ipadPreview: {
    label: "iPad Pro",
    requirements: [
      "Allowed Sizes: 1200 x 1600 px, 1600 x 1200 px",
      "15-30 seconds long",
      "Upload up to 3 videos",
      "Use footage captured on the device",
      "Mov, m4v (H.264 only), mp4 (H.264 only)",
    ],
    formats: ["video/quicktime", "video/mp4"],
    dimensions: [
      [1200, 1600],
      [1600, 1200],
    ],
    max: 3,
    type: "video",
  },
  topLayerIcon: {
    label: "App Icon (Top Layer)*",
    requirements: ["1280 x 768 px", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1280, 768]],
  },
  middleLayerIcon: {
    label: "App Icon (Middle Layer)*",
    requirements: ["1280 x 768 px", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1280, 768]],
  },
  bottomLayerIcon: {
    label: "App Icon (Bottom Layer)*",
    requirements: ["1280 x 768 px", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1280, 768]],
  },
  tvIcon: {
    label: "TV App Icon*",
    requirements: ["1280 x 768 px", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1280, 768]],
  },
  tvCover: {
    label: "Screenshots*",
    requirements: ["1920 x 1080 px", "upload up to 5", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1920, 1080]],
    max: 5,
  },
  topShelf: {
    label: "Top Shelf Image*",
    requirements: ["4640 x 1440 px", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[4640, 1440]],
  },
  firebasePlist: {
    label: "GoogleService-Info.plist (Firebase Config)*",
    type: "file",
  },
  appStoreCertificate: {
    label: "App Store Certificate (P8)*",
    type: "file",
  },
};

export const GOOGLE_ASSET_MAP = {
  appIcon: {
    label: "App Icon*",
    requirements: [
      "512 x 512 px",
      "square with corners",
      "no transparency",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [[512, 512]],
  },
  cover: {
    label: "Screenshots*",
    requirements: [
      "Allowed Sizes: 320 x 480 px or 480 x 320 px, 480 x 800 px or 800 x 480 px, 480 x 854 px or 854 x 480 px, 1280 x 720 px or 720 x 1280 px, 1280 x 800 px or 800 x 1280 px",
      "At least 2 required",
      "Upload up to 8",
      "72 dpi",
      "no rounded corners",
      "no reflection",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [
      [320, 480],
      [480, 320],
      [480, 800],
      [800, 480],
      [480, 854],
      [854, 480],
      [1280, 720],
      [720, 1280],
      [1280, 800],
      [800, 1280],
    ],
    min: 2,
    max: 8,
    tooltip: <div>These are examples of your content, to be displayed on the details page for your app in the Google Play store. <br/> They can be screenshots of anything, but we recommend exciting content to entice downloaders.</div>
  },
  promoGraphic: {
    label: "Promo Graphic",
    requirements: [
      "180 x 120 px",
      "72 dpi",
      "PNG",
      "no rounded corners",
      "no reflection",
    ],
    formats: ["image/png"],
    dimensions: [[180, 120]],
    tooltip: "(Optional) This graphic is only used in old devices, which are slowly being phased out."
  },
  featureGraphic: {
    label: "Feature Graphic*",
    requirements: [
      "1024 x 500 px",
      "72 dpi",
      "no rounded corners",
      "no reflection",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [[1024, 500]],
    tooltip: "This graphic will display like banner on your app store listing in the Google Play store. The graphic sits atop the page, behind your app icon."
  },
  notificationIcon: {
    label: "Notification Icon*",
    requirements: [
      "87 x 87 px",
      "72 dpi",
      "transparent background",
      "white",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [[87, 87]],
    tooltip: "This icon will show up next to push notifications and in the notification bar across the top of Android devices."
  },
  tvIcon: {
    label: "TV App Icon*",
    requirements: ["512 x 512 px", "no transparency", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[512, 512]],
    tooltip: "The most important identifying asset of your app. It will represent the app on the device home screen as well as in the app store."
  },
  tvFeatureGraphic: {
    label: "Feature Graphic*",
    requirements: [
      "1024 x 500 px",
      "no transparency",
      "72 dpi",
      "PNG, JPG, JPEG",
    ],
    formats: ["image/png", "image/jpg", "image/jpeg"],
    dimensions: [[1024, 500]],
    tooltip: "This graphic will display like banner on your app store listing in the Google Play store. The graphic sits atop the page, behind your app icon."
  },
  tvBanner: {
    label: "Banner*",
    requirements: ["1280 x 720 px", "no transparency", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1280, 720]],
    tooltip: "Used to represent your app in the OTT store and home screen. It's an app store requirement to display your app name in this banner."
  },
  tvSmallBanner: {
    label: "Banner (Small)*",
    requirements: ["320 x 180 px", "no transparency", "320 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[320, 180]],
    tooltip: "Used to represent your app in the OTT store and home screen. It's an app store requirement to display your app name in this banner."
  },
  tvCover: {
    label: "Screenshots*",
    requirements: [
      "1920 x 1080 px",
      "Atleast 3 required",
      "Upload up to 8",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [[1920, 1080]],
    max: 8,
    tooltip: "These are examples of your content that will be displayed for your app within the App Store. They can be screenshots of anything, but we recommend exciting content with beautiful images to entice downloaders."
  },
  serviceAccountKeyJson: {
    label: "Service Account Key JSON*",
    type: "file",
    formats: ["application/json"],
  },
  keyStore: {
    label: "Key store file*",
    type: "file",
  },
};

export const ROKU_ASSET_MAP = {
  largeAppIcon: {
    label: "App Icon (HD)*",
    requirements: ["336 x 210 px", "no transparency", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[336, 210]],
    tooltip: <div>This will be used for the app icon on the home screen as well as in the App Store.<br/><br/>Changing App Icon will require an app update.</div>
  },
  cover: {
    label: "Screenshots*",
    requirements: ["1920 x 1080 px", "Upload up to 5", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1920, 1080]],
    max: 5,
    tooltip: <div>These are examples of your app's content that will be displayed in the App Store. They can be screenshots of anything, but we recommend exciting content with beautiful images to entice downloaders..
    <br/><br/>You may upload up to five screenshots.</div>
  },
  poster: {
    label: "Poster (Full HD)*",
    requirements: ["540 x 405 px", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[540, 405]],
    tooltip: "Treat this image as if it is your app icon. It will only be used in the Roku Channel store."
  },
  smallAppIcon: {
    label: "App Icon (SD)*",
    requirements: ["248 x 140 px", "no transparency", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[248, 140]],
    tooltip: <div>This will be used for the app icon on the home screen as well as in the App Store.<br/><br/>Changing App Icon will require an app update.</div>
  },
};

export const AMAZON_ASSET_MAP = {
  appIcon: {
    label: "App Icon*",
    requirements: [
      "512 x 512 px",
      "square with corners",
      "no transparency",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [[512, 512]],
  },
  cover: {
    label: "Screenshots*",
    requirements: [
      "Allowed Sizes: 800 x 480 px or 480 x 800 px, 1024 x 600 px or 600 x 1024 px, 1280 x 800 px or 800 x 1280 px, 1280 x 720 px or 720 x 1280 px, 1920 x 2000 px or 2000 x 1920 px",
      "At least 3 required",
      "Upload up to 10",
      "no rounded corners",
      "no reflection",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [
      [800, 480],
      [480, 800],
      [1024, 600],
      [600, 1024],
      [1280, 800],
      [800, 1280],
      [1280, 720],
      [720, 1280],
      [1920, 2000],
      [2000, 1920],
    ],
    min: 3,
    max: 10,
  },
  preview: {
    label: "Video",
    requirements: [
      "Upload up to 5",
      "mpeg-2, wmv, mov, flv, avi, or h.264 mpeg-4",
      "Minimum 720px wide (4:3 or 16:9)",
      "1200 kbps or higher",
    ],
    formats: ["video/mp4", "video/x-flv", "video/x-msvideo", "video/x-ms-wmv", "video/quicktime", "video/mpeg"],
    dimensions: [],
    type: "video",
    max: 5,
  },
  tvIcon: {
    label: "App Icon*",
    requirements: ["1280 x 720 px", "no transparency", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1280, 720]],
  },
  tvCover: {
    label: "Screenshots*",
    requirements: [
      "1920 x 1080px",
      "At least 3 required",
      "Upload up to 8",
      "72 dpi",
      "PNG",
    ],
    formats: ["image/png"],
    dimensions: [[1920, 1080]],
    min: 3,
    max: 8,
  },
  storeBackground: {
    label: "Store Background*",
    requirements: ["1920 x 1080px", "no transparency", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1920, 1080]],
  },
};

export const SAMSUNG_ASSET_MAP = {
  logo: {
    label: "Transparent Logo*",
    requirements: ["1920 x 1080 px", "File Limit: 300 KB", "PNG"],
    formats: ["image/png"],
    dimensions: [[1920, 1080]],
    fileSize: 300000,
  },
  cover: {
    label: "Screenshots*",
    requirements: [
      "1920 x 1080 px",
      "File Limit: 500 KB",
      "Required 4",
      "JPG, JPEG",
    ],
    formats: ["image/jpg", "image/jpeg"],
    dimensions: [[1920, 1080]],
    min: 4,
    max: 4,
    fileSize: 500000,
  },
  appIcon: {
    label: "Icon*",
    requirements: ["512 x 423 px", "File Limit: 300 KB", "PNG"],
    formats: ["image/png"],
    dimensions: [[512, 423]],
    fileSize: 300000,
  },
  backgroundImage: {
    label: "Background Image*",
    requirements: ["1920 x 1080 px", "File Limit: 300 KB", "PNG, JPG, JPEG"],
    formats: ["image/png", "image/jpg", "image/jpeg"],
    dimensions: [[1920, 1080]],
    fileSize: 300000,
  },
};

export const LG_ASSET_MAP = {
  primaryCover: {
    label: "Primary Screenshot*",
    requirements: [
      "Allowed Sizes: 1920 x 1080 px, 1280 x 720 px",
      "File Limit: 5 MB",
      "PNG, JPG",
    ],
    formats: ["image/png", "image/jpg", "image/jpeg"],
    dimensions: [
      [1920, 1080],
      [1280, 720],
    ],
    fileSize: 5000000,
  },
  secondaryCover: {
    label: "Secondary Screenshot*",
    requirements: [
      "At least 2 required",
      "Upload up to 5",
      "Allowed Sizes: 1920 x 1080 px, 1280 x 720 px",
      "File Limit: 5 MB",
      "PNG, JPG",
    ],
    formats: ["image/png", "image/jpg", "image/jpeg"],
    dimensions: [
      [1920, 1080],
      [1280, 720],
    ],
    fileSize: 5000000,
    min: 2,
    max: 5,
  },
  appIcon: {
    label: "App Icon*",
    requirements: ["400 x 400 px", "File Limit: 5 MB", "PNG, JPG"],
    formats: ["image/png", "image/jpg", "image/jpeg"],
    dimensions: [[400, 400]],
    fileSize: 5000000,
  },
  launcherBackground: {
    label: "Launcher Background*",
    requirements: ["1920 x 1080 px", "File Limit: 10 MB", "PNG, JPG"],
    formats: ["image/png", "image/jpg", "image/jpeg"],
    dimensions: [[1920, 1080]],
    fileSize: 10000000,
  },
  splashBackground: {
    label: "Splash Screen Background*",
    requirements: ["1920 x 1080 px", "File Limit: 10 MB", "PNG, JPG"],
    formats: ["image/png", "image/jpg", "image/jpeg"],
    dimensions: [[1920, 1080]],
    fileSize: 10000000,
  },
};

export const VIZIO_ASSET_MAP = {
  appIcon: {
    label: "App Icon*",
    requirements: ["216 x 216 px", "SVG"],
    formats: ["image/svg+xml"],
    dimensions: [[216, 216]],
  },
  heroCarousel: {
    label: "Hero Carousel*",
    requirements: ["1560 x 390 px", "JPG, JPEG"],
    formats: ["image/jpg", "image/jpeg"],
    dimensions: [[1560, 390]],
  },
  availableImage: {
    label: "Available Image",
    requirements: ["Width should be greater than and equal to 1500px and Height should be less than width.", "PNG"],
    formats: ["image/png"],
    dimensions: ["custom"],
  },
};

export const MICROSOFT_ASSET_MAP = {
  posterArt: {
    label: "Poster Art",
    requirements: ["Allowed Sizes: 720 x 1080 px, 1440 x 2160px", "File Limit: 50 MB", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[720, 1080], [1440, 2160]],
    fileSize: 50000000,
    tooltip: "Poster art is highly recommended. Used as the main logo for customers on Windows 10, and required for proper display for customers on Xbox"
  },
  cover: {
    label: "Screenshots*",
    requirements: [
      "Allowed Sizes: 1920 x 1080 px, 1080 x 1920 px, 3840 x 2160 px, 2160 x 3840 px",
      "At least 4 required",
      "File Limit: 50 MB",
      "Upload up to 10",
      "72 dpi",
      "PNG"
    ],
    formats: ["image/png"],
    dimensions: [[1920, 1080], [3840, 2160], [1080, 1920], [2160, 3840]],
    max: 10,
    fileSize: 50000000,
    tooltip: "Screenshots are images of your app that are displayed to your customers in your app's Store listing."
  },
  boxArt: {
    label: "Box Art",
    requirements: ["Allowed Sizes: 1080 x 1080 px, 2160 x 2160 px", "File Limit: 50 MB", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[1080, 1080], [2160, 2160]],
    fileSize: 50000000,
    tooltip: "Box art is recommended for best display. May be used in various Store layouts. Will be used as your main logo if 9:16 Poster art is not provided."
  },
  appTile: {
    label: "App Tile",
    requirements: ["300 x 300 px", "File Limit: 50 MB", "72 dpi", "PNG"],
    formats: ["image/png"],
    dimensions: [[300, 300]],
    fileSize: 50000000,
    tooltip: "App tile icon is required for proper display if your product targets Windows Phone 8.1 or earlier. Used in addition to logo images."
  },
};
