import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_DESIGNS } from "api";
import Form from "./Form";

export default function Edit(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { designId, id } = useParams();
  const { data } = useQuery(GET_DESIGNS, { variables: appProps });
  const design = data?.designs?.find(d => d.id === designId);

  const screen = design?.onboardingScreens.find(s => s.id === id);

  return (
    screen
      ? <Form {...props} design={design} screen={screen} />
      : null
  );
}
