import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import Dashboard from "./Dashboard";
import Customers from "./Customers";
import Tiers from "./Transactions";
import Stores from "./Stores";
import PushNotifications from "./PushNotifications";
import Payment from "./PaymentGateways";
import Subscriptions from "./Subscriptions"
import Registrations from "./Registrations";
import Advertising from "./Advertising"
import Integrations from "./Integrations"
import Onboarding from './Onboarding';

export default function Monetization(props) {
  const { path } = useRouteMatch();
  const { savedSnack, isMultiLangOrRegion } = props;

  const commonProps = { savedSnack, isMultiLangOrRegion };

  return (
    <Switch>
      <Route path={`${path}/transaction`}>
        <Tiers {...commonProps} />
      </Route>
      <Route path={`${path}/customers`}>
        <Customers {...commonProps} />
      </Route>
      {/* <Route path={`${path}/dashboard`}>
        <Dashboard />
      </Route> */}
      <Route path={`${path}/notifications`}>
        <PushNotifications {...commonProps} />
      </Route>
      <Route path={`${path}/stores`}>
        <Stores {...commonProps} />
      </Route>
      <Route path={`${path}/payment`}>
        <Payment {...commonProps} />
      </Route>
      <Route path={`${path}/subscriptions`}>
        <Subscriptions {...commonProps} />
      </Route>
      <Route path={`${path}/registration_walls`}>
        <Registrations {...commonProps} />
      </Route>
      <Route path={`${path}/ads`}>
        <Advertising {...commonProps} />
      </Route>
      <Route path={`${path}/integrations`}>
        <Integrations {...commonProps} />
      </Route>
      <Route path={`${path}/onboarding`}>
        <Onboarding {...commonProps} hub="content"/>
      </Route>
      <Redirect to={`${path}/customers`} />
    </Switch>
  );
}
