import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import makeStyles from '@mui/styles/makeStyles';
import { GET_TIERS, REARRANGE_TIERS, UPDATE_TIER, GET_CURRENT_APP } from "api";
import DataGrid from "components/atoms/DataGrid";
import { withSavedSnack } from "components/molecules/Alerts/Saved";
import urljoin from 'url-join';
import FormLayout from "components/molecules/Form/FormLayout";
import _ from "underscore";
import ContentLayout from 'screens/Layout/ContentLayout';
import priceFormatter from 'helpers/priceFormatter';
import AssetModal from "components/molecules/Modal/AssetModal";

const useStyles = makeStyles((theme) => ({
  list: {
    flex: 1,
    width: "100%",
    display: "flex",
    margin: '15px 0'
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  rowClass: {
    '&.first-row::after': {
      backgroundColor: 'white'
    },
    '& > div': {
      border: 'none !important',
    },
    cursor: 'pointer'
  }
}));

function List(props) {
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();
  const { appProps, name, monetizationPath, filter } = props;

  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data, loading, refetch } = useQuery(GET_TIERS, { variables: appProps });
  const languages = _.uniq(_.flatten(app.locales.map(l => l.languages)));

  const tierType = `Tier::${filter}`;
  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/${monetizationPath}`;

  const [tierList, setTierList] = React.useState([]);

  const [showAssetIdModal, setShowAssetIdModal] = React.useState(null);
  const closeAssetIdModal = () => setShowAssetIdModal(null);

  const [updatePurchaseTier] = useMutation(UPDATE_TIER, {
    update(cache, { data: { updateTier } }) {
      const existingTiers = client.readQuery({ query: GET_TIERS, variables: appProps });

      let newTiers = [];
      existingTiers.tiers.forEach((t, i) => {
        if (updateTier.tier.type === "Tier::Registration" && t.type === updateTier.tier.type && t.id !== updateTier.tier.id) {
          newTiers.push({ ...t, isActive: false });
        } else if (t.id === updateTier.tier.id) {
          newTiers.push({ ...t, ...updateTier.tier });
        } else {
          newTiers.push(t);
        }
      });

      client.writeQuery({
        query: GET_TIERS,
        variables: appProps,
        data: { tiers: newTiers },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch()
    },
    onError(data) {
      console.log(data);
    },
  });

  const [rearrangeTiers] = useMutation(REARRANGE_TIERS, {
    onCompleted(data) {
      refetch();
      props.savedSnack.launch()
    },
    onError(data) {
      console.log(data);
    },
  });

  React.useEffect(() => {
    if (data?.tiers) {
      const tierArr = data?.tiers?.filter((t) => t.type === tierType) || [];
      setTierList(tierArr);
    }
  }, [data])

  const onEdit = (tier, event) => {
    event && event.stopPropagation();
    history.push(urljoin(listUrl, `edit/${tier.id}`));
  };

  const onOpen = (tier) => {
    history.push(urljoin(listUrl, `${tier.id}/list`));
  }

  const onSort = (params) => {
    const preventFirstRowSorting = true;
    if (params.node.data.sortOrder === 0) {
      const tierArr = data?.tiers?.filter((t) => t.type === tierType);
      setTierList(tierArr);
      return null;
    }
    const draggedTiers = params.api.rowModel.rowsToDisplay.map((o, i) => {
      let obj = {...o.data};
      if (preventFirstRowSorting) {
        if (obj.sortOrder === 0 && i !== 0) {
          obj.sortOrder = 0;
        }
        else if (obj.sortOrder !== 0 && i === 0) {
          obj.sortOrder = 1
        } else {
          obj.sortOrder = i
        }
      } else obj.sortOrder = i;
      return obj;
    })

    const sortedTiers = _.sortBy(draggedTiers, (f) => f.sortOrder)
    rearrangeTiers({
      variables: {
        ...appProps,
        tierIds: sortedTiers.map(t => t.id)
      }
    })
    setTierList(sortedTiers);
  };

  const toggleActive = (tier, val, event) => {
    event && event.stopPropagation();
    updatePurchaseTier({
      variables: {
        ...appProps,
        tierId: parseInt(tier.id),
        tierFields: {
          title: tier.title,
          isActive: val
        }
      }
    })
  }

  const allowDrag = (params) => {
    if (filter !== 'Subscription') return false;
    if (params.data.sortOrder === 0) return false;
    return true;
  }

  const columns = [
    {
      field: "title",
      headerName: "Name",
      width: 500,
      cellRenderer: "titleCellRenderer",
      sort: 'asc',
      filter: true
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: false,
      valueGetter: (params) => priceFormatter(params.data.price.amount)
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      cellRenderer: "actionCellRenderer",
      sortable:false,
      flex: 1,
    },
  ];

  const subscriptionColumns = [
    {
      field: "title",
      headerName: "Name",
      width: 500,
      cellRenderer: "titleCellRenderer",
      rowDrag: allowDrag,
      sortable: true,
      filter: true
    },
    {
      field: "offerSales",
      headerName: "Offer for sale",
      width: 150,
      filter: false,
      cellRenderer: "pollCellRenderer",
      sortable: false,
      valueGetter: (params) => params.data.metadata.offer_sales,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 300,
      sortable: false,
      cellRenderer: "actionCellRenderer",
      flex: 1,
    },
  ];

  const registrationColumns = subscriptionColumns;
  const columnDefs =
    filter === 'Subscription'
      ? subscriptionColumns
      : filter === 'Registration'
      ? registrationColumns
      : columns;

  const rowClassRules = {
    'first-row': function(params) {
      return params.node.data.sortOrder === 0
    }
  }

  const handleSelect = (params) => {
    onOpen(params.data);
  }

  if (loading || !tierList) {
    return null
  }

  const onAssetId = (tier, ev) => {
    ev && ev.stopPropagation();
    setShowAssetIdModal(tier);
  };

  const handleAssetId = (val) => {
    updatePurchaseTier({
      variables: {
        ...appProps,
        tierId: showAssetIdModal.id,
        tierFields: {
          thirdPartyId: val,
        }
      }
    }).then(data => {
      closeAssetIdModal();
    });
  };

  return (
    <ContentLayout
      title={name}
      fab={{ onClick: () => history.push(urljoin(listUrl, `new`)) }}
    >
      <FormLayout
        breadcrumbs={[
          { name: name },
        ]}
      >
        <DataGrid
          rowData={
            _.sortBy(tierList, (f) => f.sortOrder)
            .map((t) => ({
              ...t,
              actions: {
                onEdit,
                toggle: {
                  getter: t.isActive,
                  setter: (value, event) => toggleActive(t, value, event),
                },
                onAssetId: filter === "Subscription" ? onAssetId : null,
              },
            })) || []
          }
          columnDefs={columnDefs}
          defaultColDef={{ sortable: true }}
          accentedSort={true}
          rowDragManaged={true}
          onRowDragEnd={onSort}
          animateRows={true}
          immutableData={true}
          getRowNodeId={(data) => data.id}
          suppressMoveWhenRowDragging={true}
          rowClassRules={rowClassRules}
          rowClass={classes.rowClass}
          rowSelection='single'
          onRowSelected={handleSelect}
        />

        {Boolean(showAssetIdModal) && (
          <AssetModal
            open={Boolean(showAssetIdModal)}
            onClose={closeAssetIdModal}
            submit={handleAssetId}
            assetId={showAssetIdModal.thirdPartyId}
          />
        )}
      </FormLayout>
    </ContentLayout>
  );
}

export default withSavedSnack(List);
