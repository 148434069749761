import React, {useEffect, useState} from 'react';

import Layout from './Layout';
import Input from 'components/atoms/Input/BaseInput';
import UploadButton from 'components/molecules/UploadGroup';
import ColorPicker from 'components/atoms/Input/ColorPicker';
import LanguageText from 'components/molecules/LanguageText';
import HelpText from 'components/atoms/Text/Help';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import { withDesign } from './Design';
import { getHeightAndWidthFromDataUrl } from 'helpers/fileUtils';
import { generateLocalesForDesigns } from 'helpers/langUtils';

function Launch(props) {
  const { simulator, designs, isMultiLangOrRegion } = props;
  const design = simulator.design;

  function getTextLocales() { return generateLocalesForDesigns(designs, ["launchLogoAltText"]) };
  const locales = getTextLocales();
  const defaultLocale = { locale: design.locale.id, lang: design.language };
  
  const [ textLocales, setTextLocales ] = useState(locales);
  const launchLogoAltText = textLocales[defaultLocale.locale][defaultLocale.lang]["launchLogoAltText"];
  const [defaultFileName, setDefaultFileName] = useState(design.launchLogoAltText);

  const [ themeMobileLaunchBackground, setThemeMobileLaunchBackground ] = useState(design.themeMobileLaunchBackground);
  const [ mobileLaunchLogoUrl, setMobileLaunchLogoUrl ] = useState(design.mobileLaunchLogoUrl);
  const [ tvSplashUrl, setTvSplashUrl ] = useState(design.tvSplashUrl);
  const [ mobileLaunchLogoBlobId, setMobileLaunchLogoBlobId ] = useState(null);
  const [ tvSplashBlobId, setTvSplashBlobId ] = useState(null);
  
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  
  const [ launchLogoError, setLaunchLogoError ] = useState(null);
  const [ tvSplashError, setTvSplashError ] = useState(null);

  useEffect(() =>{
    onLangInputChange(defaultFileName, "launchLogoAltText")
  },[defaultFileName])

  /*    TV variables    */
  useEffect(() => {
    setIsFormChanged(true);
  }, [tvSplashUrl]);

  /*    Mobile variables    */  
  useEffect(() => {
    setIsFormChanged(true);
  }, [mobileLaunchLogoUrl,themeMobileLaunchBackground,textLocales]);

  useEffect(() => {
    setTimeout(()=>setIsFormChanged(false),0);
  }, [])
  
  const cancel = () => {
    /*    TV variables    */
    setTvSplashUrl(design.tvSplashUrl);
    /*    Mobile Variables    */
    setMobileLaunchLogoUrl(design.mobileLaunchLogoUrl);
    setThemeMobileLaunchBackground(design.themeMobileLaunchBackground);
    setTextLocales(locales);
    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const commonFields = {
    themeMobileLaunchBackground,
    launchLogoAltText,
  };

  const stateDesign = {
    ...commonFields,
    mobileLaunchLogoUrl,
    tvSplashUrl,
  };

  const stateForm = {
    ...commonFields,
    mobileLaunchLogoBlobId,
    tvSplashBlobId,
    textLocales
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm,
    simulator,
    tab: "Launch Screen",
    headerTitle: "Launch",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  function multiText(title, key, charLimit = 0, buttonMargin) {
    const buttonProps = buttonMargin
      ? { style: { marginBottom: buttonMargin } }
      : {};
    return (
      <LanguageText
        onSubmit={setTextLocales}
        data={textLocales}
        property={{ key, charLimit }}
        buttonProps={buttonProps}
        title={title}
      />
    );
  }

  function onLangInputChange(val, key) {
    const textLocaleDuplicate = {...textLocales}
    textLocaleDuplicate[defaultLocale.locale][defaultLocale.lang][key] = val;
    setTextLocales(textLocaleDuplicate);
  }

  function handleLaunchLogoUpload(response) {
    setMobileLaunchLogoUrl(response.url);
    setMobileLaunchLogoBlobId(response.signedBlobId);
  }

  async function handleLaunchLogoValidations(file) {
    setLaunchLogoError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (dimensions.width === 1200 && dimensions.height === 600) {
      const alttext = file.name?.split('.')[0];
      setDefaultFileName(alttext);
      return true;
    } else {
      setLaunchLogoError("Invalid dimensions");
      return false;
    }
  }

  function handleTvSplashUpload(response) {
    setTvSplashUrl(response.url);
    setTvSplashBlobId(response.signedBlobId);
  }

  async function handleTvSplashValidations(file) {
    setTvSplashError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (dimensions.width === 3840 && dimensions.height === 2160) {
      return true;
    } else {
      setTvSplashError("Invalid dimensions");
      return false;
    }
  }

  const mobileHelp = <HelpText style={{ marginBottom: 8 }}>Changing the Launch Logo will require an app update.</HelpText>;

  const themeMobileLaunchBackgroundColor =
    <ColorPicker
      getter={themeMobileLaunchBackground}
      setter={setThemeMobileLaunchBackground}
      label="Launch Background"
      disabled={disableForm}
    />;

  const launchLogoAltTextInput =
    <div style={{display: 'flex'}}>
      <Input
        label="Launch Logo Alt Text"
        value={launchLogoAltText || ""}
        onChange={val => {onLangInputChange(val, "launchLogoAltText")}}
        disabled={isMultiLangOrRegion || disableForm}
      />
      {multiText("Launch Logo Alt Text", "launchLogoAltText", 0, "8px")}
    </div>

  const launchLogoUpload =
    <UploadButton
      inputProps={{ accept: 'image/png' }}
      label="Launch Logo"
      required
      requirements={['1200 x 600 px', 'transparent background', '72 dpi', 'PNG']}
      onUpload={handleLaunchLogoUpload}
      validations={handleLaunchLogoValidations}
      error={launchLogoError}
      disabled={disableForm}
    >Upload Image</UploadButton>
    
  const tvSplashUpload =
    <UploadButton
      inputProps={{ accept: 'image/png' }}
      label="Launch Screen"
      required
      requirements={['3840 x 2160 px', 'transparent background', '72 dpi', 'PNG']}
      onUpload={handleTvSplashUpload}
      validations={handleTvSplashValidations}
      error={tvSplashError}
      disabled={disableForm}
    >Upload Image</UploadButton>

  return (
    <Layout {...layoutProps}>
      {simulator.deviceName === "TV"
        ? <>
            {tvSplashUpload}
          </>
        : <>
            {mobileHelp}
            {themeMobileLaunchBackgroundColor}
            {launchLogoUpload}
            {launchLogoAltTextInput}
          </>
      }
    </Layout>
  );
}

export default withDesign(withSimulator(Launch));
