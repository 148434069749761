import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import Base from "./Base";
import { useTheme } from "@mui/material/styles";
import useWindowSize from "customHooks/useWindowSize";

import { UPDATE_BUNDLE, DELETE_BUNDLE, GET_CURRENT_APP_PROPS, GET_CURRENT_APP, GET_BUNDLE, DUPLICATE_MENU } from "api";
import DataGrid from "components/atoms/DataGrid";
import FormLayout from "components/molecules/Form/FormLayout";
import ContentLayout from "screens/Layout/ContentLayout";
import { withRedirect } from "components/containers/Redirect";
import { LANGUAGE_MAP } from "helpers/langUtils";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import MenuFormModal from "components/molecules/Modal/MenuFormModal";
import { isMediaType } from "helpers/bundleUtils";
import { withLocaleLang } from "components/containers/withLocaleLang";
import { removeSeparatorForMenuLayout } from "../Picker/MenuTokenSelector";

const feedColumns = [
  {
    field: "title",
    headerName: "Name",
    width: 240,
    cellRenderer: "wrappedTextRenderer",
  },
  {
    field: "regions",
    headerName: "Regions",
    width: 150,
    valueGetter: (params) => params.data.localeIds
      ? params.data.locales.filter(l =>
          params.data.localeIds.includes(l.id)
        ).map(l => l.regionCode || l.name).join(", ")
      : "All"
  },
  {
    field: "language",
    headerName: "Language",
    width: 150,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    minWidth: 260,
    flex: 1
  },
];

const columns = [
  {
    field: "title",
    headerName: "Name",
    width: 200,
    cellRenderer: "wrappedTextRenderer",
  },
  {
    field: "regions",
    headerName: "Regions",
    width: 150,
    valueGetter: (params) => params.data.localeIds
      ? params.data.locales.filter(l =>
          params.data.localeIds.includes(l.id)
        ).map(l => l.regionCode || l.name).join(", ")
      : "All"
  },
  {
    field: "language",
    headerName: "Language",
    width: 150,
  },
  {
    field: "feed",
    headerName: "Source",
    width: 300,
    valueGetter: (params) => params.data.feed?.feedName || params.data.feed?.name,
  },
  {
    field: "isPublishable",
    headerName: "Active",
    width: 150,
    cellRenderer: "pollCellRenderer",
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    minWidth: 260,
    flex: 1
  },
];

function MenuModal(props) {
  const theme = useTheme();
  const size = useWindowSize(); 
  const { isMultiLangOrRegion, savedSnack, ...otherProps } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data } = useQuery(GET_BUNDLE, { variables: { ...appProps, id: props.bundle?.id } });
  const bundle = data?.bundle;

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  const [openForm, setOpenForm] = React.useState(null);
  const [gridApi, setGridApi] = React.useState(null);
  const [overflow,setOverflow] = React.useState(null);
  const isMenuLayoutItem = props.bundle?.type === "Bundle::StandardMenu::Item";
  const isMenuCategory = props?.bundle?.category === "menu";

  const closeForm = (refreshDataStore) => {
    setOpenForm(null);
    if (!props.bundle?.id || isMenuLayoutItem) {
      props.onClose(refreshDataStore);
    }
  };

  React.useEffect(() => {
    if (props.open && !props.bundle?.id) {
      setOpenForm({});
    }
    else if (props.open && isMenuLayoutItem ) {
      setOpenForm(props.bundle.menus[0]);
    }
  }, [props.open]);

  React.useEffect(() => {
    if (size?.width < 1200) setOverflow(true);
    else setOverflow(false);
  }, [size]);


  const handleSelect = (params) => {
    setOpenForm(params.data.object);
    gridApi.deselectAll();
  };

  const onNew = () => {
    setOpenForm({});
  };

  const onDelete = (menu, ev) => {
    ev.stopPropagation();
    setDeleteConfirmation(menu);
  };

  const onDuplicate = (menu, ev) => {
    ev.stopPropagation();
    duplicateMenu({
      variables: {
        ...appProps,
        bundleId: bundle?.id,
        id: menu.id,
      },
    });
  };

  const destroyMenuApi = (id) => {
    if (bundle?.menus?.length === 1) {
      deleteBundle({
        variables: {
          ...appProps,
          id: bundle.id,
        }
      });
    } else {
      deleteMenu(id);
    }
  };

  const [ deleteBundle ] = useMutation(DELETE_BUNDLE, {
    update(cache, { data: { deleteBundle } }) {
      cache.modify({
        fields: {
          bundles(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteBundle.bundle.id !== readField("id", ref)
            );
          },
        },
      });
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.gc();
    },
    onCompleted(data) {
      closeDeleteConfirmation();
      props.onClose(true);
    }
  });

  const deleteMenu = (id) => {
    updateBundle({
      variables: {
        ...appProps,
        id: bundle?.id,
        bundleFields: {
          menusAttributes: [{
            id: id,
            _destroy: "1",
          }]
        }
      },
    });
  };

  const toggleActive = (menu, active, event) => {
    event && event.stopPropagation();
    updateBundle({
      variables: {
        ...appProps,
        id: bundle?.id,
        bundleFields: {
          menusAttributes: [{
            id: menu.id,
            isActive: active,
          }]
        }
      }
    });
  };

  const [ duplicateMenu ] = useMutation(DUPLICATE_MENU, {
    update(cache, { data: { duplicateMenu } }) {
      cache.modify({
        fields: {
          bundles(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              bundle?.id === readField("id", ref)
                ? { ...ref, ...duplicateMenu.bundle }
                : ref
            );
          },
        },
      });
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.gc();
    },
    onCompleted(data) {
      closeDeleteConfirmation();
    },
    onError(data) {
      console.log(data);
    }
  });

  const [ updateBundle ] = useMutation(UPDATE_BUNDLE, {
    update(cache, { data: { updateBundle } }) {
      cache.modify({
        fields: {
          bundles(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              bundle?.id === readField("id", ref)
                ? { ...ref, ...updateBundle.bundle }
                : ref
            );
          },
        },
      });
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.gc();
    },
    onCompleted(data) {
      closeDeleteConfirmation();
    },
    onError(data) {
      console.log(data);
    }
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
    setHeaderNames(params.api)
  };

  const setHeaderNames = (api) => {
    const newColumns = api.getColumnDefs();
    const updatedColumns = [];
    newColumns.forEach((newColumn, index) => {
      if (isMultiLangOrRegion) {
        updatedColumns.push(newColumn)
      }
      else if (!["Regions", "Language"].includes(newColumn.headerName)) {
        updatedColumns.push(newColumn)
      }
    });
    api.setColumnDefs(updatedColumns);
  };

  return (
    <Base
      dimensions={{ width: overflow? theme.spacing(100):theme.spacing(150), height: theme.spacing(77) }}
      title="Translations"
      fab={{ onClick: onNew }}
      hideBackdrop={Boolean(openForm)}
      {...otherProps}
    >
      <DataGrid
        rowData={
          bundle?.menus?.map((t) => ({
            ...t,
            title: isMenuCategory ? removeSeparatorForMenuLayout(t.title): (t.title || t.item?.title || t.liveChannel?.title),
            object: t,
            locales: app.locales,
            language: t.language ? t.language.toUpperCase() : "Not Assigned",
            actions: {
              onDelete: t.item ? null : onDelete,
              toggle: {
                getter: t.isActive,
                setter: (value, event) => toggleActive(t, value, event),
              },
              onDuplicate,
            },
          })) || []
        }
        columnDefs={
          isMediaType(props.bundle?.type)
            ? columns
            : feedColumns
        }
        immutableData={true}
        getRowNodeId={(data) => data.id}
        rowSelection='single'
        onRowSelected={handleSelect}
        onGridReady={onGridReady}
      />

      <ConfirmDialog
        open={Boolean(deleteConfirmation)}
        handleClose={closeDeleteConfirmation}
        accept={() => destroyMenuApi(deleteConfirmation.id)}
        content={
          bundle?.menus && bundle.menus.length === 1
            ? "Are you sure you want to delete. As this is the only translation, this action will delete the entire media item."
            : "Are you sure you want to delete this translation."
        }
      />

      {Boolean(openForm) && (
        <MenuFormModal
          open={Boolean(openForm)}
          onClose={closeForm}
          menu={openForm}
          bundle={props.bundle}
          format={
            props.bundle.type === "Bundle::LiveChannel"
              ? "liveChannel"
              : isMediaType(bundle?.type) || isMediaType(props?.bundle?.type) 
              ? "media"
              : "collections"
          }
          type={props.bundle?.type}
          isMultiLangOrRegion={isMultiLangOrRegion}
          savedSnack={savedSnack}
        />
      )}
    </Base>
  );
}

export default withLocaleLang(MenuModal)