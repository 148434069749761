import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 18,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default function Label(props) {
  const classes = useStyles();
  const { children, ...otherProps } = props;

  return (
    <Typography variant="p" className={classes.label} {...otherProps}>{children}</Typography>
  );
}
