import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Profile from "./Profile";
import Workspace from "./Workspace";

export default function Settings(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;

  const commonProps = { savedSnack };

  return (
    <Switch>
      <Route path={`${path}/profile`}>
        <Profile {...commonProps} />
      </Route>
      <Route path={`${path}/workspace`}>
        <Workspace {...commonProps} />
      </Route>
    </Switch>
  );
}
