import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    svg: {
        padding: '7px',
        fill: 'white',
        backgroundColor: 'rgba(0,0,0,0.29)'
    }
}))

function DefaultAppIcon(props) {
    const classes = useStyles();

    return (
        <StarIcon className={classes.svg} {...props}/>
    )
}

export default DefaultAppIcon
