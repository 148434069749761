import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import React from "react";

import { GET_CURRENT_APP_PROPS, UPDATE_APP, GET_APP, GET_POLICY } from "api";

import Base from "./Base";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import JsonInput from "components/atoms/Input/JsonInput";
import SubmitButton from "components/atoms/Button/Submit";
import { LANGUAGE_MAP } from "helpers/langUtils";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Select from "components/atoms/Input/Select";
import Divider from "components/atoms/Divider";
import LinkText from "components/atoms/Text/LinkText";
import HelpText from "components/atoms/Text/Help";
import CopyToClipboard from "components/atoms/Button/CopyToClipBoard";
import { withSavedSnack } from "components/molecules/Alerts/Saved";
import jwt from "jsonwebtoken";

const FeedTool = (props) => {
  const { app } = props;
  const isDevlopment = process.env?.REACT_APP_ENV?.toLowerCase() === "development";
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const cloudUrl = `${process.env.REACT_APP_CLOUD_URL}/${appProps.appId}`;
  const resourcesUrl = `${process.env.REACT_APP_RESOURCES_URL}/${appProps.appId}`;

  const [ locale, setLocale ] = React.useState(app.locales[0]);
  const [ policy, setPolicy ] = React.useState(null);
  const [ expiry, setExpiry ] = React.useState(null);
  const [ copied, setCopied ] = React.useState(false);
  const [ timeoutId, setTimeoutId ] = React.useState(null);
  const [ token, setToken ] = React.useState(tokenGenerator(appProps.appId))

  React.useEffect(() => {
    setPolicy(null);
    setExpiry(null);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  }, [locale]);

  const [ loadPolicy, { data } ] = useLazyQuery(GET_POLICY, {
    fetchPolicy: "network-only",
    variables: {
      ...appProps,
      localeId: locale.id,
    },
    onCompleted: (data) => {
      if (data.policy) {
        setPolicy(data.policy);
        setExpiry(3598);
      }
    }
  });
  const onCopy = (text, result) => {
    setCopied(true);
  };

  React.useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 750);
    }
  }, [copied]);

  React.useEffect(() => {
    if (expiry && expiry > 0) {
      const t = setTimeout(() => {
        if (expiry) {
          setExpiry(expiry-1);
        }
      }, 1000);
      setTimeoutId(t);
    }
  }, [expiry]);

  React.useEffect(() => {
    const oneMinuteInterval = setInterval(() => {setToken(tokenGenerator(appProps.appId))}, 60*1000)
    return () => {
      oneMinuteInterval && clearInterval(oneMinuteInterval)
    }
  }, [])

  const policyParams = isDevlopment ? `?key=${token}` : policy;
  const encryptedFeedParams = isDevlopment ? `?key=${token}` : "";

  return (
    <>
      <Select
        label="Locales"
        value={locale.id}
        onChange={(val) => setLocale(app.locales.find(l => l.id === val))}
        options={app.locales.map(l => ({ label: l.name, value: l.id }))}
      />
      <div style={{ flexGrow: 1, maxWidth: 320 }}>
        <SubmitButton style={{ width: "100%" }} onClick={loadPolicy}>Generate policy</SubmitButton>
      </div>

      {Boolean(policy) && (
        <div style={{ maxWidth: 600, marginTop: 16 }}>
          <HelpText typographyProps={{ style: { wordWrap: "break-word" } }}>{policy}</HelpText>
          <div style={{ display: "flex", alignItems: "center", marginTop: 4 }}>
            <div style={{ flexGrow: 1 }}>
              {expiry && expiry > 0
                ? <>Valid for: <span style={{ color: "red" }}>{expiry/60 < 10 ? `0${parseInt(expiry/60)}` : parseInt(expiry/60)}:{expiry%60 < 10 ? `0${expiry%60}` : expiry%60}</span> minutes</>
                : <span style={{ color: "red" }}>Expired</span>}
            </div>
            <CopyToClipboard
              copyText={policy}
              onCopy={onCopy}
            />
            <LinkText>{copied ? "Copied" : "Copy to Clipboard"}</LinkText>
          </div>
        </div>
      )}

      <Divider />

      {locale.languages.map(l => (
        <div style={{ display: "flex", marginTop: 16 }}>
          <div style={{ width: 120 }}>
            <GroupHeader title={LANGUAGE_MAP[l]} />
          </div>
          <div style={{ flexGrow: 1 }}>
            <div style={{ flexGrow: 1, display: "flex" }}>
              <div style={{ width: 60 }}>Mobile:</div>
              <LinkText
                style={{ marginRight: 8 }}
                component="a"
                href={`${cloudUrl}/${locale.id}/${l}/feeds/v1/one_feed${policyParams || ""}`}
                target="_blank">One Feed</LinkText>
              <LinkText
                style={{ marginRight: 8 }}
                component="a"
                href={`${cloudUrl}/${locale.id}/${l}/feeds/v1/app_feed${policyParams || ""}`}
                target="_blank">App Feed</LinkText>
            </div>
            <div style={{ flexGrow: 1, display: "flex" }}>
              <div style={{ width: 60 }}>TV:</div>
              <LinkText
                style={{ marginRight: 8 }}
                component="a"
                href={`${cloudUrl}/${locale.id}/${l}/feeds/v1/tv_one_feed${policyParams || ""}`}
                target="_blank">One Feed</LinkText>
              <LinkText
                style={{ marginRight: 8 }}
                component="a"
                href={`${cloudUrl}/${locale.id}/${l}/feeds/v1/tv_app_feed${policyParams || ""}`}
                target="_blank">App Feed</LinkText>
              <LinkText
                style={{ marginRight: 8 }}
                component="a"
                href={`${resourcesUrl}/${locale.id}/${l}/feeds/v1/tv_one_feed_encrypted${encryptedFeedParams}`}
                target="_blank">Encrypted One Feed</LinkText>
              <LinkText
                style={{ marginRight: 8 }}
                component="a"
                href={`${resourcesUrl}/${locale.id}/${l}/feeds/v1/tv_app_feed_encrypted${encryptedFeedParams}`}
                target="_blank">Encrypted App Feed</LinkText>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const JsonFields = (props) => {
  const { app, setAppFields } = props;
  const [ cloudFeed, setCloudFeed ] = React.useState(app?.cloudFeed);
  const [ configuration, setConfiguration ] = React.useState(app?.configuration);
  const [ countryRatings, setCountryRatings ] = React.useState(app?.countryRatings);

  const appFields = {
    configuration,
    cloudFeed,
    countryRatings,
  };

  React.useEffect(() => {
    setAppFields(appFields);
  }, [appFields]);

  return (
    <>
      <JsonInput
        value={cloudFeed}
        onChange={setCloudFeed}
        label="Cloud Feed"
      /><br/>

      <JsonInput
        value={configuration}
        onChange={setConfiguration}
        label="Configuration"
      /><br/>

      <JsonInput
        value={countryRatings}
        onChange={setCountryRatings}
        label="Country Ratings"
      />
    </>
  );
};

function DeveloperModeModal(props) {
  const theme = useTheme();

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_APP, { variables: appProps });
  const [tab, setTab] = React.useState("feed_tool");
  const [ appFields, setAppFields ] = React.useState({});

  const [ updateApp, {loading: updatingApp} ] = useMutation(UPDATE_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      id: appProps.appId,
      appFields: appFields,
    },
    update(cache, { data: { updateApp } }) {
      cache.modify({
        id: cache.identify(updateApp.app),
        fields: {
          configuration(cachedConfiguration) {
            return (updateApp.app.configuration);
          },
          cloudFeed(cachedCloudFeed) {
            return (updateApp.app.cloudFeed);
          },
          countryRatings(cachedCountryRatings) {
            return (updateApp.app.countryRatings);
          },
        },
      });
    },
    onCompleted(data) {
      handleSuccess(data);
    }
  });

  const commonProps = {
    app: data?.app,
    setAppFields,
  };

  const saveApp = () => {
    if (isAppValid()) {
      updateApp();
    }
  };

  const isAppValid = () => true;

  const handleSuccess = () => {
    props.savedSnack.launch();
    props.onClose();
  };

  const tabs = [
    {
      key: "feed_tool",
      title: "Feed Tool",
      component: FeedTool,
      submit: null,
    },
    {
      key: "json_fields",
      title: "JSON Fields",
      component: JsonFields,
      submit: saveApp,
    },
  ]

  const currentTab = tabs.find(t => t.key === tab);

  return (
    <Base
      dimensions={{ width: theme.spacing(150), height: theme.spacing(75) }}
      title="Developer Mode"
      drawer={
        <List style={{ width: 150 }}>
          {tabs.map((t, index) => (
            <ListItem button key={t.title} selected={tab === t.key} onClick={() => setTab(t.key)}>
              <ListItemText primary={t.title} />
            </ListItem>
          ))}
        </List>
      }
      titleRight={Boolean(currentTab.submit)
        ? () => <SubmitButton onClick={currentTab.submit}>Done</SubmitButton>
        : null
      }
      {...props}
    >
      {data?.app && (
        <div style={{ flexGrow: 1 }}>
          {React.createElement(currentTab.component, commonProps)}
        </div>
      )}
    </Base>
  );
}

export default withSavedSnack(DeveloperModeModal);

const tokenGenerator = (appId) => {
  let expirationTime = Math.floor(Date.now() / 1000) + (60 * 60);
  let token = jwt.sign({
    app_id: appId,
    ts: expirationTime
  }, process.env.REACT_APP_JWT_KEY, { expiresIn: 60 * 60 });
  return token;
}
