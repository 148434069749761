import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import { GET_DESIGNS, GET_CURRENT_APP_PROPS } from "api";
import Form from "./Form";

export default function New(props) {
  const { designId } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_DESIGNS, { variables: appProps });

  const design = data?.designs?.find(d => d.id === designId);

  return (
    design
      ? <Form
          {...props}
          design={design}
          screen={{
            title: "Welcome",
            summary: "Watch TV shows & movies anytime, anywhere",
          }}
        />
      : null
  );
}
