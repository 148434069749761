import React from "react";
import Typography from "@mui/material/Typography";

export default function BaseText(props) {
  const { style, children, ...otherProps } = props;

  return (
    <Typography style={{ fontSize: 18, ...style }} {...otherProps}>{children}</Typography>
  );
}
