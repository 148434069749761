import React from "react";
import PaperGroup from "components/molecules/Form/PaperGroup";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Select from "components/atoms/Input/Select";

function PaymentProvider({ getter, setter }) {
  const providers = [
    {label: "Cleeng", value: 'cleeng'},
    {label: "Stripe", value: 'stripe'}
  ];

  return (
    <PaperGroup>
      <GroupHeader title="Payment Gateway"/>
      <Select
        value={getter || providers[0].value}
        onChange={setter}
        label={"Select provider"}
        options={providers}
        disabled={providers.length <= 1}
      />
    </PaperGroup>
  );
}

export default PaymentProvider;
