import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import Dashboard from './Dashboard';
import Streams from './Streams';


export default function Monetization(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;

  const commonProps = { savedSnack };

  return (
    <Switch>
      {/* <Route path={`${path}/dashboard`}><Dashboard {...commonProps} /></Route> */}
      <Route path={`${path}/streams`}><Streams {...commonProps} /></Route>
      <Redirect to={`${path}/streams`} />
    </Switch>
  );
}
