import React from "react";
import UploadButton, { ImagePreview, FilePreview } from "components/molecules/UploadGroup";
import _ from "underscore";
import { getDimensions } from "helpers/fileUtils";
import Grid from "@mui/material/Grid";

export const Spacer = () => <div style={{ height: 16, width: "100%" }} />;

export const ImageUploadGroup = (props) => {
  const { name, assetMap, asset, setAsset, horizontal, previewMaxHeight, previewProps, uploadValidation } = props;

  const { label, dimensions, format, requirements, max, min, tooltip } = assetMap[name];
  const url = asset.urls[name];
  const blob = asset.blobs[name];
  const error = asset.errors[name];
  const type = assetMap[name].type || "image";
  const inputProps = assetMap && assetMap[name]?.formats ? {accept : assetMap[name].formats.join(", ") } : {};

  const setter = (response) => {
    let newUrl, newBlob;
    if (multiple) {
      newUrl = [...url, response.url];
      newBlob = [...blob, response.signedBlobId];
    } else {
      newUrl = response.url;
      newBlob = response.signedBlobId;
    }

    setAsset({
      ...asset,
      urls: {
        ...asset.urls,
        [name]: newUrl,
      },
      blobs: {
        ...asset.blobs,
        [name]: newBlob,
      },
      errors: {
        ...asset.errors,
        [name]: "",
      },
    });
  };

  const errorSetter = (error) => {
    setAsset({
      ...asset,
      errors: {
        ...asset.errors,
        [name]: error,
      },
    });
  };

  const multiple = Boolean(max) && max > 1;

  const handleImageUpload = (response) => {
    setter(response);
  };

  const handleFileValidations = async (file) => {
    errorSetter("");
    return await asyncValidations(file);
  };

  const asyncValidations = async (file) => {
    let isValid = false;

    let validFileTypes = assetMap[name].formats;
    if (validFileTypes && validFileTypes.length > 0) {
      if (validFileTypes.includes(file.type)) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else {
      isValid = true;
    }

    if (!isValid) {
      errorSetter("Invalid format");
      return false;
    }
    if (type === "image") {
      isValid = false;
      const size = await getDimensions(file);
      if (dimensions[0]==="custom") {
        isValid = uploadValidation ? uploadValidation(size) : true;
      } else {
        dimensions.forEach((map) => {
          if (map[0] === size.width && map[1] === size.height) {
            isValid = true;
          }
        });
      }

      if (!isValid) {
        errorSetter("Invalid dimensions");
        return false;
      }
    }

    if (multiple && url.filter((u) => !u.startsWith("detach")).length >= max) {
      isValid = false;
      errorSetter("Limit reached. Delete an image first.");
      return false;
    }

    return true;
  };

  const renderFile = () => {
    const multipleSetter = (u, i) => {
      let newBlob, newUrl;
      if (u.split("/").length === 4) {
        newBlob = [...blob, `detach:${_.last(u.split("/"))}`];
      } else {
        newBlob = blob.filter((u, index) => index !== i);
      }
      newUrl = url.filter((u, index) => index !== i);

      setAsset({
        ...asset,
        urls: {
          ...asset.urls,
          [name]: newUrl,
        },
        blobs: {
          ...asset.blobs,
          [name]: newBlob,
        },
        error: {
          ...asset.errors,
          [name]: null,
        },
      });
    };

    const onDelete = () => {
      setter({ url: null, signedBlobId: "detach" });
    };

    return multiple ? (
      <Grid
        container
        direction="row"
      >
        {url.map((u, i) => {
          const commonProps = {
            url: u,
            alt: label,
            onDelete: () => multipleSetter(u, i),
            horizontal,
            previewMaxHeight,
            type,
          };

          return (
            <Grid item>
              {type === "image"
                ? <ImagePreview {...commonProps} previewProps={previewProps}/>
                : <FilePreview {...commonProps} />}
            </Grid>
          );
        })}
      </Grid>
    ) : Boolean(url) ? (
      type === "image"
        ? <ImagePreview {...{ url, alt: label, previewMaxHeight, onDelete, horizontal , previewProps}}/>
        : <FilePreview {...{ url, onDelete }} />
    ) : null;
  };

  return (
    <UploadButton
      label={label}
      requirements={requirements}
      onUpload={handleImageUpload}
      validations={handleFileValidations}
      error={error}
      fileRender={renderFile}
      horizontal={horizontal}
      tooltip={tooltip}
      inputProps={inputProps}
    >
      {type === "image"
        ? 'Upload Image'
        : type === "video"
        ? 'Upload Video'
        : 'Upload'}
    </UploadButton>
  );
};
