import { gql } from "@apollo/client"

export const VIDEO_AD_FRAGMENT = gql`
  fragment VideoAdSchema on VideoAd {
    id
    isActive
    source
    adInterval
    spotxStart
    adBreakFrequency
    adRequestEverytimeForLive
    cuepointsKeyValue
    descriptionMacroLimit
    vid
    kvpMethod
    adLinks{
      id
      storeDeviceName
      hook
    }
    adKvps{
      id
      kvp
    }
    locale {
      id
    }
  }
`

export const GET_VIDEO_ADS = gql`
  query videoAds($workspaceId: ID! $appId: ID! $source: String) {
    videoAds(workspaceId: $workspaceId appId: $appId source: $source) {
      ...VideoAdSchema
    }
  }
  ${VIDEO_AD_FRAGMENT}
`

export const CREATE_VIDEO_AD = gql`
  mutation createVideoAd(
    $workspaceId: ID!
    $appId: ID!
    $localeId: ID!
    $videoAdFields: VideoAdInput!
  ) {
    createVideoAd(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        localeId: $localeId
        videoAdFields: $videoAdFields
      }
    ) {
      videoAds {
        ...VideoAdSchema
      }
    }
  }
  ${VIDEO_AD_FRAGMENT}
`


export const UPDATE_VIDEO_AD = gql`
  mutation updateVideoAd(
    $workspaceId: ID!
    $appId: ID!
    $videoAdId: ID!
    $videoAdFields: VideoAdInput!
  ) {
    updateVideoAd(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        videoAdId: $videoAdId
        videoAdFields: $videoAdFields
      }
    ) {
      videoAds {
        ...VideoAdSchema
      }
    }
  }
  ${VIDEO_AD_FRAGMENT}
`
