import { useQuery } from '@apollo/client';
import React from 'react';
import Select from './Select';

import { GET_PRICES } from '../../../api';
import priceFormatter from 'helpers/priceFormatter';

export default function PricePicker(props) {
  const { data } = useQuery(GET_PRICES);
  const { value, onChange, disabled } = props;

  return (
    <Select
      value={value}
      onChange={onChange}
      options={data?.prices?.map((d) => ({ label: priceFormatter(d.amount), value: d.id })) || []}
      label="Price"
      required
      disabled={disabled}
    />
  );
}
