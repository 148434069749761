import { gql } from '@apollo/client';

export const BADGE_FRAGMENT = gql`
  fragment BadgeSchema on Badge {
    __typename
    id
    name
    isActive
    badgeType
    imageUrl
    imageAltText
    isSystemAttribute
    sortOrder
    metadata
    label
    position
  }
`

export const GET_BADGES = gql`
  query badges($workspaceId: ID! $appId: ID!) {
    badges(workspaceId: $workspaceId appId: $appId) {
      id
      name
      isActive
      isSystemAttribute
      sortOrder
    }
  }
`

export const GET_BADGE = gql`
  query badge($workspaceId: ID! $appId: ID! $id: ID!) {
    badge(workspaceId: $workspaceId appId: $appId id: $id) {
      ...BadgeSchema
    }
  }
  ${BADGE_FRAGMENT}
`

export const CREATE_BADGE = gql`
  mutation createBadge(
    $workspaceId: ID!
    $appId: ID!
    $badgeFields: BadgeInput!
  ) {
    createBadge(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        badgeFields: $badgeFields
      }
    ) {
      badge {
        ...BadgeSchema
      }
    }
  }
  ${BADGE_FRAGMENT}
`

export const UPDATE_BADGE = gql`
  mutation updateBadge(
    $workspaceId: ID!
    $appId: ID!
    $badgeId: ID!
    $badgeFields: BadgeInput!
  ) {
    updateBadge(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        badgeId: $badgeId
        badgeFields: $badgeFields
      }
    ) {
      badge {
        ...BadgeSchema
      }
    }
  }
  ${BADGE_FRAGMENT}
`

export const DELETE_BADGE = gql`
  mutation deleteBadge(
    $workspaceId: ID!
    $appId: ID!
    $badgeId: ID!
  ) {
    deleteBadge(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        badgeId: $badgeId
      }
    ) {
      badge {
        id
        name
        isActive
      }
    }
  }
`

export const REARRANGE_BADGES = gql`
  mutation rearrangeBadges(
    $workspaceId: ID!
    $appId: ID!
    $ids: [ID!]!
  ) {
    rearrangeBadges(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        ids: $ids
      }
    ) {
      success
    }
  }
`;
