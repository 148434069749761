import React from "react";
import BaseInput from "./BaseInput";
import Tooltip from "../Tooltip";

export default function BundleIdInput(props) {
  const { inputProps, ...otherProps } = props;

  return (
    <Tooltip
      title="If your app is already setup on the app-store MAZ can send an app-update over your existing app."
    >
      <BaseInput
        name="bundleId"
        label="Bundle ID"
        placeholder="com.maz.tvod.x.x"
        helperText={`Optional: ${50 - props.defaultValue?.length} - character limit`}
        inputProps={{
          maxLength: 50,
          ...inputProps,
        }}
        {...otherProps}
      />
    </Tooltip>
  );
}
