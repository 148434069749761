import { gql } from '@apollo/client';

export const FEED_FRAGMENT = gql`
  fragment FeedSchema on Feed {
    __typename
    id
    name
    language
    url
    format
    poll
    lastPolledAt
    createdAt
  }
`

export const GET_FEEDS = gql`
  query feeds($workspaceId: ID! $appId: ID! $formats: [String!]) {
    feeds(workspaceId: $workspaceId appId: $appId formats: $formats) {
      ...FeedSchema
    }
  }
  ${FEED_FRAGMENT}
`

export const GET_FEED = gql`
  query feed($workspaceId: ID! $appId: ID! $id: ID!) {
    feed(workspaceId: $workspaceId appId: $appId id: $id) {
      ...FeedSchema
    }
  }
  ${FEED_FRAGMENT}
`

export const CREATE_FEED = gql`
  mutation createFeed(
    $workspaceId: ID!
    $appId: ID!
    $name: String!
    $url: String!
    $format: String,
    $language: String!,
  ) {
    createFeed(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        name: $name
        url: $url
        format: $format
        language: $language
      }
    ) {
      feed {
        ...FeedSchema
      }
    }
  }
  ${FEED_FRAGMENT}
`

export const DELETE_FEED = gql`
  mutation deleteFeed($workspaceId: ID! $appId: ID! $id: ID!) {
    deleteFeed(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
      }
    ) {
      feed {
        ...FeedSchema
      }
    }
  }
  ${FEED_FRAGMENT}
`
