import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import _ from "underscore";
import {
  Grid,
  Switch,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  source_content: props => ({
    width: 160,
    borderRadius: '5px',
    border: 'solid 4px white',
    margin: theme.spacing(2, 0, 0),
  }),
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));

export default function AdSourceList(props) {
  const { dynamicForm, currentFormKeys, currentStateValues, switchStatus, setSwitchStatus, setSourceName, setIsFormChanged } = props;
  const classes = useStyles();

  const handleOnAdSourceChange = (obj) => {
    setSourceName(obj.name)
  }

  const handleChange = (obj) => {
    setSwitchStatus({
      ..._.mapObject(switchStatus, () => false),
      [obj?.name]: !switchStatus[obj?.name]
    });
    setIsFormChanged(true)
  }

  const source_item = (obj, key) => (
    <Grid xs={3} key={key}>
      <Card className={classes.source_content} style={{ borderColor: obj.name == currentFormKeys?.name ? '#7eeaf2' : '#fff' }}>
        <CardActionArea
          onClick={() => handleOnAdSourceChange(obj)}>
          <CardContent >
            <div className={classes.content}>
              <Typography variant="h7" component="h5">
                {obj.display_name}
              </Typography>
              <Switch
                checked={switchStatus[obj?.name]}
                onChange={() => handleChange(obj)}
                color="primary"
                name={obj?.name}
                // disabled={currentStateValues?.source != obj?.name}
              />
            </div>
          </CardContent>
          <CardMedia
            style={{ objectFit: 'none' }}
            component="img"
            alt="Contemplative Reptile"
            height="100"
            image={obj.logo}
            title="Contemplative Reptile"
          />
        </CardActionArea>
      </Card>
    </Grid>
  )

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography variant="h6" gutterBottom>
          Choose video ad source
          </Typography>
        <Typography variant="body2" gutterBottom>
          MAZ supports the following ways to serve ads to your video content. Please select one below.
          </Typography>
      </Grid>
      {
        dynamicForm.map((obj, key) => (
          source_item(obj, key)
        ))
      }
    </Grid>
  )
}
