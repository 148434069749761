import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import Base from "./Base";
import _ from "underscore";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { useParams, Link, useHistory } from "react-router-dom";
import useWindowSize from "customHooks/useWindowSize";
import JSONInput from 'react-json-editor-ajrm';

import Checkbox from "components/atoms/Input/Checkbox";
import { SwitchGroup } from "components/atoms/Input/Switch";
import Divider from "components/atoms/Divider";
import Label from "components/atoms/Text/Label";
import Input from "components/atoms/Input/BaseInput";
import GroupHeader from "components/atoms/Text/GroupHeader";
import BackgroundGroup from "components/molecules/BackgroundGroup";
import HelpText from "components/atoms/Text/Help";

import { GET_CURRENT_APP_PROPS, CREATE_BUNDLE, UPDATE_BUNDLE, GET_CURRENT_APP } from "api";
import { GUIDE_DESIGN_PATH, SAVED_DESIGN_PATH } from "helpers/urlConstants";
import ItemDetails from "components/molecules/ItemDetails";
import { getDefaultLocaleAndLang, LANGUAGE_MAP, makeDefaultLangObjectFromMenus } from "helpers/langUtils";
import { withRedirect } from "components/containers/Redirect";
import ContentLayout from "screens/Layout/ContentLayout";
import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import BaseText from "components/atoms/Text/BaseText";
import LinkText from "components/atoms/Text/LinkText";
import Select from "components/atoms/Input/Select";
import BannerGroup from "components/molecules/Picker/BannerGroup";
import IconPicker from "components/atoms/Input/IconPicker";
import DateTimePicker from "components/atoms/Input/DateTimePicker";
import LayoutPicker from "components/atoms/Input/LayoutPicker";
import LanguageSelect from "components/atoms/Input/LanguageSelect";
import SubmitButton from "components/atoms/Button/Submit";
import UploadButton, { ImagePreview } from "components/molecules/UploadGroup";
import { isMediaType, getBundleCatalog, isGhostFolderType, generateMenusFromLanguages } from "helpers/bundleUtils";
import CloseIcon from "@mui/icons-material/Close";
import { getDimensions, checkFileType, getHeightAndWidthFromDataUrl } from "helpers/fileUtils";
import moment from "moment";
import CheckBox from "components/atoms/Input/Checkbox";
import PreviewGroup from "../PreviewGroup";
import Error from "components/atoms/Error";
import LanguageText from "../LanguageText";
import RadioInput from "components/atoms/Input/RadioInput";
import MenuTokenSelector, { removeSeparatorForMenuLayout, getValueForTokenizeInput, STRING_SEPERATOR } from "../Picker/MenuTokenSelector";

const useStyles = makeStyles((theme) => ({
  imageOption: (props) => ({
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    cursor: "pointer",
    borderWidth: 5,
    borderStyle: "solid",
    borderRadius: 8,
    borderColor: props.isSelected ? theme.palette.error.main : "transparent",
    height: props.height,
    width: props.width,
    backgroundImage: `url('${props.image}')`,
    '&:hover': {
      borderColor: theme.palette.error.main,
    }
  }),
  designContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    },
    maxWidth: 1024,
  },
  designWrapper: {
    flex: 1,
    paddingRight: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
  },
}));

export default function Form(props) {
  const theme = useTheme();
  const size = useWindowSize();
  const { bundleType, collectionId } = useParams();
  const history = useHistory();

  const { type, format, isMultiLangOrRegion, savedSnack, ...otherProps } = props;
  const className = type;
  const pageTitle = getBundleCatalog(type);

  const menu = props.menu || { appItem: { id: null, type: "AppItem::Video" } };
  const bundle = props.bundle || {};
  const item = menu.item || { media: [], tracks: [] };
  const hasItem = Boolean(item.id);
  const tracks = item.tracks || [];
  const media = item.media || [];
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const isSaved = bundle.type === "Bundle::Layout::Saved";
  const isGuide = bundle.type === "Bundle::Layout::Guide";
  const isSearch = bundle.type === "Bundle::Layout::Search";
  const isSettings = bundle.type === "Bundle::Layout::Settings";
  const isMenuLayout = bundle.type === "Bundle::Layout::StandardMenu" || bundle.type === "Bundle::StandardMenu::SubMenu";
  const isMenuLayoutItem = bundle.type === "Bundle::StandardMenu::Item";
  const isLinks = bundle.type === "Bundle::Catalog::Link";
  const isSection = className.includes("Layout") && !Boolean(collectionId);
  const showLogo = !isSaved && !isSearch && !isSettings;
  const showLayouts = !isGuide;
  const isCreatingFeed = !Boolean(collectionId) && type === "Bundle::Playlist::Feed" && !bundle.id;
  const defaultLocaleLang = getDefaultLocaleAndLang(app);

  const [ language, setLanguage ] = React.useState(menu.language);

  const [ titleError, setTitleError ] = React.useState(null);
  const [ urlError, setUrlError ] = React.useState(null);
  const [ searchKeywordError, setSearchKeywordError ] = React.useState(null);
  const [ interstitalError, setInterstitialError ] = React.useState(null);

  const defaultAvailability = !isMultiLangOrRegion ? {language: defaultLocaleLang.lang} :  {}
  const [ availability, setAvailability ] = React.useState(defaultAvailability);
  const [ information, setInformation ] = React.useState({});
  const [ thumbnail, setThumbnail ] = React.useState({});
  const [ tvDesign, setTvDesign ] = React.useState({});
  const [ mobileDesign, setMobileDesign ] = React.useState({});
  const [ banner, setBanner ] = React.useState({});
  const [ searchCriteria, setSearchCriteria ] = React.useState({});
  const [ interstialBackground, setInterstitialBackground ] = React.useState({});
  const [ linkIcon, setLinkIcon ] = React.useState({});
  const [ feed, setFeed ] = React.useState({});
  const [ liveEvent, setLiveEvent ] = React.useState({});
  const [ overflow,setOverflow]  = React.useState(null);
  const [ menuLayoutItem, setMenuLayoutItem ] = React.useState({menus : [], bundleMetadata: {}});

  const [ feedNameError, setFeedNameError ] = React.useState(null);
  const [ feedUrlError, setFeedUrlError ] = React.useState(null);

  const form = {
    ...availability,
    ...information,
    ...thumbnail,
    ...tvDesign,
    ...mobileDesign,
    ...banner,
    ...interstialBackground,
    ...linkIcon
  };

  const bundleForm = {
    metadata: {
      ...searchCriteria,
      ...feed,
      ...liveEvent,
      ...menuLayoutItem.bundleMetadata
    },
    menusAttributes: isMenuLayoutItem ? menuLayoutItem.menus : [{ id: menu?.id, ...form }],
  };

  React.useEffect(() => {
    if (size?.width < 1200) setOverflow(true);
    else setOverflow(false);
  }, [size]);

  React.useEffect(() => {
    if (feed.feed_name) {
      setInformation({ ...information, title: feed.feed_name });
    }
    if (feed.feed_language) {
      setAvailability({ ...availability, language: feed.feed_language });
    }
  }, [feed.feed_name, feed.feed_language]);

  const [ updateBundle, updateRequest ] = useMutation(UPDATE_BUNDLE, {
    variables: {
      ...appProps,
      id: bundle?.type === "Bundle::Layout::Symlink" ? bundle?.metadata.id : bundle?.id,
      bundleFields: bundleForm,
    },
    update(cache, { data: { updateBundle } }) {
      cache.modify({
        fields: {
          bundles(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateBundle.bundle.id === readField("id", ref)
                ? { ...ref, ...updateBundle.bundle }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.onClose();
    },
    onError(data) {
      console.log(data);
    }
  });

  const [ createBundle, createRequest ] = useMutation(CREATE_BUNDLE, {
    variables: {
      ...appProps,
      bundleFields: {
        type: className,
        collectionId: collectionId || null,
        ...bundleForm,
      }
    },
    update(cache, { data: { createBundle } }) {
      cache.modify({
        fields: {
          bundles(existingRefs) {
            return (
              collectionId
                ? [ ...existingRefs, createBundle.bundle ]
                : [ createBundle.bundle, ...existingRefs ]
            );
          },
        },
      });
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.gc();
    },
    onCompleted(data) {
      if (isMediaType(data.createBundle.bundle.type) && !data?.createBundle?.bundle?.type.includes("Unknown")) {
        history.push(
          `/${appProps.workspaceId}/${appProps.appId}/content/catalog/all/bundles/${data.createBundle.bundle.id}`
        );
      } else {
        props.onClose && props.onClose(true);
        savedSnack.launch("Created")
        // props.redirect.set(listPath.replace("undefined", data.createBundle.bundle.id));
      }
    },
    onError(data) {
      console.log(data);
      if (isCreatingFeed) {
        if (data.message.includes("Invalid URL")) {
          setFeedUrlError("Invalid URL");
        } else if (data.message.includes("Already present")) {
          setFeedUrlError("Already present");
        }
      }
    }
  });

  const submit = () => {
    if (isValid()) {
      bundle.id ? updateBundle() : createBundle();
    }
  };
  const loading = createRequest.loading || updateRequest.loading;

  const isValid = () => {
    let hasError = false;

    if (isCreatingFeed) {
      if (!feed?.feed_name) {
        setFeedNameError("Required field");
        hasError = true;
      } else {
        setFeedNameError(null);
      }

      if (!feed?.feed_url) {
        setFeedUrlError("Required field");
        hasError = true;
      } else {
        setFeedUrlError(null);
      }
    }

    if (isMenuLayoutItem) {
      let isTitleError = false;
      menuLayoutItem.menus.forEach(m => {
        if(!m.title) {
          isTitleError = true;
          setTitleError(menuLayoutItem.menus.length === 1 ? "Required field": "Please provide title for all languages");
        }
      });
      if (!menuLayoutItem.menus.length) {
        isTitleError = true;
        setTitleError("Required field");
      }
      if (isTitleError) return false;
      return true;
    }

    if (!hasItem && !isCreatingFeed && !isSaved && !form?.title) {
      setTitleError("Required field");
      hasError = true;
    }

    // if (!hasItem && !isCreatingFeed && !isSaved && !form?.url && isGuide) {
    //   setUrlError("Required field");
    //   hasError = true;
    // }

    if (!hasItem && !form?.url && isLinks) {
      setUrlError("Required field");
      hasError = true;
    }

    if (!hasItem && !form?.url && format === "media") {
      setUrlError("Required field");
      hasError = true;
    }

    if (type === "search" && !form.searchKeywords) {
      setSearchKeywordError("Required field");
      hasError = true;
    }

    const interstitial = form.interstitialBackgroundAttributes;
    if (
      ((interstitial?.format === "video" || interstitial?.format === "image") &&
        !interstitial?.assetBlobId &&
        !menu.interstitialBackground?.assetUrl) ||
      interstitial?.assetBlobId === "detach"
    ) {
      setInterstitialError("Mandatory to upload item.");
      hasError = true;
    }

    return !hasError;
  };

  const commonProps = {
    type,
    bundle,
    menu,
    format,
    item,
    hasItem,
    showLogo,
    showLayouts,
    isSection,
    isSaved,
    isGuide,
    isSearch,
    isSettings,
    isCreatingFeed,
    isLinks,
    feedUrlError,
    feedNameError,
    isMenuLayoutItem
  };

  return (
    <Base
      dimensions={{ width: overflow? theme.spacing(100):theme.spacing(150), height: theme.spacing(77) }}
      title="Translation"
      titleLeft={() => <IconButton onClick={props.onClose} size="large"><CloseIcon /></IconButton>}
      titleRight={() => <SubmitButton onClick={submit} isLoading={loading} disabled={loading}>Save</SubmitButton>}
      {...otherProps}
    >
      <div style={{ width: "100%" }}>
        {(!isCreatingFeed && isMultiLangOrRegion && !isMenuLayoutItem) && (
          <AvailabilitySet {...commonProps} form={availability} setForm={setAvailability} app={app}/>
        )}

        {type === "Bundle::Playlist::Search" && <SearchCriteriaSet {...commonProps} searchKeywordError={searchKeywordError} form={searchCriteria} setForm={setSearchCriteria} />}
        {type === "Bundle::Playlist::Feed" && isCreatingFeed && <FeedSet {...commonProps} form={feed} setForm={setFeed} isMultiLangOrRegion={isMultiLangOrRegion}/>}
        {type === "Bundle::Catalog::Event" && <EventSet {...commonProps} form={liveEvent} setForm={setLiveEvent} />}

        {!isCreatingFeed && !isMenuLayoutItem && (
          <InformationSet
            {...commonProps}
            form={information}
            setForm={setInformation}
            titleError={titleError}
            urlError={urlError}
            media={media}
            tracks={tracks}
          />
        )}

        {isMenuLayoutItem && <MenuLayoutForm
          {...commonProps}
          bundle={bundle}
          setForm={setMenuLayoutItem}
          isMultiLangOrRegion={isMultiLangOrRegion}
          defaultLocaleLang={defaultLocaleLang}
          app={app}
          form={form}
          titleError={titleError}
        />}

        {format === "media" && !isLinks && !isMenuLayoutItem && 
          <InterstitialBackground
            {...commonProps}
            form={interstialBackground}
            setForm={setInterstitialBackground}
            error={interstitalError}
            setError={setInterstitialError}
          />
        }

        {!isSection && !isCreatingFeed && !isMenuLayoutItem && !isMenuLayout &&
          <ThumbnailSet {...commonProps} form={thumbnail} setForm={setThumbnail} />
        }
        { (isLinks || isMenuLayoutItem) && <IconSelector {...commonProps} form={linkIcon} setForm={setLinkIcon}/> }
        {(format === "collections" || isGhostFolderType(props.bundle?.type)) && !isCreatingFeed && !isMenuLayoutItem && !isMenuLayout && !isLinks &&  (
          <>
            {!isGuide && !isSearch && !isSettings && (
              <TvDesignSet {...commonProps} form={tvDesign} setForm={setTvDesign} />
            )}
            <MobileDesignSet {...commonProps} form={mobileDesign} setForm={setMobileDesign} />
            {!isSaved && !isGuide && !isSearch && !isSettings && (
              <BannerGroupSet {...commonProps} form={banner} setForm={setBanner} />
            )}
          </>
        )}
      </div>
    </Base>
  );
}

const RowItem = (props) => {
  const { isText, isSelect, defaultOptions, isResettable, title, defaultValue,
    getter, setter, inputProps, error } = props;
  const isEditing = getter !== null;

  const toggleOverride = () => {
    if (isEditing) {
      setter(null)
    } else {
      setter("");
    }
  };

  const showInput = isText && (isEditing || !isResettable);

  return <>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flexGrow: 1 }}>
        <GroupHeader title={title} />
      </div>
      {isResettable && (
        <IconButton onClick={toggleOverride} size="large">
          {isEditing ? <ClearIcon /> : <EditIcon />}
        </IconButton>
      )}
    </div>

    {isText && (
      <BaseText style={{ fontSize: 16, marginBottom: 8, overflowWrap: "break-word" }}>{defaultValue}</BaseText>
    )}
    {showInput && (
      <>
        {defaultOptions}
        <Input
          label={title}
          value={getter || defaultValue}
          placeholder={defaultValue}
          onChange={setter}
          error={Boolean(error)}
          helperText={error}
          autoFocus={isResettable}
          {...inputProps}
        />
      </>
    )}
    {isSelect && (
      <Select
        label={title}
        value={getter}
        onChange={setter}
        {...inputProps}
      />
    )}
  </>;
}

const ImageSelector = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { title, images, getter, setter, blobs, height, width, altText,
    onUpload, validations, requirements, error, noAlt } = props;
  const [ fileMetadata, setFileMetadata ] = React.useState("");

  React.useEffect(() => {
    if (fileMetadata?.name) altText.setter(fileMetadata.name);
  }, [fileMetadata]);

  const renderFile = (url, alt, urlSetter, blobSetter) => (
    url
      ? <ImagePreview {...{ url, alt, urlSetter, blobSetter }} />
      : null
  );

  return (
    <>
      {images.map((img, i) => {
        const isSelected = getter === img || blobs.url.getter === img;

        return (
          <ImageBox
            isSelected={isSelected}
            image={img}
            height={height}
            width={width}
            onClick={() => blobs.url.setter(img)}
          />
        );
      })}

      <UploadButton
        label={title}
        requirements={requirements}
        onUpload={onUpload}
        validations={validations}
        error={error}
        setFileMetadata={setFileMetadata}
        fileRender={() => renderFile(getter, title, setter, blobs.setter)}
        inputProps={{accept: '.jpg, .jpeg, .png'}}
      >
        Upload Image
      </UploadButton>

      {!noAlt && <Input
        label="Alt Text"
        value={altText.getter}
        onChange={altText.setter}
      />}
    </>
  );
}

const ImageBox = (props) => {
  const classes = useStyles(props);

  return (
    <div
      className={classes.imageOption}
      onClick={props.onClick}
    >
    </div>
  );
};

export const AvailabilitySet = (props) => {
  const { hasItem, menu, form, setForm, app } = props;

  const allowLocaleSetting = true;
  const initAllLocales = !Boolean(menu?.localeIds);
  const [ allLocales, setAllLocales ] = React.useState(initAllLocales);
  const [ localeIds, setLocaleIds ] = React.useState(menu?.localeIds || null);

  const availableLocales = allLocales || !Boolean(localeIds) ? app.locales : app.locales.filter(l => localeIds.includes(l.id));
  const languages = _.uniq(_.flatten(availableLocales.map(l => l.languages)));
  const [ language, setLanguage ] = React.useState(
    LANGUAGE_MAP[menu?.language]
      ? menu?.language
      : (_.invert(LANGUAGE_MAP)[menu?.language] ? _.invert(LANGUAGE_MAP)[menu?.language] : menu?.language)
  );

  React.useEffect(() => {
    if (allLocales) {
      setLocaleIds(null);
    } else {
      setLocaleIds(app.locales.map(l => l.id));
    }
  }, [allLocales]);

  React.useEffect(() => {
    setForm({ ...form, language: language === "Not Assigned" ? null : language, localeIds });
  }, [language, localeIds]);

  return (
    <PaperGroup>
      <GroupHeader title="Regions" />
      <SwitchGroup
        checked={allLocales}
        onChange={setAllLocales}
        label="All Locales"
        disabled={!allowLocaleSetting}
      />

      {!allLocales && Boolean(localeIds) && (
        <LocaleSelector locales={app?.locales} localeIds={localeIds} setLocaleIds={setLocaleIds} />
      )}

      <Divider />

      <RowItem
        title="Language"
        getter={languages.length === 1 ? languages[0] : language}
        setter={setLanguage}
        inputProps={{
          options: [{ label: "Not Selected", value: null }, ...languages.map(l => ({ label: LANGUAGE_MAP[l], value: l }))],
          disabled: languages.length < 2,
        }}
        isSelect={true}
      />

    </PaperGroup>
  );
};

export const InformationSet = (props) => {
  const { menu, format, form, setForm, item, hasItem, titleError, media,
    urlError, tracks, isGuide, isSaved, isSearch, isSettings, isLinks } = props;

  const [ title, setTitle ] = React.useState(menu.title || null);
  const [ subtitle, setSubtitle ] = React.useState(menu.subtitle || null);
  const [ description, setDescription ] = React.useState(menu.description || null);
  const [ keywords, setKeywords ] = React.useState(menu.keywords || null);
  const [ url, setUrl ] = React.useState(menu.url || null);
  const [ track, setTrack ] = React.useState(menu.track || null);
  const [ duration, setDuration ] = React.useState(menu.duration || null);
  const [ token, setToken ] = React.useState("");

  React.useEffect(() => {
    setForm({ ...form, title: isLinks ? getValueForTokenizeInput(title) : title, subtitle, description, keywords, url, track, duration:parseInt(duration) });
  }, [title, subtitle, description, keywords, url, track, duration]);

  return (
    <PaperGroup>
      <RowItem
        title="Title"
        defaultValue={
          format === "liveChannel"
            ? menu.liveChannel?.title
            : item.title
        }
        getter={isLinks ? removeSeparatorForMenuLayout(title) : title}
        setter={setTitle}
        isText={true}
        isResettable={
          format === "liveChannel"
            ? Boolean(menu.liveChannel)
            : hasItem
        }
        error={titleError}
        inputProps={{
          required: true
        }}
      />
      {isLinks && <MenuTokenSelector value={title} setValue={setTitle}/>}
      

      {!isGuide && !isSaved && !isSearch && !isSettings && ["media", "collections"].includes(format) && (
        <>
          <Divider />

          <RowItem
            title="Subtitle"
            defaultValue={item.subtitle}
            getter={subtitle}
            setter={setSubtitle}
            isText={true}
            isResettable={hasItem}
            inputProps={{
              multiline: true,
              rows: 3
            }}
          />

          <Divider />

          <RowItem
            title="Description"
            defaultValue={item.summary}
            getter={description}
            setter={setDescription}
            isText={true}
            isResettable={hasItem}
            inputProps={{
              multiline: true,
              rows: 5
            }}
          />

          <Divider />

          <RowItem
            title="Keywords"
            defaultValue={item.keywords}
            getter={keywords}
            setter={setKeywords}
            isResettable={hasItem}
            isText={true}
          />
        </>
      )}

      {(["media", "liveChannel"].includes(format) || isLinks)&& (
        <>
          <Divider />

          <RowItem
            title="URL"
            defaultValue={media[0]?.url}
            getter={url}
            setter={setUrl}
            isText={true}
            isResettable={hasItem}
            error={urlError}
            defaultOptions={
              media && media.length > 1
              ? <>
                  <BaseText style={{ fontSize: 16 }}>Here are a few links to choose from: </BaseText>
                  {media.map((f, i) => <LinkText href={f.url} key={`DefaultOption-${i}`}>{f.url}</LinkText>)}
                </>
              : null
            }
            inputProps={{
              required: true
            }}
          />
        </>
      )}

      {format === "media" && !isLinks &&(
        <>
          <Divider />

          <RowItem
            title="Subtitle Track"
            defaultValue={tracks[0]?.url}
            getter={track}
            setter={setTrack}
            isText={true}
            isResettable={hasItem}
            defaultOptions={
              tracks && tracks.length > 1
              ? <>
                  <BaseText style={{ fontSize: 16 }}>Here are a few links to choose from: </BaseText>
                  {tracks.map((f, i) => <LinkText href={f.url} key={`DefaultOption-${i}`}>{f.url}</LinkText>)}
                </>
              : null
            }
          />

          <Divider />

          <RowItem
            title="Duration"
            defaultValue={item.duration}
            getter={duration}
            setter={setDuration}
            isText={true}
            isResettable={hasItem}
            inputProps={{
              type: "number",
              min: "0",
            }}
          />

          <Divider />

          <GroupHeader title="Metadata" />
          <JSONInput
            id='a_unique_id'
            placeholder={item?.metadata || {}}
            height='160px'
            viewOnly={true}
          />
        </>
      )}
    </PaperGroup>
  )
};

export const ThumbnailSet = (props) => {
  const { item, menu, form, setForm } = props;

  const [ images, setImages ] = React.useState(
    _.uniq([
      ...(item?.images || []),
      ...(menu.horizontalImage ? [menu.horizontalImage] : []),
      ...(menu.verticalImage ? [menu.verticalImage] : []),
      ...(menu.squareImage ? [menu.squareImage] : []),
    ])
  );
  const [ horizontalThumbnailUrl, setHorizontalThumbnailUrl ] = React.useState(menu.horizontalThumbnailUrl);
  const [ horizontalImage, setHorizontalImage ] = React.useState(menu.horizontalImage);
  const [ horizontalThumbnailBlobId, setHorizontalThumbnailBlobId ] = React.useState(null);
  const [ horizontalThumbnailAltText, setHorizontalThumbnailAltText] = React.useState(menu.horizontalThumbnailAltText);

  const [ verticalThumbnailUrl, setVerticalThumbnailUrl ] = React.useState(menu.verticalThumbnailUrl);
  const [ verticalImage, setVerticalImage ] = React.useState(menu.verticalImage);
  const [ verticalThumbnailBlobId, setVerticalThumbnailBlobId ] = React.useState(null);
  const [ verticalThumbnailAltText, setVerticalThumbnailAltText] = React.useState(menu.verticalThumbnailAltText);

  const [ squareThumbnailUrl, setSquareThumbnailUrl ] = React.useState(menu.squareThumbnailUrl);
  const [ squareImage, setSquareImage ] = React.useState(menu.squareImage);
  const [ squareThumbnailBlobId, setSquareThumbnailBlobId ] = React.useState(null);
  const [ squareThumbnailAltText, setSquareThumbnailAltText] = React.useState(menu.squareThumbnailAltText);

  const [horizontalThumbnailError, setHorizontalThumbnailError] = React.useState(null);
  const [verticalThumbnailError, setVerticalThumbnailError] = React.useState(null);
  const [squareThumbnailError, setSquareThumbnailError] = React.useState(null);

  React.useEffect(() => {
    setForm({
      ...form,
      horizontalImage,
      horizontalThumbnailBlobId,
      horizontalThumbnailAltText,
      verticalImage,
      verticalThumbnailBlobId,
      verticalThumbnailAltText,
      squareImage,
      squareThumbnailBlobId,
      squareThumbnailAltText,
    });
  }, [
    horizontalImage, horizontalThumbnailBlobId, horizontalThumbnailAltText,
    verticalImage, verticalThumbnailBlobId, verticalThumbnailAltText,
    squareImage, squareThumbnailBlobId, squareThumbnailAltText
  ]);

  function handleHorizontalThumbnailUpload(response) {
    setHorizontalThumbnailUrl(response.url);
    setHorizontalThumbnailBlobId(response.signedBlobId);
  }

  async function handleHorizontalThumbnailValidations(file) {
    setHorizontalThumbnailError(null);

    const dimensions = await getDimensions(file);
    const imageAspectRatio =  parseFloat(dimensions.width / dimensions.height).toFixed(2);

    if (!checkFileType(file, ['jpg','jpeg'])) {
      setHorizontalThumbnailError("Invalid file type");
      return false;
    }

    if (dimensions.width >= 533 && dimensions.height >= 300 && dimensions.width >= dimensions.height) {
      if(imageAspectRatio > 1.765 && imageAspectRatio < 1.785) return true;
      else {
        setHorizontalThumbnailError("Invalid ratio");
        return false;
      }
    } else {
      setHorizontalThumbnailError("Invalid dimensions");
      return false;
    }
  }

  function handleVerticalThumbnailUpload(response) {
    setVerticalThumbnailUrl(response.url);
    setVerticalThumbnailBlobId(response.signedBlobId);
  }

  async function handleVerticalThumbnailValidations(file) {
    setVerticalThumbnailError(null);

    const dimensions = await getDimensions(file);
    const imageAspectRatio =   parseFloat(dimensions.width / dimensions.height).toFixed(2);

    if (!checkFileType(file, ['jpg','jpeg'])) {
      setVerticalThumbnailError("Invalid file type");
      return false;
    }
    if (dimensions.width >= 300 && dimensions.height >= 450 && dimensions.height >= dimensions.width) {
      if(imageAspectRatio > 0.660 && imageAspectRatio < 0.675) return true;
      else {
        setVerticalThumbnailError("Invalid ratio");
        return false;
      }
    } else {
      setVerticalThumbnailError("Invalid dimensions");
      return false;
    }
  }

  function handleSquareThumbnailUpload(response) {
    setSquareThumbnailUrl(response.url);
    setSquareThumbnailBlobId(response.signedBlobId);
  }

  async function handleSquareThumbnailValidations(file) {
    setSquareThumbnailError(null);

    const dimensions = await getDimensions(file);
    const imageAspectRatio =  dimensions.width / dimensions.height;

    if (!checkFileType(file, ['jpg','jpeg'])) {
      setSquareThumbnailError("Invalid file type");
      return false;
    }

    if (dimensions.width >= 300 && dimensions.height >= 300) {
      if(imageAspectRatio === 1) return true;
      else {
        setSquareThumbnailError("Invalid ratio");
        return false;
      }
    } else {
      setSquareThumbnailError("Invalid dimensions");
      return false;
    }
  }

  return (
    <PaperGroup>
      <GroupHeader title="Thumbnails" />
      <ImageSelector
        title="Horizontal Thumbnail"
        getter={horizontalThumbnailUrl}
        setter={setHorizontalThumbnailUrl}
        requirements={["Minimum: 533 x 300 px",
          "Recommended: 1600 x 900 px",
          "Ratio: 16:9",
          "Resolution: 72 dpi",
          "Format: JPG"
        ]}
        blobs={{
          url: {
            getter: horizontalImage,
            setter: setHorizontalImage,
          },
          id: {
            getter: horizontalThumbnailBlobId,
            setter: setHorizontalThumbnailBlobId,
          }
        }}
        images={images}
        height={135}
        width={240}
        altText={{
          getter: horizontalThumbnailAltText,
          setter: setHorizontalThumbnailAltText
        }}
        onUpload={handleHorizontalThumbnailUpload}
        validations={handleHorizontalThumbnailValidations}
        error={horizontalThumbnailError}
      />

      <Divider />

      <ImageSelector
        title="Vertical Thumbnail"
        requirements={["Minimum: 300 x 450 px",
          "Recommended: 900 x 1350 px",
          "Ratio: 2:3",
          "Resolution: 72 dpi",
          "Format: JPG"
        ]}
        getter={verticalThumbnailUrl}
        setter={setVerticalThumbnailUrl}
        blobs={{
          url: {
            getter: verticalImage,
            setter: setVerticalImage,
          },
          id: {
            getter: verticalThumbnailBlobId,
            setter: setVerticalThumbnailBlobId,
          }
        }}
        images={images}
        height={165}
        width={110}
        altText={{
          getter: verticalThumbnailAltText,
          setter: setVerticalThumbnailAltText
        }}
        onUpload={handleVerticalThumbnailUpload}
        validations={handleVerticalThumbnailValidations}
        error={verticalThumbnailError}
      />

      <Divider />
      <ImageSelector
        title="Square Thumbnail"
        requirements={[
          "Minimum: 300 x 300 px",
          "Recommended: 900 x 900 px",
          "Ratio: 1:1",
          "Resolution: 72 dpi",
          "Format: JPG"
        ]}
        getter={squareThumbnailUrl}
        setter={setSquareThumbnailUrl}
        blobs={{
          url: {
            getter: squareImage,
            setter: setSquareImage,
          },
          id: {
            getter: squareThumbnailBlobId,
            setter: setSquareThumbnailBlobId,
          }
        }}
        images={images}
        height={150}
        width={150}
        altText={{
          getter: squareThumbnailAltText,
          setter: setSquareThumbnailAltText
        }}
        onUpload={handleSquareThumbnailUpload}
        validations={handleSquareThumbnailValidations}
        error={squareThumbnailError}
      />
    </PaperGroup>
  )
};

export const IconSelector = (props) => {
  const { menu, form, setForm } = props;

  const [ images, setImages ] = React.useState(
    _.uniq([
      ...(menu.squareImage ? [menu.squareImage] : []),
    ])
  );
  const [icon, setIcon] = React.useState(null);
  const [iconBlobId, setIconBlobId] = React.useState(null);
  const [iconAltText, setIconAltText] = React.useState("");
  const [iconUrl, setIconUrl] = React.useState(menu.icon);
  const [iconError, setIconError] = React.useState(null);


  React.useEffect(() => {
    setForm({
      ...form,
      icon,
      iconBlobId,
      // iconAltText,
    });
  }, [icon, iconBlobId, iconAltText]);

  function handleIconUpload(response) {
    setIconUrl(response.url);
    setIconBlobId(response.signedBlobId);
  }

  async function handleIconValidations(file) {
    setIconError(null);
    const dimensions = await getDimensions(file);
    const imageAspectRatio =  dimensions.width / dimensions.height;

    if (!checkFileType(file, ['png'])) {
      setIconError("Invalid file type");
      return false;
    }
    if (dimensions.width >= 256 && dimensions.height === dimensions.width) {
      if(imageAspectRatio === 1) return true;
      else{
        setIconError("Invalid ratio");
        return false;
      }
    } else {
      setIconError("Invalid dimensions");
      return false;
    }
  }

  return (
    <PaperGroup>
      <GroupHeader title="Icons" />
      <ImageSelector
        title="Icon"
        requirements={["Minimum: 256 x 256 px",
          "Ratio: 1:1",
          "Resolution: 72 dpi",
          "Format: PNG",
          "Transparency Supported"
        ]}
        getter={iconUrl}
        setter={setIconUrl}
        blobs={{
          url: {
            getter: icon,
            setter: setIcon,
          },
          id: {
            getter: iconBlobId,
            setter: setIconBlobId,
          }
        }}
        images={images}
        height={165}
        width={165}
        altText={{
          getter: iconAltText,
          setter: setIconAltText
        }}
        onUpload={handleIconUpload}
        validations={handleIconValidations}
        error={iconError}
        noAlt
      />
    </PaperGroup>
  );
}

export const InterstitialBackground = (props) => {
  const { menu, form, setForm, error, setError } = props;

  const [ interstitialBackground, setInterstitialBackground ] = React.useState(menu.interstitialBackground || { format: "basic_black", primaryColor: "#000000", secondaryColor: "#000000" });

  React.useEffect(() => {
    setForm({
      ...form,
      interstitialBackgroundAttributes: _.omit(interstitialBackground, "__typename", "assetUrl")
    });
    setError(null)
  }, [interstitialBackground]);

  return (
    <PaperGroup>
      <GroupHeader title="Interstitial Screen Background" />
      <BackgroundGroup
        getter={interstitialBackground}
        setter={setInterstitialBackground}
        basicBlack
        renderAsset
        supportedImageFormats={["jpg"]}
      />
      {error && <Error>{error}</Error>}
    </PaperGroup>
  );
}

export const BannerGroupSet = (props) => {
  const { menu, form, setForm } = props;
  const [ bannerGroupId, setBannerGroupId ] = React.useState(menu.bannerGroup?.id);
  const isBannerSupported = true;
  const initShowBanner = (bannerGroupId ?? parseInt(bannerGroupId) > 0) || false;
  const [showBanner, setShowBanner] = React.useState(initShowBanner);
  const [showPreviewAd, setShowPreviewAd] = React.useState(menu.showPreviewAd || false)

  React.useEffect(() => {
    setForm({ ...form, bannerGroupId });
  }, [bannerGroupId]);

  React.useEffect(() => {
    setForm({ ...form, showPreviewAd });
  }, [showPreviewAd]);

  React.useEffect(() => {
    !showBanner && setForm({ ...form, bannerGroupId: null });
  }, [showBanner]);

  return (
    <PaperGroup>
      <GroupHeader title="Advertising Options" />
      <CheckBox
        label={"Show Ads"}
        checked={showPreviewAd}
        onChange={setShowPreviewAd}
      />
      <SwitchGroup
        checked={showBanner}
        onChange={setShowBanner}
        label="Show Banners"
        disabled={!isBannerSupported}
      />
      {isBannerSupported
        ? <GroupHeader
            caption="Check to display in-app banners in this section. Then, determine which banner group should be displayed in the section."
          />
        : <GroupHeader
            caption="Banners cannot be activated for this section, because they are not supported by the selected TV or Mobile layout"
          />
      }
      {showBanner && (
        <BannerGroup id={bannerGroupId} setBannerGroupId={setBannerGroupId} />
      )}
    </PaperGroup>
  );
};

const thumbnailOrientationOptions = [
  { label: "Default", value: "default" },
  { label: "Landscape", value: "horizontal" },
  { label: "Portrait", value: "vertical" },
  { label: "Square", value: "square" },
];

export const MobileDesignSet = (props) => {
  const classes = useStyles();
  const { menu, form, setForm, isSection, isSaved, isGuide, showLogo, bundle, isSearch, isSettings } = props;

  const [ icon, setIcon ] = React.useState(menu.icon || "broadcast");
  const [ mobileLayout, setMobileLayout ] = React.useState(menu.mobileLayout || (isSaved ? "cards" : "videoGrid"));
  const [ mobileHideTitle, setMobileHideTitle ] = React.useState(menu.mobileHideTitle);
  const [ mobileThumbnailOrientation, setMobileThumbnailOrientation ] = React.useState(menu.mobileThumbnailOrientation || "horizontal");
  const [ mobileLogoBlobId, setMobileLogoBlobId ] = React.useState(null);
  const [ mobileLogoAltText, setMobileLogoAltText ] = React.useState(menu.mobileLogoAltText);
  const [ mobileLogoUrl, setMobileLogoUrl ] = React.useState(menu?.mobileLogoUrl);

  const [mobileLogoError, setMobileLogoError] = React.useState(null);

  const [fileMetadata, setFileMetadata] = React.useState("");
  const [ iconBlob, setIconBlob ] = React.useState({});

  React.useEffect(() => {
    if (fileMetadata?.name) setMobileLogoAltText(fileMetadata.name)
  }, [fileMetadata])

  React.useEffect(() => {
    setForm({
      ...form,
      icon,
      mobileLayout,
      mobileHideTitle,
      mobileThumbnailOrientation,
      mobileLogoBlobId,
      mobileLogoAltText,
      iconBlobId: iconBlob.signedBlobId,
    });
  }, [icon, mobileLayout, mobileHideTitle, mobileThumbnailOrientation,
    mobileLogoBlobId, mobileLogoAltText, iconBlob]);

  function handleMobileLogoUpload(response) {
    setMobileLogoUrl(response.url);
    setMobileLogoBlobId(response.signedBlobId);
  }

  async function handleMobileLogoValidations(file) {
    setMobileLogoError(null);

    const dimensions = await getDimensions(file);

    if (!checkFileType(file, ['png'])) {
      setMobileLogoError("Invalid file type");
      return false;
    }
    if (dimensions.height === 132) {
      return true;
    } else {
      setMobileLogoError("Invalid dimensions");
      return false;
    }
  }

  const renderFile = (url, alt, urlSetter, blobSetter) => (
    url
      ? <ImagePreview {...{ url, alt, urlSetter, blobSetter }} />
      : null
  );

  return (
    <PaperGroup>
      <GroupHeader
        caption={<div><b>Mobile/Tablet</b> &nbsp;&nbsp; *This setting will only be visible if there are multiple sub-sections or content feeds in this section.</div>}
      />
      <div className={classes.designContainer}>
        <div className={classes.designWrapper}>
          <GroupHeader
            title="Mobile Design"
            caption="Design elements for Mobile and iPad"
          />

          {isSection && (
            <IconPicker getter={icon} setter={setIcon} label="Icon" onUpload={setIconBlob} />
          )}
          {!isSearch && !isSettings && (
            <>
              <LayoutPicker
                device="mobile"
                getter={mobileLayout}
                setter={setMobileLayout}
                isSaved={isSaved}
                isSeries={["Bundle::Catalog::Series"].includes(bundle.type)}
                isGuide={isGuide}
              />
              <SwitchGroup
                checked={mobileHideTitle}
                onChange={setMobileHideTitle}
                name="hideTitle"
                label="Hide Title"
              />
            </>
          )}
        </div>
        <div className={classes.designWrapper}>
          {!isSearch && !isSettings && (
            <Select
              value={mobileThumbnailOrientation}
              onChange={setMobileThumbnailOrientation}
              label="Thumbnail Orientation"
              options={thumbnailOrientationOptions}
              required
            />
          )}
          {showLogo && (
            <>
              <UploadButton
                label="Logo"
                requirements={[
                  "Height: 132px",
                  "transparent background",
                  "72 dpi",
                  "PNG",
                ]}
                onUpload={handleMobileLogoUpload}
                validations={handleMobileLogoValidations}
                error={mobileLogoError}
                inputProps={{accept: '.png'}}
                fileRender={() => renderFile(mobileLogoUrl, "Mobile Logo", setMobileLogoUrl, setMobileLogoBlobId)}
                setFileMetadata={setFileMetadata}
              >
                Upload Image
              </UploadButton>
              <Input
                label="Mobile Logo Alt Text"
                value={mobileLogoAltText}
                onChange={setMobileLogoAltText}
                style={{ maxWidth: 320 }}
              />
            </>
          )}
        </div>
      </div>
    </PaperGroup>
  );
};

export const TvDesignSet = (props) => {
  const classes = useStyles();
  const params = useParams();
  const { menu, item, form, setForm, isSection, isSaved, showLogo, bundle } = props;

  const initialPreview = {
    previewType: menu.previewType || 'image',
    previewAudio: menu.previewAudio || false
  }

  const [ tvLayout, setTvLayout ] = React.useState(menu.tvLayout || (isSaved ? "standard" : "spotlight"));
  const [ tvHideTitle, setTvHideTitle ] = React.useState(menu.tvHideTitle);
  const [ tvThumbnailOrientation, setTvThumbnailOrientation ] = React.useState(menu.tvThumbnailOrientation);
  const [ tvLogoBlobId, setTvLogoBlobId ] = React.useState(null);
  const [ tvLogoUrl, setTvLogoUrl ] = React.useState(menu?.tvLogoUrl);
  const [ tvLogoAltText, setTvLogoAltText ] = React.useState(menu.tvLogoAltText);
  const [ tvBackground, setTvBackground ] = React.useState(menu.tvBackground || {
    format: "basic_black",
    primaryColor: "#000000",
    secondaryColor: "#0F7FFE",
  });
  const [ previewFields, setPreviewFields ] = React.useState(initialPreview)

  const [tvLogoError, setTvLogoError] = React.useState(null);
  const [fileMetadata, setFileMetadata] = React.useState("");

  React.useEffect(() => {
    if (fileMetadata?.name) setTvLogoAltText(fileMetadata.name)
  }, [fileMetadata])

  React.useEffect(() => {
    setForm({
      ...form,
      tvLayout,
      tvHideTitle,
      tvThumbnailOrientation,
      tvLogoBlobId,
      tvLogoAltText,
      tvBackgroundAttributes: _.omit(tvBackground, "__typename", "assetUrl"),
      ...previewFields
    });
  }, [tvLayout, tvHideTitle, tvThumbnailOrientation, tvLogoBlobId, tvLogoAltText, tvBackground, previewFields ]);

  React.useEffect(() => {
    if (tvLayout === 'spotlight') {
      setTvBackground({
        format: "basic_black",
        primaryColor: "#000000",
        secondaryColor: "#0F7FFE",
      }
    )}
  }, [tvLayout])

  function handleTvLogoUpload(response) {
    setTvLogoUrl(response.url);
    setTvLogoBlobId(response.signedBlobId);
  }

  async function handleTvLogoValidations(file) {
    setTvLogoError(null);

    const dimensions = await getDimensions(file);
    if (!checkFileType(file, ['png'])) {
      setTvLogoError("Invalid file type");
      return false;
    }
    if (dimensions.height === 300) {
      return true;
    } else {
      setTvLogoError("Invalid dimensions");
      return false;
    }
  }

  const renderFile = (url, alt, urlSetter, blobSetter) => (
    url
      ? <ImagePreview {...{ url, alt, urlSetter, blobSetter }} />
      : null
  );
  const isFullscreenOrSpotlight = tvLayout === 'spotlight' || tvLayout === 'fullscreen';
  const showTvBackground = isSection && !isSaved && tvLayout !== 'fullscreen';

  return (
    <PaperGroup>
      <div className={classes.designContainer}>
        <div className={classes.designWrapper}>
          <GroupHeader
            title="TV Design"
            caption="Design elements for TV and web apps"
          />
          <LayoutPicker
            device="tv"
            getter={tvLayout}
            setter={setTvLayout}
            isSaved={isSaved}
            isSeries={["Bundle::Catalog::Series"].includes(bundle.type)}
          />
          {tvLayout !== 'spotlight' && (
          <Select
            value={tvThumbnailOrientation}
            onChange={setTvThumbnailOrientation}
            label="Thumbnail Orientation"
            options={thumbnailOrientationOptions}
            required
          />)}
          <UploadButton
            label="Logo"
            requirements={[
              "Height: 300px",
              "transparent background",
              "72 dpi",
              "PNG",
            ]}
            onUpload={handleTvLogoUpload}
            validations={handleTvLogoValidations}
            error={tvLogoError}
            inputProps={{accept: '.png'}}
            fileRender={() => renderFile(tvLogoUrl, "TV Logo", setTvLogoUrl, setTvLogoBlobId)}
            setFileMetadata={setFileMetadata}
          >
            Upload Image
          </UploadButton>
        </div>
        <div className={classes.designWrapper}>
          {showTvBackground && (
            <BackgroundGroup
              getter={tvBackground}
              setter={setTvBackground}
              basicBlack
              supportedImageFormats={['jpg']}
              renderAsset
              disabled={tvLayout === 'spotlight'}
              key={tvBackground.format}
            />
          )}
          {isFullscreenOrSpotlight && (
            <PreviewGroup
              getter={previewFields}
              setter={setPreviewFields}
            />
          )}
          <SwitchGroup
            checked={tvHideTitle}
            onChange={setTvHideTitle}
            name="hideTitle"
            label="Hide Title"
          />
        </div>
      </div>
      {isSaved && (
        <GroupHeader
          caption={
            <div>Note: Set up your saved section interface in&nbsp;
              <Link to={`/${params.workspaceId}/${params.appId}/${SAVED_DESIGN_PATH}`}>Design</Link>
            </div>
          }
        />
      )}
    </PaperGroup>
  );
};

const SearchCriteriaSet = (props) => {
  const { bundle, menu, form, setForm, searchKeywordError } = props;

  const [ search_keywords, set_search_keywords ] = React.useState(bundle?.metadata?.search_keywords || "");

  React.useEffect(() => {
    setForm({ ...form, search_keywords });
  }, [search_keywords]);

  return (
    <PaperGroup>
      <RowItem
        title="Search Keywords"
        defaultValue={""}
        getter={search_keywords}
        setter={set_search_keywords}
        isResettable={false}
        isText={true}
        error={searchKeywordError}
        inputProps={{
          helperText: "We'll search these keywords to build the playlist.",
          required: true,
        }}
      />
    </PaperGroup>
  );
};

export const EventSet = (props) => {
  const { bundle, menu, form, setForm, searchKeywordError } = props;

  const [ starts_at, set_starts_at ] = React.useState(
    bundle?.metadata?.starts_at ? moment.unix(bundle.metadata.starts_at) : moment().startOf("hour").add("1", "hour")
  );
  const [ ends_at, set_ends_at ] = React.useState(
    bundle?.metadata?.ends_at ? moment.unix(bundle.metadata.ends_at) : starts_at.clone().add("1", "hour")
  );

  React.useEffect(() => {
    setForm({ ...form, starts_at: starts_at.unix(), ends_at: ends_at.unix() });
  }, [starts_at, ends_at]);

  return (
    <PaperGroup>
      <GroupHeader title="Availability" />
      <DateTimePicker value={starts_at} onChange={set_starts_at} />
      <DateTimePicker value={ends_at} onChange={set_ends_at} />
    </PaperGroup>
  );
}

export const FeedSet = (props) => {
  const NAME_CHARACTER_LIMIT = 65;
  const { type, bundle, form, setForm, feedNameError, feedUrlError, isMultiLangOrRegion } = props;

  const [ feed_name, set_feed_name ] = React.useState("");
  const [ feed_url, set_feed_url ] = React.useState("");
  const [ feed_language, set_feed_language ] = React.useState("");
  const feedFormats = type === "liveChannels" ? ["xmltv"] : ["mrss"]

  React.useEffect(() => {
    setForm({ ...form, feed_name, feed_url, feed_language, feed_format: feedFormats[0] });
  }, [feed_name, feed_url, feed_language]);

  return (
    <PaperGroup>
      {isMultiLangOrRegion && <LanguageSelect value={feed_language} onChange={set_feed_language} />}
      <Input
        label="Name"
        defaultValue={feed_name}
        onChange={set_feed_name}
        error={Boolean(feedNameError)}
        helperText={feedNameError || `${NAME_CHARACTER_LIMIT - feed_name.length} - character limit`}
        autoFocus
        required
        inputProps={{ maxLength: NAME_CHARACTER_LIMIT }}
      />
      <Input
        label="URL"
        defaultValue={feed_url}
        onChange={set_feed_url}
        error={Boolean(feedUrlError)}
        helperText={feedUrlError}
        required
      />
      <HelpText style={{ maxWidth: 300 }}>
        Requirements:
        <br />
        Allowed Formats: {feedFormats.map((f) => f.toUpperCase())}
      </HelpText>
    </PaperGroup>
  );
};

export const LocaleSelector = (props) => {
  const { locales, localeIds, setLocaleIds } = props;

  const [ ids, setIds ] = React.useState(localeIds || []);

  React.useEffect(() => {
    setLocaleIds(ids);
  }, [ids]);

  return (
    <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
      {locales.map((l) => (
        <Checkbox
          label={l.name}
          checked={ids.includes(l.id)}
          onChange={(checked) => {
            if (checked) {
              setIds([ ...ids, l.id ]);
            } else {
              setIds(ids.filter(m => m !== l.id));
            }
          }}
        />
      ))}
    </div>
  );
}

export const MenuLayoutForm = (props) => {
  const { titleError, setForm, isMultiLangOrRegion, defaultLocaleLang, bundle, form, app} = props;
  const menus = bundle?.menus || [];
  const [ kind, setKind ] = React.useState(bundle?.metadata?.kind || "setting");
  const defaultSetting = bundle?.metadata?.kind === "setting" ? bundle?.metadata?.value : "location";
  const defaultaction = bundle?.metadata?.kind === "action" ? bundle?.metadata?.value : "authenticate";
  const [ setting, setSetting ] = React.useState(defaultSetting);
  const [ action, setAction ] = React.useState(defaultaction);
  const [ titleLangObj, setTitleLangObj ] = React.useState(makeDefaultLangObjectFromMenus(menus, app.locales));
  
  const title = titleLangObj[defaultLocaleLang.lang];
  React.useEffect(() => {
    setForm({
      menus: generateMenusFromLanguages(getValueForTokenizeInput(titleLangObj, true), menus, form),
      bundleMetadata: {
        kind,
        value: kind === "setting" ? setting: action
      }
    })
  }, [titleLangObj, kind, setting, action])

  const setDefaultTitle = (value) => {
    setTitleLangObj(prev => ({...prev, [defaultLocaleLang.lang]: value}))
  }

  return <PaperGroup>
    <GroupHeader title="Menu item details" />
    <div>
      <Input
        label="Title"
        value={removeSeparatorForMenuLayout(title)}
        onChange={setDefaultTitle}
        error={titleError}
        helperText={titleError}
        required
        disabled={isMultiLangOrRegion}
      />
      <LanguageText
        data={removeSeparatorForMenuLayout(titleLangObj, true)}
        onSubmit={setTitleLangObj}
        languageOnly
        buttonProps={{style: {padding: 15, marginBottom: 5}}}
      />
      <MenuTokenSelector
        value={titleLangObj}
        setValue={setTitleLangObj}
        isMultiLanguage
      />
    </div>
    <div style={{display: 'flex', width: 600, justifyContent: "space-between"}}>
      <RadioInput
        options={[
          {label: "Setting", value: "setting"},
          {label: "Action", value: "action"},
          {label: "Header", value: "header"},
          {label: "Info", value: "info"},
        ]}
        value={kind}
        onChange={setKind}
      />
      <div style={{display: 'flex', width: "450px", flexDirection: "column", justifyContent: "flex-start"}}>
        <Select
          value={setting}
          onChange={setSetting}
          label="Settings"
          options={[
            {label: "Location", value: "location"},
            {label: "Language", value: "language"},
            {label: "Notifications", value: "notifications"},
            {label: "Autoplay", value: "autoplay"},
          ]}
          disabled={kind !== "setting"}
        />
        <Select
          value={action}
          onChange={setAction}
          label="Actions"
          options={[
            {label: "Authenticate", value: "authenticate"},
            {label: "Restore Purchases", value: "restore"},
            {label: "Get Help", value: "getHelp"},
          ]}
          disabled={kind !== "action"}
        />
      </div>
    </div>
  </PaperGroup>
}
