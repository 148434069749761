import React from "react";

import Input from "components/atoms/Input/BaseInput";
import { INTEGRATION_TVE } from "../constants";

export default function Toolbox(props) {
  const { integration, setForm, setIsFormChanged } = props;

  const [ help, setHelp ] = React.useState(integration.metadata?.help || "");
  const [ host, setHost ] = React.useState(integration.metadata?.host || "");
  const [ apiKey, setApiKey ] = React.useState(integration.metadata?.api_key || "");
  const [ product, setProduct ] = React.useState(integration.metadata?.product || "");

  React.useEffect(() => {
    setForm({
      type: INTEGRATION_TVE,
      host,
      apiKey,
      product,
      metadataExtension: {
        help,
      },
      template: "Toolbox",
    });
    setIsFormChanged(true);
  }, [ help, host, apiKey, product ]);

  React.useEffect(() => {
    setIsFormChanged(false);
  },[]);

  return (
    <>
      <Input
        label="Host"
        value={host}
        onChange={setHost}
        required
      />

      <Input
        label="API Key"
        value={apiKey}
        onChange={setApiKey}
        required
      />

      <Input
        label="Product"
        value={product}
        onChange={setProduct}
        required
      />

      <Input
        label="Help Link"
        value={help}
        onChange={setHelp}
      />
    </>
  );
}
