import PreviewDevices from 'components/molecules/Modal/PreviewDevices';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const useStyles = (style) => makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        paddingTop: '30px',
        ...style.containerStyle,
    }
}))

/**
 * 
 * @param {Object} props 
 * @param {{tvImageUrl: String, mobileImageUrl: String}[]} props.banners
 * 
 */
function Preview(props) {
    const {banners, ...otherProps} = props;
    const classes = useStyles(props)();
    const [device, setDevice] = React.useState(null);
    const [bannerImages, setBannerImages] = React.useState(banners.map(banner => banner.mobileImageUrl));
    React.useEffect(() => {
        if (device === 'ott') {
            setBannerImages(banners.map(banner => banner.tvImageUrl));
        } else {
            setBannerImages(banners.map(banner => banner.mobileImageUrl));
        }
    }, [device, banners])

    return (
        <PreviewDevices
            {...otherProps}
            key={otherProps.open}
            setDevice={setDevice}
        >
            <div className={classes.container} style={{}}>
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    showIndicators={false}
                    showArrows={false}
                    interval={2000}
                >
                    {bannerImages.map(imgSrc => (
                        <img src={imgSrc} alt="banner" width='100%'/>
                    ))}
                </Carousel>
            </div>
        </PreviewDevices>
    )
}

export default Preview
