import { useQuery } from '@apollo/client';
import React from 'react';

import { GET_CURRENT_APP_PROPS, GET_BANNER_GROUP } from "api";
import Input from 'components/atoms/Input/BaseInput';
import BannerSelectorModal from 'components/molecules/Modal/BannerGroupSelector';

export default function BannerGroup(props) {
  const { id, setBannerGroupId, textFieldProps } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;

  const { data, loading, error } = useQuery(GET_BANNER_GROUP, { variables: { ...appProps, id: id } });

  const [ modalOpen, setModalOpen ] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div>
      <Input
        readOnly
        required
        label="Choose banner group"
        value={data?.bannerGroup?.name || "Choose"}
        name="bannerGroupId"
        onClick={openModal}
        style={{ maxWidth: 260 }}
        onChange={() => {}}
        {...textFieldProps}
      />

      <BannerSelectorModal
        open={modalOpen}
        onClose={closeModal}
        id={id}
        setBannerGroupId={setBannerGroupId}
      />
    </div>
  );
}
