import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { connect } from 'react-redux';

import * as actions from 'actions';
import ApolloContainer from './ApolloContainer';
import Login from './Login';

export const drawerWidth = 240;

function Layout(props) {
  if (!props.user?.jwt) {
    return <Login />
  }

  return (
    <ApolloContainer token={props.user.jwt}>
      <CssBaseline />
      <main>
        {props.children}
      </main>
    </ApolloContainer>
  );
}

const mapStateToProps = (state) => ({
  user: state.session.user,
});

export default connect(mapStateToProps, actions)(Layout);
