import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { useTheme } from "@mui/material/styles";

import { CREATE_TIER, UPDATE_TIER } from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import PricePicker from "components/atoms/Input/PricePicker";
import { withSavedSnack } from "components/molecules/Alerts/Saved";
import PaperGroup from 'components/molecules/Form/PaperGroup';
import Subscriptions from './Form/Subscriptions';
import Rentals, { HOUR_IN_SEC } from './Form/Rentals';
import Externals from './Form/Externals';
import { SwitchGroup } from 'components/atoms/Input/Switch';
import ContentLayout from 'screens/Layout/ContentLayout';
import Error from 'components/atoms/Error';
import LocaleOptions from 'components/molecules/LocaleOptions';
import LanguageText from 'components/molecules/LanguageText';
import { getDefaultLocaleAndLang } from 'helpers/langUtils';

const TYPES = {
  purchase : "Purchase",
  rental : "Rental",
  event : "Event",
  subscription : "Subscription",
  registration : "Registration"
}

const DEFAULT_TRIAL_VALUES = {
  oneMonthFreeTrialDuration: 7,
  oneMonthPrice: 1,
  threeMonthFreeTrialDuration: 30,
  threeMonthPrice: 1,
  sixMonthFreeTrialDuration: 30,
  sixMonthPrice: 1,
  twelveMonthFreeTrialDuration: 30,
  twelveMonthPrice: 1,
}

function defaultTitle(data) {
  return {
    en: data.title,
    fr: data.titleFr,
    es: data.titleEs,
    ge: data.titleGe,
    it: data.titleIt,
    pt: data.titlePt,
    nl: data.titleNl,
    el: data.titleEl,
  }
}

function Form(props) {
  const { appProps, app, name, filter, data, monetizationPath, isMultiLangOrRegion } = props;
  const theme = useTheme()
  const history = useHistory();
  const metadata = data?.metadata;
  const subscriptions = metadata?.subscriptions;
  const defaultLocaleLang = getDefaultLocaleAndLang(app);
  const initialSubscriptionFields = {
    offerSales: metadata?.offer_sales === undefined ? true : Boolean(metadata?.offer_sales),
    integrationIds: metadata?.integration_ids || [],
    ...getSubscriptionDetails(subscriptions, 'oneMonth', 0),
    ...getSubscriptionDetails(subscriptions, 'threeMonth', 1),
    ...getSubscriptionDetails(subscriptions, 'sixMonth', 2),
    ...getSubscriptionDetails(subscriptions, 'twelveMonth', 3)
  }

  const initialRental = {
    startDuration: data?.metadata?.start || 36 * HOUR_IN_SEC,
    finishDuration: data?.metadata?.finish || 72 * HOUR_IN_SEC,
  }

  const [price, setPrice] = React.useState(data?.price?.id || 1);
  const [rentalFields, setRentalFields] = React.useState(initialRental);
  const [subscriptionFields, setSubscriptionFields] = React.useState(initialSubscriptionFields);
  const [suppressAds, setSuppressAds] = React.useState(metadata?.extra_controls?.suppress_ads || true);
  const [titleErr, setTitleErr] = React.useState(false);
  const [subscriptionErr, setSubscriptionErr] = React.useState(false);
  const [integrationErr, setIntegrationErr] = React.useState(false);
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [localeIds, setLocaleIds] = React.useState(data.localeIds || null);
  const [localeErr, setLocaleErr] = React.useState(null);
  const [ titleLangObj, setTitleLangObj ] = React.useState(defaultTitle(data));

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/${monetizationPath}`;
  const title = titleLangObj.en;
  const titleFr = titleLangObj.fr;
  const titleEs = titleLangObj.es;
  const titleGe = titleLangObj.ge;
  const titleIt = titleLangObj.it;
  const titlePt = titleLangObj.pt;
  const titleNl = titleLangObj.nl;
  const titleEl = titleLangObj.el;
  const anyTitle = title || titleEs || titleFr || titleGe || titleIt || titlePt || titleNl || titleEl;

  const onSave = (tier) => {
    props.savedSnack.launch();
    history.push(`${listUrl}`);
  };

  const onCancel = () => {
    history.push(`${listUrl}`);
  }

  const setDefaultTitle = (value) => {
    setTitleLangObj(prev => ({...prev, [defaultLocaleLang.lang]: value}))
  }

  const [updatePurchaseTier, { loading: isUpdating }] = useMutation(UPDATE_TIER, {
    update(cache, { data: { updateTier } }) {
      cache.modify({
        fields: {
          tiers(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateTier.tier.id === readField("id", ref)
                ? { ...ref, ...updateTier.tier }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      onSave(data);
    },
    onError(data) {
      props.savedSnack.launch(data.message, {type: 'error'})
      setDisableForm(false)
    }
  });

  const [createPurchaseTier, { loading: isCreating }] = useMutation(CREATE_TIER, {
    update(cache, { data: { createTier } }) {
      cache.modify({
        fields: {
          tiers(existingRefs) {
            return [...existingRefs, createTier.tier];
          },
        },
      });
    },
    onCompleted(data) {
      onSave(data?.createTier?.tier);
    },
    onError(data) {
      props.savedSnack.launch(data.message, {type: 'error'})
      setDisableForm(false)
    },
  });

  const submit = () => {
    if (!validateForm()) return null;
    setDisableForm(true)

    let variables;
    variables = {
      ...appProps,
      tierFields: {
        title: title || anyTitle,
        titleEs,
        titleFr,
        titleGe,
        titleIt,
        titlePt,
        titleNl,
        titleEl,
        priceId: price,
        suppressAds,
        localeIds: localeIds,
      },
    };
    if (data.id) {
      variables = { ...variables, tierId: data.id };
    } else {
      variables = {
        ...variables,
        tierFields: { ...variables.tierFields, type: data?.type },
      };
    }
    if (name === "Rentals") {
      variables = {
        ...variables,
        tierFields: { ...variables.tierFields, ...rentalFields },
      };
    }
    if (name === "Subscriptions") {
      variables = {
        ...variables,
        tierFields: { ...variables.tierFields, ...subscriptionFields },
      };
    }

    if (data.id) {
      updatePurchaseTier({ variables });
    } else {
      createPurchaseTier({ variables });
    }
  };

  const validateForm = () => {
    let isValidated = true;
    const oneTitle = title || titleEs || titleFr || titleGe || titleIt || titlePt || titleNl || titleEl;
    if (!oneTitle || !oneTitle.trim()) {
      setTitleErr(true);
      isValidated = false;
    }
    if (
      name === "Subscriptions" &&
      subscriptionFields.offerSales &&
      !subscriptionFields.oneMonthActive &&
      !subscriptionFields.threeMonthActive &&
      !subscriptionFields.sixMonthActive &&
      !subscriptionFields.twelveMonthActive
    ) {
      setSubscriptionErr(true);
      isValidated = false;
    }
    if (
      name === "Subscriptions" &&
      !subscriptionFields.offerSales &&
      subscriptionFields.integrationIds.length === 0
    ) {
      setIntegrationErr(true);
      isValidated = false;
    }
    if (localeIds && !localeIds.length) {
      isValidated = false;
      setLocaleErr(true);
    }
    return isValidated;
  };

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [title, titleEs, titleFr, titleGe, titleIt, titlePt, titleNl, titleEl,
    price, rentalFields, subscriptionFields, localeIds])

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  React.useEffect(() => {
    setLocaleErr(false);
  }, localeIds)

  React.useEffect(() => {
    setTitleErr(false);
    setSubscriptionErr(false);
  }, [title, titleEs, titleFr, titleGe, titleIt, titlePt, titleNl, titleEl,
    subscriptionFields]);

  React.useEffect(() => {
    if (subscriptionFields?.integrationIds && subscriptionFields.integrationIds.length > 0) {
      setIntegrationErr(false);
    }
  }, [subscriptionFields?.integrationIds]);

  const nonNullTitle = anyTitle || `Untitled ${name}`;
  const isLoading = isCreating || isUpdating;

  return (
    <ContentLayout title={name}>
      <FormLayout
        submit={submit}
        cancel={onCancel}
        breadcrumbs={[{ name: name, link: listUrl }, { name: nonNullTitle }]}
        isChanged={isFormChanged}
        betterForm
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <PaperGroup>
          {subscriptionErr && (
            <Error>Please activate at least one subscription period.</Error>
          )}
          {integrationErr && (
            <Error>Please select at least one integration.</Error>
          )}

      <LocaleOptions
        locales={localeIds}
        setLocales={setLocaleIds}
        errorText={localeErr ? "Please select at least one Locale" : null}
      />

      {filter === TYPES.subscription && (
        <>
          <SwitchGroup
            label="Offer for sale"
            checked={subscriptionFields.offerSales}
            onChange={(val) => setSubscriptionFields({ ...subscriptionFields, offerSales: val })}
            disabled={Boolean(data?.id)}
          />
          {!subscriptionFields.offerSales && (
            <Externals
              getter={subscriptionFields}
              setter={setSubscriptionFields}
              disabled={disableForm}
            />
          )}
        </>
      )}

        <div style={{display: 'flex'}}>
          <Input
            error={titleErr}
            label="Title"
            value={anyTitle}
            onChange={setDefaultTitle}
            autoFocus
            required
            disabled={isMultiLangOrRegion}
          />
          <LanguageText
            data={titleLangObj}
            onSubmit={setTitleLangObj}
            languageOnly
            buttonProps={{style: {marginBottom: '8px'}}}
          />
        </div>
          {[TYPES.rental, TYPES.event, TYPES.purchase].includes(filter) && (
            <PricePicker value={price} onChange={setPrice} disabled={disableForm}/>
          )}
          {filter === TYPES.rental && (
            <Rentals
              getter={rentalFields}
              setter={setRentalFields}
              disabled={disableForm}
            />
          )}
          {filter === TYPES.subscription && subscriptionFields.offerSales && (
            <Subscriptions
              getter={subscriptionFields}
              setter={setSubscriptionFields}
              disabled={disableForm}
            />
          )}
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default withSavedSnack(Form);

function getSubscriptionDetails(subDetails, type, index) {
  return {
    [`${type}Active`]: subDetails ? subDetails[index]['active'] : false,
    [`${type}Price`]: subDetails ? subDetails[index]['price'] : DEFAULT_TRIAL_VALUES[`${type}Price`],
    [`${type}FreeTrial`]: subDetails ? subDetails[index]['free_trial'] : false,
    [`${type}FreeTrialDuration`]: subDetails ? subDetails[index]['free_trial_duration'] : DEFAULT_TRIAL_VALUES[`${type}FreeTrialDuration`],
  }
}
