import React from "react";
import BaseInput from "./BaseInput";

export default function GaTrackingId(props) {
  return (
    <BaseInput
      name="gaTrackingId"
      label="GA Tracking ID"
      placeholder="UA-xxxxxxxxx-x"
      {...props}
    />
  );
}
