import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import React from "react";

function RadioInput({ value, onChange, options, label }) {
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        aria-label={label}
        name="radio-group"
        value={value}
        onChange={(e) => onChange(e.target.value, e)}
      >
        {options.map((o) => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio />}
            label={o.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioInput;
