import { gql } from '@apollo/client';

export const ITEM_FRAGMENT = gql`
  fragment ItemSchema on Item {
    __typename
    id
    title
    image
    url
    guid
    media
    summary
    duration
    keywords
    metadata
    createdAt
  }
`

export const APP_ITEM_FRAGMENT = gql`
  fragment AppItemSchema on AppItem {
    __typename
    id
    bundle {
      id
      type
      folders {
        id
        type
      }
    }
    menus {
      id
      title
      item {
        id
        title
        duration
        images
        feed {
          id
          name
        }
      }
    }
    updatedAt
  }
`

export const GET_APP_ITEMS = gql`
  query appItems($workspaceId: ID! $appId: ID! $filter: String) {
    appItems(workspaceId: $workspaceId appId: $appId filter: $filter) {
      ...AppItemSchema
    }
  }
  ${APP_ITEM_FRAGMENT}
`

export const GET_APP_ITEMS_PAGINATED = gql`
  query paginatedAppItems($workspaceId: ID! $appId: ID! $filter: String $offset: Int! $limit: Int! $bundleFilter: [String!] $filterModel: String) {
    paginatedAppItems(workspaceId: $workspaceId appId: $appId filter: $filter offset: $offset limit: $limit bundleFilter: $bundleFilter filterModel: $filterModel) {
      count
      items {
        ...AppItemSchema
      }
    }
  }
  ${APP_ITEM_FRAGMENT}
`

export const GET_APP_ITEM = gql`
  query appItem($workspaceId: ID! $appId: ID! $id: ID!) {
    appItem(workspaceId: $workspaceId appId: $appId id: $id) {
      ...AppItemSchema
    }
  }
  ${APP_ITEM_FRAGMENT}
`

export const UNLINK_APP_ITEM = gql`
  mutation unlinkAppItem(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
  ) {
    unlinkAppItem(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
      }
    ) {
      appItem {
        ...AppItemSchema
      }
    }
  }
  ${APP_ITEM_FRAGMENT}
`;

export const GET_APP_ITEMS_DELETED_BUNDLES = gql`
  query unlinkedBundledAppItems($workspaceId: ID! $appId: ID! $filter: [String!]) {
    unlinkedBundledAppItems(workspaceId: $workspaceId appId: $appId filter: $filter) {
      ...AppItemSchema
    }
  }
  ${APP_ITEM_FRAGMENT}
`;
