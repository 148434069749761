import React from 'react';

import { withDesign } from './Design';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import Layout from './Layout';
import GroupHeader from 'components/atoms/Text/GroupHeader';
import { BooleanSelect } from 'components/atoms/Input/Select';
import Input from 'components/atoms/Input/BaseInput';
import PaperGroup from 'components/molecules/Form/PaperGroup';
import { generateLocalesForDesigns } from 'helpers/langUtils';
import LanguageText from 'components/molecules/LanguageText';

function Legal(props) {
  const { simulator, designs, isMultiLangOrRegion } = props;
  const design = simulator.design;

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [ gdprActive, setGdprActive ] = React.useState(design.gdprActive);
  const [ gdprThirdPartyActive, setGdprThirdPartyActive ] = React.useState(design.gdprThirdPartyActive);
  const [ gdprHeaderTextError, setGdprHeaderTextError ] = React.useState(null);
  const [ gdprPrivacyTextError, setGdprPrivacyTextError ] = React.useState(null);
  const [ gdprPrivacyUrlError, setGdprPrivacyUrlError ] = React.useState(null);
  const [ gdprPrivacyLabelError, setGdprPrivacyLabelError ] = React.useState(null);
  const [ gdprThirdPartyPrivacyTextError, setGdprThirdPartyPrivacyTextError ] = React.useState(null);
  const [ gdprThirdPartyPrivacyUrlError, setGdprThirdPartyPrivacyUrlError ] = React.useState(null);
  const [ gdprThirdPartyPrivacyLabelError, setGdprThirdPartyPrivacyLabelError ] = React.useState(null);
  const [ gdprSubmitButtonLabelError, setGdprSubmitButtonLabelError ] = React.useState(null);

  const [ ccpaShow, setCcpaShow ] = React.useState(design.ccpaShow);
  const [ ccpaSellUsersInfo, setCcpaSellUsersInfo ] = React.useState(design.ccpaSellUsersInfo);
  const [ ccpaMessageError, setCcpaMessageError ] = React.useState(null);
  const [ ccpaEmailAddressError, setCcpaEmailAddressError ] = React.useState(null);
  const [ textLocales, setTextLocales ] = React.useState(getTextLocales());
  const defaultLocale = {
    locale: design.locale.id,
    lang: design.language
  }

  const gdprHeaderText = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprHeaderText"];
  const gdprPrivacyText = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprPrivacyText"]
  const gdprPrivacyUrl = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprPrivacyUrl"]
  const gdprPrivacyLabel = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprPrivacyLabel"]
  const gdprThirdPartyPrivacyText = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprThirdPartyPrivacyText"]
  const gdprThirdPartyPrivacyUrl = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprThirdPartyPrivacyUrl"]
  const gdprThirdPartyPrivacyLabel = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprThirdPartyPrivacyLabel"]
  const gdprSubmitButtonLabel = textLocales[defaultLocale.locale][defaultLocale.lang]["gdprSubmitButtonLabel"]
  const ccpaMessage = textLocales[defaultLocale.locale][defaultLocale.lang]["ccpaMessage"]
  const ccpaEmailAddress = textLocales[defaultLocale.locale][defaultLocale.lang]["ccpaEmailAddress"]

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [textLocales,gdprActive,ccpaShow,gdprThirdPartyActive,ccpaSellUsersInfo]);

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  const cancel = () => {
    setGdprActive(design.gdprActive);
    setGdprThirdPartyActive(design.gdprThirdPartyActive);
    setCcpaShow(design.ccpaShow);
    setCcpaSellUsersInfo(design.ccpaSellUsersInfo);
    setTextLocales(getTextLocales());

    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const stateDesign = {
    gdprHeaderText,
    gdprActive,
    gdprPrivacyText,
    gdprPrivacyUrl,
    gdprPrivacyLabel,
    gdprThirdPartyActive,
    gdprThirdPartyPrivacyText,
    gdprThirdPartyPrivacyUrl,
    gdprThirdPartyPrivacyLabel,
    gdprSubmitButtonLabel,
    ccpaShow,
    ccpaMessage,
    ccpaSellUsersInfo,
    ccpaEmailAddress,
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm: {...stateDesign, textLocales},
    simulator: { ...simulator, noDevice: true },
    tab: "Data & Privacy",
    headerTitle: "Privacy",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  function getTextLocales() {
    return generateLocalesForDesigns(designs, ["gdprHeaderText", "gdprPrivacyText", "gdprPrivacyUrl", "gdprPrivacyLabel", "gdprThirdPartyPrivacyText", "gdprThirdPartyPrivacyUrl", "gdprThirdPartyPrivacyLabel", "gdprSubmitButtonLabel", "ccpaMessage", "ccpaEmailAddress"])
  }

  function multiText(title, key, charLimit = 0, buttonMargin) {
    const buttonProps = buttonMargin
      ? { style: { marginBottom: buttonMargin } }
      : {};
    return (
      <LanguageText
        onSubmit={setTextLocales}
        data={textLocales}
        property={{ key, charLimit }}
        buttonProps={buttonProps}
        title={title}
      />
    );
  }

  function onLangInputChange(val, key) {
    const textLocaleDuplicate = {...textLocales}
    textLocaleDuplicate[defaultLocale.locale][defaultLocale.lang][key] = val;
    setTextLocales(textLocaleDuplicate);
  }

  const notValidate = () => {
    let check = false

    if(gdprActive){
      if(!gdprHeaderText){
        check = true
        setGdprHeaderTextError("required field")
      }
      if(!gdprPrivacyText){
        check = true
        setGdprPrivacyTextError("required field")
      }
      if(!gdprPrivacyLabel){
        check = true
        setGdprPrivacyLabelError("required field")
      }
      if(!gdprPrivacyUrl){
        check = true
        setGdprPrivacyUrlError("required field")
      }
      if(!gdprSubmitButtonLabel){
        check = true
        setGdprSubmitButtonLabelError("required field")
      }

      if(gdprThirdPartyActive){
        if(!gdprThirdPartyPrivacyText){
          check = true
          setGdprThirdPartyPrivacyTextError("required field")
        }
        if(!gdprThirdPartyPrivacyUrl){
          check = true
          setGdprThirdPartyPrivacyUrlError("required field")
        }
        if(!gdprThirdPartyPrivacyLabel){
          check = true
          setGdprThirdPartyPrivacyLabelError("required field")
        }
      }
    }

    if(ccpaShow){
      if(ccpaSellUsersInfo){
        if(!ccpaEmailAddress){
          check = true
          setCcpaEmailAddressError("required field")
        }
      }
      else{
        if(!ccpaMessage){
          check = true
          setCcpaMessageError("required field")
        }
      }
    }
    return check
  }

  return (
    <Layout {...layoutProps} notValidate={notValidate} >
      <PaperGroup>
        <GroupHeader title="GDPR" />

        <BooleanSelect
          value={gdprActive}
          onChange={setGdprActive}
          label="Consent"
          helperText="Display Data and Privacy and ask for consent"
          disabled={disableForm}
          required
        />

        {gdprActive && (
          <>
            <div style={{display: 'flex'}}>
              <Input
                label="Header text"
                required
                value={gdprHeaderText}
                onChange={val => {onLangInputChange(val, "gdprHeaderText")}}
                error={!gdprHeaderText && gdprHeaderTextError}
                disabled={isMultiLangOrRegion || disableForm}
              />
              {multiText("Header text", "gdprHeaderText", 0, "8px")}
            </div>
            <div style={{display: 'flex'}}>
              <Input
                label="Your notice"
                required
                value={gdprPrivacyText}
                onChange={val => {onLangInputChange(val, "gdprPrivacyText")}}
                error={!gdprPrivacyText && gdprPrivacyTextError}
                multiline
                rows={4}
                disabled={isMultiLangOrRegion || disableForm}
              />
              {multiText("Your notice", "gdprPrivacyText", 0, "8px")}
            </div>
            <div style={{display: 'flex'}}>
              <Input
                label="Privacy policy label"
                required
                value={gdprPrivacyLabel}
                onChange={val => {onLangInputChange(val, "gdprPrivacyLabel")}}
                error={!gdprPrivacyLabel && gdprPrivacyLabelError}
                disabled={isMultiLangOrRegion || disableForm}
              />
              {multiText("Privacy policy label", "gdprPrivacyLabel", 0, "8px")}
            </div>
            <div style={{display: 'flex'}}>
              <Input
                label="Your privacy URL"
                value={gdprPrivacyUrl}
                onChange={val => {onLangInputChange(val, "gdprPrivacyUrl")}}
                error={!gdprPrivacyUrl && gdprPrivacyUrlError}
                required
                disabled={isMultiLangOrRegion || disableForm}
              />
              {multiText("Your privacy URL", "gdprPrivacyUrl", 0, "8px")}
            </div>

            <BooleanSelect
              value={gdprThirdPartyActive}
              onChange={setGdprThirdPartyActive}
              label="Add additional consent"
              disabled={disableForm}
              required
            />

            {gdprThirdPartyActive && (
              <>
                <div style={{display: 'flex'}}>
                  <Input
                    label="Additional notice"
                    value={gdprThirdPartyPrivacyText}
                    onChange={val => {onLangInputChange(val, "gdprThirdPartyPrivacyText")}}
                    multiline
                    rows={4}
                    required
                    error={!gdprThirdPartyPrivacyText && gdprThirdPartyPrivacyTextError}
                    disabled={isMultiLangOrRegion || disableForm}
                  />
                  {multiText("Additional notice", "gdprThirdPartyPrivacyText", 0, "8px")}
                </div>
                <div style={{display: 'flex'}}>
                  <Input
                    label="Privacy policy label"
                    value={gdprThirdPartyPrivacyLabel}
                    onChange={val => {onLangInputChange(val, "gdprThirdPartyPrivacyLabel")}}
                    error={!gdprThirdPartyPrivacyLabel && gdprThirdPartyPrivacyLabelError}
                    required
                    disabled={isMultiLangOrRegion || disableForm}
                  />
                  {multiText("Privacy policy label", "gdprThirdPartyPrivacyLabel", 0, "8px")}
                </div>
                <div style={{display: 'flex'}}>
                  <Input
                    label="Additional privacy URL"
                    value={gdprThirdPartyPrivacyUrl}
                    onChange={val => {onLangInputChange(val, "gdprThirdPartyPrivacyUrl")}}
                    error={!gdprThirdPartyPrivacyUrl && gdprThirdPartyPrivacyUrlError}
                    required
                    disabled={isMultiLangOrRegion || disableForm}
                  />
                  {multiText("Additional privacy URL", "gdprThirdPartyPrivacyUrl", 0, "8px")}
                </div>
              </>
            )}
            <div style={{display: 'flex'}}>
              <Input
                label="Submit button label"
                value={gdprSubmitButtonLabel}
                onChange={val => {onLangInputChange(val, "gdprSubmitButtonLabel")}}
                error={!gdprSubmitButtonLabel && gdprSubmitButtonLabelError}
                required
                disabled={isMultiLangOrRegion || disableForm}
              />
              {multiText("Submit button label", "gdprSubmitButtonLabel", 0, "8px")}
            </div>
          </>
        )}
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="CCPA Compliance" />

        <BooleanSelect
          value={ccpaShow}
          onChange={setCcpaShow}
          label="Display message"
          helperText="Display CCPA compliance message"
          disabled={disableForm}
        />

        {ccpaShow && (
          <>
            <BooleanSelect
              value={ccpaSellUsersInfo}
              onChange={setCcpaSellUsersInfo}
              label="Do you sell user's information?"
              disabled={disableForm}
            />

            {ccpaSellUsersInfo
              ? <div style={{display: 'flex'}}>
                  <Input
                      key="ccpaEmailAddress"
                      label="Opt-out email address"
                      required
                      value={ccpaEmailAddress}
                      onChange={val => {onLangInputChange(val, "ccpaEmailAddress")}}
                      error={!ccpaEmailAddress && ccpaEmailAddressError}
                      disabled={isMultiLangOrRegion || disableForm}
                    />
                    {multiText("Opt-out email address", "ccpaEmailAddress", 0, "8px")}
                </div>
              : <div style={{display: 'flex'}}>
                  <Input
                      key="ccpaMessage"
                      label="CCPA Message"
                      required
                      value={ccpaMessage}
                      onChange={val => {onLangInputChange(val, "ccpaMessage")}}
                      inputProps={{ maxLength: 255 }}
                      helperText={`${255 - ccpaMessage.length} - character limit`}
                      error={!ccpaMessage && ccpaMessageError}
                      disabled={isMultiLangOrRegion || disableForm}
                    />
                    {multiText("CCPA Message", "ccpaMessage", 255)}
              </div>
            }
          </>
        )}
      </PaperGroup>
    </Layout>
  );
}

export default withDesign(withSimulator(Legal));
