import React from "react";
import TvOnboarding from "../../Tv/Screens/Onboarding";

function Content(props) {
  return(
    <TvOnboarding {...props} />
  );
}

export default Content;
