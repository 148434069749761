import React from 'react';

import ColorPicker from 'components/atoms/Input/ColorPicker';
import Divider from 'components/atoms/Divider';
import { withDesign } from './Design';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import HelpText from 'components/atoms/Text/Help';
import IconPicker from 'components/atoms/Input/IconPicker';
import UploadButton from 'components/molecules/UploadGroup';
import Select, { BooleanSelect } from 'components/atoms/Input/Select';
import Input from 'components/atoms/Input/BaseInput';
import GroupHeader from 'components/atoms/Text/GroupHeader';
import { getHeightAndWidthFromDataUrl } from 'helpers/fileUtils';
import Layout from './Layout';
import { generateLocalesForDesigns } from 'helpers/langUtils';
import LanguageText from 'components/molecules/LanguageText';

function General(props) {
  const { simulator, designs, isMultiLangOrRegion} = props;
  const design = simulator.design;
  // const design = designs.find(d => d.language === 'en') || designs[0];

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [ themePrimary, setThemePrimary ] = React.useState(design.themePrimary);
  const [ themeSecondary, setThemeSecondary ] = React.useState(design.themeSecondary);
  const [ themeActive, setThemeActive ] = React.useState(design.themeActive);
  const [ themeInactive, setThemeInactive ] = React.useState(design.themeInactive);
  const [ themeTabBar, setThemeTabBar ] = React.useState(design.themeTabBar);
  const [ themeMobileTabBar, setThemeMobileTabBar ] = React.useState(design.themeMobileTabBar);
  const [ themeBorder, setThemeBorder ] = React.useState(design.themeBorder);
  const [ themeMobileBorder, setThemeMobileBorder ] = React.useState(design.themeMobileBorder);
  const [ themeMobileVideoGridBackground, setThemeMobileVideoGridBackground ] = React.useState(design.themeMobileVideoGridBackground);
  const [ themeMobileVideoGridText, setThemeMobileVideoGridText ] = React.useState(design.themeMobileVideoGridText);
  const [ iconSearch, setIconSearch ] = React.useState(design.iconSearch);
  const [ iconSettings, setIconSettings ] = React.useState(design.iconSettings);
  const [ iconSave, setIconSave ] = React.useState(design.iconSave);
  const [ iconSaved, setIconSaved ] = React.useState(design.iconSaved);
  const [ mobileLogoUrl, setMobileLogoUrl ] = React.useState(design.mobileLogoUrl);
  const [ tvLogoUrl, setTvLogoUrl ] = React.useState(design.tvLogoUrl);
  const [ mobileLogoBlobId, setMobileLogoBlobId ] = React.useState(null);
  const [ tvLogoBlobId, setTvLogoBlobId ] = React.useState(null);
  const [ lightMode, setLightMode ] = React.useState(design.lightMode);
  const [ logoPlacement, setLogoPlacement ] = React.useState(design.logoPlacement);
  const [defaultFileName, setDefaultFileName] = React.useState(design.logoAltText);
  // const [ logoAltText, setLogoAltText ] = React.useState(design.logoAltText);
  const [ logoError, setLogoError ] = React.useState(false);
  const [ textLocales, setTextLocales ] = React.useState(getTextLocales());
  const [ saveBlob, setSaveBlob ] = React.useState({});
  const [ savedBlob, setSavedBlob ] = React.useState({});
  const [ searchBlob, setSearchBlob ] = React.useState({});
  const [ settingsBlob, setSettingsBlob ] = React.useState({});

  const defaultLocale = {
    locale: design.locale.id,
    lang: design.language
  }

  const logoAltText = textLocales[defaultLocale.locale][defaultLocale.lang]["logoAltText"];

  React.useEffect(() =>{
    onLangInputChange(defaultFileName, "logoAltText")
  },[defaultFileName])
  
  // TV variables
  React.useEffect(() => {
    setIsFormChanged(true);
  }, [tvLogoUrl, themePrimary, themeSecondary, themeActive, themeInactive, 
    themeTabBar, themeBorder, iconSave, iconSaved
  ]);
  
  // Mobile variables
  React.useEffect(() => {
    setIsFormChanged(true);
  }, [lightMode, logoPlacement, mobileLogoUrl, iconSearch, 
    iconSettings, themeMobileTabBar,themeMobileBorder, themeMobileVideoGridBackground,
    themeMobileVideoGridText, logoAltText]);
    
  React.useEffect(() => {
      setIsFormChanged(false);
  }, [])

  const cancel = () => {

    //  TV variables
    setTvLogoUrl(design.tvLogoUrl); 
    setThemePrimary(design.themePrimary); 
    setThemeSecondary(design.themeSecondary);
    setThemeActive(design.themeActive); 
    setThemeInactive(design.themeInactive); 
    setThemeTabBar(design.themeTabBar); 
    setThemeBorder(design.themeBorder);
    setIconSave(design.iconSave); 
    setIconSaved(design.iconSaved);

    //  Mobile Variables
    setLightMode(design.lightMode);
    setLogoPlacement(design.logoPlacement);
    setMobileLogoUrl(design.mobileLogoUrl);
    setIconSearch(design.iconSearch);
    setIconSettings(design.iconSettings);
    setThemeMobileTabBar(design.themeMobileTabBar);
    setThemeMobileBorder(design.themeMobileBorder);
    setThemeMobileVideoGridBackground(design.themeMobileVideoGridBackground);
    setThemeMobileVideoGridText(design.themeMobileVideoGridText);
    setTextLocales(getTextLocales());

    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);



  const commonFields = {
    themePrimary,
    themeSecondary,
    themeActive,
    themeInactive,
    themeTabBar,
    themeMobileTabBar,
    themeBorder,
    themeMobileBorder,
    themeMobileVideoGridBackground,
    themeMobileVideoGridText,
    iconSearch,
    iconSettings,
    iconSave,
    iconSaved,
    lightMode,
    logoPlacement,
    logoAltText,
  };

  const stateDesign = {
    ...commonFields,
    mobileLogoUrl,
    tvLogoUrl,
    iconSearch: searchBlob.url || iconSearch,
    iconSettings: settingsBlob.url || iconSettings,
    iconSave: saveBlob.url || iconSave,
    iconSaved: savedBlob.url || iconSaved,
  };

  const stateForm = {
    ...commonFields,
    mobileLogoBlobId,
    tvLogoBlobId,
    textLocales,
    saveIconBlobId: saveBlob.signedBlobId,
    savedIconBlobId: savedBlob.signedBlobId,
    searchIconBlobId: searchBlob.signedBlobId,
    settingsIconBlobId: settingsBlob.signedBlobId,
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm,
    simulator,
    tab: "General",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  async function handleLogoValidations(file) {
    setLogoError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (simulator.deviceName === "TV" && dimensions.height === 300) {
      return true;
    } else if (simulator.deviceName === "Mobile" && dimensions.height === 132) {
      const alttext = file.name?.split('.')[0];
      setDefaultFileName(alttext);
      return true;
    } else {
      setLogoError("Invalid dimensions");
      return false;
    }
  }

  function handleLogoUpload(response) {
    if (simulator.deviceName === "TV") {
      setTvLogoUrl(response.url);
      setTvLogoBlobId(response.signedBlobId);
    } else {
      setMobileLogoUrl(response.url);
      setMobileLogoBlobId(response.signedBlobId);
    }
  }

  function getTextLocales() {
    return generateLocalesForDesigns(designs, ["logoAltText"])
  }

  function multiText(title, key, charLimit = 0, buttonMargin) {
    const buttonProps = buttonMargin
      ? { style: { marginBottom: buttonMargin } }
      : {};
    return (
      <LanguageText
        onSubmit={setTextLocales}
        data={textLocales}
        property={{ key, charLimit }}
        buttonProps={buttonProps}
        title={title}
      />
    );
  }

  function onLangInputChange(val, key) {
    const textLocaleDuplicate = {...textLocales}
    textLocaleDuplicate[defaultLocale.locale][defaultLocale.lang][key] = val;
    setTextLocales(textLocaleDuplicate);
  }

  const logoUpload =
    <UploadButton
      inputProps={{ accept: 'image/png' }}
      label={`${simulator.deviceName === "TV" ? "CTV" : "Nav Bar"} Logo`}
      required
      requirements={[`Height: ${simulator.deviceName === "TV" ? 300 : 132}px`, 'transparent background', '72 dpi', 'PNG']}
      onUpload={handleLogoUpload}
      validations={handleLogoValidations}
      error={logoError}  
      disabled={disableForm}
    >Upload Image</UploadButton>;

  const primaryColor = <ColorPicker getter={themePrimary} setter={setThemePrimary} label="Theme Color"   disabled={disableForm}/>;
  const secondaryColor = <ColorPicker getter={themeSecondary} setter={setThemeSecondary} label={`${simulator.deviceName === 'TV' ? 'Secondary' : 'Selected'} Color`}   disabled={disableForm}/>;
  const activeColor = <ColorPicker getter={themeActive} setter={setThemeActive} label="Active Color"   disabled={disableForm}/>;
  const inactiveColor = <ColorPicker getter={themeInactive} setter={setThemeInactive} label="Inactive Color"   disabled={disableForm}/>;
  const tabBarColor = <ColorPicker getter={themeTabBar} setter={setThemeTabBar} label="Tab Bar Color"   disabled={disableForm}/>;
  const mobileTabBarColor = <ColorPicker getter={themeMobileTabBar} setter={setThemeMobileTabBar} label="Tab Bar Color"   disabled={disableForm}/>;
  const borderColor = <ColorPicker getter={themeBorder} setter={setThemeBorder} label="Border Color"   disabled={disableForm}/>;
  const mobileBorderColor = <ColorPicker getter={themeMobileBorder} setter={setThemeMobileBorder} label="Border Color"   disabled={disableForm}/>;
  const mobileVideoGridBackgroundColor = <ColorPicker getter={themeMobileVideoGridBackground} setter={setThemeMobileVideoGridBackground} label="Video Grid Background Color" direction="top"   disabled={disableForm}/>;
  const mobileVideoGridTextColor = <ColorPicker getter={themeMobileVideoGridText} setter={setThemeMobileVideoGridText} label="Video Grid Text Color" direction="top"   disabled={disableForm}/>;
  const videoGridNote =
    <HelpText>
      Note: Locking info for locked content will always appear in grey color (hex value C2C2C2).
    </HelpText>;

  const searchIcon = (
    <IconPicker
      getter={iconSearch}
      setter={setIconSearch}
      onUpload={setSearchBlob}
      label="Search Icon"
      textFieldProps={{
        style: {
          maxWidth: "300px",
        },
      }}
    disabled={disableForm}
    />
  );
  const settingsIcon = (
    <IconPicker
      getter={iconSettings}
      setter={setIconSettings}
      onUpload={setSettingsBlob}
      label="Settings Icon"
      textFieldProps={{
        style: {
          maxWidth: "300px",
        },
      }}
    disabled={disableForm}
    />
  );
  const saveIcon = (
    <IconPicker
      getter={iconSave}
      setter={setIconSave}
      onUpload={setSaveBlob}
      label="Save Icon"
      direction={simulator.deviceName === "TV" && "top"}
      textFieldProps={{
        style: {
          maxWidth: "300px",
        },
      }}
      disabled={disableForm}
    />
  );
  const savedIcon = (
    <IconPicker
      getter={iconSaved}
      setter={setIconSaved}
      onUpload={setSavedBlob}
      label="Saved Icon"
      direction={simulator.deviceName === "TV" && "top"}
      textFieldProps={{
        style: {
          maxWidth: "300px",
        },
      }}
      disabled={disableForm}
    />
  );
  const saveHelp =
    <HelpText>
      *Saved menu and save buttons only appear if Saved section is made active in App Creator
    </HelpText>;

  const navBarGroup = <GroupHeader title="Mobile Nav Bar" caption="Apply to all mobile app screens" />;
  const tabBarGroup = <GroupHeader title="Mobile Tab Bar" caption="Apply to all mobile app screens" />;
  const videoGridGroup = <GroupHeader title="Video Grid Layout In Menus" caption="Apply to all menus with Video Grid layout" />;
  const divider = <Divider />;

  const lightModeSelect =
    <BooleanSelect
      value={lightMode}
      onChange={setLightMode}
      onOff={true}
      label="Light Mode"
      disabled={disableForm}
      required
    />;
  const logoPlacementSelect =
    <Select
      value={logoPlacement}
      onChange={setLogoPlacement}
      options={[
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
      ]}
      label="Logo Placement"
      disabled={disableForm}
      required
    />;
  const logoAltTextInput =
    <div style={{display: 'flex'}}>
      <Input
        label="Nav Bar Logo Alt Text"
        value={logoAltText}
        onChange={val => {onLangInputChange(val, "logoAltText")}}
        required
        disabled={isMultiLangOrRegion || disableForm}
      />
      {multiText("Nav Bar Logo Alt Text", "logoAltText", 0, "8px")}
    </div>

  return (
    <Layout {...layoutProps}>
      {simulator.deviceName === "TV"
        ? <>
            {logoUpload}
            {primaryColor}
            {secondaryColor}
            {activeColor}
            {inactiveColor}
            {tabBarColor}
            {borderColor}
            {saveIcon}
            {savedIcon}
            {saveHelp}
          </>
        : <>
            {navBarGroup}
            {primaryColor}
            {lightModeSelect}
            {logoPlacementSelect}
            {logoUpload}
            {logoAltTextInput}
            {searchIcon}
            {settingsIcon}
            {divider}
            {tabBarGroup}
            {mobileTabBarColor}
            {mobileBorderColor}
            {secondaryColor}
            {saveIcon}
            {savedIcon}
            {saveHelp}
            {divider}
            {videoGridGroup}
            {mobileVideoGridBackgroundColor}
            {mobileVideoGridTextColor}
            {videoGridNote}
          </>
      }
    </Layout>
  );
}

export default withDesign(withSimulator(General));
