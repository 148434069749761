import { gql } from "@apollo/client";

export const BANNER_GROUP_FRAGMENT = gql`
  fragment BannerGroupSchema on BannerGroup {
    id
    name
    segmentation
    bannerStores {
      id
      isActive
      appStoreName
    }
  }
`;

export const GET_BANNER_GROUPS = gql`
  query bannerGroups($workspaceId: ID!, $appId: ID!) {
    bannerGroups(workspaceId: $workspaceId, appId: $appId) {
      id
      name
    }
  }
`;

export const GET_BANNER_GROUP = gql`
  query bannerGroup($workspaceId: ID!, $appId: ID!, $id: ID!) {
    bannerGroup(workspaceId: $workspaceId, appId: $appId, id: $id) {
      ...BannerGroupSchema
    }
  }
  ${BANNER_GROUP_FRAGMENT}
`;

export const CREATE_BANNER_GROUP = gql`
  mutation createBannerGroup(
    $workspaceId: ID!
    $appId: ID!
    $bannerGroupFields: BannerGroupInput!
  ) {
    createBannerGroup(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bannerGroupFields: $bannerGroupFields
      }
    ) {
      bannerGroup {
        ...BannerGroupSchema
      }
    }
  }
  ${BANNER_GROUP_FRAGMENT}
`;

export const UPDATE_BANNER_GROUP = gql`
  mutation updateBannerGroup(
    $workspaceId: ID!
    $appId: ID!
    $bannerGroupId: ID!
    $bannerGroupFields: BannerGroupInput!
  ) {
    updateBannerGroup(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bannerGroupId: $bannerGroupId
        bannerGroupFields: $bannerGroupFields
      }
    ) {
      bannerGroup {
        ...BannerGroupSchema
      }
    }
  }
  ${BANNER_GROUP_FRAGMENT}
`;

export const DELETE_BANNER_GROUP = gql`
  mutation deleteBannerGroup(
    $workspaceId: ID!
    $appId: ID!
    $bannerGroupId: ID!
  ) {
    deleteBannerGroup(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bannerGroupId: $bannerGroupId
      }
    ) {
      bannerGroup {
        id
        name
      }
    }
  }
`;
