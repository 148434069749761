import { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux'
import Hubs from './screens/Hubs';
import LoginProvider from './components/containers/Login';
import Root from './screens/Root';
import { ThemeProvider } from '@mui/material/styles';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material';
import theme from 'theme';
import StripeRedirect from 'components/molecules/StripeRedirect';
import { updateRoute } from 'actions/routeHistory';

import './App.css';

// AG Grid
const enterprise = require("@ag-grid-enterprise/core");
enterprise.LicenseManager.setLicenseKey("CompanyName=MAZ Systems Inc.,LicensedApplication=MAZ TV,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-018085,ExpiryDate=16_August_2022_[v2]_MTY2MDYwNDQwMDAwMA==74a95aa5581c3fc38e709f71c3a1ed31");

function App() {
  const location = useLocation();
  const dispatch = useDispatch()

  useEffect(()=>{
    if(location!==null){
      const pathname = location.pathname;
      dispatch(updateRoute(pathname));
    }
  },[location])

  return (
    <StyledEngineProvider injectFirst> 
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <LoginProvider>
              <Switch>
                <Route exact path="/"><Root /></Route>
                <Route exact path="/redirect/stripe"><StripeRedirect /></Route>
                <Route path={`/:workspaceId/:appId`}><Hubs /></Route>
                <Redirect to="/" />
              </Switch>
            </LoginProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
