import makeStyles from '@mui/styles/makeStyles';
import React from "react";

import { Card } from "@mui/material";
import pbm_sand from "images/pbm_sand.png";
import publogo from "images/default-publisher-logo.svg";
import {Footer, Content, Header, LoginConfScreen, OnboardingScreen, Epg} from './Screens';
import Input from "components/atoms/Input/BaseInput";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  launchscreen: props => ({
    position: "relative",
    backgroundSize: "cover",
    minHeight: "35em",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: props.themeMobileLaunchBackground,
  }),
  loginConfiguration: props => ({
    position: "relative",
    backgroundSize: "cover",
    minHeight: "35em",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  pwm: {
    height: "20px",
    position: "absolute",
    bottom: "35px",
  },
  notes: {
    fontSize: "11px",
    padding: "0.35em 0",
  },
}));

function ContentBackground(props){
  const { simulator } = props;
  const { design, tab } = simulator;
  const classes = useStyles(design);

  let backgroundObject = {};
  if (tab === "Saved") {
    backgroundObject = design.savedBackground;
  } else if (tab === "Onboarding") {
    backgroundObject = design.onboardingScreenBackground;
  }
  const { assetUrl, format, primaryColor, secondaryColor} = backgroundObject;

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  const back = (tab) => {
    if (tab === "General") {
      return { backgroundColor: "#191919" };
    }

    if (format === "single") {
      return { backgroundColor: primaryColor };
    }

    if (format === "basic_black") {
      return { backgroundColor: "#000000" };
    }

    let addcss = {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center"
    };

    if (format === "image") {
      addcss = {
        ...addcss,
        backgroundImage: `url("${assetUrl}")`,
      }
    }
    else if (format === "gradient") {
      addcss = {
        ...addcss,
        backgroundImage: `linear-gradient(135deg, ${hex2rgba(primaryColor, 0.7)} 0%, ${hex2rgba(secondaryColor, 0.18)} 100%`}
    }
    return addcss
  }

  return(
    <div style={{
      ...back(tab),
      position: 'relative',
      minHeight: 600,
    }}>
      {format === "video"
        ? <video
            autoPlay muted loop src={assetUrl}
            style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
          ></video>
        : null}
      {format === "image"
        ? <div
            style={{
              position: 'absolute', width: '100%', height: '100%', objectFit: 'cover',
              backgroundImage: `linear-gradient(135deg, ${hex2rgba(primaryColor, 0.7)} 0%, ${hex2rgba(secondaryColor, 0.18)} 100%`,
            }}
          />
        : null}
      {props.children}
    </div>
  )
}

function MobileSimulator(props) {
  const { simulator } = props;
  const { design, tab } = simulator;
  const classes = useStyles(design);

  return (
    <>
      <Card style={{ marginTop: "8px", width: "373px" }}>
        {["General", "Saved"].includes(tab) ? (
          <>
            <Header {...props} />
            <Content {...props} />
            <Footer {...props} />
          </>
        ) : null}
        {["Launch Screen"].includes(tab) ? (
          <div className={classes.launchscreen}>
            <img
              style={{ maxHeight: "72px" }}
              src={design.mobileLaunchLogoUrl ? design.mobileLaunchLogoUrl : publogo}
              alt="Launch"
            />
            <img className={classes.pwm} src={pbm_sand} alt="Powered by MAZ" />
          </div>
        ) : null}
        {["Login Configuration"].includes(tab) ? (
          <>
            <Header {...props} />
            <LoginConfScreen simulator={true} {...props}/>
          </>

        ) : null}
        {["Onboarding"].includes(tab) && (
          <ContentBackground {...props}>
            <OnboardingScreen {...props} />
          </ContentBackground>
        )}
        {["Guide"].includes(tab) && (
          <ContentBackground {...props}>
            <Epg {...props} />
          </ContentBackground>
        )}
      </Card>
      <div className={classes.notes}>
        Note: *some features may look different in Android
      </div>
    </>
  );
}

export default MobileSimulator;
