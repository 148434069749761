import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useParams, useLocation } from "react-router-dom";
import urlJoin from "url-join";

import {
  GET_CURRENT_APP_PROPS,
  GET_BANNER_GROUP,
  GET_BANNERS,
  UPDATE_BANNER,
  DELETE_BANNER,
  REARRANGE_BANNERS,
} from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import DataGrid from "components/atoms/DataGrid";
import ContentLayout from "screens/Layout/ContentLayout";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 400,
    cellRenderer: "titleCellRenderer",
    rowDrag: true,
    sortable: true,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    flex: 1,
  },
];

function Banner(props) {
  const { parentId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const bannerGroup = useQuery(GET_BANNER_GROUP, {
    variables: { ...appProps, id: parentId },
  });
  const { data } = useQuery(GET_BANNERS, {
    variables: { ...appProps, bannerGroupId: parentId },
  });
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);

  const onEdit = (banner) => {
    history.push(urlJoin(pathname, `edit/${banner.id}`));
  };

  const onDelete = (banner) => {
    setDeleteConfirmation(banner);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(null);
  };

  const toggleActive = (banner, value, event) => {
    updateBanner({
      variables: {
        ...appProps,
        bannerId: banner.id,
        bannerGroupId: parentId,
        bannerFields: { name: banner.name, isActive: value },
      },
    });
  };

  const [updateBanner] = useMutation(UPDATE_BANNER, {
    update(cache, { data: { updateBanner } }) {
      cache.modify({
        fields: {
          banners(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateBanner.banner.id === readField("id", ref)
                ? { ...ref, ...updateBanner.banner }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const [deleteBanner] = useMutation(DELETE_BANNER, {
    update(cache, { data: { deleteBanner } }) {
      cache.modify({
        fields: {
          banners(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteBanner.banner.id !== readField("id", ref)
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
    },
    onError(data) {
      console.log(data);
    },
  });

  const deleteBannerApi = (id) => {
    closeDeleteConfirmation();
    deleteBanner({
      variables: {
        ...appProps,
        bannerGroupId: parentId,
        bannerId: id,
      },
    });
  };

  const [rearrangeBanners] = useMutation(REARRANGE_BANNERS, {
    onCompleted(data) {
      props.savedSnack.launch("Saved");
    },
    onError(data) {
      console.log(data);
    },
  });

  const rearrangeBannersApi = (ids) => {
    const existingBanners = client.readQuery({
      query: GET_BANNERS,
      variables: { ...appProps, bannerGroupId: parentId },
    });

    let newBanners = [];
    ids.forEach((f, i) => {
      newBanners.push({
        ...existingBanners.banners.find((ef) => ef.id === f),
        sortOrder: i,
      });
    });

    client.writeQuery({
      query: GET_BANNERS,
      variables: { ...appProps, bannerGroupId: parentId },
      data: { banners: newBanners },
    });

    rearrangeBanners({
      variables: {
        ...appProps,
        bannerGroupId: parentId,
        bannerIds: ids,
      },
    });
  };

  const onSort = (params) => {
    rearrangeBannersApi(
      params.api.rowModel.rowsToDisplay.map((o) => o.data.id)
    );
  };

  return (
    <ContentLayout
      title="Banner"
      fab={{ onClick: () => history.push(urlJoin(pathname, "new")) }}
    >
      <FormLayout
        breadcrumbs={[
          {
            name: "Banner Groups",
            link: `/${appProps.workspaceId}/${appProps.appId}/monetization/ads/banners`,
          },
          { name: bannerGroup?.data?.bannerGroup?.name },
        ]}
      >
        <DataGrid
          rowData={
            data?.banners
              ? data.banners.map((b) => ({
                  ...b,
                  title: b.name,
                  actions: {
                    toggle: {
                      getter: b.isActive,
                      setter: (value, event) => toggleActive(b, value, event),
                    },
                    onEdit,
                    onDelete,
                  },
                }))
              : []
          }
          columnDefs={columns}
          accentedSort={true}
          rowDragManaged={true}
          animateRows={true}
          onRowDragEnd={onSort}
          immutableData={true}
          getRowNodeId={(data) => data.id}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => {
            deleteBannerApi(deleteConfirmation.id);
          }}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default Banner;
