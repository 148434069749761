import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";

import { GET_CURRENT_APP_PROPS, ME, DELETE_APP, GET_CURRENT_APP, GET_APP, UPDATE_APP } from "api";
import { workspace } from "cache";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import NewAppModal from "components/molecules/Modal/NewApp";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import DefaultAppIcon from "components/atoms/DefaultAppIcon";
import ContentLayout from "screens/Layout/ContentLayout";
import { withRedirect } from "components/containers/Redirect";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import { isSuperUser } from "helpers/platformUtils";

const columns = [
  { field: "name", headerName: "Name", width: 512, sortable: true, sort: "asc" },
  { field: "action", headerName: "Action", cellRenderer: "actionCellRenderer", flex: 1, minWidth: 150 },
];

function Locales(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { pathname } = useLocation();
  const params = useParams()

  const { data } = useQuery(GET_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      appId: params.appId,
    }
  });
  const { data: meData } = useQuery(ME);
  const user = meData?.me;
  const isSuperAdmin = isSuperUser(user);
  const appDetails = data?.app;

  const [ editing, setEditing ] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  function onEdit(locale, e) {
    e.stopPropagation()
    props.redirect.set(`${pathname}/edit/${locale?.id}`);
  }

  function onDelete(locale, e) {
    e.stopPropagation()
    setDeleteConfirmation(locale);
  }

  const [ updateApp, {loading: updatingApp} ] = useMutation(UPDATE_APP, {
    update(cache, { data: { updateApp } }) {
      cache.modify({
        id: cache.identify(updateApp.app),
        fields: {
          locales(cachedLocale) {
            return (updateApp.app.locales);
          }
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch();
    }
  });

  function deleteLocaleApi(locale) {
    updateApp({
      variables: {
        workspaceId: appProps.workspaceId,
        id: appDetails?.id,
        appFields: {
          localesAttributes: [{..._.omit(locale, "__typename"), _destroy: "1"}],
        },
      },
    });
    closeDeleteConfirmation();
  }
  
  function handleSelect(params) {
    const selectedLocale = params.api.getSelectedNodes()[0].data;
    props.redirect.set(`${pathname}/${selectedLocale?.id}/languages`);
  }

  return (
    <ContentLayout title="Locales" fab={isSuperAdmin ? { onClick: () => props.redirect.set(`${pathname}/new`) }: null}>
      <FormLayout
        breadcrumbs={[
          { name: "Brands", link: `${pathname.split('/brands')[0]}/brands` },
          { name: appDetails?.name}
        ]}
      >
        <DataGrid
          rowData={
            appDetails?.locales
              ? appDetails.locales.map((l) => ({
                  ...l,
                  actions: {
                    onEdit: onEdit,
                    onDelete: appDetails.locales.length < 2 ? null : onDelete
                  }
                }))
              : []
          }
          columnDefs={columns}
          accentedSort={true}
          onRowSelected={handleSelect}
          rowSelection={"single"}
        />

        <NewAppModal
          open={Boolean(editing)}
          app={editing}
          onClose={() => setEditing(null)}
          savedSnack={props.savedSnack}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => deleteLocaleApi(deleteConfirmation)}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(Locales);
