import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

export default function Help(props) {
  const classes = useStyles();
  const { children, typographyProps, ...otherProps } = props;

  return (
    <Box className={classes.container} bgcolor="grey.300" borderRadius={1} {...otherProps}>
      <Typography variant="caption" {...typographyProps}>
        {children}
      </Typography>
    </Box>
  );
}
