import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import _ from 'underscore';
import { GET_CURRENT_APP_PROPS, UPDATE_DESIGN } from "api";
import ContentLayout from "screens/Layout/ContentLayout";
import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import GroupHeader from 'components/atoms/Text/GroupHeader';

import Input from 'components/atoms/Input/BaseInput';
import UploadButton, { ImagePreview } from 'components/molecules/UploadGroup';
import { getHeightAndWidthFromDataUrl } from 'helpers/fileUtils';

function Form(props) {
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { design, screen, redirect } = props;

  const [ title, setTitle ] = React.useState(screen.title || "");
  const [ summary, setSummary ] = React.useState(screen.summary || "");
  const [ logoAltTag, setLogoAltTag ] = React.useState(screen.logoAltTag || "");
  const [ logoUrl, setLogoUrl ] = React.useState(screen.logoUrl);
  const [ logoBlobId, setLogoBlobId ] = React.useState(null);
  const [ logoError, setLogoError ] = React.useState(false);
  const [isFormChanged, setIsFormChanged] = React.useState(false);

  React.useEffect(() => {
    setIsFormChanged(true);
  },[logoAltTag, title, summary])

  React.useEffect(() => {
    setIsFormChanged(false);
  },[])

  const commonScreenFields = {
    title,
    summary,
    logoAltTag,
  };

  const stateForm = {
    onboardingScreensAttributes: [{
      id: screen.id,
      ...commonScreenFields,
      logoBlobId,
    }],
  };

  const listPath = `/${appProps.workspaceId}/${appProps.appId}/monetization/onboarding`;

  async function handleLogoValidations(file) {
    setLogoError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (dimensions.height === 300) {
      setLogoAltTag(file.name?.split('.')[0])
      return true;
    } else {
      setLogoError("Invalid dimensions");
      return false;
    }
  }

  function handleLogoUpload(response) {
    setLogoUrl(response.url);
    setLogoBlobId(response.signedBlobId);
  }

  const titleInput =
    <Input
      label="Title"
      value={title}
      onChange={setTitle}
      inputProps={{ maxLength: 25 }}
      helperText={`${25 - title.length} - character limit`}
      autoFocus
    />;

  const summaryInput =
    <Input
      label="Subtitle"
      value={summary}
      onChange={setSummary}
      inputProps={{ maxLength: 50 }}
      helperText={`${50 - summary.length} - character limit`}
    />;

  const logoAltTagInput =
    <Input
      label="Image Alt Text"
      value={logoAltTag}
      onChange={setLogoAltTag}
    />;

  const logoUpload =
    <UploadButton
      inputProps={{ accept: 'image/png' }}
      label="Image"
      requirements={[`Height: 300px`, 'transparent background', '72 dpi', 'PNG']}
      onUpload={handleLogoUpload}
      validations={handleLogoValidations}
      error={logoError}
      fileRender={() => renderFile(logoUrl, "Logo", setLogoUrl, setLogoBlobId)}
    >Upload Image</UploadButton>;

  const renderFile = (url, alt, urlSetter, blobSetter) => (
    url
      ? <ImagePreview {...{ url, alt, urlSetter, blobSetter }} />
      : null
  );

  const nonNullName = title || "Untitled Onboarding Screen";
  const screenGroup = <GroupHeader title="Screen" />;

  const [ updateDesign, { loading: isUpdating } ] = useMutation(UPDATE_DESIGN, {
    variables: {
      ...appProps,
      id: design?.id,
      designFields: stateForm,
    },
    onCompleted(data) {
      history.push(listPath);
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const cancel = () => {
    setIsFormChanged(false);
    history.push(listPath)
  };

  const isLoading = isUpdating;

  return (
    <ContentLayout title={nonNullName}>
      <FormLayout
        submit={updateDesign}
        cancel={cancel}
        isChanged={isFormChanged}
        betterForm={true} 
        breadcrumbs={[
          { name: "Onboarding", link: listPath },
          { name: nonNullName }
        ]}
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <PaperGroup>
          {screenGroup}
          {titleInput}
          {summaryInput}
          {logoUpload}
          {logoAltTagInput}
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default Form;
