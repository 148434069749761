import React from "react";

import Input from "components/atoms/Input/BaseInput";
import PasswordInput from "components/atoms/Input/Password";
import { SwitchGroup } from "components/atoms/Input/Switch";
import { INTEGRATION_OAUTH, OAUTH_PASSWORD } from "../constants";

export default function Zype(props) {
  const { integration, setForm, setIsFormChanged } = props;
  const grant = integration?.oauthGrants?.find((g) => g.type === OAUTH_PASSWORD) || {};

  const [ sso, setSso ] = React.useState(integration?.id ? integration.sso : true);
  const [ drm, setDrm ] = React.useState(integration?.id ? integration.drm : false);
  const [ clientId, setClientId ] = React.useState(integration.metadata?.client_id || "");
  const [ clientSecret, setClientSecret ] = React.useState(integration.metadata?.client_secret || "");
  const [ apiKey, setApiKey ] = React.useState(integration.metadata?.api_key || "");
  const [ staging, setStaging ] = React.useState(integration.metadata?.template === "ZypeStaging" || false);
  const [ signingKey, setSigningKey ] = React.useState(integration.metadata?.signing_key || "");

  const [ drmUsername, setDrmUsername ] = React.useState(integration.metadata?.drm_username);
  const [ drmPassword, setDrmPassword ] = React.useState(null);

  React.useEffect(() => {
    setForm({
      type: INTEGRATION_OAUTH,
      drm,
      sso,
      clientId,
      clientSecret,
      apiKey,
      signingKey,
      template: staging ? "ZypeStaging" : "Zype",
      drmUsername,
      drmPassword,
    });
    setIsFormChanged(true);
  }, [ clientId, clientSecret, apiKey, sso, drm, staging, signingKey, drmUsername, drmPassword ]);

  React.useEffect(() => {
    setIsFormChanged(false);
  },[]);

  return (
    <>
      <SwitchGroup
        label="Staging Environment"
        checked={staging}
        onChange={setStaging}
      />

      <SwitchGroup
        label="URL Signing"
        checked={drm}
        onChange={setDrm}
      />

      <SwitchGroup
        label="SSO"
        checked={sso}
        onChange={setSso}
      />

      {(drm || sso) && (
        <Input
          label="Admin API Key"
          value={apiKey}
          onChange={setApiKey}
          required
        />
      )}

      {drm && (
        <Input
          label="URL Signing Key"
          value={signingKey}
          onChange={setSigningKey}
          required
        />
      )}

      {(drm || sso) && (
        <>
          <Input
            label="OAuth Client ID"
            value={clientId}
            onChange={setClientId}
            required
          />

          <Input
            label="OAuth Client Secret"
            value={clientSecret}
            onChange={setClientSecret}
            required
          />
        </>
      )}

      {drm && (
        <>
          <Input
            label="DRM Username"
            value={drmUsername}
            onChange={setDrmUsername}
            required
          />

          <PasswordInput
            label="DRM Password"
            defaultValue={integration.metadata?.drm_password ? "*************" : ""}
            onChange={setDrmPassword}
            required
          />
        </>
      )}
    </>
  );
}
