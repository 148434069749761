import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import clsx from 'clsx';
import { connect } from 'react-redux';
import validator from 'validator';

import * as actions from 'actions';
import EmailInput from 'components/atoms/Input/Email';
import PasswordInput from 'components/atoms/Input/Password';
import SubmitButton from 'components/atoms/Button/Submit';
import Copyright from 'components/molecules/Copyright';
import Error from "components/atoms/Error";
import { useHistory, useLocation } from 'react-router-dom';
import { withSavedSnack } from 'components/molecules/Alerts/Saved';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    overflowY: "auto",
    height: "100vh",
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// step 0 => Login
// step 1 => Register
// step 2 => Forgot Password

function Login(props) {
  const classes = useStyles();
  const [ step, setStep ] = React.useState(0);
  const [ email, setEmail ] = React.useState("");
  const [ password, setPassword ] = React.useState("");
  const [ error, setError ] = React.useState();
  const [ confirmPassword, setConfirmPassword ] = React.useState("");

  const location = useLocation();
  const history = useHistory();

  let resetToken;
  if (location.search && location.search.includes("reset_password_token")) {
    resetToken = location.search.split('=')[1];
  }

  React.useEffect(() => {
    if (resetToken) {
      setStep(2);
    }
  }, [resetToken])

  const toggleLogin = (event) => {
    event.preventDefault();
    setStep(0);
  };
  const togglePassword = (event) => {
    event.preventDefault();
    setStep(1);
  };

  const submit = (event) => {
    event.preventDefault();
    setError(null);
    step === 0 && login();
    step === 1 && fPassword();
    step === 2 && resetPassword();
  };

  const login = async () => {
    if (email && password) {
      if (!validateEmailAndPassword(email, password)) return;
      
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({ user: { email: email, password: password } })
      };

      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/users/sign_in`, requestOptions);
      const data = await response.json();

      if (data.id) {
        const bearerToken = response.headers.get("Authorization");
        if (bearerToken) {
          props.login(bearerToken.split("Bearer ")[1]);
        }
      } else if (data.error) {
        setError(data.error);
      }
    } else {
      showMessage("Please fill all the required fields.", "error");
    }
  }

  const fPassword = async () => {
    if (email) {
      if (!validateEmailAndPassword(email, "", true)) return;
      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          body: JSON.stringify({ user: { email: email } })
        };

        const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/users/password`, requestOptions);
        const data = await response.json();
        showMessage(`An email has been sent to ${email} with further instructions`);
      } catch(e) {
        showMessage("Some error occurred", "error");
      }
    } else {
      showMessage("Please enter email", "error");
    }
  }

  const resetPassword = async() => {
    if (password.trim().length < 6) {
      showMessage("Password length must be greator than 5 character", "error");
    }
    else if (password && password === confirmPassword) {
      try {
        const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          body: JSON.stringify({ user: { password: password, reset_password_token: resetToken, password_confirmation: confirmPassword } })
        };
        const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/users/password`, requestOptions);
        if (response.status === 204) {
          resetPasswordSuccess();
        } else { throw response; }
      } catch(e) {
        showMessage("Some error occurred", "error");
      }
    } else {
      if (!password || !confirmPassword) showMessage("Please enter password", "error");
      if (password !== confirmPassword) showMessage("Password does not match", "error");
    }
  }

  const resetPasswordSuccess = () => {
    history.push('/');
    setStep(0);
    showMessage("Your password has been reset successfully");
  }

  const showMessage = (message, type="") => {
    props.savedSnack.launch(message, {type: type, anchorOrigin:{
      vertical: 'top',
      horizontal: 'center',
    }});
  }

  const validateEmailAndPassword = (email, password, emailOnly=false) => {
    if (!validator.isEmail(email)) {
      showMessage("Please enter correct email.", "error");
      return false
    }
    if (!emailOnly && password.trim().length < 6) {
      showMessage("Password must be atleat 6 character long.", "error");
      return false
    }
    return true;
  }

  const isLogin = step === 0;
  const isFPassword = step === 1;
  const isResetPassword = step === 2;

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {isLogin
            ? `Sign in`
            : `Reset password`}
        </Typography>
        <form className={clsx(classes.form)} onSubmit={submit} noValidate>
          {error && <Error>{error}</Error>}
          {isLogin
            ? <>
                <EmailInput onChange={setEmail} required autoFocus />
                <PasswordInput key={step} onChange={setPassword} required />
              </>
            : isFPassword
            ? <EmailInput onChange={setEmail} required autoFocus />
            : isResetPassword
            ? <>
                <PasswordInput key={step} onChange={setPassword} label="New password" helperText="" required />
                <PasswordInput onChange={setConfirmPassword} label="Confirm new password" helperText="" required />
              </>
            :  null         
          }

          <SubmitButton className={classes.submit} style={(isFPassword || isResetPassword) ? {width: '100%'} : {}}>
            {isLogin
              ? `Sign In`
              : isResetPassword
              ? `Reset password`
              : `Send reset password email`}
          </SubmitButton>

          <Grid container justifyContent={isLogin ? "flex-end" : null}>
            {isLogin && (
              <Grid item xs>
                <Link href="#" onClick={togglePassword} variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            )}
            <Grid item>
              <Link href="#" onClick={toggleLogin} variant="body2">
                {isLogin ? null : `Already have an account? Sign in`}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Box mt={8} mb={2}>
        <Copyright />
      </Box>
    </div>
  );
}

export default connect(null, actions)(withSavedSnack(Login));
