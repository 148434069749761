import React from 'react';
import iPhonePortrait from '../../../images/devicePreview/iphone.jpeg';
import iPhoneLandscape from '../../../images/devicePreview/iphone-landscape.jpeg';
import homeTV from '../../../images/devicePreview/home-tv.jpeg';
import iPadPortrait from '../../../images/devicePreview/ipad.jpeg';
import iPadLandscape from '../../../images/devicePreview/ipad-landscape.jpeg';
import Base from './Base';
import { useTheme } from '@mui/material';
import Select from 'components/atoms/Input/Select';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = (deviceStyle) => makeStyles((theme) => ({
    deviceContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: '1 1 auto'
    },
    device: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        height: '100%',
        width: '100%',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        ...deviceStyle
    },
    content: {
        display: 'block',
        width: '100%',
        height: '100%',
    },
    modal: {
        '& h5': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '176px'
        }
    }
}))

const DEVICES_MOBILE = {
    iPhone: 'iPhone Portrait',
    iPhoneLandscape: 'iPhone Landscape',
    iPad: 'iPad Portrait',
    iPadLandscape: 'iPad Landscape',
}

const DEVICES_OTT = {
    ott: 'OTT'
}

const config = {
    iPad: { 
        image: iPadPortrait, 
        style: {width: '333px', height: '471px', padding: '43px 23px'},
    },
    iPadLandscape: { 
        image: iPadLandscape,
        style: {width: '545px', height: '395px', padding: '31px 51px 31px 48px'} 
    },
    iPhone: { 
        image: iPhonePortrait, 
        style: {width: '234px', height: '471px', padding: '57px 15px'} 
    },
    iPhoneLandscape: { 
        image: iPhoneLandscape, 
        style: {width: '545px', height: '270px', padding: '18px 65px'} 
    },
    ott: { 
        image: homeTV, 
        style: {padding: '17px 36px 57px'}
    }
}

/**
 * 
 * @param {Object} props 
 * @param {String} props.title - Title to show on Modal
 * @param {Boolean} props.ottOnly - Show only ott preview
 * @param {Boolean} props.mobileOnly - show only mobile preview
 * @param {Boolean} props.setDevice - Function to get which device is selected in modal.
 */
function PreviewDevices(props) {
    const theme = useTheme();
    const {title, children, ottOnly, mobileOnly, setDevice: setDeviceParent, ...otherProps} = props;
    const DEVICES = mobileOnly ? DEVICES_MOBILE : ottOnly ? DEVICES_OTT : { ...DEVICES_MOBILE, ...DEVICES_OTT };
    const devicesList = Object.keys(DEVICES);
    
    const [device, setDevice] = React.useState(devicesList[0]);
    const [deviceStyle, setDeviceStyle] = React.useState({});
    
    const classes = useStyles(deviceStyle)();

    React.useEffect(() => {
        setDeviceStyle(config[device].style)
        setDeviceParent && setDeviceParent(device);
    }, [device])

    return (
        <Base
            title={title}
            titleLeft={() => (
                <CloseIcon onClick={props.onClose} style={{fontSize: '35px', cursor: 'pointer'}}/>
            )}
            titleRight={() => (
                <Select
                    options={devicesList.map(device => ({label: DEVICES[device], value: device}))}
                    value={device}
                    onChange={setDevice}
                    style={{width: '200px'}}
                />
            )}
            dimensions={{
                width: theme.spacing(105),
                height: theme.spacing(75),
            }}
            className={classes.modal}
            {...otherProps}
        >
            <div className={clsx([classes.deviceContainer])}>
                <div className={clsx([classes.device])} 
                    key={device}
                    style={{ backgroundImage:`url(${config[device].image})`}}
                >
                    <div className={classes.content}>
                        {children}
                    </div>
                </div>
            </div>
        </Base>
    )
}

export default PreviewDevices
