import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import List from "./List";

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Contents(props) {
  const { path } = useRouteMatch();
  const { savedSnack, category, defaultRoute, isCustom } = props;

  const commonProps = { savedSnack };
  return (
    <Switch>
      <Route path={`${path}/:bundleType/:collectionId?`}>
        <List {...commonProps} category={category} isCustom={isCustom}/>
      </Route>
      <Redirect to={`${path}/${defaultRoute}`} />
    </Switch>
  );
}

export default withSavedSnack(Contents);
