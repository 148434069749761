import React from 'react';

import { withDesign } from './Design';
import ColorPicker from 'components/atoms/Input/ColorPicker';
import Select, { BooleanSelect } from 'components/atoms/Input/Select';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import Input from 'components/atoms/Input/BaseInput';
import UploadButton from 'components/molecules/UploadGroup';
import { getHeightAndWidthFromDataUrl } from 'helpers/fileUtils';
import Layout from './Layout';
import { generateLocalesForDesigns } from 'helpers/langUtils';
import LanguageText from 'components/molecules/LanguageText';
import BackgroundGroup from 'components/molecules/BackgroundGroup';
import _ from 'lodash';

function LoginConfiguration(props) {
  const { simulator, designs, isMultiLangOrRegion } = props;
  const design = simulator.design;
  const initialBackground = {
    ...design.loginBackground,
    // if format is basic black, convert it into single
    format: design.loginBackground.format === "basic_black" ? 'single' : design.loginBackground.format
  }

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [ loginHeaderImageBlobId, setLoginHeaderImageBlobId ] = React.useState(null);
  const [ logoError, setLogoError ] = React.useState(false);
  const [ loginHeaderImageUrl, setLoginHeaderImageUrl ] = React.useState(design.loginHeaderImageUrl);

  const [loginButtonColor, setLoginButtonColor] = React.useState(design.loginButtonColor)
  const [loginHighlightColor, setLoginHighlightColor] = React.useState(design.loginHighlightColor)
  const [loginBorderColor, setLoginBorderColor] = React.useState(design.loginBorderColor)
  const [loginHeaderColor, setLoginHeaderColor] = React.useState(design.loginHeaderColor)
  const [loginButtonLabelColor, setLoginButtonLabelColor] = React.useState(design.loginButtonLabelColor)
  const [loginTextButtonColor, setLoginTextButtonColor] = React.useState(design.loginTextButtonColor)
  const [loginBackground, setLoginBackground] = React.useState(initialBackground)
  const [defaultFileName, setDefaultFileName] = React.useState(design.loginHeaderImageAltText);

  const [ errorTermUrl, setErrorTermUrl ] = React.useState(null);
  const [ errorPrivacyUrl, setErrorPrivacyUrl ] = React.useState(null);
  const [ errorLoginHeaderText, setErrorLoginHeaderText ] = React.useState(null)
  const [ errorLoginSigninText, setErrorLoginSigninText ] = React.useState(null)
  const [ errorLoginRegisterText, setErrorLoginRegisterText ] = React.useState(null)
  const [ errorLoginExistingSubscribersHeading, setErrorLoginExistingSubscribersHeading ] = React.useState(null)
  const [ errorLoginRestorePurchasesHeading, setErrorLoginRestorePurchasesHeading ] = React.useState(null)
  const [ errorLoginButtonText, setErrorLoginButtonText ] = React.useState(null)
  const [ errorLoginNoAccountText, setErrorLoginNoAccountText ] = React.useState(null)
  const [ errorLoginAccountExistsText, setErrorLoginAccountExistsText ] = React.useState(null)
  const [ textLocales, setTextLocales ] = React.useState(getTextLocales());
  const defaultLocale = {
    locale: design.locale.id,
    lang: design.language
  }

  const loginHeaderImageAltText = textLocales[defaultLocale.locale][defaultLocale.lang]["loginHeaderImageAltText"];
  const loginHeaderText = textLocales[defaultLocale.locale][defaultLocale.lang]["loginHeaderText"];
  const loginSigninText = textLocales[defaultLocale.locale][defaultLocale.lang]["loginSigninText"]
  const loginRegisterText = textLocales[defaultLocale.locale][defaultLocale.lang]["loginRegisterText"]
  const loginExistingSubscribersHeading = textLocales[defaultLocale.locale][defaultLocale.lang]["loginExistingSubscribersHeading"]
  const loginRestorePurchasesHeading = textLocales[defaultLocale.locale][defaultLocale.lang]["loginRestorePurchasesHeading"]
  const loginPrivacyUrl = textLocales[defaultLocale.locale][defaultLocale.lang]["loginPrivacyUrl"]
  const loginTermsUrl = textLocales[defaultLocale.locale][defaultLocale.lang]["loginTermsUrl"]
  const loginButtonText = textLocales[defaultLocale.locale][defaultLocale.lang]["loginButtonText"]
  const loginNoAccountText = textLocales[defaultLocale.locale][defaultLocale.lang]["loginNoAccountText"]
  const loginAccountExistsText = textLocales[defaultLocale.locale][defaultLocale.lang]["loginAccountExistsText"]


  React.useEffect(() =>{
    onLangInputChange(defaultFileName, "loginHeaderImageAltText")
  },[defaultFileName]);

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [textLocales,loginHeaderImageUrl,loginButtonColor,loginHighlightColor,loginBorderColor,
      loginHeaderColor,loginButtonLabelColor,loginTextButtonColor, loginBackground]);

  React.useEffect(() => {
    setTimeout(()=>setIsFormChanged(false),0);
  }, [])

  const cancel = () => {

    setLoginHeaderImageUrl(design.loginHeaderImageUrl);
    setLoginButtonColor(design.loginButtonColor);
    setLoginHighlightColor(design.loginHighlightColor);
    setLoginBorderColor(design.loginBorderColor);
    setLoginHeaderColor(design.loginHeaderColor);
    setLoginButtonLabelColor(design.loginButtonLabelColor);
    setLoginTextButtonColor(design.loginTextButtonColor);
    setLoginBackground(design.loginBackground);

    setTextLocales(getTextLocales());
    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const commonFields = {
    loginHeaderImageAltText,
    loginHeaderText,
    loginSigninText,
    loginRegisterText,
    loginExistingSubscribersHeading,
    loginRestorePurchasesHeading,
    loginButtonText,
    loginPrivacyUrl,
    loginTermsUrl,
    loginButtonColor,
    loginHighlightColor,
    loginBorderColor,
    loginHeaderColor,
    loginButtonLabelColor,
    loginTextButtonColor,
    loginNoAccountText,
    loginAccountExistsText,
  };

  const stateDesign = {
    ...commonFields,
    loginHeaderImageUrl,
    loginBackground: _.omit(loginBackground, "assetBlobId"),
  };
  const stateForm = {
    ...commonFields,
    loginHeaderImageBlobId,
    textLocales,
    loginBackgroundAttributes: _.omit(loginBackground, "assetUrl", "__typename"),
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm,
    simulator,
    tab: "Login Configuration",
    headerTitle: "Registration & Login",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  function getTextLocales() {
    return generateLocalesForDesigns(designs, ["loginHeaderImageAltText", "loginHeaderText", "loginSigninText", "loginRegisterText", "loginExistingSubscribersHeading", "loginRestorePurchasesHeading", "loginPrivacyUrl", "loginTermsUrl", "loginButtonText", "loginNoAccountText", "loginAccountExistsText"])
  }

  function multiText(title, key, charLimit = 0, buttonMargin) {
    const buttonProps = buttonMargin
      ? { style: { marginBottom: buttonMargin } }
      : {};
    return (
      <LanguageText
        onSubmit={setTextLocales}
        data={textLocales}
        property={{ key, charLimit }}
        buttonProps={buttonProps}
        title={title}
      />
    );
  }

  function onLangInputChange(val, key) {
    const textLocaleDuplicate = {...textLocales}
    textLocaleDuplicate[defaultLocale.locale][defaultLocale.lang][key] = val;
    setTextLocales(textLocaleDuplicate);
  }

  function handleHeaderImageUpload(response) {
    setLoginHeaderImageUrl(response.url);
    setLoginHeaderImageBlobId(response.signedBlobId);
  }

  async function handleHeaderImageValidations(file) {
    setLogoError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (dimensions.height === 300) {
      const alttext = file.name?.split('.')[0];
      setDefaultFileName(alttext);
      return true;
    } else {
      setLogoError("Invalid dimensions");
      return false;
    }
  }

  const setMobileBackground = (color) => {
    setLoginBackground(background => ({
      ...background,
      primaryColor: color
    }))
  }
  const backgroundColor = loginBackground.primaryColor;

  const loginButtonColorPicker = <ColorPicker getter={loginButtonColor} setter={setLoginButtonColor} label="Button Color" disabled={disableForm} required/>;
  const loginHighlightColorPicker = <ColorPicker getter={loginHighlightColor} setter={setLoginHighlightColor} label="Highlight Color" disabled={disableForm} required/>;
  const loginBorderColorPicker = <ColorPicker getter={loginBorderColor} setter={setLoginBorderColor} label="Border Color" disabled={disableForm} required/>;
  const loginHeaderColorPicker = <ColorPicker getter={loginHeaderColor} setter={setLoginHeaderColor} label="Header Color" disabled={disableForm} required/>;
  const loginButtonLabelColorPicker = <ColorPicker getter={loginButtonLabelColor} setter={setLoginButtonLabelColor} label="Button Label Color" disabled={disableForm} required/>;
  const loginTextButtonColorPicker = <ColorPicker getter={loginTextButtonColor} setter={setLoginTextButtonColor} label="Text Button Color" disabled={disableForm} required/>;
  const mobileBackgroundColor = <ColorPicker getter={backgroundColor} setter={setMobileBackground} label="Background Color" disabled={disableForm} required/>;

  const headerImageUpload =
    <UploadButton
      inputProps={{ accept: 'image/png' }}
      label={`Header Image`}
      required
      requirements={[`Height: 300px`, 'transparent background', '72 dpi', 'PNG']}
      onUpload={handleHeaderImageUpload}
      validations={handleHeaderImageValidations}
      error={logoError}
      disabled={disableForm}
    >Upload Image</UploadButton>;

  const loginHeaderImageAltTextInput = <Input
    label="Header Image Alt Text"
    value={loginHeaderImageAltText || ""}
    onChange={val => {onLangInputChange(val, "loginHeaderImageAltText")}}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const headerText = <Input
    label="Header Text"
    required
    error={!loginHeaderText && setErrorLoginHeaderText}
    value={loginHeaderText}
    onChange={val => {onLangInputChange(val, "loginHeaderText")}}
    inputProps={{ maxLength: 85 }}
    helperText={`${85 - loginHeaderText.length} - character limit`}
    disabled={isMultiLangOrRegion || disableForm}
  />;

  const loginText = <Input
    label="Login Button"
    required
    error={!loginSigninText && setErrorLoginSigninText}
    value={loginSigninText}
    onChange={val => {onLangInputChange(val, "loginSigninText")}}
    inputProps={{ maxLength: 17 }}
    helperText={`${17 - loginSigninText.length} - character limit`}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const registerText = <Input
    label="Register Button"
    required
    error={!loginRegisterText && setErrorLoginRegisterText}
    value={loginRegisterText}
    onChange={val => {onLangInputChange(val, "loginRegisterText")}}
    inputProps={{ maxLength: 17 }}
    helperText={`${17 - loginRegisterText.length} - character limit`}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const existingSubscribersHeading = <Input
    label="Existing Subscriber"
    required
    error={!loginExistingSubscribersHeading && setErrorLoginExistingSubscribersHeading}
    value={loginExistingSubscribersHeading}
    onChange={val => {onLangInputChange(val, "loginExistingSubscribersHeading")}}
    inputProps={{ maxLength: 40 }}
    helperText={`${40 - loginExistingSubscribersHeading.length} - character limit`}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const restorePurchasesHeading = <Input
    label="Restore Purchases"
    required
    error={!loginRestorePurchasesHeading && setErrorLoginRestorePurchasesHeading}
    value={loginRestorePurchasesHeading}
    onChange={val => {onLangInputChange(val, "loginRestorePurchasesHeading")}}
    inputProps={{ maxLength: 40 }}
    helperText={`${40 - loginRestorePurchasesHeading.length} - character limit`}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const buttonText = <Input
    label="Button Text"
    required
    error={!loginButtonText && setErrorLoginButtonText}
    value={loginButtonText}
    onChange={val => {onLangInputChange(val, "loginButtonText")}}
    inputProps={{ maxLength: 40 }}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const noAccountText = <Input
    label="No Account Text"
    required
    error={!loginNoAccountText && setErrorLoginNoAccountText}
    value={loginNoAccountText}
    onChange={val => {onLangInputChange(val, "loginNoAccountText")}}
    inputProps={{ maxLength: 40 }}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const accountExistsText = <Input
    label="Account Exists Text"
    required
    error={!loginAccountExistsText && setErrorLoginAccountExistsText}
    value={loginAccountExistsText}
    onChange={val => {onLangInputChange(val, "loginAccountExistsText")}}
    inputProps={{ maxLength: 40 }}
    disabled={isMultiLangOrRegion || disableForm}
  />;
  const loginType = (
    <div style={{ display: "flex", fontSize: 16 }}>
      <span><b>Login type</b></span>
      <span style={{ marginLeft: 32 }}>{design.loginType === "OnScreen" ? 'On Screen': design.loginType.toUpperCase()}</span>
    </div>
  );

  const termsText = <Input
    label="Terms of Use URL"
    required
    value={loginTermsUrl || ""}
    onChange={val => {onLangInputChange(val, "loginTermsUrl")}}
    error={!loginTermsUrl && setErrorTermUrl}
    disabled={isMultiLangOrRegion || disableForm}
  />;

  const policyText = <Input
    label="Privacy Policy URL"
    required
    value={loginPrivacyUrl || ""}
    onChange={val => {onLangInputChange(val, "loginPrivacyUrl")}}
    error={!loginPrivacyUrl && setErrorPrivacyUrl}
    disabled={isMultiLangOrRegion || disableForm}
  />;

  const screenBackground =
    <BackgroundGroup
      getter={loginBackground}
      setter={setLoginBackground}
      supportedImageFormats={['jpg']}
      disabled={disableForm}
    />;

  const notValidate = () => {
    let check = false
    if(!loginTermsUrl){
      setErrorTermUrl("required field")
      check = true
    }
    if(!loginPrivacyUrl){
      setErrorPrivacyUrl("required field")
      check = true
    }
    if(!loginHeaderText){
      setErrorLoginHeaderText("required field")
      check = true
    }
    if(!loginSigninText){
      setErrorLoginSigninText("required field")
      check = true
    }
    if(!loginRegisterText){
      setErrorLoginRegisterText("required field")
      check = true
    }
    if(!loginExistingSubscribersHeading){
      setErrorLoginExistingSubscribersHeading("required field")
      check = true
    }
    if(!loginRestorePurchasesHeading){
      setErrorLoginRestorePurchasesHeading("required field")
      check = true
    }
    if(!loginButtonText){
      setErrorLoginButtonText("required field")
      check = true
    }
    if(!loginNoAccountText){
      setErrorLoginNoAccountText("required field")
      check = true
    }
    if(!loginAccountExistsText){
      setErrorLoginAccountExistsText("required field")
      check = true
    }

    return check
  }

  function addMultiLangText(component, title, key, charLimit, buttonMargin) {
    return <div style={{display: 'flex'}}>
      {component}
      {multiText(title, key, charLimit, buttonMargin)}
    </div>
  }

  return (
    <Layout {...layoutProps} notValidate={notValidate}>
      <>
        {loginType}
        {headerImageUpload}
        {addMultiLangText(loginHeaderImageAltTextInput, "Header Image Alt Text", "loginHeaderImageAltText", 0, "8px")}
        {addMultiLangText(headerText, "Header Text", "loginHeaderText", 85)}
        {addMultiLangText(loginText, "Login Button", "loginSigninText", 17)}
        {addMultiLangText(registerText, "Register Button", "loginRegisterText", 17)}
        {addMultiLangText(existingSubscribersHeading, "Existing Subscriber", "loginExistingSubscribersHeading", 40)}
        {addMultiLangText(restorePurchasesHeading, "Restore Purchases", "loginRestorePurchasesHeading", 40)}
        {addMultiLangText(buttonText, "Login Button Text", "loginButtonText", 40, "8px")}
        {addMultiLangText(termsText, "Terms of Use URL", "loginTermsUrl", 0, "8px")}
        {addMultiLangText(policyText, "Privacy Policy URL", "loginPrivacyUrl", 0, "8px")}
        {addMultiLangText(noAccountText, "No Account Text", "loginNoAccountText", 0, "8px")}
        {addMultiLangText(accountExistsText, "Account Exists Text", "loginAccountExistsText", 0, "8px")}
        {loginButtonColorPicker}
        {loginHighlightColorPicker}
        {loginBorderColorPicker}
        {loginHeaderColorPicker}
        {loginButtonLabelColorPicker}
        {loginTextButtonColorPicker}
        {
          simulator.deviceName === "Mobile"
          ? mobileBackgroundColor
          : screenBackground
        }
      </>
    </Layout>
  );
}

export default withDesign(withSimulator(LoginConfiguration));
