import { gql } from '@apollo/client';

export const LOCALE_FRAGMENT = gql`
  fragment LocaleSchema on Locale {
    __typename
    id
    name
    countries
    languages
    appUsageTracking
    regionCode
  }
`

export const APP_FRAGMENT = gql`
  fragment AppSchema on App {
    __typename
    id
    name
    status
    iconUrl
    locales {
      ...LocaleSchema
    }
  }
  ${LOCALE_FRAGMENT}
`

export const FULL_APP_FRAGMENT = gql`
  fragment FullAppSchema on App {
    __typename
    id
    name
    status
    iconUrl
    locales {
      ...LocaleSchema
    }
    configuration
    cloudFeed
    countryRatings
    developerWebsite
    maxStreams
  }
  ${LOCALE_FRAGMENT}
`;

export const WORKSPACE_FRAGMENT = gql`
  fragment WorkspaceSchema on Workspace {
    __typename
    id
    name
    card
    vizioSourceKey
    apps {
      ...AppSchema
    }
  }
  ${APP_FRAGMENT}
`

export const USER_FRAGMENT = gql`
  fragment UserSchema on User {
    __typename
    id
    name
    email
    image
    phone
    phoneVerified
    locked
    workspaces {
      ...WorkspaceSchema
    }
  }
  ${WORKSPACE_FRAGMENT}
`

export const ME = gql`
  query {
    me {
      ...UserSchema
    }
  }
  ${USER_FRAGMENT}
`

export const UPDATE_ME = gql`
  mutation updateMe($userFields: UserInput!) {
    updateMe(
      input: {
        userFields: $userFields,
      }
    ) {
      me {
        ...UserSchema
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const SEND_VERIFICATION_CODE = gql`
  mutation sendVerificationCode($phone: String!) {
    sendVerificationCode(
      input: {
        phone: $phone
      }
    ) {
      success
      user {
        ...UserSchema
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace($name: String!) {
    createWorkspace(
      input: {
        name: $name
      }
    ) {
      workspace {
        ...WorkspaceSchema
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
`

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace($id: ID! $workspaceFields: WorkspaceInput!) {
    updateWorkspace(
      input: {
        id: $id
        workspaceFields: $workspaceFields
      }
    ) {
      workspace {
        ...WorkspaceSchema
      }
    }
  }
  ${WORKSPACE_FRAGMENT}
`

export const GET_APP = gql`
  query app($workspaceId: ID! $appId: ID!) {
    app(
      workspaceId: $workspaceId
      appId: $appId
    ) {
      ...FullAppSchema
    }
  }
  ${FULL_APP_FRAGMENT}
`;

export const GET_POLICY = gql`
  query policy($workspaceId: ID! $appId: ID! $localeId: ID!) {
    policy(
      workspaceId: $workspaceId
      appId: $appId
      localeId: $localeId
    )
  }
`;

export const CREATE_APP = gql`
  mutation createApp($workspaceId: ID! $name: String!) {
    createApp(
      input: {
        workspaceId: $workspaceId
        name: $name
      }
    ) {
      app {
        ...AppSchema
      }
    }
  }
  ${APP_FRAGMENT}
`;

export const UPDATE_APP = gql`
  mutation updateApp($workspaceId: ID! $id: ID! $appFields: AppInput!) {
    updateApp(
      input: {
        workspaceId: $workspaceId
        id: $id
        appFields: $appFields
      }
    ) {
      app {
        ...AppSchema
      }
    }
  }
  ${APP_FRAGMENT}
`;

export const DELETE_APP = gql`
  mutation deleteApp($workspaceId: ID! $id: ID!) {
    deleteApp(
      input: {
        workspaceId: $workspaceId
        id: $id
      }
    ) {
      app {
        ...AppSchema
      }
    }
  }
  ${APP_FRAGMENT}
`;

export const VERIFY_CODE = gql`
  mutation verifyCode($phone: String! $code: String!) {
    verifyCode(
      input: {
        code: $code
        phone: $phone
      }
    ) {
      success
      user {
        ...UserSchema
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const GET_CURRENT_APP = gql`
  query {
    getCurrentApp @client
  }
`;

export const GET_CURRENT_WORKSPACE = gql`
  query {
    getCurrentWorkspace @client
  }
`;

export const GET_CURRENT_APP_PROPS = gql`
  query {
    getCurrentAppProps @client
  }
`;
