import React from "react";
import _ from "underscore";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import BundleIdInput from "components/atoms/Input/BundleId";
import PasswordInput from "components/atoms/Input/Password";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import Select from "components/atoms/Input/Select";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Tooltip from "components/atoms/Tooltip";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { SAMSUNG_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import LanguageText from "components/molecules/LanguageText";
import { PlatformApiKey } from "./List";
import PaymentProvider from "./PaymentProvider";

const categories = [
  { label: "Videos", value: "videos" },
  { label: "Games", value: "games" },
  { label: "Lifestyle", value: "lifestyle" },
  { label: "Sports", value: "sports" },
  { label: "Information", value: "information" },
  { label: "Education", value: "education" },
];

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion } = props;
  const platform = store.platforms?.find((p) => p.type === "Platform::Samsung");

  const { lang } = getDefaultLocaleAndLang(app);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store.metadata || {};
  const platformMetadata = platform.metadata || {};

  const [accessGranted, setAccessGranted] = React.useState(
    storeMetadata.access_granted || false
  );
  const [username, setUsername] = React.useState(storeMetadata.username || "");
  const [password, setPassword] = React.useState(storeMetadata.password || "");

  const [appStoreName, setAppStoreName] = React.useState(
    storeMetadata.app_store_name || ""
  );
  const [category, setCategory] = React.useState(storeMetadata.category || "");
  const [rating, setRating] = React.useState(storeMetadata.rating || "");
  const [keywords, setKeywords] = React.useState(storeMetadata.keywords || "");
  const [sellerName, setSellerName] = React.useState(
    storeMetadata.seller_name || ""
  );
  const [supportEmail, setSupportEmail] = React.useState(
    storeMetadata.support_email || ""
  );
  const [sellerHomepage, setSellerHomepage] = React.useState(
    storeMetadata.seller_homepage || ""
  );
  const [representative, setRepresentative] = React.useState(
    storeMetadata.representative || ""
  );
  const [address, setAddress] = React.useState(storeMetadata.address || "");
  const [phoneNumber, setPhoneNumber] = React.useState(
    storeMetadata.phone_number || ""
  );
  const [gaTrackingId, setGaTrackingId] = React.useState(platformMetadata.ga_tracking_id || "");
  const [bundleId, setBundleId] = React.useState(platformMetadata.bundle_id || "");
  const saveTimer = React.useRef(null);

  const [asset, setAsset] = React.useState({
    urls: {
      logo: platform.assetUrls.logo,
      appIcon: platform.assetUrls.app_icon,
      backgroundImage: platform.assetUrls.background_image,
      cover: platform.assetUrls.covers || [],
    },
    blobs: {
      logo: null,
      appIcon: null,
      backgroundImage: null,
      cover: [],
    },
    errors: {
      logo: null,
      appIcon: null,
      backgroundImage: null,
      cover: null,
    },
  });

  const [paymentGateway, setPaymentGateway] = React.useState(store.paymentGateway || "stripe")
  const [lastUploaded, setLastUploaded] = React.useState(null);
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))
  const description = descriptionLangObj[lang] || "";

  const assetProps = { asset, setAsset, assetMap: SAMSUNG_ASSET_MAP };
  const apiKeys = [{name: "Samsung", apiKey: platform.apiKey}]

  React.useEffect(() => {
    if (
      asset.blobs.logo ||
      asset.blobs.appIcon ||
      asset.blobs.backgroundImage ||
      asset.blobs.cover.length > 0
    ) {
      setLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.logo,
    asset.blobs.appIcon,
    asset.blobs.backgroundImage,
    asset.blobs.cover,
  ]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [lastUploaded, username, password, appStoreName, descriptionLangObj, category, rating, keywords, sellerName, supportEmail, sellerHomepage, representative, address, phoneNumber, gaTrackingId, bundleId, paymentGateway]);

  React.useEffect(() => {
    if (username && password) {
      setAccessGranted(true);
    }
  }, [username, password]);
  
  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            paymentGateway,
            metadata: {
              access_granted: accessGranted,
              username: username,
              password: password,
              app_store_name: appStoreName,
              description: descriptionLangObj,
              category: category,
              rating: rating,
              keywords: keywords,
              seller_name: sellerName,
              support_email: supportEmail,
              seller_homepage: sellerHomepage,
              representative: representative,
              address: address,
              phone_number: phoneNumber,
            },
            platformsAttributes: [
              {
                id: platform.id,
                metadata: {
                  bundle_id: bundleId,
                  ga_tracking_id: gaTrackingId,
                  last_uploaded: lastUploaded,
                },
                assetBlobs: {
                  logo: asset.blobs.logo,
                  app_icon: asset.blobs.appIcon,
                  background_image: asset.blobs.backgroundImage,
                  covers: asset.blobs.cover,
                },
              },
            ],
          },
        },
      });
    }
  };

  const validateForm = () => {
    return true;
  };

  const langInput = (data, onSubmit, title, charLimit) => (<LanguageText
    data={data}
    onSubmit={onSubmit}
    languageOnly
    buttonProps={{style: {marginBottom: '32px'}}}
    title={title}
    charLimit={charLimit}
  />)

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl  }, { name: "Samsung" }]}>
      <PaperGroup>
        <GroupHeader
          title="General"
          caption="You must connect to stripe if you have enabled the subscriptions or locked the feeds items by IAPs in your app."
        />
        <Tooltip title="The App Title is used only in the Samsung store, not on a user's home screen. Tip: all of the words in the app store name count as keywords when user's search for your app.">
          <Input
            label="App title"
            defaultValue={appStoreName}
            onChange={setAppStoreName}
            inputProps={{ maxLength: 50 }}
            helperText={`${50 - appStoreName.length} - character limit`}
            required
            autoFocus
          />
        </Tooltip>

        <div style={{display: 'flex'}}>
          <Tooltip title="This is a description of your app that will be displayed in the app store. Keep your catchiest content in the first few lines to make sure it is visible to all visitors.">
            <Input
              label="Description"
              value={description}
              onChange={val => {onLangTextChange(val, setDescriptionLangObj)}}
              inputProps={{ maxLength: 2000 }}
              helperText={`${2000 - description.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description", 2000)}
        </div>
        

        <Tooltip title="Category helps prospective downloaders find your app in the App Store.">
          <Select
            label="Category"
            value={category}
            onChange={setCategory}
            options={categories}
            required
          />
        </Tooltip>

        <Tooltip
          interactive
          title={
            <>
              {`Samsung will assign your app an age rating based on its content. The ratings are 4+, 12+, 16+, or 18+.`}<br/><br/>
              {`Note: MAZ cannot approve any apps with Graphic Realistic Violence or Graphic Sexual/Nudity content.`}
            </>
          }
        >
          <Select
            label="Rating"
            value={rating}
            onChange={setRating}
            options={[
              { label: "All", value: "all" },
              { label: "4+", value: "4+" },
              { label: "12+", value: "12+" },
              { label: "16+", value: "16+" },
              { label: "18+", value: "18+" },
            ]}
            required
          />
        </Tooltip>

        <Input
          label="Tags"
          defaultValue={keywords}
          onChange={setKeywords}
          inputProps={{ maxLength: 96 }}
          placeholder="celebrities, celebrity news, taylor swift, justin bieber, kardashians"
          helperText={`${96 - keywords.length} - character limit`}
          required
        />

        <Input
          label="Seller Name"
          defaultValue={sellerName}
          onChange={setSellerName}
          inputProps={{ maxLength: 50 }}
          helperText={`${50 - sellerName.length} - character limit`}
          required
        />

        <Input
          label="Support Email"
          defaultValue={supportEmail}
          onChange={setSupportEmail}
          inputProps={{ maxLength: 50 }}
          helperText={`${50 - supportEmail.length} - character limit`}
          required
        />

        <Input
          label="Seller Homepage"
          defaultValue={sellerHomepage}
          onChange={setSellerHomepage}
          inputProps={{ maxLength: 50 }}
          helperText={`${50 - sellerHomepage.length} - character limit`}
          required
        />

        <Input
          label="Representative"
          defaultValue={representative}
          onChange={setRepresentative}
          inputProps={{ maxLength: 50 }}
          helperText={`${50 - representative.length} - character limit`}
          required
        />

        <Input
          label="Address"
          defaultValue={address}
          onChange={setAddress}
          inputProps={{ maxLength: 50 }}
          helperText={`${50 - address.length} - character limit`}
          required
        />

        <Input
          label="Phone Number"
          defaultValue={phoneNumber}
          onChange={setPhoneNumber}
          inputProps={{ maxLength: 20 }}
          helperText={`${20 - phoneNumber.length} - character limit`}
          required
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Assets" />

        <ImageUploadGroup {...assetProps} name="logo" horizontal previewMaxHeight={120} />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="backgroundImage" horizontal previewMaxHeight={120} />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="appIcon" horizontal previewMaxHeight={148} />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="cover" horizontal previewMaxHeight={108} />
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaymentProvider
        getter={paymentGateway}
        setter={setPaymentGateway}
      />

      <PaperGroup>
        <GroupHeader title="Developer Access" />

        <Input
          label="Login username"
          defaultValue={username}
          onChange={setUsername}
          required
        />
        <PasswordInput
          label="Login password"
          defaultValue={password}
          onChange={setPassword}
          required
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <GaTrackingIdInput
          label="Google Analytics Tracking ID"
          defaultValue={gaTrackingId}
          onChange={setGaTrackingId}
          required
        />
        <BundleIdInput
          label="App Bundle ID"
          defaultValue={bundleId}
          onChange={setBundleId}
          placeholder={`com.maz.tvod.${appProps.appId}.${platform.id}`}
        />
      </PaperGroup>
    </FormLayout>
  );
};

export default withStore(Platform);
