import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_INTEGRATION } from "api";
import Form from "./Form";

export default function Edit(props) {
  const { id } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_INTEGRATION, { variables: { ...appProps, id: id } });

  return (
    data?.integration
      ? <Form {...props} integration={data.integration} />
      : null
  );
}
