import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  noitem: (props) => ({
    fontSize: "20px",
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "column",
    color: props.savedTextColor,
    width: '100%',
    position: 'absolute',
    padding: '1rem',
    paddingBottom: '3rem',
    textAlign: 'center'
  }),
  title: (props) => ({
    fontWeight: 700,
    fontSize: 28,
    maxWidth: 300,
    color: props.onboardingTitleColor,
  }),
  summary: (props) => ({
    fontSize: 24,
    maxWidth: 300,
    color: props.onboardingDescriptionColor,
  }),
  dots: {
    display: "flex",
    marginBottom: 12,
    marginTop: 8,
  },
  dot: (props) => ({
    height: 10,
    width: 10,
    borderRadius: 10,
    backgroundColor: `#${props.onboardingPageControlUnselectedColor.substring(3)}`,
    backgroundOpacity: 0.5,
    margin: "10px 12px",
    opacity: 0.5
  }),
  dotSelected: (props) => ({
    backgroundColor: props.onboardingPageControlSelectedColor,
    opacity: 1,
  }),
  button: (props) => ({
    backgroundColor: props.onboardingButtonColor,
    color: props.onboardingButtonTitleColor,
    width: 240,
    fontSize: 20,
    textTransform: 'none',
    padding: "4px 16px"
  }),
  logo: {
    maxHeight: 132,
    maxWidth: 280,
  },
  skip: {
    position: "absolute",
    top: 15,
    right: 25,
  }
}));

export default function Onboarding(props) {
  const { simulator } = props;
  const { design } = simulator;
  const classes = useStyles(design);

  const screen = design.screen || design.onboardingScreens[0];
  if (!screen) {
    return null;
  }

  return (
    <div className={classes.noitem}>
      {screen.logoUrl && <img className={classes.logo} src={screen.logoUrl} />}
      {design.onboardingAllowSkip && <div className={classes.skip}>Skip</div>}
      <div className={classes.title}>{screen.title}</div>
      <div className={classes.summary}>{screen.summary}</div>
      <div className={classes.dots}>
        <div className={clsx(classes.dot, classes.dotSelected)} />
        <div className={classes.dot} />
        <div className={classes.dot} />
      </div>
      <Button variant="contained" className={classes.button}>Next</Button>
    </div>
  );
}
