import { useQuery, useApolloClient } from '@apollo/client'
import React from 'react';
import { Redirect, Switch, Route, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from 'actions';
import { ME, GET_CURRENT_WORKSPACE, GET_CURRENT_APP } from 'api';
import { workspace, app } from 'cache';

import Design from './Design';
import Content from './Content';
import Monetization from './Monetization';
import Live from './Live';
import Analytics from './Analytics';
import Settings from './Settings';

import Layout from 'screens/Layout';
import { useLayoutData } from './layouts';
import { withSavedSnack } from "components/molecules/Alerts/Saved";
import { withLocaleLang } from 'components/containers/withLocaleLang';

function Hubs(props) {
  const { path } = useRouteMatch();
  const client = useApolloClient();
  const location = useLocation();
  const layoutData = useLayoutData();
  const { workspaceId, appId } = useParams();
  const cachedWorkspace = useQuery(GET_CURRENT_WORKSPACE).data.getCurrentWorkspace;
  const cachedApp = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data, loading } = useQuery(ME, {
    onError(error) {
      if (error.message.startsWith("NetworkError")) {
        workspace({});
        app({});
        client.resetStore();
        props.clearRoute();
        props.logout();
      }
    }
  });
  const [ notFound, setNotFound ] = React.useState(false);

  React.useEffect(() => {
    if (data && workspaceId && appId) {
      let lworkspace, lapp;
      lworkspace = data.me.workspaces.find((w) => w.id === workspaceId);
      if (lworkspace) {
        workspace(lworkspace);
        lapp = lworkspace.apps.find((a) => a.id === appId);
        if (lapp) {
          app(lapp);
        } else {
          if (appId === 0) {
            lapp = lworkspace.apps[0];
            app(lapp);
          } else {
            app({});
            setNotFound(true);
          }

        }
      } else {
        workspace({});
        app({});
        setNotFound(true);
      }
    }
  }, [workspaceId, appId, data]);

  if (!loading && notFound) {
    return <Redirect to="/" />;
  }

  if (!cachedApp.id) {
    return null;
  }

  const locSplit = location.pathname.split('/');
  if (locSplit.length < 4 || locSplit[locSplit.length-1] === '') {
    return <Redirect to={`/${cachedWorkspace.id}/${cachedApp.id}/design`} />;
  }

  const hubProps = { savedSnack: props.savedSnack, isMultiLangOrRegion: props.isMultiLangOrRegion };

  if (layoutData["$$typeof"]) {
    return layoutData;
  }

  return (
    <Layout {...layoutData}>
      <Switch>
        <Route path={`${path}/design`}><Design {...hubProps} /></Route>
        <Route path={`${path}/content`}><Content {...hubProps} /></Route>
        <Route path={`${path}/monetization`}><Monetization {...hubProps} /></Route>
        <Route path={`${path}/live`}><Live {...hubProps} /></Route>
        {/* <Route path={`${path}/analytics`}><Analytics {...hubProps} /></Route> */}
        <Route path={`${path}/settings`}><Settings {...hubProps} /></Route>
        <Redirect to={`${path}/content/sections`} />
      </Switch>
    </Layout>
  );
}

export default connect(null, actions)(withLocaleLang(withSavedSnack(Hubs)));
