import React from 'react';
import _ from 'underscore';

import ColorPicker from 'components/atoms/Input/ColorPicker';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import { withDesign } from './Design';
import Layout from './Layout';
import Input from 'components/atoms/Input/BaseInput';
import { BooleanSelect } from 'components/atoms/Input/Select';
import { generateLocalesForDesigns } from 'helpers/langUtils';
import LanguageText from 'components/molecules/LanguageText';

function Saved(props) {
  const { simulator, designs, isMultiLangOrRegion } = props;
  const design = simulator.design;

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [ savedBackground, setSavedBackground ] = React.useState(design.savedBackground);
  const [ savedActive, setSavedActive ] = React.useState(design.savedActive);
  const [ savedTextColor, setSavedTextColor ] = React.useState(design.savedTextColor);
  // const [ savedNoItemsText, setSavedNoItemsText ] = React.useState(design.savedNoItemsText);
  const [ textLocales, setTextLocales ] = React.useState(getTextLocales())
    
  const defaultLocale = {
    locale: design.locale.id,
    lang: design.language
  }

  const savedNoItemsText = textLocales[defaultLocale.locale][defaultLocale.lang]["savedNoItemsText"];

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [savedBackground, savedActive, savedTextColor, savedNoItemsText]);

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  const cancel = () => {
    setSavedBackground(design.savedBackground);
    setSavedActive(design.savedActive);
    setSavedTextColor(design.savedTextColor);
    setTextLocales(getTextLocales());

    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const commonFields = {
    savedActive,
    savedTextColor,
    savedNoItemsText,
  };

  const stateDesign = {
    ...commonFields,
    savedBackground: _.omit(savedBackground, "assetBlobId"),
  };

  const stateForm = {
    ...commonFields,
    savedBackgroundAttributes: _.omit(savedBackground, "assetUrl", "__typename"),
    textLocales
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm,
    simulator,
    tab: "Saved",
    headerTitle: "Saved Items",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  function getTextLocales() {
    return generateLocalesForDesigns(designs, ["savedNoItemsText"])
  }

  function multiText(title, key, charLimit = 0, buttonMargin) {
    const buttonProps = buttonMargin
      ? { style: { marginBottom: buttonMargin } }
      : {};
    return (
      <LanguageText
        onSubmit={setTextLocales}
        data={textLocales}
        property={{ key, charLimit }}
        buttonProps={buttonProps}
        title={title}
      />
    );
  }

  function onLangInputChange(val, key) {
    const textLocaleDuplicate = {...textLocales}
    textLocaleDuplicate[defaultLocale.locale][defaultLocale.lang][key] = val;
    setTextLocales(textLocaleDuplicate);
  }

  const savedActiveSelect =
    <BooleanSelect
      value={savedActive}
      onChange={setSavedActive}
      label="Saved Section"
      onOff={true}
      disabled={disableForm}
      required
    />;

  const bgColor = savedBackground.primaryColor;
  const setBgColor = (color) => setSavedBackground({...savedBackground, format: 'single', primaryColor: color})

  const backgroundColor = <ColorPicker getter={bgColor} setter={setBgColor} label="Background Color" disabled={disableForm}/>;
  const primaryColor = <ColorPicker getter={savedTextColor} setter={setSavedTextColor} label="Text Color" disabled={disableForm}/>;
  const noItemsText =
    <div style={{display: 'flex'}}>
      <Input
        label="No Items Text"
        value={savedNoItemsText}
        onChange={val => {onLangInputChange(val, "savedNoItemsText")}}
        inputProps={{ maxLength: 85 }}
        helperText={`${85 - savedNoItemsText.length} - character limit`}
        required
        disabled={isMultiLangOrRegion || disableForm}
      />
      {multiText("No Items Text", "savedNoItemsText", 85)}
    </div>

  return (
    <Layout {...layoutProps}>
      {savedActiveSelect}
      {savedActive && (
        <>
          {backgroundColor}
          {primaryColor}
          {noItemsText}
        </>
      )}
    </Layout>
  );
}

export default withDesign(withSimulator(Saved));
