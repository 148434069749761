import parsePhoneNumber from 'libphonenumber-js'

export function formattedPhoneNumber(text) {
  if (!text || text === "") return null;
  const phoneNumber = parsePhoneNumber(`+${text}`);
  if (phoneNumber) {
    return phoneNumber.formatInternational()
  }
  
  text.slice(-10);
  return `${
    text.length > 10 ? `+${text.substring(0, text.length - 10)} ` : ""
  }(${text.slice(-10).substring(0, 3)}) ${text
    .slice(-10)
    .substring(3, 6)}-${text.slice(-10).substring(6)}`;
}
