import { gql } from "@apollo/client";

export const APP_PAYMENT_PLATFORM_SCHEMA = gql`
  fragment AppPaymentPlatformSchema on AppPaymentPlatform {
    id
    name
    metadata
  }
`;

export const GET_APP_PAYMENT_PLATFORMS = gql`
  query appPaymentPlatforms($workspaceId: ID! $appId: ID!) {
    appPaymentPlatforms(workspaceId: $workspaceId appId: $appId) {
      ...AppPaymentPlatformSchema
    }
  }
  ${APP_PAYMENT_PLATFORM_SCHEMA}
`;

export const CONNECT_STRIPE = gql`
  mutation connectStripe(
    $workspaceId: ID!
    $appId: ID!
    $responseCode: String!
  ) {
    connectStripe(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        responseCode: $responseCode
      }
    ) {
      appPaymentPlatform {
        ...AppPaymentPlatformSchema
      }
    }
  }
  ${APP_PAYMENT_PLATFORM_SCHEMA}
`;

export const UPDATE_STRIPE_COUPON = gql`
  mutation updateStripeCoupon(
    $workspaceId: ID!
    $appId: ID!
    $allowCouponCode: Boolean!
  ) {
    updateStripeCoupon(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        allowCouponCode: $allowCouponCode
      }
    ) {
      appPaymentPlatform {
        ...AppPaymentPlatformSchema
      }
    }
  }
  ${APP_PAYMENT_PLATFORM_SCHEMA}
`;
