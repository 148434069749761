import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import _ from "underscore";

import BaseInput from './BaseInput';

import standardGridImage from '../../../images/layouts/tv/standard-grid.png';
import jumboGridImage from '../../../images/layouts/tv/jumbo-grid.png';
import detailViewImage from '../../../images/layouts/tv/detail-view.png';
import spotlightImage from '../../../images/layouts/tv/spotlight.png';
import fullScreenImage from '../../../images/layouts/tv/fullscreen.png';

import fullTile from '../../../images/layouts/mobile/full-tile.png';
import squareTile from '../../../images/layouts/mobile/square-tile.png';
import list from '../../../images/layouts/mobile/list.png';
import mosaic from '../../../images/layouts/mobile/mosaic.png';
import fullCards from '../../../images/layouts/mobile/full-cards.png';
import cards from '../../../images/layouts/mobile/cards.png';

import videoGrid from '../../../images/layouts/mobile/video-grid.png';
import podcast from '../../../images/layouts/mobile/podcast.png';
import fullScreen from '../../../images/layouts/mobile/full-screen.png';

import imageChooser from '../../../images/layouts/mobile/img-chooser.png';
import textChooser from '../../../images/layouts/mobile/text-chooser.png';

import carousel from '../../../images/layouts/mobile/carousel.png';
import featureGrid from '../../../images/layouts/mobile/featured.png';
import coverList from '../../../images/layouts/mobile/cover-list.png';

const useStyles = makeStyles((theme) => ({
  iconPaper: {
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 0px 2px, rgba(0, 0, 0, 0.3) 0px 4px 8px',
    maxHeight: 320,
    overflowY: 'auto',
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2, 0),
  },
  iconBox: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  },
  card: {
    display: 'inline-block',
  },
  mobileCard: {
    width: 130,
  },
  tvCard: {
    width: 260,
  },
  mobileCardMedia: {
    width: 100,
    height: 165,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tvCardMedia: {
    width: 219,
    height: 135,
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor:"pointer"
  },
  cardMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1),
    cursor:"pointer",
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  cardSelected: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  cardSelectedMedia: {
    backgroundPosition: '-1px -1px',
  },
  cardTitle: {
    textAlign: 'center',
    fontSize: 14,
  },
}));

const tvLayouts = {
  standard: {
    name: "Standard Grid",
    image: standardGridImage,
  },
  spotlight: {
    name: "Spotlight",
    image: spotlightImage,
  },
  jumbo: {
    name: "Jumbo Grid",
    image: jumboGridImage,
  },
  detail: {
    name: "Detail View",
    image: detailViewImage,
  },
  fullscreen: {
    name: "Full Screen",
    image: fullScreenImage,
  },
  hero: {
    name: "Hero Slider",
    image: standardGridImage,
  },
};

const mobileLayouts = {
  videoGrid: {
    name: "Standard Grid",
    image: videoGrid,
  },
  fullTile: {
    name: "Full Tile",
    image: fullTile,
  },
  edge: {
    name: "Square Tile",
    image: squareTile,
  },
  list: {
    name: "List",
    image: list,
  },
  mosaic: {
    name: "Mosaic",
    image: mosaic,
  },
  bigcards: {
    name: "Full Cards",
    image: fullCards,
  },
  cards: {
    name: "Square Cards",
    image: cards,
  },
  // podcast: {
  //   name: "Podcast",
  //   image: podcast,
  // },
  detail: {
    name: "Full Screen",
    image: fullScreen,
  },
  chooser: {
    name: "Image Chooser",
    image: imageChooser,
  },
  text: {
    name: "Text Chooser",
    image: textChooser,
  },
  // carousel: {
  //   name: "Carousel",
  //   image: carousel,
  // },
  // grid: {
  //   name: "Feature Grid",
  //   image: featureGrid,
  // },
  // coverList: {
  //   name: "Cover List",
  //   image: coverList,
  // },
  hero: {
    name: "Hero Slider",
    image: videoGrid,
  },
  simpleGrid: {
    name: "Simple Grid",
    image: videoGrid,
  },
};

export const tvSeriesLayout = {
  series: {
    name: "Series",
    image: standardGridImage,
  },
};

export const mobileSeriesLayout = {
  series: {
    name: "Series",
    image: videoGrid,
  }
};

export const mobileEpgLayout = {
  epg: {
    name: "EPG Grid",
    image: textChooser,
  },
  epglist: {
    name: "EPG List",
    image: textChooser,
  }
};

export default function LayoutPicker(props) {
  const { device, getter, setter, isSaved, textFieldProps, isSeries, isGuide } = props;
  const [ layoutAnchor, setLayoutAnchor ] = React.useState(null);
  const handleClose = () => setLayoutAnchor(null);

  let layouts = device === "tv" ? tvLayouts : mobileLayouts;
  if (isSaved) {
    if (device === "tv") {
      layouts = _.omit(layouts, "fullscreen", "spotlight");
    } else {
      layouts = _.omit(layouts, "videoGrid", "carousel", "grid", "coverList", "text", "chooser", "bigcards", "podcast");
    }
  } else if (isGuide) {
    if (device === "mobile") {
      layouts = { ...mobileEpgLayout };
    }
  } else if (isSeries) {
    layouts = { ...layouts, ...(device === "tv" ? tvSeriesLayout : mobileSeriesLayout) };
  }

  return (
    <div>
      <BaseInput
        readOnly
        required
        label="Layout"
        value={layouts[getter]?.name}
        name={`${device}Layout`}
        onClick={(event) => setLayoutAnchor(event.currentTarget)}
        style={{ maxWidth: 260, cursor:"pointer" }}
        inputtype="pointer"
        onChange={() => {}}
        {...textFieldProps}
      />
      <Menu
        anchorEl={layoutAnchor}
        open={Boolean(layoutAnchor)}
        onClose={handleClose}
        PaperProps={{ style: { width: 260 } }}
        MenuListProps={{ style: { padding: 0 } }}
      >
        <IconPaper
          device={device}
          icon={getter}
          isSaved={isSaved}
          isSeries={isSeries}
          layouts={layouts}
          onChange={(icon) => {
            setter(icon);
            handleClose();
          }}
        />
      </Menu>
    </div>
  );
}

const IconPaper = ({ device, icon, onChange, layouts }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconPaper}>
      {Object.keys(layouts).map((name) => (
        <LayoutCell
          key={`LayoutCell-${device}-${name}`}
          title={layouts[name].name}
          image={layouts[name].image}
          value={name}
          device={device}
          getter={icon}
          setter={onChange}
        />
      ))}
    </div>
  );
}

export function LayoutCell(props) {
  const classes = useStyles();
  const { device, value, getter, setter, title, image } = props;
  const selected = value === getter;

  return (
    <>
      <div className={clsx(device === "tv" ? classes.tvCard : classes.mobileCard, classes.card)} onClick={() => setter(value)}>
        <div className={clsx(device === "tv" ? classes.tvCardMedia : classes.mobileCardMedia, classes.cardMedia, selected && classes.cardSelected)}>
          <img src={image} width={device === "tv" ? 204 : 85} height={device === "tv" ? 120 : 150} alt={title} />
        </div>
        <Typography gutterBottom variant="h6" component="h2" className={classes.cardTitle}>
          {title}
        </Typography>
      </div>
    </>
  );
};
