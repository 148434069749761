import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 4),
    margin: theme.spacing(2, 0),
  },
  label: {
    color: theme.palette.grey["600"],
  },
}));

export default function InformationGroup(props) {
  const classes = useStyles();
  return (
    <Paper square className={classes.paper}>
      {props.records.map((rec) =>
        <InformationRow key={`IRow-${rec.label}-${rec.value}`} {...rec} />
      )}
    </Paper>
  );
}

export function InformationRow(props) {
  const { label, text, value, type } = props;
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center">
      <Box width={80}>
        <Typography className={classes.label}>{label}</Typography>
      </Box>
      <Box flexGrow={1}>
        {type === "link"
          ? <Link href={value}>{text}</Link>
          : <Typography>{text || value}</Typography>
        }
      </Box>
    </Box>
  )
}
