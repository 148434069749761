import { gql } from '@apollo/client';

export const LIVE_CHANNEL_FRAGMENT = gql`
  fragment LiveChannelSchema on LiveChannel {
    __typename
    id
    title
    xmltvChannelId
    defaultSchedule {
      id
      defaultUrl
    }
  }
`

export const GET_LIVE_CHANNELS = gql`
  query liveChannels($workspaceId: ID! $appId: ID!) {
    liveChannels(workspaceId: $workspaceId appId: $appId) {
      ...LiveChannelSchema
    }
  }
  ${LIVE_CHANNEL_FRAGMENT}
`

export const GET_LIVE_CHANNEL = gql`
  query liveChannel($workspaceId: ID! $appId: ID! $id: ID!) {
    liveChannel(workspaceId: $workspaceId appId: $appId id: $id) {
      ...LiveChannelSchema
    }
  }
  ${LIVE_CHANNEL_FRAGMENT}
`

export const CREATE_LIVE_CHANNEL = gql`
  mutation createLiveChannel(
    $workspaceId: ID!
    $appId: ID!
    $liveChannelFields: LiveChannelInput!
  ) {
    createLiveChannel(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        liveChannelFields: $liveChannelFields
      }
    ) {
      liveChannel {
        ...LiveChannelSchema
      }
    }
  }
  ${LIVE_CHANNEL_FRAGMENT}
`

export const UPDATE_LIVE_CHANNEL = gql`
  mutation updateLiveChannel(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
    $liveChannelFields: LiveChannelInput!
  ) {
    updateLiveChannel(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
        liveChannelFields: $liveChannelFields
      }
    ) {
      liveChannel {
        ...LiveChannelSchema
      }
    }
  }
  ${LIVE_CHANNEL_FRAGMENT}
`

export const DELETE_LIVE_CHANNEL = gql`
  mutation deleteLiveChannel(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
  ) {
    deleteLiveChannel(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
      }
    ) {
      liveChannel {
        id
        title
      }
    }
  }
`

export const REARRANGE_LIVE_CHANNELS = gql`
  mutation rearrangeLiveChannels(
    $workspaceId: ID!
    $appId: ID!
    $liveChannelIds: [ID!]!
  ) {
    rearrangeLiveChannels(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        liveChannelIds: $liveChannelIds
      }
    ) {
      success
    }
  }
`;
