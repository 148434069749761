import React from "react";
import Select from "components/atoms/Input/Select";

export const HOUR_IN_SEC = 3600;

const START_DURATIONS = [
  { label: "12 hours", value: 12 * HOUR_IN_SEC },
  { label: "24 hours", value: 24 * HOUR_IN_SEC },
  { label: "36 hours", value: 36 * HOUR_IN_SEC },
  { label: "48 hours", value: 48 * HOUR_IN_SEC },
  { label: "60 hours", value: 60 * HOUR_IN_SEC },
  { label: "72 hours", value: 72 * HOUR_IN_SEC },
];

const FINISH_DURATION = [
  ...START_DURATIONS,
  { label: "84 hours", value: 84 * HOUR_IN_SEC },
  { label: "96 hours", value: 96 * HOUR_IN_SEC },
];

function Rentals({ getter, setter, disabled }) {
  const setStart = (value) =>
    setter({ ...getter, startDuration: parseInt(value) });
  const setFinish = (value) =>
    setter({ ...getter, finishDuration: parseInt(value) });

  return (
    <>
      <Select
        key={`${getter.startDuration}-start`}
        label="Start Watching Duration"
        value={getter.startDuration}
        onChange={setStart}
        options={START_DURATIONS}
        required
        disabled={disabled}
      />
      <Select
        key={`${getter.finishDuration}-finish`}
        label="Finish Watching Duration"
        value={getter.finishDuration}
        onChange={setFinish}
        options={FINISH_DURATION}
        required
        disabled={disabled}
      />
    </>
  );
}

export default Rentals;
