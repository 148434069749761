import React from 'react';
import Confirm from './Confirm';

export default function App(props) {
  return (
    <Confirm
      {...props}
      content="This app will be deleted. All content inside will be permanently removed."
    />
  );
}
