import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import List from "./List";
import Edit from "./Edit";
import LocaleList from './Locale/List';
import LocaleEdit from './Locale/Edit';
import LocaleNew from './Locale/New';
import LocaleLanguages from './Locale/LanguagesList';

export default function Monetization(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;

  const commonProps = { savedSnack };

  return (
    <Switch>
      <Route path={`${path}/edit/:appId`}>
        <Edit {...commonProps} />
      </Route>
      <Route path={`${path}/:appId/locales/edit/:localeId`}>
        <LocaleEdit {...commonProps} />
      </Route>
      <Route path={`${path}/:appId/locales/new`}>
        <LocaleNew {...commonProps} />
      </Route>
      <Route path={`${path}/:appId/locales/:localeId/languages`}>
        <LocaleLanguages {...commonProps} />
      </Route>
      <Route path={`${path}/:appId/locales`}>
        <LocaleList {...commonProps} />
      </Route>
      <Route path={path}>
        <List {...commonProps} />
      </Route>
    </Switch>
  );
}
