export default function isValid(platformDetails, appStores) {
    const { metadata, storeId, assetUrls } = platformDetails;
    const storeDetails = appStores.find(s => s.id === storeId);
    const storeMetadata = storeDetails.metadata;
    
    if (!metadata || !storeMetadata || !assetUrls) return false;
    if (!metadata.ga_tracking_id) return false;
    if (!assetUrls.app_icon) return false;
    if (!assetUrls.covers || assetUrls.covers.length < 3) return false;
    if (!assetUrls.store_background) return false;
    if (!storeMetadata.app_store_name) return false;
    if (!storeMetadata.description) return false;
    if (!storeMetadata.short_description) return false;
    return true;
}