import { gql } from "@apollo/client";

export const PLATFORM_FRAGMENT = gql`
  fragment PlatformSchema on Platform {
    __typename
    id
    type
    metadata
    buildable
    assetUrls
    isActive
    status
    apiKey
  }
`;

export const APP_STORE_FRAGMENT = gql`
  fragment AppStoreSchema on AppStore {
    __typename
    id
    name
    hasSubscriptions
    externalSubscriptionMessage
    metadata
    assetUrls
    isActive
    paymentGateway
    platforms {
      ...PlatformSchema
    }
  }
  ${PLATFORM_FRAGMENT}
`;

export const GET_APP_STORES = gql`
  query appStores($workspaceId: ID!, $appId: ID!) {
    appStores(workspaceId: $workspaceId, appId: $appId) {
      ...AppStoreSchema
    }
  }
  ${APP_STORE_FRAGMENT}
`;

export const UPDATE_APP_STORE = gql`
  mutation updateAppStore(
    $workspaceId: ID!
    $appId: ID!
    $appStoreId: ID!
    $appStoreFields: AppStoreInput!
  ) {
    updateAppStore(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        appStoreId: $appStoreId
        appStoreFields: $appStoreFields
      }
    ) {
      appStore {
        ...AppStoreSchema
      }
    }
  }
  ${APP_STORE_FRAGMENT}
`;
