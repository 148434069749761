import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export default function Submit(props) {
  const { style, children, isLoading, ...otherProps } = props;
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      style={{ width: 120, ...style }}
      {...otherProps}
    >
      {isLoading
      ? <CircularProgress size={24} color="secondary"/>
      : children
      }
    </Button>
  );
}
