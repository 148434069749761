import { useQuery } from '@apollo/client';
import { GET_CURRENT_APP_PROPS, GET_TIER, GET_TIERS } from 'api';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Form from './Form';

function Edit(props) {
    const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
    const { id } = useParams();
    const { data, loading } = useQuery(GET_TIER, { variables: { ...appProps, id } });

    const tierDetails = data?.tier;
    const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/${props.monetizationPath}`;

    if (tierDetails) {
        return (
            <Form {...props} data={tierDetails}/>
        )
    } 
    if (!loading && !tierDetails) {
        return <Redirect to={listUrl} />
    } else {
        //Can show loader here.
        return null;
    }
}

export default Edit
