import _ from "underscore";

export const getBundleType = (type) => {
  if (!type) {
    return null;
  }
  const name = type.split("::");
  const lastname = _.last(type.split("::"));

  if (name[1] === "Collection" && name[2] === "Manual")
    return "Custom"
  else return lastname;
};

export const isGhostFolderType = (type) => {
  if (!type) {
    return false;
  }

  return [
    "Bundle::Catalog::Movie",
    "Bundle::Catalog::Event",
    "Bundle::Catalog::Episode",
  ].includes(type);
};

export const isMediaType = (type) => {
  if (!type) {
    return false;
  }

  return (type.includes("Media") || [
    "Bundle::Catalog::Movie",
    "Bundle::Catalog::Event",
    "Bundle::Catalog::Episode",
  ].includes(type));
};

export const getBundleCatalog = (type, page) => {
  if (!type) {
    return null;
  }
  
  const isCustom = page;

  return {
    "Bundle::Playlist::Feed": "Feed",
    "Bundle::Playlist::Manual": "Manual Playlist",
    "Bundle::Playlist::Search": "Search Playlist",

    "Bundle::Media::Unknown": "Media",
    "Bundle::Media::Preview": "Preview",
    "Bundle::Media::Trailer": "Trailer",
    "Bundle::Media::Extra": "Extra",
    "Bundle::Catalog::Link": "Link",

    "Bundle::Catalog::Movie": "Movie",
    "Bundle::Catalog::Event": "Event",
    "Bundle::Catalog::Series": "Series",
    "Bundle::Catalog::Season": "Season",
    "Bundle::Catalog::Episode": "Episode",
    "Bundle::Catalog::LimitedSeries": "Limited Series",

    "Bundle::Collection::Manual": isCustom ? "Custom": "Collection",
    "Bundle::Collection::Categories": "Categories",
    "Bundle::Collection::Genre": "Genres",
    "Bundle::Collection::Keywords": "Keywords",
    "Bundle::Collection::Actors": "Actors",
    "Bundle::Collection::Directors": "Directors",

    "Bundle::Layout::Section": "Section",
    "Bundle::Layout::Saved": "Saved Section",
    "Bundle::Layout::Guide": "Guide Section",
    "Bundle::Layout::Search": "Search Section",
    "Bundle::Layout::Settings": "Settings Section",
    "Bundle::Layout::StandardMenu": "Menu Section",
    "Bundle::StandardMenu::SubMenu": "Menu Section",
    "Bundle::StandardMenu::Item": "Menu Item",

    "Bundle::LiveChannel": "Live Channel",
  }[type];
};

export const getContentLinkPath = (bundle) => {
  const key = bundle.type === "Bundle::Layout::Symlink" ? bundle.metadata.catalog : bundle.type;
  const key_id = bundle.type === "Bundle::Layout::Symlink" ? bundle.metadata.id : bundle.id;
  let type = {
    "Bundle::Catalog::Movie": "catalog/movies",
    "Bundle::Catalog::Event": "events",
    "Bundle::Catalog::Series": "catalog/series",
    "Bundle::Catalog::Season": "catalog/seasons",
    "Bundle::Catalog::Episode": "catalog/episodes",
    "Bundle::Catalog::LimitedSeries": "catalog/limited_series",

    "Bundle::Collection::Manual": "collections/manual",
    "Bundle::Collection::Categories": "collections/categories",
    "Bundle::Collection::Genre": "collections/genres",
    "Bundle::Collection::Keywords": "collections/keywords",
    "Bundle::Collection::Actors": "collections/actors",
    "Bundle::Collection::Directors": "collections/directors",

    "Bundle::Playlist::Manual": "playlists/manual",
    "Bundle::Playlist::Search": "playlists/search",
    "Bundle::Playlist::Feed": "playlists/feeds",
  }[key];

  let relativePath = `${key_id}`;
  if (type) {
    relativePath = `${type}/${relativePath}`;
  }

  return relativePath;
};

export const getFolderName = (folder) => {
  if (!folder) return null;
  if (!folder.bundle.sync)
    return folder.bundle.title
  return folder.bundle?.appItem?.item?.title
}

export const getBundleTypePath = (type, isEdit) => {
  const name = getBundleType(type);
  const path = {
    Event: 'content/events',
    Movie: 'content/catalog/movies',
    Series: isEdit ? 'content/catalog/series' : 'content/catalog/seasons',
    LimitedSeries: isEdit ? 'content/catalog/limited_series' : 'content/catalog/limited_episodes',
  }
  return path[name];
}

export const getBundleData = (af) => {
  if (!af.menus) {
    return {}
  }
  const image = af.menus[0]?.item && af.menus[0]?.item.images ? af.menus[0].item.images[0] : null;
  const title = af.appFeed?.feedName || af.menus[0]?.title || af.menus[0]?.item?.title || af.menus[0]?.liveChannel?.title;
  const duration = af.menus[0]?.duration || af.menus[0]?.item?.duration;
  const link = af.appFeed?.url || af.menus[0]?.url || (af.menus[0]?.item?.media && af.menus[0]?.item?.media[0]?.url);
  const label = af.metadata?.catalog ? af.type.split("::")[2] : null;

  return ({
    image,
    title,
    duration,
    link,
    label,
  });
}

export const generateMenusFromLanguages = (titleLang, menus, form) => {
  const updatedMenus = []
  Object.keys(titleLang).forEach(ln => {
    const langMenu = menus?.find(m => m.language === ln) || {};
    updatedMenus.push({
      ...{id: langMenu.id},
      ...form,
      title: titleLang[ln],
      language: ln,
      isActive: true
    })
  })
  return updatedMenus;
}
