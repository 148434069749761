import { gql } from "@apollo/client";
import { BACKGROUND_FRAGMENT } from "./design";

export const BUNDLE_FRAGMENT = gql`
  fragment BundleSchema on Bundle {
    __typename
    id
    type
    metadata
    menus {
      id
      isActive
      isPublishable
      title
      subtitle
      description
      language
      url
      track
      duration
      horizontalImage
      horizontalThumbnailUrl
      horizontalThumbnailAltText
      verticalImage
      verticalThumbnailUrl
      verticalThumbnailAltText
      squareImage
      squareThumbnailUrl
      squareThumbnailAltText
      icon
      mobileLayout
      tvLayout
      mobileHideTitle
      tvHideTitle
      bannerGroup {
        id
      }
      mobileThumbnailOrientation
      tvThumbnailOrientation
      tvBackground {
        ...BackgroundSchema
      }
      mobileLogoUrl
      tvLogoUrl
      mobileLogoAltText
      tvLogoAltText
      keywords
      item {
        id
        title
        subtitle
        summary
        duration
        images
        media
        tracks
        keywords
        metadata
      }
      feed {
        id
        name
        feedName
      }
      appItem {
        id
      }
      localeIds
      interstitialBackground {
        ...BackgroundSchema
      }
      liveChannel {
        title
      }
      showPreviewAd
      previewType
      previewAudio
    }
    ancestors {
      id
      type
      menus {
        title
        item {
          title
        }
      }
    }
    appFeed {
      id
      name
      feedName
      url
      poll
      lastPolledAt
      language
    }
    liveChannel {
      id
      defaultSchedule {
        id
      }
    }
    updatedAt
  }
  ${BACKGROUND_FRAGMENT}
`;

export const GET_BUNDLES = gql`
  query bundles($workspaceId: ID! $appId: ID! $filters: [String!] $collectionId: ID $limit: Int $offset: Int) {
    bundles(workspaceId: $workspaceId appId: $appId filters: $filters collectionId: $collectionId limit: $limit offset: $offset) {
      ...BundleSchema
    }
  }
  ${BUNDLE_FRAGMENT}
`;

export const DELETE_BUNDLE = gql`
  mutation deleteBundle($workspaceId: ID! $appId: ID! $id: ID!) {
    deleteBundle(input: { workspaceId: $workspaceId appId: $appId id: $id }) {
      bundle {
        id
      }
    }
  }
`;

export const DELETE_BUNDLE_COLLECTION = gql`
  mutation deleteBundleCollection($workspaceId: ID! $appId: ID! $collectionId: ID! $id: ID!) {
    deleteBundleCollection(input: { workspaceId: $workspaceId appId: $appId collectionId: $collectionId id: $id }) {
      bundle {
        id
      }
    }
  }
`;

export const GET_PAGINATED_BUNDLES = gql`
  query paginatedBundles($workspaceId: ID! $appId: ID! $offset: Int! $limit: Int! $filterModel: String $filters: [String!] $collectionId: ID) {
    paginatedBundles(workspaceId: $workspaceId appId: $appId offset: $offset limit: $limit filterModel: $filterModel filters: $filters collectionId: $collectionId) {
      count
      bundles {
        ...BundleSchema
      }
    }
  }
  ${BUNDLE_FRAGMENT}
`;

export const CREATE_BUNDLE = gql`
  mutation createBundle(
    $workspaceId: ID!
    $appId: ID!
    $bundleFields: BundleInput!
  ) {
    createBundle(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bundleFields: $bundleFields
      }
    ) {
      bundle {
        ...BundleSchema
      }
    }
  }
  ${BUNDLE_FRAGMENT}
`;

export const UPDATE_BUNDLE = gql`
  mutation updateBundle(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
    $bundleFields: BundleInput!
  ) {
    updateBundle(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
        bundleFields: $bundleFields
      }
    ) {
      bundle {
        ...BundleSchema
      }
    }
  }
  ${BUNDLE_FRAGMENT}
`;

export const GET_BUNDLE = gql`
  query bundle($workspaceId: ID! $appId: ID! $id: ID!) {
    bundle(workspaceId: $workspaceId appId: $appId id: $id) {
      ...BundleSchema
    }
  }
  ${BUNDLE_FRAGMENT}
`;

export const REARRANGE_BUNDLE_COLLECTIONS = gql`
  mutation rearrangeBundleCollections($workspaceId: ID! $appId: ID! $collectionId: ID! $ids: [ID!]!) {
    rearrangeBundleCollections(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        collectionId: $collectionId
        ids: $ids
      }
    ) {
      success
    }
  }
`;

export const REARRANGE_BUNDLES = gql`
  mutation rearrangeBundles($workspaceId: ID! $appId: ID! $ids: [ID!]!) {
    rearrangeBundles(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        ids: $ids
      }
    ) {
      success
    }
  }
`;

export const DUPLICATE_MENU = gql`
  mutation duplicateMenu(
    $workspaceId: ID!
    $appId: ID!
    $bundleId: ID!
    $id: ID!
  ) {
    duplicateMenu(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bundleId: $bundleId
        id: $id
      }
    ) {
      bundle {
        ...BundleSchema
      }
    }
  }
  ${BUNDLE_FRAGMENT}
`;
