import { useQuery } from '@apollo/client';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import React from 'react';

import { GET_CURRENT_APP, GET_CURRENT_APP_PROPS } from '../../../../api';
import List from './List';
import New from './New';
import Edit from './Edit';
import BundleList from './bundle/List';

export default function Tiers(props) {
  const { path } = useRouteMatch();

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const tabProps = { ...props, appProps, app };

  return (
    <Switch>
      <Route exact path={`${path}/purchases/new`}>
        <New {...tabProps} name="Purchases" filter="Purchase" monetizationPath="transaction/purchases"/>
      </Route>
      <Route exact path={`${path}/purchases/edit/:id`}>
        <Edit {...tabProps} name="Purchases" filter="Purchase"  monetizationPath="transaction/purchases"/>
      </Route>
      <Route exact path={`${path}/purchases/:tierId/list`}>
        <BundleList {...tabProps} name="Purchases" filter="Purchase"  monetizationPath="transaction/purchases"/>
      </Route>
      <Route path={`${path}/purchases`}>
        <List {...tabProps} name="Purchases" filter="Purchase"  monetizationPath="transaction/purchases"/>
      </Route>

      <Route exact path={`${path}/rentals/new`}>
        <New {...tabProps} name="Rentals" filter="Rental"  monetizationPath="transaction/rentals"/>
      </Route>
      <Route exact path={`${path}/rentals/edit/:id`}>
        <Edit {...tabProps} name="Rentals" filter="Rental"   monetizationPath="transaction/rentals"/>
      </Route>
      <Route exact path={`${path}/rentals/:tierId/list`}>
        <BundleList {...tabProps} name="Rentals" filter="Rental"   monetizationPath="transaction/rentals"/>
      </Route>
      <Route path={`${path}/rentals`}>
        <List {...tabProps} name="Rentals" filter="Rental"   monetizationPath="transaction/rentals"/>
      </Route>

      <Route exact path={`${path}/events/new`}>
        <New {...tabProps} name="Events" filter="Event"  monetizationPath="transaction/events" />
      </Route>
      <Route exact path={`${path}/events/edit/:id`}>
        <Edit {...tabProps} name="Events" filter="Event" monetizationPath="transaction/events"/>
      </Route>
      <Route exact path={`${path}/events/:tierId/list`}>
        <BundleList {...tabProps} name="Events" filter="Event" monetizationPath="transaction/events"/>
      </Route>
      <Route path={`${path}/events`}>
        <List {...tabProps} name="Events" filter="Event" monetizationPath="transaction/events"/>
      </Route>
    </Switch>
  );
}
