import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_APP } from "api";
import Form from "./Form";

export default function Edit(props) {
  const { appId } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      appId: appId,
    }
  });

  return (
    data?.app
      ? <Form {...props} app={data.app} locale={{}}/>
      : null
  );
}
