import { useQuery } from '@apollo/client';
import { GET_CURRENT_APP } from 'api';
import { getAllLanguagesFromLocale } from 'helpers/langUtils';
import React from 'react';

export function withLocaleLang(Component) {
	return (props) => {
		const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
		const locales = app.locales;
		const allLanguages = getAllLanguagesFromLocale(locales);
		const isMultiLangOrRegion = locales?.length >= 2 || allLanguages.length >= 2;
		const isMultiLocale = locales?.length >=2;
		return <Component {...props} isMultiLangOrRegion={isMultiLangOrRegion} isMultiLocale={isMultiLocale} />;
	};
}
