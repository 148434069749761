import { gql } from '@apollo/client';
import { PRICE_FRAGMENT } from './prices';

export const TIER_FRAGMENT = gql`
  fragment TierSchema on Tier {
    __typename
    id
    type
    title
    titleEs
    titleFr
    titleGe
    titleIt
    titlePt
    titleNl
    titleEl
    metadata
    isActive
    sortOrder
    localeIds
    thirdPartyId
    price {
      ...PriceSchema
    }
    createdAt
  }
  ${PRICE_FRAGMENT}
`

export const TIER_WITH_BUNDLE_TIER_FRAGMENT = gql`
  fragment TierWithBundleTierSchema on Tier {
    ...TierSchema
    bundleTiers {
      id
      thirdPartyId
      bundle {
        id
        type
        menus {
          title
          item {
            title
          }
        }
        appFeed {
          feedName
        }
      }
    }
  }
  ${TIER_FRAGMENT}
`;

export const GET_TIERS = gql`
  query tiers($workspaceId: ID! $appId: ID!) {
    tiers(workspaceId: $workspaceId appId: $appId) {
      ...TierSchema
    }
  }
  ${TIER_FRAGMENT}
`

export const GET_TIER = gql`
  query tier($workspaceId: ID! $appId: ID! $id: ID!) {
    tier(workspaceId: $workspaceId appId: $appId id: $id) {
      ...TierSchema
    }
  }
  ${TIER_FRAGMENT}
`

export const CREATE_TIER = gql`
  mutation createTier($workspaceId: ID! $appId: ID! $tierFields: TierInput!) {
    createTier(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        tierFields: $tierFields
      }
    ) {
      tier {
        ...TierSchema
      }
    }
  }
  ${TIER_FRAGMENT}
`

export const UPDATE_TIER = gql`
  mutation updateTier($workspaceId: ID! $appId: ID! $tierId: ID! $tierFields: TierInput!) {
    updateTier(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        tierId: $tierId
        tierFields: $tierFields
      }
    ) {
      tier {
        ...TierSchema
      }
    }
  }
  ${TIER_FRAGMENT}
`

export const DELETE_TIER = gql`
  mutation deleteTier($workspaceId: ID! $appId: ID! $tierId: ID!) {
    deleteTier(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        tierId: $tierId
      }
    ) {
      tier {
        ...TierSchema
      }
    }
  }
  ${TIER_FRAGMENT}
`

export const GET_BUNDLE_TIERS = gql`
  query tier($workspaceId: ID! $appId: ID! $id: ID!) {
    tier(workspaceId: $workspaceId appId: $appId id: $id) {
      ...TierWithBundleTierSchema
    }
  }
  ${TIER_WITH_BUNDLE_TIER_FRAGMENT}
`;

export const REARRANGE_TIERS = gql`
  mutation rearrangeTiers(
    $workspaceId: ID!
    $appId: ID!
    $tierIds: [ID!]!
  ) {
    rearrangeTiers(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        tierIds: $tierIds
      }
    ) {
      success
    }
  }
`;
