import React from "react";
import {
  Grid,
  Typography,
  Link
} from '@mui/material';

import Input from "components/atoms/Input/BaseInput";

export default function AdLinks(props) {
  const { updateState, currentFormKeys, currentStateValues } = props;

  const hook_item = (channel_obj, key) => (
    <Grid key={key} item xs={6}>
      <Grid xs={12} sm={9}>
        <Input label={channel_obj.display_name}
          // error={errorHandler("name")}
          name={channel_obj.display_name}
          value={currentStateValues.adLinks.find(o => o.storeDeviceName == channel_obj.name)?.hook || ""}
          onChange={(val) => updateState('adLink', val, channel_obj)}
          placeholder={channel_obj.placeholder}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  )

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography variant="h6" gutterBottom>
          Choose channels
          </Typography>
        <Typography variant="body2" gutterBottom>
          Enter the
            {currentFormKeys.name == "spotx" ? " Spotx Channel ID or URL " : " ad tag URL "}
          {currentFormKeys.name == "google" ? <span>from <Link href="https://support.google.com/admanager/answer/177207?hl=en" target="_blank">Google Ad Manager </Link></span> : null}
            to serve ads to your channels.
          </Typography>
          {currentFormKeys.name == "google" ? <Typography variant="body2" color="textSecondary">
          <em>*Make sure that you do not pass description_url, correlator, scor, rdid, idtype, is_lat as key value pair in Ad tag URL as apps will suffix these automatically with every request.</em>
        </Typography> : null}
      </Grid>
      {
        currentFormKeys?.store_devices_obj.map((channel_obj, key) => (
          hook_item(channel_obj, key)
        ))
      }
    </Grid>
  )
}
