import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { withRedirect } from "components/containers/Redirect";
import _ from "underscore";
import { useLocation } from "react-router-dom";
import { GET_CURRENT_APP_PROPS, UPDATE_APP, ME } from "api";
import ContentLayout from "screens/Layout/ContentLayout";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import { BooleanSelect } from "components/atoms/Input/Select";
import Countries from "components/atoms/Input/Countries";
import Label from "components/atoms/Text/Label";
import MuiDivider from '@mui/material/Divider';
import PaperGroup from "components/molecules/Form/PaperGroup";
import Error from "components/atoms/Error";
import { isSuperUser } from "helpers/platformUtils";

function Form(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { app, locale } = props;
  const { pathname } = useLocation();

  const [ name, setName ] = React.useState(app?.name);
  const [ updatedLocale, setUpdatedLocale ] = React.useState(locale);
  const [ localeError, setLocaleError ] = React.useState(null);
  const [ isFormChanged, setIsFormChanged] = React.useState(false);
  const [ disableForm, setDisableForm] = React.useState(false);

  const { data } = useQuery(ME);
  const user = data?.me;
  const isSuperAdmin = isSuperUser(user);
  const listPath = `${pathname.split('/locales')[0]}/locales`;

  const [ updateApp, {loading: updatingApp} ] = useMutation(UPDATE_APP, {
    update(cache, { data: { updateApp } }) {
      cache.modify({
        id: cache.identify(updateApp.app),
        fields: {
          locales(cachedLocale) {
            return (updateApp.app.locales);
          }
        },
      });
    },
    onCompleted(data) {
      handleSuccess(data);
    },
    onError(data) { handleError(data); }
  });

  const handleSuccess = (data) => {
    setDisableForm(false)
    props.savedSnack.launch();
    props.redirect.set(listPath);
  };

  const handleError = (data) => {
    console.log(data);
    setDisableForm(false)
  }

  const submit = () => {
    let locales = app?.locales.map((l) => _.omit(l, "__typename"));
    if (updatedLocale.id) {
      locales = locales.map(l => l.id === updatedLocale.id ? {id: l, ...updatedLocale} : l)
    } else {
      locales = [...locales, updatedLocale]
    }
    if (isValid(locales)) {
      updateApp({
        variables: {
          workspaceId: appProps.workspaceId,
          id: app?.id,
          appFields: {
            localesAttributes: locales,
          },
        }
      })
    }
  }

  const cancel = () => {
    props.redirect.set(listPath);
  };

  const isValid = (locales) => {
    if (!Boolean(name)) {
      return false;
    }

    const {isValid, message} = checkLocalesValidation(locales);
    setLocaleError(message);
    if (!isValid) return false;

    return true;
  };

  const isLoading = updatingApp;

  return (
    <ContentLayout title="Locale">
      <FormLayout
        isLoading={isLoading}
        disableSave={isLoading}
        submit={submit}
        cancel={cancel}
        breadcrumbs={[
          { name: "Brands", link: listPath },
          { name: app?.name, link: `${pathname.split('/locales')[0]}/locales`},
          { name: locale?.name || "Unnamed Locale" },
        ]}
        isChanged={isFormChanged}
        betterForm
        disableCancel={isLoading}
      >
        <PaperGroup>
          {localeError && <Error>{localeError}</Error>}
          <Locale
            locale={locale}
            setLocale={setUpdatedLocale}
            disabled={!isSuperAdmin || disableForm}
            setIsFormChanged={setIsFormChanged}
          />
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  )
}

export default withRedirect(Form);

const Locale = (props) => {
  const { locale, setLocale, disabled, setIsFormChanged } = props;

  const id = locale.id;
  const [ name, setName ] = React.useState(locale.name ?? "");
  const languages = id ? locale.languages : ["en"];
  const [ countries, setCountries ] = React.useState(locale.countries ?? { all: true });
  const [ localeCode, setLocaleCode ] = React.useState(locale.regionCode ?? "");
  const [ appUsageTracking, setAppUsageTracking ] = React.useState(locale.appUsageTracking);

  React.useEffect(() => {
    setLocale({
      id,
      name,
      languages,
      countries,
      appUsageTracking,
      regionCode: localeCode
    });
  }, [id, name, countries, localeCode, appUsageTracking]);

  React.useEffect(() => {
    if (!localeCode && name.length > 3) {
      setLocaleCode(name.substring(0,3).toUpperCase());
    }
  }, [name])

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [name, localeCode, appUsageTracking])

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Input
        label="Name"
        defaultValue={name}
        onChange={setName}
        required
        disabled={disabled}
      />
      <Input
        label="Region code"
        value={localeCode}
        onChange={val => setLocaleCode(val.toUpperCase())}
        required
        disabled={disabled}
        inputProps={{maxLength: 3}}
      />
      <BooleanSelect
        label="App usage tracking"
        value={Boolean(appUsageTracking)}
        onChange={setAppUsageTracking}
        required
      />

      <Label>Regions</Label>
      <MuiDivider />
      <Countries countryState={countries} setCountryState={setCountries} disabled={disabled} setIsFormChanged={setIsFormChanged}/>
    </div>
  );
};

const checkLocalesValidation = (locales) => {
  let isValid = true;
  let message = "";
  let noCountrySelectedLocale = false;
  const re = new RegExp("^[a-zA-Z0-9]*$");

  if (locales && locales.length > 0) {
    let cmap = {};
    let regionCodeCount = {}
    locales.forEach(l => {
      let noCountry = true;
      regionCodeCount[l.regionCode] = (regionCodeCount[l.regionCode] || 0) + 1;
      for (let country in l.countries) {
        if (!cmap[country]) {
          cmap[country] = 0
        }
        if (l.countries[country]) {
          cmap[country] = cmap[country] + 1
          noCountry = false;
        }
      }
      if (noCountry) {
        isValid = false;
        message = "Locale should have regions. It seems no region is selected in one of the locales. Please address this issue."
        noCountrySelectedLocale = true;
      }
      if (!l.name) {
        isValid = false;
        message = "Locale name is mandatory."
      }
      if (!l.regionCode) {
        isValid = false;
        message = "Locale code is mandatory."
      }
      if (l.regionCode.length < 2 || !re.test(l.regionCode)) {
        isValid = false;
        message = "Locale code must be atleast 2 character long and can contains only alphabets and numbers."
      }
      if (regionCodeCount[l.regionCode] >= 2) {
        isValid = false;
        message = "Locale code must be unique."
      }
    })
    if (Object.values(cmap).filter((f) => f > 1).length > 0) {
      message = "Locales should have distinct regions. It seems your locales have overlapping regions. Please address this issue or remove unwanted locales."
      isValid = false;
    }
    const allLocale = locales.find((l) => l.countries.all === true);
    if (allLocale) {
      if (locales.length > 1 && !noCountrySelectedLocale) {
        message = "Locales should have distinct regions. It seems your locales have overlapping regions. Please address this issue or remove unwanted locales."
        isValid = false;
      }
    }
  }
  return {isValid, message}
}
