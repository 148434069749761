import { gql } from '@apollo/client';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { BlobUpload } from '@rails/activestorage/src/blob_upload';

export const UPLOAD_FILE = gql`
  mutation(
    $filename: String!
    $byte_size: Int!
    $checksum: String!
    $content_type: String!
  ) {
    createDirectUpload(
      input: {
        uploadFields: {
          filename: $filename
          byteSize: $byte_size
          checksum: $checksum
          contentType: $content_type
        }
      }
    ) {
      directUpload {
        url
        headers
        blobId
        signedBlobId
      }
    }
  }
`;

export const GET_BLOB_DATA = gql`
  query getBlobData($id: ID!) {
    getBlobData(id: $id) {
      url
      contentType
      metadata
    }
  }
`;

const calculateChecksum = (file) => {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
};

const getFileMetadata = (file) => {
  return new Promise((resolve) => {
    calculateChecksum(file).then((checksum) => {
      resolve({
        checksum,
        filename: file.name || `photo.${file.type.split("/")[1]}`,
        content_type: file.type,
        byte_size: file.size,
      });
    });
  });
};

const directUpload = (url, headers, file, onprogress) => {
  const upload = new BlobUpload({ file, directUploadData: { url, headers } });
  if (onprogress) {
    upload.xhr.upload.onprogress = function (event) {
      onprogress(Math.round((event.loaded / event.total) * 100));
    };
  }
  return new Promise((resolve, reject) => {
    upload.create((error) => {
      if (error) {
        console.log(error);
        reject(error);
      } else {
        resolve();
      }
    });
  });
};

export function getCdnUrl(originUrl) {
  return (originUrl ? originUrl.replace("s3.amazonaws.com/", "").split("?")[0] : null);
}

export function upload(client, file, handleResponse, onprogress) {
  getFileMetadata(file).then(input => {
    client
      .mutate({
        mutation: UPLOAD_FILE,
        variables: input,
      })
      .then(response => {
        let {
          url,
          headers,
          blobId,
          signedBlobId
        } = response.data.createDirectUpload.directUpload;
        return directUpload(url, JSON.parse(headers), file, onprogress).then(
          () => {
            client
              .query({
                query: GET_BLOB_DATA,
                variables: { id: blobId },
              })
              .then(res => {
                handleResponse({
                  success: true,
                  signedBlobId: signedBlobId,
                  ...res.data.getBlobData,
                  url: getCdnUrl(res.data.getBlobData.url),
                });
              });
          }
        );
      })
      .catch(e => {
        handleResponse({ success: false, error: e.message });
      });
  });
}
