import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import List from "./List";

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Contents(props) {
  const { path } = useRouteMatch();
  const { savedSnack, category, token } = props;

  const commonProps = { savedSnack, category, token };
  return (
    <Switch>
      <Route path={`${path}/:collectionId?`}>
        <List {...commonProps}/>
      </Route>
    </Switch>
  );
}

export default withSavedSnack(Contents);
