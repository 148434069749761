import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import React from 'react';

import { GET_DESIGNS, UPDATE_DESIGN, UPDATE_DESIGNS, GET_CURRENT_APP, GET_CURRENT_APP_PROPS } from '../../../../api';

export default function Design(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data } = useQuery(GET_DESIGNS, { variables: appProps });

  return (
    data?.designs
      ? props.screen({ app, designs: data.designs })
      : null
  );
}

export function withDesign(Component) {
  return (props) => {
    return (
      <Design screen={(data) => <Component {...props} {...data} />} />
    );
  }
}

export function withDesignSubmit(Component) {
  return (props) => {
    const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
    const { stateForm, stateDesign, onError, savedSnack, designs, disableform, formchange} = props;
    const {textLocales, ...formValues} = stateForm;


    const [ updateDesigns,{loading} ] = useMutation(UPDATE_DESIGNS, {
      onCompleted(data) {
        disableform(false);
        formchange(false);
        props.savedSnack && props.savedSnack.launch();
      },
      onError(data) {
        console.log(data);
        savedSnack.launch(`${data.message}`, {type: 'error', anchorOrigin:{
          vertical: 'top',
          horizontal: 'center',
        }});
        disableform(false);
        onError && onError(data);
      },
    });

    const onSubmit = () => {
      if (props.notValidate && props.notValidate()) {
        savedSnack.launch("Missing fields!", {type: 'error', anchorOrigin:{
          vertical: 'top',
          horizontal: 'center',
        }});
        return null;
      }

      const allDesign = designs.map((design, i) => {
        const locale = design.locale.id;
        const lang = design.language;
        const idObj = design.id ? {id: design.id}: {};
        const localeValuesObj = textLocales ? textLocales[locale][lang] : {};
        const saveBackground = formValues.savedBackgroundAttributes ? {savedBackgroundAttributes: {...formValues.savedBackgroundAttributes, id: design.savedBackground.id}} : {};
        const onboardBackground = formValues.onboardingScreenBackgroundAttributes ? {onboardingScreenBackgroundAttributes: {...formValues.onboardingScreenBackgroundAttributes, id: design.onboardingScreenBackground.id}} : {}
        const loginBackground = formValues.loginBackgroundAttributes ? {loginBackgroundAttributes: {...formValues.loginBackgroundAttributes, id: design.loginBackground.id}} : {}
        const fontAttributes = i===0? {fontsAttributes: formValues.fontsAttributes} : {}
        const newFormValues = _.omit(formValues, "fontsAttributes");
        return {
          ...idObj,
          ...newFormValues,
          ...localeValuesObj,
          ...saveBackground,
          ...onboardBackground,
          ...loginBackground,
          ...fontAttributes
        }
      })
      // console.log(allDesign);
      disableform(true);
      updateDesigns({
        variables: {
          ...appProps,
          designFields: allDesign,
        }
      });
    }

    return <Component {...props} submit={onSubmit} isLoading={loading}/>;
  }
}
