import { gql } from "@apollo/client"

export const BANNER_FRAGMENT = gql`
  fragment BannerSchema on Banner {
    id
    name
    isActive
    mobileImageUrl
    tvImageUrl
    altText
    mobileUrl
    mobileUrlType
  }
`

export const GET_BANNERS = gql`
  query banners($workspaceId: ID! $appId: ID! $bannerGroupId: ID!) {
    banners(
      workspaceId: $workspaceId
      appId: $appId
      bannerGroupId: $bannerGroupId
    ) {
      id
      name
      isActive
    }
  }
`

export const GET_BANNER = gql`
  query banner($workspaceId: ID! $appId: ID! $bannerGroupId: ID! $id: ID!) {
    banner(
      workspaceId: $workspaceId
      appId: $appId
      bannerGroupId: $bannerGroupId
      id: $id
    ) {
      ...BannerSchema
    }
  }
  ${BANNER_FRAGMENT}
`

export const CREATE_BANNER = gql`
  mutation createBanner(
    $workspaceId: ID!
    $appId: ID!
    $bannerGroupId: ID!
    $bannerFields: BannerInput!
  ) {
    createBanner(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bannerGroupId: $bannerGroupId
        bannerFields: $bannerFields
      }
    ) {
      banner {
        ...BannerSchema
      }
    }
  }
  ${BANNER_FRAGMENT}
`

export const UPDATE_BANNER = gql`
  mutation updateBanner(
    $workspaceId: ID!
    $appId: ID!
    $bannerGroupId: ID!
    $bannerId: ID!
    $bannerFields: BannerInput!
  ) {
    updateBanner(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bannerGroupId: $bannerGroupId
        bannerId: $bannerId
        bannerFields: $bannerFields
      }
    ) {
      banner {
        ...BannerSchema
      }
    }
  }
  ${BANNER_FRAGMENT}
`

export const DELETE_BANNER = gql`
  mutation deleteBanner(
    $workspaceId: ID!
    $appId: ID!
    $bannerGroupId: ID!
    $bannerId: ID!
  ) {
    deleteBanner(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bannerGroupId: $bannerGroupId
        bannerId: $bannerId
      }
    ) {
      banner {
        id
        name
        isActive
      }
    }
  }
`

export const REARRANGE_BANNERS = gql`
  mutation rearrangeBanners(
    $workspaceId: ID!
    $appId: ID!
    $bannerGroupId: ID!
    $bannerIds: [ID!]!
  ) {
    rearrangeBanners(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        bannerGroupId: $bannerGroupId
        bannerIds: $bannerIds
      }
    ) {
      success
    }
  }
`
