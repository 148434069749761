export default function isValid(platformDetails, appStores) {
    const { metadata, assetUrls, storeId } = platformDetails;
    const storeDetails = appStores.find(s => s.id === storeId);
    const {metadata: storeMetadata} = storeDetails;

    if (!metadata || !storeMetadata || !assetUrls ) return false;

    if (!storeMetadata.app_store_name) return false;
    if (!storeMetadata.description) return false;
    if (!storeMetadata.category) return false;
    if (!storeMetadata.rating) return false;
    if (!storeMetadata.keywords) return false;
    if (!storeMetadata.seller_name) return false;
    if (!storeMetadata.support_email) return false;
    if (!storeMetadata.seller_homepage) return false;
    if (!storeMetadata.representative) return false;
    if (!storeMetadata.address) return false;
    if (!storeMetadata.phone_number) return false;

    if (!storeMetadata.username) return false;
    if (!storeMetadata.password) return false;

    if (!metadata.ga_tracking_id) return false;

    if (!assetUrls.covers || assetUrls.covers.length < 4) return false;
    if (!assetUrls.app_icon) return false;
    if (!assetUrls.background_image) return false;
    if (!assetUrls.logo) return false;

    return true;
}