export function fullUrl(subdomain) {
  return (
    `https://${subdomain}${process.env.NODE_ENV === "production" ? "" : `.${process.env.NODE_ENV}`}.maz.tv`
  );
}

export function isSuperUser(me) {
  return (
    me.email.includes("mazdigital.com") ||
    me.email.includes("mazsystems.com") ||
    me.email.includes("zype.com")
  );
}