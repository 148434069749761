import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Link,
  Box
} from '@mui/material';

import Input from "components/atoms/Input/BaseInput";
import PaperGroup from "components/molecules/Form/PaperGroup";

const useStyles = makeStyles((theme) => ({
  red: {
    color: 'red'
  },
  content_id: {
    color: 'red',
    border: '1px red solid',
    borderRadius: '8px',
    padding: '5px',
    backgroundColor: 'white'
  }
}));

export default function VID(props) {
  const { updateState, currentFormKeys, currentStateValues } = props;
  const classes = useStyles();

  return (
    currentFormKeys.vid ?
    <PaperGroup>
      <Grid container >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Target ads
          </Typography>
          <Typography variant="body2" gutterBottom paragraph>
            In order to <span> </span>
            <Link
              href="https://support.google.com/admanager/answer/10678356?visit_id=637619523740024577-2669318216&rd=2#cmsid-vid"
              target="_blank"
            >
              target ads using Google Ad Manager
            </Link>
               , MAZ must provide a content
            ID making ad requests. This is commonly known as a <span className={classes.red}>vid</span> parameter.
          </Typography>
          <Typography variant="body2" gutterBottom paragraph>
            Please choose the key in your MRSS feed you want to use for your <span className={classes.red}>vid</span>. It may
            be called something like <span className={classes.red}>contentID</span> or <span className={classes.red}>guid</span>.
            </Typography>
        </Grid>
        <Grid xs={12}>
          <Box bgcolor="#f2f2ed" p={4}>
            <Typography variant="body2" gutterBottom align="center" paragraph>
              HINT: You will know it's the correct key if the values match those of the
              <span className={classes.red}> dfpvideo:contentID</span> in your Google Ad Manager feed.
            </Typography>
            <Typography variant="body2" gutterBottom align="center" paragraph>
              <span className={classes.red}>{"<dfvvideo:contentId>"}</span>
              2324ertw4er49
              <span className={classes.red}>{"</dfvvideo:contentId>"}</span>
            </Typography>
            <Typography variant="body2" gutterBottom align="center" paragraph>
              {"<"}<span className={classes.content_id}>{"content_id"}</span>{">"}
              2324ertw4er49
              <span className={classes.red}>{"</content_id>"}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid xs={12}>
          <Input
            label={currentFormKeys.vid.name}
            // error={errorHandler("name")}
            name={currentFormKeys.vid.name}
            value={currentStateValues.vid}
            onChange={(val) => updateState("vid", val)}
            placeholder={currentFormKeys.vid.placeholder}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    </PaperGroup> : null
  )
}
