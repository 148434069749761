import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import urlJoin from "url-join";
import moment from "moment";

import {
  GET_CURRENT_APP_PROPS,
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION
} from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import ContentLayout from "screens/Layout/ContentLayout";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 230,
    sort:"asc",
    cellRenderer: "titleCellRenderer",
  },
  {
    field: "schulded_for",
    headerName: "Scheduled For",
    width: 220,
    type: "dateTime",
    valueGetter: (params) => params.data.scheduleFor ? moment(params.data.scheduleFor).format('LLL') : null
  },
  {
    field: "total_devices",
    headerName: "Device Count",
    width: 140,
    cellRenderer: "countCellRenderer",
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    cellRenderer: "statusCellRenderer",
    sortable:false,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    sortable:false,
    flex: 1,
  },
];

const CountCellRenderer = (params) => {
  return params.data.totalDevices
};

const StatusCellRenderer = (params) => {
  return params.data.status
};

function Notifications(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_NOTIFICATIONS, { variables: appProps });
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);

  const onEdit = (notification) => {
    history.push(urlJoin(pathname, `edit/${notification.id}`));
  };

  const onDelete = (notification) => {
    setDeleteConfirmation(notification);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(null);
  };

  
  const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
    update(cache, { data: { deleteNotification } }) {
      cache.modify({
        fields: {
          notifications(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteNotification.notification.id !== readField("id", ref)
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
    },
    onError(data) {
      console.log(data);
    },
  });

  const deleteNotificationApi = (id) => {
    closeDeleteConfirmation();
    deleteNotification({
      variables: {
        ...appProps,
        notificationId: id,
      },
    });
  };

  return (  
    <ContentLayout
      title="Notifications"
      fab={{onClick: () => history.push(urlJoin(pathname, "new"))}}
    >
      <FormLayout>
        <DataGrid
          frameworks={{
            countCellRenderer: CountCellRenderer,
            statusCellRenderer: StatusCellRenderer
          }}
          rowData={
            data?.notifications
              ? data.notifications.map((s) => ({
                  ...s,
                  title: s.name,
                  actions: {
                    onEdit,
                    onDelete: s.status === 'sent' ? null : onDelete,
                  },
                }))
              : []
          }
          columnDefs={columns}
          accentedSort={true}
          defaultColDef={{ sortable: true }}
          immutableData={true}
          getRowNodeId={(data) => data.id}
        />
        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => {
            deleteNotificationApi(deleteConfirmation.id);
          }}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default Notifications;
