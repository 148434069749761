import { useQuery } from "@apollo/client";
import React from 'react';
import { useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_LIVE_STREAM } from "api";
import Form from './Form';

export default function Edit(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { id } = useParams();
  const { data } = useQuery(GET_LIVE_STREAM, {
    variables: { ...appProps, id: id },
  });

  return (
    data?.liveStream
      ? <Form {...props} liveStream={data?.liveStream} />
      : null
  );
}
