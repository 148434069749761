export default function isValid(platformDetails, appStores) {
    const { metadata, assetUrls, storeId } = platformDetails;
    const storeDetails = appStores.find(s => s.id === storeId);
    const {metadata: storeMetadata} = storeDetails;

    if (!metadata || !storeMetadata || !assetUrls ) return false;

    if (!storeMetadata.app_store_name) return false;
    if (!storeMetadata.category) return false;
    if (!storeMetadata.description) return false;

    if (!storeMetadata.username) return false;
    if (!storeMetadata.password) return false;
    if (!storeMetadata.store_id) return false;

    if (!metadata.ga_tracking_id) return false;

    if (!assetUrls.covers || !assetUrls.covers.length) return false;
    if (!assetUrls.large_app_icon) return false;
    if (!assetUrls.small_app_icon) return false;
    if (!assetUrls.poster) return false;

    return true;
}