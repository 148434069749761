import React from 'react';
import Layout from './Layout';
import ColorPicker from 'components/atoms/Input/ColorPicker';
import { withDesign } from './Design';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';

function Menus(props) {
  const { simulator, designs } = props;
  const design = simulator.design;

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);

  const [ backgroundColor, setBackgroundColor] = React.useState(design.standardMenuBackgroundColor);
  const [ fontColor, setFontColor ] = React.useState(design.standardMenuFontColor);
  const [ itemFontColor, setItemFontColor ] = React.useState(design.standardMenuItemFontColor);
  const [ itemSeparatorColor, setItemSeparatorColor ] = React.useState(design.standardMenuItemSeperatorColor);
  const [ detailFontColor, setDetailFontColor ] = React.useState(design.standardMenuDetailFontColor);
  const [ selectedItemFontColor, setSelectedItemFontColor ] = React.useState(design.standardMenuSelectedItemFontColor);
  const [ selectedItemBackgroundColor, setSelectedItemBackgroundColor ] = React.useState(design.standardMenuSelectedItemBackgroundColor);


  React.useEffect(() => {
    setIsFormChanged(true);
  }, [backgroundColor,fontColor,itemFontColor,itemSeparatorColor,detailFontColor,selectedItemFontColor,selectedItemBackgroundColor])

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  const cancel = () => {
    setBackgroundColor(design.standardMenuBackgroundColor);
    setFontColor(design.standardMenuFontColor);
    setItemFontColor(design.standardMenuItemFontColor);
    setItemSeparatorColor(design.standardMenuItemSeperatorColor);
    setDetailFontColor(design.standardMenuDetailFontColor);
    setSelectedItemFontColor(design.standardMenuSelectedItemFontColor);
    setSelectedItemBackgroundColor(design.standardMenuSelectedItemBackgroundColor);

    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const stateDesign = {
    standardMenuBackgroundColor: backgroundColor,
    standardMenuFontColor: fontColor,
    standardMenuItemFontColor: itemFontColor,
    standardMenuItemSeperatorColor: itemSeparatorColor,
    standardMenuDetailFontColor: detailFontColor,
    standardMenuSelectedItemFontColor: selectedItemFontColor,
    standardMenuSelectedItemBackgroundColor: selectedItemBackgroundColor
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm: stateDesign,
    simulator: { ...simulator },
    tab: "Menu",
    headerTitle: "Menus",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  const backgroundColorInput = <ColorPicker getter={backgroundColor} setter={setBackgroundColor} label="Background Color" disabled={disableForm}/>;
  const fontColorInput = <ColorPicker getter={fontColor} setter={setFontColor} label="Font Color" disabled={disableForm}/>;
  const itemFontColorInput = <ColorPicker getter={itemFontColor} setter={setItemFontColor} label="Item Font Color" disabled={disableForm}/>;
  const itemSeparatorColorInput = <ColorPicker getter={itemSeparatorColor} setter={setItemSeparatorColor} label="Item Separate Color" disabled={disableForm}/>;
  const detailFontColorInput = <ColorPicker getter={detailFontColor} setter={setDetailFontColor} label="Detail Font Color" disabled={disableForm}/>;
  const selectedItemFontColorInput = <ColorPicker getter={selectedItemFontColor} setter={setSelectedItemFontColor} label="Selected Item Font Color" disabled={disableForm}/>;
  const selectedItemBackgroundColorInput = <ColorPicker getter={selectedItemBackgroundColor} setter={setSelectedItemBackgroundColor} label="Selected Item Background" disabled={disableForm}/>;

  return (
      <Layout {...layoutProps}>
        {backgroundColorInput}
        {fontColorInput}
        {itemFontColorInput}
        {itemSeparatorColorInput}
        {detailFontColorInput}
        {selectedItemFontColorInput}
        {selectedItemBackgroundColorInput}
      </Layout>
  );
}

export default withDesign(withSimulator(Menus));