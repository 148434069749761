import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import React from 'react';

import { CREATE_WORKSPACE, UPDATE_WORKSPACE, ME } from "api";
import { workspace } from "cache";
import Base from './Base';
import Input from "components/atoms/Input/BaseInput";
import SubmitButton from "components/atoms/Button/Submit";

export default function NewApp(props) {
  const theme = useTheme();
  const client = useApolloClient();
  const { workspace, ...otherProps } = props;

  const [ name, setName ] = React.useState(workspace?.name);
  const [ nameError, setNameError ] = React.useState(null);

  const [ createWorkspace ] = useMutation(CREATE_WORKSPACE, {
    variables: {
      name: name,
    },
    update(cache, { data: { createWorkspace } }) {
      const existingMe = client.readQuery({ query: ME });

      const newMe = {
        ...existingMe.me,
        workspaces: [ ...existingMe.me.workspaces, createWorkspace.workspace ],
      };

      client.writeQuery({ query: ME, data: { me: newMe } });
    },
    onCompleted(data) {
      onSave(data.createWorkspace.workspace);
    },
    onError(data) {
      handleError(data);
    }
  });

  const [ updateWorkspace ] = useMutation(UPDATE_WORKSPACE, {
    variables: {
      id: workspace?.id,
      workspaceFields: {
        name: name,
      }
    },
    onCompleted(data) {
      onSave(data.updateWorkspace.workspace);
    },
    onError(data) {
      handleError(data);
    }
  });

  React.useEffect(() => {
    if (workspace) {
      setName(workspace.name);
    }
  }, [workspace]);

  function onSave(data) {
    props.savedSnack?.launch();
    props.onClose(data);
  }

  function handleError(data) {
    console.log(data);
    if (data.message.includes("Name has already been taken")) {
      setNameError("Name is not available. Try a different name.");
    }
  }

  function saveAndClose() {
    if (Boolean(name)) {
      workspace.id ? updateWorkspace() : createWorkspace();
    }
  }

  function submit(ev) {
    ev.preventDefault();
    saveAndClose();
  }

  return (
    <Base
      title={workspace?.id ? "Edit Workspace" : "New Workspace"}
      titleRight={() => <SubmitButton disabled={!Boolean(name)} onClick={saveAndClose}>Done</SubmitButton>}
      dimensions={{
        width: theme.spacing(100),
        height: theme.spacing(50),
      }}
      {...otherProps}
    >
      <form style={{ width: "100%" }} onSubmit={submit}>
        <Input
          label="Workspace Name"
          defaultValue={name}
          onChange={setName}
          error={Boolean(nameError)}
          helperText={nameError}
          autoFocus
          required
        />
      </form>
    </Base>
  );
}
