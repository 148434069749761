import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  noitem: (props) => ({
    fontSize: "20px",
    minHeight: "20rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: props.savedTextColor,
    width: '100%',
    position: 'absolute',
    padding: '3rem',
    textAlign: 'center'
  }),
}));

export default function SavedScreen(props) {
  const { simulator } = props;
  const { design } = simulator;
  const classes = useStyles(design);

  return <div className={classes.noitem}>{design.savedNoItemsText}</div>;
}
