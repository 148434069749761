import React from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "components/atoms/Input/Switch";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import BuildIcon from "@mui/icons-material/Build";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CopyToClipboard from "components/atoms/Button/CopyToClipBoard";
import makeStyles from '@mui/styles/makeStyles';
import _ from "underscore";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./DataGrid.css";

const useStyles = makeStyles((theme) => ({
  stretch: {
    flex:1, width: "100%"
  }
}));

function onError(e) {
  const target = e.target;
  if (target) target.style.display = 'none'
}

const TitleCellRenderer = (params) => {
  return (
    <Typography
      variant="h6"
      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
    >{params.data.title}</Typography>
  );
};

const ImageCellRenderer = (params) => {
  if (!params.data.image && !params.data.imageUrl && params.data.defaultImageComponent) {
    let DefaultImage = params.data.defaultImageComponent;
    return (<DefaultImage />);
  }
  return params.data.image || params.data.imageUrl ? (
    <img
      onError={onError}
      src={params.data.image || params.data.imageUrl}
      alt={params.data.title || params.data.name}
      {...(params.data.imageWidth
        ? { width: params.data.imageWidth }
        : { height: 24 }
      )}
    />
  ) : null;
};

const ImageTitleRenderer = (params) => {
  return params.data.image || params.data.imageUrl ? (
    <>
      <img
        onError={onError}
        src={params.data.image || params.data.imageUrl}
        alt={params.data.title || params.data.name}
        {...(params.data.imageWidth
          ? { width: params.data.imageWidth, style: {margin: '0 15px 0 0'} }
          : { height: 24, style: {margin: '0 15px 0 0'} }
        )}
      />
      {params.data.title}
    </>
  ) : params.data.title;
}

const WrappedTextRenderer = (params) => {
  return (
    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{params.value || params.data.title}</div>
  );
};

const PollCellRenderer = (params) => {
  return params.value ? <CheckCircleIcon /> : <CancelIcon />
};

const LabelRenderer = (params) => {
  return (
    params.data.label
      ? <div style={{ backgroundColor: "grey", borderRadius: 8, height: '20px',
          lineHeight: '20px', padding: "0 12px", fontSize: 13,
          position: "absolute", right: 0 }}>{params.data.label}</div>
      : null
  );
};

const ActionCellRenderer = (params) => {
  const {
    toggle,
    onLink,
    launchLink,
    buildLink,
    onCopy,
    copyText,
    copyTip,
    onOpen,
    onEdit,
    onDelete,
    email,
    onAssetId,
    onDuplicate,
  } = params.data.actions;
  const record = _.omit(params.data, "actions");

  return <>
    {Boolean(toggle) && (
      <>
        <FormGroup row>
          <Tooltip
            title={toggle.title ? toggle.title : (toggle.getter ? "Move to drafts" : "Publish")}
            aria-label="publish"
          >
                <Switch
                  checked={toggle.getter}
                  onChange={toggle.setter}
                  disabled={toggle.disabled}
                  name="publish"
                />
          </Tooltip>
        </FormGroup>
        <Divider
          orientation="vertical"
          style={{ height: 24, marginLeft: 20, marginRight: 22 }}
        />
      </>
    )}

    {Boolean(onLink) && (
      <Tooltip
        title={params.data.type === "Bundle::Media::Unknown" ? "Catalog As" : "Unlink"}
        aria-label="link"
      >
        <IconButton onClick={(ev) => onLink(record, ev)} size="large">
          {params.data.type === "Bundle::Media::Unknown" ? <LinkIcon /> : <LinkOffIcon />}
        </IconButton>
      </Tooltip>
    )}

    {Boolean(launchLink) && (
      <Tooltip title="Open in new tab" aria-label="open">
        <IconButton
          component="a"
          href={launchLink}
          target="_blank"
          onClick={(ev) => ev.stopPropagation()}
          size="large">
          <LaunchIcon />
        </IconButton>
      </Tooltip>
    )}

    {Boolean(onAssetId) && (
      <Tooltip title="Asset ID" aria-label="asset">
        <IconButton onClick={(ev) => onAssetId(record, ev)} size="large">
          <BuildIcon />
        </IconButton>
      </Tooltip>
    )}

    {Boolean(buildLink) && (
      <Tooltip title="Manage builds" aria-label="open">
        <IconButton component="a" href={buildLink} size="large">
          <BuildIcon />
        </IconButton>
      </Tooltip>
    )}

    {Boolean(email) && (
      <Tooltip title="Email" aria-label="email">
        <IconButton component="a" href={`mailto:${email}`} size="large">
          <MailOutlineIcon />
        </IconButton>
      </Tooltip>
    )}

    {Boolean(onCopy) && Boolean(copyText) && (
      <CopyToClipboard
        copyText={copyText}
        copyTip={copyTip}
        onCopy={onCopy}
      />
    )}

    {Boolean(onOpen) && (
      <Tooltip title="Open" aria-label="open">
        <IconButton onClick={(event) => onOpen(record, event)} size="large">
          <FolderOpenIcon />
        </IconButton>
      </Tooltip>
    )}

    {Boolean(onDuplicate) && (
      <Tooltip title="Duplicate" aria-label="copy">
        <IconButton onClick={(event) => onDuplicate(record, event)} size="large">
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    )}

    {Boolean(onEdit) && (
      <Tooltip title="Edit" aria-label="edit">
        <IconButton onClick={(event) => onEdit(record, event)} size="large">
          <EditIcon />
        </IconButton>
      </Tooltip>
    )}

    {Boolean(onDelete) && (
      <Tooltip title="Delete" aria-label="delete">
        <IconButton onClick={(event) => onDelete(record, event)} size="large">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    )}
  </>;
};

export default function BaseGrid(props) {
  const { frameworks, ...otherProps } = props;
  const classes = useStyles();

  return (
    <div style={{ flex: 1, display: "flex", width: "100%", justifyContent: "center" }}>
      <Paper
        className={`${classes.stretch} ag-theme-material`}
        square
      >
        <AgGridReact
          rowHeight={48}
          frameworkComponents={{
            imageCellRenderer: ImageCellRenderer,
            actionCellRenderer: ActionCellRenderer,
            titleCellRenderer: TitleCellRenderer,
            wrappedTextRenderer: WrappedTextRenderer,
            imageTitleRenderer: ImageTitleRenderer,
            pollCellRenderer: PollCellRenderer,
            labelRenderer: LabelRenderer,
            ...frameworks,
          }}
          {...otherProps}
        />
      </Paper>
    </div>
  );
}
