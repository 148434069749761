import amazonTvValidator from './amazonTv';
import amazonMobileValidator from './amazonMobile';
import googleTvValidator from './googleTv';
import googleMobileValidator from './googleMobile';
import iosValidator from './appleIos';
import tvosValidator from './appleTv';
import lgValidator from './lg';
import rokuValidator from './roku';
import tizenValidator from './tizen';
import webValidator from './web';
import xboxValidator from './xbox';
import vizioValidator from './vizio';

const validators =  {
    amazonTvValidator,
    amazonMobileValidator,
    googleTvValidator,
    googleMobileValidator,
    iosValidator,
    tvosValidator,
    lgValidator,
    rokuValidator,
    tizenValidator,
    webValidator,
    xboxValidator,
    vizioValidator
}

export default validators;