import Breadcrumbs from "@mui/material/Breadcrumbs";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import React from 'react';
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    paddingRight: theme.spacing(16.5),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
}));

export default function Crumbs({ breadcrumbs }) {
  const classes = useStyles();

  return (
    <Box width={1} className={classes.breadcrumbContainer}>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((crumb, i) =>
          crumb.link ? (
            <Link key={`Breadcrumb-${i}`} to={crumb.link}>{crumb.name}</Link>
          ) : (
            <Typography key={`Breadcrumb-${i}`} color="textPrimary">
              {crumb.name}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Box>
  );
}
