import Tooltip from '@mui/material/Tooltip';
import React from 'react';

/**
 * 
 * @param {Object} props 
 * @param {String} props.title
 */
export default function Tip(props) {
  const { title, children, ...otherProps } = props;
  return (
    <Tooltip
      placement="right"
      title={title}
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
}
