import React from "react";
import Paper from "@mui/material/Paper";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
  },
}));

export default function PaperGroup(props) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}  {...props} square>
      {props.children}
    </Paper>
  );
}
