import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ListStreams from "./List";
import NewStream from "./New";
import EditStream from "./Edit";

export default function Streams(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;

  const commonProps = { savedSnack };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <NewStream {...commonProps} />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <EditStream {...commonProps} />
      </Route>
      <Route path={path}>
        <ListStreams {...commonProps} />
      </Route>
    </Switch>
  );
}
