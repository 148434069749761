import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";
import useWindowSize from "customHooks/useWindowSize";

import { GET_CURRENT_APP_PROPS, GET_APP, UPDATE_APP, ME } from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import Base from "components/molecules/Modal/Base";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import ContentLayout from "screens/Layout/ContentLayout";
import { withRedirect } from "components/containers/Redirect";
import { useLocation, useParams } from "react-router-dom";
import { LANGUAGE_MAP } from "helpers/langUtils";
import _ from "lodash";
import SubmitButton from "components/atoms/Button/Submit";
import { useTheme } from "@mui/material/styles";
import { isSuperUser } from "helpers/platformUtils";

const columns = [
  { field: "name", headerName: "Name", width: 512, rowDrag: true },
  { field: "action", headerName: "Action", cellRenderer: "actionCellRenderer", flex: 1 },
];

function LanguageList(props) {
  const { appId, localeId } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      appId: appId,
    }
  });
  const { data: meData } = useQuery(ME);
  const user = meData?.me;
  const isSuperAdmin = isSuperUser(user);
  const app = data?.app;
  const selectedlocale = app?.locales.find(l => l.id === localeId)
  const locale = selectedlocale && _.omit(selectedlocale, "__typename")
  const langs = locale?.languages;


  const { pathname } = useLocation();
  const appDetails = data?.app;
  const [ editing, setEditing ] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  const [ updateApp, {loading: updatingApp} ] = useMutation(UPDATE_APP, {
    update(cache, { data: { updateApp } }) {
      cache.modify({
        id: cache.identify(updateApp.app),
        fields: {
          locales(cachedLocale) {
            return (updateApp.app.locales);
          }
        },
      });
    },
    onCompleted(data) {
      setEditing(null);
      closeDeleteConfirmation()
    }
  });

  function onDelete(language) {
    setDeleteConfirmation(language.language);
  }

  function deleteLanguageApi(language) {
    updateAppLocale({...locale, languages: locale.languages.filter(l => l!==language)})
  }

  const updateAppLocale = (updatedLocale) => {
    updateApp({
      variables: {
        workspaceId: appProps.workspaceId,
        id: app?.id,
        appFields: {
          localesAttributes: [{...updatedLocale}],
        },
      }
    })
  }

  const onSort = (params) => {
    const langs = params.api.rowModel.rowsToDisplay.map(d => d.data.language);
    if (langs.length) {
      updateAppLocale({...locale, languages: langs})
    }
  };

  const onLanguageSelection = (languages) => {
    updateAppLocale({...locale, languages})
  }

  return (
    <ContentLayout title="Languages" fab={isSuperAdmin ? { onClick: () => setEditing({}) }: null}>
      <FormLayout
        breadcrumbs={[
          { name: "Brands", link: `${pathname.split('/brands')[0]}/brands` },
          { name: appDetails?.name, link: `${pathname.split('/locales')[0]}/locales`},
          { name: locale?.name},
        ]}
      >
        <DataGrid
          rowData={
            langs
              ? langs.map((l) => ({
                  name: LANGUAGE_MAP[l],
                  language: l,
                  actions: {
                    onDelete: langs.length < 2 ? null : onDelete
                  }
                }))
              : []
          }
          columnDefs={columns}
          onRowDragEnd={onSort}
          rowDragManaged={true}
          animateRows={true}
          
        />

        <LanguageSelector
          open={Boolean(editing)}
          onClose={() => setEditing(null)}
          savedSnack={props.savedSnack}
          selected={langs}
          onLanguageSelection={onLanguageSelection}
          loading={updatingApp}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          content={"Remove the language?"}
          handleClose={closeDeleteConfirmation}
          accept={() => deleteLanguageApi(deleteConfirmation)}
          acceptLabel={"Remove"}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(LanguageList);

const LanguageSelector = (props) => {
  const columns = [
    { field: "name", headerName: "Name", width: 512, checkboxSelection: true },
  ]
  const {selected, onLanguageSelection, loading, ...otherProps} = props;
  const langs = ["en", "fr", "es", "ge", "it", "pt", "nl", "el"];
  const theme = useTheme()
  const size = useWindowSize();

  const [selectedLanguages, setSelectedLanguages] = React.useState(selected || []);
  const [modalWidth, setModalWidth] = React.useState(100);

  const onSubmit = () => {
    if (selectedLanguages.length) onLanguageSelection(selectedLanguages)
  }
  const handleSelect = (params) => {
    setSelectedLanguages(params.api.getSelectedNodes().map((n) => n.data.language));
  }
  const onGridReady = (params) => {
    params.api.forEachNode(n => selected.includes(n.data.language) && n.setSelected(true));
  }

  React.useEffect(() => {
    if(size?.width > 800) setModalWidth(100);
    else if (size?.width <= 800 && size?.width > 600) setModalWidth(60);
    else if(size?.width <= 600) setModalWidth(50);
  }, [size]);

  return (
    <Base
      title={"Languages"}
      titleRight={() => <SubmitButton onClick={onSubmit} isLoading={loading}>Done</SubmitButton>}
      dimensions={{
        width: theme.spacing(modalWidth),
        height: theme.spacing(60),
      }}
      {...otherProps}
    >
      <DataGrid
          rowData={
            langs
              ? langs.map((l) => ({
                  name: LANGUAGE_MAP[l],
                  language: l,
                }))
              : []
          }
          columnDefs={columns}
          accentedSort={true}
          animateRows={true}
          rowSelection={"multiple"}
          onRowSelected={handleSelect}
          onGridReady={onGridReady}
        />
    </Base>
  );
}
