import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { withRedirect } from "components/containers/Redirect";
import { useLocation } from "react-router-dom";
import { GET_CURRENT_APP_PROPS, UPDATE_APP, CREATE_APP, ME } from "api";
import ContentLayout from "screens/Layout/ContentLayout";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import Tooltip from "components/atoms/Tooltip";
import Select from "components/atoms/Input/Select";
import PaperGroup from "components/molecules/Form/PaperGroup";

function Form(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { app } = props;
  const { pathname } = useLocation();

  const [ name, setName ] = React.useState(app?.name);
  const [ developerWebsite, setDeveloperWebsite ] = React.useState(app?.developerWebsite);
  const [ maxStreams, setMaxStreams ] = React.useState(app?.maxStreams || 3);
  const [ nameError, setNameError ] = React.useState(null);
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);

  const listPath = `${pathname.split('/edit/')[0]}`;

  const appFields = {
    name,
    developerWebsite,
    maxStreams,
  };

  const [ createApp, {loading: creatingApp} ] = useMutation(CREATE_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      appFields: appFields,
    },
    onCompleted(data) {
      handleSuccess(data);
    },
    onError(data) { handleError(data); }
  });

  const [ updateApp, {loading: updatingApp} ] = useMutation(UPDATE_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      id: app?.id,
      appFields: appFields,
    },
    update(cache, { data: { updateApp } }) {
      cache.modify({
        id: cache.identify(updateApp.app),
        fields: {
          developerWebsite(cachedDeveloperWebsite) {
            return (updateApp.app.developerWebsite);
          },
          maxStreams(cachedMaxStreams) {
            return (updateApp.app.maxStreams);
          },
        },
      });
    },
    onCompleted(data) {
      handleSuccess(data);
    },
    onError(data) { handleError(data); }
  });

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [name, developerWebsite, maxStreams])
  
  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  const handleSuccess = (data) => {
    setDisableForm(false)
    props.savedSnack.launch();
    props.redirect.set(listPath);
  };

  const handleError = (data) => {
    console.log(data);
    setDisableForm(false)
  }

  const submit = () => {
    if (isValid()) {
      app?.id ? updateApp() : createApp();
    }
  }

  const cancel = () => {
    props.redirect.set(listPath);
  };

  const isValid = () => {
    if (!Boolean(name)) {
      setNameError("Name is mandatory");
      return false;
    }
    return true;
  };

  const isLoading = updatingApp || creatingApp;

  return (
    <ContentLayout title="Brand">
      <FormLayout
        isLoading={isLoading}
        disableSave={isLoading}
        submit={submit}
        cancel={cancel}
        breadcrumbs={[
          { name: "Brands", link: listPath },
          { name: app?.name },
        ]}
        isChanged={isFormChanged}
        betterForm
        disableCancel={isLoading}
      >
        <PaperGroup>
          <Input
            label="App Name"
            defaultValue={name}
            onChange={setName}
            error={Boolean(nameError)}
            helperText={nameError || `${25 - (name?.length || 0)} - character limit`}
            autoFocus
            required
            disabled={disableForm}
          />

          <Input
            label="Website"
            defaultValue={developerWebsite}
            onChange={setDeveloperWebsite}
            disabled={disableForm}
          />

          <Select
            label="Maximum concurrent streams"
            defaultValue={maxStreams}
            onChange={setMaxStreams}
            options={[
              { label: 1, value: 1 },
              { label: 2, value: 2 },
              { label: 3, value: 3 },
              { label: 4, value: 4 },
              { label: 5, value: 5 },
              { label: 10, value: 10 },
              { label: 20, value: 20 },
              { label: 50, value: 50 },
              { label: 100, value: 100 },
              { label: 1000, value: 1000 },
            ]}
            required
            disabled={disableForm}
            helperText="This setting allows you to control the maximum number of devices that can stream videos concurrently using the same account."
          />
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(Form);