import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import urlJoin from "url-join";

import {
  GET_CURRENT_APP_PROPS,
  GET_SPONSORS,
  UPDATE_SPONSOR,
  DELETE_SPONSOR,
  GET_CURRENT_APP,
} from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import ContentLayout from "screens/Layout/ContentLayout";
import { LANGUAGE_MAP } from "helpers/langUtils";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 400,
    cellRenderer: "titleCellRenderer",
    sort: 'asc',
  },
  {
    field: "regions",
    headerName: "Regions",
    width: 150,
    valueGetter: (params) => params.data.localeIds
      ? params.data.locales.filter(l =>
          params.data.localeIds.includes(l.id)
        ).map(l => l.name).join(", ")
      : "All"
  },
  {
    field: "language",
    headerName: "Language",
    width: 150,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    sortable:false,
    flex: 1,
    minWidth: 250
  },
];

function Sponsors(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data } = useQuery(GET_SPONSORS, { variables: appProps });
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);

  const onEdit = (sponsor) => {
    history.push(urlJoin(pathname, `edit/${sponsor.id}`));
  };

  const onDelete = (sponsor) => {
    setDeleteConfirmation(sponsor);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(null);
  };

  const toggleActive = (sponsor, value, event) => {
    updateSponsor({
      variables: {
        ...appProps,
        sponsorId: sponsor.id,
        sponsorFields: { isActive: value },
      },
    });
  };

  const [updateSponsor] = useMutation(UPDATE_SPONSOR, {
    update(cache, { data: { updateSponsor } }) {
      const existingSponsors = client.readQuery({
        query: GET_SPONSORS,
        variables: appProps,
      });

      const newSponsors = existingSponsors.sponsors.map((s) => (s.id == updateSponsor.sponsor.id ? { ...s, ...updateSponsor.sponsor } : { ...s }))

      client.writeQuery({
        query: GET_SPONSORS,
        variables: appProps,
        data: { sponsors: newSponsors },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const [deleteSponsor] = useMutation(DELETE_SPONSOR, {
    update(cache, { data: { deleteSponsor } }) {
      cache.modify({
        fields: {
          sponsors(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteSponsor.sponsor.id !== readField("id", ref)
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
    },
    onError(data) {
      console.log(data);
    },
  });

  const deleteSponsorApi = (id) => {
    closeDeleteConfirmation();
    deleteSponsor({
      variables: {
        ...appProps,
        sponsorId: id,
      },
    });
  };

  const coulumnsForSingleLocaleLang = columns.filter(c => !["Regions", "Language"].includes(c.headerName));

  return (
    <ContentLayout
      title="Sponsors"
      fab={{ onClick: () => history.push(urlJoin(pathname, "new")) }}
    >
      <FormLayout>
        <DataGrid
          rowData={
            data?.sponsors
              ? data.sponsors.map((s) => ({
                  ...s,
                  title: s.name,
                  locales: app.locales,
                  language: s.language ? LANGUAGE_MAP[s.language] : "Not Assigned",
                  actions: {
                    toggle: {
                      getter: s.isActive,
                      setter: (value, event) => toggleActive(s, value, event),
                    },
                    onEdit,
                    onDelete,
                  },
                }))
              : []
          }
          columnDefs={props.isMultiLangOrRegion ? columns : coulumnsForSingleLocaleLang}
          defaultColDef={{ sortable: true }}
          accentedSort={true}
          immutableData={true}
          getRowNodeId={(data) => data.id}
        />
        <ConfirmDialog
          content={"This Sponsor screen will be removed from the app. Are you sure you want to proceed?"}
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => {
            deleteSponsorApi(deleteConfirmation.id);
          }}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default Sponsors;
