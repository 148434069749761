import React from "react";
import Link from "@mui/material/Link";
import validator from "validator";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Input from "components/atoms/Input/BaseInput";
import EmailInput from "components/atoms/Input/Email";
import Select from "components/atoms/Input/Select";
import Tooltip from "components/atoms/Tooltip";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { WEB_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import { useParams, Link as RedirectLink } from "react-router-dom";
import { WEB_PATH } from "helpers/urlConstants";
import { PlatformApiKey } from "./List";
import PaymentProvider from "./PaymentProvider";

const Platform = (props) => {
  const { store, updateStoreApi, appProps } = props;
  const params = useParams()

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const platform = store?.platforms?.find((p) => p.type === "Platform::Web");
  const metadata = platform.metadata || { scaling_factor: 1.0 };

  const [title, setTitle] = React.useState(metadata.title);
  const [subdomain, setSubdomain] = React.useState(metadata.subdomain);
  const [customDomain, setCustomDomain] = React.useState(
    metadata.custom_domain
  );
  const [supportEmail, setSupportEmail] = React.useState(
    metadata.support_email
  );
  const [scalingFactor, setScalingFactor] = React.useState(
    metadata.scaling_factor
  );
  const [paymentGateway, setPaymentGateway] = React.useState(store.paymentGateway || "stripe")
  const saveTimer = React.useRef(null);

  const [asset, setAsset] = React.useState({
    urls: {
      favicon: platform.assetUrls.favicon,
    },
    blobs: {
      favicon: null,
    },
    errors: {
      favicon: null,
    },
  });

  const [subdomainError, setSubdomainError] = React.useState(null);
  const [customDomainError, setCustomDomainError] = React.useState(null);

  const [lastUploaded, setLastUploaded] = React.useState(null);
  const assetProps = { asset, setAsset, assetMap: WEB_ASSET_MAP };
  const apiKeys = [{name: "Web", apiKey: platform.apiKey}]

  const handleSupportEmail = (val) => {
    if (val === "") {
      setSupportEmail("");
    } else {
      if (validator.isEmail(val)) {
        setSupportEmail(val);
      }
    }
  }

  React.useEffect(() => {
    if (asset.blobs.favicon) {
      setLastUploaded(new Date().getTime());
    }
  }, [asset.blobs.favicon]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [title, subdomain, customDomain, supportEmail, scalingFactor, lastUploaded, paymentGateway]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            paymentGateway,
            platformsAttributes: [
              {
                id: platform.id,
                metadata: {
                  title: title,
                  subdomain: subdomain,
                  custom_domain: customDomain,
                  support_email: supportEmail,
                  scaling_factor: scalingFactor,
                },
                assetBlobs: {
                  favicon: asset.blobs.favicon,
                },
              },
            ],
          },
        },
      });
    }
  };

  const validateForm = () => {
    return true;
  };

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl  }, { name: "Web" }]}>
      <PaperGroup>
        <GroupHeader title="General" />

        <Tooltip title="This will be visible on web browser tab">
          <Input
            label="Website title"
            defaultValue={title}
            onChange={setTitle}
            autoFocus
            required
          />
        </Tooltip>

        <Tooltip title="This is the URL where your app will be available">
          <Input
            label="Subdomain"
            defaultValue={subdomain}
            onChange={setSubdomain}
            helperText={subdomain ? `https://${subdomain}.maz.tv` : "subdomain.maz.tv. we'll append .maz.tv to the subdomain."}
            required
          />
        </Tooltip>

        <Tooltip
          interactive
          title={
            <>
              {`Add a custom domain where your app will be made available. Follow additional steps `}
              <Link color="inherit" underline="always" href="https://help.mazsystems.com/en/4552213-how-to-set-up-a-custom-domain-for-web" target="_blank">
                here
              </Link>
            </>
          }
        >
          <Input
            label="Custom Domain"
            defaultValue={customDomain}
            onChange={setCustomDomain}
          />
        </Tooltip>

        <Tooltip title="End-users having billing or purchase related queries will be asked to write to this email.">
          <EmailInput
            label="Support Email"
            defaultValue={supportEmail}
            onChange={handleSupportEmail}
            required
          />
        </Tooltip>

        <Tooltip title="This will be used to decide the number of items to be shown on the screen if Standard Grid Layout is chosen as the design of the section or feed.">
          <Select
            label="Scaling Factor"
            value={scalingFactor}
            onChange={setScalingFactor}
            options={[
              { label: "1.0", value: 1.0 },
              { label: "1.1", value: 1.1 },
              { label: "1.2", value: 1.2 },
              { label: "1.3", value: 1.3 },
              { label: "1.4", value: 1.4 },
              { label: "1.5", value: 1.5 },
              { label: "1.6", value: 1.6 },
              { label: "1.7", value: 1.7 },
              { label: "1.8", value: 1.8 },
              { label: "1.9", value: 1.9 },
              { label: "2.0", value: 2.0 },
            ]}
            required
          />
        </Tooltip>

        <Spacer />
        <ImageUploadGroup {...assetProps} name="favicon" horizontal previewMaxHeight={100} previewProps={{style: {width: '60px', marginLeft: '10px'}}} />
        <GroupHeader 
          caption={<div>Note: To enable purchases, please connect to&nbsp;
            <RedirectLink to={`/${params.workspaceId}/${params.appId}/${WEB_PATH}`}>Stripe</RedirectLink>
          </div>}
        />
      </PaperGroup>
      <PlatformApiKey
        apiKeys={apiKeys}
      />
      <PaymentProvider
        getter={paymentGateway}
        setter={setPaymentGateway}
      />
    </FormLayout>
  );
};

export default withStore(Platform);
