import { Card } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import useWindowSize from "customHooks/useWindowSize";

import placeholder from "images/placeholder-img.svg";
import HeaderScreen from "./Screens/Header";
import SavedScreen from "./Screens/Saved";
import GeneralScreen from "./Screens/General";
import LoginConfScreen from "./Screens/LoginConfig";
import OnboardingScreen from "./Screens/Onboarding";
import Epg from "./Screens/Epg";
import { REGISTER } from "redux-persist";

const useStyles = makeStyles((theme) => ({
  header: {
    height: "50px",
    padding: "0px 6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    fontSize: "13px",
  },
  launchscreen: (props) => ({
    backgroundSize: "cover",
    minHeight: "370px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(0,0,0,0.9)",
    backgroundImage: `url(${
      props.tvSplashUrl ? props.tvSplashUrl : placeholder
    })`,
    height: 'calc((100vw - 604px)/16*9)'
  }),
  loginConfiguration: (props) => ({
    backgroundSize: "cover",
    minHeight: "370px",
    height: 'calc((100vw - 604px)/16*9)',
    margin: 'auto',
    textAlign: 'center',
    overflowY: "scroll"
  }),
  notes: {
    fontSize: "11px",
    padding: "0.35em 0",
  },
}));

function ContentBackground(props){
  const { simulator, dimensions } = props;
  const { design, tab } = simulator;
  const classes = useStyles(design);

  let backgroundObject = {};
  if (tab === "Saved") {
    backgroundObject = design.savedBackground;
  } else if (tab === "Onboarding") {
    backgroundObject = design.onboardingScreenBackground;
  } else if (tab === "Login Configuration") {
    backgroundObject = design.loginBackground;
  }
  const { assetUrl, format, primaryColor, secondaryColor} = backgroundObject;

  const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  };

  const back = (tab) => {
    if (tab === "General") {
      return { backgroundColor: "#191919" };
    }

    if (format === "single") {
      return { backgroundColor: primaryColor };
    }

    if (format === "basic_black") {
      return { backgroundColor: "#000000" };
    }

    let addcss = {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center"
    };

    if (format === "image") {
      addcss = {
        ...addcss,
        backgroundImage: `url("${assetUrl}")`,
      }
    }
    else if (format === "gradient") {
      addcss = {
        ...addcss,
        backgroundImage: `linear-gradient(135deg, ${hex2rgba(primaryColor, 0.7)} 0%, ${hex2rgba(secondaryColor, 0.18)} 100%`}
    }
    return addcss
  }

  return(
    <div style={{
      ...back(tab),
      height: dimensions.height ? dimensions.height : "calc((100vw - 604px)/16*9 - 50px)",
      width: dimensions.width,
      position: 'relative'
    }}>
      {format === "video"
        ? <video
            autoPlay muted loop src={assetUrl}
            style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }}
          ></video>
        : null}
      {format === "image"
        ? <div
            style={{
              position: 'absolute', width: '100%', height: '100%', objectFit: 'cover',
              backgroundImage: `linear-gradient(135deg, ${hex2rgba(primaryColor, 0.7)} 0%, ${hex2rgba(secondaryColor, 0.18)} 100%`,
            }}
          />
        : null}
      {props.children}
    </div>
  )
}

function TvSimulator(props) {
  const { simulator } = props;
  const { design, tab } = simulator;
  const classes = useStyles(design);
  const [ dimensions, setDimensions ] = React.useState({});
  const size = useWindowSize();

  React.useEffect(() => {
    if (size.width) {
      const width = Math.min((size.width - 604), 1280);
      const height = width * 9 / 16;

      setDimensions({ height, width });
    }
  }, [size]);

  const commonProps = { dimensions };

  return (
    <>
      <Card
        style={{
          maxHeight: 720,
          maxWidth: 1280,
          width: "100%",
        }}
      >
        {["General", "Saved", "Onboarding", "Guide"].includes(tab) && (
          <>
            {["General", "Saved"].includes(tab) && <HeaderScreen {...props} />}
            <ContentBackground {...props} {...commonProps}>
              {tab === "Saved" ? <SavedScreen {...props} /> : null}
              {tab === "General" ? <GeneralScreen {...props} /> : null}
              {tab === "Onboarding" ? <OnboardingScreen {...props} /> : null}
              {tab === "Guide" ? <Epg {...props}/>:null}
            </ContentBackground>
          </>
        )}
        {tab === "Launch Screen" && <div className={classes.launchscreen} />}
        {tab === "Login Configuration" && 
          <div className={classes.loginConfiguration} >
            <ContentBackground {...props} {...commonProps}>
              <LoginConfScreen simulator={true} {...props}/>
            </ContentBackground>
          </div>
        }
      </Card>
      <div className={classes.notes}>
        Note: This is not a preview of your app, just a generic mockup of a
        generic CTV interface.
        <br />
        Disclaimer: Certain visual elements are not available on Roku.
      </div>
    </>
  );
}

export default TvSimulator;
