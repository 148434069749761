import React from 'react';
import Button from '@mui/material/Button';

export default function Cancel(props) {
  const { style, children, ...otherProps } = props;
  return (
    <Button
      variant="outlined"
      style={{ width: 120, ...style }}
      {...otherProps}
    >
      {children || 'Cancel'}
    </Button>
  );
}
