import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ListSponsors from "./List";
import EditSponsor from "./Edit";
import NewSponsor from "./New";

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Sponsors(props) {
  const { path } = useRouteMatch();
  const { savedSnack, isMultiLangOrRegion } = props;

  const commonProps = { savedSnack, isMultiLangOrRegion };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <NewSponsor {...props} {...commonProps} />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <EditSponsor {...props} {...commonProps} />
      </Route>
      <Route path={path}>
        <ListSponsors {...props} {...commonProps} />
      </Route>
    </Switch>
  );
}

export default withSavedSnack(Sponsors);
