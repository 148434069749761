import { gql } from "@apollo/client";

export const APP_USER_FRAGMENT = gql`
  fragment AppUserSchema on AppUser {
    id
    email
  }
`;

export const GET_APP_USERS = gql`
  query appUsers($workspaceId: ID!, $appId: ID!) {
    appUsers(workspaceId: $workspaceId, appId: $appId) {
      ...AppUserSchema
    }
  }
  ${APP_USER_FRAGMENT}
`;
