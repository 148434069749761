import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";
import { useRouteMatch } from "react-router-dom";
import moment from "moment";
import makeStyles from '@mui/styles/makeStyles';

import { Grid, FormControlLabel, Switch } from '@mui/material';

import apple_store from "images/store/ios-store.png";
import google_store from "images/store/android-store.png";

import {
  GET_CURRENT_APP,
  GET_CURRENT_APP_PROPS,
  CREATE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  GET_AVAILABLE_PLATFORMS,
} from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from 'components/atoms/Input/BaseInput';
import Select from 'components/atoms/Input/Select';
import Tooltip from 'components/atoms/Tooltip';
import DateTimePicker from "components/atoms/Input/DateTimePicker";
import PaperGroup from "components/molecules/Form/PaperGroup";
import GroupHeader from "components/atoms/Text/GroupHeader";
// import Countries from "components/atoms/Input/Countries";
import ContentLayout from "screens/Layout/ContentLayout";
import LocaleOptions from "components/molecules/LocaleOptions";
import DeeplinkModal from "components/molecules/Modal/BundleSelector";
import { isPastDateTime } from "helpers/dateUtil";
import GeneralDialog from "components/molecules/Dialog/Confirm/General";


let platform_data = [
  {
    name: "Platform::Ios",
    logo: apple_store,
    display_name: "App Store (iOS)",
  },
  {
    name: "Platform::Android",
    logo: google_store,
    display_name: "Google Play (Android)",
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  platform_img: {
    display: "flex",
    objectFit: "contain",
    width: "50px",
    marginRight: "20px",
  },
}));


function Form(props) {
  
  const classes = useStyles();
  const { path } = useRouteMatch();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const appData = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const available_platforms = useQuery(GET_AVAILABLE_PLATFORMS, { variables: appProps })?.data?.availableNotificationPlatforms;
  
  const allAppLocales = appData.locales.map((l,i)=> {
    return parseInt(l.id)
  })
  
  const [platformData, setPlatformData] = React.useState(platform_data)

 
  const { notification } = props;
  

  const [name, setName] = React.useState(notification.name ?? "");
  const [notificationText, setNotificationText] = React.useState(notification.notificationText ?? "");

  const [scheduleOption, setScheduleOption] = React.useState(notification.scheduleOption ?? "now");
  const [scheduleFor, setScheduleFor] = React.useState(notification.scheduleFor ? moment(notification.scheduleFor) : moment().add(1, "hour").startOf("hour"));

  const [scheduleForError, setScheduleForError] = React.useState(null);

  const [linkType, setLinkType] = React.useState(notification.linkType ?? "web_url");
  const [url, setUrl] = React.useState(notification.url ?? "");
  const [showDeepLinkModal, setShowDeepLinkModal] = React.useState(false);
  const [selectedDeepLink, setSelectedDeepLink] = React.useState(notification.url);
  const [selectedDeepLinkText, setSelectedDeepLinkText] = React.useState(notification.linkType==="deeplink"?notification.url:"");

  const [segmentation, setSegmentation] = React.useState(notification.segmentation ?? "all");

  const [countryState, setCountryState] = React.useState(notification.countries ?? { ALL: true });
  const [nameErr, setNameErr] = React.useState(null);
  const [textErr, setTextErr] = React.useState(null);
  const [scheduleErr, setScheduleErr] = React.useState(null);
  const [formDisabled, setFormDisabled] = React.useState(notification?.status === "sent" || false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [isFormChanged, setIsFormChanged] = React.useState(false);

  const [localeIds, setLocaleIds] = React.useState(notification.localeIds || null);
  const [language, setLanguage] = React.useState(notification.language || null);
  const [localeErr, setLocaleErr] = React.useState(null);

  const [platforms, setPlatforms] = React.useState(
    notification.platforms || [
      { platformType: "Platform::Android", isActive: true },
      { platformType: "Platform::Ios", isActive: true },
    ]
  );

  // const { data: deeplinkfolder } = useQuery(GET_FOLDER, {
  //   variables: {
  //     ...appProps,
  //     id: linkType==="deeplink"?url.split("/").pop():""
  //   }
  // });
  const deeplinkfolder = null;
  React.useEffect(() => {
    linkType==="deeplink" && deeplinkfolder && deeplinkfolder?.folder && setSelectedDeepLink(deeplinkfolder.folder)
  }, [deeplinkfolder])

  React.useEffect(() => {
    setPlatformData(platform_data.filter((p) => {
      for (let ap in available_platforms) {
        if (p.name == available_platforms[ap].type) {
          return true;
        }
      }
    }))
  }, [available_platforms])

  React.useEffect(() => {
    if (name) setNameErr(null);
    if (notificationText) setTextErr(null);
    setIsFormChanged(true);
  }, [name, notificationText])

  React.useEffect(() => { 
    setScheduleErr(null);
    setIsFormChanged(true);
  }, [scheduleFor,scheduleOption, segmentation]);

  React.useEffect(() => { 
    setIsFormChanged(true);
  }, [localeIds,language]);

  React.useEffect(() => { 
    setIsFormChanged(true);
  }, [linkType, url, ]);

  React.useEffect(() => {
    setTimeout(() => setIsFormChanged(false),50);
  },[]);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/notifications`;

  const onSave = () => {
    props.savedSnack.launch();
    history.push(listUrl);
  };

  const stateForm = {
    name,
    notificationText,
    linkType,
    url:linkType==="deeplink"?url.url:url,
    scheduleOption,
    scheduleFor,
    segmentation,
    platformsAttributes: platforms.map((pt) => ({
      ..._.omit(pt, "__typename", "platformType"),
      appPlatformType: pt.platformType
    })),
    localeIds,
    language
  };

  const isValidate = () => {
    let check = true;
    if (!name) {
      setNameErr("This field is mandatory")
      check = false
    }
    if (!notificationText) {
      setTextErr("This field is mandatory")
      check = false
    }
    if (scheduleOption === "future" && isPastDateTime(scheduleFor)) {
      setScheduleErr("Please select a future date")
      check = false
    }
    return check
  }

  const submit = () => {
    if (notification.id) {
      updateNotification({
        variables: {
          ...appProps,
          notificationId: notification.id,
          notificationFields: stateForm
        }
      });
    } else {
      createNotification({
        variables: {
          ...appProps,
          notificationFields: stateForm
        }
      });
    }
  }

  const validateAndSubmit = () => {
    if (!isValidate()) {
      return
    }
    if (scheduleOption === 'now') {
      setOpenAlert(true);
      return;
    }
    submit();
  }

  const [updateNotification, { loading: isUpdating }] = useMutation(UPDATE_NOTIFICATION, {
    update(cache, { data: { updateNotification } }) {
      cache.modify({
        fields: {
          notifications(existingRefs, { readField }) {
            return existingRefs.map((ref) => (
              updateNotification.notification.id === readField("id", ref)
                ? { ...ref, ...updateNotification.notification }
                : ref
            ));
          },
        },
      });
    },
    onCompleted(data) { onSave() },
    onError(err) { console.log(err) }
  });

  const [createNotification, { loading: isCreating }] = useMutation(CREATE_NOTIFICATION, {
    update(cache, { data: { createNotification } }) {
      cache.modify({
        fields: {
          notifications(existingRefs) {
            return [createNotification.notification, ...existingRefs];
          },
        },
      });
    },
    onCompleted(data) { onSave() },
    onError(err) { console.log(err) }
  });

  const nonNullTitle = name || "Untitled Notification";

  const mazIdSegment = (
    <Grid container>
      <Grid item xs={12}>
        <GroupHeader title="MAZ ID Segments" />
      </Grid>

      <Grid item xs={12}>
        <Tooltip title="Select the user types to which the notification will be sent. E.g you may select a notification to be sent to Subscribers and another for Non-Subscribers only.">

          <Select
            value={segmentation}
            onChange={setSegmentation}
            options={[
              { label: "All Users", value: "all" },
              { label: "Subscribers Only", value: "subs_only" },
              { label: "Expired Subscribers Only", value: "expired_subs_only" },
              { label: "Non Subscribers", value: "no_sub" },
            ]}
            label="User Type"
            helperText="types of users who will receive the notification"
            required
            disabled={formDisabled}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );

  const handleAppPlatformSwitch = (value, name) => {
    setPlatforms(
      platforms.map((p) => ({
        ...p,
        isActive: name === p.platformType ? value : p.isActive,
      }))
    );
    setIsFormChanged(true)
  };

  const fetchParentArr = (item) => {
    const treeBundle = [];
    
    if (item.parent && item.parent.key) {
        treeBundle.push(...fetchParentArr(item.parent)) 
    }
    treeBundle.push(item);
    return treeBundle
  }

  const closeDeepLinkModal = (e) => {
    
    setShowDeepLinkModal(false)
    if (!e || !e[0]) {
      setUrl("");
      return;
    }
    setSelectedDeepLink(e)
    let tempItemURL = {text:"ROOT", url:""}
    const itemParent = fetchParentArr(e[0])
    itemParent[itemParent.length-1] = e[0].data;
    itemParent.forEach(element => {
      (element.data && element.data.title)?tempItemURL.text+="> "+element.data.title:tempItemURL.text+="> "+element.title;
      (element.data && element.data.title)?tempItemURL.url+="/"+element.data.id:tempItemURL.url+="/"+element.id;
    });
    
    setUrl(tempItemURL)
  }

  const changeUrlTypeHandler = (e) => {
    setLinkType(e);
    setUrl("")
  }

  const isLoading = isCreating || isUpdating;

  const channelSegment = (
    <Grid container>
      <Tooltip title="The notification to be sent to the channel set.">
        <Grid item xs={3}>
          <GroupHeader title="Channel" />
        </Grid>
      </Tooltip>
      <Grid item xs={9}>
        <div></div>
      </Grid>
      {platformData.map((platform) => (
        <Grid item xs={6} style={{ display: "flex", height: "5em" }}>
          <FormControlLabel
            control={
              <>
                <Switch
                  name={platform.name}
                  color="primary"
                  checked={
                    platforms.find((p) => p.platformType === platform.name).isActive
                  }
                  onChange={(event) =>
                    handleAppPlatformSwitch(event.target.checked, platform.name)
                  }
                  disabled={formDisabled}
                />
                <img
                  height={34}
                  width={34}
                  src={platform.logo}
                  className={classes.platform_img}
                />
              </>
            }
            label={platform.display_name}
          />
        </Grid>
      ))}
    </Grid>
  );


  return (
    <ContentLayout title={nonNullTitle} >
      <FormLayout
        submit={validateAndSubmit}
        breadcrumbs={[
          { name: "Notifications", link: listUrl },
          { name: nonNullTitle }
        ]}
        disableSave={formDisabled}
        isChanged={isFormChanged}
        betterForm={true} 
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <PaperGroup>
          <Tooltip
            title="Used to identify the notification in TVOD Control."
          >
            <Input label="Tracking Name"
              placeholder={"Untitled Notification"}
              name="name"
              defaultValue={name}
              onChange={setName}
              helperText={"Not consumer facing"}
              required
              error={nameErr}
              disabled={formDisabled}
            />
          </Tooltip>
          <Input label="Notification Text"
            name="name"
            defaultValue={notificationText}
            onChange={setNotificationText}
            helperText="Character Limit (iOS only) =>
            106 - visible on lock screen,
            220 - visible in Notification Center"
            required
            multiline
            rows={5}
            rowsMax={Infinity}
            error={textErr}
            disabled={formDisabled}
          />
          <LocaleOptions
            locales={localeIds}
            setLocales={setLocaleIds}
            withLanguage
            language={language}
            setLanguage={setLanguage}
            errorText={localeErr ? "Please select locale and language": null}
            divider
          />

          <Tooltip
            title="When a user swipes or taps on this notification, the app will open to this URL. It is highly recommended to only use URLs that appear within the feeds in this app, but any web URL will work."
          >
           <Select
            label="Link Type"
            value={linkType}
            onChange={e => changeUrlTypeHandler(e)}
            options={[
              { label: "Web URL", value: "web_url" },
              { label: "Deeplink", value: "deeplink" },
            ]}
            required
          />
          </Tooltip>
          {linkType==="web_url" && <Input
            label="Link to"
            defaultValue={url}
            onChange={setUrl}
            helperText="URL to open or save"
          />}

          {linkType==="deeplink" &&
            <Grid item xs={5}>
              <Input
                type="button"
                color="secondary"
                onClick = {e => {setShowDeepLinkModal(true)}}
                value="Create deeplink"
              />
              {setSelectedDeepLink && <Grid item xs={10}>{url.text}</Grid>}
          </Grid>}

          <Select
            label="Schedule Option"
            value={scheduleOption}
            onChange={setScheduleOption}
            options={[
              { label: "Now", value: 'now' },
              { label: "Future", value: 'future' },
            ]}
            required
            disabled={formDisabled}
          />
          {
            scheduleOption === "future" &&
            <DateTimePicker
              label="Schedule for"
              value={scheduleFor}
              onChange={setScheduleFor}
              required
              disabled={formDisabled}
              helperText={scheduleErr}
              error={scheduleErr}
            />
          }
          {/* <Countries countryState={countryState} setCountryState={setCountryState} disabled={formDisabled}/> */}
        </PaperGroup>
        <PaperGroup>
          {mazIdSegment}
        </PaperGroup>
        <PaperGroup>
          {channelSegment}
        </PaperGroup>
        {showDeepLinkModal && <DeeplinkModal
          filters={["Bundle::Layout::Section","Bundle::Layout::Symlink"]}
          multiple={true}
          selectSingle={true}
          open={showDeepLinkModal}
          onClose={e => closeDeepLinkModal(e)}
          disableInActive
        />}
      </FormLayout>
      <GeneralDialog
        open={openAlert}
        handleClose={() => setOpenAlert(false)}
        accept={submit}
        content={<div>You are trying to schedule this push notification to be sent immediately. You will not be able to edit or delete this push notification after clicking save.
          <br/><br/>Are you sure you want to proceed?</div>}
        acceptLabel="Proceed"
        title="Alert"
      />
    </ContentLayout>
  );
}

export default Form;
