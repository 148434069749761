import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import List from "./List";
import Edit from "./Edit";
import New from "./New";

export default function DatabaseIntegrations(props) {
  const { path } = useRouteMatch();
  const { savedSnack, isMultiLangOrRegion } = props;

  const commonProps = { savedSnack, isMultiLangOrRegion };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <New {...commonProps} />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <Edit {...commonProps} />
      </Route>
      <Route exact path={path}>
        <List {...commonProps} />
      </Route>
    </Switch>
  );
}
