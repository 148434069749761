import { useQuery, useMutation } from "@apollo/client";
import React from 'react';
import { useParams } from "react-router-dom";
import moment from "moment";
import validator from "validator";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import DateTimePicker from "components/atoms/Input/DateTimePicker";
import Select, { BooleanSelect } from "components/atoms/Input/Select";
import { withRedirect } from "components/containers/Redirect";

import { GET_CURRENT_APP_PROPS, CREATE_LIVE_PROGRAM, UPDATE_LIVE_PROGRAM } from "api";
import ContentLayout from "screens/Layout/ContentLayout";

function Form(props) {
  const { liveChannel, liveProgram, hub, formats, channelsPath } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { liveChannelId, liveScheduleId } = useParams();
  const [ title, setTitle ] = React.useState(liveProgram.title || "");
  const [ start, setStart ] = React.useState(liveProgram.start ? moment(liveProgram.start) : moment().add(1, "hour").startOf("hour"));
  const [ stop, setStop ] = React.useState(liveProgram.stop ? moment(liveProgram.stop) : moment().add(1, "hour").startOf("hour").add(30, "minutes"));

  const [ startError, setStartError ] = React.useState(null);
  const [ endError, setEndError ] = React.useState(null);

  const liveSegment = liveProgram.segments ? liveProgram.segments[0] : null;
  const [hasSegment, setHasSegment] = React.useState(Boolean(liveSegment));
  const [segmentUrl, setSegmentUrl] = React.useState(liveSegment?.url);
  const [segmentFormat, setSegmentFormat] = React.useState(liveSegment?.format || "video");

  const [segmentUrlError, setSegmentUrlError] = React.useState(null);

  const listPath = `${channelsPath}/${liveChannelId}/schedules/${liveScheduleId}/programs`;

  const handleError = (data) => {
    console.log(data);
    const errorMessage = "This time is conflicting with another program.";
    if (data.message.includes("Start not available")) {
      setStartError(errorMessage);
    }
    if (data.message.includes("Stop not available")) {
      setEndError(errorMessage);
    }
    if (data.message.includes("Segments url can't be blank")) {
      setSegmentUrlError("URL cannot be blank");
    }
  };

  const handleSuccess = (data) => {
    props.savedSnack.launch();
    props.redirect.set(listPath);
  };

  let stateForm = {
    liveProgramFields: {
      title: title,
      start: start,
      stop: stop,
    }
  };

  if (hasSegment) {
    stateForm = {
      ...stateForm,
      liveProgramFields: {
        ...stateForm.liveProgramFields,
        segmentsAttributes: [{
          id: liveSegment?.id,
          format: segmentFormat,
          url: segmentUrl,
        }]
      }
    }
  } else if (!hasSegment && liveSegment) {
    stateForm = {
      ...stateForm,
      liveProgramFields: {
        ...stateForm.liveProgramFields,
        segmentsAttributes: [{
          id: liveSegment.id,
          _destroy: '1',
        }]
      }
    }
  }

  const [ createLiveProgram ] = useMutation(CREATE_LIVE_PROGRAM, {
    variables: {
      ...appProps,
      liveChannelId: liveChannel?.id,
      liveScheduleId: liveChannel?.defaultSchedule?.id,
      ...stateForm,
    },
    update(cache, { data: { createLiveProgram } }) {
      cache.modify({
        fields: {
          livePrograms(existingRefs) {
            return [ ...existingRefs, createLiveProgram.liveProgram ];
          },
        },
      });
    },
    onCompleted(data) { handleSuccess(data); },
    onError(data) { handleError(data); }
  });

  const [ updateLiveProgram ] = useMutation(UPDATE_LIVE_PROGRAM, {
    variables: {
      ...appProps,
      liveChannelId: liveChannel?.id,
      liveScheduleId: liveChannel?.defaultSchedule?.id,
      id: liveProgram.id,
      ...stateForm,
    },
    update(cache, { data: { updateLiveProgram } }) {
      cache.modify({
        fields: {
          livePrograms(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateLiveProgram.liveProgram.id === readField("id", ref)
                ? { ...ref, ...updateLiveProgram.liveProgram }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) { handleSuccess(data); },
    onError(data) { handleError(data); }
  });

  const nonNullName = title || "Untitled Program";

  React.useEffect(() => { setStartError(null); }, [start]);
  React.useEffect(() => { setEndError(null); }, [stop]);
  React.useEffect(() => { setSegmentUrlError(null); }, [segmentUrl]);

  function submit() {
    if (hasSegment && !segmentUrl) {
      setSegmentUrlError("Cannot be blank");
    } else if (hasSegment && !validator.isURL(segmentUrl)) {
      setSegmentUrlError("Invalid URL");
    } else if (title && start && stop && stop.isAfter(start)) {
      liveProgram.id ? updateLiveProgram() : createLiveProgram();
    } else {
      if (start.isSameOrAfter(stop)) {
        setStartError("Start time needs to before end time");
      }
    }
  }

  return (
    <ContentLayout title={nonNullName}>
      <FormLayout
        submit={submit}
        cancel={() => props.redirect.set(listPath)}
        breadcrumbs={[
          { name: "Channels", link: channelsPath },
          { name: liveChannel?.title, link: listPath },
          { name: nonNullName }
        ]}
      >
        <PaperGroup>
          <Input
            label="Title"
            defaultValue={title}
            onChange={setTitle}
            autoFocus
            required
          />

          <DateTimePicker
            label="Starts at"
            value={start}
            onChange={setStart}
            error={Boolean(startError)}
            helperText={startError}
          />

          <DateTimePicker
            label="Ends at"
            value={stop}
            onChange={setStop}
            error={Boolean(endError)}
            helperText={endError}
          />

          <BooleanSelect
            value={hasSegment}
            onChange={setHasSegment}
            label="Add a segment"
          />

          {hasSegment && (
            <>
              <Select
                value={segmentFormat}
                onChange={setSegmentFormat}
                label="Type"
                options={[
                  { label: "Video", value: "video" },
                  { label: "Playlist", value: "mrss" },
                ]}
              />

              <Input
                label="URL"
                defaultValue={segmentUrl}
                onChange={setSegmentUrl}
                error={Boolean(segmentUrlError)}
                placeholder={
                  segmentFormat === "video"
                    ? 'A live event or video URL'
                    : 'MRSS feed URL'
                }
                helperText={segmentUrlError}
                required
              />
            </>
          )}
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(Form);
