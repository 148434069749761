import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import Dashboard from "./Dashboard";
import Collections from "./Collections";
import Catalog from "./Catalog";
import Feeds from "components/molecules/Feed";
import Channels from './Channels';
import Playlists from './Playlists';
import Sections from './Sections'
import Metadata from './Metadata'
import MenuLayout from "./Sections/MenuLayout";

export default function Content(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;
  const commonProps = { savedSnack };

  return (
    <Switch>
      {/* <Route path={`${path}/dashboard`}>
        <Dashboard />
      </Route> */}
      <Route path={`${path}/sections`}>
        <Sections {...commonProps} />
      </Route>
      <Route path={`${path}/collections`}>
        <Collections {...commonProps} />
      </Route>
      <Route path={`${path}/playlists`}>
        <Playlists {...commonProps} />
      </Route>
      <Route path={`${path}/catalog`}>
        <Catalog />
      </Route>
      <Route path={`${path}/menu`}>
        <MenuLayout {...commonProps}/>
      </Route>
      <Route path={`${path}/metadata`}>
        <Metadata {...commonProps}/>
      </Route>
      <Route path={`${path}/sources`}><Feeds {...commonProps} hub="content" formats={["xmltv"]} /></Route>
      <Route path={`${path}/channels`}><Channels {...commonProps} hub="content"/></Route>
      <Redirect to={`${path}/sections`} />
    </Switch>
  );
}
