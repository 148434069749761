import { useQuery, useApolloClient } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from 'actions';
import { ME } from 'api';
import { workspace, app } from 'cache';

import Onboarding from './Onboarding';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function Root(props) {
  const { logout, clearRoute } = props;
  const classes = useStyles();
  const client = useApolloClient();
  const { loading, data, refetch } = useQuery(ME, {
    onError(error) {
      if (error.message.startsWith("NetworkError")) {
        workspace({});
        app({});
        client.resetStore();
        clearRoute();
        logout();
      }
    }
  });

  if (data && data.me.workspaces[0] && data.me.workspaces[0].apps[0]) {
    return <Redirect to={`/${data.me.workspaces[0].id}/${data.me.workspaces[0].apps[0].id}`} />
  }

  return (
    <div className={classes.container}>
      {loading
        ? <CircularProgress />
        : data
        ? <Onboarding me={data.me} refetch={refetch} />
        : null
      }
    </div>
  );
}

export default connect(null, actions)(Root);
