import { useQuery, useMutation } from "@apollo/client";
import React from 'react';
import validator from 'validator';

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import LanguageSelect from "components/atoms/Input/LanguageSelect";
import HelpText from "components/atoms/Text/Help";
import { withRedirect } from "components/containers/Redirect";

import { GET_CURRENT_APP_PROPS, CREATE_FEED } from "api";
import ContentLayout from "screens/Layout/ContentLayout";
import { withLocaleLang } from "components/containers/withLocaleLang";

function Form(props) {
  const NAME_CHARACTE_LIMIT = 65;
  const { feed, hub, formats, isMultiLangOrRegion } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const [ name, setName ] = React.useState(feed?.name || "");
  const [ url, setUrl ] = React.useState(feed?.url || "");
  const [ language, setLanguage ] = React.useState(feed?.bundle?.menus[0]?.language || "en");

  const [nameError, setNameError] = React.useState(null);
  const [urlError, setUrlError] = React.useState(null);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/${hub}/sources`;

  const [ createFeed ] = useMutation(CREATE_FEED, {
    variables: {
      ...appProps,
      name: name,
      url: url,
      format: formats[0],
      language: language
    },
    update(cache, { data: { createFeed } }) {
      cache.modify({
        fields: {
          feeds(existingRefs) {
            return [ ...existingRefs, createFeed.feed ];
          },
        },
      });
    },
    onError(data) {
      handleError(data);
    },
    onCompleted(data) {
      props.redirect.set(listUrl);
      props.savedSnack.launch();
    },
  });

  function handleError(data) {
    if (data.message.includes("Invalid feed or format")) {
      setUrlError("This is not a valid feed. Please check the format of the feed and try again.")
    } else if (data.message.includes("Url has already been taken")) {
      setUrlError("This feed has already been added to your brand.")
    }
    console.log(data);
  }

  const nonNullName = name || "Untitled Feed";

  React.useEffect(() => setNameError(null), [name]);
  React.useEffect(() => setUrlError(null), [url]);

  function submit() {
    if (name && url && validator.isURL(url)) {
      createFeed();
    } else {
      if (!name) {
        setNameError("Cannot be blank");
      }
      if (!url) {
        setUrlError("Cannot be blank");
      } else if (!validator.isURL(url)) {
        setUrlError("Invalid URL");
      }
    }
  }

  return (
    <ContentLayout title={nonNullName} >
      <FormLayout
        submit={submit}
        breadcrumbs={[
          { name: "Feeds", link: listUrl },
          { name: nonNullName }
        ]}
      >
        <PaperGroup>
          {isMultiLangOrRegion ? <LanguageSelect value={language} onChange={setLanguage} />: null}
          <Input
            label="Name"
            defaultValue={name}
            onChange={setName}
            error={Boolean(nameError)}
            helperText={nameError || `${NAME_CHARACTE_LIMIT - name.length} - character limit`}
            autoFocus
            required
            inputProps={{ maxLength: NAME_CHARACTE_LIMIT }}
          />
          <Input
            label="URL"
            defaultValue={url}
            onChange={setUrl}
            error={Boolean(urlError)}
            helperText={urlError}
            required
          />
          <HelpText style={{ maxWidth: 300 }}>
            Requirements:
            <br />
            Allowed Formats: {formats.map((f) => f.toUpperCase())}
          </HelpText>
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default withLocaleLang(withRedirect(Form));
