import { gql } from '@apollo/client';

export const ONBOARDING_SCREEN_FRAGMENT = gql`
  fragment OnboardingScreenSchema on OnboardingScreen {
    __typename
    id
    title
    summary
    logoUrl
    logoAltTag
    sortOrder
    isActive
  }
`;

export const BACKGROUND_FRAGMENT = gql`
  fragment BackgroundSchema on Background {
    __typename
    id

    format
    primaryColor
    secondaryColor
    assetUrl
    altText
  }
`;

export const FONT_FRAGMENT = gql`
  fragment FontSchema on Font {
    __typename
    id

    name
    url
    fontType
    postscriptName
  }
`;

export const DESIGN_FRAGMENT = gql`
  fragment DesignSchema on Design {
    __typename
    id
    locale {
      id
      name
    }
    language

    themePrimary
    themeSecondary
    themeActive
    themeInactive
    themeTabBar
    themeMobileTabBar
    themeBorder
    themeMobileBorder
    themeMobileVideoGridBackground
    themeMobileVideoGridText
    themeMobileLaunchBackground

    gdprHeaderText
    gdprActive
    gdprPrivacyText
    gdprPrivacyUrl
    gdprPrivacyLabel
    gdprThirdPartyActive
    gdprThirdPartyPrivacyText
    gdprThirdPartyPrivacyUrl
    gdprThirdPartyPrivacyLabel
    gdprSubmitButtonLabel

    ccpaShow
    ccpaMessage
    ccpaSellUsersInfo
    ccpaEmailAddress

    savedActive
    savedTextColor
    savedNoItemsText
    savedBackground {
      ...BackgroundSchema
    }

    lightMode
    logoPlacement
    logoAltText
    allowDownload
    launchLogoAltText

    iconSearch
    iconSettings
    iconSave
    iconSaved

    inlineVideo
    inlineVideoAccess
    inlineVideoDisplayTitle

    mobileLogoUrl
    tvLogoUrl
    mobileLaunchLogoUrl
    tvSplashUrl

    epgBorderColor
    epgProgramLabelColor
    epgInactiveProgramColor
    epgActiveProgramColor
    epgTimeMarkerColor
    epgTimeDisplayColor
    epgChannelColor
    epgChannelLabelColor
    epgTimelineBackgroundColor
    epgDetailsBackgroundColor
    epgGuideBackgroundColor
    epgDetailsPrimaryTextColor
    epgDetailsSecondaryTextColor
    epgProgramSubtitleColor

    standardMenuBackgroundColor
    standardMenuFontColor
    standardMenuItemFontColor
    standardMenuItemSeperatorColor
    standardMenuDetailFontColor
    standardMenuSelectedItemFontColor
    standardMenuSelectedItemBackgroundColor

    fonts {
      ...FontSchema
    }

    primaryFontId
    secondaryFontId

    loginType
    loginHeaderImageAltText
    loginHeaderText
    loginEmailPassword
    loginAccountExistsText
    loginNoAccountText
    loginFacebook
    loginHighlightColor
    loginBorderColor
    loginHeaderColor
    loginButtonColor
    loginButtonLabelColor
    loginTextButtonColor
    loginTermsUrl
    loginPrivacyUrl
    loginExistingSubscribersHeading
    loginRestorePurchasesHeading
    loginButtonText
    loginRegisterText
    loginSigninText
    loginHeaderImageUrl
    loginBackground {
      ...BackgroundSchema
    }

    onboardingWall
    onboardingAllowSkip
    onboardingButtonTitle
    onboardingAction
    onboardingTitleColor
    onboardingDescriptionColor
    onboardingPageControlSelectedColor
    onboardingPageControlUnselectedColor
    onboardingButtonColor
    onboardingButtonTitleColor
    onboardingScreenBackground {
      ...BackgroundSchema
    }
    onboardingScreens {
      ...OnboardingScreenSchema
    }
  }
  ${BACKGROUND_FRAGMENT}
  ${FONT_FRAGMENT}
  ${ONBOARDING_SCREEN_FRAGMENT}
`;

export const GET_DESIGNS = gql`
  query designs($workspaceId: ID! $appId: ID!) {
    designs(workspaceId: $workspaceId appId: $appId) {
      ...DesignSchema
    }
  }
  ${DESIGN_FRAGMENT}
`;

export const UPDATE_DESIGNS = gql`
  mutation updateDesigns(
    $workspaceId: ID!
    $appId: ID!
    $designFields: [DesignInput!]!
  ) {
    updateDesigns(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        designFields: $designFields
      }
    ) {
      designs {
        ...DesignSchema
      }
    }
  }
  ${DESIGN_FRAGMENT}
`;

export const UPDATE_DESIGN = gql`
  mutation updateDesign(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
    $designFields: DesignInput!
  ) {
    updateDesign(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
        designFields: $designFields
      }
    ) {
      design {
        ...DesignSchema
      }
    }
  }
  ${DESIGN_FRAGMENT}
`;
