import React from "react";
import Link from "@mui/material/Link";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Input from "components/atoms/Input/BaseInput";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import Tooltip from "components/atoms/Tooltip";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { MICROSOFT_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import LanguageText from "components/molecules/LanguageText";
import { PlatformApiKey } from "./List";
import PaymentProvider from "./PaymentProvider";

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion } = props;
  const { lang } = getDefaultLocaleAndLang(app);

  const platform = store?.platforms?.find((p) => p.type === "Platform::Xbox");

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store?.metadata || {};
  const metadata = platform.metadata || {};

  const [productName, setProductName] = React.useState(
    storeMetadata.product_name || ""
  );

  const [supportContactInfo, setSupportContactInfo] = React.useState(
    storeMetadata.support_contact_info || ""
  );

  const [packageIdentityName, setPackageIdentityName] = React.useState(
    storeMetadata.package_identity_name || ""
  );

  const [packageIdentityPublisher, setPackageIdentityPublisher] = React.useState(
    storeMetadata.package_identity_publisher || ""
  );

  const [publisherDisplayName, setPublisherDisplayName] = React.useState(
    storeMetadata.publisher_display_name || ""
  );

  const [gaTrackingId, setGaTrackingId] = React.useState(
    metadata.ga_tracking_id || ""
  );

  const saveTimer = React.useRef(null);

  const [asset, setAsset] = React.useState({
    urls: {
      posterArt: platform.assetUrls.poster_art,
      boxArt: platform.assetUrls.box_art,
      appTile: platform.assetUrls.app_tile,
      cover: platform.assetUrls.covers || [],
    },
    blobs: {
      posterArt: null,
      boxArt: null,
      appTile: null,
      cover: [],
    },
    errors: {
      posterArt: null,
      boxArt: null,
      appTile: null,
      cover: null,
    },
  });
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))
  const description = descriptionLangObj[lang] || "";

  const [paymentGateway, setPaymentGateway] = React.useState(store.paymentGateway || "stripe")
  const [lastUploaded, setLastUploaded] = React.useState(null);
  const assetProps = { asset, setAsset, assetMap: MICROSOFT_ASSET_MAP };
  const apiKeys = [{name: "XBOX", apiKey: platform.apiKey}]

  React.useEffect(() => {
    if (
      asset.blobs.posterArt ||
      asset.blobs.boxArt ||
      asset.blobs.appTile ||
      asset.blobs.cover.length > 0
    ) {
      setLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.posterArt,
    asset.blobs.boxArt,
    asset.blobs.appTile,
    asset.blobs.cover
  ]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [
    productName,
    descriptionLangObj,
    supportContactInfo,
    packageIdentityName,
    packageIdentityPublisher,
    publisherDisplayName,
    gaTrackingId,
    lastUploaded,
    paymentGateway
  ]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            paymentGateway,
            metadata: {
              product_name: productName,
              description: descriptionLangObj,
              support_contact_info: supportContactInfo,
              package_identity_name: packageIdentityName,
              package_identity_publisher: packageIdentityPublisher,
              publisher_display_name: publisherDisplayName,
            },
            platformsAttributes: [
              {
                id: platform.id,
                metadata: {
                  ga_tracking_id: gaTrackingId,
                  last_uploaded: lastUploaded,
                },
                assetBlobs: {
                  poster_art: asset.blobs.posterArt,
                  box_art: asset.blobs.boxArt,
                  app_tile: asset.blobs.appTile,
                  covers: asset.blobs.cover,
                },
              },
            ],
          },
        },
      });
    }
  };

  const langInput = (data, onSubmit, title) => (<LanguageText
    data={data}
    onSubmit={onSubmit}
    languageOnly
    buttonProps={{style: {marginBottom: '32px'}}}
    title={title}
  />)

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  const validateForm = () => {
    return true;
  };

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl }, { name: "Microsoft" }]}>
      <PaperGroup>
        <GroupHeader title="General" />

        <Tooltip
          title="The Product name is used only in the Product, not on a user's home screen. Tip: all of the words in the Product name count as keywords when user's search for your app."
        >
          <Input
            label="Product Name"
            defaultValue={productName}
            onChange={setProductName}
            inputProps={{ maxLength: 22 }}
            helperText={`${22 - productName.length} - character limit`}
            required
            autoFocus
          />
        </Tooltip>

        
        <div style={{display: 'flex'}}>
          <Tooltip title="This is a description of your app that will be displayed in the app store. Keep your catchiest content in the first few lines to make sure it is visible to all visitors.">
            <Input
              label="Description"
              value={description}
              inputProps={{ maxLength: 4000 }}
              helperText={`${4000 - description.length} - character limit`}
              multiline
              rows={5}
              required
              onChange={val => {onLangTextChange(val, setDescriptionLangObj)}}
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description")}
        </div>

        <Input
          label="Support Contact Info"
          defaultValue={supportContactInfo}
          onChange={setSupportContactInfo}
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Xbox" />

        <Tooltip title="Copy this from Package/Identity/Name in your xbox developer account">
          <Input
            label="Package Identity Name"
            value={packageIdentityName}
            onChange={setPackageIdentityName}
            required
          />
        </Tooltip>

        <Tooltip title="Copy this from Package/Identity/Publisher in your xbox developer account">
          <Input
            label="Package Identity Publisher"
            value={packageIdentityPublisher}
            onChange={setPackageIdentityPublisher}
            required
          />
        </Tooltip>

        <Tooltip title="Copy this from Package/Properties/PublisherDisplayName in your xbox developer account">
          <Input
            label="Package Display Name"
            value={publisherDisplayName}
            onChange={setPublisherDisplayName}
            required
          />
        </Tooltip>

        <ImageUploadGroup
          {...assetProps}
          name="posterArt"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="boxArt"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="appTile"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="cover"
          horizontal
          previewMaxHeight={108}
        />
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaymentProvider
        getter={paymentGateway}
        setter={setPaymentGateway}
      />

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <GaTrackingIdInput
          label="Xbox Google Analytics Tracking ID"
          defaultValue={gaTrackingId}
          onChange={setGaTrackingId}
          required
        />
      </PaperGroup>
    </FormLayout>
  );
};

export default withStore(Platform);
