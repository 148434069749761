import React from 'react';
import Button from '@mui/material/Button';

export default function New(props) {
  const { children, ...otherProps } = props;
  return (
    <Button
      variant="contained"
      color="primary"
      {...otherProps}
    >{children || `+ New`}</Button>
  );
}
