import { useQuery, useApolloClient } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppsIcon from '@mui/icons-material/Apps';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import clsx from 'clsx';
import { connect } from 'react-redux';

import { GET_CURRENT_APP, GET_CURRENT_APP_PROPS, GET_CURRENT_WORKSPACE } from 'api';
import TitleWithButton from 'components/molecules/TitleWithButton';
import Header from './Header';
import * as actions from 'actions';
import { workspace, app } from 'cache';
import ConfirmDialog from "components/molecules/Dialog/Confirm/Logout";
import NewAppModal from "components/molecules/Modal/NewApp";
import DefaultAppIcon from 'components/atoms/DefaultAppIcon';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appContainer: {
    // padding: theme.spacing(0, 1),
    width: drawerWidth,
    borderColor: `rgba(0,0,0,.12)`,
    height: theme.spacing(8)
  },
  appStatus: {
    fontSize: 10,
    lineHeight: 1.5,
    textAlign: "left",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
  nestedText: {
    fontSize: 14,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: '100vh',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    position: 'relative'
  },
  contentShift: {
    [theme.breakpoints.up('desktop')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
  appButton: {
    height: "100%",
    '& span.MuiButton-startIcon': {
      marginLeft: theme.spacing(.5),
    },
    '& span.MuiButton-endIcon': {
      flexGrow: 1,
      justifyContent: "flex-end",
    }
  },
  appName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: "left",
  },
  createApp: {
    fontWeight: "bold",
    color: theme.palette.primary.main,
  }
}));

function Layout(props) {
  const client = useApolloClient();
  const classes = useStyles();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const cachedApp = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const cachedWorkspace = useQuery(GET_CURRENT_WORKSPACE).data.getCurrentWorkspace;
  const [ linkPath, setLinkPath ] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const { logout, clearRoute } = props;
  const [logoutConfirmation, setLogoutConfirmation] = React.useState(false);
  const [ appAnchor, setAppAnchor ] = React.useState(null);
  const [newApp, setNewApp] = React.useState(null);

  const handleAppClick = (event) => {
    setAppAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAppAnchor(null);
  };

  const handleLinkClick = (data) => {
    if (data.toggle) {
      data.toggle.click();
    } else {
      if (data.path === "/logout") {
        setLogoutConfirmation(true);
      } else {
          setLinkPath(`/${appProps.workspaceId}/${appProps.appId}${data.path}`);
          history.push(`/${appProps.workspaceId}/${appProps.appId}${data.path}`);
      }
    }
  }

  const toggleDrawer = (event) => setDrawerOpen(!drawerOpen);

  React.useEffect(() => {
    if (linkPath) {
      setLinkPath(null);
    }
  }, [linkPath]);

  if (linkPath) {
    return <Redirect to={linkPath} />
  }

  const closeLogoutConfirmation = () => setLogoutConfirmation(false);
  const fullyLogout = () => {
    closeLogoutConfirmation();
    client.resetStore();
    workspace({});
    app({});
    clearRoute();
    logout();
  };

  const startIcon = (app) => {
    if (app.iconUrl)
      return <img src={app.iconUrl} alt="App icon" style={{ width: 40, height: 40 }} />
    return <DefaultAppIcon style={{ width: 40, height: 40, padding: 14 }} />
  }

  return (
    <div className={classes.root}>
      <Header toggleDrawer={toggleDrawer} />
      <Toolbar />

      <div className={classes.page}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <Box borderRight={1} className={classes.appContainer}>
            <Button
              aria-controls="app-menu"
              aria-haspopup="true"
              startIcon={startIcon(cachedApp)}
              endIcon={<KeyboardArrowDownIcon />}
              color="inherit"
              onClick={handleAppClick}
              fullWidth
              className={classes.appButton}
            >
              <Typography variant="h6" color="textSecondary" className={classes.appName}>
                {cachedApp.name}
                <br/>
                <Typography color="textSecondary" className={classes.appStatus}>
                  {_.startCase(cachedApp.status)}
                </Typography>
              </Typography>
            </Button>

            <Menu
              id="app-menu"
              anchorEl={appAnchor}
              open={Boolean(appAnchor)}
              onClose={handleClose}
              keepMounted
            >
              {cachedWorkspace.apps.map((a) => (
                <MenuItem
                  key={`MenuItemWorkspace-${a.id}`}
                  className={classes.appList}
                  onClick={() => {
                    handleClose();
                    if (a.id !== appProps.appId) {
                      props.clearRoute();
                      window.location.href= `/${cachedWorkspace.id}/${a.id}`;
                    }
                  }}
                >
                  {a.name}
                </MenuItem>
              ))}
              <Divider />
              <MenuItem
                className={classes.createApp}
                onClick={() => {
                  setNewApp({});
                  handleClose();
                }}
              >
                Create Brand
              </MenuItem>
            </Menu>

          </Box>
          <Divider />
          <div className={classes.drawerContainer}>
            {props.drawer && (
              <List>
                {props.drawer.data.map((data) => {
                  if (data.name === "divider") {
                    return (<Divider key={`DrawerMenuDivider-${data.key}`}/>);
                  } else {
                    return (
                      <div key={`DrawerMenu-${data.name}`}>
                        <ListItem button onClick={() => handleLinkClick(data)} selected={data.selected}>
                          <ListItemText primary={data.name} />
                          {data.toggle ? (data.toggle.flag ? <ExpandLessIcon /> : <ExpandMoreIcon />) : null}
                        </ListItem>
                        {data.toggle && (
                          <Collapse in={data.toggle.flag} timeout="auto" unmountOnExit>
                            <List className={classes.nested} disablePadding>
                              {data.toggle.data.map((d) => {
                                return (
                                  <ListItem
                                    key={`DrawerSubMenu-${d.name}`}
                                    onClick={() => handleLinkClick(d)}
                                    selected={d.selected}
                                    button
                                    autoFocus={d.selected}
                                  >
                                    <ListItemText
                                      primary={d.name}
                                      primaryTypographyProps={{ className: classes.nestedText }}
                                    />
                                  </ListItem>
                                )
                              })}
                            </List>
                          </Collapse>
                        )}
                      </div>
                    );
                  }
                })}
              </List>
            )}
          </div>
        </Drawer>
        <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
          <Toolbar />
          {props.children}
        </main>
      </div>

      <ConfirmDialog
        open={logoutConfirmation}
        handleClose={closeLogoutConfirmation}
        accept={fullyLogout}
      />

      <NewAppModal
        open={Boolean(newApp)}
        app={newApp}
        onClose={(data) => {
          setNewApp(null);
          if (data && data.__typename === "App") {
            window.location.href = `/${cachedWorkspace.id}/${data.id}`;
          }
        }}
      />
    </div>
  );
}

export default connect(null, actions)(Layout);
