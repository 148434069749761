import { gql } from "@apollo/client";

export const WORKSPACE_USER_FRAGMENT = gql`
  fragment WorkspaceUserSchema on WorkspaceUser {
    __typename
    id
    name
    email
    role
  }
`;

export const GET_WORKSPACE_USERS = gql`
  query workspaceUsers($id: ID!) {
    workspaceUsers(id: $id) {
      id
      name
      email
      role
    }
  }
`;

export const CREATE_WORKSPACE_USER = gql`
  mutation createWorkspaceUser(
    $workspaceId: ID!
    $email: String!
    $role: String!
  ) {
    createWorkspaceUser(
      input: { workspaceId: $workspaceId, email: $email, role: $role }
    ) {
      workspaceUser {
        ...WorkspaceUserSchema
      }
    }
  }
  ${WORKSPACE_USER_FRAGMENT}
`;

export const UPDATE_WORKSPACE_USER = gql`
  mutation updateWorkspaceUser(
    $workspaceId: ID!
    $userId: ID!
    $role: String!
  ) {
    updateWorkspaceUser(
      input: { workspaceId: $workspaceId, userId: $userId, role: $role }
    ) {
      workspaceUser {
        ...WorkspaceUserSchema
      }
    }
  }
  ${WORKSPACE_USER_FRAGMENT}
`;

export const DELETE_WORKSPACE_USER = gql`
  mutation deleteWorkspaceUser(
    $workspaceId: ID!
    $userId: ID!
  ) {
    deleteWorkspaceUser(
      input: { workspaceId: $workspaceId, userId: $userId }
    ) {
      workspaceUser {
        ...WorkspaceUserSchema
      }
    }
  }
  ${WORKSPACE_USER_FRAGMENT}
`;
