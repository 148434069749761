import { gql } from '@apollo/client';

export const PRICE_FRAGMENT = gql`
  fragment PriceSchema on Price {
    __typename
    id
    amount
    tier
  }
`

export const GET_PRICES = gql`
  query prices {
    prices {
      ...PriceSchema
    }
  }
  ${PRICE_FRAGMENT}
`
