import React from "react";

import FormLayout from "components/molecules/Form/FormLayout";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import PaperGroup from "components/molecules/Form/PaperGroup";
import GroupHeader from "components/atoms/Text/GroupHeader";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { VIZIO_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import { PlatformApiKey } from "./List";
import Tooltip from "components/atoms/Tooltip";
import Input from "components/atoms/Input/BaseInput";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import LanguageText from "components/molecules/LanguageText";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { GET_CURRENT_WORKSPACE, WORKSPACE_FRAGMENT } from "api";
import CopyToClipboard from "components/atoms/Button/CopyToClipBoard";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PaymentProvider from "./PaymentProvider";

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    textDecoration: "none", 
    marginTop: "8px"
  },
}));

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion, savedSnack } = props;
  const client = useApolloClient()
  const classes = useStyles();

  const workspace = useQuery(GET_CURRENT_WORKSPACE).data.getCurrentWorkspace

  const platform = store.platforms?.find((p) => p.type === "Platform::Vizio");
  const { lang } = getDefaultLocaleAndLang(app);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store.metadata || {};
  const platformMetadata = platform.metadata || {};
  const [ gaTrackingId, setGaTrackingId ] = React.useState(platformMetadata.ga_tracking_id || "");
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))
  const [titleLangObj, setTitleLangObj] = React.useState(makeDefaultLangObject(storeMetadata.title))
  const description = descriptionLangObj[lang] || "";
  const title = titleLangObj[lang] || "";
  
  const [sourceKey, setSourceKey] = React.useState(workspace.vizioSourceKey || "")
  const [asset, setAsset] = React.useState({
    urls: {
      appIcon: platform.assetUrls.app_icon,
      heroCarousel: platform.assetUrls.hero_carousel,
      availableImage: platform.assetUrls.available_image,
    },
    blobs: {
      appIcon: null,
      heroCarousel: null,
      availableImage: null,
    },
    errors: {
      appIcon: null,
      heroCarousel: null,
      availableImage: null,
    },
  });
  
  const [lastUploaded, setLastUploaded] = React.useState(null);
  const [paymentGateway, setPaymentGateway] = React.useState(store.paymentGateway || "stripe")

  const assetProps = { asset, setAsset, assetMap: VIZIO_ASSET_MAP };
  const saveTimer = React.useRef(null);
  const apiKeys = [{name: "Vizio", apiKey: platform.apiKey}]
  
  const vizioUrl = `${process.env.REACT_APP_CATALOG_URL}/${sourceKey}/feeds/v1/vizio_feeds?key=${platform.apiKey}`;

  React.useEffect(() => {
    if (asset.blobs.appIcon || asset.blobs.heroCarousel || asset.blobs.availableImage) {
      setLastUploaded(new Date().getTime());
    }
  }, [asset.blobs.appIcon, asset.blobs.heroCarousel, asset.blobs.availableImage]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [lastUploaded, gaTrackingId, descriptionLangObj, titleLangObj, sourceKey, paymentGateway]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            metadata: {
              description: descriptionLangObj,
              title: titleLangObj
            },
            vizioSourceKey: sourceKey,
            paymentGateway,
            platformsAttributes: [
              {
                id: platform.id,
                metadata: {
                  ga_tracking_id: gaTrackingId,
                  last_uploaded: lastUploaded
                },
                assetBlobs: {
                  app_icon: asset.blobs.appIcon,
                  hero_carousel: asset.blobs.heroCarousel,
                  available_image: asset.blobs.availableImage
                },
              },
            ],
          },
        },
        update(cache,  { data: { updateAppStore } }) {
          cache.modify({
            fields: {
              appStores(existingRefs, { readField }) {
                return existingRefs.map((ref) =>
                  updateAppStore.appStore.id === readField("id", ref)
                    ? { ...ref, ...updateAppStore.appStore }
                    : ref
                );
              },
            },
          });
          const workspaceFragment = gql`
          fragment WorkspaceSchema on Workspace {
            id
            vizioSourceKey
          }`
          const existingWorkspace = client.readFragment({ id: `Workspace:${workspace.id}`, fragment: workspaceFragment });
          const updated = {
            ...existingWorkspace,
            vizioSourceKey: sourceKey
          }
          client.writeFragment({id: `Workspace:${workspace.id}`, fragment: workspaceFragment, data: updated});
          savedSnack.launch();
        },
      });
    }
  };

  const langInput = (data, onSubmit, title, charLimit=null) => (
    <LanguageText
      data={data}
      onSubmit={onSubmit}
      languageOnly
      buttonProps={{style: {marginBottom: '32px'}}}
      title={title}
      charLimit={charLimit}
    />);

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  const availableValidation = (size) => {
    if (size.width >= 1500 && size.width > size.height) return true;
    return false
  }

  const validateForm = () => {
    return true;
  };

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl  }, { name: "Vizio" }]}>
      <PaperGroup>
        <ImageUploadGroup {...assetProps} name="appIcon" previewMaxHeight={128} horizontal />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="heroCarousel" horizontal />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Vizio search feed" />
        <div style={{display: 'flex'}}>
          <Tooltip title="">
            <Input
              label="App title"
              value={title}
              onChange={val => {onLangTextChange(val, setTitleLangObj)}}
              inputProps={{ maxLength: 50 }}
              helperText={`${50 - title.length} - character limit`}
              required
              autoFocus
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(titleLangObj, setTitleLangObj, "Title", 50)}
        </div>

        <div style={{display: 'flex'}}>
          <Tooltip title="">
            <Input
              label="Description"
              value={description}
              onChange={val => {onLangTextChange(val, setDescriptionLangObj)}}
              inputProps={{ maxLength: 2000 }}
              helperText={`${2000 - description.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description", 2000)}
        </div>
        <Tooltip title="This key will always be same for all the apps in a workspace. Please change carefully.">
            <Input
              label="Source Key"
              value={sourceKey}
              onChange={setSourceKey}
              required
            />
        </Tooltip>
        {
          sourceKey && apiKeys[0].apiKey !== null ?
            <div>
              <Typography component="a" href={vizioUrl} target="_blank" variant="p" className={classes.linkStyle}>Vizio Search Feed URL</Typography>
              <CopyToClipboard
                copyText={vizioUrl}
                copyTip={"Copy URL"}
                onCopy={() => { savedSnack.launch("Copied to clipboard") }}
              />
            </div> : null
        }
        <ImageUploadGroup {...assetProps} name="availableImage" horizontal uploadValidation={availableValidation}/>
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaymentProvider
        getter={paymentGateway}
        setter={setPaymentGateway}
      />

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <GaTrackingIdInput
          label="Google Analytics Tracking ID"
          defaultValue={gaTrackingId}
          onChange={setGaTrackingId}
          required
        />
      </PaperGroup>



    </FormLayout>
  );
};

export default withStore(Platform);
