import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import LoginConfigurations from './LoginConfigurations';
import Fonts from './Fonts';
import General from './General';
import Launch from './Launch';
import Saved from './Saved';
import Guide from './Guide';
import Menus from './Menus';
import Onboarding from './Onboarding';
import Inline from './Inline';
import Legal from './Legal'

export default function Sections(props) {
  const { path } = useRouteMatch();
  const { savedSnack, isMultiLangOrRegion } = props;

  const commonProps = { savedSnack, isMultiLangOrRegion };

  return (
    <Switch>
      <Route exact path={`${path}/fonts`}><Fonts {...commonProps}/></Route>
      <Route exact path={`${path}/login_configurations`}><LoginConfigurations {...commonProps}/></Route>
      <Route exact path={`${path}/launch`}><Launch {...commonProps}/></Route>
      <Route exact path={`${path}/saved`}><Saved {...commonProps}/></Route>
      <Route exact path={`${path}/guide`}><Guide {...commonProps}/></Route>
      <Route exact path={`${path}/menus`}><Menus {...commonProps}/></Route>
      <Route exact path={`${path}/general`}><General {...commonProps}/></Route>
      <Route exact path={`${path}/onboarding`}><Onboarding {...commonProps} /></Route>
      <Route exact path={`${path}/video_settings`}><Inline {...commonProps}/></Route>
      <Route exact path={`${path}/legal`}><Legal {...commonProps}/></Route>
      <Redirect to={`${path}/general`}/>
    </Switch>
  );
}
