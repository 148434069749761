import React from 'react';
import Confirm from './Confirm';

export default function LiveChannel(props) {
  return (
    <Confirm
      {...props}
      content="This programme will be deleted. Are you sure you want to proceed?"
    />
  );
}
