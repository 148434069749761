import React from 'react';
import ColorPicker from 'components/atoms/Input/ColorPicker';
import { withDesign } from './Design';
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import Layout from './Layout';

function Guide(props) {
  const { simulator, designs } = props;
  const design = simulator.design;


  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [ epgBorderColor, setEpgBorderColor ] = React.useState(design.epgBorderColor);
  const [ epgProgramLabelColor, setEpgProgramLabelColor ] = React.useState(design.epgProgramLabelColor);
  const [ epgInactiveProgramColor, setEpgInactiveProgramColor ] = React.useState(design.epgInactiveProgramColor);
  const [ epgActiveProgramColor, setEpgActiveProgramColor ] = React.useState(design.epgActiveProgramColor);
  const [ epgTimeMarkerColor, setEpgTimeMarkerColor ] = React.useState(design.epgTimeMarkerColor);
  const [ epgTimeDisplayColor, setEpgTimeDisplayColor ] = React.useState(design.epgTimeDisplayColor);
  const [ epgChannelColor, setEpgChannelColor ] = React.useState(design.epgChannelColor);
  const [ epgChannelLabelColor, setEpgChannelLabelColor ] = React.useState(design.epgChannelLabelColor);
  const [ epgTimelineBackgroundColor, setEpgTimelineBackgroundColor ] = React.useState(design.epgTimelineBackgroundColor);
  const [ epgDetailsBackgroundColor, setEpgDetailsBackgroundColor] = React.useState(design.epgDetailsBackgroundColor);
  const [ epgGuideBackgroundColor, setEpgGuideBackgroundColor] = React.useState(design.epgGuideBackgroundColor);
  const [ epgDetailsPrimaryTextColor, setDetailsPrimaryTextColor] = React.useState(design.epgDetailsPrimaryTextColor);
  const [ epgDetailsSecondaryTextColor, setEpgDetailsSecondaryTextColor] = React.useState(design.epgDetailsSecondaryTextColor);
  const [ epgProgramSubtitleColor, setEpgProgramSubtitleColor] = React.useState(design.epgProgramSubtitleColor);

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [epgBorderColor, epgProgramLabelColor, epgActiveProgramColor, epgInactiveProgramColor, epgTimeMarkerColor,
    epgTimeDisplayColor, epgChannelColor, epgChannelLabelColor, epgTimelineBackgroundColor,
    epgDetailsBackgroundColor, epgGuideBackgroundColor, epgDetailsPrimaryTextColor, epgDetailsSecondaryTextColor,
    epgProgramSubtitleColor]);

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  const cancel = () => {
    setEpgBorderColor(design.epgBorderColor);
    setEpgProgramLabelColor(design.epgProgramLabelColor); 
    setEpgInactiveProgramColor(design.epgInactiveProgramColor); 
    setEpgActiveProgramColor(design.epgActiveProgramColor);
    setEpgTimeMarkerColor(design.epgTimeMarkerColor);
    setEpgTimeDisplayColor(design.epgTimeDisplayColor);
    setEpgChannelColor(design.epgChannelColor); 
    setEpgChannelLabelColor(design.epgChannelLabelColor);
    setEpgTimelineBackgroundColor(design.epgTimelineBackgroundColor);
    setEpgDetailsBackgroundColor(design.epgDetailsBackgroundColor);
    setEpgGuideBackgroundColor(design.epgGuideBackgroundColor);
    setDetailsPrimaryTextColor(design.epgDetailsPrimaryTextColor);
    setEpgDetailsSecondaryTextColor(design.epgDetailsSecondaryTextColor);
    setEpgProgramSubtitleColor(design.epgProgramSubtitleColor);

    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const stateDesign = {
    epgBorderColor,
    epgProgramLabelColor,
    epgInactiveProgramColor,
    epgActiveProgramColor,
    epgTimeMarkerColor,
    epgTimeDisplayColor,
    epgChannelColor,
    epgChannelLabelColor,
    epgTimelineBackgroundColor,
    epgDetailsBackgroundColor,
    epgGuideBackgroundColor,
    epgDetailsPrimaryTextColor,
    epgDetailsSecondaryTextColor,
    epgProgramSubtitleColor
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm: stateDesign,
    simulator: { ...simulator },
    tab: "Guide",
    headerTitle: "Program Guide",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  const borderColor = <ColorPicker getter={epgBorderColor} setter={setEpgBorderColor} label="Border Color" disabled={disableForm}/>;
  const programLabelColor = <ColorPicker getter={epgProgramLabelColor} setter={setEpgProgramLabelColor} label="Program Label Color" disabled={disableForm}/>;
  const inactiveProgramColor = <ColorPicker getter={epgInactiveProgramColor} setter={setEpgInactiveProgramColor} label="Inactive Program Color" disabled={disableForm}/>;
  const activeProgramColor = <ColorPicker getter={epgActiveProgramColor} setter={setEpgActiveProgramColor} label="Active Program Color" disabled={disableForm}/>;
  const timeMarkerColor = <ColorPicker getter={epgTimeMarkerColor} setter={setEpgTimeMarkerColor} label="Time Marker Color" disabled={disableForm}/>;
  const timeDisplayColor = <ColorPicker getter={epgTimeDisplayColor} setter={setEpgTimeDisplayColor} label="Time Display Color" disabled={disableForm}/>;
  const channelColor = <ColorPicker getter={epgChannelColor} setter={setEpgChannelColor} label="Channel Color" disabled={disableForm}/>;
  const channelLabelColor = <ColorPicker getter={epgChannelLabelColor} setter={setEpgChannelLabelColor} label="Channel Label Color" disabled={disableForm}/>;
  const timelineBackgroundColor = <ColorPicker getter={epgTimelineBackgroundColor} setter={setEpgTimelineBackgroundColor} label="Timeline Background" disabled={disableForm}/>;
  const detailsBackgroundColor = <ColorPicker getter={epgDetailsBackgroundColor} setter={setEpgDetailsBackgroundColor} label="Details Background" disabled={disableForm}/>;
  const guideBackgroundColor = <ColorPicker getter={epgGuideBackgroundColor} setter={setEpgGuideBackgroundColor} label="Guide Background" disabled={disableForm}/>;
  const detailsPrimaryTextColor = <ColorPicker getter={epgDetailsPrimaryTextColor} setter={setDetailsPrimaryTextColor} label="Details Primary Text" disabled={disableForm}/>;
  const detailsSecondaryTextColor = <ColorPicker getter={epgDetailsSecondaryTextColor} setter={setEpgDetailsSecondaryTextColor} label="Details Secondary Text" disabled={disableForm}/>;
  const programSubtitleColor = <ColorPicker getter={epgProgramSubtitleColor} setter={setEpgProgramSubtitleColor} label="Program Subtitle" disabled={disableForm}/>;
  
  return (
      <Layout {...layoutProps}>
      {detailsPrimaryTextColor}
      {detailsSecondaryTextColor}
      {detailsBackgroundColor}
      {timelineBackgroundColor}
      {timeMarkerColor}
      {timeDisplayColor}
      {channelColor}
      {channelLabelColor}
      {borderColor}
      {programLabelColor}
      {programSubtitleColor}
      {inactiveProgramColor}
      {activeProgramColor}
      {guideBackgroundColor}
      </Layout>
  );
}

export default withDesign(withSimulator(Guide));
