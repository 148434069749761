import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import { continents, countries } from "countries-list";
import React from 'react';

import { GET_CURRENT_APP_PROPS, CREATE_APP, UPDATE_APP, ME } from "api";
import { workspace } from "cache";
import Base from './Base';
import Input from "components/atoms/Input/BaseInput";
import SubmitButton from "components/atoms/Button/Submit";
import useWindowSize from "customHooks/useWindowSize";

export default function NewApp(props) {
  const theme = useTheme();
  const client = useApolloClient();
  const size = useWindowSize();
  const { app, ...otherProps } = props;

  const [ name, setName ] = React.useState(app?.name);
  const [ nameError, setNameError ] = React.useState(null);

  const [modalWidth, setModalWidth] = React.useState(100);

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;

  const appFields = {
    name,
  };

  const [ createApp, {loading: createLoading} ] = useMutation(CREATE_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      name,
    },
    update(cache, { data: { createApp } }) {
      const existingMe = client.readQuery({ query: ME });

      const newMe = {
        ...existingMe.me,
        workspaces:
          existingMe.me.workspaces.map((w) => (
            w.id === appProps.workspaceId
              ? { ...w, apps: [ ...w.apps, createApp.app ] }
              : w
          )),
      };

      workspace(newMe.workspaces.find((w) => w.id === appProps.workspaceId));
      client.writeQuery({ query: ME, data: { me: newMe } });
    },
    onCompleted(data) {
      onSave(data.createApp.app);
    },
    onError(data) {
      handleError(data);
    }
  });

  const [ updateApp, {loading: updateLoading} ] = useMutation(UPDATE_APP, {
    variables: {
      workspaceId: appProps.workspaceId,
      id: app?.id,
      appFields: appFields,
    },
    onCompleted(data) {
      onSave(data.updateApp.app);
    },
    onError(data) {
      handleError(data);
    }
  });

  React.useEffect(() => {
    if (app) {
      setName(app.name);
    }
  }, [app]);

  React.useEffect(() => {
    setNameError(null);
  }, [name]);

  React.useEffect(() => {
    if(size?.width > 800) setModalWidth(100);
    else if (size?.width <= 800 && size?.width > 600) setModalWidth(60);
    else if(size?.width <= 600) setModalWidth(50);
  }, [size]);

  function onSave(data) {
    props.savedSnack?.launch();
    props.onClose(data);
  }

  function handleError(data) {
    console.log(data);
    setNameError(data.message);
  }

  function saveAndClose() {
    if (isValid()) {
      app.id ? updateApp() : createApp();
    }
  }

  function submit(ev) {
    ev.preventDefault();
    saveAndClose();
  }

  function isValid() {
    if (!Boolean(name)) {
      return false;
    }

    return true;
  }

  const isLoading = createLoading || updateLoading;

  return (
    <Base
      title={app?.id ? "Edit Brand" : "New Brand"}
      titleRight={() => <SubmitButton disabled={(!Boolean(name) || isLoading)} onClick={saveAndClose} isLoading={isLoading}>Done</SubmitButton>}
      dimensions={{
        width: theme.spacing(modalWidth),
        height: theme.spacing(30),
      }}
      {...otherProps}
    >
      <form style={{ width: "100%" }} onSubmit={submit}>
        <Input
          label="App Name"
          defaultValue={name}
          onChange={setName}
          error={Boolean(nameError)}
          inputProps={{ maxLength: 25 }}
          helperText={nameError || `${25 - (name?.length || 0)} - character limit`}
          autoFocus
          required
        />
      </form>
    </Base>
  );
}
