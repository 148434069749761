import { useQuery, useMutation } from "@apollo/client";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";
import _ from "underscore";
import urlJoin from "url-join";
import { Redirect, useLocation } from "react-router-dom";
import moment from "moment";

import { GET_FEEDS, GET_CURRENT_APP_PROPS, DELETE_FEED } from "api";
import DataGrid from "components/atoms/DataGrid";
import FormLayout from "components/molecules/Form/FormLayout";
import ConfirmDialog from "components/molecules/Dialog/Confirm/Feed";
import { withRedirect } from "components/containers/Redirect";
import ContentLayout from "screens/Layout/ContentLayout";
import { LANGUAGE_MAP } from "helpers/langUtils";
import { friendlyDates } from "helpers/dateUtil";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 300,
    sortable: true,
    sort:"asc",
    filter: true,
  },
  {
    field: "language",
    headerName: "Language",
    width: 150,
    valueGetter: (params) => LANGUAGE_MAP[params.data.language]
  },
  {
    field: "poll",
    headerName: "Poll",
    width: 150,
    cellRenderer: "pollCellRenderer",
  },
  {
    field: "lastPolledAt",
    headerName: "Last Polled",
    width: 240,
    type: "dateTime",
    sortable: true,
    valueGetter: (params) =>
      params.data.lastPolledAt ? friendlyDates(params.data.lastPolledAt) : null,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
  },
];

const PollCellRenderer = (params) => {
  return params.data.poll ? <CheckCircleIcon /> : <CancelIcon />
};

function Feeds(props) {
  const { formats, savedSnack, redirect } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_FEEDS, { variables: { ...appProps, formats } });

  const { pathname } = useLocation();
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(null);
  };

  const [deleteFeed] = useMutation(DELETE_FEED, {
    update(cache, { data: { deleteFeed } }) {
      cache.modify({
        fields: {
          feeds(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteFeed.feed.id !== readField("id", ref)
            );
          },
        },
      });
    },
    onCompleted(data) {
      savedSnack.launch("Deleted");
      closeDeleteConfirmation();
    },
    onError(data) {
      console.log(data);
    },
  });

  function deleteFeedApi(id) {
    deleteFeed({ variables: { ...appProps, id: id } });
  }

  function onDelete(feed) {
    setDeleteConfirmation(feed);
  }

  function onCopy(text, result) {
    savedSnack.launch("Copied to clipboard");
  }

  return (
    <ContentLayout
      title="Feeds"
      fab={{ onClick: () => redirect.set(urlJoin(pathname, "new")) }}
    >
      <FormLayout>
        <DataGrid
          frameworks={{
            pollCellRenderer: PollCellRenderer,
          }}
          rowData={
            data?.feeds
              ? _.reject(data.feeds, (af) => af.format === "root").map(
                  (af) => ({ ...af, actions: { launchLink: af.url, copyText: af.url, onDelete, onCopy } })
                )
              : []
          }
          columnDefs={columns}
        />
      </FormLayout>

      <ConfirmDialog
        open={Boolean(deleteConfirmation)}
        handleClose={closeDeleteConfirmation}
        accept={() => deleteFeedApi(deleteConfirmation.id)}
      />
    </ContentLayout>
  );
}

export default withRedirect(Feeds);
