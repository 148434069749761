import React from "react";
import DataGrid from "components/atoms/DataGrid";
import FormLayout from "components/molecules/Form/FormLayout";
import _ from "lodash";

import ios_platform from "images/store/ios-store.png";
import tvos_platform from "images/store/tvos-store.png";
import android_platform from "images/store/android-store.png";
import android_tv_platform from "images/store/android_tv-store.png";
import amazon_platform from "images/store/amazon-store.png";
import fire_tv_platform from "images/store/fire_tv-store.png";
import roku_platform from "images/store/roku-store.png";
import web_platform from "images/store/web_as_output-store.png";
import vizio_platform from "images/store/vizio-store.png";
import lg_platform from "images/store/lg-store.png";
import xbox_platform from "images/store/xbox-store.png";
import samsung_platform from "images/store/samsung-store.png";
import sony_platform from "images/store/sony-store.png";
import sharp_platform from "images/store/sharp-store.png";
import tcl_platform from "images/store/tcl-store.png";
import panasonic_platform from "images/store/panasonic-store.png";
import philips_platform from "images/store/philips-store.png";
import { fullUrl } from "helpers/platformUtils";
import ContentLayout from "screens/Layout/ContentLayout";

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { withRedirect } from "components/containers/Redirect";
import platformValidator from './validations'
import PaperGroup from "components/molecules/Form/PaperGroup";
import GroupHeader from "components/atoms/Text/GroupHeader";
import CopyToClipboard from "components/atoms/Button/CopyToClipBoard";
import { withSavedSnack } from "components/molecules/Alerts/Saved";

const DATA_MAP = {
  "Platform::Ios": {
    name: "iOS",
    storeName: "Apple",
    link: "https://developer.apple.com/",
    image: ios_platform,
    validator: platformValidator.iosValidator
  },
  "Platform::Tvos": {
    name: "tvOS",
    storeName: "Apple",
    link: "https://developer.apple.com/",
    image: tvos_platform,
    validator: platformValidator.tvosValidator
  },
  "Platform::Android": {
    name: "Android",
    storeName: "Google Play",
    link: "https://play.google.com/store",
    image: android_platform,
    validator: platformValidator.googleMobileValidator
  },
  "Platform::AndroidTv": {
    name: "Android TV",
    storeName: "Google Play",
    link: "https://play.google.com/store",
    image: android_tv_platform,
    validator: platformValidator.googleTvValidator
  },
  "Platform::Sony": {
    name: "Sony",
    storeName: "Google Play",
    link: "https://play.google.com/store",
    image: sony_platform,
    validator: platformValidator.googleTvValidator
  },
  "Platform::Panasonic": {
    name: "Panasonic",
    storeName: "Google Play",
    link: "https://play.google.com/store",
    image: panasonic_platform,
    validator: platformValidator.googleTvValidator
  },
  "Platform::Tcl": {
    name: "TCL",
    storeName: "Google Play",
    link: "https://play.google.com/store",
    image: tcl_platform,
    validator: platformValidator.googleTvValidator
  },
  "Platform::Sharp": {
    name: "Sharp",
    storeName: "Google Play",
    link: "https://play.google.com/store",
    image: sharp_platform,
    validator: platformValidator.googleTvValidator
  },
  "Platform::Philips": {
    name: "Philips",
    storeName: "Google Play",
    link: "https://play.google.com/store",
    image: philips_platform,
    validator: platformValidator.googleTvValidator
  },
  "Platform::Amazon": {
    name: "Amazon Mobile/Tablet",
    storeName: "Amazon",
    link: "https://developer.amazon.com/",
    image: amazon_platform,
    validator: platformValidator.amazonMobileValidator
  },
  "Platform::FireTv": {
    name: "Amazon Fire TV",
    storeName: "Amazon",
    link: "https://developer.amazon.com/",
    image: fire_tv_platform,
    validator: platformValidator.amazonTvValidator
  },
  "Platform::Lg": {
    name: "WebOS",
    storeName: "LG",
    link: "https://seller.lgappstv.com/seller/main/Main.lge",
    image: lg_platform,
    validator: platformValidator.lgValidator
  },
  "Platform::Roku": {
    name: "Roku",
    storeName: "Roku",
    link: "https://developer.roku.com",
    image: roku_platform,
    validator: platformValidator.rokuValidator
  },
  "Platform::Samsung": {
    name: "Tizen",
    storeName: "Samsung",
    link: "https://seller.samsungapps.com/tv",
    image: samsung_platform,
    validator: platformValidator.tizenValidator
  },
  "Platform::Vizio": {
    name: "SmartCast",
    storeName: "Vizio",
    link: "https://www.vizio.com/en/shop/tv",
    image: vizio_platform,
    validator: platformValidator.vizioValidator
  },
  "Platform::Web": {
    name: "HTML5",
    storeName: "Web",
    image: web_platform,
    validator: platformValidator.webValidator
  },
  "Platform::Xbox": {
    name: "Xbox",
    storeName: "Microsoft",
    image: xbox_platform,
    validator: platformValidator.xboxValidator
  },
};

const STORE_PLATFORM_MAP = {
  'Google Play' : 'google',
  'Apple': 'apple',
  'Web': 'web',
  'Vizio': 'vizio',
  'Samsung': 'samsung',
  'Roku': 'roku',
  'LG': 'lg',
  'Amazon': 'amazon',
  'Microsoft': 'microsoft',
}

const autoGroupColumnDef = {
  headerName: "Name",
  field: 'title',
  width: 330,
  cellRendererParams: {
    suppressCount: true,
    innerRenderer: 'imageTitleRenderer'
  }
};

const columns = [
  {
    field: "platformStatus",
    headerName: "Status",
    width: 150,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    flex: 1,
  },
];

function List(props) {
  const { stores, updateStoreApi, appProps, redirect } = props;

  const toggleActive = (platform, active, event) => {
    updateStoreApi({
      variables: {
        ...appProps,
        appStoreId: platform.storeId,
        appStoreFields: {
          platformsAttributes: [
            {
              id: platform.id,
              isActive: active,
            },
          ],
        },
      },
    });
  };

  const toogleAppStoreActive = (store, active, e) => {
    updateStoreApi({
      variables: {
        ...appProps,
        appStoreId: store.id,
        appStoreFields: {
          isActive: active
        }
      }
    });
  }

  const onBuild = () => {};

  let platforms = [];
  stores?.forEach((store) => {
    platforms = [
      ...platforms,
      ...store.platforms.map((p) => ({ ...p, storeId: store.id, storeIsActive: store.isActive, storeName: store.name })),
    ];
  });

  let sortedPlatforms = [];
  if (platforms.length > 0) {
    Object.keys(DATA_MAP).forEach((d) => {
      const platform = platforms.find((p) => p.type === d);
      if (platform) {
        sortedPlatforms.push(platform);
      }
    });
  }

  const getDataPath = data => {
    return data.hierarchy;
  }

  const rowData = sortedPlatforms.map((p) => {
    return {
      ...p,
      image: DATA_MAP[p.type].image,
      imageWidth: 32,
      title: DATA_MAP[p.type].name,
      storeName: p.storeName,
      isActive: p.isActive ? _.capitalize(p.status) : null,
      hierarchy: [p.storeName, DATA_MAP[p.type].name],
      // platformStatus: p.status && p.isActive ? (p.status.toLowerCase() == "live" ? "Live" : "Incomplete") : null,
      platformStatus: p.storeIsActive ? (DATA_MAP[p.type].validator(p, stores) ? 'Complete': 'Incomplete'): null,
      actions: {
        launchLink: p.type.includes("Web") && p.metadata && p.metadata.subdomain
          ? p.metadata.url || fullUrl(p.metadata.subdomain)
          : DATA_MAP[p.type].link,
        toggle: p.storeIsActive && {
          getter: p.isActive,
          setter: (value, event) => p.type.includes("Web") ? null : toggleActive(p, value, event),
          disabled: p.type.includes("Web") ? true : !DATA_MAP[p.type].validator(p, stores),
          title: p.isActive ? "Switch off" : "Switch on",
        },

        // buildLink: p.buildable
        //   ? `/${appProps.workspaceId}/${appProps.appId}/design/platforms/build/${p.type.split("::")[1].toLowerCase()}`
        //   : null
      },
    }
  })

  const parentRows = React.useMemo(() => getList(`/${appProps.workspaceId}/${appProps.appId}/design/platforms`, redirect, toogleAppStoreActive, stores), [stores]) ;


  return (
    <ContentLayout title="Platforms">
      <FormLayout breadcrumbs={[{ name: "Platforms" }]}>
        <DataGrid
          modules={[ClientSideRowModelModule, RowGroupingModule]}
          rowData={_.sortBy([...rowData, ...parentRows], (p) => p.title)}
          columnDefs={columns}
          immutableData={true}
          getRowNodeId={(data) => data.id}
          // groupDisplayType={"groupRows"}
          treeData={true}
          getDataPath={getDataPath}
          autoGroupColumnDef={autoGroupColumnDef}
          groupDefaultExpanded={-1}
          // showRowGroup={false}
          groupDisplayType={'custom'}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(List);

function getList(initUrl, redirect, toogleAppStore, storeList=[]) {
  if (!storeList.length) return [];

  const headerRows = Object.keys(STORE_PLATFORM_MAP).map(key => {
    const storeObj = storeList.find(s => s.name === STORE_PLATFORM_MAP[key]);
    return {
      id: key,
      title: key,
      url: `${initUrl}/${STORE_PLATFORM_MAP[key]}`,
      hierarchy: [STORE_PLATFORM_MAP[key]],
      actions: {
        onEdit: storeObj?.isActive ? ((v) => {redirect.set(v.url)}) : null,
        toggle: {
          getter: storeObj?.isActive,
          setter: (value, event) => {toogleAppStore(storeObj, value, event)},
          title: storeObj?.isActive ? "Switch off" : "Switch on",
        }
      }
    }
  })
  return headerRows
}

function PlatformApiComponent({apiKeys=[], savedSnack}) {
  return (
    <PaperGroup>
      <GroupHeader title="API Keys" />
      {apiKeys.map(platform => (
        <div>
          <b>{platform.name} - </b><span>{platform.apiKey}</span>
            <CopyToClipboard
              copyText={platform.apiKey}
              copyTip={"Copy key"}
              onCopy={() => {savedSnack.launch("Copied to clipboard")}}
            />
        </div>
      ))}
    </PaperGroup>
  )
}

export const PlatformApiKey = withSavedSnack(PlatformApiComponent)
