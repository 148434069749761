import React from 'react';
import TextField from '@mui/material/TextField';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';



export default function Picker(props) {
  const { label, value, onChange, style, ...otherProps } = props;

  return (
    <MobileDateTimePicker
      label={label}
      inputVariant="outlined"
      margin="normal"
      value={value}
      onChange={onChange}
      renderInput={(value) => <TextField sx={{mt:2, mb:2, maxWidth:600, ...style}}  {...value} />}
      required
      {...otherProps}
    />
  );
}
