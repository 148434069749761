import React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import _ from "underscore";

import { withDesign } from "./Design";
import withSimulator from 'components/molecules/SimulatorLayout/withSimulator';
import Layout from "./Layout";
import NewButton from "components/atoms/Button/New";
import DataGrid from "components/atoms/DataGrid";
import Select from "components/atoms/Input/Select";
import FontSelectorModal from "components/molecules/Modal/FontSelector";
import PaperGroup from "components/molecules/Form/PaperGroup";

const sanitizedFontName = (font) => font.postscriptName || font.name;
const columns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "name",
    headerName: "Name",
    width: 300,
    valueGetter: (params) => params.data.postscriptName || params.data.name,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    flex:1,
    minWidth: 300
  },
];

function Fonts(props) {
  const { simulator, designs } = props;
  const design = simulator.design;

  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);
  const [fonts, setFonts] = React.useState(designs[0]?.fonts);
  const [primaryFontId, setPrimaryFontId] = React.useState(
    design.primaryFontId
  );
  const [secondaryFontId, setSecondaryFontId] = React.useState(
    design.secondaryFontId
  );
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    setFonts(design.fonts);
  }, [design.fonts]);
  
  React.useEffect(() => {
    setIsFormChanged(true);
  }, [primaryFontId, secondaryFontId, fonts]);

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  const cancel = () => {
    setPrimaryFontId(design.primaryFontId);
    setSecondaryFontId(design.secondaryFontId);
    setFonts(design.fonts)
    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  const formchange = (value) => setIsFormChanged(value);
  const disableform = (value) => setDisableForm(value);

  const handlePrimaryFontId = (val) => {
    if (val === "null") {
      setPrimaryFontId(null);
    } else {
      setPrimaryFontId(val);
    }
  };

  const handleSecondaryFontId = (val) => {
    if (val === "null") {
      setSecondaryFontId(null);
    } else {
      setSecondaryFontId(val);
    }
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const stateDesign = {
    fontsAttributes: fonts.map((f) =>
      _.omit(f, "__typename", "fontType", "postscriptName")
    ),
    primaryFontId,
    secondaryFontId,
  };

  const layoutProps = {
    design,
    designs,
    stateDesign,
    stateForm: stateDesign,
    simulator: { ...simulator, noDevice: true },
    right: () => <NewButton onClick={openModal}>+ Google Fonts</NewButton>,
    tab: "Fonts",
    isChanged: isFormChanged,
    betterForm: true, 
    cancel,
    formchange,
    disableform
  };

  const fontOptions = [
    { label: "System Font", value: "null" },
    ...fonts.map((f) => ({ label: sanitizedFontName(f), value: f.id })),
  ];

  const onDelete = (font) => {
    setFonts(
      fonts.map((f) =>
        f.id === font.id ? { ...f, _destroy: true } : f
      )
    );
  }

  return (
    <Layout {...layoutProps}>
      <PaperGroup>
        <Select
          value={`${primaryFontId}`}
          onChange={handlePrimaryFontId}
          options={fontOptions}
          label="Primary Feed Font"
          disabled={disableForm}
          required
        />

        <Select
          value={`${secondaryFontId}`}
          onChange={handleSecondaryFontId}
          options={fontOptions}
          label="Secondary Feed Font"
          disabled={disableForm}
          required
        />
      </PaperGroup>

      {fonts.length > 0 && (
        <>
          <div style={{ height: 16 }}>&nbsp;</div>
          <DataGrid
            rowData={_.reject(fonts, (f) => Boolean(f._destroy)).map(f => ({
              ...f,
              actions: {
                onDelete
              }
            }))}
            columnDefs={columns}
            autoHeight
          />
        </>
      )}

      <FontSelectorModal
        open={modalOpen}
        onClose={closeModal}
        fonts={fonts}
        setFonts={setFonts}
        designs={designs}
      />
    </Layout>
  );
}

export default withDesign(withSimulator(Fonts));
