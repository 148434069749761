import { useQuery } from "@apollo/client";
import React from 'react';

import { GET_CURRENT_APP_PROPS, GET_APP_USERS } from "api";
import DataGrid from "components/atoms/DataGrid";
import FormLayout from "components/molecules/Form/FormLayout";

import { withSavedSnack } from "components/molecules/Alerts/Saved";
import ContentLayout from "screens/Layout/ContentLayout";

const columns = [
  { field: "email", headerName: "Email", width: 400, sortable: true, sort: 'asc' },
  { field: "actions", headerName: "Actions", cellRenderer: "actionCellRenderer", minwidth:400, flex:1 },
];

function Customers(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_APP_USERS, { variables: appProps });

  const onCopy = () => {
    props.savedSnack.launch("Copied");
  };

  return (
    <ContentLayout title="Consumers">
      <FormLayout>
        <DataGrid
          rowData={data?.appUsers
            ? data.appUsers.map((au) => ({ ...au, actions: { email: au.email, copyTip: "Copy", copyText: au.email, onCopy: onCopy } }))
            : []}
          columnDefs={columns}
          accentedSort={true}
          immutableData={true}
          getRowNodeId={(data) => data.id}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withSavedSnack(Customers);
