import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import Dashboard from "./Dashboard";
import Design from "./Design";
import Platforms from "./Platforms";

export default function Apps(props) {
  const { path } = useRouteMatch();
  const { savedSnack, isMultiLangOrRegion } = props;

  const commonProps = { savedSnack, isMultiLangOrRegion };

  return (
    <Switch>
      {/* <Route path={`${path}/dashboard`}>
        <Dashboard />
      </Route> */}
      <Route path={`${path}/platforms`}>
        <Platforms {...commonProps} />
      </Route>
      <Route path={`${path}`}>
        <Design {...commonProps} />
      </Route>
    </Switch>
  );
}
