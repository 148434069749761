import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
    BottomNavigationAction,
    BottomNavigation,
  } from "@mui/material";
import selected from "images/selected-icon.svg";
import unselected from "images/unselected-icon.svg";
import savedIcon from "images/star.png";

const useStyles = makeStyles((theme) => ({
    footer: props => ({
        width: "100%",
        bottom: 0,
        position: "absolute",
        backgroundColor: props.themeMobileTabBar,
        borderTop: `1px solid ${props.themeMobileBorder}`
    }),
    star: {
        WebkitFilter: "invert(100)",
        width: "24px",
        margin: "2px",
    }
}))

function Footer(props) {
    const { simulator } = props;
    const { design, tab } = simulator;
    const classes = useStyles(design);
    const footVal = {
        General: 0,
        Saved: 2,
      };

    React.useEffect(() => {
      if (simulator.deviceName !== 'TV') {
        try {
          let svg = document.querySelector('#selected_id').getSVGDocument()
          svg.querySelectorAll('path').forEach(path => path.style.stroke = design.themeSecondary)
        } catch(e) {
          console.log('Unable to change selected icon border');
        }
      }
    }, [design])

    React.useEffect(() => {
      if (simulator.deviceName !== 'TV') {
        try {
          let svg = document.querySelector('#selected_id')
          if (svg) {
            svg.addEventListener("load", function() {
              svg.getSVGDocument().querySelectorAll('path').forEach(path => path.style.stroke = design.themeSecondary)
            })
          }
        } catch(e) {
          console.log('Unable to change selected icon border');
        }
      }
    }, [simulator.deviceName])

    return(
        <div style={{position: "relative"}}>
            <BottomNavigation
              value={footVal[tab]}
              showLabels
              className={classes.footer}
            >
              <BottomNavigationAction
                label="Selected"
                icon={
                  <object
                    id="selected_id"
                    className={classes.check}
                    type="image/svg+xml"
                    data-stroke="blue"
                    data={selected}
                    aria-label="Selected"
                  />
                }
              />
              <BottomNavigationAction
                label="Unselected"
                icon={<img src={unselected} alt="unselected" />}
              />
              <BottomNavigationAction
                label="Saved"
                icon={<img className={classes.star} src={savedIcon} alt="saved" />}
              />
            </BottomNavigation>
        </div>
    )
}

export default Footer;
