import { gql } from '@apollo/client';

export const LIVE_STREAM_FRAGMENT = gql`
  fragment LiveStreamSchema on LiveStream {
    __typename
    id
    title
    description
    status
    metadata
    provider
  }
`

export const GET_LIVE_STREAMS = gql`
  query liveStreams($workspaceId: ID! $appId: ID!) {
    liveStreams(workspaceId: $workspaceId appId: $appId) {
      ...LiveStreamSchema
    }
  }
  ${LIVE_STREAM_FRAGMENT}
`

export const GET_LIVE_STREAM = gql`
  query liveStream($workspaceId: ID! $appId: ID! $id: ID!) {
    liveStream(workspaceId: $workspaceId appId: $appId id: $id) {
      ...LiveStreamSchema
    }
  }
  ${LIVE_STREAM_FRAGMENT}
`

export const CREATE_LIVE_STREAM = gql`
  mutation createLiveStream(
    $workspaceId: ID!
    $appId: ID!
    $liveStreamFields: LiveStreamInput!
  ) {
    createLiveStream(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        liveStreamFields: $liveStreamFields
      }
    ) {
      liveStream {
        ...LiveStreamSchema
      }
    }
  }
  ${LIVE_STREAM_FRAGMENT}
`

export const UPDATE_LIVE_STREAM = gql`
  mutation updateLiveStream(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
    $liveStreamFields: LiveStreamInput!
  ) {
    updateLiveStream(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
        liveStreamFields: $liveStreamFields
      }
    ) {
      liveStream {
        ...LiveStreamSchema
      }
    }
  }
  ${LIVE_STREAM_FRAGMENT}
`

export const DELETE_LIVE_STREAM = gql`
  mutation deleteLiveStream(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
  ) {
    deleteLiveStream(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
      }
    ) {
      liveStream {
        id
        title
      }
    }
  }
`
