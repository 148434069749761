import React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function SwitchInput(props) {
  const { checked, onChange, ...otherProps } = props;

  return (
    <Switch checked={checked} onChange={(event) => onChange(event.target.checked, event)} color="primary" {...otherProps} />
  );
}

export function SwitchGroup(props) {
  const { label, ...otherProps } = props;

  return (
    <FormGroup row>
      <FormControlLabel
        control={<SwitchInput {...otherProps} name={label} />}
        label={label}
      />
    </FormGroup>
  );
}
