import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Drawer from '@mui/material/Drawer';
import clsx from 'clsx';

import TitleWithButton from '../TitleWithButton';

function getModalStyle(dimensions) {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    margin: 'auto',
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    ...dimensions,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 0, 4, 0),
    display: 'flex',
    flexDirection: 'column',
  },
  titleSpacing: {
    padding: theme.spacing(0, 2),
  },
  contentSpacing: {
    padding: theme.spacing(0, 4),
  },
  content: {
    overflowY: 'auto',
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    display: 'flex',
  },
}));

export default function Base({ titleLeft, title, titleRight, children, open,
  onClose, dimensions, fab, speedDial, secondaryFab, drawer, ...otherProps }) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{ alignItems:'center', justifyContent:'center' }}
      overlayStyle={{ backgroundColor: "transparent" }}
      {...otherProps}
    >
      <div style={getModalStyle(dimensions)} className={classes.paper}>
        <div className={classes.titleSpacing}>
          <TitleWithButton title={title} right={titleRight} left={titleLeft} fab={fab} speedDial={speedDial} secondaryFab={secondaryFab} />
        </div>
        <Divider />
        <div style={{ display: "flex", height: "calc(100% - 65px)" }}>
          {Boolean(drawer) && (
            <Drawer
              variant="permanent"
              anchor="left"
              style={{ width: 150, overflow: "hidden" }}
              PaperProps={{ style: { marginTop: 65, height: `calc(100% - 65px)` }}}
            >
              {drawer}
            </Drawer>
          )}
          <div style={{ flexGrow: 1, display: "flex" }}>
            <div className={clsx(classes.contentSpacing, classes.content)}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
