import { useMutation, useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { GET_CURRENT_APP_PROPS } from "api";
import { CONNECT_STRIPE, GET_APP_PAYMENT_PLATFORMS, UPDATE_STRIPE_COUPON } from "api";
import StripeButton from "components/atoms/Button/StripeButton";
import GroupHeader from "components/atoms/Text/GroupHeader";
import React from "react";
import { useLocation } from "react-router-dom";

function Stripe(props) {
  const [connected, setConnected] = React.useState(false);
  const location = useLocation();

  const params = new URLSearchParams(location.search)
  const code = params.get('code');

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data, loading } = useQuery(GET_APP_PAYMENT_PLATFORMS, { variables: appProps });
  
  
  const stripePlatform = data?.appPaymentPlatforms?.find((p) => p.name == "stripe");
  const connectId = stripePlatform?.metadata?.stripe_connect_account_id;

  const [enableCouponCode, setEnableCouponCode] = React.useState(stripePlatform?.metadata?.allow_promotion_codes || false)
  
  const [connectStripe, {loading: createLoading}] = useMutation(CONNECT_STRIPE, {
    update(cache, { data: { connectStripe } }) {
      cache.modify({
        fields: {
          appPaymentPlatforms(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              connectStripe.appPaymentPlatform.id === readField("id", ref)
                  ? { ...ref, ...connectStripe.appPaymentPlatform }
                  : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const [updateStripeCoupon] = useMutation(UPDATE_STRIPE_COUPON, {
    onCompleted(data) {
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const loadingApis = (loading || createLoading);
  

  React.useEffect(() => {
    if (code && !connected) {
      setConnected(true);
      connectStripe({variables: { ...appProps, responseCode: code }});
      window.history.replaceState(null, "", location.pathname);
    }
    if(data){
      const stripePlatform = data?.appPaymentPlatforms?.find((p) => p.name == "stripe");
      setEnableCouponCode(stripePlatform?.metadata?.allow_promotion_codes || false)
    }
  }, [data, code, connected]);

  const toggleCouponCode = (e) => {
    updateStripeCoupon({variables: { ...appProps, allowCouponCode: e }});
  }

  

  if (loadingApis) {
    return (
      <>
        <GroupHeader key={'stripeHeader'} title="Stripe"/>
        <CircularProgress />
      </>
    );
  }

  return (
    <>
      <GroupHeader key={'stripeHeader'} title="Stripe"/>
      {!connectId
        ? <StripeButton />
        : <div>
            <b>Status: </b>Connected <br/>
            <b>Connect Id: </b>{connectId}
          </div>
      }
    </>
  );
}

export default Stripe;
