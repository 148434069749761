import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    launchscreen: props => ({
        position: "relative",
        backgroundSize: "cover",
        minHeight: "35em",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "32em",
        padding: '2rem',
        textAlign: 'center',
        backgroundColor: props.tab ==="Saved" ? props.design?.savedBackground?.primaryColor : "#fff",
      })
}))

function Content(props) {
    const { simulator } = props;
    const { design, tab } = simulator;
    const classes = useStyles(simulator);

    return(
        <div className={classes.launchscreen}>
            <span style={{color: design.savedTextColor}}>
                {tab==="Saved" ? design.savedNoItemsText: null}
            </span>
        </div>
    )
}

export default Content;