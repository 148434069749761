import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

export default function Confirm({ open, handleClose, accept, content, acceptLabel, title, noCancel }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">{title || "Confirm"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          !noCancel &&
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        }
        <Button onClick={accept} color="secondary">
          {acceptLabel || "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
