import { gql } from '@apollo/client';
import { BACKGROUND_FRAGMENT } from "./design";

export const SPONSOR_FRAGMENT = gql`
  fragment SponsorSchema on Sponsor {
    __typename
    id
    isActive
    name
    logoUrl
    logoAltText
    background {
      ...BackgroundSchema
    }
    broughtBy
    duration
    actionButton
    actionText
    actionUrl
    actionUrlType
    language
    localeIds
  }
  ${BACKGROUND_FRAGMENT}
`

export const GET_SPONSORS = gql`
  query sponsors($workspaceId: ID! $appId: ID!) {
    sponsors(workspaceId: $workspaceId appId: $appId) {
      id
      name
      isActive
      language
      localeIds
    }
  }
`

export const GET_SPONSOR = gql`
  query sponsor($workspaceId: ID! $appId: ID! $id: ID!) {
    sponsor(workspaceId: $workspaceId appId: $appId id: $id) {
      ...SponsorSchema
    }
  }
  ${SPONSOR_FRAGMENT}
`

export const CREATE_SPONSOR = gql`
  mutation createSponsor(
    $workspaceId: ID!
    $appId: ID!
    $sponsorFields: SponsorInput!
  ) {
    createSponsor(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        sponsorFields: $sponsorFields
      }
    ) {
      sponsor {
        ...SponsorSchema
      }
    }
  }
  ${SPONSOR_FRAGMENT}
`

export const UPDATE_SPONSOR = gql`
  mutation updateSponsor(
    $workspaceId: ID!
    $appId: ID!
    $sponsorId: ID!
    $sponsorFields: SponsorInput!
  ) {
    updateSponsor(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        sponsorId: $sponsorId
        sponsorFields: $sponsorFields
      }
    ) {
      sponsor {
        ...SponsorSchema
      }
    }
  }
  ${SPONSOR_FRAGMENT}
`

export const DELETE_SPONSOR = gql`
  mutation deleteSponsor(
    $workspaceId: ID!
    $appId: ID!
    $sponsorId: ID!
  ) {
    deleteSponsor(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        sponsorId: $sponsorId
      }
    ) {
      sponsor {
        id
        name
        isActive
      }
    }
  }
`
