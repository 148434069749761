import React from "react";
// import Input from "components/atoms/Input/BaseInput";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
      loginConfigAttributes: props => ({
        display: "flex",
        minHeight: "470px",
        flexWrap: "wrap",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: props.loginBackground?.primaryColor
      })
}))

function Content(props) {
    const { simulator } = props;
    const { design, tab } = simulator;
    const classes = useStyles(design);
    return(
      <div className={classes.loginConfigAttributes}>

        <div className="loginhHeader" style={{color:design.loginHeaderColor}}>
          <img
            src={design.loginHeaderImageUrl}
            style={{maxHeight:"40px", margin: "0 auto 5px", display: "block"}}
          />
          {design.loginHeaderText}
        </div>
        {design.loginType==="on_screen" && <div className="loginForm"
          style={{width:"100%", justifyContent:"center"}}
          >
          <input
            type="text"
            style={{minWidth:"80%",marginBottom:"15px", height: "35px",background: "transparent",border: "1px solid #333"}}
            label="Enter Email"
            placeholder="Enter Email"
          />
          <input
            type="text"
            style={{minWidth:"80%",marginBottom:"15px", height: "35px",background: "transparent",border: "1px solid #333"}}
            label="Enter Password"
            placeholder="Enter Password"
          />
          <div
            style={{display:"flex", justifyContent:"space-evenly"}}
          >
            <Grid item xs={6}>
              <Button style={{background: design.loginButtonColor, color: design.loginButtonLabelColor}}>{design.loginSigninText}</Button>
            </Grid>
            <Grid item xs={6}>
              <Button style={{background: design.loginButtonColor, color: design.loginButtonLabelColor}}>{design.loginRegisterText}</Button>
            </Grid>
          </div>
        </div>

        }
        {design.loginType==="web" && <div
            style={{border: "1px solid #3333", marginBottom:"5px"}}
          >
            <Button style={{background: design.loginButtonColor, color: design.loginButtonLabelColor, width:"100%"}}>{design.loginButtonText}</Button>
          </div>

        }

        <div className="loginOptions"
          style={{width:"80%"}}
        >
          <div
            style={{ marginBottom:"5px"}}
          >
            <Button style={{color: design.loginTextButtonColor ,border: "2px solid "+design.loginBorderColor ,background:design.loginHighlightColor ,width:"100%"}}>{design.loginExistingSubscribersHeading}</Button>
          </div>
          <div
            style={{ marginBottom:"5px"}}
          >
            <Button style={{color: design.loginTextButtonColor ,border: "2px solid "+design.loginBorderColor ,background:design.loginHighlightColor ,width:"100%"}}>{design.loginRestorePurchasesHeading}</Button>
          </div>
          <div style={{display:"flex", justifyContent:"space-evenly"}}>
            <div
              style={{ marginBottom:"5px"}}
            >
              <a style={{color:design.loginTextButtonColor, fontSize:"10px", padding:"5px 10px"}}>Terms of Use URL</a>
            </div>
            <div
              style={{ marginBottom:"5px"}}
            >
              <a style={{color:design.loginTextButtonColor, fontSize:"10px", padding:"5px 10px"}}>Privacy Policy URL</a>
            </div>
          </div>

        </div>
      </div>
    )
}

export default Content;
