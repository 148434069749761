import React from 'react';
import Input from "./BaseInput";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  digitBox: {
    height: theme.spacing(6),
    width: theme.spacing(6),
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(1),
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderStyle: "solid",
  },
  digit: {
    fontSize: 24,
    lineHeight: 1.33,
    textAlign: "center",
  },
  digitsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  actualInput: {
    position: "absolute",
    height: 0,
    width: 0,
    opacity: 0,
  },
}));

export default function OtpInput({ codeLength, cleared, setCleared, setOtp }) {
  const theme = useTheme();
  const classes = useStyles();
  const ref = React.useRef(null);
  const [focused, setFocused] = React.useState(false);
  const [code, setCode] = React.useState("");
  const codeDigitsArray = new Array(codeLength).fill(0);

  React.useEffect(() => {
    if (code.length === codeLength) {
      setOtp && setOtp(code);
    }
  }, [code, codeLength, setOtp]);

  React.useEffect(() => {
    if (cleared) {
      setCleared && setCleared(false);
      clear();
    }
  }, [cleared]);

  const toDigitInput = (_value: number, idx: number) => {
    const value = code[idx];
    const active = idx === 0 ? !code[1] : code[idx - 1] && !value;

    return (
      <div
        key={idx}
        className={classes.digitBox}
        style={{
          marginLeft: idx === 0 ? 0 : theme.spacing(1),
          borderColor: value
            ? theme.palette.primary.main
            : focused
            ? active
              ? theme.palette.success.main
              : null
            : null,
          borderWidth: value || (focused && active) ? 3 : 1,
        }}
      >
        <Typography className={classes.digit}>
          {value || " "}
        </Typography>
      </div>
    );
  };

  const focus = () => {
    ref?.current?.focus();
  };

  const clear = () => {
    setCode("");
  };

  return (
    <>
      <div className={classes.digitsContainer} onClick={focus}>
        {codeDigitsArray.map(toDigitInput)}
      </div>
      <Input
        value={code}
        onChange={setCode}
        label="One Time Code"
        name="oneTimeCode"
        inputmode="numeric"
        autoComplete="one-time-code"
        inputProps={{
          ref: ref,
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
          maxLength: codeLength,
        }}
        className={classes.actualInput}
        autoFocus
      />
    </>
  );
}
