export default function isValid(platformDetails, appStores) {
    const { metadata } = platformDetails;

    if (!metadata ) return false;

    if (!metadata.title) return false;
    if (!metadata.subdomain) return false;
    if (!metadata.support_email) return false;
    if (!metadata.scaling_factor) return false;
    
    return true;
}