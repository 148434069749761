import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import urlJoin from "url-join";

import {
  GET_CURRENT_APP_PROPS,
  GET_CURRENT_APP,
  GET_INTEGRATIONS,
  UPDATE_INTEGRATION,
  DELETE_INTEGRATION
} from "api";
import ContentLayout from "screens/Layout/ContentLayout";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import DbiSelectorModal from "components/molecules/Modal/DbiSelector";
import { useLocation } from "react-router-dom";

const getType = (obj) => {
  let types = [];

  if (obj.sso) {
    types.push("SSO");
  }
  if (obj.drm) {
    types.push("DRM");
  }

  return types.join(", ")
};

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 400,
    cellRenderer: "titleCellRenderer",
    sort:"asc"
  },
  {
    field: "regions",
    headerName: "Regions",
    width: 150,
    filter: false,
  },
  {
    field: "type",
    width: 150,
    filter: false,
    valueGetter: (params) => getType(params.data),
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    sortable:false,
    minWidth:300,
    flex: 1,
  },
];

function List(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const locales = app.locales;
  const isMultiLangOrRegion = props.isMultiLangOrRegion;
  const { data } = useQuery(GET_INTEGRATIONS, { variables: appProps });

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const [selectorOpen, setSelectorOpen] = React.useState(false);

  const onEdit = (integration) => {
    history.push(urlJoin(pathname, `edit/${integration.id}`));
  };

  const onDelete = (integration) => {
    setDeleteConfirmation(integration);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(null);
  };

  const closeSelector = () => {
    setSelectorOpen(false);
  };

  const toggleActive = (integration, value, event) => {
    updateIntegration({
      variables: {
        ...appProps,
        id: integration.id,
        integrationFields: { isActive: value },
      },
    });
  };

  const [updateIntegration] = useMutation(UPDATE_INTEGRATION, {
    onCompleted(data) {
      props.savedSnack.launch();
    },
    update(cache, { data: { updateIntegration } }) {
      cache.modify({
        fields: {
          integrations(existingRefs, { readField }) {
            return existingRefs.map((ref) => (
              updateIntegration.integration.id === readField("id", ref)
                ? { ...ref, ...updateIntegration.integration }
                : ref
            ));
          },
        },
      });
    },
    onError(data) {
      console.log(data);
    },
  });

  const [deleteIntegration] = useMutation(DELETE_INTEGRATION, {
    update(cache, { data: { deleteIntegration } }) {
      cache.modify({
        fields: {
          integrations(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteIntegration.integration.id !== readField("id", ref)
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
    },
    onError(data) {
      console.log(data);
    },
  });

  const deleteIntegrationApi = (id) => {
    closeDeleteConfirmation();
    deleteIntegration({ variables: { ...appProps, id } });
  };

  const onDbiSelect = (name) => {
    history.push(urlJoin(pathname, `new?template=${name}`));
  };
  
  const columnsWithoutRegion = columns.filter(c => c.headerName !== "Regions");
  return (
    <ContentLayout
      title="Integrations"
      fab={{
        onClick: () => setSelectorOpen(true),
      }}
    >
      <FormLayout>
        <DataGrid
          rowData={data?.integrations
            ? data.integrations.map((au) => ({
                ...au,
                title: au.name,
                regions: au.localeIds
                  ? locales.filter((l) => au.localeIds.includes(l.id)).map((l) => l.name).join(", ")
                  : "All Regions",
                actions: {
                  toggle: {
                    getter: au.isActive,
                    setter: (value, event) => toggleActive(au, value, event),
                  },
                  onEdit,
                  onDelete,
                },
              }))
            : []}
          columnDefs={isMultiLangOrRegion ? columns : columnsWithoutRegion}
          defaultColDef={{ sortable: true }}
          accentedSort={true}
          immutableData={true}
          getRowNodeId={(data) => data.id}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => deleteIntegrationApi(deleteConfirmation.id)}
        />

        <DbiSelectorModal
          open={selectorOpen}
          onClose={closeSelector}
          onClick={onDbiSelect}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default List;
