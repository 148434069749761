import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  header: (props) => ({
    height: "50px",
    padding: "0px 6rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    fontSize: "13px",
    backgroundColor: props.themeTabBar ? props.themeTabBar : props.themeTabBar,
    color: props.themeInactive ? props.themeInactive : props.themeInactive,
  }),
  activeTab: (props) => ({
    color: props.themeActive,
  }),
}));

export default function HeaderScreen(props) {
  const { simulator } = props;
  const { design, tab } = simulator;
  const classes = useStyles(design);

  return (
    <div className={classes.header}>
      <p className={clsx(tab === "General" && classes.activeTab)}>
        Active Section
      </p>
      <p>Active Section Hover</p>
      <p className={clsx(tab === "Saved" && classes.activeTab)}>Saved</p>
      <p>Settings</p>
    </div>
  );
}
