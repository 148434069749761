import _ from "lodash";

export const LANGUAGE_MAP = {
  en: "English",
  fr: "French",
  es: "Spanish",
  sp: "Spanish",
  ge: "German",
  it: "Italian",
  pt: "Portuguese",
  nl: "Dutch",
  el: "Greek",
};

export const readLangAttribute = (obj, attr, languages) => {
  let value = null;

  for (var i = 0; i < languages.length; i++) {
    const key = `${attr}${_.capitalize(languages[i])}`;
    if (obj[key]) {
      value = obj[key];
      break;
    }
  }

  return value;
};

export const getAllLanguagesFromLocale = (locales) => {
  const allLanguages = [];
  if (!locales) return allLanguages
  locales.forEach((locale) => {
    allLanguages.push(...locale.languages);
  });
  return _.uniq(allLanguages);
};

export const generateLocalesForDesigns = (designs, keys) => {
  if (!keys.length || !designs) return null;
  let obj = {};
  designs.forEach((dgn) => {
    const locale = dgn.locale.id;
    const lang = dgn.language;
    const val = {};
    keys.forEach((key) => (val[key] = dgn[key]));
    if (obj[locale]) obj[locale][lang] = val;
    else {
      obj[locale] = {
        [lang]: val,
      };
    }
  });
  return obj;
};

export const updateKeyInTextLocales = (textLocale, key, value) => {
  let textLocaleCopy = _.cloneDeep(textLocale);
  Object.keys(textLocaleCopy).forEach((locale) => {
    const localeObj = textLocaleCopy[locale];
    Object.keys(localeObj).forEach((lang) => {
      textLocaleCopy[locale][lang][key] = value;
    });
  });
  return textLocaleCopy;
};

export const getDefaultLocaleAndLang = (app) => {
  if (!app) return { lang: "en" };
  const defaultLocale = app.locales[0];
  return {
    locale: defaultLocale,
    lang: defaultLocale.languages[0],
  };
};

export const makeDefaultLangObject = (title) => {
  if (!title) return {};
  if (typeof title === "string") return { en: title };
  return title;
};

const getAvailable = (allAvailable, app, bundleLocaleIds) => {
  const availableLocales = []
  if (allAvailable) return 'ALL';
  const allLocales = app.locales;
  if (bundleLocaleIds.length === allLocales.length) return 'ALL';
  allLocales.forEach(l => {
    if (bundleLocaleIds.includes(l.id)) {
      availableLocales.push((l.regionCode || l.name));
    }
  });
  return availableLocales.join(", ")
}

const getLanguage = (allLanguage, app, bundleLanguages) => {
  const allLangs = getAllLanguagesFromLocale(app.locales);
  if (allLanguage) return 'ALL';
  if (!_.difference(allLangs, bundleLanguages).length) return 'ALL';
  return bundleLanguages.map(l => l.toUpperCase()).join(", ")
}

const getAllLangsForMenu = (menu, app) => {
  if (menu.language) return [menu.language];
  if (menu.localeIds) {
    const allLangs = []
    app.locales.forEach(l => {
      if (menu.localeIds.includes(l.id)) {
        allLangs.push(...l.languages)
      }
    })
    return allLangs;
  } else {
    return getAllLanguagesFromLocale(app.locales)
  }
}

export const getAvailableAndLanguage = (bundle, app) => {
  let allAvailable = false;
  let allLanguage = false;
  let bundleLocaleIds = [];
  let bundleLanguages = [];
  bundle.menus.forEach(m => {
    if (m.isActive) {
      if (!m.localeIds) allAvailable = true;
      if (!m.localeIds && !m.language) allLanguage = true;
      m.localeIds && bundleLocaleIds.push(...m.localeIds);
      !allLanguage && bundleLanguages.push(...getAllLangsForMenu(m, app))
    }
  })
  const uniqBundleLocalesIds = _.uniq(bundleLocaleIds);
  const uniqBundleLanguages = _.uniq(bundleLanguages)
  const available = getAvailable(allAvailable, app, uniqBundleLocalesIds)
  const language = getLanguage(allLanguage, app, uniqBundleLanguages)
  return { available, language};
}

export function makeDefaultLangObjectFromMenus(menus, locales) {
  const langMap = {};
  const allLangs = getAllLanguagesFromLocale(locales);
  for (const lang of allLangs) { langMap[lang] = "" }
  menus?.length && menus.forEach(m => {
    langMap[m.language] = m.title;
  })
  return langMap;
}
