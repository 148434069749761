const INITIAL_STATE = { user: {} };

export default function foo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "login":
      return {
        ...state,
        user: {
          jwt: action.payload,
        },
      };
    case "setPushToken":
      return {
        ...state,
        user: { ...state.user, pushToken: action.payload },
      };
    case "logout":
      return { ...state, user: {} };
    default:
      return state;
  }
}
