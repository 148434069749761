import React from "react";
import Label from "components/atoms/Text/Label";
import { JsonEditor as Editor } from 'jsoneditor-react';

export default function JsonInput(props) {
  const { label, value, onChange } = props;

  return (
    <>
      <Label>{label}</Label>
      <Editor
        value={value}
        onChange={onChange}
      />
    </>
  )
}
