import { useQuery } from "@apollo/client";
import React from "react";
import { Switch, Route, useRouteMatch, useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_LIVE_CHANNEL } from "api";
import ListPrograms from "./List";
import NewPrograms from "./New";
import EditPrograms from "./Edit";

export default function Programs(props) {
  const { savedSnack, hub } = props;

  const { liveChannelId } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_LIVE_CHANNEL, { variables: { ...appProps, id: liveChannelId } });

  const channelsPath = `/${appProps.workspaceId}/${appProps.appId}/${hub}/channels`;

  const { path } = useRouteMatch();

  const commonProps = { savedSnack, liveChannel: data?.liveChannel, channelsPath, hub };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        {commonProps.liveChannel && <NewPrograms {...commonProps} />}
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        {commonProps.liveChannel && <EditPrograms {...commonProps} />}
      </Route>
      <Route path={path}>
        {commonProps.liveChannel && <ListPrograms {...commonProps} />}
      </Route>
    </Switch>
  );
}
