import React from "react";
import { useTheme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

import Base from "./Base";
import cleengLogo from "images/integrations/cleeng.png";
import toolboxLogo from "images/integrations/toolbox.png";
import zypeLogo from "images/integrations/zype.svg";

const INTEGRATION_MAPPING = [
  { name: "Cleeng", src: cleengLogo },
  { name: "Zype", src: zypeLogo },
  { name: "Toolbox", src: toolboxLogo },
];

const useStyles = makeStyles((theme) => ({
  logoBox: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
  },
  logoContainer: {
    height: theme.spacing(30),
    width: theme.spacing(30),
    borderWidth: 1,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    margin: theme.spacing(1),
    '&:hover': {
      borderWidth: 3,
    },
  },
  logo: {
    maxWidth: theme.spacing(25),
    maxHeight: theme.spacing(25),
  }
}));

const Logo = (props) => {
  const classes = useStyles();
  const { name, src, onClick } = props;

  return (
    <div className={classes.logoContainer} onClick={(ev) => onClick(name)}>
      <img className={classes.logo} src={src} alt={name} />
    </div>
  );
};

const DbiLogos = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.logoBox}>
      {INTEGRATION_MAPPING.map((map, i) => <Logo key={`DBI-${i}`} {...props} {...map} />)}
    </div>
  );
};

export default function DbiSelector(props) {
  const theme = useTheme();
  const { onClick, ...otherProps } = props;

  return (
    <Base
      dimensions={{ width: theme.spacing(100), height: theme.spacing(75) }}
      title="Integrations"
      {...otherProps}
    >
      <DbiLogos onClick={onClick} />
    </Base>
  );
}
