import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import _ from "underscore";
import { useRouteMatch } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, CREATE_SPONSOR, UPDATE_SPONSOR } from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from 'components/atoms/Input/BaseInput';
import Select from 'components/atoms/Input/Select';
import Tooltip from 'components/atoms/Tooltip';
import BackgroundInput from "components/molecules/BackgroundGroup";
import GroupHeader from "components/atoms/Text/GroupHeader";
import { checkFileType, getHeightAndWidthFromDataUrl } from 'helpers/fileUtils';
import UploadButton, { ImagePreview } from "components/molecules/UploadGroup";
import PaperGroup from "components/molecules/Form/PaperGroup";
import New from "components/atoms/Button/New";
import Preview from "./Preview";
import ContentLayout from "screens/Layout/ContentLayout";
import DeeplinkModal from "components/molecules/Modal/BundleSelector";
import LocaleOptions from "components/molecules/LocaleOptions";


function Form(props) {
  const { path } = useRouteMatch();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { sponsor } = props;

  const [ name, setName ] = React.useState(sponsor.name || "");
  const [ logoUrl, setLogoUrl ] = React.useState(sponsor.logoUrl);
  const [ logoBlobId, setLogoBlobId ] = React.useState(null);
  const [ logoAltText, setLogoAltText ] = React.useState(sponsor.logoAltText || "");
  const [ background, setBackground ] = React.useState(sponsor.background || { format: "single", primaryColor: "#000000", secondaryColor: "#000000" });
  const [ broughtBy, setBroughtBy ] = React.useState(Boolean(sponsor.broughtBy));
  const [ duration, setDuration ] = React.useState(sponsor.duration || 3);
  const [ actionButton, setActionButton ] = React.useState(Boolean(sponsor.actionButton));
  const [ actionUrl, setActionUrl ] = React.useState(sponsor.actionUrl);
  const [ actionText, setActionText ] = React.useState(sponsor.actionText);
  const [ showPreview, setShowPreview ] = React.useState(false);

  const [ logoError, setLogoError ] = React.useState(null);
  const [ actionUrlError, setActionUrlError ] = React.useState(null);
  const [ actionTextError, setActionTextError ] = React.useState(null);
  const [ nameError, setNameError ] = React.useState(null);
  const [ backgroundImageError, setBackgroundImageError ] = React.useState(null);


  const [showDeepLinkModal, setShowDeepLinkModal] = React.useState(false);
  const [selectedDeepLink, setSelectedDeepLink] = React.useState("");
  const [deeplinkWebURL, setDeeplinkWebURL] = React.useState(sponsor.actionUrlType!=="deeplink"?sponsor.actionUrl:"");
  const [deeplinkItemURL, setDeeplinkItemURL] = React.useState(sponsor.actionUrlType==="deeplink"?sponsor.actionUrl:"");
  const [deeplinkItemURLText, setDeeplinkItemURLText] = React.useState(sponsor.actionUrlType==="deeplink"?sponsor.actionUrl:"");
  const [deeplinkType, setDeeplinkType] = React.useState(sponsor.actionUrlType);
  const [fileMetadata, setFileMetadata] = React.useState("");
  const [localeIds, setLocaleIds] = React.useState(sponsor.localeIds || null);
  const [language, setLanguage] = React.useState(sponsor.language || null);
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [localeErr, setLocaleErr] = React.useState(null);


  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/ads/sponsors`;

  React.useEffect(() => {
    setIsFormChanged(true);
  },[language, localeIds]);

  React.useEffect(() => {
    setIsFormChanged(true);
  },[name, logoUrl, logoAltText, background, broughtBy, duration, actionButton, actionText])

  React.useEffect(() => {
    setTimeout(() => setIsFormChanged(false),50);
  },[]);

  const onSave = () => {
    props.savedSnack.launch();
    history.push(listUrl);
  };

  const stateForm = {
    name,
    logoBlobId,
    logoAltText,
    broughtBy,
    duration,
    actionButton,
    actionUrl: (deeplinkWebURL && deeplinkWebURL.length>0)?deeplinkWebURL:deeplinkItemURL,
    actionUrlType: (deeplinkWebURL && deeplinkWebURL.length>0)?"web_url":"deeplink",
    actionText,
    backgroundAttributes: _.omit(
      background,
      "__typename",
      "assetUrl"
    ),
    language,
    localeIds
  };

  const isValidate = () => {
    let check = true
    if(!stateForm.name){
      setNameError(true)
      check = false
    }
    if((!stateForm.logoBlobId && !sponsor.logoUrl) || stateForm.logoBlobId == "detach"){
      setLogoError("Mandatory to upload")
      check = false
    }
    if(stateForm.actionButton && !stateForm.actionText){
      setActionTextError("Mandatory Field")
      check = false
    }
    if(stateForm.actionButton && !stateForm.actionUrl){
      setActionUrlError("Mandatory Field")
      check = false
    }
    if(stateForm.backgroundAttributes?.format == "image"
      && (!stateForm.backgroundAttributes?.assetBlobId && !sponsor.background?.assetUrl)
      || stateForm.backgroundAttributes?.assetBlobId == "detach"){
      setBackgroundImageError("Mandatory to upload")
      check = false
    }
    if ((localeIds && !localeIds.length) || !language) {
      check = false
      setLocaleErr(true)
    }
    console.log(check);
    return check
  }

  const submit = () => {
    if(!isValidate()){
      return
    }
    if (sponsor.id) {
      updateSponsor({
        variables: {
          ...appProps,
          sponsorId: sponsor.id,
          sponsorFields: stateForm
        }
      });
    } else {
      createSponsor({
        variables: {
          ...appProps,
          sponsorFields: stateForm
        }
      });
    }
  }

  function handleLogoUpload(response) {
    setLogoUrl(response.url);
    setLogoBlobId(response.signedBlobId);
  }

  async function handleLogoValidations(file) {
    setLogoError(null);
    const fileAsDataURL = window.URL.createObjectURL(file);
    const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    if (!checkFileType(file, ['png'])) {
      setLogoError("Invalid file type");
      return false;
    }
    if (file.size > 1000000) {
      setLogoError("Image size exceeded");
      return false;
    }
    if (dimensions.width === 1200 && dimensions.height === 600) {
      return true;
    }
    setLogoError("Invalid dimensions");
    return false;
  }

  const handleError = (data) => {
    if(data.message.includes("Name")){
      setNameError(data.message)
    }
    if (data.message.includes("Action url")) {
      setActionUrlError(data.message)
    }
  };

  // const { data: deeplinkfolder } = useQuery(GET_FOLDER, {
  //   variables: {
  //     ...appProps,
  //     id: deeplinkType==="deeplink"?deeplinkItemURL.split("/").pop():""
  //   }
  // });
  const deeplinkfolder = null;

  React.useEffect(() => {
    deeplinkfolder && deeplinkfolder?.folder && setSelectedDeepLink(deeplinkfolder.folder)
  }, [deeplinkfolder])

  React.useEffect(() => {
    if (fileMetadata?.name) {
      setLogoAltText(fileMetadata.name)
    }
  }, [fileMetadata])
  React.useEffect(() => {setLocaleErr(null)},[language])

  const [updateSponsor, { loading: isUpdating }] = useMutation(UPDATE_SPONSOR, {
    update(cache, { data: { updateSponsor } }) {
      cache.modify({
        fields: {
          sponsors(existingRefs, { readField }) {
            return existingRefs.map((ref) => (
              updateSponsor.sponsor.id === readField("id", ref)
                ? { ...ref, ...updateSponsor.sponsor }
                : ref
            ));
          },
        },
      });
    },
    onCompleted(data) { onSave() },
    onError(data) { handleError(data) }
  });

  const [createSponsor, { loading: isCreating }] = useMutation(CREATE_SPONSOR, {
    update(cache, { data: { createSponsor } }) {
      cache.modify({
        fields: {
          sponsors(existingRefs) {
            return [...existingRefs, createSponsor.sponsor];
          },
        },
      });
    },
    onCompleted(data) { onSave() },
    onError(data) { handleError(data) }
  });

  const renderFile = (url, alt, urlSetter, blobSetter) => (
    url
      ? <ImagePreview {...{ url, alt, urlSetter, blobSetter }} />
      : null
  );

  const nonNullTitle = name || "Untitled Sponsor";

  const previewButton = () => logoUrl && (
    <New onClick={() => {setShowPreview(true)}}>Preview</New>
  )

  const fetchParentArr = (item) => {
    const treeBundle = [];
    
    if (item.parent && item.parent.key) {
        treeBundle.push(...fetchParentArr(item.parent)) 
    }
    treeBundle.push(item);
    return treeBundle
  }
  const closeDeepLinkModal = (e) => {
    setShowDeepLinkModal(false)
    if (!e || !e[0]) {
      setDeeplinkItemURL(null);
      setDeeplinkItemURLText(null);
      return;
    };

    setSelectedDeepLink(e)
    let tempItemURL = {text:"ROOT", url:""}
    const itemParent = fetchParentArr(e[0])
    itemParent[itemParent.length-1] = e[0].data;
    itemParent.forEach(element => {
      (element.data && element.data.title)?tempItemURL.text+="> "+element.data.title:tempItemURL.text+="> "+element.title;
      (element.data && element.data.title)?tempItemURL.url+="/"+element.data.id:tempItemURL.url+="/"+element.id;
    });
    setDeeplinkItemURL(tempItemURL.url)
    setDeeplinkItemURLText(tempItemURL.text)
    setDeeplinkType("item")
    setDeeplinkWebURL("")

  }
  

  const onDeeplinkWebURLChange = (e) => {
    setDeeplinkType("web_url")
    setDeeplinkWebURL(e)
  }

  const isLoading = isCreating || isUpdating;

  return (
    <ContentLayout
      title={nonNullTitle}
      right={previewButton}
    >
      <FormLayout
        submit={submit}
        breadcrumbs={[
          { name: "Sponsors", link: listUrl },
          { name: nonNullTitle }
        ]}
        isChanged={isFormChanged}
        betterForm={true} 
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <Preview
          open={showPreview}
          title="Sponsor Screen Preview"
          onClose={() => {setShowPreview(false)}}
          state = {{logoUrl, background, broughtBy, actionText, actionUrl, actionButton}}
        />

        <PaperGroup>
          <LocaleOptions
            locales={localeIds}
            setLocales={setLocaleIds}
            withLanguage
            language={language}
            setLanguage={setLanguage}
            errorText={localeErr ? "Please select locale and language": null}
            divider
          />
          <Tooltip
            title="Used to track sponsor screen engagement in Google Analytics and to identify the sponsor screen."
          >
            <Input label="Tracking Name"
              name="name"
              defaultValue={name}
              onChange={setName}
              helperText="Not consumer facing"
              autoFocus
              required
              error={!name && nameError }
            />
          </Tooltip>

          <UploadButton
            requirements={[
              "1200 x 600px",
              "transparent background",
              "72 dpi",
              "PNG",
              "Maximum size: 1 MB"
            ]}
            label="Sponsor Logo"
            onUpload={handleLogoUpload}
            validations={handleLogoValidations}
            error={logoError}
            helperText="displays in the center of the screen"
            fileRender={() => renderFile(logoUrl, "Logo", setLogoUrl, setLogoBlobId)}
            inputProps={{accept: '.png'}}
            setFileMetadata={setFileMetadata}
            required
          >
            Upload Image
          </UploadButton>

          <Input
            label="Sponsor Logo Alt Text"
            value={logoAltText}
            onChange={setLogoAltText}
          />

          <BackgroundInput
            getter={background}
            setter={setBackground}
            imageDimensions={{ height: 2732, width: 2732 }}
            renderAsset
            noVideo
            hideGradientIfVideoOrImage
            supportedImageFormats={['jpg']}
            maxFileSize={1}
            noTransparent
            error={backgroundImageError}
          />

          <Tooltip
            title="This selection toggles visibility of the words 'Brought to you by' on the sponsor screen"
          >
            <Select
              value={broughtBy}
              onChange={setBroughtBy}
              options={[
                { label: "On", value: true },
                { label: "Off", value: false },
              ]}
              label="“Brought to you...”"
              helperText='Will display the text "Brought to you by" at the top of the screen'
              required
            />
          </Tooltip>

          <Tooltip
            title="It is recommended not to set the duration for more than 2 or 3 seconds as the user will begin to think the app is frozen if it is any longer."
          >
            <Select
              value={duration}
              onChange={setDuration}
              options={[
                { label: "1 Second", value: 1 },
                { label: "2 Seconds", value: 2 },
                { label: "3 Seconds", value: 3 },
                { label: "4 Seconds", value: 4 },
                { label: "5 Seconds", value: 5 }
              ]}
              label="Duration"
              helperText='How long the sponsor screen will display for'
              required
            />
          </Tooltip>
        </PaperGroup>

        <PaperGroup>
          <GroupHeader title="Mobile/Tablet" />

          <Tooltip
            title="Adds an actionable button on the screen"
          >
            <Select
              value={actionButton}
              onChange={setActionButton}
              options={[
                { label: "On", value: true },
                { label: "Off", value: false },
              ]}
              label="Action Button"
              required
            />
          </Tooltip>

          {actionButton ?
            <>
              <Grid container>
                <Grid item xs={6}>
                  <New
                  color="secondary"
                  onClick = {e => {setShowDeepLinkModal(true)}}
                  disabled = {deeplinkWebURL && deeplinkWebURL.length>0}
                  >
                    Deeplink to...
                  </New>
                  {deeplinkItemURLText && <Grid item xs={10}>{deeplinkItemURLText}</Grid>}
                </Grid>

                {/* <Grid item xs={6}>
                  <Input
                    label="Enter WebURL"
                    value={deeplinkWebURL}
                    onChange={onDeeplinkWebURLChange}
                    disabled = {deeplinkItemURLText.length>0}
                  />
                </Grid> */}
                <Grid item xs={10}>
                  <Input
                    label="Enter action text"
                    value={actionText}
                    
                    onChange={setActionText}
                  />
                </Grid>
              </Grid>

            </>
          : null}
        </PaperGroup>
        {showDeepLinkModal && <DeeplinkModal
          filters={["Bundle::Layout::Section","Bundle::Layout::Symlink"]}
          multiple={true}
          selectSingle={true}
          open={showDeepLinkModal}
          onClose={e => closeDeepLinkModal(e)}
          disableInActive
        />}
      </FormLayout>
    </ContentLayout>
  );
}

export default Form;
