import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingRight: theme.spacing(2),
  },
  tab: {
    margin: theme.spacing(2),
    flexGrow: 1,
  },
  fab: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4.5),
    zIndex: 10,
  },
  secondaryFab: {
    right: theme.spacing(12.5),
  },
}));

export default function TitleWithButton({ left, title, right, fab, speedDial, secondaryFab }) {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" className={classes.root}>
        <Box display="flex" alignItems="center">
          {left && left()}
        </Box>
        <Typography variant="h5" className={classes.tab} noWrap>{title}</Typography>
        <Box display="flex" alignItems="center">
          {right && right()}
        </Box>
        {secondaryFab && <SecondaryFab fab={secondaryFab} />}
        {fab && <SingleOption fab={fab} />}
        {speedDial && <MultipleOptions speedDial={speedDial} />}
      </Box>
    </>
  );
}

const SecondaryFab = ({ fab }) => {
  const classes = useStyles();
  return (
    <Fab className={clsx(classes.fab, classes.secondaryFab)} color="secondary" aria-label="open" {...fab}>
      <PlayArrowIcon />
    </Fab>
  );
};

const SingleOption = ({ fab }) => {
  const classes = useStyles();
  return (
    <Fab className={classes.fab} color="primary" aria-label="add" {...fab}>
      <AddIcon />
    </Fab>
  );
};

const MultipleOptions = ({ speedDial }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { actions } = speedDial;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (clickFunc) => {
    handleClose();
    clickFunc && clickFunc();
  };

  return (
    <>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Create new"
        className={classes.fab}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="down"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={`SpeedDialAction-${action.name}`}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleClick(action.onClick)}
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </>
  );
};
