import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import _ from "underscore";

import apple_store from "images/store/ios-store.png";
import google_store from "images/store/android-store.png";
import amazon_store from "images/store/amazon-store.png";
import roku_store from "images/store/roku-store.png";
import web_store from "images/store/web_as_output-store.png";
import vizio_store from "images/store/vizio-store.png";
import lg_store from "images/store/lg-store.png";
import samsung_store from "images/store/samsung-store.png";
import microsoft from "images/store/microsoft.png";

import {
  GET_CURRENT_APP_PROPS,
  UPDATE_BANNER_GROUP,
  CREATE_BANNER_GROUP,
} from "api";

import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import makeStyles from '@mui/styles/makeStyles';

import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import Select from "components/atoms/Input/Select";
import GroupHeader from "components/atoms/Text/GroupHeader";
import { withRedirect } from "components/containers/Redirect";
import PaperGroup from "components/molecules/Form/PaperGroup";
import ContentLayout from "screens/Layout/ContentLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  store_img: {
    display: "flex",
    objectFit: "contain",
    width: "50px",
    marginRight: "20px",
  },
}));

const store_data = [
  {
    name: "apple",
    logo: apple_store,
    display_name: "App Store (iOS)",
  },
  {
    name: "google",
    logo: google_store,
    display_name: "Google Play (Android)",
  },
  {
    name: "amazon",
    logo: amazon_store,
    display_name: "Amazon App Store (Android)",
  },
  {
    name: "roku",
    logo: roku_store,
    display_name: "Roku",
  },
  {
    name: "web",
    logo: web_store,
    display_name: "Web",
  },
  {
    name: "vizio",
    logo: vizio_store,
    display_name: "Vizio",
  },
  {
    name: "lg",
    logo: lg_store,
    display_name: "LG",
  },
  {
    name: "samsung",
    logo: samsung_store,
    display_name: "Samsung",
  },
  {
    name: "microsoft",
    logo: microsoft,
    display_name: "Microsoft",
  },
];

const defaultBannerStores = [
  { appStoreName: "apple", isActive: true },
  { appStoreName: "google", isActive: true },
  { appStoreName: "amazon", isActive: true },
  { appStoreName: "roku", isActive: true },
  { appStoreName: "lg", isActive: true },
  { appStoreName: "samsung", isActive: true },
  { appStoreName: "vizio", isActive: true },
  { appStoreName: "web", isActive: true },
  { appStoreName: "microsoft", isActive: true },
]

function getInitialBannerStores(bannerStores) {
  if (!bannerStores) return defaultBannerStores;
  const initBannerStores = [];
  defaultBannerStores.forEach(store => {
    let bannerStore = bannerStores.find(bs => bs.appStoreName === store.appStoreName);
    if (bannerStore) initBannerStores.push(bannerStore)
    else initBannerStores.push(store)
  })
  return initBannerStores
}

function Form(props) {
  const classes = useStyles();
  const history = useHistory();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { bannerGroup } = props;

  const [name, setName] = React.useState(bannerGroup?.name || "");
  const [segmentation, setSegmentation] = React.useState(
    bannerGroup?.segmentation || "all"
  );
  const [stores, setStores] = React.useState(getInitialBannerStores(bannerGroup?.bannerStores));
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [ nameError, setNameError ] = React.useState(null);

  React.useEffect(() => {
    setIsFormChanged(true);
  },[name, segmentation, stores])

  React.useEffect(() => {
    setIsFormChanged(false);
  },[])

  const handleAppStoreSwitch = (value, name) => {
    setStores(
      stores.map((s) => ({
        ...s,
        isActive: name === s.appStoreName ? value : s.isActive,
      }))
    );
    setIsFormChanged(true);
  };

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/monetization/ads/banners`;

  const onSave = () => {
    props.savedSnack.launch();
    history.push(listUrl)
  };

  const [createBannerGroup, { loading: isCreating }] = useMutation(CREATE_BANNER_GROUP, {
    update(cache, { data: { createBannerGroup } }) {
      cache.modify({
        fields: {
          bannerGroups(existingRefs) {
            return [...existingRefs, createBannerGroup.bannerGroup];
          },
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      console.log(data);
    },
  });

  const [updateBannerGroup, { loading: isUpdating }] = useMutation(UPDATE_BANNER_GROUP, {
    update(cache, { data: { updateBannerGroup } }) {
      cache.modify({
        fields: {
          bannerGroups(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateBannerGroup.bannerGroup.id === readField("id", ref)
                ? { ...ref, ...updateBannerGroup.bannerGroup }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      console.log(data);
    },
  });

  const nameInputSegment = (
    <Grid container>
      <Grid item xs={12}>
        <Input
          label="Group Name"
          name="name"
          value={name}
          onChange={setName}
          inputProps={{ maxLength: 15 }}
          helperText={`${15 - name.length} - character limit`}
          error={name.length == 0 && nameError}
          required
          autoFocus
        />
      </Grid>
    </Grid>
  );


  const mazIdSegment = (
    <Grid container>
      <Grid item xs={12}>
        <GroupHeader title="MAZ ID Segments" />
      </Grid>

      <Grid item xs={12}>
        <Select
          value={segmentation}
          onChange={setSegmentation}
          options={[
            { label: "All Users", value: "all" },
            { label: "Subscribers Only", value: "sub" },
            { label: "Non Subscribers Only", value: "non_sub" },
            { label: "Logged In", value: "logged_in" },
            { label: "Non Logged In", value: "logged_out" },
          ]}
          label="User Type"
          helperText="Banner group will be visible to those types of users"
          required
        />
      </Grid>
    </Grid>
  );

  const channelSegment = (
    <Grid container>
      <Grid item xs={12}>
        <GroupHeader title="Stores" />
      </Grid>

      {store_data.map((store_obj) => (
        <Grid item xs={6} style={{ display: "flex", height: "5em" }}>
          <FormControlLabel
            control={
              <>
                <Switch
                  color="primary"
                  checked={
                    stores.find((s) => s.appStoreName === store_obj.name)
                      .isActive
                  }
                  name={store_obj.name}
                  onChange={(event) =>
                    handleAppStoreSwitch(event.target.checked, store_obj.name)
                  }
                />
                <img
                  height={34}
                  width={34}
                  src={store_obj.logo}
                  className={classes.store_img}
                />
              </>
            }
            label={store_obj.display_name}
          />
        </Grid>
      ))}
    </Grid>
  );

  const stateForm = {
    name,
    segmentation,
    bannerStoresAttributes: stores.map((st) => ({
      ..._.omit(st, "__typename", "appStoreName"),
      storeName: st.appStoreName,
    })),
  };

  const isValidate = () => {
    let check = true;
    if(!stateForm.name){
      setNameError("Tracking name can't be blank")
      check = false
    }
    return check
  }

  const submit = () => {
    if(!isValidate()){
      return;
    }
    if (bannerGroup.id) {
      updateBannerGroup({
        variables: {
          ...appProps,
          bannerGroupId: bannerGroup.id,
          bannerGroupFields: stateForm,
        },
      });
    } else {
      createBannerGroup({
        variables: { ...appProps, bannerGroupFields: stateForm },
      });
    }
  };

  const nonNullTitle = name || `Untitled Banner`;
  const isLoading = isCreating || isUpdating;

  return (
    <ContentLayout title={nonNullTitle} >
      <FormLayout
        breadcrumbs={[
          { name: "Banner Groups", link: listUrl },
          { name: nonNullTitle },
        ]}
        submit={submit}
        isChanged={isFormChanged}
        betterForm={true} 
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <div className={classes.root}>
          <PaperGroup>
            {nameInputSegment}
          </PaperGroup>
          <PaperGroup>
            {mazIdSegment}
          </PaperGroup>
          <PaperGroup>
            {channelSegment}
          </PaperGroup>
        </div>
      </FormLayout>
    </ContentLayout>
  );
}

export default Form;
