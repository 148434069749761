import { gql } from '@apollo/client';

export const LIVE_PROGRAM_FRAGMENT = gql`
  fragment LiveProgramSchema on LiveProgram {
    __typename
    id
    title
    start
    stop
    imageUrl
    className
    segments {
      id
      format
      url
    }
  }
`

export const GET_LIVE_PROGRAMS = gql`
  query livePrograms($workspaceId: ID! $appId: ID! $liveChannelId: ID! $liveScheduleId: ID!) {
    livePrograms(workspaceId: $workspaceId appId: $appId liveChannelId: $liveChannelId liveScheduleId: $liveScheduleId) {
      ...LiveProgramSchema
    }
  }
  ${LIVE_PROGRAM_FRAGMENT}
`

export const GET_LIVE_PROGRAM = gql`
  query liveProgram($workspaceId: ID! $appId: ID! $liveChannelId: ID! $liveScheduleId: ID! $id: ID!) {
    liveProgram(workspaceId: $workspaceId appId: $appId liveChannelId: $liveChannelId liveScheduleId: $liveScheduleId id: $id) {
      ...LiveProgramSchema
    }
  }
  ${LIVE_PROGRAM_FRAGMENT}
`

export const CREATE_LIVE_PROGRAM = gql`
  mutation createLiveProgram(
    $workspaceId: ID!
    $appId: ID!
    $liveChannelId: ID!
    $liveScheduleId: ID!
    $liveProgramFields: LiveProgramInput!
  ) {
    createLiveProgram(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        liveChannelId: $liveChannelId
        liveScheduleId: $liveScheduleId
        liveProgramFields: $liveProgramFields
      }
    ) {
      liveProgram {
        ...LiveProgramSchema
      }
    }
  }
  ${LIVE_PROGRAM_FRAGMENT}
`

export const UPDATE_LIVE_PROGRAM = gql`
  mutation updateLiveProgram(
    $workspaceId: ID!
    $appId: ID!
    $liveChannelId: ID!
    $id: ID!
    $liveScheduleId: ID!
    $liveProgramFields: LiveProgramInput!
  ) {
    updateLiveProgram(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
        liveChannelId: $liveChannelId
        liveScheduleId: $liveScheduleId
        liveProgramFields: $liveProgramFields
      }
    ) {
      liveProgram {
        ...LiveProgramSchema
      }
    }
  }
  ${LIVE_PROGRAM_FRAGMENT}
`

export const DELETE_LIVE_PROGRAM = gql`
  mutation deleteLiveProgram(
    $workspaceId: ID!
    $appId: ID!
    $liveChannelId: ID!
    $liveScheduleId: ID!
    $id: ID!
  ) {
    deleteLiveProgram(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        liveChannelId: $liveChannelId
        liveScheduleId: $liveScheduleId
        id: $id
      }
    ) {
      liveProgram {
        id
        title
        className
      }
    }
  }
`
