import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { GET_CURRENT_APP_PROPS, CREATE_INTEGRATION, UPDATE_INTEGRATION } from "api";
import ContentLayout from "screens/Layout/ContentLayout";
import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import { Cleeng, Zype, Toolbox } from "./Integrations";
import LocaleOptions from "components/molecules/LocaleOptions";
import { useTheme } from '@mui/material/styles';

const templateMap = {
  "Cleeng": {
    component: Cleeng,
    title: "Cleeng",
  },
  "CleengSandbox": {
    component: Cleeng,
    title: "Cleeng",
  },
  "Zype": {
    component: Zype,
    title: "Zype",
  },
  "ZypeStaging": {
    component: Zype,
    title: "Zype",
  },
  "Toolbox": {
    component: Toolbox,
    title: "Toolbox TVE",
  },
};

function Form(props) {
  const { integration } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const history = useHistory();
  const theme = useTheme();

  const type = integration.type;
  const template = integration?.metadata?.template;
  const title = templateMap[template].title;
  const [ name, setName ] = React.useState(title);
  const [ form, setForm ] = React.useState({});
  const [ localeIds, setLocaleIds ] = React.useState(integration.localeIds || null);
  const [ localeErr, setLocaleErr ] = React.useState(false);
  const [isFormChanged, setIsFormChanged] = React.useState(false);

  React.useEffect(() => {
    setIsFormChanged(true);
  },[localeIds]);

  React.useEffect(() => {
    setIsFormChanged(false);
  },[]);

  const submit = () => {
    if (!isValidate()) return
    integration.id ? updateIntegration() : createIntegration();
  };

  const cancel = () => {
    setIsFormChanged(false);
    history.push(listPath);
  }

  const listPath = `/${appProps.workspaceId}/${appProps.appId}/monetization/integrations`;

  const templateRender = () => {
    return (
      React.createElement(templateMap[template].component, { integration, setForm, setIsFormChanged })
    );
  };

  const [createIntegration, { loading: isCreating }] = useMutation(CREATE_INTEGRATION, {
    variables: {
      ...appProps,
      integrationFields: { ...form, name, localeIds },
    },
    update(cache, { data: { createIntegration } }) {
      cache.modify({
        fields: {
          integrations(existingRefs) {
            return [createIntegration.integration, ...existingRefs];
          },
        },
      });
    },
    onCompleted(data) {
      handleSuccess(data.createIntegration.integration);
    },
    onError(error) {
      handleError(error);
    }
  });

  const [updateIntegration, { loading: isUpdating }] = useMutation(UPDATE_INTEGRATION, {
    variables: {
      ...appProps,
      id: integration.id,
      integrationFields: { ...form, name, localeIds },
    },
    update(cache, { data: { updateIntegration } }) {
      cache.modify({
        fields: {
          integrations(existingRefs, { readField }) {
            return existingRefs.map((ref) => (
              updateIntegration.integration.id === readField("id", ref)
                ? { ...ref, ...updateIntegration.integration }
                : ref
            ));
          },
        },
      });
    },
    onCompleted(data) {
      handleSuccess(data.updateIntegration.integration);
    },
    onError(error) {
      handleError(error);
    }
  });

  React.useEffect(() => {
    setLocaleErr(false);
  }, [localeIds])

  const isValidate = () => {
    let isValid = true;
    if ( localeIds && !localeIds.length ) {
      setLocaleErr(true);
      isValid = false;
    }

    return isValid;
  }

  const handleSuccess = (obj) => {
    props.savedSnack.launch();
    history.push(listPath);
  };

  const handleError = (error) => {
    console.log(error);
  };
  const isLoading = isCreating || isUpdating;

  return (
    <ContentLayout title={name}>
      <FormLayout
        submit={submit}
        cancel={cancel}
        isChanged={isFormChanged}
        betterForm={true} 
        breadcrumbs={[
          {
            name: "Database Connections",
            link: listPath,
          },
          { name: name },
        ]}
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <LocaleOptions
          locales={localeIds}
          setLocales={setLocaleIds}
          wrapPaper
          errorText={localeErr ? "Please select atleast one region": null}
        />

        <PaperGroup>
          {templateRender()}
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default Form;
