import React from 'react';
import Confirm from './Confirm';

export default function LiveStream(props) {
  return (
    <Confirm
      {...props}
      content="This stream will be removed. Are you sure you want to proceed?"
    />
  );
}
