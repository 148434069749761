import { gql } from '@apollo/client';

export const GET_QUICKSIGHT_EMBED_URL = gql`
  query quicksightEmbedUrl($hub: String!) {
    quicksightEmbedUrl(hub: $hub) {
      url
      expiration
    }
  }
`
