import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MobileIcon from "@mui/icons-material/Smartphone";
import TvIcon from "@mui/icons-material/Tv";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LANGUAGE_MAP } from "helpers/langUtils";
import React from "react";

import { MobileSimulator, TvSimulator } from "./Simulator";
import Breadcrumbs from '../Breadcrumbs';
import { withLocaleLang } from "components/containers/withLocaleLang";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    overflow: "hidden",
  },
  wrapper: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
  },
  simulatorContainer: {
    flexGrow: 1,
    position: "relative",
  },
  simulatorScreenContainer: {
    padding: theme.spacing(5.5, 0, 2),
    flexGrow: 1,
  },
  simulatorSpacing: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: theme.spacing(3, 4),
    height: "100%",
    maxHeight: "100%",
    overflowY: "auto",
  },
  contentContainer: {
    width: 300,
    background: "white",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  fullContentContainer: {
    width: "100%",
    paddingTop: 40,
  },
  tabs: {
    maxHeight: 20,
    boxShadow:"0 10px 10px rgba(0,0,0,0.15)" 
  },
  tab: {
    maxHeight: 20,
    minWidth: 20,
  },
  deviceSelectorContainer: {
    position: "absolute",
    right: theme.spacing(3),
    top: theme.spacing(1),
    zIndex: 2,
    display: "flex",
  },
  deviceSelector: {
    lineHeight: 1,
    marginLeft: theme.spacing(1),
  },
}));


function FormLayout(props) {
  const classes = useStyles();
  const { children, simulator, breadcrumbs, isMultiLangOrRegion } = props;

  const showLocaleOption = !isMultiLangOrRegion || ["Guide", "Menu","Video Settings", "Data & Privacy", "Fonts"].includes(simulator?.tab) ? false : true;

  return (
    <>
      {simulator && !simulator.noDevice ? (
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <Box
              borderLeft={1}
              borderColor="grey.300"
              className={classes.contentContainer}
            >
              {children}
            </Box>
            <div className={classes.simulatorContainer}>
              {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
              <div className={classes.deviceSelectorContainer}>
                {showLocaleOption 
                  ? <>
                    <Select
                      value={simulator.region}
                      onChange={(ev) => simulator.setRegion(ev.target.value)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ mr: 2 , height:45, mt:0.5}}
                    >
                      {simulator.regions.map((l, i) => (
                        <MenuItem key={`${l.id}-${i}`} value={l.value}>{l.label}</MenuItem>
                      ))}
                    </Select>

                    <Select
                      value={simulator.language}
                      onChange={(ev) => simulator.setLanguage(ev.target.value)}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ mr: 2 ,height:45, mt:0.5}}
                    >
                      {simulator.languages.map((l, i) => (
                        <MenuItem key={`${l}-${i}`} value={l}>{LANGUAGE_MAP[l]}</MenuItem>
                      ))}
                    </Select>
                  </>
                  : null
                }

                {/* <Paper square elevation={3} className={classes.deviceSelector}> */}
                  <Tabs
                    value={simulator.getter}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={simulator.setter}
                    aria-label="Preview"
                    className={classes.tabs}
                   
                  >
                    <Tab className={classes.tab} icon={<MobileIcon />} />
                    <Tab className={classes.tab} icon={<TvIcon />} />
                  </Tabs>
                {/* </Paper> */}
              </div>

              {!simulator.noDevice && (
                <div className={classes.simulatorSpacing}>
                  <TabPanel value={simulator.getter} index={0}>
                    <MobileSimulator {...props} />
                  </TabPanel>
                  <TabPanel
                    value={simulator.getter}
                    index={1}
                    style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}
                  >
                    <TvSimulator {...props} />
                  </TabPanel>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : simulator ? (
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <Box
              borderLeft={1}
              borderColor="grey.300"
              className={clsx(classes.contentContainer, classes.fullContentContainer)}
            >
              {children}
            </Box>
            <div className={classes.simulatorContainer}>
              <div className={classes.deviceSelectorContainer}>
                {showLocaleOption 
                  ?<>
                    <Select
                      label="Region"
                      value={simulator.region}
                      onChange={(ev) => simulator.setRegion(ev.target.value)}
                      sx={{ mr: 8 }}
                    >
                      {simulator.regions.map((l, i) => (
                        <MenuItem key={`${l.id}-${i}`} value={l.value}>{l.label}</MenuItem>
                      ))}
                    </Select>

                    <Select
                      label="Language"
                      value={simulator.language}
                      onChange={(ev) => simulator.setLanguage(ev.target.value)}
                    >
                      {simulator.languages.map((l, i) => (
                        <MenuItem key={`${l}-${i}`} value={l}>{LANGUAGE_MAP[l]}</MenuItem>
                      ))}
                    </Select>
                  </>
                  : null
                }
              </div>
            </div>
          </div>
        </div>
      ) : children}
    </>
  );
}

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={classes.simulatorScreenContainer}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export default withLocaleLang(FormLayout)