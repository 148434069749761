import { useQuery } from "@apollo/client";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { GET_CURRENT_APP_PROPS, GET_INTEGRATIONS } from "api";

export default function Externals({ getter, setter, disabled }) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_INTEGRATIONS, { variables: appProps });

  const handleChange = (id, checked) => {
    let integrationIds = getter.integrationIds;
    if (checked) {
      integrationIds = [ ...integrationIds, id ];
    } else {
      integrationIds = integrationIds.filter(i => i !== id);
    }
    setter(prev => ({ ...prev, integrationIds }));
  };

  return (
    <>
      {data?.integrations?.filter(i => i.isActive && i.sso).map(i => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={getter.integrationIds.includes(i.id)}
                onChange={(e) => handleChange(i.id, e.target.checked)}
                name={i.name}
              />
            }
            label={i.name}
          />
        </>
      ))}
    </>
  );
};
