import { useQuery, useMutation } from "@apollo/client";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Form from "./Form";
import { GET_CURRENT_APP_PROPS, GET_APP_STORES, UPDATE_APP_STORE, GET_CURRENT_APP } from "api";

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Platforms(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data } = useQuery(GET_APP_STORES, { variables: appProps });
  const { path } = useRouteMatch();

  const [ updateStoreApi, { loading: isUpdating }] = useMutation(UPDATE_APP_STORE, {
    update(cache, { data: { updateAppStore } }) {
      cache.modify({
        fields: {
          appStores(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateAppStore.appStore.id === readField("id", ref)
                ? { ...ref, ...updateAppStore.appStore }
                : ref
            );
          },
        },
      });
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const commonProps = {
    stores: data?.appStores,
    updateStoreApi,
    appProps,
    isMultiLangOrRegion: props.isMultiLangOrRegion,
    app,
    isUpdating
  };

  return (
    <Switch>
      <Route exact path={`${path}/web`}>
        <Form name="web" title="Web" {...commonProps} />
      </Route>
      <Route exact path={`${path}/apple`}>
        <Form name="apple" title="Apple" {...commonProps} />
      </Route>
      <Route exact path={`${path}/google`}>
        <Form name="google" title="Google" {...commonProps} />
      </Route>
      <Route exact path={`${path}/roku`}>
        <Form name="roku" title="Roku" {...commonProps} />
      </Route>
      <Route exact path={`${path}/amazon`}>
        <Form name="amazon" title="Amazon" {...commonProps} />
      </Route>
      <Route exact path={`${path}/samsung`}>
        <Form name="samsung" title="Samsung" {...commonProps} />
      </Route>
      <Route exact path={`${path}/lg`}>
        <Form name="lg" title="LG" {...commonProps} />
      </Route>
      <Route exact path={`${path}/vizio`}>
        <Form name="vizio" title="Vizio" {...commonProps} />
      </Route>
      <Route exact path={`${path}/microsoft`}>
        <Form name="microsoft" title="Microsoft" {...commonProps} />
      </Route>
    </Switch>
  );
}

export default withSavedSnack(Platforms);
