import moment from "moment"

export const isPastDateTime = (date) => {
    const momentDate = moment(date);
    const currDate = moment()
    return currDate.isAfter(momentDate)
}

export const friendlyDates = (date) =>{
    const byDefault = moment(date).format("MMMM Do YYYY");
    const time = moment(date).format("h:mm a");
    return moment(date).calendar(null, {
        sameDay: `[Today at ${time}]`,
        nextDay: `[Tomorrow at ${time}]`,
        lastDay: `[Yesterday at ${time}]`,
        lastWeek: `[${byDefault}]`,
        nextWeek: `[${byDefault}]`,
        sameElse: `[${byDefault}]`
    });
}