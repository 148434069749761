import { ApolloClient, createHttpLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';
import React from 'react';
import { cache } from 'cache';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_CORE_URL}/graphql`,
});

const authLink = (token) => setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const client = (token) => new ApolloClient({
  cache,
  link: authLink(token).concat(httpLink),
});

export default function ApolloContainer(props) {
  return (
    <ApolloProvider client={client(props.token)}>
      {props.children}
    </ApolloProvider>
  );
}
