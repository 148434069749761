import { useLazyQuery, useQuery, useApolloClient } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import _ from "lodash";

import { GET_CURRENT_APP_PROPS, GET_PAGINATED_BUNDLES, GET_BUNDLES } from "../../../api";
import Base from "./Base";
import DataGrid from "../../atoms/DataGrid";
import SubmitButton from "../../atoms/Button/Submit";
import { getBundleCatalog } from "helpers/bundleUtils";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import moment from 'moment'
import { getBundleData } from "helpers/bundleUtils";
import { AllCommunityModules } from '@ag-grid-community/all-modules';

const useStyles = makeStyles((theme) => ({
  formDatagrid: {
    '& .ag-row-selected-disable': {
      pointerEvents: 'none',
      opacity: '0.5',
    },
    '& .ag-checkbox-disable .ag-selection-checkbox': {
      pointerEvents: 'none',
      opacity: '0.5',
    }
  },
  rowClass: {
    '&.ag-row-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    },
    '& > div': {
      border: 'none !important',
    },
    cursor: 'pointer'
  }
}))

const singleColumns = [
  {
    field: 'title',
    width: 400,
  },
  {
    field: "catalog",
    headerName: "Kind",
    width: 200,
    flex: 1,
  },
];

const columns = [
  { field: 'id', hide: true },
  { field: 'title', hide: true },
  {
    field: "catalog",
    headerName: "Kind",
    width: 200,
    flex: 1,
  },
];

const autoGroupColumnDef = {
  field: 'title',
  width: 400,
  cellRendererParams: {
    checkbox: true,
  }
};

export default function BundleSelector(props) {
  const client = useApolloClient();
  const theme = useTheme();
  const { id, filters, collectionId, addingTo, multiple, selectSingle: isDeeplinkSelector, selectedBundles=[], freezeAlreadySelected, disableInActive=false, ...otherProps } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const [ added, setAdded ] = React.useState([]);

  const [selected, setSelected] = React.useState(multiple ? [] : id);
  const [gridApi, setGridApi] = React.useState(null);

  const [loadExistingData, loadEventsRequest] = useLazyQuery(GET_BUNDLES, {
    variables: {
      ...appProps,
      collectionId: addingTo,
      limit: 100,
    },
    onCompleted: (data) => {
      setAdded(data.bundles.map(b => b.id));
    },
  });

  React.useEffect(() => {
    if (addingTo) {
      loadExistingData();
    }
  }, [addingTo]);

  React.useEffect(() => {
    if (added.length && gridApi) {
      updateDisabledRows()
    }
  }, [added, gridApi])

  const handleSelect = (params) => {
    if (isDeeplinkSelector) {
      setSelected([ {data: params.data, parent:params.node.parent} ]);
    } else if (multiple) {
      setSelected(params.api.getSelectedNodes().map((n) => n.data.object));
    } else {
      setSelected(params.api.getSelectedNodes()[0].data.object);
    }
  };

  const saveAndClose = () => {
    props.onClose(selected);
  };

  const classes = useStyles();

  const rowClassRules = {
    'ag-row-selected-disable': function(params) {
      if (disableInActive && !params.node.data?.isActiveAndPublishableMenuPresent) return true
      return params.node.data?.disableRow
    },
    'ag-checkbox-disable': function(params) {
      return freezeAlreadySelected && selectedBundles.some(b => parseInt(b.id) === parseInt(params.node.data?.id));
    },
  };

  const handleMultipleSelect = () => {};

  let gridProps = {
    rowClassRules,
  };

  const updateDisabledRows = () => {
    if (added.length && gridApi) {
      gridApi.forEachNode(function (rowNode) {
        rowNode.setData({...rowNode.data, disableRow: added.includes(rowNode.data?.id)});
      });
    }
  }

  const onDataChanged = (e) => {
    updateDisabledRows();
    if (!selectedBundles || !selectedBundles.length) return
    e.api.forEachNode(node => {
      if (selectedBundles.some(b => parseInt(b.id) === parseInt(node.data?.id))) {
        !node.isSelected() && node.setSelected(true);
      }
    })
  }

  const rowSelection = !multiple || isDeeplinkSelector ? "single": "multiple"

  gridProps = {
    ...gridProps,
    columnDefs: props.multiple ? columns : singleColumns,
    modules: [ ServerSideRowModelModule, RowGroupingModule, AllCommunityModules ],
    rowSelection: rowSelection,
    onRowSelected: handleSelect,
    isRowSelectable: (rowNode) => rowNode.data ? !added.includes(rowNode.data.id) : false,
    onGridReady: onGridReady,
    pagination: true,
    serverSideStoreType: "partial",
    paginationAutoPageSize: true,

    defaultColDef: {
      flex: 1,
      minWidth: 100,
    },
    autoGroupColumnDef: autoGroupColumnDef,
    animateRows: true,
    rowModelType: "serverSide",
    treeData: props.multiple,
    isServerSideGroup: dataItem => {
      return dataItem.group;
    },
    getServerSideGroupKey: dataItem => {
      return dataItem.id;
    },
    suppressRowClickSelection: props.multiple,
    onGridReady: onGridReady,
    onModelUpdated: onDataChanged,
  }

  async function getBundlesApi(params, offset, limit) {
    const res = await client.query({
      query: GET_BUNDLES,
      variables: {
        ...appProps,
        ...(params.parentNode?.data?.id
          ? { collectionId: params.parentNode?.data?.id }
          : { filters: filters }),
        offset: offset,
        limit: limit,
      }
    });
    return res.data.bundles;
  }

  function setBundles(params, data) {
    const trees = getBundlesData(data);
    params.success({ rowData: trees, rowCount: trees.length });
  }

  async function getBundles(params) {
    const limit = 10
    let page = 1;
    let data = await getBundlesApi(params, 0, limit);
    let dataCount = data.length;
    setBundles(params, data);

    while (dataCount === limit) {
      const newData = await getBundlesApi(params, page*limit, limit);
      dataCount = newData.length;

      data = [ ...data, ...newData ];
      setBundles(params, data);
      page += 1;
    }
  }

  function singleDatagridserver() {
    return {
      getRows: (params) => {
        if (params.parentNode?.data?.id) {
          getBundles(params);
        } else {
          const req = params.request;
          client.query({
            query: GET_PAGINATED_BUNDLES,
            variables: {
              ...appProps,
              ...(params.parentNode?.data?.id
                ? { collectionId: params.parentNode?.data?.id }
                : { filters: filters }),
              offset: req.startRow,
              limit: (req.endRow - req.startRow),
            }
          })
          .then(res => {
            const data = res.data;
            const trees = getBundlesData(data.paginatedBundles.bundles);
            params.success({ rowData: trees, rowCount: data.paginatedBundles.count });
          })
          .catch(err => {
            console.log(err)
            params.fail();
          })
        }
      }
    }
  }

  function onGridReady(params) {
    const source = singleDatagridserver();
    params.api.setServerSideDatasource(source)
    setGridApi(params.api);
  };

  return (
    <Base
      dimensions={{ width: theme.spacing(100), height: theme.spacing(77) }}
      title="Content"
      titleRight={() => (
        <SubmitButton disabled={!Boolean(selected)} onClick={saveAndClose}>
          Done
        </SubmitButton>
      )}
      className={classes.formDatagrid}
      {...otherProps}
    >
      <DataGrid {...gridProps} />
    </Base>
  );
}

function getBundlesData(bundles) {
  const response = [];
  bundles.forEach(bundle => {
    // if (!["Bundle::Media::Extra", "Bundle::Media::Preview", "Bundle::Media::Trailer"].includes(bundle.type)) {
      const isActiveAndPublishableMenuPresent = bundle.menus.some(m => m.isActive && m.isPublishable)
      const { title } = getBundleData(bundle);
      const current = {
        id: bundle.id,
        title: title,
        catalog: getBundleCatalog(bundle.metadata?.catalog || bundle.type),
        group: ["Bundle::Collection::Manual", "Bundle::Catalog::Series", "Bundle::Catalog::Season", "Bundle::Catalog::LimitedSeries", "Bundle::Playlist::Feed", "Bundle::Playlist::Search", "Bundle::Playlist::Manual", "Bundle::Layout::Section","Bundle::Layout::Symlink"].includes(bundle.type),
        object: bundle,
        isActiveAndPublishableMenuPresent,
        disableRow: false
      };
      response.push(current);
    // }
  });
  return response;
}
