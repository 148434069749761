import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import urlJoin from "url-join";
import _ from "underscore";
import {
  GET_CURRENT_APP_PROPS,
  GET_CURRENT_APP,
  GET_BADGES,
  UPDATE_BADGE,
  DELETE_BADGE,
  REARRANGE_BADGES,
} from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import ContentLayout from "screens/Layout/ContentLayout";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 400,
    cellRenderer: "titleCellRenderer",
    rowDrag: true
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    sortable:false,
    flex: 1,
    minWidth: 250
  },
];

function List(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data,loading,refetch } = useQuery(GET_BADGES, { variables: appProps });
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const [badgesList, setBadgesList] = React.useState([]);

  React.useEffect(() => {
    if (data?.badges) {
     setBadgesList(data.badges);
    }
  }, [data])

  const onEdit = (badge) => {
    history.push(urlJoin(pathname, `edit/${badge.id}`));
  };

  const onDelete = (badge) => {
    setDeleteConfirmation(badge);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(null);
  };

  const toggleActive = (badge, value, event) => {
    updateBadge({
      variables: {
        ...appProps,
        badgeId: badge.id,
        badgeFields: { isActive: value },
      },
    });
  };

  const [updateBadge] = useMutation(UPDATE_BADGE, {
    update(cache, { data: { updateBadge } }) {
      const existingBadges = client.readQuery({
        query: GET_BADGES,
        variables: appProps,
      });

      const newBadges = existingBadges.badges.map((s) => (s.id == updateBadge.badge.id ? { ...s, ...updateBadge.badge } : { ...s }))

      client.writeQuery({
        query: GET_BADGES,
        variables: appProps,
        data: { badges: newBadges },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const [deleteBadge] = useMutation(DELETE_BADGE, {
    update(cache, { data: { deleteBadge } }) {
      cache.modify({
        fields: {
          badges(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteBadge.badge.id !== readField("id", ref)
            );
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
    },
    onError(data) {
      console.log(data);
    },
  });

  const deleteBadgeApi = (id) => {
    closeDeleteConfirmation();
    deleteBadge({
      variables: {
        ...appProps,
        badgeId: id,
      },
    });
  };

  const [rearrangeBadges] = useMutation(REARRANGE_BADGES, {
    onCompleted(data) {
      refetch();
      props.savedSnack.launch()
    },
    onError(data) {
      console.log(data);
    },
  });

  const onSort = (params) => {
    const draggedData = params.api.rowModel.rowsToDisplay.map((o,i) => {
      let obj = {...o.data};
      obj.sortOrder = i
      return obj;
    })
    const sortedData = _.sortBy(draggedData, (f) => f.sortOrder);

    rearrangeBadges({
      variables: {
        ...appProps,
        ids: sortedData.map(t => t.id)
      }
    })
    setBadgesList(sortedData);
  };

  return (
    <ContentLayout
      title="Attributes"
      fab={{ onClick: () => history.push(urlJoin(pathname, "new")) }}
    >
      <FormLayout>
        <DataGrid
          rowData={
                  _.sortBy(badgesList, (f) => f.sortOrder)
                  .map((s) => ({
                  ...s,
                  title: s.name,
                  actions: {
                    toggle: {
                      getter: s.isActive,
                      setter: (value, event) => !s.isSystemAttribute && toggleActive(s, value, event),
                      disabled: s.isSystemAttribute
                    },
                    onEdit,
                    onDelete: !s.isSystemAttribute && onDelete,
                  },
                })) || []
          }
          columnDefs={columns}
          defaultColDef={{ sortable: true }}
          accentedSort={true}
          immutableData={true}
          getRowNodeId={(data) => data.id}
          rowDragManaged={true}
          onRowDragEnd={onSort}
          animateRows={true}
          suppressMoveWhenRowDragging={true}
        />
        <ConfirmDialog
          content={"This attribute will be removed from the app. Are you sure you want to proceed?"}
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => {
            deleteBadgeApi(deleteConfirmation.id);
          }}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default List;
