import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_BANNER } from "api";
import Form from "./Form";

export default function Edit(props) {
  const { parentId, id } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_BANNER, {
    variables: {
      ...appProps,
      bannerGroupId: parentId,
      id: id,
    },
  });

  return data?.banner ? <Form {...props} banner={data?.banner} /> : null;
}
