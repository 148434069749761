import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";

const useStyles = makeStyles((theme) => ({
    epg_container: {
        width: "100%",
        height: "100%",
        backgroundColor: "#000",
        color: "#fff",
        borderRadius: "5px",
        overflowY: "hidden",
    },

    epg_navbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "50px",
        padding: "0 10px",
    },

    epg_navbar_left: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "10px",
    },

    epg_navbar_right: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: "10px",
    },

    epg_navbar_logo: {
        fontSize: "20px",
        fontWeight: "500",
    },

    epg_navlink_active: {
        fontSsize: "16px",
        fontWeight: "500",
    },
    epg_preview: {
        height: "calc(100% - 50px)",
    },
    epg_preview_description:(props)=> ({
        display: "grid",
        gridTemplateColumns: "50% 50%",
        padding: "5px 10px",
        height: "50%",
        backgroundColor:props.epgDetailsBackgroundColor
    }),

    ch_show_name: {
        display: "flex",
        flexDirection: "column",
    },

    title: (props) => ({
        textTtransform: "uppercase",
        fontSize: "14px",
        fontWeight: "500",
        color: props.epgDetailsPrimaryTextColor || "#fff"
    }),

    sub_title: (props) => ({
        fontSize: "12px",
        color: props.epgDetailsSecondaryTextColor || "#9facb5",
    }),

    epg_main: (props) =>({
        backgroundColor: props.epgGuideBackgroundColor || "#283135",
        height: "50%",
        overflow: "scroll",
    }),

    epg_header:(props)=>({
        display: "flex",
    }),
    current_time: (props) => ({
        padding: "10px",
        minWidth: "230px",
        maxWidth: "230px",
        color: props.epgTimeMarkerColor || "#76EFEF",
        backgroundColor: props.epgTimelineBackgroundColor || "#46555e",
    }),
    time_head: (props)=>({
        position: "relative",
        backgroundColor: props.epgTimelineBackgroundColor || "#46555e",
    }),
    time: (props) => ({
        position: "relative",
        display: "table-cell",
        padding: "10px",
        minWidth: "360px",
        maxWidth: "360px",
        overflow: "hidden",
        color: props.epgTimeDisplayColor || "#ffffff",
        "&::before": {
            content: '""',
            position: "absolute",
            top: "0",
            height: "50px",
            borderLeft: "0.5px dotted  #000",
            left: "30px",
        }
    }),

    time_line: {
        position: "absolute",
        left: "80px",
        top: "0",
    },

    time_line_h: (props) => ({
        display: "block",
        width: "12px",
        height: "12px",
        background: props.epgTimeMarkerColor || "#76EFEF",
        borderRadius: "6px",
    }),

    time_line_t: (props) => ({
        display: "block",
        height: "28px",
        borderLeft: `2px solid ${props.epgTimeMarkerColor || "#76EFEF"}`,
        marginLeft: "5px",
    }),
    epg_channel_programs: {
        display: "flex",
        minWidth: "1080px",
    },
    epg_channel_programs_r: (props) => ({
        border: `0.5px solid ${props.epgBorderColor || "#D4D6D7"}`,
        display: "flex",
        width: "100%",
        flex: "1",
    }),
    epg_channel_link: (props) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "sticky",
        left: "0",
        cursor: "pointer",
        minWidth: "260px",
        height: "50px",
        borderTop: "1px solid #3d4b51",
        borderBottom: "1px solid #3d4b51",
        backgroundColor: props.epgChannelColor,
        '& span': {
            color: props.epgChannelLabelColor || "#ffffff"
        }
    }),

    title_box: (props) => ({
        boxSizing: "border-box",
        maxHeight: "50px",
        width: "262px",
        borderRight: `0.5px solid ${props.epgBorderColor || "#D4D6D7"}`,
        backgroundColor: props.epgInactiveProgramColor
    }),
    title_wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        padding: "0 10px",
    },
    program_label: (props) => ({
        color: props.epgProgramLabelColor
    }),
    show_subtitle: (props) => ({
        fontSize: "12px",
        color: props.epgProgramSubtitleColor || "#adb3ad",
    })
}));

export default function Epg(props) {
    const { simulator } = props;
    const { design } = simulator;
    const classes = useStyles(design);
    return (
        <div className={classes.epg_container}>
            <div className={classes.epg_navbar}>
                <div className={classes.epg_navbar_left}>
                    <span className={classes.epg_navbar_logo}>MAZ Channel</span>
                    <p>Home</p>
                    <p>Series</p>
                    <p className={classes.epg_navlink_active}>Episodes</p>
                    <p>Schedule</p>
                    <p>Saved</p>
                </div>
                <div className={classes.epg_navbar_right}>
                    <div className={classes.epg_searchbox}>
                        <SearchIcon />
                    </div>
                    <div className={classes.epg_settings}>
                        <SettingsIcon />
                    </div>
                </div>
            </div>
            <div className={classes.epg_preview}>
                <div className={classes.epg_preview_description}>
                    <div className={classes.ch_show_name}>
                        <span className={classes.title}>Shark Tank</span>
                        <span className={classes.sub_title}>13:00 - 13:25</span>
                        <span className={`${classes.sub_title} ${classes.item_description}`}></span>
                    </div>
                    <div className={classes.ch_title}></div>
                </div>
                <div className={classes.epg_main}>
                    <div className={classes.epg_header}>
                        <div className={classes.current_time}>Dec 28, 1:33 PM</div>
                        <div className={classes.time_head}>
                            <div className={classes.time}>1:00 PM</div>
                            <div className={classes.time}>2:00 PM</div>
                            <div className={classes.time}>3:00 PM</div>
                            <div className={classes.time_line}>
                                <span className={classes.time_line_h}></span>
                                <span className={classes.time_line_t}></span>
                            </div>
                        </div>
                    </div>
                    <div className={classes.epg_channel_wrapper}>
                        <div className={classes.epg_channel}>
                            <div className={classes.epg_channel_programs}>
                                <div className={classes.epg_channel_link}>
                                    <span>SET Andes</span>
                                </div>
                                <div className={classes.epg_channel_programs_r}>
                                    <div className={classes.title_box} style={{ backgroundColor: design.epgActiveProgramColor }}>
                                        <div className={classes.title_wrapper}>
                                            <span className={classes.program_label}>Shark Tank</span>
                                            <span className={classes.show_subtitle}>Look Up Child</span>
                                        </div>
                                    </div>
                                    <div className={classes.title_box}>
                                        <div className={classes.title_wrapper}>
                                            <span className={classes.program_label}>Grey's Anatomy</span>
                                            <span className={classes.show_subtitle}>Tradition</span>
                                        </div>
                                    </div>
                                    <div className={classes.title_box}>
                                        <div className={classes.title_wrapper}>
                                            <span className={classes.program_label}>Station 19</span>
                                            <span className={classes.show_subtitle}>Episode #1008</span>
                                        </div>
                                    </div>
                                    <div className={classes.title_box} >
                                        <div className={classes.title_wrapper}>
                                            <span className={classes.program_label}>No Program</span>
                                            <span className={classes.show_subtitle}></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
