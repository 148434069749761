import { useQuery } from "@apollo/client"
import React from "react";
import Select from "./Select";
import _ from "underscore";
import { LANGUAGE_MAP } from "helpers/langUtils";
import { GET_CURRENT_APP } from "api";

export default function LanguageSelect(props) {
  const { value, onChange } = props;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const languages = _.uniq(_.flatten(app.locales.map((l) => l.languages)));

  return (
    <Select
      label="Language"
      value={value}
      onChange={onChange}
      options={languages.map((k) => ({ label: LANGUAGE_MAP[k], value: k }))}
    />
  );
}
