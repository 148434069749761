import React from "react";
import _ from "underscore";
import {
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import Input from "components/atoms/Input/BaseInput";

export default function RequestFrequency(props) {
  const { updateState, currentFormKeys, currentStateValues } = props;

  return (
    <Grid container >
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Set request frequency
          </Typography>
        <Typography variant="body2" gutterBottom>
          {currentFormKeys?.name == "google" ? "Choose when ads are requested from Google Ad Manager." : null}
          {currentFormKeys?.name == "spotx" ?
            (<>
              <span>Choose when and how often you want ads to be requested from SpotX.</span>
              <br />
              <span> Currently MAZ only supports SpotX pre-roll ads.</span>
            </>) : null
          }
          {["advanced", "spring_serve"].includes(currentFormKeys.name) ? "Choose how you want ads to be requested from your ad platform." : null}
        </Typography>
      </Grid>
      <Grid item xs={3} >
        <Input
          type="number"
          InputProps={{
            inputProps: {
              max: 20, min: 1
            },
            startAdornment: <InputAdornment position="start">Every</InputAdornment>,
            endAdornment: <InputAdornment position="end">video(s)</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          width={10}
          value={currentStateValues?.adInterval} 
          onChange={(val) => updateState('adInterval', val)}
          label="Request an ad"
          required
        />
        <Input
          type="number"
          InputProps={{
            inputProps: {
              max: 21, min: 1
            },
            startAdornment: <InputAdornment position="start">Before</InputAdornment>,
            endAdornment: <InputAdornment position="end">Video</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          width={10}
          value={currentStateValues?.spotxStart} 
          onChange={(val) => updateState('spotxStart', val)}
          label="Send first request"
          required
        />
      </Grid>
      <Grid item xs={12} >
        <FormControlLabel
          control={<Checkbox 
            checked={currentStateValues?.adRequestEverytimeForLive}  
            onChange={() => updateState('adRequestEverytimeForLive', !currentStateValues?.adRequestEverytimeForLive)} 
          />}
          label="For live feeds, request the ad every time before playing the live stream."
        />
      </Grid>
    </Grid>
  )
}
