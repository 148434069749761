import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import publogo from "images/tv-defaultlogo.png";
import placeholder from "images/placeholder-img.svg";

const useStyles = makeStyles((theme) => ({
  dflexC: {
    display: "flex",
    justifyContent: "center"
  },
  plogo: {
    marginTop: "20px",
    maxWidth: "175px",
    maxHeight: "70px",
  },
  content: {
    paddingLeft: "4rem",
    marginTop: "2rem",
    color: "white",
    fontSize: "14px"
  },
  dflexS: {
    display: "flex",
    justifyContent: "start",
    marginTop: "4px",
  },
  contentImg: {
    width: "133px",
    height: "85px",
    backgroundColor: "#e9e9e9",
    objectFit: "cover",
  },
  contentCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 5px",
    fontSize: "12px",
  },
  imgSize: {
    width: "20px",
    height: "20px",
  },
}));

function CardLayout({ icon }) {
  const classes = useStyles();
  const getIcon = () => {
    if (icon && icon.includes("http")) {
      return icon;
    } else {
      return `https://resources.mazsystems.com/assets/core/production/${icon}@3x.png`;
    }
  };
  return (
    <span style={{ marginRight: "20px" }}>
      <img className={classes.contentImg} src={placeholder} alt="thumb" />
      <div className={classes.contentCard}>
        <span>Title</span>
        <img className={classes.imgSize} src={getIcon()} alt={icon} />
      </div>
    </span>
  );
}

export default function GeneralScreen(props) {
  const { simulator } = props;
  const { design } = simulator;
  const classes = useStyles();

  return (
    <>
      <div className={classes.dflexC}>
        <img
          className={classes.plogo}
          src={design.tvLogoUrl ? design.tvLogoUrl : publogo}
          alt="Logo"
        />
      </div>
      <div className={classes.content}>
        <span>Heading</span>
        <div className={classes.dflexS}>
          {[design.iconSave, design.iconSaved, design.iconSaved].map(
            (icon, i) => (
              <CardLayout
                key={`CardLayoutSimulator-${i}`}
                icon={icon}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}
