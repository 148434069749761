import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import _ from 'lodash';

import BaseInput from './BaseInput';

export default function Select(props) {
  const { value, onChange, label, helperText, options, ...otherProps } = props;

  return (
    <BaseInput
      style={{ maxWidth: 320 }}
      select
      label={label}
      name={_.camelCase(label)}
      value={value}
      onChange={onChange}
      helperText={helperText}
      {...otherProps}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </BaseInput>
  );
}

export function BooleanSelect(props) {
  const { onChange, value, onOff, ...otherProps } = props;

  return (
    <Select
      value={`${value}`}
      onChange={(val) => onChange(val === "true")}
      options={[
        { label: onOff ? "On" : "Yes", value: "true" },
        { label: onOff ? "Off" : "No", value: "false" },
      ]}
      {...otherProps}
    />
  );
}
