import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ListFeeds from "./List";
import NewFeed from "./New";

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Feeds(props) {
  const { path } = useRouteMatch();
  const { savedSnack, hub, formats } = props;
  const commonProps = { savedSnack, hub, formats };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <NewFeed {...commonProps} title="Feed" />
      </Route>

      <Route exact path={path}>
        <ListFeeds {...commonProps} title="Feeds" />
      </Route>
    </Switch>
  );
}

export default withSavedSnack(Feeds);
