import { useQuery, useMutation } from "@apollo/client";
import React from 'react';

import { ME, UPDATE_ME } from "api";
import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import PhoneInput from "components/atoms/Input/Phone";
import InformationGroup from "components/molecules/InformationGroup";
import { formattedPhoneNumber } from "helpers/phoneUtils";
import ContentLayout from "screens/Layout/ContentLayout";

export default function Profile(props) {
  const { data } = useQuery(ME);

  const [ name, setName ] = React.useState(data.me.name);
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);

  const email = data.me.email;
  const phone = data.me.phone;

  const [ updateMeApi, {loading} ] = useMutation(UPDATE_ME, {
    variables: {
      userFields: {
        name: name,
      }
    },
    update(cache) {
      const { me } = cache.readQuery({ query: ME });
      const newData = { ...me, name: name}

      cache.writeQuery({
        query: ME,
        data: { me: newData}
      });
    },
    onCompleted(data) {
      setDisableForm(false);
      setIsFormChanged(false);
      props.savedSnack.launch();
    },
    onError(data) {
      setDisableForm(false)
      setIsFormChanged(true)
    },
    
  });

  React.useEffect(() => {
    setIsFormChanged(true);
  }, [name])

  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  const submit = () => {
    setDisableForm(true)
    updateMeApi();
  };

  const cancel = () => {
    setName(data.me.name);
    setTimeout(() => {setIsFormChanged(false)}, 0)
  }

  let records = [
    { label: "Email", text: email }
  ];
  if (phone) {
    records.push(
      { label: "Phone", value: formattedPhoneNumber(phone) },
    )
  }

  return (
    <ContentLayout title="Profile">
      <FormLayout
        submit={submit}
        cancel={cancel}
        isChanged={isFormChanged}
        betterForm
        disableSave={loading}
        disableCancel={loading}
        isLoading={loading}
      >
        <InformationGroup records={records} />

        <Input
          label="Name"
          value={name}
          onChange={setName}
          autoFocus
          required
          disabled={disableForm}
        />
      </FormLayout>
    </ContentLayout>
  );
}
