import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ListBannerGroups from "./BannerGroups/List";
import EditBannerGroup from "./BannerGroups/Edit";
import NewBannerGroup from "./BannerGroups/New";

import ListBanners from "./Banner/List";
import EditBanner from "./Banner/Edit";
import NewBanner from "./Banner/New";

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Banners(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;

  const commonProps = { savedSnack };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <NewBannerGroup {...commonProps} />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <EditBannerGroup {...commonProps} />
      </Route>

      <Route exact path={`${path}/:parentId`}>
        <ListBanners {...props} {...commonProps} />
      </Route>
      <Route exact path={`${path}/:parentId/new`}>
        <NewBanner {...props} {...commonProps} />
      </Route>
      <Route exact path={`${path}/:parentId/edit/:id`}>
        <EditBanner {...props} {...commonProps} />
      </Route>

      <Route path={path}>
        <ListBannerGroups {...commonProps} />
      </Route>
    </Switch>
  );
}

export default Banners;
