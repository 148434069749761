import React from "react";
import _ from "underscore";
import Link from "@mui/material/Link";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import BundleIdInput from "components/atoms/Input/BundleId";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import PasswordInput from "components/atoms/Input/Password";
import Select from "components/atoms/Input/Select";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Tooltip from "components/atoms/Tooltip";
import { SwitchGroup } from "components/atoms/Input/Switch";
import { ImageUploadGroup } from "./UploadGroup";
import { LG_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import LanguageText from "components/molecules/LanguageText";
import { PlatformApiKey } from "./List";
import PaymentProvider from "./PaymentProvider";

const categories = [
  { label: "Videos", value: "videos" },
  { label: "Games", value: "games" },
  { label: "Lifestyle", value: "lifestyle" },
  { label: "Sports", value: "sports" },
  { label: "Information", value: "information" },
  { label: "Education", value: "education" },
];

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion } = props;
  const platform = store.platforms?.find((p) => p.type === "Platform::Lg");

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store.metadata || {};
  const platformMetadata = platform.metadata || {};
  const { lang } = getDefaultLocaleAndLang(app);

  const [accessGranted, setAccessGranted] = React.useState(
    storeMetadata.access_granted || false
  );
  const [username, setUsername] = React.useState(storeMetadata.username || "");
  const [password, setPassword] = React.useState(storeMetadata.password || "");

  const [appStoreName, setAppStoreName] = React.useState(
    storeMetadata.app_store_name || ""
  );
  const [primaryCategory, setPrimaryCategory] = React.useState(
    storeMetadata.primary_category || ""
  );
  const [secondaryCategory, setSecondaryCategory] = React.useState(
    storeMetadata.secondary_category || ""
  );
  const [rating, setRating] = React.useState(storeMetadata.rating || "");
  const [keywords, setKeywords] = React.useState(storeMetadata.keywords || "");
  const [supportEmail, setSupportEmail] = React.useState(
    storeMetadata.support_email || ""
  );
  const [gaTrackingId, setGaTrackingId] = React.useState(platformMetadata.ga_tracking_id || "");
  const [bundleId, setBundleId] = React.useState(platformMetadata.bundle_id || "");
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))
  const description = descriptionLangObj[lang] || "";

  const saveTimer = React.useRef(null);
  const apiKeys = [{name: "LG", apiKey: platform.apiKey}]

  const [asset, setAsset] = React.useState({
    urls: {
      appIcon: platform.assetUrls.app_icon,
      primaryCover: platform.assetUrls.primary_cover,
      secondaryCover: platform.assetUrls.secondary_covers || [],
      launcherBackground: platform.assetUrls.launcher_background,
      splashBackground: platform.assetUrls.splash_background,
    },
    blobs: {
      appIcon: null,
      primaryCover: null,
      secondaryCover: [],
      launcherBackground: null,
      splashBackground: null,
    },
    errors: {
      appIcon: null,
      primaryCover: null,
      secondaryCover: null,
      launcherBackground: null,
      splashBackground: null,
    },
  });

  const [paymentGateway, setPaymentGateway] = React.useState(store.paymentGateway || "stripe")
  const [lastUploaded, setLastUploaded] = React.useState(null);
  const assetProps = { asset, setAsset, assetMap: LG_ASSET_MAP };

  React.useEffect(() => {
    if (username && password) {
      setAccessGranted(true);
    }
  }, [username, password]);

  React.useEffect(() => {
    if (
      asset.blobs.appIcon ||
      asset.blobs.primaryCover ||
      asset.blobs.secondaryCover.length > 0 ||
      asset.blobs.launcherBackground ||
      asset.blobs.splashBackground
    ) {
      setLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.appIcon,
    asset.blobs.primaryCover,
    asset.blobs.secondaryCover,
    asset.blobs.launcherBackground,
    asset.blobs.splashBackground,
  ]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [lastUploaded, username, password, appStoreName, descriptionLangObj, primaryCategory, secondaryCategory, rating, keywords, supportEmail, gaTrackingId, bundleId, paymentGateway]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            paymentGateway,
            metadata: {
              access_granted: accessGranted,
              username: username,
              password: password,
              app_store_name: appStoreName,
              description: descriptionLangObj,
              primary_category: primaryCategory,
              secondary_category: secondaryCategory,
              rating: rating,
              keywords: keywords,
              support_email: supportEmail,
            },
            platformsAttributes: [
              {
                id: platform.id,
                metadata: {
                  bundle_id: bundleId,
                  ga_tracking_id: gaTrackingId,
                  last_uploaded: lastUploaded,
                },
                assetBlobs: {
                  app_icon: asset.blobs.appIcon,
                  primary_cover: asset.blobs.primaryCover,
                  secondary_covers: asset.blobs.secondaryCover,
                  launcher_background: asset.blobs.launcherBackground,
                  splash_background: asset.blobs.splashBackground,
                },
              },
            ],
          },
        },
      }).then((data) => {
        if (data.updateAppStore) {
          const newPlatform = data.updateAppStore.store.platforms.find((p) => p.id === platform.id);
          setAsset({ ...asset, blobs: { ...asset.blobs, secondaryCover: [] } });
        }
      });
    }
  };

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  const langInput = (data, onSubmit, title, charLimit) => (<LanguageText
    data={data}
    onSubmit={onSubmit}
    languageOnly
    buttonProps={{style: {marginBottom: '32px'}}}
    title={title}
    charLimit={charLimit}
  />)

  const validateForm = () => {
    return true;
  };

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl  }, { name: "LG" }]}>
      <PaperGroup>
        <GroupHeader
          title="General"
          caption="You must connect to stripe if you have enabled the subscriptions or locked the feeds items by IAPs in your app."
        />

        <Tooltip
          title="The App Title is used only in the LG store, not on a user's home screen. Tip: all of the words in the app store name count as keywords when user's search for your app."
        >
          <Input
            label="App Title"
            defaultValue={appStoreName}
            onChange={setAppStoreName}
            inputProps={{ maxLength: 95 }}
            helperText={`${95 - appStoreName.length} - character limit`}
            required
            autoFocus
          />
        </Tooltip>

        <div style={{display: 'flex'}}>
          <Tooltip title="This is a description of your app that will be displayed in the app store. Keep your catchiest content in the first few lines to make sure it is visible to all visitors.">
            <Input
              label="Description"
              value={description}
              onChange={val => {onLangTextChange(val, setDescriptionLangObj)}}
              inputProps={{ maxLength: 4000 }}
              helperText={`${4000 - description.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description", 4000)}
        </div>

        

        <Tooltip title="The Primary category helps prospective downloaders find your app in the App Store.">
          <Select
            label="Primary Category"
            value={primaryCategory}
            onChange={setPrimaryCategory}
            options={categories}
            required
          />
        </Tooltip>

        <Tooltip title="The Secondary category helps prospective downloaders find your app in the App Store.">
          <Select
            label="Secondary Category"
            value={secondaryCategory}
            onChange={setSecondaryCategory}
            options={categories}
            required
          />
        </Tooltip>

        <Tooltip
          interactive
          title={
            <>
              {`LG will assign your app an age rating based on its content. The ratings are 4+, 12+, 16+, or 18+.`}<br/><br/>
              {`Note: MAZ cannot approve any apps with Graphic Realistic Violence or Graphic Sexual/Nudity content.`}
            </>
          }
        >
          <Select
            label="Rating"
            value={rating}
            onChange={setRating}
            options={[
              { label: "All", value: "all" },
              { label: "4+", value: "4+" },
              { label: "12+", value: "12+" },
              { label: "16+", value: "16+" },
              { label: "18+", value: "18+" },
            ]}
            required
          />
        </Tooltip>

        <Input
          label="Tags"
          defaultValue={keywords}
          onChange={setKeywords}
          placeholder="celebrities, celebrity news, taylor swift, justin bieber, kardashians"
          required
        />

        <Input
          label="Support Email"
          defaultValue={supportEmail}
          onChange={setSupportEmail}
          required
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader
          title="Assets"
          caption={
            <>
              {`To avoid rejections by LG, you must go through and strictly adhere to the`}<br/>
              {`LG Seller Lounge specifications for various image assets mentioned in `}
              <Link href="https://help.mazsystems.com/en/metadata-assets" target="_blank">this article</Link>
              {`.`}
            </>
          }
        />

        <ImageUploadGroup {...assetProps} name="appIcon" horizontal previewMaxHeight={148} />
        <ImageUploadGroup {...assetProps} name="primaryCover" horizontal previewMaxHeight={120} />
        <ImageUploadGroup {...assetProps} name="secondaryCover" horizontal previewMaxHeight={96} />
        <ImageUploadGroup {...assetProps} name="launcherBackground" horizontal previewMaxHeight={120} />
        <ImageUploadGroup {...assetProps} name="splashBackground" horizontal previewMaxHeight={120} />
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaymentProvider
        getter={paymentGateway}
        setter={setPaymentGateway}
      />

      <PaperGroup>
        <GroupHeader title="Developer Access" />

        <Tooltip
          title="If you have shared developer access with MAZ, please alert us at success@mazdigital.com to set up this account. Otherwise, please enter your LG account credentials.">
          <Input
            label="LG username"
            defaultValue={username}
            onChange={setUsername}
            required
          />
        </Tooltip>

        <PasswordInput
          label="LG password"
          defaultValue={password}
          onChange={setPassword}
          required
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <GaTrackingIdInput
          label="Google Analytics Tracking ID"
          defaultValue={gaTrackingId}
          onChange={setGaTrackingId}
          required
        />
        <BundleIdInput
          label="App Bundle ID"
          defaultValue={bundleId}
          onChange={setBundleId}
          placeholder={`com.maz.tvod.${appProps.appId}.${platform.id}`}
        />
      </PaperGroup>
    </FormLayout>
  );
};

export default withStore(Platform);
