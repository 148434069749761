import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";

import { GET_CURRENT_APP_PROPS, ME, DELETE_APP } from "api";
import { workspace } from "cache";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import NewAppModal from "components/molecules/Modal/NewApp";
import ConfirmDialog from "components/molecules/Dialog/Confirm/App";
import DefaultAppIcon from "components/atoms/DefaultAppIcon";
import ContentLayout from "screens/Layout/ContentLayout";
import { withRedirect } from "components/containers/Redirect";
import { useLocation } from "react-router-dom";

const columns = [
  {
    field: "image",
    headerName: " ",
    width: 45,
    cellRenderer: "imageCellRenderer",
  },
  { field: "name", headerName: "Name", width: 512, sortable: true, sort:"asc" },
  { field: "action", headerName: "Action", cellRenderer: "actionCellRenderer", flex: 1 , minWidth: 150 },
];

function Brands(props) {
  const client = useApolloClient();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const me = useQuery(ME).data.me;
  const org = me.workspaces.find((w) => w.id === appProps.workspaceId);
  const { pathname } = useLocation();

  const [selected, setSelected] = React.useState("");

  const [ deleteApp ] = useMutation(DELETE_APP, {
    update(cache, { data: { deleteApp } }) {
      const existingMe = client.readQuery({ query: ME });

      const newMe = {
        ...existingMe.me,
        workspaces:
          existingMe.me.workspaces.map((w) => (
            w.id === appProps.workspaceId
              ? { ...w, name: "HEllo", apps: w.apps.filter((a) => a.id !== deleteApp.app.id) }
              : w
          )),
      };

      workspace(newMe.workspaces.find((w) => w.id === appProps.workspaceId));
      client.writeQuery({ query: ME, data: { me: newMe } });
    }
  });

  const [ editing, setEditing ] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  function onEdit(app) {
    props.redirect.set(`${pathname}/edit/${app?.id}`);
  }

  function onDelete(app) {
    setDeleteConfirmation(app);
  }

  function deleteAppApi(id) {
    deleteApp({
      variables: {
        workspaceId: appProps.workspaceId,
        id: id,
      }
    });
    closeDeleteConfirmation();
  }

  function handleSelect(params) {
    const selectedApp = params.api.getSelectedNodes()[0].data;
    props.redirect.set(`${pathname}/${selectedApp?.id}/locales`);
  }

  return (
    <ContentLayout title="Brands" fab={{ onClick: () => setEditing({}) }}>
      <FormLayout
        breadcrumbs={[
          { name: "Brands" },
        ]}
      >
        <DataGrid
          rowData={
            org?.apps
              ? org.apps.map((app) => ({
                  ...app,
                  image: app.iconUrl,
                  defaultImageComponent: DefaultAppIcon,
                  actions: {
                    onEdit,
                    onDelete: org.apps.length > 1 ? onDelete : null,
                  },
                }))
              : []
          }
          onRowSelected={handleSelect}
          rowSelection={"single"}
          columnDefs={columns}
          accentedSort={true}
        />

        <NewAppModal
          open={Boolean(editing)}
          app={editing}
          onClose={() => setEditing(null)}
          savedSnack={props.savedSnack}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => deleteAppApi(deleteConfirmation.id)}
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(Brands);
