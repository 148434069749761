import React from "react";
import { useLocation } from "react-router-dom";
import SubLevelIndex from "../SubLevelIndex";

export default function Catalog(props) {
  const { pathname } = useLocation();
  const pathArray = pathname.split('/');
  const isCustom = pathArray[4] === "collections"  && pathArray[5] === "manual" && true;

  return (
    <SubLevelIndex {...props} category="collections" defaultRoute="playlists" isCustom={isCustom}/>
  );
}
