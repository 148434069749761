import React from "react";
import _ from "underscore";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import PasswordInput from "components/atoms/Input/Password";
import Select from "components/atoms/Input/Select";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Tooltip from "components/atoms/Tooltip";
import { SwitchGroup } from "components/atoms/Input/Switch";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { ROKU_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import LanguageText from "components/molecules/LanguageText";
import { PlatformApiKey } from "./List";

const categories = [
  { label: "Apps", value: "apps" },
  { label: "Comedy", value: "comedy" },
  { label: "Educational", value: "educational" },
  { label: "Fitness", value: "fitness" },
  { label: "Food", value: "food" },
  { label: "Games", value: "games" },
  { label: "International", value: "international" },
  { label: "Kids & Family", value: "kids_and_family" },
  { label: "Lifestyle", value: "lifestyle" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Music", value: "music" },
  { label: "News & Weather", value: "news_and_weather" },
  { label: "Personal Media", value: "personal_media" },
  { label: "Photo Apps", value: "photo_apps" },
  { label: "Religious", value: "religious" },
  { label: "Sci & Tech", value: "Sci_and_Tech" },
  { label: "Screensavers", value: "screensavers" },
  { label: "Shopping", value: "shopping" },
  { label: "Special Interest", value: "special_interest" },
  { label: "Sports", value: "sports" },
  { label: "Themes", value: "themes" },
  { label: "Travel", value: "travel" },
  { label: "Web Video", value: "web" },
];

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion } = props;
  const rokuPlatform = store.platforms?.find(
    (p) => p.type === "Platform::Roku"
  );
  const { lang } = getDefaultLocaleAndLang(app);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store.metadata || {};
  const rokuMetadata = rokuPlatform.metadata || {};

  const [accessGranted, setAccessGranted] = React.useState(
    storeMetadata.access_granted || false
  );
  const [username, setUsername] = React.useState(storeMetadata.username || "");
  const [password, setPassword] = React.useState(storeMetadata.password || "");

  const [appStoreName, setAppStoreName] = React.useState(
    storeMetadata.app_store_name || ""
  );
  const [category, setCategory] = React.useState(storeMetadata.category || "");
  const [rokuGaTrackingId, setRokuGaTrackingId] = React.useState(
    rokuMetadata.ga_tracking_id || ""
  );
  const [ appStoreId, setAppStoreId ] = React.useState(storeMetadata.store_id || "");
  const saveTimer = React.useRef(null);

  const [asset, setAsset] = React.useState({
    urls: {
      largeAppIcon: rokuPlatform.assetUrls.large_app_icon,
      smallAppIcon: rokuPlatform.assetUrls.small_app_icon,
      poster: rokuPlatform.assetUrls.poster,
      cover: rokuPlatform.assetUrls.covers || [],
    },
    blobs: {
      largeAppIcon: null,
      smallAppIcon: null,
      poster: null,
      cover: [],
    },
    errors: {
      largeAppIcon: null,
      smallAppIcon: null,
      poster: null,
      cover: null,
    },
  });

  const [lastUploaded, setLastUploaded] = React.useState(null);
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))
  const description = descriptionLangObj[lang] || "";

  const assetProps = { asset, setAsset, assetMap: ROKU_ASSET_MAP };
  const apiKeys = [{name: "Roku", apiKey: rokuPlatform.apiKey}]

  React.useEffect(() => {
    if (
      asset.blobs.largeAppIcon ||
      asset.blobs.smallAppIcon ||
      asset.blobs.poster ||
      asset.blobs.cover.length > 0
    ) {
      setLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.largeAppIcon,
    asset.blobs.smallAppIcon,
    asset.blobs.poster,
    asset.blobs.cover,
  ]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [
    lastUploaded,
    appStoreName,
    category,
    descriptionLangObj,
    accessGranted,
    username,
    password,
    rokuGaTrackingId,
    appStoreId,
  ]);

  React.useEffect(() => {
    if (username && password) {
      setAccessGranted(true);
    }
  }, [username, password]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            metadata: {
              access_granted: accessGranted,
              username: username,
              password: password,
              app_store_name: appStoreName,
              description: descriptionLangObj,
              category: category,
              store_id: appStoreId,
            },
            platformsAttributes: [
              {
                id: rokuPlatform.id,
                metadata: {
                  ga_tracking_id: rokuGaTrackingId,
                  last_uploaded: lastUploaded,
                },
                assetBlobs: {
                  large_app_icon: asset.blobs.largeAppIcon,
                  small_app_icon: asset.blobs.smallAppIcon,
                  poster: asset.blobs.poster,
                  covers: asset.blobs.cover,
                },
              },
            ],
          },
        },
      });
    }
  };

  const langInput = (data, onSubmit, title, charLimit) => (<LanguageText
    data={data}
    onSubmit={onSubmit}
    languageOnly
    buttonProps={{style: {marginBottom: '32px'}}}
    title={title}
    charLimit={charLimit}
  />)

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  const validateForm = () => {
    return true;
  };

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl  }, { name: "Roku" }]}>
      <PaperGroup>
        <GroupHeader title="General" />
        <Input
          label="App store name"
          defaultValue={appStoreName}
          onChange={setAppStoreName}
          inputProps={{ maxLength: 31 }}
          helperText={`${31 - appStoreName.length} - character limit`}
          required
          autoFocus
        />

        <Tooltip title="Categories allow users to find your app without searching for the app name or keywords.">
          <Select
            label="Category"
            value={category}
            onChange={setCategory}
            options={categories}
            required
          />
        </Tooltip>

        <div style={{display: 'flex'}}>
          <Tooltip title="This is a description of your app that will be displayed in the app store. Keep your catchiest content in the first few lines to make sure it is visible to all visitors.">
            <Input
              label="Description"
              value={description}
              onChange={val => {onLangTextChange(val, setDescriptionLangObj)}}
              inputProps={{ maxLength: 200 }}
              helperText={`${200 - description.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description", 200)}
        </div>

        
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Assets" />

        <ImageUploadGroup
          {...assetProps}
          name="largeAppIcon"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="smallAppIcon"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="poster"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="cover"
          horizontal
          previewMaxHeight={108}
        />
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaperGroup>
        <GroupHeader title="Developer Access" />

        <Input
          label="Roku username"
          defaultValue={username}
          onChange={setUsername}
          required
        />
        <PasswordInput
          label="Roku password"
          defaultValue={password}
          onChange={setPassword}
          required
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <Input
          label="App Store ID"
          defaultValue={appStoreId}
          onChange={setAppStoreId}
          required
        />
        <GaTrackingIdInput
          label="Google Analytics Tracking ID"
          defaultValue={rokuGaTrackingId}
          onChange={setRokuGaTrackingId}
          required
        />
      </PaperGroup>
    </FormLayout>
  );
};

export default withStore(Platform);
