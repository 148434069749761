import React from "react";
import Box from "@mui/material/Box";
import Label from "components/atoms/Text/Label";
import BaseText from "components/atoms/Text/BaseText";

function onError(e) {
  if (e.target) e.target.style.display = 'none'
}

function ItemDetails(props) {
  const { item, headerText } = props;
  return (
    <>
      {item && (
        <>
          {item.image && (
            <img
              onError={onError}
              src={item.image}
              alt={item.title || "Image"}
              style={{ maxWidth: 200, marginBottom: 8, marginTop: 8 }}
            />
          )}
          {item.title     &&  <DetailEntry label="Title" text={item.title} />}
          {item.summary   &&  <DetailEntry label="Summary" text={item.summary} />}
          {item.keywords  &&  <DetailEntry label="Keywords" text={item.keywords} />}
        </>
      )}
    </>
  );
}

export default ItemDetails;

const DetailEntry = ({ label, text }) => {
  return (
    <Box
      display="flex"
      borderColor="grey.300"
      style={{ paddingTop: 8, paddingBottom: 8 }}
    >
      <div style={{ width: 100, flex: 1.1 }}>
        <Label>{label}</Label>
      </div>
      <BaseText>-&nbsp;&nbsp;</BaseText>
      <div style={{ flex: 8 }}>
        <BaseText style={{fontSize: 16}}>{text}</BaseText>
      </div>
    </Box>
  );
};
