import React, { useState } from 'react';
import { useQuery, useMutation } from "@apollo/client";
import makeStyles from '@mui/styles/makeStyles';
import _ from "underscore";

import {
  GET_CURRENT_APP_PROPS,
  GET_CURRENT_APP,
  GET_VIDEO_ADS,
  UPDATE_VIDEO_AD,
  CREATE_VIDEO_AD
} from "api";

import google_logo from 'images/ad_source/google.png'
import spotx_logo from 'images/ad_source/spotX.png'
import spring_serve_logo from 'images/ad_source/springServe.png'
import advanced_logo from 'images/ad_source/third-party.png'

import { withSavedSnack } from "components/molecules/Alerts/Saved";
import FormLayout from "components/molecules/Form/FormLayout";
import AdSourceList from './AdSourceList';
import AdLinks from './AdLinks';
import RequestFrequency from './RequestFrequency';
import AdBreakVmap from './AdBreakVmap';
import VID from './Vid';
import Kvps from './Kvps';
import PaperGroup from 'components/molecules/Form/PaperGroup';
import ContentLayout from 'screens/Layout/ContentLayout';
import Select from "components/atoms/Input/Select";
import { withLocaleLang } from 'components/containers/withLocaleLang';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center'
  },
  divider: {
    margin: theme.spacing(2, 0),
    width: '100%'
  },
  source_content: props => ({
    width: 160,
    borderRadius: '5px',
    border: 'solid 4px white',
    // borderColor: ["google"].includes(props.name) ? '#7eeaf2' : '#fff',
    margin: theme.spacing(2, 0, 0),
  }),
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  red: {
    color: 'red'
  },
  content_id: {
    color: 'red',
    border: '1px red solid',
    borderRadius: '8px',
    padding: '5px',
    backgroundColor: 'white'
  }
}));

const store_devices_obj = [
  {
    name: 'ios',
    display_name: 'iOS',
    placeholder: "Ad tag URL"
  },
  {
    name: 'android',
    display_name: 'Android',
    placeholder: "Ad tag URL"
  },
  {
    name: 'tvos',
    display_name: 'tvOS',
    placeholder: "Ad tag URL"
  },
  {
    name: 'android_tv',
    display_name: 'AndroidTV',
    placeholder: "Ad tag URL"
  },
  {
    name: 'roku',
    display_name: 'Roku',
    placeholder: "Ad tag URL"
  },
  {
    name: 'fire_tv',
    display_name: 'FireTV',
    placeholder: "Ad tag URL"
  },
  {
    name: 'web',
    display_name: 'Web',
    placeholder: "Ad tag URL"
  },
  {
    name: 'samsung',
    display_name: 'Samsung',
    placeholder: "Ad tag URL"
  },
  {
    name: 'lg',
    display_name: 'LG',
    placeholder: "Ad tag URL"
  },
  {
    name: 'vizio',
    display_name: 'Vizio',
    placeholder: "Ad tag URL"
  },
]

const schedule_ad_break = {
  break_frequency: true,
  cuepoints: true
}

const dynamicForm = [
  {
    name: "google",
    logo: google_logo,
    display_name: "Google Ad Manager",
    store_devices_obj: store_devices_obj,
    vid: {
      name: 'Vid Parameter Key',
      placeholder: 'e.g. content_id'
    },
    schedule_ad_break: schedule_ad_break
  },
  {
    name: "spotx",
    logo: spotx_logo,
    display_name: "SpotX",
    store_devices_obj: store_devices_obj.map((channel, index) => ({
      display_name: channel.display_name,
      name: channel.name,
      placeholder: ["roku", "samsung", "lg", "vizio"].includes(channel.name) ? "SpotX URL" : "SpotX channel ID"
    })),
    kvp_note: true
  },
  {
    name: "spring_serve",
    logo: spring_serve_logo,
    display_name: "Spring Serve",
    store_devices_obj: store_devices_obj,
    schedule_ad_break: schedule_ad_break,
    kvp_note: true
  },
  {
    name: "advanced",
    logo: advanced_logo,
    display_name: "Advanced",
    store_devices_obj: store_devices_obj,
    schedule_ad_break: {
      ...schedule_ad_break,
      limit_dec: true
    }
  }
]


function Ads(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { data } = useQuery(GET_VIDEO_ADS, {variables: { ...appProps }});
  const allAdSource = data?.videoAds;
  const classes = useStyles();

  const [sourceName, setSourceName] = useState(null);
  const [currentFormKeys, setCurrentFormKeys] = React.useState(dynamicForm[0]);
  const [adRequestLive, setAdRequestLive] = React.useState(true);
  const [adBreak, setAdBreak] = React.useState("ad_break_frequency");
  const [kvpCount, setKvpCount] = React.useState(1);
  const [switchStatus, setSwitchStatus] = React.useState({
    google: false,
    spotx: false,
    spring_serve: false,
    advanced: false
  });
  const stateForm = {
    adBreakFrequency: null,
    adInterval: 2,
    adKvps: [],
    adLinks: [],
    adRequestEverytimeForLive: true,
    cuepointsKeyValue: null,
    descriptionMacroLimit: null,
    id: null,
    isActive: false,
    source: sourceName,
    spotxStart: 2,
    vid: null,
    kvpMethod: "google_ad_manager_method"
  }

  const [currentStateValues, setCurrentStateValues] = React.useState(stateForm)
  const [callSourceEffect, setCallSourceEffect] = React.useState(false)
  const [localeId, setLocaleId] = React.useState(app.locales[0].id);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const filteredAdSource = allAdSource?.filter(obj => obj.locale?.id === localeId);

  React.useEffect(() => {
    if(data){
      let currentSource = filteredAdSource?.find(obj => obj.isActive);
      setSourceName(currentSource?.source || 'google')
      setSwitchStatus({
        google: false,
        spotx: false,
        spring_serve: false,
        advanced: false,
        ...(currentSource ? { [currentSource.source]: true } : {})
      })
      setCallSourceEffect(!callSourceEffect)
    }
  }, [data, localeId])

  React.useEffect(() => {
    setIsFormChanged(true);
  },[localeId])

  React.useEffect(() => {
    setTimeout(() => setIsFormChanged(false),50);
  },[])

  React.useEffect(() => {
    if(sourceName){
      let currentSource = filteredAdSource?.find(obj => obj.source === sourceName);

      setCurrentFormKeys(dynamicForm.find(ob => ob.name == sourceName))
      setCurrentStateValues({
        ...stateForm,
        ...currentSource
      })
      const adBreakRadio = currentSource?.cuepointsKeyValue ? 'cupoints' : 'ad_break_frequency';
      setAdBreak(adBreakRadio)
      setKvpCount(currentSource?.adKvps.length || 1)
    }
  }, [sourceName, callSourceEffect])

  const updateState = (key, val, channel=null, pos=null) => {
    if(key === 'adLink'){
      let newAdLinks = JSON.parse(JSON.stringify(currentStateValues.adLinks))
      let index = newAdLinks.findIndex(o => o.storeDeviceName == channel.name)
      if(index !== -1){
        newAdLinks[index].hook = val
      }
      else{
        newAdLinks.push({
          storeDeviceName: channel.name,
          hook: val
        })
      }

      setCurrentStateValues({
        ...currentStateValues,
        adLinks: newAdLinks
      })
    }
    else if(key === 'adKvps'){
      let newKvps = JSON.parse(JSON.stringify(currentStateValues.adKvps))

      if(newKvps[pos]){
        newKvps[pos].kvp = val
      }
      else{
        newKvps.push({
          kvp: val
        })
      }
      setCurrentStateValues({
        ...currentStateValues,
        adKvps: newKvps
      })
    }
    else if(key === 'cuepointsKeyValue' || key === 'adBreakFrequency') {
      let obj = {
        'cuepointsKeyValue': key === 'adBreakFrequency' ? null: val,
        'adBreakFrequency': key === 'cuepointsKeyValue' ? null: val
      }
      setCurrentStateValues({
        ...currentStateValues,
        ...obj
      })
    }
    else{
      setCurrentStateValues({
        ...currentStateValues,
        [key]: val
      })
    }
    setIsFormChanged(true);
  }

  const [updateVideoAdApi, { loading: isUpdating }] = useMutation(UPDATE_VIDEO_AD, {
    update(cache, { data: { updateVideoAd } }) {
      cache.modify({
        fields: {
          videoAds(existingRefs, { readField }) {
            return updateVideoAd.videoAds
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const [createVideoAdApi, { loading: isCreating }] = useMutation(CREATE_VIDEO_AD, {
    update(cache, { data: { createVideoAd } }) {
      cache.modify({
        fields: {
          videoAds(existingRefs) {
            return [ ...existingRefs, ...createVideoAd.videoAds ];
          },
        },
      });
    },
    onCompleted(data) {
      props.savedSnack.launch();
    },
    onError(data) {
      console.log(data);
    },
  });

  const refineStateForm = (stateData) => {
    return _.omit({
      ...stateData,
      adLinksAttributes: stateData.adLinks.map((ad) => ({
        storeName: ad.storeDeviceName,
        ..._.omit(ad, "__typename", "storeDeviceName")
      })),
      descriptionMacroLimit: adRequestLive ? parseInt(stateData.descriptionMacroLimit) : null,
      adInterval: parseInt(stateData.adInterval),
      adBreakFrequency: adBreak == 'ad_break_frequency' ? parseInt(stateData.adBreakFrequency) : null,
      spotxStart: parseInt(stateData.spotxStart),
      adKvpsAttributes: stateData.adKvps.map((ak) => _.omit(ak, "__typename")),
      cuepointsKeyValue: adBreak == 'cupoints' ? stateData.cuepointsKeyValue : null,
      isActive: switchStatus[stateData.source]
    },
    "id", "__typename", "adLinks", "adKvps", "locale");
  }

  const submitForm = () => {
    if(currentStateValues.id){
      updateVideoAdApi({
        variables: {
          ...appProps,
          videoAdId: currentStateValues.id,
          videoAdFields: refineStateForm(currentStateValues),
        }
      })
    }
    else{
      createVideoAdApi({
        variables: {
          ...appProps,
          localeId: localeId,
          videoAdFields: refineStateForm(currentStateValues),
        }
      })
    }
    setIsFormChanged(false);
  }

  const isLoading = isCreating || isUpdating;

  return (
    <ContentLayout title="Video Ads">
      <FormLayout className={classes.root}
        submit={submitForm}
        noCancel
        isChanged={isFormChanged}
        betterForm={true} 
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        {props.isMultiLocale && props.isMultiLangOrRegion 
          ? <div style={{ textAlign: "right" }}>
              <Select
                label="Locale"
                value={localeId}
                onChange={setLocaleId}
                style={{ width: 200, textAlign: "left" }}
                options={app.locales.map((l, i) => ({ label: l.name, value: l.id }))}
              />
            </div>
          : null
        }

        <PaperGroup>
          <AdSourceList
            dynamicForm={dynamicForm}
            currentFormKeys={currentFormKeys}
            currentStateValues={currentStateValues}
            switchStatus={switchStatus}
            setSwitchStatus={setSwitchStatus}
            setSourceName={setSourceName}
            setIsFormChanged={setIsFormChanged}
          />
        </PaperGroup>

        <PaperGroup>
          <AdLinks
            updateState={updateState}
            currentFormKeys={currentFormKeys}
            currentStateValues={currentStateValues}
          />
        </PaperGroup>

        <PaperGroup>
          <RequestFrequency
            updateState={updateState}
            currentFormKeys={currentFormKeys}
            currentStateValues={currentStateValues}
          />
        </PaperGroup>

        <AdBreakVmap
          updateState={updateState}
          currentFormKeys={currentFormKeys}
          currentStateValues={currentStateValues}
          adBreak={adBreak}
          setAdBreak={setAdBreak}
          adRequestLive={adRequestLive}
          setAdRequestLive={setAdRequestLive}
        />

        <VID
          updateState={updateState}
          currentFormKeys={currentFormKeys}
          currentStateValues={currentStateValues}
        />

        <PaperGroup>
          <Kvps
            updateState={updateState}
            currentFormKeys={currentFormKeys}
            currentStateValues={currentStateValues}
            kvpCount={kvpCount}
            setKvpCount={setKvpCount}
          />
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}

export default withLocaleLang(withSavedSnack(Ads))
