import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, Checkbox, FormControl, FormGroup, FormControlLabel } from '@mui/material';
import { continents, countries } from "countries-list";
import { Divider } from '@mui/material';
import Tooltip from 'components/atoms/Tooltip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1, 0, 0, 0),
  },
}));

delete continents["AN"];

const ContinentWiseCountries = (function () {
  let final_list = {};

  for (let continent in continents) {
    final_list[continent] = {};

    for (let country in countries) {
      if (countries[country]["continent"] === continent) {
        final_list[continent][countries[country]["name"]] = country
      }
    }
  }
  return final_list;
}());

const continentsObj = function (countryState) {
  let final_list = {};

  if (typeof countryState === 'boolean') {
    for (let continent in continents) {
      final_list[continent] = countryState
    }
    return final_list;
  }

  const allContinentCountries = continentCountries();
  for (let continent in continents) {
    if (countryState['all'] === true) {
      final_list[continent] = true;
    }
    else {
      final_list[continent] = allContinentCountries[0][continent].filter((c) => !countryState[c]).length === 0
    }
  }
  return final_list;
}

const continentCountries = function (checked = true, continent = null) {
  let continentCountriesList = {};
  let countriesObj = {}
  for (let country in countries) {
    let continentCode = countries[country]["continent"];
    if (continentCode !== 'AN') {
      continentCountriesList[continentCode] ? continentCountriesList[continentCode].push(country) : (continentCountriesList[continentCode] = [country])
    }
  }
  if (continent) {
    continentCountriesList[continent].forEach(countryCode => { countriesObj[countryCode] = checked });
  }
  return [continentCountriesList, countriesObj]
}

const continentCountriesIntial = function (countryState) {
  let continent_list = continentCountries()[0]

  let intialCountriesObj = {}
  for (let continent in continent_list) {
    if (countryState['all'] === true) {
      continent_list[continent].forEach(countryCode => { intialCountriesObj[countryCode] = true });
    }
    else {
      intialCountriesObj = {
        ...intialCountriesObj,
        ...continentCountries(false, continent)[1],
        ...countryState,
      }
    }
  }

  return intialCountriesObj;
}

const countriesObj = function (checked = true) {
  let final_list = {};

  for (let country in countries) {
    if (countries[country].continent !== 'AN') {
      final_list[country] = checked;
    }
  }
  return final_list;
}

function Countries(props) {
  const classes = useStyles();
  const { countryState, setCountryState, disabled, setIsFormChanged } = props;

  const [allCountries, setallCountries] = React.useState(countryState.all === true);
  const [allContinents, setallContinents] = React.useState(continentsObj(countryState));
  const [countriesList, setCountriesList] = React.useState(continentCountriesIntial(countryState));

  const [showContinents, setShowContinents] = React.useState(false)
  const [showCountries, setShowCountries] = React.useState(Object.assign({}, ...Object.keys(continents).map((key) => ({ [key]: true }))))

  React.useEffect(() => {
    const isallContinentChecked = Object.keys(allContinents).every(continentCode => allContinents[continentCode])
    setallCountries(isallContinentChecked);
  }, [allContinents])

  const getCountryList = (code) => {
    return ContinentWiseCountries[code] || []
  };

  const generateObj = () => {
    let obj = {};
    const isallContinentChecked = Object.keys(allContinents).every(continentCode => allContinents[continentCode]);
    if (isallContinentChecked) {
      obj = { all: true }
    } else {
      Object.keys(countriesList).forEach(countryCode => {
        obj[countryCode] = countriesList[countryCode]
      });
    }
    setCountryState(obj);
  };

  React.useEffect(() => {
    generateObj()
  }, [countriesList, allContinents])

  React.useEffect(() => {
    setIsFormChanged && setIsFormChanged(true)
  }, [countriesList, allContinents])

  React.useEffect(() => {
    setIsFormChanged && setIsFormChanged(false)
  }, [])

  const allCountriesChange = (e) => {
    const { checked } = e.target;
    setallCountries(checked);
    setallContinents(prev => ({ ...prev, ...continentsObj(checked) }));
    setCountriesList(prev => ({ ...prev, ...countriesObj(checked) }));
  }

  const onContinentChange = (e) => {
    const { checked, name: continentCode } = e.target;
    const [continentObj, countriesObj] = continentCountries(checked, continentCode);
    setCountriesList(prev => ({ ...prev, ...countriesObj }));
    setallContinents(prev => ({ ...prev, [continentCode]: checked }));
  }

  const onCountriesChange = (e, continentCode) => {
    const { checked, name: currCountryCode } = e.target;
    const [continentObj] = continentCountries();
    const isContinentCountriesChecked = continentObj[continentCode].every(countryCode => {
      if (countryCode === currCountryCode) return checked;
      return countriesList[countryCode]
    })
    setallContinents(prev => ({ ...prev, [continentCode]: isContinentCountriesChecked }));
    setCountriesList(prev => ({ ...prev, [currCountryCode]: checked }));
  }

  const continentsList = (
    <FormControl className={classes.formControl}>
      <FormGroup aria-label="position" row>
        <Grid container>
          {
            Object.keys(continents).map((continentCode, index) => (
              <Grid xs={2}>
                <FormControlLabel
                  control={<Checkbox checked={allContinents[continentCode]} onChange={onContinentChange} name={continentCode} />}
                  label={continents[continentCode]}
                  disabled={disabled}
                />
                <Button onClick={() => setShowCountries({ ...showCountries, [continentCode]: !showCountries[continentCode] })} color="primary">{!showCountries[continentCode] ? "(Show all)" : "(Hide all)"}</Button>
                {
                  showCountries[continentCode] &&
                  Object.keys(getCountryList(continentCode)).sort().map((countryName) => (
                    <FormControlLabel
                      control={<Checkbox
                        checked={countriesList[getCountryList(continentCode)[countryName]]}
                        onChange={(e) => onCountriesChange(e, continentCode)} name={getCountryList(continentCode)[countryName]} />}
                      label={countryName}
                      disabled={disabled}
                    />
                  ))
                }
              </Grid>
            ))
          }
        </Grid>
      </FormGroup>
    </FormControl>
  );


  return (
    <Grid container>
      <Grid xs={12}>
        <FormControl component="fieldset" className={classes.formControl}>
          <Tooltip
            title="Enable this to make your content available in all countries"
            placement="bottom-start"
          >
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={<Checkbox checked={allCountries} onChange={allCountriesChange} name="all" />}
                label="Available in all Countries"
                disabled={disabled}
              />
              <Button onClick={() => setShowContinents(!showContinents)} color="primary">{!showContinents ? "(Show all)" : "(Hide all)"}</Button>
            </FormGroup>
          </Tooltip>
        </FormControl>
      </Grid>
      <Grid xs={12}>
        {
          showContinents && continentsList
        }
      </Grid>
    </Grid>
  );
}

export default React.memo(Countries)
