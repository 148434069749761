import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TitleWithButton from 'components/molecules/TitleWithButton';
import { useTheme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  parentDiv: {
    display: 'flex',
    flex: 1,
    maxHeight: "calc(100vh - 128px)",
    "& .form-spacing-nosubmit": {
      maxWidth: '100%',
      padding: 16
    },
    "& .ag-theme-material": {
      // maxWidth: '100% !important',
      // padding: 16
    }
  },
  content:{
    zIndex: 1250 ,

    [theme.breakpoints.down('lg')]: {
     zIndex:100,
    },
  }
}));

/**
 *
 * @param {props} otherProps
 * @returns
 */
function ContentLayout({left, title, right, fab, speedDial, secondaryFab, ...otherProps}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.parentDiv}>
      <Paper square elevation={0} className={classes.content} style={{ position: "absolute", top: 0, left: 0, right: 0}}>
        <Box boxShadow={3} style={{minHeight: theme.spacing(8)}}>
          <TitleWithButton {...{left, title, right, fab, speedDial, secondaryFab}} />
        </Box>
      </Paper>
      {otherProps.children}
    </div>
  );
}

export default ContentLayout;
