export const login = (token) => {
  return { type: "login", payload: token };
};

export const setPushToken = (token) => {
  return { type: "setPushToken", payload: token };
};

export const logout = () => {
  return { type: "logout" };
};
