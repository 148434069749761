import { useQuery, useMutation } from "@apollo/client";
import React from 'react';
import { useTheme } from "@mui/material/styles";

import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import GroupHeader from "components/atoms/Text/GroupHeader";
import LabelText from "components/atoms/Text/Label";
import BaseText from "components/atoms/Text/BaseText";
import HelpText from "components/atoms/Text/Help";
import PaperGroup from "components/molecules/Form/PaperGroup";
import { withRedirect } from "components/containers/Redirect";

import { GET_CURRENT_APP_PROPS, CREATE_LIVE_STREAM, UPDATE_LIVE_STREAM } from "api";
import ContentLayout from "screens/Layout/ContentLayout";

function Form(props) {
  const theme = useTheme();
  const { liveStream } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;

  const [ title, setTitle ] = React.useState(liveStream.title || "");
  const [ description, setDescription ] = React.useState(liveStream.description || "");
  const [titleError, setTitleError] = React.useState(null);
  const [isFormChanged, setIsFormChanged] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(false);

  const listPath = `/${appProps.workspaceId}/${appProps.appId}/live/streams`;

  const handleError = (data) => {
    console.log(data);
    setDisableForm(false)
  };

  const handleSuccess = (data) => {
    props.savedSnack.launch();
    props.redirect.set(listPath);
  };

  const liveStreamFields = { title, description };

  const [ createLiveStream, { loading: isCreating } ] = useMutation(CREATE_LIVE_STREAM, {
    variables: { ...appProps, liveStreamFields },
    update(cache, { data: { createLiveStream } }) {
      cache.modify({
        fields: {
          liveStreams(existingRefs) {
            return [ createLiveStream.liveStream, ...existingRefs ];
          },
        },
      });
    },
    onCompleted(data) { handleSuccess(data); },
    onError(data) { handleError(data); }
  });

  const [ updateLiveStream, { loading: isUpdating } ] = useMutation(UPDATE_LIVE_STREAM, {
    variables: { ...appProps, id: liveStream?.id, liveStreamFields },
    update(cache, { data: { updateLiveStream } }) {
      cache.modify({
        fields: {
          liveStreams(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateLiveStream.liveStream.id === readField("id", ref)
                ? { ...ref, ...updateLiveStream.liveStream }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) { handleSuccess(data); },
    onError(data) { handleError(data); }
  });

  const nonNullName = title || "Untitled Live Stream";
  
  React.useEffect(() => {
    setIsFormChanged(true);
  }, [title, description])
  
  React.useEffect(() => {
    setIsFormChanged(false);
  }, [])

  React.useEffect(() => {
    if (title) {
      setTitleError(null);
    }
  }, [title]);

  function submit() {
    if (title) {
      liveStream.id ? updateLiveStream() : createLiveStream();
    } else {
      setTitleError("Cannot be blank");
    }
  }

  const isLoading = isCreating || isUpdating;

  return (
    <ContentLayout title={nonNullName}>
      <FormLayout
        submit={submit}
        breadcrumbs={[
          { name: "Live Streams", link: listPath },
          { name: nonNullName }
        ]}
        isChanged={isFormChanged}
        betterForm
        isLoading={isLoading}
        disableSave={isLoading}
        disableCancel={isLoading}
      >
        <PaperGroup>
          <Input
            label="Title"
            value={title}
            onChange={setTitle}
            error={Boolean(titleError)}
            helperText={titleError}
            required
            disabled={disableForm}
          />

          <Input
            label="Description"
            value={description}
            onChange={setDescription}
            disabled={disableForm}
          />
        </PaperGroup>

        {liveStream.provider && liveStream.metadata && (
          <PaperGroup>
            <GroupHeader title="Streaming Details" caption=" " />

            {liveStream.metadata.stream_url && (
              <>
                <LabelText>Streaming URL</LabelText>
                <BaseText color="primary" style={{ marginBottom: theme.spacing(2) }}>{liveStream.metadata.stream_url}</BaseText>
              </>
            )}

            {liveStream.provider === "Mux::LiveStreams" && (
              <>
                <LabelText>Ingest URL</LabelText>
                <BaseText color="primary" style={{ marginBottom: 8 }}>rtmps://global-live.mux.com/app</BaseText>
                <HelpText style={{ maxWidth: 600, marginBottom: theme.spacing(2) }}>
                  Our RTMP servers use port number 5222 and not the standard RTMP port number 1935. If your encoder does not provide a method to change the port number, please contact support@mazsystems.com with your encoder details.
                </HelpText>
              </>
            )}

            {liveStream.metadata.stream_key && (
              <>
                <LabelText>Stream Key</LabelText>
                <BaseText color="primary" style={{ marginBottom: theme.spacing(2) }}>{liveStream.metadata.stream_key}</BaseText>
              </>
            )}
          </PaperGroup>
        )}
      </FormLayout>
    </ContentLayout>
  );
}

export default withRedirect(Form);
