const INITIAL_STATE = { appId: "", workspaceId: "", route: {} };

const updateRoute = (state, pathname) => {
    const pathArr = pathname.split('/');
    const [, workspaceId, appId, hub] = pathArr;
    const isForm = pathArr.includes('edit') || pathArr.includes('new');
    if (appId === state.appId && workspaceId === state.workspaceId) {
        if (hub && !isForm) {
            return {
                ...state,
                appId: appId,
                workspaceId: workspaceId,
                route: {
                    ...state.route,
                    [hub]: pathname
                }
            }
        }
        return state;
    }
    else {
        if (hub && !isForm) {
            return { appId: appId, workspaceId: workspaceId, route: { [hub]: pathname } }
        }
        return state;
    }
}

    export default function routeHistory(state = INITIAL_STATE, action) {
        switch (action.type) {
            case "updateRoute":
                return updateRoute(state, action.payload);
            case "clearRoute":
                return { appId: "", workspaceId: "", route: {} };
            default:
                return state;
        }
    }


