import { useQuery, useApolloClient, useMutation } from "@apollo/client";
import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { withRedirect } from "components/containers/Redirect";
import { getBundleCatalog, isMediaType, getContentLinkPath, getBundleData } from "helpers/bundleUtils";
import AddIcon from '@mui/icons-material/Add';
import { getAllLanguagesFromLocale, getAvailableAndLanguage, LANGUAGE_MAP } from "helpers/langUtils";
import BundleSelectorModal from "components/molecules/Modal/BundleSelector";
import BundleTypeSelectorModal from "components/molecules/Modal/BundleTypeSelector";
import moment from "moment";
import _ from "underscore";
import { useTheme } from "@mui/material/styles";

import { GET_PAGINATED_BUNDLES, GET_CURRENT_APP_PROPS, UPDATE_BUNDLE, CREATE_BUNDLE,
  GET_BUNDLES, REARRANGE_BUNDLE_COLLECTIONS, REARRANGE_BUNDLES, DELETE_BUNDLE,
  DELETE_BUNDLE_COLLECTION, GET_BUNDLE, GET_APP_STORES, GET_CURRENT_APP } from "api";
import DataGrid from "components/atoms/DataGrid";
import FormLayout from "components/molecules/Form/FormLayout";
import ContentLayout from "screens/Layout/ContentLayout";
import { withSavedSnack } from "components/molecules/Alerts/Saved";

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import LinkMediaModal from "components/molecules/Modal/LinkMedia";
import Confirm from "components/molecules/Dialog/Confirm/Confirm";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import MenuModal from "components/molecules/Modal/MenuModal";
import {friendlyDates} from "helpers/dateUtil";
import { withLocaleLang } from "components/containers/withLocaleLang";
import { removeSeparatorForMenuLayout } from "components/molecules/Picker/MenuTokenSelector";

function List(props) {
  const { bundleType, collectionId } = useParams();
  const type = bundleType || props.bundleType;
  const category = props.category;
  const rootTypes = {
    playlists: ["manual", "search", "feeds"],
    catalog: ["all", "series", "movies", "extras","events","links"],
    events: [],
    collections: ["manual", "keywords", "actors", "directors", "genre", "categories",],
    sections: [],
    channels: [],
    menu: [],
  }[category];

  return (
    Boolean(collectionId) || rootTypes.includes(type) || rootTypes.length === 0
      ? <BundleList {...props} />
      : null
  );
}

export const columns = [
  {
    field: "title",
    headerName: "Name",
    width: 333,
    cellRenderer: "wrappedTextRenderer",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ['contains', 'notContains'],
      suppressAndOrCondition: true,
    },
    valueGetter: (params) => (params.data.category === "menu" ? removeSeparatorForMenuLayout(params.data?.title) : params.data.title)
  },
  {
    field: "label",
    headerName: " ",
    width: 100,
    cellRenderer: "labelRenderer",
  },
  {
    field: "category",
    headerName: "Kind",
    width: 175,
    valueGetter: (params) => getBundleCatalog(params.data.metadata?.catalog || params.data.type, params.data.isCustom),
    filter:"agSetColumnFilter",
    filterParams:{
      values: ['Episode', 'Movie', 'Event', 'Extra', 'Unbundled'],
      suppressSelectAll: true
    }
  },
  {
    headerName: "Available",
    field: "available",
    width: 150,
  },
  {
    headerName: "Language",
    field: "language",
    width: 150,
  },
  {
    field: "lastUpdated",
    headerName: "Modified",
    width: 215,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
  },
];

const feedColumns = [
  {
    field: "title",
    headerName: "Name",
    width: 300,
    cellRenderer: "wrappedTextRenderer",
    filter: true,
  },
  {
    field: "category",
    headerName: "Kind",
    width: 175,
    valueGetter: (params) => getBundleCatalog(params.data.metadata?.catalog || params.data.type),
    filter:"agSetColumnFilter",
    filterParams:{
      values: ['Episode', 'Movie', 'Event', 'Extra', 'Unbundled'],
      suppressSelectAll: true
    }
  },
  {
    headerName: "Available",
    field: "available",
    width: 150,
  },
  {
    headerName: "Language",
    field: "language",
    width: 150,
  },
  {
    field: "poll",
    headerName: "Poll",
    width: 150,
    cellRenderer: "pollCellRenderer",
    valueGetter: (params) => params.data?.appFeed?.poll,
  },
  {
    field: "lastPolledAt",
    headerName: "Last Polled",
    width: 240,
    type: "dateTime",
    sortable: true,
    valueGetter: (params) =>
      params.data?.appFeed?.lastPolledAt ? friendlyDates(params.data.appFeed.lastPolledAt) : null,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    width: 240,
  },
];

const mediaColumns = [
  {
    field: "image",
    headerName: " ",
    width: 75,
    cellRenderer: "imageCellRenderer",
  },
  {
    field: "title",
    headerName: "Name",
    width: 400,
    cellRenderer: "wrappedTextRenderer",
    filter: "agTextColumnFilter",
    filterParams: {
      filterOptions: ['contains', 'notContains'],
      suppressAndOrCondition: true,
    }
  },
  {
    field: "category",
    headerName: "Kind",
    width: 175,
    valueGetter: (params) => getBundleCatalog(params.data.type),
    filter:"agSetColumnFilter",
    filterParams:{
      values: ['Episode', 'Movie', 'Event', 'Extra', 'Unbundled'],
      suppressSelectAll: true
    }
  },
  {
    headerName: "Available",
    field: "available",
    width: 150,
  },
  {
    headerName: "Language",
    field: "language",
    width: 150,
  },
  {
    field: "duration",
    headerName: "Length",
    width: 175,
  },
  {
    field: "lastUpdated",
    headerName: "Modified",
    width: 215,
  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    minWidth: 240,
    flex:1
  },
];

const mediaGridActions = ["launchLink", "copyText", "onCopy", "onLink"];

const BundleList = (props) => {
  const isMultiLangOrRegion = props.isMultiLangOrRegion;
  const { pathname } = useLocation();
  const { bundleType, collectionId } = useParams();
  const category = props.category;
  const isCustom = props.isCustom;
  const token = props.token;
  const type = bundleType || props.bundleType;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const storeData = useQuery(GET_APP_STORES, { variables: appProps });

  const [ selectedBundle, setSelectedBundle ] = React.useState(null);
  const [ selectedBundleFilter, setSelectedBundleFilter ] = React.useState(["Bundle::Media::Unknown"]);
  const [ bundleTypeSelectorOpen, setBundleTypeSelectorOpen ] = React.useState(false);

  const [linkItem, setLinkItem] = React.useState(null);
  const [unlinkItem, setUnlinkItem] = React.useState(null);
  const [gridApi, setGridApi] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const [menuModalOpen, setMenuModalOpen] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  const webStore = storeData.data?.appStores?.find(s => s.name === "web");
  const webPlatform = webStore?.platforms?.find(p => p.type === "Platform::Web");
  const previewHost = webPlatform?.metadata?.preview;

  const closeBundleTypeSelector = () => {
    setBundleTypeSelectorOpen(false);
    blurSpeedDial();
  };
  const closeMenuModalOpen = (refreshDataStore) => {
    setMenuModalOpen(null);
    if (refreshDataStore) {
      refreshStore()
    }
    blurSpeedDial()
  };

  const refreshStore = () => gridApi.refreshServerSideStore({})

  const blurSpeedDial = () => {setTimeout(() => document.activeElement.blur(), 0);}

  const saveAndCloseBundleSelector = (selected) => {
    if (selectedBundle && selected?.__typename === "Bundle") {
      let bundleFields = { collectionId };
      const newBundleType = selectedBundle === true ? selected.type : selectedBundle;
      const cataloguedAs = selected?.metadata?.catalog;

      if (selectedBundle === "Bundle::Layout::Symlink") {
        bundleFields = { ...bundleFields, type: newBundleType, metadata: { catalog: selected.type, id: selected.id  } };
        createBundle({ variables: { ...appProps, bundleFields }});
      } else {
        if (selected.type === "Bundle::Media::Unknown") {
          bundleFields = { ...bundleFields, type: newBundleType };
        } else {
          if (selected.type.includes("Bundle::Playlist")) {
            const catalog = (selected.type === "Bundle::Playlist::Feed" && cataloguedAs !== "Bundle::Playlist::Feed") ? (cataloguedAs || newBundleType) : newBundleType
            bundleFields = {
              ...bundleFields,
              metadata: {
                catalog,
              }
            };
          }
        }

        if (selectedBundle === "Bundle::Catalog::Event" && !Object.keys(selected.metadata).length) {
          const starts_at = selected?.metadata?.starts_at || moment().startOf("hour").add("1", "hour").unix();
          const ends_at = selected?.metadata?.ends_at || moment.unix(starts_at).clone().add("1", "hour").unix();
          bundleFields= { ...bundleFields, metadata: { starts_at, ends_at } };
        }
        updateBundle({ variables: { ...appProps, id: selected?.id, bundleFields } });
      }
    }
    closeBundleSelectorModal();
  };

  const closeBundleSelectorModal = () => {
    setSelectedBundle(null);
    blurSpeedDial()
  };
  const openBundleSelector = (bundleType, filters) => {
    setSelectedBundleFilter(filters || ["Bundle::Media::Unknown"]);
    setSelectedBundle(bundleType);
  };

  const [unlinkBundle] = useMutation(UPDATE_BUNDLE, {
    update(cache, {data: { updateBundle }}) {
      gridApi.forEachNode(r => {
        if (r.data.id == updateBundle.bundle.id) {
          r.setData({...r.data, ...updateBundle.bundle})
        }
      })
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.gc();
    },
    onCompleted(data) {
      props.savedSnack.launch('Unlinked');
      refreshStore();
      closeModal();
    },
    onError(data) {
      console.log(data);
    },
  });

  function unLink() {
    unlinkBundle({
      variables: {
        ...appProps,
        id: unlinkItem.id,
        bundleFields: {
          type: "Bundle::Media::Unknown",
        },
      }
    });
  }

  const [ createBundle ] = useMutation(CREATE_BUNDLE, {
    update(cache, { data: { updateBundle } }) {
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.evict({ fieldName: 'bundles' });
      cache.gc();
    },
    onCompleted(data) {
      props.savedSnack.launch();;
      refreshStore()
    }
  });

  const [ updateBundle ] = useMutation(UPDATE_BUNDLE, {
    update(cache, { data: { updateBundle } }) {
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.evict({ fieldName: 'bundles' });
      cache.gc();
    },
    onCompleted(data) {
      props.savedSnack.launch();;
      refreshStore()
    }
  });

  const { data } = useQuery(GET_BUNDLE, {
    variables: {
      ...appProps,
      id: collectionId,
    }
  });

  const [ deleteBundle ] = useMutation(DELETE_BUNDLE, {
    update(cache, { data: { deleteBundle } }) {
      // cache.modify({
      //   fields: {
      //     bundles(existingRefs, { readField }) {
      //       return existingRefs.filter(
      //         (ref) => deleteBundle.bundle.id !== readField("id", ref)
      //       );
      //     },
      //   },
      // });
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.evict({ fieldName: 'bundles' });
      cache.gc();
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
      refreshStore()
      // props.redirect.set(parentListPath);
    }
  });

  const [ deleteBundleCollection ] = useMutation(DELETE_BUNDLE_COLLECTION, {
    update(cache, { data: { deleteBundle } }) {
      cache.evict({ fieldName: 'paginatedBundles' });
      cache.evict({ fieldName: 'bundles' });
      cache.gc();
    },
    onCompleted(data) {
      props.savedSnack.launch("Deleted");
      refreshStore()
      // props.redirect.set(parentListPath);
    }
  });

  const openTrailerSelector = () => {
    openBundleSelector("Bundle::Media::Trailer", ["Bundle::Media::Unknown", "Bundle::Media::Trailer"]);
  };
  const openPreviewSelector = () => {
    openBundleSelector("Bundle::Media::Preview", ["Bundle::Media::Unknown", "Bundle::Media::Preview"]);
  };
  const openExtraSelector = () => {
    openBundleSelector("Bundle::Media::Extra", ["Bundle::Media::Unknown", "Bundle::Media::Extra"]);
  };
  const openEpisodeSelector = () => {
    openBundleSelector("Bundle::Catalog::Episode", ["Bundle::Media::Unknown", "Bundle::Catalog::Episode"])
  };

  const baseCrumbPath = `/${appProps.workspaceId}/${appProps.appId}/content${
    props.category ? `/${props.category}` : ''}`;

  const bundle_map = {
    playlists: {
      manual: {
        title: collectionId ? "Playlist" : "Manual Playlists",
        nextLink: collectionId ? null : "manual",
        filters: collectionId ? null : ["Bundle::Playlist::Manual"],
        contentLayoutProps: {
          fab: { onClick: () => collectionId
            ? openBundleSelector(true, [
                "Bundle::Catalog::Movie",
                "Bundle::Catalog::Event",
                "Bundle::Catalog::Episode",
                "Bundle::Media::Trailer",
                "Bundle::Media::Preview",
                "Bundle::Media::Extra",
                "Bundle::Media::Unknown",
              ])
            : onNew({ type: "Bundle::Playlist::Manual" })
          }
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: collectionId ? true : false,
      },
      search: {
        title: collectionId ? "Playlist" : "Search Playlists",
        nextLink: collectionId ? null : "search",
        filters: collectionId ? null : ["Bundle::Playlist::Search"],
        contentLayoutProps: {
          fab: collectionId ? null : { onClick: () => onNew({ type: "Bundle::Playlist::Search" }) }
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: false,
      },
      feeds: {
        title: collectionId ? "Media" : "Feeds",
        nextLink: collectionId ? null : "feeds",
        filters: collectionId ? null : ["Bundle::Playlist::Feed"],
        contentLayoutProps: {
          fab: collectionId ? null : { onClick: () => onNew({ type: "Bundle::Playlist::Feed" }) }
        },
        columns: collectionId ? mediaColumns : feedColumns,
        gridActions: collectionId
          ? ["launchLink", "copyText", "onCopy", "onLink"]
          : ["launchLink", "copyText", "onCopy", "onEdit", "onDelete"],
        sortable: false,
      },
    },
    catalog: {
      all: {
        title: "Media",
        nextLink: null,
        filters: ["Bundle::Media::Unknown", "Bundle::Media::Extra",
          "Bundle::Media::Trailer", "Bundle::Media::Preview",
          "Bundle::Catalog::Episode", "Bundle::Catalog::Movie",
          "Bundle::Catalog::Event"],
        contentLayoutProps: {
          fab: { onClick: () => onNew({ type: "Bundle::Media::Unknown" }) }
        },
        columns: mediaColumns,
        gridActions: ["launchLink", "copyText", "onCopy", "onLink"],
        sortable: false,
      },
      series: {
        title: collectionId ? "Seasons" : "Series",
        nextLink: collectionId ? "seasons" : "series",
        filters: collectionId ? null : ["Bundle::Catalog::Series", "Bundle::Catalog::LimitedSeries"],
        contentLayoutProps: {
          speedDial: {
            actions:
              collectionId
                ? [{ name: "Season", icon: <AddIcon />, onClick: () => openBundleTypeSelector("Bundle::Catalog::Season") },
                  { name: "Trailer", icon: <AddIcon />, onClick: openTrailerSelector },
                  { name: "Preview", icon: <AddIcon />, onClick: openPreviewSelector },
                  { name: "Extra", icon: <AddIcon />, onClick: openExtraSelector }]
                : [{ name: "Series", icon: <AddIcon />, onClick: () => onNew({ type: "Bundle::Catalog::Series" }) },
                  { name: "Limited Series", icon: <AddIcon />, onClick: () => openBundleTypeSelector("Bundle::Catalog::LimitedSeries") }]
          },
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: Boolean(collectionId),
      },
      limited_series: {
        title: "Episodes",
        nextLink: "episodes",
        filters: null,
        contentLayoutProps: {
          speedDial: {
            actions: [
              { name: "Episode", icon: <AddIcon />, onClick: openEpisodeSelector },
              { name: "Trailer", icon: <AddIcon />, onClick: openTrailerSelector },
              { name: "Preview", icon: <AddIcon />, onClick: openPreviewSelector },
              { name: "Extra", icon: <AddIcon />, onClick: openExtraSelector },
            ]
          }
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: true,
      },
      seasons: {
        title: "Episodes",
        nextLink: "episodes",
        filters: null,
        contentLayoutProps: {
          speedDial: {
            actions: [
              { name: "Episode", icon: <AddIcon />, onClick: openEpisodeSelector },
              { name: "Trailer", icon: <AddIcon />, onClick: openTrailerSelector },
              { name: "Preview", icon: <AddIcon />, onClick: openPreviewSelector },
              { name: "Extra", icon: <AddIcon />, onClick: openExtraSelector },
            ]
          }
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: true,
      },
      episodes: {
        title: "Extras",
        nextLink: null,
        filters: null,
        contentLayoutProps: {
          speedDial: {
            actions: [
              { name: "Trailer", icon: <AddIcon />, onClick: openTrailerSelector },
              { name: "Preview", icon: <AddIcon />, onClick: openPreviewSelector },
              { name: "extra", icon: <AddIcon />, onClick: openExtraSelector },
            ]
          }
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: true,
      },
      movies: {
        title: collectionId ? "Extras" : "Movies",
        nextLink: collectionId ? null : "movies",
        filters: collectionId ? null : ["Bundle::Catalog::Movie"],
        contentLayoutProps: {
          fab: collectionId ? null : {
            onClick: () => openBundleSelector("Bundle::Catalog::Movie"),
          },
          speedDial: collectionId ? {
            actions: [
              { name: "Trailer", icon: <AddIcon />, onClick: openTrailerSelector },
              { name: "Preview", icon: <AddIcon />, onClick: openPreviewSelector },
              { name: "Extra", icon: <AddIcon />, onClick: openExtraSelector },
            ]
          } : null,
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: Boolean(collectionId),
      },
      events: {
        title: collectionId ? "Extras" : "Events",
        nextLink: collectionId ? null : "events",
        filters: collectionId ? null : ["Bundle::Catalog::Event"],
        contentLayoutProps: {
          fab: collectionId ? null : {
            onClick: () => openBundleSelector("Bundle::Catalog::Event"),
          },
          speedDial: collectionId ? {
            actions: [
              { name: "Trailer", icon: <AddIcon />, onClick: openTrailerSelector },
              { name: "Preview", icon: <AddIcon />, onClick: openPreviewSelector },
              { name: "Extra", icon: <AddIcon />, onClick: openExtraSelector },
            ]
          } : null,
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: Boolean(collectionId),
        isSingleType: true,
      },
      extras: {
        title: "Extras",
        nextLink: null,
        filters: ["Bundle::Media::Extra", "Bundle::Media::Preview","Bundle::Media::Trailer"],
        contentLayoutProps: {},
        gridActions: ["onEdit", "onDelete"],
        sortable: false,
      },
      links: {
        title: "Links",
        nextLink: null,
        filters: ["Bundle::Catalog::Link"],
        columns: columns,
        gridActions: ["onEdit", "onDelete"],
        sortable: false,
      },
    },
    collections: {
      manual: {
        title: collectionId ? "Custom" : "Custom",
        nextLink: collectionId ? null : "manual",
        filters: collectionId ? null : ["Bundle::Collection::Manual"],
        contentLayoutProps: {
          fab: { onClick: () => collectionId ? openBundleSelector(true, ["Bundle::Catalog::Series", "Bundle::Catalog::LimitedSeries", "Bundle::Catalog::Movie", "Bundle::Catalog::Event"]) : onNew({ type: "Bundle::Collection::Manual" }) },
        },
        gridActions: ["onEdit", "onDelete"],
        sortable: collectionId ? true : false,
      },
      genres: {
        title: "Genres",
        nextLink: null,
        filters: ["Bundle::Collection::Genre"],
        contentLayoutProps: {},
        gridActions: ["onEdit", "onDelete"],
        sortable: false,
      },
      keywords: {
        title: "Keywords",
        nextLink: null,
        filters: ["Bundle::Collection::Keywords"],
        contentLayoutProps: {},
        gridActions: ["onEdit"],
        sortable: false,
      },
      categories: {
        title: "Categories",
        nextLink: null,
        filters: ["Bundle::Collection::Categories"],
        contentLayoutProps: {},
        gridActions: ["onEdit"],
        sortable: false,
      },
      actors: {
        title: "Actors",
        nextLink: null,
        filters: ["Bundle::Collection::Actors"],
        contentLayoutProps: {},
        gridActions: ["onEdit"],
        sortable: false,
      },
      directors: {
        title: "Directors",
        nextLink: null,
        filters: ["Bundle::Collection::Directors"],
        contentLayoutProps: {},
        gridActions: ["onEdit"],
        sortable: false,
      },
    },
    sections: {
      title: "Sections",
      nextLink: collectionId ? null : "sections",
      filters: collectionId ? null : ["Bundle::Layout::Section", "Bundle::Layout::Saved", "Bundle::Layout::Guide", "Bundle::Layout::Symlink", "Bundle::Layout::Search", "Bundle::Layout::Settings", "Bundle::Layout::StandardMenu"],
      contentLayoutProps: {
        title: "Sections",
        fab: collectionId ? {
          onClick: () => openBundleSelector(true, ["Bundle::Collection::Manual", "Bundle::Collection::Categories", "Bundle::Collection::Genre", "Bundle::Collection::Actors", "Bundle::Collection::Actors", "Bundle::Collection::Directors", "Bundle::Collection::Keywords", "Bundle::Playlist::Manual", "Bundle::Playlist::Search", "Bundle::Playlist::Feed", "Bundle::Catalog::Series", "Bundle::Catalog::LimitedSeries", "Bundle::Catalog::Movie", "Bundle::Catalog::Event"]),
        } : null,
        secondaryFab: previewHost ? {
          component: "a",
          href: `https://${previewHost}/?token=${token || ''}`,
          target: "_blank",
        } : null,
        speedDial: collectionId ? null : {
          actions: [
            {
              name: "Collection",
              icon: <AddIcon />,
              onClick: () => openBundleSelector("Bundle::Layout::Symlink", ["Bundle::Collection::Manual", "Bundle::Collection::Categories", "Bundle::Collection::Genre", "Bundle::Collection::Actors", "Bundle::Collection::Actors", "Bundle::Collection::Directors", "Bundle::Collection::Keywords"])
            },
            {
              name: "Playlist",
              icon: <AddIcon />,
              onClick: () => openBundleSelector("Bundle::Layout::Symlink", ["Bundle::Playlist::Manual", "Bundle::Playlist::Search", "Bundle::Playlist::Feed"])
            },
            {
              name: "Section",
              icon: <AddIcon />,
              onClick: () => onNew({ type: "Bundle::Layout::Section" })
            },
          ]
        },
      },
      gridActions: ["onEdit", "onDelete"],
      sortable: true,
      isSingleType: true,
    },
    channels: {
      title: "Guide",
      nextLink: collectionId ? null : "channels",
      filters: collectionId ? null : ["Bundle::LiveChannel"],
      contentLayoutProps: {
        title: "Guide",
        fab: collectionId ? null : { onClick: () => onNew({ type: "Bundle::LiveChannel" }) },
      },
      gridActions: ["onEdit", "onDelete"],
      sortable: true,
      isSingleType: true,
    },
    menu: {
      title: "Menu",
      nextLink: "menu",
      filters: collectionId ? null : ["Bundle::Layout::MenuLayout"],
      contentLayoutProps: {
        speedDial: {
          actions: [{ name: "Link", icon: <AddIcon />, onClick: () => onNew({ type: "Bundle::Catalog::Link" }) },
          { name: "Item", icon: <AddIcon />, onClick: () => onNew({type: "Bundle::StandardMenu::Item"}) },
          { name: "Section", icon: <AddIcon />, onClick: () => onNew({type: "Bundle::StandardMenu::SubMenu"}) }]
        },
      },
      gridActions: ["onEdit", "onDelete"],
      sortable: true,
      isSingleType: true,
    }
  };
  const category_data = bundle_map[category];
  let bundle_data;
  if (category_data.isSingleType) {
    bundle_data = category_data;
  } else {
    bundle_data = category_data[type];
  }

  const filters = bundle_data.filters;
  const gridActions = bundle_data.gridActions;
  const columnDefs = bundle_data.columns || columns;

  let breadcrumbs = [];

  const sectionPath =   `/${appProps.workspaceId}/${appProps.appId}/content/sections`;
  if(category === "channels"){
    breadcrumbs.push({ name: "Sections", link: `${sectionPath}/sections` });
  }

  if (data?.bundle?.ancestors) {

    if (category === "catalog") {
      const seriesBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Catalog::Series");
      if (seriesBundle) {
        breadcrumbs.push({ name: "Series", link: `${baseCrumbPath}/series` });
        const seriesTitle = seriesBundle.menus[0]?.title || seriesBundle.menus[0]?.item?.title;
        const seasonBundle = data.bundle.ancestors.find(a => a.type === "Bundle::Catalog::Season");
        breadcrumbs.push({ name: seriesBundle.menus[0]?.title || seriesBundle.menus[0]?.item?.title, link: seasonBundle ? `${baseCrumbPath}/series/${seriesBundle.id}` : null });
        if (seasonBundle) {
          const seasonTitle = seasonBundle.menus[0]?.title || seriesBundle.menus[0]?.item?.title;
          const episodeBundle = data.bundle.ancestors.find(a => a.type === "Bundle::Catalog::Episode");
          breadcrumbs.push({ name: seasonTitle, link: episodeBundle ? `${baseCrumbPath}/seasons/${seasonBundle.id}` : null });
          if (episodeBundle) {
            const episodeTitle = episodeBundle.menus[0]?.title || episodeBundle.menus[0]?.item?.title;
            breadcrumbs.push({ name: episodeTitle })
          }
        }
      }

      const limitedSeriesBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Catalog::LimitedSeries");
      if (limitedSeriesBundle) {
        breadcrumbs.push({ name: "Series", link: `${baseCrumbPath}/series` });
        const limitedSeriesTitle = limitedSeriesBundle.menus[0]?.title || limitedSeriesBundle.menus[0]?.item?.title;
        const limitedEpisodeBundle = data.bundle.ancestors.find(a => a.type === "Bundle::Catalog::Episode");
        breadcrumbs.push({ name: limitedSeriesTitle, link: limitedEpisodeBundle ? `${baseCrumbPath}/seasons/${limitedEpisodeBundle.id}` : null });
        if (limitedEpisodeBundle) {
          const limitedEpisodeTitle = limitedEpisodeBundle.menus[0]?.title || limitedEpisodeBundle.menus[0]?.item?.title;
          breadcrumbs.push({ name: limitedEpisodeTitle })
        }
      }

      const movieBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Catalog::Movie");
      if (movieBundle) {
        breadcrumbs.push({ name: "Movies", link: `${baseCrumbPath}/movies` });
        breadcrumbs.push({
          name: data.bundle.menus[0]?.title || data.bundle.menus[0].item?.title,
        });
      }

      const eventBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Catalog::Event");
      if (eventBundle) {
        breadcrumbs.push({ name: "Events", link: baseCrumbPath });
        breadcrumbs.push({
          name: data.bundle.menus[0]?.title || data.bundle.menus[0].item?.title,
        });
      }
    }

    if (category === "playlists") {
      if (type === "feeds") {
        const feedBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Playlist::Feed");
        if (feedBundle) {
          breadcrumbs.push({ name: "Feeds", link: `${baseCrumbPath}/feeds` });
          breadcrumbs.push({ name: "Media" });
        }
      } else if (type === "manual") {
        const playlistBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Playlist::Manual");
        if (playlistBundle) {
          breadcrumbs.push({ name: "Manual Playlists", link: `${baseCrumbPath}/manual` });
          breadcrumbs.push({ name: "Media" });
        }
      } else if (type === "search") {
        const searchBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Playlist::Search");
        if (searchBundle) {
          breadcrumbs.push({ name: "Search Playlists", link: `${baseCrumbPath}/search` });
          breadcrumbs.push({ name: "Media" });
        }
      }
    }

    if (category === "sections") {
      const sectionBundle = data.bundle.ancestors.find((b) => b.type === "Bundle::Layout::Section");
      if (sectionBundle) {
        breadcrumbs.push({ name: "Sections", link: baseCrumbPath });
        breadcrumbs.push({
          name: data.bundle.menus[0]?.title || data.bundle.menus[0].item?.title,
        });
      }
    }

    if (category === "collections") {
      if (type === "manual") {
        const manualCollection = data.bundle.ancestors.find((b) => b.type === "Bundle::Collection::Manual");
        if (manualCollection) {
          breadcrumbs.push({ name: "Custom", link: `${baseCrumbPath}/manual` });
          breadcrumbs.push({
            name: data.bundle.menus[0]?.title || data.bundle.menus[0].item?.title,
          });
        }
      }
    }

    if (category === "menu") {
      breadcrumbs.push({ name: "Sections", link: sectionPath });
      data.bundle.ancestors.forEach((b, i) => {
        breadcrumbs.push({
          name: b.menus[0]?.title || b.menus[0].item?.title,
          link: data.bundle.ancestors.length === (i+1) ? null : `${baseCrumbPath}/${b.id}`
        });
      })
    }
  } else {
    if(type !== "all")
      breadcrumbs.push({ name: bundle_data.title });
    else
      breadcrumbs=null
  }

  const handleSelect = (params) => {
    let nextLink = bundle_data.nextLink;

    if(params.data.type === "Bundle::Layout::Guide"){
      const channelLink = `/${appProps.workspaceId}/${appProps.appId}/content/channels`;
      props.redirect.set(channelLink);
    } else if(params.data.type === "Bundle::Layout::StandardMenu" || params.data.type === "Bundle::StandardMenu::SubMenu") {
      const link = `/${appProps.workspaceId}/${appProps.appId}/content/menu/${params.data.id}`
      props.redirect.set(link);
    } else if (params.data.type === "Bundle::Catalog::Link"){
      onEdit(params.data);
      gridApi.deselectAll();
    }

    else if (["Media", "Playlist"].includes(params.data.type.split("::")[1]) || !Boolean(nextLink) || ["Bundle::Layout::Saved", "Bundle::Layout::Guide","Bundle::Layout::Symlink", "Bundle::Playlist::Feed", "Bundle::Layout::Search", "Bundle::Layout::Settings", "Bundle::StandardMenu::Item"].includes(params.data.type)) {
      if (["Collection", "Playlist"].includes(params.data.type.split("::")[1]) || params.data.type.includes("Bundle::Catalog") || params.data.type === "Bundle::Layout::Symlink") {
        props.redirect.set(`/${appProps.workspaceId}/${appProps.appId}/content/${getContentLinkPath(params.data)}`);
      } else {
        onEdit(params.data);
        gridApi.deselectAll();
      }
    } else {
      if (params.data.type === "Bundle::Catalog::LimitedSeries") {
        nextLink = "limited_series";
      }
      let fullLink = `/${appProps.workspaceId}/${appProps.appId}/content`;

      if (params.data.type === "Bundle::LiveChannel") {
        fullLink = `${fullLink}/${props.category}/${params.data.liveChannel.id}/schedules/${params.data.liveChannel.defaultSchedule?.id}/programs`;
      } else {
        fullLink = `${fullLink}${
          props.category && !Boolean(bundle_data.isSingleType) ? `/${props.category}` : ''
        }/${nextLink}/${params.data.id}`;
      }

      props.redirect.set(fullLink);
    }
  };

  const onNew = (obj) => {
    setMenuModalOpen(obj);
  };

  const openBundleTypeSelector = (bundleType) => {
    setBundleTypeSelectorOpen(bundleType);
  };

  let contentLayoutProps = {
    title: bundle_data.title,
    ...bundle_data.contentLayoutProps,
  };

  let formLayoutProps = {
    breadcrumbs: breadcrumbs,
  };

  function onLink(bundle, ev) {
    ev && ev.stopPropagation();
    if (bundle.type === "Bundle::Media::Unknown") {
      setLinkItem(bundle);
    } else {
      setUnlinkItem(bundle);
    }
  }

  function onCopy(text, result) {
    props.savedSnack.launch("Copied to clipboard");
  }

  function onEdit(bundle, ev) {
    ev && ev.stopPropagation();
    setMenuModalOpen(bundle);
  }

  function onDelete(bundle, ev) {
    ev.stopPropagation();
    setDeleteConfirmation(bundle);
  }

  let gridProps = {
    gridActions, filters, columnDefs, gridApi, setGridApi, onCopy, onLink,
    onEdit, onDelete, datagridProps: { onRowSelected: handleSelect },
    savedSnack: props.savedSnack, category, isCustom, isMultiLangOrRegion
  };

  function closeModal() {
    setLinkItem(null);
    setUnlinkItem(null);
  }

  function updateGridOnLinking(bundle) {
    gridApi.forEachNode(r => {
      if (r.data.id == bundle.id) {
        r.setData({...r.data, bundle})
      }
    })
  }

  function deleteBundleApi(bundle) {
    if (collectionId && !["series"].includes(type)) {
      deleteBundleCollection({
        variables: {
          ...appProps,
          collectionId: collectionId,
          id: bundle.id,
        }
      });
    } else {
      deleteBundle({
        variables: {
          ...appProps,
          id: bundle.id,
        },
      });
    }
    closeDeleteConfirmation();
  }

  return (
    <>
      <ContentLayout {...contentLayoutProps}>
        <FormLayout {...formLayoutProps}>
          {bundle_data.sortable
            ? <PlainList {...gridProps} />
            : <PaginatedList {...gridProps} />
          }

          <LinkMediaModal
            open={Boolean(linkItem)}
            onClose={closeModal}
            bundle={linkItem}
            updateGridOnLinking={updateGridOnLinking}
          />

          <Confirm
            open={unlinkItem}
            content={"Are you sure you want to unlink this item? Bundle linked to this item will be removed."}
            accept={unLink}
            handleClose={closeModal}
            acceptLabel={'Unlink'}
          />

          <ConfirmDialog
            open={Boolean(deleteConfirmation)}
            handleClose={closeDeleteConfirmation}
            accept={() => deleteBundleApi(deleteConfirmation)}
          />

          <MenuModal
            open={Boolean(menuModalOpen)}
            onClose={closeMenuModalOpen}
            bundle={menuModalOpen}
            savedSnack={props.savedSnack}
          />

          <BundleSelectorModal
            open={Boolean(selectedBundle)}
            onClose={saveAndCloseBundleSelector}
            filters={selectedBundleFilter}
            addingTo={(contentLayoutProps.fab || contentLayoutProps.speedDial) ? collectionId : null}
          />

          <BundleTypeSelectorModal
            open={Boolean(bundleTypeSelectorOpen)}
            onClose={closeBundleTypeSelector}
            onSelect={(val) => {
              if (val === "manual") {
                onNew({ type: bundleTypeSelectorOpen });
              } else {
                openBundleSelector(bundleTypeSelectorOpen, val);
              }
              closeBundleTypeSelector();
            }}
          />
        </FormLayout>
      </ContentLayout>
    </>
  );
}

export default withLocaleLang(withSavedSnack(withRedirect(List)));

const PlainList = (props) => {
  const { filters, columnDefs, datagridProps, onCopy, onLink, actions,
    gridActions, onEdit, onDelete, setGridApi, category, isMultiLangOrRegion } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const { collectionId } = useParams();
  const client = useApolloClient();

  const { data } = useQuery(GET_BUNDLES, {
    variables: { ...appProps, filters, collectionId, limit: 100 },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const onSort = (params) => {
    rearrangeBundleCollectionsApi(
      params.api.rowModel.rowsToDisplay.map((o) => o.data.id)
    );
  };

  const rearrangeBundleCollectionsApi = (ids) => {
    const existingBundles = client.readQuery({
      query: GET_BUNDLES,
      variables: { ...appProps, filters, collectionId, limit: 100 },
    });

    let newBundles = [];
    ids.forEach((f, i) => {
      newBundles.push({
        ...existingBundles.bundles.find((ef) => ef.id === f),
      });
    });

    client.writeQuery({
      query: GET_BUNDLES,
      variables: { ...appProps, filters, collectionId, limit: 100 },
      data: { bundles: newBundles },
    });

    if (collectionId) {
      rearrangeBundleCollections({
        variables: {
          ...appProps,
          collectionId,
          ids,
        },
      });
    } else {
      rearrangeBundles({
        variables: {
          ...appProps,
          ids,
        }
      });
    }
  };

  const [ rearrangeBundleCollections ] = useMutation(REARRANGE_BUNDLE_COLLECTIONS, {
    update(cache, { data: { rearrangeBundleCollections } }) {
      if (rearrangeBundleCollections.success) {
        props.savedSnack.launch();
      }
    },
    onError(data) {
      console.log(data);
    }
  });

  const [ rearrangeBundles ] = useMutation(REARRANGE_BUNDLES, {
    update(cache, { data: { rearrangeBundles } }) {
      if (rearrangeBundles.success) {
        props.savedSnack.launch();
      }
    },
    onError(data) {
      console.log(data);
    }
  });

  const draggableColumns = columnDefs.map((c) => (
    c.field === "title"
      ? { ...c, sortable: true, rowDrag: true }
      : { ...c }
  ));
  const singleLocaleLangColumns = draggableColumns.filter(c => !['Available', 'Language'].includes(c.headerName));

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  return (
    <DataGrid
      columnDefs={isMultiLangOrRegion ? draggableColumns: singleLocaleLangColumns}
      rowDragManaged={true}
      animateRows={true}
      rowData={
        data?.bundles
          ? data.bundles.map((af) => {
              const { image, title, duration, link, label } = getBundleData(af);
              const allActions = ["Bundle::Layout::Saved", "Bundle::Layout::Guide", "Bundle::Layout::Search", "Bundle::Layout::Settings", "Bundle::Layout::StandardMenu"].includes(af.type)
                ? gridActions.filter(f => f !== "onDelete")
                : gridActions;

              return {
                ...af,
                category,
                image: image,
                title: title,
                label: label,
                available: isMultiLangOrRegion ? getAvailableAndLanguage(af, app).available: null,
                language: isMultiLangOrRegion ? getAvailableAndLanguage(af, app).language: null,
                duration: duration
                  ? new Date(duration * 1000).toISOString().substr(11, 8)
                  : null,
                lastUpdated: af.updatedAt ? friendlyDates(af.updatedAt) : null,
                actions: _.pick({
                  launchLink: link,
                  copyText: link,
                  onCopy,
                  onLink,
                  onDelete,
                  onEdit,
                  ...actions,
                }, allActions),
              };
            })
          : []
      }
      onRowDragEnd={onSort}
      immutableData={true}
      getRowNodeId={(data) => data.id}
      onGridReady={onGridReady}
      rowSelection={'single'}
      {...datagridProps}
    />
  );
};

const PaginatedList = (props) => {
  const { columnDefs, datagridProps, gridApi, setGridApi, onCopy, onLink,
    onEdit, filters, actions, gridActions, onDelete, isCustom, isMultiLangOrRegion } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const app = useQuery(GET_CURRENT_APP).data.getCurrentApp;
  const client = useApolloClient();
  const { collectionId } = useParams();
  const singleLocaleLangColumns = columnDefs.filter(c => !['Available', 'Language'].includes(c.headerName));

  React.useEffect(() => {
    if (gridApi) {
      const source = datagridserver();
      gridApi.setServerSideDatasource(source);
    }
  }, [collectionId]);

  function datagridserver() {
    return {
      getRows: (params) => {
        const req = params.request;
        const filterModel = JSON.stringify(params.request.filterModel)
        client.query({
          query: GET_PAGINATED_BUNDLES,
          variables: {
            ...appProps,
            offset: req.startRow,
            limit: (req.endRow - req.startRow),
            filterModel,
            filters,
            collectionId,
          }
        })
        .then(res => {
          const data = res.data.paginatedBundles;
          const rowData = data?.bundles?.map((af) => {
            const { image, title, duration, link, label } = getBundleData(af);

            return {
              ...af,
              isCustom,
              image: image,
              title: title,
              duration: duration
                ? new Date(duration * 1000).toISOString().substr(11, 8)
                : null,
              lastUpdated: af.updatedAt ? friendlyDates(af.updatedAt) : null,
              label: label,
              available: isMultiLangOrRegion ? getAvailableAndLanguage(af, app).available: null,
              language: isMultiLangOrRegion ? getAvailableAndLanguage(af, app).language : null,
              actions: _.pick({
                launchLink: link,
                copyText: link,
                onCopy,
                onLink,
                onEdit,
                onDelete,
                ...actions,
              }, gridActions),
            };
          })
          params.successCallback(rowData, data.count)
        })
        .catch(err => {
          console.log(err)
        })
      }
    }
  }

  function onGridReady(params) {
    const source = datagridserver();
    params.api.setServerSideDatasource(source)
    setGridApi(params.api);
  };

  return (
    <DataGrid
      modules={[
        ClientSideRowModelModule,
        SetFilterModule,
        ServerSideRowModelModule,
        MenuModule,
        ColumnsToolPanelModule,
      ]}
      columnDefs={isMultiLangOrRegion ? columnDefs: singleLocaleLangColumns}
      pagination={true}
      paginationPageSize={10}
      cacheBlockSize={10}
      rowModelType={'serverSide'}
      onGridReady={onGridReady}
      serverSideStoreType={'partial'}
      paginationAutoPageSize={true}
      rowSelection={'single'}
      {...datagridProps}
    />
  );
};
