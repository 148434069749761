import React from "react";
import _ from "lodash";

import { IconButton } from "@mui/material";
import TranslateIcon from '@mui/icons-material/Translate';
import LanguageInput from "./Modal/LanguageInput";
import { withLocaleLang } from "components/containers/withLocaleLang";

/**
 * 
 * @param {Object} props
 * @param {Function} props.onSubmit
 * @param {Object} props.data
 * @param {Object} props.buttonProps
 * @param {Boolean} props.languageOnly
 * @returns 
 */
function LanguageText(props) {
  const { onSubmit, property, data, buttonProps, languageOnly, isMultiLangOrRegion , ...otherProps } = props;

  const [ openLangInput, setOpenLangInput ] = React.useState(false);

  const onTranslateClick = () => {
    setOpenLangInput(true);
  }

  if (!isMultiLangOrRegion) return null;

  return <>
    <IconButton
      color="primary"
      aria-label="directions"
      style={{
        padding: 10,
        margin: "16px 0px 32px 2px",
        border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "6px",
        ...buttonProps?.style
      }}
      onClick={ onTranslateClick }
      {..._.omit(buttonProps, 'style')}
      size="large">
      <TranslateIcon/>
    </IconButton>
    <LanguageInput 
      open={openLangInput}
      onClose={() => {setOpenLangInput(false)}}
      data = {data}
      property={property}
      onSubmit={onSubmit}
      languageOnly={languageOnly}
      key={openLangInput}
      {...otherProps}
    />
  </>;
}

export default withLocaleLang(LanguageText)
