import { useQuery } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";

import { GET_CURRENT_APP_PROPS, GET_BANNER_GROUPS } from "../../../api";
import Base from "./Base";
import DataGrid from "../../atoms/DataGrid";
import SubmitButton from "../../atoms/Button/Submit";

const useStyles = makeStyles((theme) => ({
  rowClass: {
    "&.ag-row-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "& > div": {
      border: "none !important",
    },
    cursor: "pointer",
  },
}));

const columns = [
  {
    field: "title",
    headerName: "Name",
    width: 800,
    cellRenderer: "titleCellRenderer",
  },
];

export default function ItemSelector(props) {
  const { id, setBannerGroupId, filter, ...otherProps } = props;
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data } = useQuery(GET_BANNER_GROUPS, {
    variables: { ...appProps },
  });

  const theme = useTheme();

  const [selected, setSelected] = React.useState(id);

  const handleSelect = (params) => {
    setSelected(params.api.getSelectedNodes()[0].data);
  };

  const saveAndClose = () => {
    setBannerGroupId(selected.id);
    props.onClose();
  };
  const classes = useStyles();

  return (
    <Base
      dimensions={{ width: theme.spacing(100), height: theme.spacing(75) }}
      title="Banner Groups"
      titleRight={() => (
        <SubmitButton disabled={!Boolean(selected)} onClick={saveAndClose}>
          Done
        </SubmitButton>
      )}
      {...otherProps}
    >
      <DataGrid
        rowData={
          data?.bannerGroups
            ? data.bannerGroups.map((bg) => ({
                ...bg,
                title: bg.name,
              }))
            : []
        }
        columnDefs={columns}
        onRowSelected={handleSelect}
        selectionModel={Boolean(selected) ? [selected] : []}
        rowSelection="single"
        rowClass={classes.rowClass}
      />
    </Base>
  );
}
