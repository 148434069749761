export default function isValid(platformDetails, appStores) {
    const { metadata, assetUrls } = platformDetails;

    if (!metadata || !assetUrls ) return false;

    if (!metadata.ga_tracking_id) return false;

    if (!assetUrls.app_icon) return false;
    if (!assetUrls.hero_carousel) return false;

    return true;
}