import { gql } from '@apollo/client';

export const INTEGRATION_FRAGMENT = gql`
  fragment IntegrationSchema on Integration {
    __typename
    id
    name
    type
    metadata
    isActive
    localeIds
    sso
    drm
  }
`;

export const GET_INTEGRATIONS = gql`
  query integrations($workspaceId: ID! $appId: ID!) {
    integrations(workspaceId: $workspaceId appId: $appId) {
      ...IntegrationSchema
    }
  }
  ${INTEGRATION_FRAGMENT}
`;

export const GET_INTEGRATION = gql`
  query integration($workspaceId: ID! $appId: ID! $id: ID!) {
    integration(workspaceId: $workspaceId appId: $appId id: $id) {
      ...IntegrationSchema
    }
  }
  ${INTEGRATION_FRAGMENT}
`;

export const CREATE_INTEGRATION = gql`
  mutation createIntegration(
    $workspaceId: ID!
    $appId: ID!
    $integrationFields: IntegrationInput!
  ) {
    createIntegration(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        integrationFields: $integrationFields
      }
    ) {
      integration {
        ...IntegrationSchema
      }
    }
  }
  ${INTEGRATION_FRAGMENT}
`;

export const UPDATE_INTEGRATION = gql`
  mutation updateIntegration(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
    $integrationFields: IntegrationInput!
  ) {
    updateIntegration(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
        integrationFields: $integrationFields
      }
    ) {
      integration {
        ...IntegrationSchema
      }
    }
  }
  ${INTEGRATION_FRAGMENT}
`;

export const DELETE_INTEGRATION = gql`
  mutation deleteIntegration(
    $workspaceId: ID!
    $appId: ID!
    $id: ID!
  ) {
    deleteIntegration(
      input: {
        workspaceId: $workspaceId
        appId: $appId
        id: $id
      }
    ) {
      integration {
        ...IntegrationSchema
      }
    }
  }
  ${INTEGRATION_FRAGMENT}
`;
