import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { GET_BADGE, GET_CURRENT_APP_PROPS, GET_SPONSOR } from "api";
import Form from "./Form";

export default function Edit(props) {
  const { id } = useParams();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { data ,loading} = useQuery(GET_BADGE, { variables: { ...appProps, id: id } });

  return !loading && <Form {...props} badge={data.badge} />;
}
