import { Switch, Route, useRouteMatch } from "react-router-dom";
import React from "react";
import Banners from "./Banners";
import Ads from "./Ads";
import Sponsors from "./Sponsors";

import { withSavedSnack } from "components/molecules/Alerts/Saved";

function Advertising(props) {
  const { path } = useRouteMatch();
  const { savedSnack, isMultiLangOrRegion } = props;

  const commonProps = { savedSnack, isMultiLangOrRegion };

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Ads {...commonProps} />
      </Route>
      <Route path={`${path}/banners`}>
        <Banners {...commonProps} />
      </Route>
      <Route path={`${path}/sponsors`}>
        <Sponsors {...commonProps} />
      </Route>
    </Switch>
  );
}

export default withSavedSnack(Advertising);
