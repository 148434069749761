import { useQuery, useMutation } from "@apollo/client";
import React from 'react';
import { useTheme } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { GET_CURRENT_APP_PROPS, UPDATE_BUNDLE } from "api";
import Base from "./Base";
import SubmitButton from 'components/atoms/Button/Submit';
import MediaCard from 'components/molecules/MediaCard';
import _ from 'lodash';
import BundleSelector from "./BundleSelector";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    margin: "5px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "350px",
    "& > span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "15px"
    },
    "& > button": {
      width: "150px",
      minWidth: "120px",
      maxHeight: "60px",
    }
  }
}))

export default function LinkMedia(props) {
  const theme = useTheme();
  const classes = useStyles();
  const { bundle, refetch = null, updateGridOnLinking, ...otherProps } = props;

  const [ selected, setSelected ] = React.useState(null);
  const [ defaultMenu, setDefaultMenu ] = React.useState({});
  const [openContentSelector, setOpenContentSelector] = React.useState(false);
  const [ filters, setFilters ] = React.useState([]);
  const [ series, setSeries ] = React.useState(null);
  const [ season, setSeason ] = React.useState(null);

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;

  const [ updateBundle, { loading } ] = useMutation(UPDATE_BUNDLE, {
    variables: {
      ...appProps,
      id: bundle?.id,
      bundleFields: {
        type: `Bundle::Catalog::${selected}`,
        collectionId: season?.id || series?.id || null,
      }
    },
    update(cache, { data: { updateBundle } }) {
      cache.modify({
        fields: {
          bundles(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateBundle.bundle.id === readField("id", ref)
                ? { ...ref, ...updateBundle.bundle }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      updateGridOnLinking && updateGridOnLinking(data?.updateBundle?.bundle);
      clearContent();
      setSelected('Movie');
      props.onClose();
    },
    onError(data) {
      console.log(data);
    }
  });

  React.useEffect(() => {
    setSelected('Movie');
  }, [props.open])

  const clearContent = () => {
    setSeason(null);
    setSeries(null);
  }

  const handleChange = (event) => {
    clearContent();
    setSelected(event.target.value);
  };

  const saveAndClose = () => {
    updateBundle();
  };

  const onButtonClick = (types) => {
    setFilters(types);
    setOpenContentSelector(true);
  };

  const onBundleSelect = (bundle) => {
    setFilters([]);

    if (bundle.type.includes("Series")) {
      setSeason(null);
      setSeries(bundle);
    } else if (bundle.type.includes("Season")) {
      setSeason(bundle);
    }
  };

  let disableButton = loading || !Boolean(selected);
  if (selected === "Episode") {
    disableButton = loading || !series || (series.type === "Bundle::Catalog::Series" && !season)
  }

  return (
    <Base
      dimensions={{ width: theme.spacing(100), height: theme.spacing(75) }}
      title="Select media type"
      titleRight={() => <SubmitButton disabled={disableButton} onClick={saveAndClose}>Done</SubmitButton>}
      {...otherProps}
    >
      <div style={{ paddingRight: theme.spacing(4) }}>
        {bundle && <MediaCard menu={bundle.menus[0]} noPointer />}
      </div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Media Type</FormLabel>
        <RadioGroup aria-label="category" name="category" value={selected} onChange={handleChange}>
          <FormControlLabel value="Movie" control={<Radio />} label="Movie" />
          <FormControlLabel value="Event" control={<Radio />} label="Event" />
          <FormControlLabel value="Episode" control={<Radio />} label="Episode" />
        </RadioGroup>
        {selected === "Episode" &&
          <div className={classes.buttonDiv}>
            <SubmitButton
              onClick={() => onButtonClick(["Bundle::Catalog::Series", "Bundle::Catalog::LimitedSeries"])}
            >Choose Series</SubmitButton>
            <span>{series ? series.menus[0]?.title : "Select series"}</span>
          </div>
        }
        {selected === "Episode" && series?.type === "Bundle::Catalog::Series" &&
          <div className={classes.buttonDiv}>
            <SubmitButton disabled={!series} onClick={() => onButtonClick(["Bundle::Catalog::Season"])}>Choose Season</SubmitButton>
            <span>{season ? season.menus[0]?.title : "Select season"}</span>
          </div>
        }
      </FormControl>

      {filters.length ?
        <BundleSelector
          open={openContentSelector}
          filters={filters}
          onClose={(sel) => {
            setOpenContentSelector(false);
            onBundleSelect(sel);
          }}
        />
        : null
      }
    </Base>
  );
}
