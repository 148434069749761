import { useQuery } from "@apollo/client";
import Divider from "@mui/material/Divider";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { useRouteMatch, useParams } from "react-router-dom";
import clsx from "clsx";

import { GET_CURRENT_APP_PROPS } from "api";
import CancelButton from "components/atoms/Button/Cancel";
import SubmitButton from "components/atoms/Button/Submit";
import Breadcrumbs from "components/molecules/Breadcrumbs";
import { withRedirect } from "components/containers/Redirect";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
  formContent: {
    flexGrow: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: 'center'
  },
  formSpacing: {
    padding: theme.spacing(2, 8),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    maxWidth: 1152,
    width: "100%",
  },
  maxWidth: {
    alignItems: "center",
  },
  formSpacingWithSimulator: {
    padding: theme.spacing(2),
  },
  submitContainer: {
    padding: theme.spacing(2, 8),
    borderTop: "1px solid rgba(0,0,0,0.2)"
  },
  submitContainerWithSimulator: {
    padding: theme.spacing(2),
  },
}));

function Form(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const classes = useStyles();
  const { path } = useRouteMatch();
  const { parentId } = useParams();
  const {
    children,
    submit,
    simulator,
    hasSubmit,
    cancel,
    noCancel,
    breadcrumbs,
    disableCancel=false,
    disableSave=false,
    isChanged=false,
    betterForm=false,
    isLoading
  } = props;

  const hasSimulator = Boolean(simulator);

  function cancelForm() {
    props.redirect.set(
      path
        .replace(`/new`, "")
        .replace(`/edit/:id`, "")
        .replace(`:parentId`, parentId)
        .replace(":appId", appProps.appId)
        .replace(":workspaceId", appProps.workspaceId)
    );
  }

  const showSaveCancelBar = !betterForm || isChanged

  return (
    <>
      {hasSubmit ? (
        <form className={classes.form} onSubmit={submit} noValidate>
          <div className={classes.formContent}>
            <div
              className={`${clsx(
                classes.formSpacing,
                hasSimulator && classes.formSpacingWithSimulator
              )} form-spacing`}
            >
              {breadcrumbs && !simulator && (
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              )}
              {children}
            </div>
          </div>

          { (showSaveCancelBar) &&
            <div
              className={`${clsx(
                classes.submitContainer,
                hasSimulator && classes.submitContainerWithSimulator
              )} submit-container`}
            >
              {!noCancel && (
                <CancelButton
                  style={{ marginRight: 8 }}
                  onClick={cancel ? cancel : cancelForm}
                  disabled={disableCancel}
                />
              )}
              <SubmitButton disabled={disableSave} isLoading={isLoading}>Save</SubmitButton>
            </div>
          }
        </form>
      ) : (
        <div className={classes.form}>
          <div className={classes.formContent}>
            <div
              className={`${clsx(
                classes.formSpacing,
                hasSimulator && classes.formSpacingWithSimulator,
                classes.maxWidth
              )} form-spacing-nosubmit`}
            >
              {breadcrumbs && !simulator && (
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              )}
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withRedirect(Form);
