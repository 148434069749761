import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function CheckBox(props) {
  const { label, checked, onChange } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(ev) => onChange(ev.target.checked)}
          name={label}
        />
      }
      label={label}
    />
  );
}
