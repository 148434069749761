import React from "react";
import Typography from "@mui/material/Typography";

export default function LinkText(props) {
  const { style, children, ...otherProps } = props;

  return (
    <Typography style={{ fontSize: 14, color: 'red', cursor: 'pointer', ...style }} {...otherProps}>{children}</Typography>
  );
}
