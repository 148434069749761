import React from "react";
import withStore from "./withStore";

import FormLayout from "components/molecules/Form/FormLayout";
import Input from "components/atoms/Input/BaseInput";
import { SwitchGroup } from "components/atoms/Input/Switch";
import PaperGroup from "components/molecules/Form/PaperGroup";
import LanguageText from "components/molecules/LanguageText";
import { getDefaultLocaleAndLang } from "helpers/langUtils";


function getDefaultValue(data) {
  if (!data) return {}
  if (typeof data === 'string') return {'en': data}
  return data
}

function Apple(props) {
  const { store, updateStoreApi, appProps, isMultiLangOrRegion, app, isUpdating } = props;

  const defaultLocaleLang = getDefaultLocaleAndLang(app)
  const metadata = store.metadata || {};
  const [ hasSubscriptions, setHasSubscriptions ] = React.useState(store.metadata ? metadata.has_subscriptions : false);
  const [ subText, setSubText ] = React.useState(getDefaultValue(metadata.subscription_terms_text));
  const [ externalMsg, setExternalMsg ] = React.useState(getDefaultValue(metadata.external_subscription_message));
  const [isFormChanged, setIsFormChanged] = React.useState(false);

  const externalSubscriptionMessage = externalMsg[defaultLocaleLang.lang]
  const subscriptionTermsText = subText[defaultLocaleLang.lang];

  const [ messageError, setMessageError ] = React.useState(null);

  React.useEffect(() => {
    setIsFormChanged(true);
  },[hasSubscriptions, subText, externalMsg]);

  React.useEffect(() => {
    setIsFormChanged(false);
  },[]);

  const submit = () => {
    if (!hasSubscriptions && !externalSubscriptionMessage) {
      setMessageError("Cannot be blank");
      return;
    }
    updateStoreApi({
      variables: {
        ...appProps,
        appStoreId: store.id,
        appStoreFields: {
          metadata: {
            has_subscriptions: hasSubscriptions,
            external_subscription_message: externalMsg,
            subscription_terms_text: subText,
          }
        },
      },
    });
    setIsFormChanged(false);
  }

  const onCancel = () =>{
     setHasSubscriptions(store.metadata ? metadata.has_subscriptions : false);
     setSubText(getDefaultValue(metadata.subscription_terms_text));
     setExternalMsg(getDefaultValue(metadata.external_subscription_message));
     setTimeout(() => setIsFormChanged(false), 50);
  }

  React.useEffect(() => {
    if (externalSubscriptionMessage && messageError) {
      setMessageError(null);
    }
  }, [messageError, externalSubscriptionMessage]);

  const onLanguagesUpdate = (key, value) => {
    if (key === "externalMsg") {
      setExternalMsg(value)
    } else {
      setSubText(value)
    }
  }

  const onTextChange = (key, value) => {
    if (key === "externalMsg") {
      setExternalMsg({[defaultLocaleLang.lang]: value})
    } else {
      setSubText({[defaultLocaleLang.lang]: value})
    }
  }

  const buttonProps = {style: {marginBottom: '8px'}}
  const languageText = (property, data, title) => (
  <LanguageText
    data={data} 
    onSubmit={(val) => onLanguagesUpdate(property, val)}
    languageOnly
    buttonProps={buttonProps}
    title={title}
    key={property}
  />)

  const isLoading = isUpdating;

  return (
    <FormLayout
      submit={submit}
      isChanged={isFormChanged}
      betterForm={true} 
      cancel={onCancel}
      isLoading={isLoading}
      disableSave={isLoading}
      disableCancel={isLoading}
    >
      <PaperGroup>
        <SwitchGroup
          label="Offer subscriptions"
          checked={hasSubscriptions}
          onChange={setHasSubscriptions}
        />
        <div style={{display: 'flex'}}>
          <Input
            label="External Subscription Message"
            value={externalSubscriptionMessage || ""}
            helperText={messageError}
            error={Boolean(messageError)}
            required={!hasSubscriptions}
            disabled={isMultiLangOrRegion}
            onChange={(val) => onTextChange("externalMsg", val)}
          />
          {languageText("externalMsg", externalMsg, "External Subscription Message")}
        </div>

        <div style={{display: 'flex'}}>
          <Input
            label="Subscription Terms Text"
            value={subscriptionTermsText || ""}
            disabled={isMultiLangOrRegion}
            onChange={(val) => onTextChange("subText", val)}
          />
          {languageText("subText", subText, "Subscription Terms Text")}
        </div>
      </PaperGroup>
    </FormLayout>
  );
}

export default withStore(Apple);
