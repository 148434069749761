import makeStyles from '@mui/styles/makeStyles';
import PreviewDevices from 'components/molecules/Modal/PreviewDevices';
import React from 'react';

const useStyles = (style) => makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        margin: '0 auto',
        position: 'relative',
        ...style.containerStyle
    },
    broghtBy: {
        color: 'white',
        display: 'inline-block',
        textAlign: 'center',
        width: '100%',
        padding: '10px',
        ...style.broughtByStyle
    },
    actionButtonDiv: {
        position: 'absolute',
        bottom: '50px',
        width: '100%',
        textAlign: 'center',
        ...style.actionButtonStyle
    },
    actionButton: {
        padding: '10px 15px',
        border: '1px solid white',
        borderRadius: '8px',
        color: 'white',
        textDecoration: 'none',
    }
}))

const config = {
    iPad: { 
        style: {backgroundSize: '220px auto, cover'},
    },
    iPadLandscape: { 
        style: {backgroundSize: '260px auto, cover'},
    },
    iPhone: { 
        style: {backgroundSize: '160px auto, cover'},
    },
    iPhoneLandscape: { 
        style: {backgroundSize: '260px auto, cover'},
    },
    ott: { 
        style: {backgroundSize: '210px auto, cover'},
    }
}

const actionStyle = {
    iPad: { 
        style: {bottom: '90px'},
    },
    iPadLandscape: { 
        style: {bottom: '50px'},
    },
    iPhone: { 
        style: {bottom: '80px'},
    },
    iPhoneLandscape: { 
        style: {bottom: '18px'},
    }
}

function Preview(props) {
    const {state, ...otherProps} = props;
    const { logoUrl, background, broughtBy, actionText, actionUrl, actionButton } = state;
    
    const [style, setStyle] = React.useState({});
    const [device, setDevice] = React.useState(null);
    
    const classes = useStyles(style)();

    const getBackground = () => {
        if (background.format === 'gradient') {
            return {
                backgroundImage: `url(${logoUrl}), linear-gradient(to bottom, ${background.primaryColor} 0%, ${background.secondaryColor} 100%)`,
            };
        } else if (background.format === 'image') {
            return {
                backgroundImage: background.assetUrl ? `url(${logoUrl}), url(${background.assetUrl})`: `url(${logoUrl})`,
                backgroundColor: background.assetUrl ? '': 'black'
            }
        } else {
            return {
                backgroundImage: `url(${logoUrl})`,
                backgroundColor: background.primaryColor
            };
        }
    }
    
    React.useEffect(() => {
        if (device) {
            const myStyle = {};
            myStyle.containerStyle = {
                ...config[device].style,
                ...getBackground()
            }
    
            if (device === "ott") {
                myStyle.broughtByStyle = {
                    textShadow: '0 2px 20px rgb(0 0 0 / 50%)',
                    background: 'linear-gradient(to bottom, rgba(0,0,0,0.25) 0%, transparent 100%)',
                    fontSize: '20px',
                    padding: '15px'
                }
            }

            if (actionButton) {
                myStyle.actionButtonStyle = actionStyle[device]?.style;
            }

            setStyle(myStyle);
        }
    }, [device, logoUrl, background, actionButton]);
    

    return (
        <PreviewDevices
            key={props.open}
            {...{setDevice}}
            {...otherProps}
        >
            <div className={classes.container} style={{...style}}>
                {broughtBy && <span className={classes.broghtBy}>
                    Brought to you by
                </span>}
                { device !== 'ott' && actionButton && (
                    <div className={classes.actionButtonDiv}>
                        <a className={classes.actionButton} target="_blank" href={actionUrl} rel="noreferrer">
                            {actionText}
                        </a>
                    </div>)
                }

            </div>
        </PreviewDevices>
    )
}

export default Preview
