import React from 'react'
import { Redirect, useLocation } from 'react-router-dom';

function StripeRedirect() {
    const location = useLocation();

    const params = new URLSearchParams(location.search)
    const state = params.get('state')
    const appPath = state && state.split('_').join('/');
    const url = appPath ? `/${appPath}/monetization/payment${location.search}` : '/';

    return (
        <Redirect to={url} />
    )
}

export default StripeRedirect
