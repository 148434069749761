import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Divider from "@mui/material/Divider";
import Select from "components/atoms/Input/Select";
import { SwitchGroup } from "components/atoms/Input/Switch";
import Label from "components/atoms/Text/Label";
import PricePicker from "components/atoms/Input/PricePicker";

const useStyles = makeStyles((theme) => ({
  list: {
    flex: 1,
    width: "100%",
    display: "flex",
  },
  listEditing: {
    height: 0,
    overflow: "hidden",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

function Subscriptions({ getter, setter, disabled }) {
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.divider} />
      <PriceGroup
        label="Monthly Subscription"
        getter={getter}
        setter={setter}
        durationOptions={[7, 30]}
        type={"oneMonth"}
        disabled={disabled}
      />
      <Divider className={classes.divider} />
      <PriceGroup
        label="Three Month Subscription"
        getter={getter}
        setter={setter}
        durationOptions={[7, 30]}
        type={"threeMonth"}
        disabled={disabled}
      />
      <Divider className={classes.divider} />
      <PriceGroup
        label="Six Month Subscription"
        getter={getter}
        setter={setter}
        durationOptions={[7, 30, 60]}
        type={"sixMonth"}
        disabled={disabled}
      />
      <Divider className={classes.divider} />
      <PriceGroup
        label="Annual Subscription"
        getter={getter}
        setter={setter}
        durationOptions={[7, 30, 60, 90]}
        type={"twelveMonth"}
        disabled={disabled}
      />
    </>
  );
}

export default Subscriptions;

function PriceGroup(props) {
  const {getter, setter, type, label, durationOptions, disabled} = props;

  const active = getter[`${type}Active`];
  const price = getter[`${type}Price`];
  const freeTrial = getter[`${type}FreeTrial`];
  const freeTrialDuration = getter[`${type}FreeTrialDuration`];

  const setActive = (active) => {
    setter(prev =>({ ...prev, [`${type}Active`]: active }));
  }
  const setPrice = (price) => {
    setter(prev =>({ ...prev, [`${type}Price`]: price }));
  }
  const setFreeTrial = (freeTrial) => {
    setter(prev =>({ ...prev, [`${type}FreeTrial`]: freeTrial }));
  }
  const setFreeTrialDuration = (freeTrialDuration) => {
    setter(prev =>({ ...prev, [`${type}FreeTrialDuration`]: freeTrialDuration }));
  }

  return (
    <>
      <Label>{label}</Label>
      <SwitchGroup label="Active" checked={active} onChange={setActive} disabled={disabled}/>
      {active && (
        <>
          <PricePicker value={price} onChange={setPrice} disabled={disabled}/>
          <SwitchGroup
            label="Offer Free Trial"
            checked={freeTrial}
            onChange={setFreeTrial}
            disabled={disabled}
          />
          {freeTrial && (
            <>
              <Select
                value={freeTrialDuration}
                onChange={setFreeTrialDuration}
                options={durationOptions.map((d) => ({
                  label: `${d === 7 ? d : d / 30} ${
                    d === 7 ? "days" : d > 30 ? "months" : "month"
                  }`,
                  value: d,
                }))}
                label="Duration"s
                required
                disabled={disabled}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
