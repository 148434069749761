export default function isValidate(platformDetails, appStores) {
    const { metadata, assetUrls, storeId } = platformDetails;
    const storeDetails = appStores.find(s => s.id === storeId);
    const {metadata: storeMetadata, assetUrls: storeAssetUrls} = storeDetails;

    if (!metadata || !storeMetadata || !assetUrls || !storeAssetUrls) return false;
    if (!storeMetadata.app_store_name) return false;
    if (!storeMetadata.description) return false;
    if (!storeMetadata.primary_category) return false;
    if (!storeMetadata.secondary_category) return false;
    if (!storeMetadata.keywords) return false;
    if (!storeMetadata.copyright) return false;
    if (!storeMetadata.content_rating) return false;

    if (!storeMetadata.dev_team_id) return false;
    if (!storeMetadata.itc_team_id) return false;
    if (!storeMetadata.developer_account_name) return false;

    if (!storeMetadata.store_id) return false;
    if (!storeMetadata.key_id) return false;
    if (!storeMetadata.issuer_id) return false;
    if (!storeAssetUrls.certificate) return false;

    if (!metadata.ga_tracking_id) return false;
    if (!assetUrls.top_shelf) return false;

    if (metadata.icon_type === 'layer') {
        if (!assetUrls.bottom_layer_icon) return false;
        if (!assetUrls.middle_layer_icon) return false;
        if (!assetUrls.top_layer_icon) return false;
    } else {
        if (!assetUrls.app_icon) return false;
    }
    
    return true;
}