import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import React from 'react';
import Tooltip from "components/atoms/Tooltip";

export default function Upload(props) {
  const inputRef = React.useRef(null);
  const [ press, setPress ] = React.useState(false);
  const { children, progress, onChange, error, inputProps, tooltip="", ...otherProps } = props;

  React.useEffect(() => {
    if (press) {
      setPress(false);
      inputRef.current.click();
    }
  }, [press]);

  const onChangeFile = (...args) => {
    onChange(...args);
    inputRef.current.value = null;
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <Button
          color={error ? "secondary" : "primary"}
          variant="outlined"
          onClick={() => setPress(true)}
          style={{ minWidth: 140, height: 40 }}
          {...otherProps}
        >
          {progress
            ? <CircularProgress variant="determinate" value={progress} size={24} />
            : children}
        </Button>
      </Tooltip>
      <Input
        inputProps={inputProps}
        inputRef={inputRef}
        type="file"
        style={{ position: 'absolute', height: 0, width: 0, opacity: 0 }}
        onChange={onChangeFile}
      />
    </>
  );
}
