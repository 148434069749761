import React from 'react';
import Confirm from './Confirm';

export default function Tier(props) {
  return (
    <Confirm
      {...props}
      content={`This user will be removed from ${props.workspace.name}. Are you sure you want to proceed?`}
    />
  );
}
