import React from "react";
import Form from "./Form";
import { useLocation } from "react-router-dom";
import { VALID_TEMPLATES } from "./constants";
import queryString from "query-string";

export default function New(props) {
  const { search } = useLocation();
  const params = queryString.parse(search);
  const template = VALID_TEMPLATES.includes(params.template) ? params.template : VALID_TEMPLATES[0];

  return (
    <Form {...props} integration={{ metadata: { template: template }}} />
  );
}
