import React from 'react';
import Alert from '@mui/material/Alert';

export default function Error(props) {
  const { severity, children, style, ...otherProps } = props;

  return (
    <Alert
      severity={severity || "error"}
      style={{ marginTop: 8, marginBottom: 8, ...style }}
      {...otherProps}
    >{children}</Alert>
  );
}
