import React from 'react';
import Confirm from './Confirm';

export default function General(props) {
  return (
    <Confirm
      {...props}
      content={props.content ?? "Are you sure you want to delete? This action cannot be undone."}
    />
  );
}
