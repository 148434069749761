export default function isValid(platformDetails, appStores) {
    const { metadata, assetUrls, storeId } = platformDetails;
    const storeDetails = appStores.find(s => s.id === storeId);
    const {metadata: storeMetadata, assetUrls: storeAssetUrls} = storeDetails;

    if (!metadata || !storeMetadata || !assetUrls || !storeAssetUrls) return false;

    if (!storeMetadata.app_store_name) return false;
    if (!storeMetadata.description) return false;
    if (!storeMetadata.short_description) return false;

    if (!storeMetadata.store_password) return false;
    if (!storeMetadata.key_alias) return false;
    if (!storeMetadata.key_password) return false;
    if (!storeMetadata.sha_key) return false;
    if (!storeMetadata.license_key) return false;
    if (!storeMetadata.firebase_api_key) return false;
    if (!storeMetadata.firebase_service_json) return false;

    if (!storeAssetUrls.key_store) return false;
    if (!storeAssetUrls.service_account_key_json) return false;

    if (!metadata.ga_tracking_id) return false;

    if (!assetUrls.app_icon) return false;
    if (!assetUrls.feature_graphic) return false;
    if (!assetUrls.notification_icon) return false;
    if (!assetUrls.covers || assetUrls.covers.length < 2) return false;

    return true;
}