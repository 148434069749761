import React from "react";
import Select from "../atoms/Input/Select";
import { SwitchGroup } from "components/atoms/Input/Switch";

export default function PreviewGroup(props) {
  const { getter, setter } = props;

  const [previewType, setPreviewType] = React.useState(
    getter.previewType || "image"
  );
  const [previewAudio, setPreviewAudio] = React.useState(
    getter.previewAudio || false
  );

  let options = [
    { label: "Play video of selected item", value: "video" },
    { label: "Show image of selected item", value: "image" },
  ];

  React.useEffect(() => {
    setter({ ...getter, previewType, previewAudio });
  }, [previewType, previewAudio]);

  return (
    <>
      <Select
        value={previewType}
        onChange={setPreviewType}
        options={options}
        label="Preview Type"
        required
      />
      {previewType === "video" && (
        <SwitchGroup
          label={"Preview Audio*"}
          checked={previewAudio}
          onChange={setPreviewAudio}
        />
      )}
    </>
  );
}
