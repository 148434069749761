import React from "react";
import {
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from '@mui/material';

import Input from "components/atoms/Input/BaseInput";
import PaperGroup from "components/molecules/Form/PaperGroup";

export default function AdBreakVmap(props) {
  const { updateState, currentFormKeys, currentStateValues, adBreak, setAdBreak, adRequestLive, setAdRequestLive } = props;

  return (
    currentFormKeys.schedule_ad_break ?
    <PaperGroup>
      <Grid container >
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Schedule ad breaks without VMAP
          </Typography>
          <Typography variant="body2" gutterBottom>
            When a video starts playing:
          </Typography>
        </Grid>

        <RadioGroup value={adBreak} onChange={(e) => setAdBreak(e.target.value)}>
          <Grid container>
            {
              currentFormKeys.schedule_ad_break?.break_frequency &&
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  value="ad_break_frequency"
                  control={<Radio/>}
                  label="Make an ad call every" />
                <Input
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 1
                    },
                    endAdornment: <InputAdornment position="end">Minutes</InputAdornment>,
                    style: { width: '250px' }
                  }}
                  onChange={(val) => updateState('adBreakFrequency', val)}
                  value={currentStateValues?.adBreakFrequency}
                  disabled={adBreak == "cupoints"}
                  fullWidth={false}
                />
              </Grid>
            }
            {
              currentFormKeys.schedule_ad_break?.cuepoints &&
              <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  value="cupoints"
                  control={<Radio />}
                  label="Make ad calls at cue points Key for cue points in source feeds" />
                <Input
                  InputProps={{
                    style: { width: '250px' }
                  }}
                  onChange={(val) => updateState('cuepointsKeyValue', val)}
                  value={currentStateValues?.cuepointsKeyValue || ""}
                  disabled={adBreak == "ad_break_frequency"}
                  helperText={"e.g: cuepoints, jwplayer:cuepoints etc."}
                  fullWidth={false}
                />
              </Grid>
            }
            {
              currentFormKeys.schedule_ad_break?.limit_dec &&
              <Grid xs={11} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormControlLabel
                  control={<Checkbox checked={adRequestLive} onChange={() => setAdRequestLive(!adRequestLive)} />}
                  label="Limit the value of the Description macro to the first "
                />
                <Input
                  type="number"
                  value={currentStateValues?.descriptionMacroLimit}
                  onChange={(val) => updateState('descriptionMacroLimit', val)}
                  InputProps={{
                    inputProps: {
                      min: 0
                    },
                    endAdornment: <InputAdornment position="end">Characters</InputAdornment>,
                    style: { width: '200px' }
                  }}
                  disabled={!adRequestLive}
                />
              </Grid>
            }
          </Grid>
        </RadioGroup>
      </Grid>
    </PaperGroup> : null
  )
}
