import React, { useEffect } from "react";
import {useParams } from "react-router-dom";
import jwt from "jsonwebtoken";
import BaseIndex from "../BaseIndex";
import { useState } from "react";

export default function Catalog(props) {
  const { appId } = useParams();
  const [jwttoken, setJwttoken] = useState(null);

  const tokenGenerator = () => {
    let expirationTime = Math.floor(Date.now() / 1000) + (60 * 60);
    let token = jwt.sign({
      app_id: appId,
      ts: expirationTime
    }, process.env.REACT_APP_JWT_KEY, { expiresIn: 60 * 60 });
    return token;
  }

  useEffect(()=>{
    let token = tokenGenerator();
    setJwttoken(token);
    let interval = setInterval(() => {
      let token = tokenGenerator();
      setJwttoken(token);
    }, (60 * 1000));
    return () => {clearInterval(interval)};
  },[])

  return (
    <BaseIndex {...props} category="sections" token={jwttoken} />
  );
}
