import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";
import { useHistory } from "react-router-dom";
import urlJoin from "url-join";
import { useRouteMatch, useLocation } from "react-router-dom";
import _ from "underscore";

import { GET_CURRENT_APP_PROPS, GET_DESIGNS, UPDATE_DESIGNS } from "api";
import ContentLayout from "screens/Layout/ContentLayout";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import ConfirmDialog from "components/molecules/Dialog/Confirm/General";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LANGUAGE_MAP } from "helpers/langUtils";
import { withLocaleLang } from "components/containers/withLocaleLang";

const columns = [
  {
    field: "title",
    headerName: "Name",
    width: 500,
    cellRenderer: "titleCellRenderer",
    rowDrag: true,
    sortable: true,
    filter: true,

  },
  {
    field: "actions",
    headerName: "Actions",
    cellRenderer: "actionCellRenderer",
    minWidth:300,
    flex: 1,
  },
];

function List(props) {
  const { redirect, defaultLocale={}, setDefaultLocale, isMultiLangOrRegion } = props;
  const client = useApolloClient();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const [confirmationText, setConfirmationText] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  const { data } = useQuery(GET_DESIGNS, { variables: appProps });
  const dmap = {};
  data?.designs?.forEach(d => {
    if (!dmap[d.locale.id]) {
      dmap[d.locale.id] = { ...d.locale, languages: [] };
    }
    dmap[d.locale.id].languages.push(d.language);
  });
  const [ region, setRegion ] = React.useState(defaultLocale.region || Object.keys(dmap)[0]);
  const [ language, setLanguage ] = React.useState(defaultLocale.language || dmap[region].languages[0]);
  const regions = Object.keys(dmap).map(l => ({ label: dmap[l].name, value: dmap[l].id }));
  const languages = dmap[region].languages;
  const design = data?.designs?.find(d => d.locale.id === region && d.language === language);

  React.useEffect(() => {
    if (region) {
      if (!dmap[region].languages.includes(language)) {
        setLanguage(dmap[region].languages[0]);
      }
    }
    setDefaultLocale({
      region, language
    })
  }, [region, language]);

  const toggleActive = (screen, active, event) => {
    event && event.stopPropagation();
    updateDesigns({
      variables: {
        ...appProps,
        designFields: [{
          id: design?.id,
          onboardingScreensAttributes: [{ id: screen.id, isActive: active }]
        }]
      },
      update(cache, { data: { updateDesigns } }) {
        props.savedSnack.launch();
      }
    });
  };

  const onSort = (params) => {
    rearrangeScreensApi(
      params.api.rowModel.rowsToDisplay.map((o) => o.data.id)
    );
  };

  const rearrangeScreensApi = (ids) => {
    const existingDesigns = client.readQuery({
      query: GET_DESIGNS,
      variables: appProps,
    });

    let newDesigns = [];
    let newScreens = [];
    existingDesigns.designs.forEach((d) => {
      if (d.id === design?.id) {
        ids.forEach((f, i) => {
          newScreens.push({
            ...d.onboardingScreens.find((ef) => ef.id === f),
            position: i,
          });
        });
        newDesigns.push({ ...d, onboardingScreens: newScreens });
      } else {
        newDesigns.push(d);
      }
    });

    client.writeQuery({
      query: GET_DESIGNS,
      variables: appProps,
      data: { designs: newDesigns },
    });

    updateDesigns({
      variables: {
        ...appProps,
        designFields: [{
          id: design?.id,
          onboardingScreensAttributes: newScreens.map(s => ({ id: s.id, sortOrder: s.position }))
        }]
      },
    });

    props.savedSnack.launch();
  };

  const destroyScreenApi = (screenId) => {
    closeDeleteConfirmation();
    updateDesigns({
      variables: {
        ...appProps,
        designFields: [{
          id: design?.id,
          onboardingScreensAttributes: [{ id: screenId, _destroy: true }]
        }]
      },
      update(cache, { data: { updateDesigns} }) {
        props.savedSnack && props.savedSnack.launch("Deleted");
      },
    });
  };

  const onEdit = (screen) => {
    history.push(urlJoin(pathname, `${design?.id}/edit/${screen.id}`));
  };

  const onDelete = (screen) => {
    setDeleteConfirmation(screen);
  };

  const [ updateDesigns ] = useMutation(UPDATE_DESIGNS, {
    onError(data) {
      console.log(data);
      if (data.message.includes("Designs onboarding screens is active ")) {
        props.savedSnack.launch("Limit reached");
      }
    },
  });

  return (
    <ContentLayout
      title="Onboarding"
      fab={{
        onClick: () => history.push(urlJoin(path.replace(":workspaceId", appProps.workspaceId).replace(":appId", appProps.appId), `${design?.id}`, "new")) }}
    >
      <FormLayout>
        {isMultiLangOrRegion ? (
          <div style={{ width: "100%", paddingLeft: "16px", paddingBottom: 8 }}>
            <Select
              value={region}
              onChange={(ev) => setRegion(ev.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mr: 2 , height:45, mt:0.5}}
            >
              {regions.map((l, i) => (
                <MenuItem key={`${l.id}-${i}`} value={l.value}>{l.label}</MenuItem>
              ))}
            </Select>

            <Select
              value={language}
              onChange={(ev) => setLanguage(ev.target.value)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{ mr: 2 ,height:45, mt:0.5}}
            >
              {languages.map((l, i) => (
                <MenuItem key={`${l}-${i}`} value={l}>{LANGUAGE_MAP[l]}</MenuItem>
              ))}
            </Select>
          </div>
        ) : null}

        <DataGrid
          rowDragManaged={true}
          animateRows={true}
          columnDefs={columns}
          accentedSort={true}
          rowData={
            design?.onboardingScreens
              ? _.sortBy(design.onboardingScreens, (f) => f.position).map((s) => ({
                  ...s,
                  name: s.name || "Untitled onboarding screen",
                  actions: {
                    onEdit,
                    onDelete,
                    toggle: {
                      getter: s.isActive,
                      setter: (value, event) => toggleActive(s, value, event),
                    },
                  }
                }))
              : []
          }
          onRowDragEnd={onSort}
          immutableData={true}
          getRowNodeId={(data) => data.id}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => destroyScreenApi(deleteConfirmation.id)}
          content="Are you sure you want to delete this screen?"
        />
      </FormLayout>
    </ContentLayout>
  );
}

export default withLocaleLang(List);
