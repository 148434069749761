import { useApolloClient } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import UploadButton from "../atoms/Button/Upload";
import HelpText from "../atoms/Text/Help";

import { upload } from "../../api";
import { removeExtFromName } from "helpers/fileUtils";

export default function UploadGroup(props) {
  const client = useApolloClient();
  const [progress, setProgress] = React.useState(null);
  const {
    label,
    required,
    requirements,
    onUpload,
    validations,
    error,
    fileRender,
    horizontal,
    setFileMetadata = null
  } = props;

  const fullLabel = `${label}${required ? "*" : ""}`;

  function onProgress(val) {
    if (val === 100) {
      setProgress(100);
      setTimeout(() => {
        setProgress(null);
      }, 200);
    } else {
      if (val) {
        setProgress(val);
      }
    }
  }

  async function onChange(ev) {
    let file = ev.target.files[0];

    let isValid = true;
    if (validations) {
      isValid = await validations(file);
    }

    if (isValid) {
      const meta = { name: removeExtFromName(file?.name) };
      setFileMetadata && setFileMetadata(meta);
      upload(client, file, onUpload, onProgress);
    }
  }

  return (
    <div style={{ display: "flex", marginTop: 16, marginBottom: 16, flexDirection: horizontal ? "row" : "column", maxWidth: horizontal ? null : 320 }}>
      <div style={{ width: '100%', maxWidth: 320, minWidth: 260 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <Typography style={{ marginRight: 8, flexGrow: 1 }}>
            {fullLabel}
          </Typography>
          <UploadButton {...props} progress={progress} onChange={onChange} />
        </Grid>
        <Typography
          color="secondary"
          style={{ fontSize: 12, textAlign: "right" }}
        >
          {error}
        </Typography>
        <div style={{ height: 12, width: "100%" }}>&nbsp;</div>
        {requirements && (
          <HelpText>
            Requirements:
            <br />
            {requirements.map((req) => (
              <div key={`Requirement-${req}`}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- {req}
                <br />
              </div>
            ))}
          </HelpText>
        )}
      </div>
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        {fileRender && fileRender()}
      </div>
    </div>
  );
}

function onError(e) {
  const target = e.target;
  if (!target) return null;
  target.style.display = 'none';
  if (target?.parentElement?.querySelector('div')) {
    target.parentElement.querySelector('div').style.display = 'none'
  }
}

function onLoad(e) {
  const target = e.target;
  if (!target) return null;
  target.style.display = 'block';
  if (target?.parentElement?.querySelector('div')) {
    target.parentElement.querySelector('div').style.display = 'block'
  }
}

export function ImagePreview({ url, alt, urlSetter, blobSetter, onDelete, horizontal, previewMaxHeight, type, previewProps }) {
  return (
    <>
      <img onError={onError} onLoad={onLoad} 
        style={{ maxWidth: 320, marginTop: horizontal ? 0 : 8, marginLeft: horizontal ? 16 : 0, maxHeight: previewMaxHeight }} alt={alt || "File"} 
        src={url} 
        {...previewProps}
      />
      <Box position="relative">
        <Box position="absolute" right={0} bottom={0}>
          <DeleteButton {...{ onDelete, urlSetter, blobSetter, horizontal }} />
        </Box>
      </Box>
    </>
  );
}

const DeleteButton = ({ urlSetter, blobSetter, onDelete, horizontal }) => {
  return (
    <IconButton
      color="secondary"
      onClick={() => {
        onDelete && onDelete();
        urlSetter && urlSetter(null);
        blobSetter && blobSetter("detach");
      }}
      size="large">
      <DeleteIcon />
    </IconButton>
  );
}

export function FilePreview({ horizontal, type, url, onDelete }) {
  return (
    <Box bgcolor="text.disabled" width={1} display="flex" alignItems="center" style={{ marginTop: horizontal ? 0 : 8, marginLeft: horizontal ? 16 : 0, paddingLeft: 16 }}>
      <Box flexGrow={1} fontSize={18}>
        <Link href={url} target="_blank" underline="always">{type === "video" ? "Play" : "File"}</Link>
      </Box>
      <DeleteButton {...{ onDelete }} />
    </Box>
  );
}

export function VideoPreview({ url, alt, urlSetter, blobSetter, onDelete, horizontal, previewMaxHeight, type, videopreviewProps }) {
  return (
    <>
      <video 
        controlsList="nodownload nofullscreen noremoteplayback" 
        autoPlay 
        loop 
        style={{ maxWidth: 320, marginTop: horizontal ? 0 : 8, marginLeft: horizontal ? 16 : 0, maxHeight: previewMaxHeight }} 
        alt={alt || "File"} 
        src={url} 
        onError={onError}
        onLoadStart={onLoad}
        {...videopreviewProps}
      />
      <Box position="relative">
        <Box position="absolute" right={0} bottom={0}>
          <DeleteButton {...{ onDelete, urlSetter, blobSetter, horizontal }} />
        </Box>
      </Box>
    </>
  );
}
