import React from "react";
import _ from "underscore";
import Link from "@mui/material/Link";

import FormLayout from "components/molecules/Form/FormLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Input from "components/atoms/Input/BaseInput";
import Select from "components/atoms/Input/Select";
import GaTrackingIdInput from "components/atoms/Input/GaTrackingId";
import BundleIdInput from "components/atoms/Input/BundleId";
import GroupHeader from "components/atoms/Text/GroupHeader";
import Tooltip from "components/atoms/Tooltip";
import { SwitchGroup } from "components/atoms/Input/Switch";
import { ImageUploadGroup, Spacer } from "./UploadGroup";
import { APPLE_ASSET_MAP } from "./constants";
import withStore from "./withStore";
import LanguageText from "components/molecules/LanguageText";
import { getDefaultLocaleAndLang, makeDefaultLangObject } from "helpers/langUtils";
import { PlatformApiKey } from "./List";

const categories = [
  { label: "Book", value: "book" },
  { label: "Business", value: "business" },
  { label: "Education", value: "education" },
  { label: "Entertainment", value: "entertainment" },
  { label: "Finance", value: "finance" },
  { label: "Food & Drink", value: "food_and_drink" },
  { label: "Games", value: "games" },
  { label: "Health & Fitness", value: "healthcare_and_fitness" },
  { label: "Lifestyle", value: "lifestyle" },
  { label: "Magazines & Newspapers", value: "magazines" },
  { label: "Medical", value: "medical" },
  { label: "Music", value: "music" },
  { label: "Navigation", value: "navigation" },
  { label: "News", value: "news" },
  { label: "Photo & Video", value: "photography" },
  { label: "Productivity", value: "productivity" },
  { label: "Reference", value: "reference" },
  { label: "Shopping", value: "shopping" },
  { label: "Social Networking", value: "social_networking" },
  { label: "Sports", value: "sports" },
  { label: "Stickers", value: "stickers" },
  { label: "Travel", value: "travel" },
  { label: "Utilities", value: "utilities" },
  { label: "Weather", value: "weather" },
];

const Platform = (props) => {
  const { store, updateStoreApi, appProps, app, isMultiLangOrRegion } = props;

  const iosPlatform = store?.platforms?.find((p) => p.type === "Platform::Ios");
  const tvosPlatform = store?.platforms?.find(
    (p) => p.type === "Platform::Tvos"
  );
  const { lang } = getDefaultLocaleAndLang(app);

  const listUrl = `/${appProps.workspaceId}/${appProps.appId}/design/platforms`;
  const storeMetadata = store.metadata || {};
  const iosMetadata = iosPlatform.metadata || {};
  const tvosMetadata = tvosPlatform.metadata || {};

  const [accessGranted, setAccessGranted] = React.useState(
    storeMetadata.access_granted || false
  );
  const [devTeamId, setDevTeamId] = React.useState(
    storeMetadata.dev_team_id || ""
  );
  const [itcTeamId, setItcTeamId] = React.useState(
    storeMetadata.itc_team_id || ""
  );
  const [developerAccountName, setDeveloperAccountName] = React.useState(
    storeMetadata.developer_account_name || ""
  );

  const [appStoreName, setAppStoreName] = React.useState(
    storeMetadata.app_store_name || ""
  );
  const [primaryCategory, setPrimaryCategory] = React.useState(
    storeMetadata.primary_category || ""
  );
  const [secondaryCategory, setSecondaryCategory] = React.useState(
    storeMetadata.secondary_category || ""
  );
  const [keywords, setKeywords] = React.useState(storeMetadata.keywords || "");
  const [copyright, setCopyright] = React.useState(
    storeMetadata.copyright || ""
  );
  const [contentRating, setContentRating] = React.useState(
    storeMetadata.content_rating || "all"
  );

  const [iosGaTrackingId, setIosGaTrackingId] = React.useState(
    iosMetadata.ga_tracking_id || ""
  );
  const [tvosGaTrackingId, setTvosGaTrackingId] = React.useState(
    tvosMetadata.ga_tracking_id || ""
  );
  const [bundleId, setBundleId] = React.useState(iosMetadata.bundle_id || "");
  const [appStoreId, setAppStoreId] = React.useState(storeMetadata.store_id || "");
  const [appTracking, setAppTracking] = React.useState(
    typeof(storeMetadata.app_tracking) === "undefined" ? false : storeMetadata.app_tracking
  );
  const [adultCategories, setAdultCategories] = React.useState(
    storeMetadata.content_categories || {
      cartoon_violence: false,
      profanity_crude_humor: false,
      medical_treatment_info: false,
      nudity_sexual_content: false,
      realistic_violence: false,
      mature_suggestive_layout: false,
      alcohol_tobacco_drugs: false,
      graphic_sexual_nudity: false,
      graphic_realistic_violence: false,
      horror_fear_layout: false,
      simulated_gambling: false,
      gambling_and_contests: false,
    }
  );
  const [firebaseApiKey, setFirebaseApiKey] = React.useState(iosMetadata.firebase_api_key || "");
  const [appStoreKeyId, setAppStoreKeyId] = React.useState(storeMetadata.key_id || "");
  const [appStoreIssuerId, setAppStoreIssuerId] = React.useState(storeMetadata.issuer_id || "");
  const saveTimer = React.useRef(null);

  const [asset, setAsset] = React.useState({
    urls: {
      appIcon: iosPlatform.assetUrls.app_icon,
      iphoneXCover: iosPlatform.assetUrls.iphone_x_covers || [],
      iphoneCover: iosPlatform.assetUrls.iphone_covers || [],
      ipadCover: iosPlatform.assetUrls.ipad_covers || [],
      iphoneXPreview: iosPlatform.assetUrls.iphone_x_previews || [],
      iphonePreview: iosPlatform.assetUrls.iphone_previews || [],
      ipadPreview: iosPlatform.assetUrls.ipad_previews || [],
      topLayerIcon: tvosPlatform.assetUrls.top_layer_icon,
      middleLayerIcon: tvosPlatform.assetUrls.middle_layer_icon,
      bottomLayerIcon: tvosPlatform.assetUrls.bottom_layer_icon,
      tvIcon: tvosPlatform.assetUrls.app_icon,
      tvCover: tvosPlatform.assetUrls.covers || [],
      topShelf: tvosPlatform.assetUrls.top_shelf,
      firebasePlist: iosPlatform.assetUrls.firebase_plist,
      appStoreCertificate: store.assetUrls.certificate,
    },
    blobs: {
      appIcon: null,
      iphoneXCover: [],
      iphoneCover: [],
      ipadCover: [],
      iphoneXPreview: [],
      iphonePreview: [],
      ipadPreview: [],
      topLayerIcon: null,
      middleLayerIcon: null,
      bottomLayerIcon: null,
      tvIcon: null,
      tvCover: [],
      topShelf: null,
      firebasePlist: null,
      appStoreCertificate: null,
    },
    errors: {
      appIcon: null,
      iphoneXCover: null,
      iphoneCover: null,
      ipadCover: null,
      iphoneXPreview: null,
      iphonePreview: null,
      ipadPreview: null,
      topLayerIcon: null,
      middleLayerIcon: null,
      bottomLayerIcon: null,
      tvIcon: null,
      tvCover: null,
      topShelf: null,
      firebasePlist: null,
      appStoreCertificate: null,
    },
  });

  const [tvIconType, setTvIconType] = React.useState(
    tvosMetadata.icon_type || "simple"
  );
  const [mobileLastUploaded, setMobileLastUploaded] = React.useState(null);
  const [tvLastUploaded, setTvLastUploaded] = React.useState(null);
  const [storeLastUploaded, setStoreLastUploaded] = React.useState(null);
  const [descriptionLangObj, setDescriptionLangObj] = React.useState(makeDefaultLangObject(storeMetadata.description))

  const description = descriptionLangObj[lang] || "";
  const assetProps = { asset, setAsset, assetMap: APPLE_ASSET_MAP };
  const apiKeys = [
    {name: "IOS", apiKey: iosPlatform.apiKey},
    {name: "TVOS", apiKey: tvosPlatform.apiKey}
  ]

  React.useEffect(() => {
    if (
      asset.blobs.appIcon ||
      asset.blobs.iphoneXCover.length > 0 ||
      asset.blobs.iphoneCover.length > 0 ||
      asset.blobs.ipadCover.length > 0 ||
      asset.blobs.iphoneXPreview.length > 0 ||
      asset.blobs.iphonePreview.length > 0 ||
      asset.blobs.ipadPreview.length > 0 ||
      asset.blobs.firebasePlist
    ) {
      setMobileLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.appIcon,
    asset.blobs.iphoneXCover,
    asset.blobs.iphoneCover,
    asset.blobs.ipadCover,
    asset.blobs.iphoneXPreview,
    asset.blobs.iphonePreview,
    asset.blobs.ipadPreview,
    asset.blobs.firebasePlist,
  ]);

  React.useEffect(() => {
    if (
      asset.blobs.topLayerIcon ||
      asset.blobs.middleLayerIcon ||
      asset.blobs.bottomLayerIcon ||
      asset.blobs.tvIcon ||
      asset.blobs.topShelf ||
      asset.blobs.tvCover.length > 0
    ) {
      setTvLastUploaded(new Date().getTime());
    }
  }, [
    asset.blobs.topLayerIcon,
    asset.blobs.middleLayerIcon,
    asset.blobs.bottomLayerIcon,
    asset.blobs.tvIcon,
    asset.blobs.tvCover,
    asset.blobs.topShelf,
  ]);

  React.useEffect(() => {
    if (asset.blobs.appStoreCertificate) {
      setStoreLastUploaded(new Date().getTime());
    }
  }, [asset.blobs.appStoreCertificate]);

  React.useEffect(() => {
    if (saveTimer.current) {
      clearTimeout(saveTimer.current);
      saveTimer.current = setTimeout(submit, 500);
    } else {
      saveTimer.current = "ready";
    }
  }, [
    tvLastUploaded,
    mobileLastUploaded,
    storeLastUploaded,
    accessGranted,
    devTeamId,
    itcTeamId,
    developerAccountName,
    appStoreName,
    descriptionLangObj,
    primaryCategory,
    secondaryCategory,
    keywords,
    copyright,
    contentRating,
    adultCategories,
    iosGaTrackingId,
    tvosGaTrackingId,
    bundleId,
    appStoreId,
    firebaseApiKey,
    appStoreKeyId,
    appStoreIssuerId,
    appTracking,
  ]);

  const submit = () => {
    if (validateForm()) {
      updateStoreApi({
        variables: {
          ...appProps,
          appStoreId: store.id,
          appStoreFields: {
            metadata: {
              access_granted: accessGranted,
              dev_team_id: devTeamId,
              itc_team_id: itcTeamId,
              developer_account_name: developerAccountName,
              app_store_name: appStoreName,
              description: descriptionLangObj,
              primary_category: primaryCategory,
              secondary_category: secondaryCategory,
              keywords: keywords,
              copyright: copyright,
              content_rating: contentRating,
              adult_categories: adultCategories,
              store_id: appStoreId,
              last_uploaded: storeLastUploaded,
              key_id: appStoreKeyId,
              issuer_id: appStoreIssuerId,
              app_tracking: appTracking,
            },
            assetBlobs: {
              certificate: asset.blobs.appStoreCertificate,
            },
            platformsAttributes: [
              {
                id: iosPlatform.id,
                metadata: {
                  bundle_id: bundleId,
                  ga_tracking_id: iosGaTrackingId,
                  last_uploaded: mobileLastUploaded,
                  firebase_api_key: firebaseApiKey,
                },
                assetBlobs: {
                  app_icon: asset.blobs.appIcon,
                  iphone_x_covers: asset.blobs.iphoneXCover,
                  iphone_covers: asset.blobs.iphoneCover,
                  ipad_covers: asset.blobs.ipadCover,
                  iphone_x_previews: asset.blobs.iphoneXPreview,
                  iphone_previews: asset.blobs.iphonePreview,
                  ipad_previews: asset.blobs.ipadPreview,
                  firebase_plist: asset.blobs.firebasePlist,
                },
              },
              {
                id: tvosPlatform.id,
                metadata: {
                  ga_tracking_id: tvosGaTrackingId,
                  icon_type: tvIconType,
                  last_uploaded: tvLastUploaded,
                },
                assetBlobs: {
                  top_layer_icon: asset.blobs.topLayerIcon,
                  middle_layer_icon: asset.blobs.middleLayerIcon,
                  bottom_layer_icon: asset.blobs.bottomLayerIcon,
                  app_icon: asset.blobs.tvIcon,
                  top_shelf: asset.blobs.topShelf,
                  covers: asset.blobs.tvCover,
                },
              },
            ],
          },
        },
      });
    }
  };

  const validateForm = () => {
    return true;
  };

  const onLangTextChange = (val, updateFunction) => {
    updateFunction({[lang]: val})
  }

  const langInput = (data, onSubmit, title, charLimit) => (<LanguageText
    data={data}
    onSubmit={onSubmit}
    languageOnly
    buttonProps={{style: {marginBottom: '32px'}}}
    title={title}
    charLimit={charLimit}
  />)

  return (
    <FormLayout breadcrumbs={[{ name: "Platforms", link: listUrl  }, { name: "Apple" }]}>
      <PaperGroup>
        <GroupHeader title="General" />

        <Tooltip title="The App Store name is used only in the app store, not on a user's home screen. Tip: all of the words in the app store name count as keywords when user's search for your app.">
          <Input
            label="App store name"
            defaultValue={appStoreName}
            onChange={setAppStoreName}
            inputProps={{ maxLength: 22 }}
            helperText={`${22 - appStoreName.length} - character limit`}
            required
            autoFocus
          />
        </Tooltip>

        <div style={{display: 'flex'}}>
          <Tooltip title="This is a description of your app that will be displayed in the app store. Keep your catchiest content in the first few lines to make sure it is visible to all visitors.">
            <Input
              label="Description"
              value={description}
              onChange={(val) => {onLangTextChange(val, setDescriptionLangObj)}}
              inputProps={{ maxLength: 4000 }}
              helperText={`${4000 - description.length} - character limit`}
              multiline
              rows={5}
              required
              disabled={isMultiLangOrRegion}
            />
          </Tooltip>
          {langInput(descriptionLangObj, setDescriptionLangObj, "Description", 4000)}
        </div>

        <Tooltip title="The primary and secondary categories help prospective downloaders find your app in the App Store.">
          <Select
            label="Primary Category"
            value={primaryCategory}
            onChange={setPrimaryCategory}
            options={categories}
            required
          />
        </Tooltip>

        <Tooltip title="The primary and secondary categories help prospective downloaders find your app in the App Store.">
          <Select
            label="Secondary Category"
            value={secondaryCategory}
            onChange={setSecondaryCategory}
            options={categories}
            required
          />
        </Tooltip>

        <Tooltip title="These are words and phrases used for App Store SEO. Apple limits to 96 characters so choose strategically. You can leave your App Name out, Apple already indexes this separately for search purposes. Please separate keywords using commas.">
          <Input
            label="Keywords"
            defaultValue={keywords}
            onChange={setKeywords}
            inputProps={{ maxLength: 96 }}
            placeholder="celebrities, celebrity news, taylor swift, justin bieber, kardashians"
            helperText={`${96 - keywords.length} - character limit`}
            required
          />
        </Tooltip>

        <Tooltip title="This should be the organization that owns the majority of content contained in the app.">
          <Input
            label="Copyright"
            defaultValue={copyright}
            onChange={setCopyright}
            inputProps={{ maxLength: 198 }}
            helperText={`${198 - copyright.length} - character limit`}
            required
          />
        </Tooltip>

        <Tooltip
          interactive
          title={
            <>
              {`Apple will assign your app an age rating based on its content. The ratings are 4+, 9+, 12+, or 17+.`}
              <br />
              <br />
              {`Note: MAZ cannot approve any apps with Graphic Realistic Violence or Graphic Sexual/Nudity content.`}
            </>
          }
        >
          <Select
            label="Content rating"
            value={contentRating}
            onChange={setContentRating}
            options={[
              { label: "All ages", value: "all" },
              { label: "17+", value: "17+" },
            ]}
            required
          />
        </Tooltip>

        <SwitchGroup
          label="App Tracking"
          checked={appTracking}
          onChange={setAppTracking}
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="iOS" caption={<>{`Use images of your app to convey your app’s value, highlight features, and preview the user experience. `}<Link href="https://help.apple.com/app-store-connect/#/devd274dd925" target="_blank">View Apple’s guidelines for screenshots</Link>{`.`}</>} />

        <ImageUploadGroup
          {...assetProps}
          name="appIcon"
          horizontal
          previewMaxHeight={128}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="iphoneXCover"
          horizontal
          previewMaxHeight={108}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="iphoneCover"
          horizontal
          previewMaxHeight={108}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="ipadCover"
          horizontal
          previewMaxHeight={108}
        />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="iOS Preview videos" caption={<>{`Quickly highlight your app’s top features. It’s also a good opportunity to show people how to use your app so they feel like experts before begin. We recommend you`} <Link href="https://developer.apple.com/app-store/app-previews/" target="_blank">view the marketing guidelines</Link>{` from Apple before you upload.`}</>} />

        <ImageUploadGroup {...assetProps} name="iphoneXPreview" horizontal />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="iphonePreview" horizontal />
        <Spacer />
        <ImageUploadGroup {...assetProps} name="ipadPreview" horizontal />
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="tvOS" />

        <Select
          value={tvIconType}
          onChange={setTvIconType}
          options={[
            { label: "Simple Icon", value: "simple" },
            { label: "Layered Icon", value: "layer" },
          ]}
          required
        />

        {tvIconType === "layer" ? (
          <>
            <ImageUploadGroup
              {...assetProps}
              name="topLayerIcon"
              horizontal
              previewMaxHeight={128}
            />
            <Spacer />
            <ImageUploadGroup
              {...assetProps}
              name="middleLayerIcon"
              horizontal
              previewMaxHeight={128}
            />
            <Spacer />
            <ImageUploadGroup
              {...assetProps}
              name="bottomLayerIcon"
              horizontal
              previewMaxHeight={128}
            />
          </>
        ) : (
          <ImageUploadGroup
            {...assetProps}
            name="tvIcon"
            horizontal
            previewMaxHeight={128}
          />
        )}

        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="tvCover"
          horizontal
          previewMaxHeight={108}
        />
        <Spacer />
        <ImageUploadGroup
          {...assetProps}
          name="topShelf"
          horizontal
          previewMaxHeight={128}
        />
      </PaperGroup>

      <PlatformApiKey
        apiKeys={apiKeys}
      />

      <PaperGroup>
        <GroupHeader title="Developer Access" />

        <SwitchGroup
          label="Admin access granted to devaccounts@mazsystems.com"
          checked={accessGranted}
          onChange={setAccessGranted}
        />
        {accessGranted &&
          <>
            <Input
              label="Dev team ID"
              defaultValue={devTeamId}
              onChange={setDevTeamId}
              required
            />
            <Input
              label="Itunes connect team ID"
              defaultValue={itcTeamId}
              onChange={setItcTeamId}
              required
            />
            <Input
              label="Developer account name"
              defaultValue={developerAccountName}
              onChange={setDeveloperAccountName}
              required
            />
          </>
        }
      </PaperGroup>

      <PaperGroup>
        <GroupHeader title="Configuration" />

        <Input
          label="App Store ID"
          defaultValue={appStoreId}
          onChange={setAppStoreId}
          required
        />
        <GaTrackingIdInput
          label="iOS Google Analytics Tracking ID"
          defaultValue={iosGaTrackingId}
          onChange={setIosGaTrackingId}
          required
        />
        <GaTrackingIdInput
          label="tvOS Google Analytics Tracking ID"
          defaultValue={tvosGaTrackingId}
          onChange={setTvosGaTrackingId}
          required
        />
        <BundleIdInput
          label="App Bundle ID"
          defaultValue={bundleId}
          onChange={setBundleId}
          placeholder={`com.maz.tvod.${appProps.appId}.${iosPlatform.id}`}
        />

        <Spacer />
        <GroupHeader title="Firebase" />
        <ImageUploadGroup {...assetProps} name="firebasePlist" />
        <Input
          label="Firebase API Key"
          defaultValue={firebaseApiKey}
          onChange={setFirebaseApiKey}
          required
        />
        <Spacer />

        <GroupHeader title="App Store" />
        <Input
          label="App Store Key ID"
          defaultValue={appStoreKeyId}
          onChange={setAppStoreKeyId}
          required
        />
        <Input
          label="App Store Issuer ID"
          defaultValue={appStoreIssuerId}
          onChange={setAppStoreIssuerId}
          required
        />
        <ImageUploadGroup {...assetProps} name="appStoreCertificate" />
      </PaperGroup>
    </FormLayout>
  );
};

export default withStore(Platform);
