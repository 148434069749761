import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import React from 'react';

export default function Saved({ open, onClose, message, additionalProps }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      message={message || "Saved"}
      {...additionalProps}
    >
      {additionalProps.type && message && <Alert onClose={onClose} severity={additionalProps.type}>
          {message}
        </Alert>
      }
    </Snackbar>
  );
}

export function withSavedSnack(Component) {
  return (props) => {
    const [ open, setOpen ] = React.useState(false);
    const [ message, setMessage ] = React.useState(false);
    const [ additionalProps, setAdditionalProps ] = React.useState({});
    const launch = (message, additionalProps={}) => {
      setMessage(message);
      setAdditionalProps(additionalProps);
      setOpen(true);
    };

    const onClose = () => {
      setOpen(false);
      setAdditionalProps({});
      setTimeout(() => {
        setMessage(null);
      }, 100);
    };

    return (
      <>
        <Component {...props} savedSnack={{ launch }} />
        <Saved open={open} onClose={onClose} message={message} additionalProps={additionalProps}/>
      </>
    );
  };
}
