import React from 'react';
import Confirm from './Confirm';

export default function Feed(props) {
  return (
    <Confirm
      {...props}
      content="This feed will be removed from your brand. Are you sure you want to proceed?"
    />
  );
}
