import React from "react";
import Base from "./Base";
import Input from "components/atoms/Input/BaseInput";
import SubmitButton from "components/atoms/Button/Submit";
import { useTheme } from "@mui/material/styles";

export default function AssetModal(props) {
  const { submit, ...otherProps } = props;
  const theme = useTheme();
  const [val, setVal] = React.useState(props.assetId);
  const [valError, setValError] = React.useState(null);

  const handleVal = (val) => {
    if (val) {
      setVal(val);
    } else {
      setVal(null);
    }
  };

  const saveAndClose = (props) => {
    if (val) {
      submit(val);
    } else {
      setValError("Required field");
    }
  };

  return (
    <Base
      title="Asset ID"
      titleRight={() => <SubmitButton disabled={!Boolean(val)} onClick={saveAndClose}>Done</SubmitButton>}
      dimensions={{
        width: theme.spacing(75),
        height: theme.spacing(30),
      }}
      {...otherProps}
    >
      <form style={{ width: "100%" }} onSubmit={saveAndClose}>
        <Input
          label="Asset ID"
          defaultValue={val}
          onChange={handleVal}
          error={Boolean(valError)}
          helperText={valError}
          autoFocus
          required
        />
      </form>
    </Base>
  );
}
