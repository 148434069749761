export function getHeightAndWidthFromDataUrl(dataURL) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };
    img.src = dataURL;
  });
}

export async function getDimensions(file) {
  const fileAsDataURL = window.URL.createObjectURL(file);
  const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);
  return dimensions;
}

/**
 * 
 * @param {{}} file 
 * @param {Array} allowedTypes i.e. [jpeg, png, jpg]. if not passed or empty array is passed, all types is accepted.
 */
export function checkFileType(file, allowedTypes=[]) {
  const mime = file.type;
  const IMAGE_TYPES = {
    png: 'image/png',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg'
  }
  if (!mime) return false;
  if (!allowedTypes || !allowedTypes.length) return true;
  const allowedMime = allowedTypes.map(type => IMAGE_TYPES[type.toLowerCase()]);
  return (allowedMime.includes(mime));
}

export function removeExtFromName(name) {
  return name.replace(/\.[^/.]+$/, "");
}
