import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";

import {
  GET_CURRENT_APP_PROPS,
  CREATE_WORKSPACE_USER,
  UPDATE_WORKSPACE_USER,
  UPDATE_APP,
  ME,
} from "api";
import { workspace } from "cache";
import Base from "./Base";
import Input from "components/atoms/Input/BaseInput";
import Select from "components/atoms/Input/Select";
import SubmitButton from "components/atoms/Button/Submit";
import useWindowSize from "customHooks/useWindowSize";

export default function WorkspaceUser(props) {
  const theme = useTheme();
  const client = useApolloClient();
  const size = useWindowSize();
  const { user, ...otherProps } = props;

  const [email, setEmail] = React.useState(user?.email);
  const [role, setRole] = React.useState(user?.role || "member");
  const [error, setError] = React.useState(null);

  const [modalWidth, setModalWidth] = React.useState(100);

  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const [createUser] = useMutation(CREATE_WORKSPACE_USER, {
    variables: {
      workspaceId: appProps.workspaceId,
      email: email,
      role: role,
    },
    update(cache, { data: { createWorkspaceUser } }) {
      cache.modify({
        fields: {
          workspaceUsers(existingRefs, { readField }) {
            return [...existingRefs, createWorkspaceUser.workspaceUser];
          },
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      handleError(data);
    },
  });

  const [updateUser] = useMutation(UPDATE_WORKSPACE_USER, {
    variables: {
      workspaceId: appProps.workspaceId,
      userId: user?.id,
      role: role,
    },
    update(cache, { data: { updateWorkspaceUser } }) {
      cache.modify({
        fields: {
          workspaceUsers(existingRefs, { readField }) {
            return existingRefs.map((ref) =>
              updateWorkspaceUser.workspaceUser.id === readField("id", ref)
                ? { ...ref, ...updateWorkspaceUser.workspaceUser }
                : ref
            );
          },
        },
      });
    },
    onCompleted(data) {
      onSave();
    },
    onError(data) {
      handleError(data);
    },
  });

  React.useEffect(() => {
    if (user?.email) {
      setEmail(user.email);
      setRole(user.role);
    } else {
      setEmail("");
      setRole("member");
      setError(null);
    }
  }, [user]);

  React.useEffect(() => {
    setError(null);
  }, [email])

  React.useEffect(() => {
    if(size?.width > 800) setModalWidth(100);
    else if (size?.width <= 800 && size?.width > 600) setModalWidth(60);
    else if(size?.width <= 600) setModalWidth(50);
  }, [size]);

  function onSave() {
    props.savedSnack.launch();
    props.onClose();
  }

  function handleError(data) {
    if (data.message.includes("already exists")) {
      setError("User already exists");
    } else if (data.message.includes("not found")) {
      setError("User not found");
    }
  }

  function saveAndClose() {
    if (Boolean(email) && Boolean(role)) {
      user.id ? updateUser() : createUser();
    }
  }

  function submit(ev) {
    ev.preventDefault();
    saveAndClose();
  }

  return (
    <Base
      title={user?.role ? "Edit Roles" : "New User"}
      titleRight={() => (
        <SubmitButton disabled={!Boolean(email)} onClick={saveAndClose}>
          Done
        </SubmitButton>
      )}
      dimensions={{
        width: theme.spacing(modalWidth),
        height: theme.spacing(50),
      }}
      {...otherProps}
    >
      <form style={{ width: "100%" }} onSubmit={submit}>
        <Input
          label="Email"
          defaultValue={email}
          onChange={setEmail}
          error={Boolean(error)}
          helperText={error}
          required
          disabled={user?.id}
        />

        <Select
          label="Role"
          value={role}
          onChange={setRole}
          options={[
            { label: "Admin", value: "admin" },
            { label: "Member", value: "member" },
          ]}
          required
        />
      </form>
    </Base>
  );
}
