import React from 'react';
import { Redirect } from "react-router-dom";

export function withRedirect(Component) {
  return (props) => {
    const [redirectToPath, setRedirectToPath] = React.useState(null);

    React.useEffect(() => {
      if (redirectToPath) {
        setRedirectToPath(null);
      }
    }, [redirectToPath]);

    if (redirectToPath) {
      return <Redirect to={redirectToPath} />;
    }

    return (
      <Component {...props} redirect={{ set: setRedirectToPath }} />
    );
  };
}
