import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import React from "react";
import _ from "lodash";

import { GET_CURRENT_APP_PROPS, ME, GET_WORKSPACE_USERS, DELETE_WORKSPACE_USER } from "api";
import { workspace } from "cache";
import FormLayout from "components/molecules/Form/FormLayout";
import DataGrid from "components/atoms/DataGrid";
import NewUserModal from "components/molecules/Modal/WorkspaceUser";
import ConfirmDialog from "components/molecules/Dialog/Confirm/User";
import ContentLayout from "screens/Layout/ContentLayout";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 240,
    sortable: true,
    filter: true,
    sort: "asc"
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
    sortable: true,
    filter: true,
  },
  {
    field: "roleName",
    headerName: "Role",
    width: 160,
    filter: true,
  },
  {
    field: "action",
    headerName: "Action",
    cellRenderer: "actionCellRenderer",
    minWidth:150,
    flex: 1,
  },
];

export default function Apps(props) {
  const client = useApolloClient();
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const me = useQuery(ME).data.me;
  const org = me.workspaces.find((w) => w.id === appProps.workspaceId);
  const { data } = useQuery(GET_WORKSPACE_USERS, {
    variables: {
      id: appProps.workspaceId,
    },
  });
  const [deleteWorkspaceUser] = useMutation(DELETE_WORKSPACE_USER, {
    update(cache, { data: { deleteWorkspaceUser } }) {
      cache.modify({
        fields: {
          workspaceUsers(existingRefs, { readField }) {
            return existingRefs.filter(
              (ref) => deleteWorkspaceUser.workspaceUser.id !== readField("id", ref)
            );
          },
        },
      });
      closeDeleteConfirmation();
    },
  });

  const [editing, setEditing] = React.useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null);
  const closeDeleteConfirmation = () => setDeleteConfirmation(null);

  const myRole = data?.workspaceUsers.find(w => w.id === me.id)?.role;

  function onEdit(app) {
    setEditing(app);
  }

  function onDelete(app) {
    setDeleteConfirmation(app);
  }

  function deleteWorkspaceUserApi(id) {
    deleteWorkspaceUser({
      variables: {
        workspaceId: appProps.workspaceId,
        userId: id,
      }
    });
  }

  const preventAction = (WUser) => {
    return WUser.role === "owner" || WUser.id === me.id || myRole === 'member'
  }

  return (
    <ContentLayout
      title="Users"
      fab={myRole !== 'member' && { onClick: () => setEditing({}) }}
    >
      <FormLayout>
        <DataGrid
          rowData={
            data?.workspaceUsers
              ? data.workspaceUsers.map((w) => ({
                  ...w,
                  roleName: w.id === me.id ? `${_.capitalize(w.role)} (You)` : _.capitalize(w.role),
                  actions: {
                    onEdit: preventAction(w) ? null : onEdit,
                    onDelete: preventAction(w) ? null : onDelete,
                  },
                }))
              : []
          }
          columnDefs={columns}
          accentedSort={true}
        />

        <NewUserModal
          open={Boolean(editing)}
          user={editing}
          onClose={() => setEditing(null)}
          savedSnack={props.savedSnack}
        />

        <ConfirmDialog
          open={Boolean(deleteConfirmation)}
          handleClose={closeDeleteConfirmation}
          accept={() => deleteWorkspaceUserApi(deleteConfirmation.id)}
          workspace={workspace()}
        />
      </FormLayout>
    </ContentLayout>
  );
}
