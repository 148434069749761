import React from "react";

import SimulatorLayout from "../SimulatorLayout";
import Form from "./Form";

export default function FormLayout(props) {
  const {
    title,
    left,
    right,
    children,
    submit,
    simulator,
    breadcrumbs,
    cancel,
    noCancel,
    disableCancel,
    disableSave,
    isChanged,
    betterForm,
    isLoading
  } = props;

  const submitForm = (event) => {
    event.preventDefault();
    submit && submit(event);
  };

  const hasSubmit = Boolean(submit);

  const formProps = {
    title,
    left,
    right,
    children,
    submit: submitForm,
    hasSubmit,
    simulator,
    cancel,
    noCancel,
    breadcrumbs,
    disableCancel,
    disableSave,
    isChanged,
    betterForm,
    isLoading
  };
  const simulatorLayoutProps = { children, simulator, breadcrumbs };

  return (
    <SimulatorLayout {...simulatorLayoutProps}>
      <Form {...formProps} />
    </SimulatorLayout>
  );
}
