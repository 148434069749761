import React, { useMemo } from "react";
import Select from "components/atoms/Input/Select";
import Tooltip from "components/atoms/Tooltip";

export const STRING_SEPERATOR = "^&%";
const tokens = [
  {token: "[APP_VERSION]", detail: "The app’s version string"},
  {token: "[APP_NAME]", detail: "The app’s display name"},
  {token: "[LANGUAGE_NAME]", detail: "The name of the app’s currently configured language"},
  {token: "[LANGUAGE_CODE]", detail: "The ISO code of the app’s currently configured language"},
  {token: "[LOCATION_NAME]", detail: "The name of the app’s currently configured location (country name)."},
  {token: "[LOCATION_CODE]", detail: "The ISO code of the app’s currently configured location (country code)."},
  {token: "[TERMS_OF_SERVICE_URL]", detail: "The value of the “Terms of Use” link as specified in the designer"},
  {token: "[PRIVACY_POLICY_URL]", detail: "The value of the “Privacy Policy” link as specified in the designer"},
  {token: "[FAQ_URL]", detail: "The value of the “FAQ” link as specified in the developer extras (if present) or the default FAQ."},
  {token: "[AUTH_HEADER]", detail: "The value of the “Header Text” field from the Design > Registration & Login page."},
  {token: "[AUTH_PROMPT]", detail: "The value of the string that should be shown based on the user’s current authentication"},
  {token: "[AUTH_SERVICE_NAME]", detail: "The “Display Name” of the DBI used to authenticate the user"},
]

function MenuTokenSelector(props) {
  const { value, setValue, isMultiLanguage } = props;

  const isDisabled = useMemo(() => (checkIfTokenPresent(value, isMultiLanguage)), [value])

  const onChange = (val) => {
    if (isMultiLanguage) {
      const langObj = {...value};
      const newLangObj = {};
      for (let language in langObj) {
        newLangObj[language] = `${langObj[language]}${val && val+STRING_SEPERATOR}`
      }
      setValue(newLangObj);
    } else {
      setValue((prev) => `${prev || ""}${val && val+STRING_SEPERATOR}`)
    }
  }
  return (
    <>
      {/* <Toolti */}
      <Tooltip
        title={<div>
          {tokens.map(t => (<div>
            <b>{t.token} - </b><span>{t.detail}</span>
          </div>))}
        </div>}
      >
        <div style={{width: 250}}>
          <Select
            label={"Insert token"}
            value={""}
            options={[
              {label: "Select token", value: ""},
              ...tokens.map(t => ({label: t.token, value: t.token}))
            ]}
            onChange={onChange}
            disabled={isDisabled}
          />
        </div>
      </Tooltip>
    </>
  );
}

export default MenuTokenSelector;

export const checkIfTokenPresent = (value, isMultiLanguage) => {
  if (!value) return value;
  if (isMultiLanguage) {
    const obj = {...value}
    for(let key in obj) {
      if(tokens.some(t => obj[key].includes(t.token))) return true;
    }
    return false;
  } else {
    return tokens.some(t => value.includes(t.token))
  }
}

export const getValueForTokenizeInput = (currenValue, isMultiLanguage) => {
  if (isMultiLanguage) {
    const langObj = {...currenValue};
    for (let language in langObj) {
      const string = langObj[language]
      if (string && !string.includes(STRING_SEPERATOR) && checkIfTokenPresent(string)) 
        langObj[language] = `${string+STRING_SEPERATOR}`
    }
    return langObj;
  } else {
    if (currenValue && !currenValue.includes(STRING_SEPERATOR) && checkIfTokenPresent(currenValue)) 
      return `${currenValue+STRING_SEPERATOR}`
    return currenValue;
  }
}

export const removeSeparatorForMenuLayout = (value, isMultiLanguage) => {
  if (isMultiLanguage) {
    const langObj = {...value};
    for (let language in langObj) {
      const string = langObj[language]
      if (string) 
        langObj[language] = string.split(STRING_SEPERATOR)[0]
    }
    return langObj;
  } else {
    if (!value) return "";
    return value.split(STRING_SEPERATOR)[0];
  }
}
