import React from 'react';

export default function withSimulator(Component) {
  return (props) => {
    const [ device, setDevice ] = React.useState(1);
    const deviceName = ["Mobile", "TV"][device];
    const designs = props.designs;
    const dmap = {};
    designs.forEach(d => {
      if (!dmap[d.locale.id]) {
        dmap[d.locale.id] = { ...d.locale, languages: [] };
      }
      dmap[d.locale.id].languages.push(d.language);
    });
    const [ region, setRegion ] = React.useState(Object.keys(dmap)[0]);
    const [ language, setLanguage ] = React.useState(dmap[region].languages[0]);
    const [ resetForm, setResetForm ] = React.useState(false);

    React.useEffect(() => {
      if (region) {
        if (!dmap[region].languages.includes(language)) {
          setLanguage(dmap[region].languages[0]);
        }
      }
      setResetForm(true);
    }, [region, language]);

    React.useEffect(() => {
      setResetForm(false);
    }, [resetForm]);

    const design = designs.find(d => d.locale.id === region && d.language === language);
    if (!design || resetForm) {
      return null;
    }

    const simulator = {
      deviceName: deviceName,
      getter: device,
      setter: (event, newValue) => setDevice(newValue),
      region: region,
      setRegion: setRegion,
      language: language,
      setLanguage: setLanguage,
      regions: Object.keys(dmap).map(l => ({ label: dmap[l].name, value: dmap[l].id })),
      languages: dmap[region].languages,
      design: design,
    };

    return (
      <Component {...props} simulator={simulator} />
    );
  };
}
