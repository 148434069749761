import { useQuery } from "@apollo/client";
import React from 'react';
import { useParams } from "react-router-dom";

import { GET_CURRENT_APP_PROPS, GET_LIVE_PROGRAM } from "api";
import Form from './Form';

export default function Edit(props) {
  const appProps = useQuery(GET_CURRENT_APP_PROPS).data.getCurrentAppProps;
  const { id, liveChannelId, liveScheduleId } = useParams();
  const { data } = useQuery(GET_LIVE_PROGRAM, {
    variables: { ...appProps, liveChannelId, liveScheduleId, id },
  });

  return (
    data?.liveProgram
      ? <Form {...props} liveProgram={data?.liveProgram} />
      : null
  );
}
