import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
            light: '#2c2c2c',
            dark: '#000000'
        },
        secondary: {
            main: '#d32f2f',
            light: '#ff6659',
            dark: '#9a0007'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile:600,
            tablet: 800,
            desktop: 1200,
        },
      },
});

export default theme;