import React from "react";
import FormLayout from "components/molecules/Form/FormLayout";
import ContentLayout from "screens/Layout/ContentLayout";
import PaperGroup from "components/molecules/Form/PaperGroup";
import Stripe from "./Stripe";
import Cleeng from "./Cleeng";

export default function Payment(props) {
  const { savedSnack } = props;
  const commonProps = {savedSnack}

  return (
    <ContentLayout title="Payments">
      <FormLayout>
        <PaperGroup>
          <Stripe {...commonProps}/>
        </PaperGroup>
        <PaperGroup>
          <Cleeng {...commonProps}/>
        </PaperGroup>
      </FormLayout>
    </ContentLayout>
  );
}
