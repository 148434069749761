import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import List from "./List";
import New from "./New";
import Edit from "./Edit";

export default function Content(props) {
  const { path } = useRouteMatch();
  const { savedSnack } = props;
  const commonProps = { savedSnack };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <New {...props} {...commonProps} />
      </Route>
      <Route exact path={`${path}/edit/:id`}>
        <Edit {...props} {...commonProps} />
      </Route>
      <Route path={`${path}`}>
        <List {...commonProps} />
      </Route>
    </Switch>
  );
}
